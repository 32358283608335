import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PostAssignmentComponent } from 'src/app/teachers/post-assignment/post-assignment.component';
import { MatDialog } from '@angular/material/dialog';
import { COURSE_ACTIVITY, ADMIN_THEME, ACTIVITY_TYPES, PUBLISH_ACTION, ACTIVE_STATUS, ROLE_TYPE, STUDENT_ROLE } from 'src/app/Constants/Constant';
import { PostMaterialComponent } from 'src/app/teachers/post-material/post-material.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CoursesService } from 'src/app/services/courses.service';
import { Course } from 'src/app/models/course.model';
import { Location } from '@angular/common';
import { CourseActivitiesService } from 'src/app/admin/services/course-activities.service';
import { TestComponent } from 'src/app/teachers/test/test.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { FindSchoolUserRoleService } from 'src/app/services/find-school-user-role.service';

const POST_ASSIGNMENT = 'Post Assignment';
const POST_MATERIAL = 'Post Material';
const POST_TEST = 'Post Test';
const DIALOG_WIDTH = '91%';
const EDIT_ASSIGNMENT = 'Edit Assignment';
const EDIT_MATERIAL = 'Edit Material';

const ASSIGNMENT = 'assignment';
const MATERIAL = 'material';
@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss']
})
export class CourseDetailComponent implements OnInit, OnDestroy {

  constructor(
    private sidenavSvc: ToggleSidenavService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private courseSvc: CoursesService,
    private _location: Location,
    private courseActivitiesSvc: CourseActivitiesService,
    private ref: ChangeDetectorRef,
    private snackbar: SnackbarService,
    private localstorage: LocalStorageService,
    private router: Router,
    private userRole: FindSchoolUserRoleService
  ) {
    this.sidenavSvc.minmizeSidenav();
  }
  public sortDirection = 'asc';

  public actionsList: string[] = [POST_ASSIGNMENT, POST_MATERIAL, POST_TEST];
  public action = new FormControl();
  public selectedCourse: Course;
  private destroy$$: Subject<any> = new Subject<any>();

  private nextCreationIndex: number;

  private config = {
    width: '46%',
    height: '100%',
    backdropClass: 'blur',
    data: null,
    disableClose: true,
    panelClass: ['admin-theme', 'width-class'],
    autoFocus: false
  };

  public loadingCourseDetails: boolean;
  public loadingCourseActivities: boolean;
  public courseActivities: any[] = [];
  public displayedColumns = ['seq', 'title', 'date', 'type', 'completedBy', 'actions'];

  public dataSource: any[];
  public updatingState = false;
  public publishButtonText = '';
  public role = '';
  public disableTableActions = false;


  ngOnInit(): void {
    this.userRole.userRole$.pipe(
      takeUntil(this.destroy$$)
    )
      .subscribe(role => {
        this.role = role;
        if (this.role === 'member') {
          this.disableTableActions = true;
        }
      });
    this.courseActivitiesSvc.courseActivities$.subscribe(
      (courseActivities) => {
        if (courseActivities) {
          this.courseActivities = courseActivities;
          this.nextCreationIndex = this.courseActivities.length + 1;
          this.dataSource = this.courseActivities;
        }
      });
    this.sidenavSvc.minmizeSidenav();
    this.courseSvc.isLoading$.subscribe((isLoading) => this.loadingCourseDetails = isLoading);
    this.courseActivitiesSvc.isLoading$.subscribe((isLoading) => this.loadingCourseActivities = isLoading);
    this.route.params.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(param => {
      if (param.id) {
        this.courseSvc.getCourseDetail(param.id);
        this.courseActivitiesSvc.getCourseActivities(param.id);
      }
    });
    this.courseSvc.courseDetail$.subscribe((course) => {
      if (course) {
        this.selectedCourse = course;
        this.publishButtonText = this.selectedCourse.isPublished ? 'Published' : 'Publish';
      }
    });

    this.action.valueChanges.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(value => {
      const dialogConfig = { ...this.config };
      dialogConfig.data = {
        title: value, courseID: this.selectedCourse.id, entityType: COURSE_ACTIVITY,
        activityIndex: this.nextCreationIndex
      };
      if (value === POST_ASSIGNMENT) {
        const dialogRef = this.dialog.open(PostAssignmentComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          this.action.setValue('');
          if (result && result.assignment) {
            this.courseActivities.push(result.assignment);
            this.dataSource = [...this.courseActivities];
            this.nextCreationIndex++;
          }
        });
      } else if (value === POST_MATERIAL) {
        const dialogRef = this.dialog.open(PostMaterialComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          this.action.setValue('');
          if (result && result.material) {
            this.courseActivities.push(result.material);
            this.dataSource = [...this.courseActivities];
            this.nextCreationIndex++;
          }
        });
      } else if (value === POST_TEST) {
        const dialogProps = { ...dialogConfig };
        dialogProps.width = DIALOG_WIDTH;
        dialogProps.height = '100%';
        dialogProps.data = {
          type: COURSE_ACTIVITY, theme: ADMIN_THEME,
          courseID: this.selectedCourse.id, activityIndex: this.nextCreationIndex
        };
        const dialogRef = this.dialog.open(TestComponent, dialogProps);
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.test) {
            this.courseActivities.push(result.test);
            this.dataSource = [...this.courseActivities];
            this.nextCreationIndex++;
          }
        });
        this.action.setValue('');
      }
    });
  }


  back() {
    this._location.back();
  }

  editCourseActivity(courseActivity) {
    const dialogConfig = { ...this.config };
    if (courseActivity.activityType === ACTIVITY_TYPES.assignments) {
      dialogConfig.data = {
        post: courseActivity, title: EDIT_ASSIGNMENT, courseID: this.selectedCourse.id,
        entityType: COURSE_ACTIVITY, activityIndex: courseActivity.index
      };
      const dialogRef = this.dialog.open(PostAssignmentComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        this.action.setValue('');
        if (result && result.assignment) {
          const updateIndex = this.courseActivities.findIndex(ca => ca.id === courseActivity.id);
          if (updateIndex >= 0) {
            this.courseActivities[updateIndex] = result.assignment;
            this.dataSource = [...this.courseActivities];
          }
        }
      });

    } else if (courseActivity.activityType === ACTIVITY_TYPES.materials) {
      dialogConfig.data = {
        post: courseActivity, title: EDIT_MATERIAL, courseID: this.selectedCourse.id,
        entityType: COURSE_ACTIVITY, activityIndex: courseActivity.index
      };
      const dialogRef = this.dialog.open(PostMaterialComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        this.action.setValue('');
        if (result && result.material) {
          const updateIndex = this.courseActivities.findIndex(ca => ca.id === courseActivity.id);
          if (updateIndex >= 0) {
            this.courseActivities[updateIndex] = result.material;
            this.dataSource = [...this.courseActivities];
          }
        }
      });
    }

  }

  public updateOrder({ prevIndex, newIndex }) {
    const temp = { ...this.courseActivities[prevIndex] };
    this.courseActivities.splice(prevIndex, 1);
    this.courseActivities.splice(newIndex, 0, temp);
    this.courseActivities[prevIndex].index = prevIndex + 1;
    this.courseActivities[newIndex].index = newIndex + 1;
    this.dataSource = [...this.courseActivities];
    this.updatingState = true;
    const requestBody = {
      activityID: temp.id,
      currentIndex: prevIndex + 1,
      newIndex: newIndex + 1,
    }
    this.courseActivitiesSvc.reorderCourseActivity(temp.courseID, requestBody)
      .pipe(takeUntil(this.destroy$$))
      .subscribe((resp) => {
        if (prevIndex > newIndex) {
          this.courseActivities = this.courseActivities.map((ca) => {
            if (ca.index >= (newIndex + 1) && ca.index < (prevIndex + 1)) {
              ca.index++;
            }
            return ca;
          });
        } else {
          this.courseActivities = this.courseActivities.map((ca) => {
            if (ca.index <= (newIndex + 1) && ca.index > (prevIndex + 1)) {
              ca.index--;
            }
            return ca;
          });
        }
        this.courseActivities[prevIndex].index = prevIndex + 1;
        this.courseActivities[newIndex].index = newIndex + 1;
        this.dataSource = [...this.courseActivities];
        this.updatingState = false;
      }, err => {
        const tmp = { ...this.courseActivities[prevIndex] };
        this.courseActivities.splice(prevIndex, 1);
        this.courseActivities.splice(newIndex, 0, tmp);
        this.courseActivities[prevIndex].index = prevIndex + 1;
        this.courseActivities[newIndex].index = newIndex + 1;
        this.dataSource = [...this.courseActivities];
        this.updatingState = false;
        this.snackbar.openSnackbar('unable to reorder course activities');
      });
  }

  public publishCourse() {
    console.log('publish course clicked');
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      height: '240px',
      backdropClass: 'blur',
      data: { theme: 'admin', actionType: PUBLISH_ACTION, contentType: 'course' },
      panelClass: ['delete-dialog-css'],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updatingState = true;
        this.courseSvc.updateCourse({ id: this.selectedCourse.id, isPublished: true, status: ACTIVE_STATUS }).subscribe(() => {
          this.updatingState = false;
          this.selectedCourse.isPublished = true;
          this.snackbar.openSnackbar('course published successfully');
          this.publishButtonText = this.selectedCourse.isPublished ? 'Published' : 'Publish';
        }, () => {
          this.updatingState = false;
          this.snackbar.openSnackbar('unable to publish course at the moment');
        });
      }
    });
  }

  public navigateToActivity(courseActivity) {
    this.router.navigate(['school', 'dashboard', 'course-activity', courseActivity.activityType, courseActivity.id]);
  }

  ngOnDestroy() {
    this.sidenavSvc.maximizeSidenav();
    this.courseActivitiesSvc.reset();
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }
}
