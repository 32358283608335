<div class="common-settings-wrapper">
    <ng-container *ngIf="selectedSetting === ''; else settingDetail">
        <app-settings [selectedSchool]="selectedSchool" [isSchoolSpecificSetting]="true" (specificSelectedSetting)="onSettingSelected($event)"></app-settings>
    </ng-container>

    <ng-template #settingDetail>
        <ng-container *ngIf="!isLoading; else loader">
            <div class="selected">
                <ng-container>
                    <div class="header">
                        <ng-container *ngIf="lists.length !== 0; else note">
                            <div>
                                <div><span class="common"><span class="img" (click)="back()"><img src="../../../../assets/grey-back.svg"/></span>Common Settings</span> <span class="name"> - {{labelData[selectedSetting.toLowerCase()] | titlecase}}</span></div>
                                <div class="add">Check and Uncheck to Add or Remove {{labelData[selectedSetting.toLowerCase()]}} from this {{labelData['school']}}</div>
                            </div>
                        </ng-container>

                        <ng-template #note>
                            <div>
                                <div><span class="common" (click)="back()">Common Settings</span> <span class="name"> - {{labelData[selectedSetting.toLowerCase()] | titlecase}}</span></div>
                                <div class="add">Please add the {{selectedSetting | titlecase}} in Master settings first</div>
                            </div>
                        </ng-template>

                        <div *ngIf="lists.length !== 0">
                            <ng-container>
                                <div class="btn">
                                    <button mat-flat-button [disabled]="userRole === 'member' || selectedSchool.status == 'archived'" color="primary" (click)="save()">SAVE</button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>

                <ng-container [ngSwitch]="selectedSetting">
                    <ng-container *ngSwitchCase="'standards'">
                        <div class="list">
                            <div *ngFor="let standard of lists" class="label">
                                <mat-checkbox (change)="onSelect($event, standard)" [checked]="isSelected(standard)" [disabled]="userRole === 'member' || selectedSchool.status == 'archived'" color="primary">{{standard.name}}</mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'session'">
                        <div class="list">
                            <div *ngFor="let session of lists" class="label">
                                <mat-checkbox (change)="onSelect($event, session)" [checked]="isSelected(session)" [disabled]="userRole === 'member'  || selectedSchool.status == 'archived'" color="primary">{{session.name}}</mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'sections'">
                        <div class="list">
                            <div *ngFor="let section of lists" class="label">
                                <mat-checkbox (change)="onSelect($event, section)" [checked]="isSelected(section)" [disabled]="userRole === 'member'  || selectedSchool.status == 'archived'" color="primary">{{section.name}}</mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'subjects'">
                        <div class="list">
                            <div *ngFor="let subject of lists" class="label">
                                <mat-checkbox (change)="onSelect($event, subject)" [checked]="isSelected(subject)" [disabled]="userRole === 'member' || selectedSchool.status == 'archived'" color="primary">{{subject.name}}</mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

        <ng-template #loader>
            <app-spinner></app-spinner>
        </ng-template>
    </ng-template>
</div>