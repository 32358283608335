<ng-container *ngIf="record.type !== 'staff'; else staff" >
    <div class="record-card-wrapper">
        <div class="header">
            <div class="title">{{record.type == 'teachers' ? teachersLabel : (record.type == 'staff' ? staffLabel : (record.type == 'students' ? studentsLabel : record.type) ) | titlecase }} </div>
            <div class="strength"  [ngClass]="getClass()">{{record.strength}}</div>
        </div>
        <div class="stat"  [ngClass]="record.type">
            <div class="active">
                <div class="text">Active</div>
                <div class="progress">
                    <mat-progress-bar mode="determinate" [value]='50'></mat-progress-bar>
                </div>
                <div class="value">{{record.active}}</div>
            </div>
            <div class="inactive">
                <div class="text">Inactive</div>
                <div class="progress">
                    <mat-progress-bar mode="determinate" [value]='30'></mat-progress-bar>
                </div>
                <div class="value">{{record.inactive}}</div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #staff>
    <div class="record-card-wrapper">
        <div class="header">
            <div class="title">{{staffLabel | titlecase}}</div>
            <div class="strength" [ngClass]="getClass()">{{record.strength}}</div>
        </div>
        <div class="stat" [ngClass]="record.type">
            <div class="text">Punctual</div>
            <div class="subheader">
                <div class="progress">
                    <mat-progress-bar mode="determinate" [value]='80'></mat-progress-bar>
                </div>
                <div class="value">{{record.punctual}}</div>
            </div>
        </div>
    </div>
</ng-template>