import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-insert-file-dialog',
  templateUrl: './insert-file-dialog.component.html',
  styleUrls: ['./insert-file-dialog.component.scss']
})
export class InsertFileDialogComponent implements OnInit {
  @Input() postType;
  @Input() fileType: 'test' | 'folder' = 'folder';
  @Input() isCloudContent = false;
  @Output() action = new EventEmitter();
  libraryOptionText;
  constructor() { }

  ngOnInit(): void {
    this.libraryOptionText = this.isCloudContent ? 'CLOUD' : 'LIBRARY';
  }

  closeDialog(action) {
    this.action.emit(action);
  }

  keepDialog(event) {
    event.stopPropagation();
  }
}
