import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  @Input() studentsLabel;
  @Input() staffLabel;
  @Input() teachersLabel;
  stats;
  sessionStatus;
  constructor() { }

  ngOnInit(): void {
    this.stats = {
      students: {
        type: 'students',
        active: '95%',
        inactive: '5%',
        strength: '1000'
      },
      teachers: {
        type: 'teachers',
        active: '90%',
        inactive: '15%',
        strength: '700'
      },
      staff: {
        type: 'staff',
        punctual: '75%',
        strength: '80'
      }
    };
    this.sessionStatus = {
      assignment: '65%',
      test: '55%',
      material: '75%',
      live: '85%'
    }
  }

}
