import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LiveStream } from 'src/app/models/live-class-models';
import { environment } from 'src/environments/environment';
import { SUCCESS_CALLBACK_INFOS, CONNECTION_ALIVE_CHECK_TIME } from 'src/app/Constants/Constant';

@Injectable({
  providedIn: 'root'
})
export class TeacherSubscriberService {

  constructor(private crud: CrudService) { }

  //streaming constraints
  private peerConnectionConfig: any;
  private mediaConstraints: any;
  private sdpConstraints: any;
  private webRTCAdaptor: any;


  private isLeftSession: boolean = false;
  //room related info
  private roomName: string;
  private localStreamId: string;
  private teacherStreamId: string;

  //showing loader if pong is not received in a fixed time.
  private showLoader$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public get showLoader$(): Observable<boolean> {
    return this.showLoader$$.asObservable()
  }

  //check for connection alive or not, handling reconnecting
  private isConnectionClosed$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public get isConnectionClosed$(): Observable<boolean> {
    return this.isConnectionClosed$$.asObservable()
  }

  private lastPongTime: any;
  private connectionAliveCheck: any;
  private checkIfConnectionAlive() {
    let presentTime: any = new Date();
    if (presentTime - this.lastPongTime >= 10000) {
      this.showLoader$$.next(true);
    } else if (this.showLoader$$.value) {
      this.showLoader$$.next(false);
    }
  }

  //if rejoining or not
  private joiningSession: boolean = false;

  public isJoiningSession(): boolean {
    return this.joiningSession
  }

  // to maintain other remote streams
  private alreadyPlayingStreamsIds: any = [];

  private remoteStreams$$: BehaviorSubject<LiveStream[]> = new BehaviorSubject([]);

  public get remoteStreams$(): Observable<LiveStream[]> {
    return this.remoteStreams$$.asObservable()
  }

  private setGlobalMediaConstraints() {
    mediaConstraints = this.mediaConstraints;
  }

  public setStreamingConstraints(
    peerConnectionConfig: any,
    mediaConstraints: any,
    sdpConstraints: any,) {
    this.peerConnectionConfig = peerConnectionConfig;
    this.mediaConstraints = mediaConstraints;
    this.sdpConstraints = sdpConstraints;
    this.setGlobalMediaConstraints();
    this.showLoader$$.next(false);
    this.isLeftSession = false;
  }

  public setTeacherStreamId(id: string) {
    this.teacherStreamId = id;
  }


  //retrying 
  private retryCount = 0;
  private isConnectionDropped$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get isConnectionDropped$(): Observable<boolean> {
    return this.isConnectionDropped$$.asObservable()
  }

  //joing session request 
  public joinSession(roomName: string, localVideoElId: string, localStreamId: string) {
    if(this.isLeftSession) {
      return
    }
    this.joiningSession = true;
    this.localStreamId = localStreamId + '-sub';
    this.roomName = roomName;
    this.webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: environment.antMediaPlayUrl,
      mediaConstraints: this.mediaConstraints,
      peerconnection_config: this.peerConnectionConfig,
      sdp_constraints: this.sdpConstraints,
      isPlayMode: true,
      bandwidth: 800,
      callback: this.successCallback,
      callbackError: this.errorCallback,
    });
  }

  //success callback
  private successCallback = (info, obj = null) => {
    if (this.isLeftSession) {
      this.setInitialState();
      return
    }
    switch (info) {
      case SUCCESS_CALLBACK_INFOS.Initialized:
        this.initSuccessCallback(info);
        break;
      case SUCCESS_CALLBACK_INFOS.JoinedTheRoom:
        this.roomJoinSuccessCallback(info, obj);
        break;
      case SUCCESS_CALLBACK_INFOS.RoomInfo:
        this.receivedRoomInfoCallback(info, obj);
        break;
      case SUCCESS_CALLBACK_INFOS.NewStreamAvailable:
        this.addNewlyAvailableStream(obj);
        break;
      case SUCCESS_CALLBACK_INFOS.Pong:
        this.lastPongTime = new Date();
        break;
      case SUCCESS_CALLBACK_INFOS.PlayFinished:
        this.playFinishedCallback(obj);
        break;
      case SUCCESS_CALLBACK_INFOS.DataChannelOpened:
        this.dataChannelOpenedCallback(obj);
        break;
      case SUCCESS_CALLBACK_INFOS.DataReceived:
        this.onMessageCallback(obj);
        break;
      case SUCCESS_CALLBACK_INFOS.DataChannelClosed:
        this.dataChannelClosedCallback(obj);
        break;
    }
  }

  private errorCallback = (error, message) => {
    if (this.isLeftSession) {
      this.setInitialState();
      return
    }
    //TODO: check for room not initialized or error callback
    if (error == "TypeError" && this.webRTCAdaptor) {
      if (this.webRTCAdaptor.roomTimerId) {
        clearInterval(this.webRTCAdaptor.roomTimerId);
      }
      this.resetWebRTCAdaptor();
    }
    if (error === "WebSocketNotConnected" || error.target instanceof WebSocket) {
      if (this.connectionAliveCheck) {
        clearInterval(this.connectionAliveCheck);
      }
      clearInterval(this.webRTCAdaptor.roomTimerId);
      this.remoteStreams$$.next([])
      if (!this.showLoader$$.value) {
        this.showLoader$$.next(true);
      }
      this.joiningSession = false;
      this.isConnectionClosed$$.next(true)
    }
    this.resetWebRTCAdaptor();
    console.log("[errorCallback] error: ", error, 'message: ', message);
  }

  private initSuccessCallback(info: any) {
    this.retryCount = 0;
    console.log("[teacher-subscriber-service]: websocket connection established, adaptor initialized")
    this.showLoader$$.next(false);
    this.connectionAliveCheck = setInterval(() => {
      this.checkIfConnectionAlive();
    }, CONNECTION_ALIVE_CHECK_TIME);
    console.log("[teacher-subscriber-service]: joining room: ",this.roomName, "with stream id: ",this.localStreamId)
    this.webRTCAdaptor.joinRoom(this.roomName, this.localStreamId);
  }

  private roomJoinSuccessCallback(info: any, obj: any) {
    console.log("[teacher-subscriber-service]: joined the room successfully starting call to get room info")
    this.webRTCAdaptor.getRoomInfo(this.roomName, this.localStreamId)
  }

  private receivedRoomInfoCallback(info: any, obj: any) {
    console.log("[teacher-subscriber-service]: received room info,details: ",obj)
    console.log("[teacher-subscriber-service]: checking for already playing streams and removing them if needed: ",obj)
    let foundTeacherStream = false;
    for (let index = 0; index < obj.streams.length; index++) {
      if (obj.streams[index] == this.teacherStreamId) {
        foundTeacherStream = true;
        continue;
      }
      if (!this.alreadyPlayingStreamsIds.includes(obj.streams[index])) {
        //get token to play the corresponding stream from server
        this.alreadyPlayingStreamsIds.push(obj.streams[index]);
        if (!this.webRTCAdaptor.isPlayMode) {
          this.webRTCAdaptor.isPlayMode = true;
        }
        // retrive token from server and then add token to the play call.
        console.log("[teacher-subscriber-service]: sending request for a play token for streamId: ",obj.streams[index])
        this.crud.get(environment.socketServerBaseUrl + '/class/live/' + this.roomName + '/stream/' + obj.streams[index] + '?token-type=play').subscribe(
          (resp: any) => {
            console.log("[teacher-subscriber-service]: successfully got the token to play stream: ",obj.streams[index])
            if (this.webRTCAdaptor && !this.isLeftSession) {
              this.webRTCAdaptor.play(obj.streams[index], resp.token, this.roomName);
            }
          }, (err) => {
            console.log("[teacher-subscriber-service]: unable to get play token for stream: ",obj.streams[index], " error :",err)
          }
        );
      }
    }
    let existingRemoteStreams = this.remoteStreams$$.value;
    let toRemoveStreamIds = [];
    for (let index = 0; index < existingRemoteStreams.length; index++) {
      if (!obj.streams.includes(existingRemoteStreams[index].source.streamId)) {
        toRemoveStreamIds.push(existingRemoteStreams[index].source.streamId);
      }
    }
    if (toRemoveStreamIds.length > 0) {
      let updatedAlreadyPlayingStreamIds = this.alreadyPlayingStreamsIds.filter(
        (streamId) => !toRemoveStreamIds.includes(streamId));
      this.alreadyPlayingStreamsIds = updatedAlreadyPlayingStreamIds;
      let updatedExistingRemoteStreams = existingRemoteStreams.filter(
        (remoteStream) => !toRemoveStreamIds.includes(remoteStream.source.streamId)
      );
      this.remoteStreams$$.next(updatedExistingRemoteStreams);
    }
  }

  private addNewlyAvailableStream(obj: any) {
    console.log("[teacher-subscriber-service]: a new stream is available, adding it to show on the ui")
    let alreadyAvailableStreams = this.remoteStreams$$.value;
    let alreadyPresentStreamIndex = alreadyAvailableStreams.findIndex(
      (remoteStream) => remoteStream.source.streamId === obj.streamId
    )
    if (alreadyPresentStreamIndex === -1) {
      if (obj.streamId === this.teacherStreamId) {
      } else {
        let remoteStream: LiveStream = {
          isCameraOn: true,
          isMicOn: true,
          source: obj,
          isSharingScreen: false
        }
        alreadyAvailableStreams.push(remoteStream)
        this.remoteStreams$$.next(alreadyAvailableStreams);
      }
    }
  }

  private playFinishedCallback(obj) {
    console.log("[teacher-subscriber-service]: play finished, removing the stream from ui. details:",obj)
    let updatedStartedStreamIds = this.alreadyPlayingStreamsIds;
    let leavedStreamIndex = updatedStartedStreamIds.findIndex((streamId) => streamId === obj.streamId);
    if (leavedStreamIndex != -1) {
      updatedStartedStreamIds.splice(leavedStreamIndex, 1);
    }
    //check for teacher stream
    if (obj.streamId == this.teacherStreamId) {
    } else {
      let updatedRemoteStreams = this.remoteStreams$$.value;
      let leavedStreamIndex = updatedRemoteStreams.findIndex((stream) => stream.source.streamId === obj.streamId);
      if (leavedStreamIndex != -1) {
        updatedRemoteStreams.splice(leavedStreamIndex, 1)
        this.remoteStreams$$.next(updatedRemoteStreams);
      }
    }
  }

  private dataChannelOpenedCallback = (streamId) => {
    console.log("[teacher-subscriber-service]: data channel opened for stream", streamId);
  }

  private onMessageCallback = (obj) => {
    let message = JSON.parse(obj.event.data)
    console.log("message received", message)
    if (message.event === "STREAM_INFO") {
      if (message.data.streamId === this.teacherStreamId) {
      } else {
        let index = this.remoteStreams$$.value.findIndex((stream) => stream.source.streamId === message.data.streamId)
        if (index != -1) {
          let remoteStreams = this.remoteStreams$$.value;
          if(
            remoteStreams[index].isCameraOn != message.data.isCameraOn ||
            remoteStreams[index].isMicOn != message.data.isMicOn ||
            remoteStreams[index].isSharingScreen != message.data.isSharingScreen
            ) {
              remoteStreams[index].isCameraOn = message.data.isCameraOn;
              remoteStreams[index].isMicOn = message.data.isMicOn;
              remoteStreams[index].isSharingScreen = message.data.isSharingScreen;
              this.remoteStreams$$.next(remoteStreams);
            }
        }
      }
    }
  }

  private dataChannelClosedCallback = (streamId) => {
    console.log("[teacher-subscriber-service]: dataChannelClosedCallback for stream", streamId);
  }


  public setInitialState = () => {
    if (this.connectionAliveCheck) {
      clearInterval(this.connectionAliveCheck)
      this.connectionAliveCheck = null;
    }
    this.showLoader$$.next(false);
    this.isConnectionClosed$$.next(false);
    this.resetWebRTCAdaptor();
  }

  private resetWebRTCAdaptor() {
    if (this.webRTCAdaptor) {
      if (this.webRTCAdaptor.roomTimerId) {
        clearInterval(this.webRTCAdaptor.roomTimerId);
      }
      if (this.webRTCAdaptor.webSocketAdaptor) {
        Object.keys(this.webRTCAdaptor.webSocketAdaptor).forEach(k => this.webRTCAdaptor.webSocketAdaptor[k] = null);
      }
      if (this.webRTCAdaptor.localStream) {
        this.webRTCAdaptor.localStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      if (this.webRTCAdaptor.desktopStream) {
        this.webRTCAdaptor.desktopStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      Object.keys(this.webRTCAdaptor).forEach(k => {
        if (k !== "callback" && k !== "callbackError") {
          this.webRTCAdaptor[k] = null
        }
      });
      this.webRTCAdaptor = null;
    }
  }


  public reJoinSession() {
    this.joiningSession = true;
    setTimeout(() => {
      if(this.retryCount > 2) {
        this.isConnectionDropped$$.next(true);
      }else {
        this.retryCount++;
        this.joinSession(this.roomName, '', this.localStreamId)
      }
    }, 5000);
  }

  public leaveSession() {
    this.isLeftSession = true;
    this.showLoader$$.next(false)
    this.remoteStreams$$.next([]);
    if (this.webRTCAdaptor) {
      if (this.webRTCAdaptor.localStream) {
        this.webRTCAdaptor.localStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      if (this.webRTCAdaptor.webSocketAdaptor) {
        this.webRTCAdaptor.closeWebSocket();
      }
    }
    this.setInitialState();
  }
}
