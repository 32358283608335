<div class="add-question-wrapper">
    <div class="header">
        <div>Add a question</div>
        <div (click)="close()"> <img src="../../../assets/close.svg" /></div>
    </div>

    <div class="form">
        <form [formGroup]="questionForm">
            <div class="name">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Question #{{questionLength}}</mat-label>
                    <textarea rows="5" matInput placeholder="Enter question" formControlName="question" autocomplete="off"></textarea>
                    <div class="photo-name">
                        <mat-icon>
                            <div class="add" (click)="uploadImage()">
                                <img src="../../../assets/add-photo.svg" />
                            </div>
                        </mat-icon>
                    </div>
                    <mat-error *ngIf="!questionForm.controls['question'].valid && questionForm.controls['question'].touched && imgUrl === '' ">Question description is required</mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="imgUrl != '' " class="uploaded-photo">
                <div class="image">
                    <img [src]="imgUrl" />
                </div>
                <div class="cross" (click)="removeImage()"><img src="../../../assets/cross.svg" /></div>
            </div>

            <div class="group">
                <div class="type">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Type</mat-label>
                        <mat-select placeholder="Select type of question" formControlName="type">
                            <mat-option *ngFor="let type of questionType" [value]="type">
                                {{type}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!questionForm.controls['type'].valid && questionForm.controls['type'].touched">
                            Please select question type
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="marks">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Marks</mat-label>
                        <input matInput placeholder="Enter max marks" formControlName="marks" autocomplete="off" type="number">
                        <mat-error *ngIf="!questionForm.controls['marks'].valid && questionForm.controls['marks'].touched">Maximum marks can't be empty</mat-error>
                    </mat-form-field>
                </div>

                <div class="note">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Note</mat-label>
                        <textarea matInput rows="1" placeholder="Add a note (optional)" formControlName="notes" autocomplete="off"></textarea>
                        <mat-error *ngIf="!questionForm.controls['notes'].valid && questionForm.controls['notes'].touched">Note is required</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <ng-container *ngIf="optionList && optionList.length > 0">
        <ng-container *ngIf="questionForm.controls['type'].value && questionForm.controls['type'].value.toLowerCase() === 'multiple choice'; else single">
            <div *ngFor="let option of optionList; let i = index">
                <div class="optionList" [ngClass]="{ 'selected' : option.isCorrect}">
                    <div *ngIf="option.image != '' " class="uploaded-photo">
                        <div class="image">
                            <img [src]="option.image" />
                        </div>
                        <div class="cross" (click)="removeOptionImage(i)"><img src="../../../assets/cross.svg" /></div>
                    </div>
                    <div class="options" [ngClass]="isAdminTheme ? 'admin-options': ''">
                        <mat-checkbox [color]="'accent'" [checked]="option.isCorrect" (change)="optionSelect($event, option, i)"></mat-checkbox>
                        <mat-form-field appearance="outline">
                            <input matInput placeholder="Option {{i+1}}" [(ngModel)]="option.option">
                            <div class="photo">
                                <mat-icon>
                                    <div class="add" (click)="uploadOptionimage(i)">
                                        <img src="../../../assets/add-photo.svg" />
                                    </div>

                                    <div (click)="deleteOption(option, i)" class="delete"><img src="../../../assets/delete-option.svg" /> </div>
                                </mat-icon>
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #single>
            <div *ngFor="let option of optionList; let i = index">
                <div class="optionList" [ngClass]="{ 'selected' : option.isCorrect}">
                    <div *ngIf="option.image != '' " class="uploaded-photo">
                        <div class="image">
                            <img [src]="option.image" />
                        </div>
                        <div class="cross" (click)="removeOptionImage(i)"><img src="../../../assets/cross.svg" /></div>
                    </div>
                    <div class="options" [ngClass]="isAdminTheme ? 'admin-options': ''">
                        <mat-radio-button [color]="'accent'" [checked]="option.isCorrect" (change)="radioSelect(option, i)"></mat-radio-button>
                        <mat-form-field appearance="outline">
                            <input matInput placeholder="Option {{i+1}}" [(ngModel)]="option.option">
                            <div class="photo">
                                <mat-icon>
                                    <div class="add" (click)="uploadOptionimage(i)">
                                        <img src="../../../assets/add-photo.svg" />
                                    </div>

                                    <div (click)="deleteOption(option, i)" class="delete"><img src="../../../assets/delete-option.svg" /> </div>
                                </mat-icon>
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>

    <div class="option">
        <div (click)="addOption()"> <img [src]="addOptionIconAsset" /></div>
        <div (click)="addOption()" class="txt" [ngClass]="isAdminTheme ? 'admin-color': ''">Add Option</div>
    </div>

    <ng-container>
        <div class="action">
            <div class="cancel">
                <button [ngClass]="isAdminTheme ? 'admin-transparent-button': ''" mat-flat-button type="submit" (click)="cancel()">{{isEdit ? 'DELETE' : 'CANCEL'}}</button>
            </div>
            <div class="grp">
                <div class="save">
                    <button mat-flat-button type="submit" color="primary" (click)="save('save')">SAVE</button>
                </div>
                <div class="more">
                    <button mat-flat-button type="submit" color="primary" (click)="save('add')">SAVE & ADD MORE</button>
                </div>
            </div>
        </div>
    </ng-container>
</div>