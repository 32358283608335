import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsDetailComponent } from '../settings-detail/settings-detail.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Subscription, Observable } from 'rxjs';
import { FindSchoolUserRoleService } from 'src/app/services/find-school-user-role.service';
import { LabelService } from 'src/app/services/label.service';
import { COURSE_CATEGORY_TITLE } from 'src/app/Constants/Constant';
import { CoursesService } from 'src/app/services/courses.service';

export interface DialogData {
  title: 'standards' | 'subjects' | 'sessions' | 'sections',
  name?: string,
  type: 'add' | 'edit',
  settingId?: string,
}

@Component({
  selector: 'app-crud-settings',
  templateUrl: './crud-settings.component.html',
  styleUrls: ['./crud-settings.component.scss']
})
export class CrudSettingsComponent implements OnInit, OnDestroy {
  title: string;
  profileForm: FormGroup;
  name: any;
  btnLabel: string;
  from: any;
  to: any;
  crudType: string;
  roleType: string;
  settingID: string;
  private subscriptions = new Subscription();
  labelData = {};
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<SettingsDetailComponent>,
    public settingService: SettingsService,
    public userRole: FindSchoolUserRoleService,
    public snackbarService: SnackbarService,
    private coursesService: CoursesService,
    private labelService: LabelService
  ) { }

  ngOnInit(): void {
    this.name = this.data.name ? this.data.name : null;
    this.labelService.labels$.subscribe(data => {
      this.labelData = data
      this.title = this.data.title;
      this.btnLabel = this.data.type === 'edit' ? 'SAVE' : `ADD`;
      this.title === 'session' ? this.buildSessionForm() : this.buildForm();
    })
    this.dialogRef.updatePosition({ right: `0px` });
    this.crudType = this.data.type;
    this.settingID = this.data.settingId;
    this.userRole.userRole$.subscribe(role => {
      this.roleType = role;
    })
  }

  buildForm() {
    this.profileForm = this.fb.group({
      detail: [this.name, Validators.required]
    })
  }

  buildSessionForm() {
    this.from = this.name ? this.name.split('-')[0] : null;
    this.to = this.name ? this.name.split('-')[1] : null;
    this.profileForm = this.fb.group({
      from: [this.from, Validators.required],
      to: [this.to, Validators.required]
    })
  }

  crud() {
    if (this.profileForm.valid) {
      if (this.title.toLowerCase() !== 'session') {
        this.settingCrud();
      } else {
        this.sessionCrud();
      }
    } else {
      this.snackbarService.openSnackbar('Please fill all details')
    }
  }

  settingCrud() {
    let createSetting;
    const requestBody = {
      name: this.profileForm.controls['detail'].value.trim()
    }

    if (this.title.toLowerCase() === 'standard') {
      createSetting = this.settingService.createStandard(requestBody);
    } else if (this.title.toLowerCase() === 'subject') {
      createSetting = this.settingService.createSubject(requestBody);
    } else if (this.title.toLowerCase() === 'section') {
      createSetting = this.settingService.createSection(requestBody);
    } else if (this.title.toLowerCase() === COURSE_CATEGORY_TITLE) {
      createSetting = this.coursesService.createCourseCategory(requestBody);
    }

    if (this.crudType === 'add') {
      this.subscriptions = createSetting.subscribe(
        (res) => {
          this.snackbarService.openSnackbar(`${this.title} successfully created`);
          this.closeDIalog(res);
        },
        (err) => this.snackbarService.openSnackbar(err.error.error)
      )
    }

    if (this.crudType === 'edit') {
      this.editSettings();
    }
  }

  editSettings() {
    let updateSetting: Observable<any>;
    const requestBody = {
      name: this.profileForm.controls['detail'].value.trim()
    }

    if (this.title.toLowerCase() === 'standard') {
      updateSetting = this.settingService.updateStandard(this.settingID, requestBody);
    } else if (this.title.toLowerCase() === 'subject') {
      updateSetting = this.settingService.updateSubject(this.settingID, requestBody);
    } else if (this.title.toLowerCase() === 'section') {
      updateSetting = this.settingService.updateSection(this.settingID, requestBody);
    } else if (this.title.toLowerCase() === COURSE_CATEGORY_TITLE) {
      updateSetting = this.coursesService.updateCourseCategory(this.settingID,requestBody);
    }

    this.subscriptions = updateSetting.subscribe(
      (res) => {
        this.snackbarService.openSnackbar(`${this.title} updated succesfully`)
        this.closeDIalog(res)
      },
      (err) => {
        this.snackbarService.openSnackbar(err.error.error);
      }
    )
  }

  sessionCrud() {
    const requestBody = {
      starting: this.profileForm.controls['from'].value.trim(),
      ending: this.profileForm.controls['to'].value.trim()
    }

    if (this.crudType === 'add') {
      this.subscriptions = this.settingService.createSession(requestBody).subscribe(
        (res) => {
          this.snackbarService.openSnackbar('Session created succesfully');
          this.closeDIalog(res);
        },
        (err) => this.snackbarService.openSnackbar(err.error.error),
      )
    }

    if (this.crudType === 'edit') {
      this.subscriptions = this.settingService.updateSession(this.settingID, requestBody).subscribe(
        (res) => {
          this.snackbarService.openSnackbar('Session updated succesfully');
          this.closeDIalog(res);
        },
        (err) => this.snackbarService.openSnackbar(err.error.error)
      )
    }
  }

  closeDIalog(res?) {
    this.dialogRef.close(res);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
