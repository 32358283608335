<div class="dashboard-wrapper admin-theme">
    <div class="create-header">
        <div>{{label['createschool']}}</div>
        <div (click)="closeDialog()" class="close"><img src="../../../assets/close.svg"/></div>
    </div>

    <div class="header">Enter {{label['school']}} details</div>

    <div class="form">
        <form  [formGroup]="schoolForm">
            <div class="name">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Enter Name" formControlName="schoolName" autocomplete="off">
                    <mat-error *ngIf="!schoolForm.controls['schoolName'].valid && schoolForm.controls['schoolName'].touched">{{ getNameError() }}</mat-error>
                </mat-form-field>
            </div>

            <!-- <div class="school-type" >
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>{{label['selectschool']}}</mat-label>
                    <mat-select placeholder="Select Type" formControlName="schoolType" >
                        <mat-option *ngFor="let school of schools" [value]="school">
                          {{school}}
                        </mat-option>
                      </mat-select>
                    <mat-error *ngIf="!schoolForm.controls['schoolType'].valid && schoolForm.controls['schoolType'].touched">
                         {{label['pleaseselectschool']}}
                    </mat-error>
                </mat-form-field>
            </div> -->

            <div class="user" >
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Address</mat-label>
                    <input matInput placeholder="Enter Address" formControlName="address" autocomplete="off">
                    <mat-error *ngIf="!schoolForm.controls['address'].valid && schoolForm.controls['address'].touched">{{ getAddressError() }}</mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div [ngStyle]="isImageUploaded === false && {'border': '2px solid #ff8156', 'border-radius': '12px', 'color': '#ff8156'}">
        <app-upload [imgUrl]="'assets/school.svg'" [imgType]="'logo'" [text]="'UPLOAD SCHOOL LOGO'" (url)="onImageUpload($event)"></app-upload>
    </div>

    <div class="btn">
        <button mat-flat-button color="primary" (click)="createSchool()" [disabled]="isSubmitted">{{label['createschool']}}</button>
    </div>

</div>
