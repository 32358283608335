import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { FormDataService } from 'src/app/services/form-data.service';

export const questionKey = 'questions';
export const questionFile = 'questions.json';
export const answerFile = 'answers.json';
export const answerKey = 'answers';
@Injectable({
  providedIn: 'root'
})
export class TestService {

  public createTestRoute() {
    return `${environment.apiUrl}class/test/create`
  }

  public getTestRoute(testID) {
    return `${environment.apiUrl}class/test/details/${testID}`
  }

  public getTestQuestionRoute(testID) {
    return `${environment.apiUrl}student/test/get/${testID}`
  }

  public solutionRoute() {
    return `${environment.apiUrl}class/test/solution`
  }

  public statusRoute(id) {
    return environment.apiUrl + 'class/activity/upload-status/' + `${id}`;
  }

  public deleteSolutionRoute(testID) {
    return environment.apiUrl + '/class/test/solution/' + `${testID}`;
  }

  public addCommentRoute() {
    return environment.apiUrl + '/class/test/comments';
  }

  public shareCommentRoute(testID,studentID ) {
    return environment.apiUrl + `class/test/comments/${testID}/student/${studentID}`;
  }

  constructor(public crud: CrudService, public formdata: FormDataService) { }

  createTest(body, questions, answers) {
    const questionJSON = this.createJSONFile(questions, questionFile);
    const answerJSON = this.createJSONFile(answers, answerFile);
    let file = new FormData();
    Object.keys(body).forEach(ele => {
      file.append(ele, body[ele])
    })
    file.append(questionKey, questionJSON);
    file.append(answerKey, answerJSON)
    return this.crud.post(this.createTestRoute(), file);
  }

  createJSONFile(questions, fileName) {
    questions = JSON.stringify(questions);
    const blob = new Blob([questions], { type: 'application/json' });
    const file2 = new File([ blob ], fileName);
    console.log(file2)
    return file2;
  }

  getTestDetails(testID) {
    return this.crud.get(this.getTestRoute(testID));
  }

  getTestQuestions(testID) {
    return this.crud.get(this.getTestQuestionRoute(testID))
  }

  mapQuestionWithAnswer(questions) {
      questions[0] = JSON.parse(questions[0]);
      const answers = JSON.parse(questions[1]);
      let questionList = [];
      questions[0].map(question => {
        if(!question.instruction) {
          questionList.push(question)
        }
      })
      questionList.map((question, index) => {
        question.options.map((option, i) => {
          if(answers[index].correctAnswers.includes(i)) {
            question.options[i].isCorrect = true;
          } else {
            question.options[i].isCorrect = false;
          }
        })
      })
      return questionList;
  }


  uploadSolution(body) {
    const formData = this.formdata.createFormData(body);
    return this.crud.post(this.solutionRoute(),formData);
  }

  fetchFromGcs(url) {
    return this.crud.get(url);
  }

  deleteSolutions(testID, body) {
    return this.crud.delete(this.deleteSolutionRoute(testID),body);
  }

  addTestComment(body) {
    return this.crud.post(this.addCommentRoute(), body);
  }

  shareComment(testID, studentID) {
    return this.crud.patch(this.shareCommentRoute(testID, studentID));
  }
}
