import { Component, Input, OnInit } from '@angular/core';
import { LiveClassModel } from 'src/app/models/live-class-models';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LiveClassService } from '../../services/live-class.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RELOAD_LIVE_CLASS_PREV_PAGE, DO_RELOAD, DO_NOT_RELOAD, RELOAD_LIVE_CLASS_PAGE } from 'src/app/Constants/Constant';
@Component({
  selector: 'app-student-live-class',
  templateUrl: './student-live-class.component.html',
  styleUrls: ['./student-live-class.component.scss']
})
export class StudentLiveClassComponent implements OnInit {
  @Input() data: LiveClassModel[] = [];
  @Input() columns: string[];
  public currentDate;
  constructor(
    public liveClassService: LiveClassService,
    public snackbar: SnackbarService,
    public localStorage: LocalStorageService,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    let reloadPage = this.localStorage.getItem(RELOAD_LIVE_CLASS_PREV_PAGE)
    if (reloadPage == DO_RELOAD) {
      this.localStorage.setItem(RELOAD_LIVE_CLASS_PREV_PAGE, DO_NOT_RELOAD);
      window.location.reload();
    } else {
      this.localStorage.setItem(RELOAD_LIVE_CLASS_PAGE, DO_RELOAD);
      this.localStorage.setItem(RELOAD_LIVE_CLASS_PREV_PAGE, '');
    }
    this.currentDate = new Date();
  }

  onClassJoin(classID) {
    this.liveClassService.joinClass(classID).subscribe(
      (res) => {
        window.open(res.link, '_blank');
      },
      (err) => {
        this.snackbar.openSnackbar(err.error.error)
      }
    )
  }

}
