import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { nameregex } from 'src/app/Constants/Constant';
import { MatDialogRef } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { SchoolService } from 'src/app/services/api/school.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LabelService } from 'src/app/services/label.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-school',
  templateUrl: './create-school.component.html',
  styleUrls: ['./create-school.component.scss']
})
export class CreateSchoolComponent implements OnInit {
  schoolForm: FormGroup;
  url;
  schools = [
    'Small (Upto 100 Students)',
    'Medium (101 to 200 Students)',
    'Large (201 to 300 Students)'
  ];
  Imageurl: string | ArrayBuffer;
  isSubmitted = false;
  isImageUploaded: boolean;
  subscription = new Subscription();
  label;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DashboardComponent>,
    private schoolService: SchoolService,
    public _snackbar: SnackbarService,
    private labelService: LabelService
  ) { }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: `0px` });
    this.buildForm();
    this.subscription = this.labelService.labels$.subscribe(data => {
      this.label = data
    })
  }

  buildForm() {
    this.schoolForm = this.fb.group({
      schoolName: [null, [Validators.required, Validators.maxLength(1000)]],
      address: [null, [Validators.required, Validators.maxLength(1000)]],
      // 'schoolType': [null, [Validators.required]],
    });
  }

  getNameError() {
    if (this.schoolForm.get('schoolName').hasError('required')) {
      return 'Name is required';
    } else if (this.schoolForm.invalid) {
      return 'name length cannot exceed 1000 characters';
    } else {
      return null;
    }
  }

  getAddressError() {
    if (this.schoolForm.get('address').hasError('required')) {
      return 'Address can\'t be empty';
    } else if (this.schoolForm.invalid) {
      return 'address length cannot exceed 1000 characters';
    } else {
      return null;
    }
  }

  onImageUpload(url) {
    this.Imageurl = url ? url : null;
    this.isImageUploaded = url ? true : false;
  }

  closeDialog(requestBody?) {
    requestBody ? this.dialogRef.close(requestBody) : this.dialogRef.close();
  }

  createSchool() {
    this.isSubmitted = true;
    if (this.schoolForm.valid) {
      const requestBody = {
        name: this.schoolForm.controls['schoolName'].value.trim(),
        // type: this.schoolForm.controls['schoolType'].value.trim(),
        address: this.schoolForm.controls['address'].value.trim(),
        logo: this.Imageurl,
        creatorID: localStorage.getItem('id')
      }
      this.schoolService.create(requestBody).subscribe(
        (res: any) => {
          this._snackbar.openSnackbar('school succesfully created');
          this.isSubmitted = false;
          this.closeDialog(requestBody);
        }, (err) => {
          this.isSubmitted = false;
          this._snackbar.openSnackbar(err.error.error);
        }
      );
      return;
    }
    if (!this.schoolForm.controls['address'].value) {
      this.schoolForm.controls['address'].setErrors({ 'valid': false });
      this.schoolForm.controls['address'].markAllAsTouched();
    }
    if (!this.schoolForm.controls['schoolName'].value) {
      this.schoolForm.controls['schoolName'].setErrors({ 'valid': false });
      this.schoolForm.controls['schoolName'].markAllAsTouched();
    }
    if (!this.schoolForm.controls['schoolType'].value) {
      this.schoolForm.controls['schoolType'].setErrors({ 'valid': false });
      this.schoolForm.controls['schoolType'].markAllAsTouched();
    }
    if (!this.Imageurl) {
      this.isImageUploaded = false;
    }
    this.isSubmitted = false;
    this._snackbar.openSnackbar('please fill all details');
  }
}
