import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-question-list-view',
  templateUrl: './question-list-view.component.html',
  styleUrls: ['./question-list-view.component.scss']
})
export class QuestionListViewComponent implements OnInit {
  totalQues : Number;
  detailView : boolean = true;
  currentQuestion;
  totalAttempt: number = 0;
  @Output() questionSelected = new EventEmitter();
  @Input() questions;

  constructor() { }

  ngOnInit(): void {
    this.totalQues = this.questions.length;
    this.currentQuestion = this.questions[0];
    this.questionSelected.emit(this.currentQuestion)
  }

  selectQuestion(question, index) {
    this.getTotalAttmept();
    this.currentQuestion = question;
    this.questionSelected.emit(question);
  }

  trackByQuestionByID(index: number, question: any) {
    return question && question.question;
  }

  changeView(view: 'bullet' | 'grid') {
    this.detailView = (view === 'grid') ? false : true;
  }

  getStatus(question): string {
    if(!question) {
      return 
    }
    if(question.number === this.currentQuestion.number) {
      return 'current'
    }
    const isAttempted = question.options.filter(option => option.isSelected);
    return isAttempted.length > 0 ? 'attempted' : 'unattempted'
  }

  getTextColor(status) {
    if(status === 'attempted') 
      return 'attempt-text'
    else if(status === 'unattempted')
      return 'pending-text'
    else
      return 'current-text'
  }

  getTotalAttmept() {
    this.totalAttempt = 0;
    this.questions.filter(question => {
      this.totalAttempt = question.options.filter(option => option.isSelected).length > 0 ? this.totalAttempt + 1 : this.totalAttempt;
    })
  }

  goToQuestion(type : 'previous' | 'next') {
    this.getTotalAttmept();
    if(type === 'previous') {
      if(!this.currentQuestion.number) {
        return
      }
      const newIndex = this.currentQuestion.number - 1;
      this.currentQuestion = this.questions[newIndex];
    } else if(type === 'next') {
      if(this.currentQuestion.number + 1 === this.totalQues) {
        return
      }
      const newIndex = this.currentQuestion.number + 1;
      this.currentQuestion = this.questions[newIndex];
    } else {
      return
    }
    this.questionSelected.emit(this.currentQuestion)
  }

}
