<ng-container *ngIf="isLoading; else class">
    <div class="spinner">
        <app-spinner></app-spinner>
    </div>
</ng-container>

<ng-template #class>
    <div class="wrapper">
        <ng-container *ngIf="!isClassCreated">
            <div class="class-header">
                <div *ngIf="labelData" class="text">{{labelData['classes']}}</div>
                <div class="btn">
                    <button *ngIf="labelData" mat-flat-button color="primary" (click)="createClass()">{{labelData['createclass']}}</button>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="isClassCreated">
            <div class="header">
                <div class="left">
                    {{labelData['classes']}} ({{filteredList.length}})
                </div>
                <div class="right">
                    <ng-select (change)="filterByStatus($event)" [clearable]="false" [searchable]="false" [(ngModel)]="status">
                        <ng-option *ngFor="let car of statusList" [value]="car">
                            {{car == 'All Classes' ? labelData['allclasses'] : car }}</ng-option>
                    </ng-select>
                    <ng-select (change)="filterBySubject($event)" [clearable]="false" [searchable]="false" [(ngModel)]="subjects">
                        <ng-option *ngFor="let car of allSubjectsList" [value]="car">
                            {{car == 'All Subjects' ? labelData['allsubjects'] : car }}</ng-option>
                    </ng-select>
                    <ng-select (change)="filterByStandard($event)" [clearable]="false" [searchable]="false" [(ngModel)]="standards">
                        <ng-option *ngFor="let car of allStandardsList" [value]="car">
                            {{car == 'All Standards' ? labelData['allstandards'] : car }}</ng-option>
                    </ng-select>

                    <div *ngIf="!(accessRights && !accessRights.liveClasses)" class="btn join">
                        <button mat-flat-button color="primary" (click)="joinClass()">{{labelData['joinliveclass']}}</button>
                    </div>

                    <div class="btn">
                        <button mat-flat-button color="primary" (click)="createClass()">{{labelData['createclass']}}</button>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!isClassCreated; else cards">
            <div *ngIf="!isSchoolGroupSelected" class="noclass vertically-centered">
                No School Group Selected
                <button mat-flat-button color="primary" (click)="switchSchoolGroup()" class="school-group-button">SWITCH SCHOOL GROUP</button>
            </div>

            <div *ngIf="isSchoolGroupSelected" class="content bordered-box">
                <div class="empty bordered-box">
                    <div class="txt">
                        <div>{{labelData['createanewclass']}}</div>
                    </div>
                    <div class="list">
                        <div *ngFor="let task of tasks" class="task">
                            <div class="tick">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
                                  <path fill="#a1b6c0"
                                    d="M13.302.905c.472-.44 1.277-1.183 2-.798 1.86.99-.5 3.301-1.25 4.236-1.194 1.513-2.444 2.971-3.666 4.456-.944 1.128-1.777 2.366-2.805 3.466-.583.633-1.582 1.788-2.554 1.733-.806-.027-1.5-.605-2.027-1.155C1.75 11.55.056 9.515 0 7.617c-.027-1.458 1.306-1.018 2.11-.413 1.112.853 1.973 1.898 2.972 2.86.445-.825 1.167-1.54 1.833-2.2 1.166-1.183 2.222-2.393 3.36-3.603 1.028-1.1 1.944-2.31 3.027-3.356zM2.24 9.873l.271.312c.622.698 1.175 1.205 1.907 1.81.563.084.796.214 1.03.343C4.203 10.733 3.134 9.91 2.24 9.873zm12.634-7.76C13.046 2.997 8.223 7.968 8 8.833L10.094 7c1.001-.543 1.45-1.509 2.3-2.183.2-.533 2.306-2.503 2.48-2.704z" />
                                </svg>
                            </div>
                            <div class="text">
                                {{task}}
                            </div>
                        </div>
                    </div>
                    <div class="img">
                        <img src="../../../assets/class.svg" />
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #cards>
            <div class="card-content bordered-box">
                <ng-container *ngIf="filteredList.length === 0">
                    <div class="noclass">No {{labelData['class']}} Found</div>
                </ng-container>

                <ng-container>
                    <div class="full bordered-box" *ngIf="filteredList.length > 0">
                        <app-class-card [classes]="filteredList"></app-class-card>
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </div>
</ng-template>
