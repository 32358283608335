import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SUCCESS_CALLBACK_INFOS, STREAM_STATE_BROADCAST_TIME } from 'src/app/Constants/Constant';
import { BehaviorSubject, Observable } from 'rxjs';
import { CrudService } from 'src/app/services/crud.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class StudentPublisherService {

  constructor(private crud: CrudService,
    private snackBar: SnackbarService) { }

  //streaming constraints and webrtc adaptor variable
  private peerConnectionConfig: any;
  private mediaConstraints: any;
  private sdpConstraints: any;
  private webRTCAdaptor: any;


  //setting global media constraint variable
  private setGlobalMediaConstraints() {
    mediaConstraints = this.mediaConstraints;
  }

  //setting streaming constraints
  public setStreamingConstraints(peerConnectionConfig: any, mediaConstraints: any, sdpConstraints: any) {
    this.peerConnectionConfig = peerConnectionConfig;
    this.mediaConstraints = mediaConstraints;
    this.sdpConstraints = sdpConstraints;
    this.setGlobalMediaConstraints();
  }

  //room related information
  private roomName: string;
  private localVideoElId: string;
  private localStreamId: string;
  public localStream: any;

  //to check if publishing or not
  private isPublishing$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get isPublishing$(): Observable<boolean> {
    return this.isPublishing$$.asObservable()
  }

  //stream state related variables
  private isCameraOn$$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public get isCameraOn$(): Observable<boolean> {
    return this.isCameraOn$$.asObservable()
  }

  private isMicOn$$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public get isMicOn$(): Observable<boolean> {
    return this.isMicOn$$.asObservable()
  }

  //setinterval reference for stream state.
  public streamStateBroadcaster: any;


  //joing session request 
  public joinSession(roomName: string, localVideoElId: string, localStreamId: string) {
    this.roomName = roomName;
    this.localVideoElId = localVideoElId;
    this.localStreamId = localStreamId;
    this.webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: environment.antMediaPublishUrl,
      mediaConstraints: this.mediaConstraints,
      peerconnection_config: this.peerConnectionConfig,
      localVideoId: this.localVideoElId,
      sdp_constraints: this.sdpConstraints,
      bandwidth: 500,
      isPlayMode: true,
      callback: this.successCallback,
      callbackError: this.errorCallback,
    });
  }

  private successCallback = (info, obj = null) => {
    switch (info) {
      case SUCCESS_CALLBACK_INFOS.Initialized:
        this.initSuccessCallback(info);
        break;
      case SUCCESS_CALLBACK_INFOS.JoinedTheRoom:
        this.roomJoinSuccessCallback(info, obj);
        break;
      case SUCCESS_CALLBACK_INFOS.DataChannelOpened:
        this.dataChannelOpenedCallback(obj);
        break;
      case SUCCESS_CALLBACK_INFOS.DataChannelClosed:
        this.dataChannelClosedCallback(obj);
        break;
      case SUCCESS_CALLBACK_INFOS.PublishStarted:
        this.publishStartedCallback(obj);
        break;
      case SUCCESS_CALLBACK_INFOS.PubilshFinished:
        this.publishFinishedCallback();
        break;
    }
  }

  private errorCallback = (error, message) => {
    if (error == "TypeError" && this.webRTCAdaptor) {
      if (this.webRTCAdaptor.roomTimerId) {
        clearInterval(this.webRTCAdaptor.roomTimerId);
      }
      this.resetWebRTCAdaptor();
    }
    if (error === "WebSocketNotConnected" || error.target instanceof WebSocket) {
      clearInterval(this.webRTCAdaptor.roomTimerId);
      this.resetWebRTCAdaptor();
    }
    console.log("[student-publisher-service] errorCallback err: ", error, 'message: ', message);
  }

  private initSuccessCallback(info: any) {
    console.log("[student-publisher-service]: Adaptor Initialized, And WebSocketConnection Established")
    console.log("[student-publisher-service]: Now Joining Room: ", this.roomName, "with stream id: ", this.localStreamId)
    this.webRTCAdaptor.joinRoom(this.roomName, this.localStreamId);
  }

  private roomJoinSuccessCallback(info: any, obj: any) {
    console.log("[student-publisher-service]: Successfully Joined Room, Details: ", obj);
    console.log("[student-publisher-service]: Starting Publishing");
    this.startPublishing();
  }

  //start publishing will be called when ever hand raise request is accepted.
  private startPublishing() {
    this.webRTCAdaptor.isPlayMode = false;
    console.log("[student-publisher-service]: Getting Student Stream To Pubilsh");
    navigator.mediaDevices.getUserMedia(this.mediaConstraints)
      .then((stream) => {
        console.log("[student-publisher-service]: Student Gave the access for stream.");
        this.webRTCAdaptor.localStream = stream;
        this.localStream = stream;
        console.log("[student-publisher-service]: Requesting for a token to publish stream .", this.localStreamId);
        this.crud.get(environment.socketServerBaseUrl + '/class/live/' + this.roomName + '/stream/' + this.localStreamId + '?token-type=publish').subscribe(
          (resp: any) => {
            console.log("[student-publisher-service]: Successfully Get The Token.")
            if (this.webRTCAdaptor) {
              this.webRTCAdaptor.publish(this.localStreamId, resp.token);
            }
          }, (err) => {
            this.snackBar.openSnackbar("Unable to get publish token from server, lowering the hand")
            console.log("[student-publisher-service]: Error in getting token for the stream: ", this.localStreamId, "err: ", err);
          }
        );
      }).catch((err) => {
        console.log("[student-publisher-service]: Student Denied access for camera, mic or other stream related error: ", err);
        console.log("[student-publisher-service]: setting isPublishing to false")
        this.isPublishing$$.next(false);
      })
  }

  //mic and camera related functions when publishing
  public turnOnMic() {
    this.webRTCAdaptor.unmuteLocalMic();
    this.isMicOn$$.next(true);
    this.sendStreamState();
  }

  public turnOffMic() {
    this.webRTCAdaptor.muteLocalMic();
    this.isMicOn$$.next(false);
    this.sendStreamState();
  }

  public turnOnCamera() {
    this.webRTCAdaptor.turnOnLocalCamera();
    this.isCameraOn$$.next(true);
    this.sendStreamState();
  }

  public turnOffCamera() {
    this.webRTCAdaptor.turnOffLocalCamera();
    this.isCameraOn$$.next(false);
    this.sendStreamState();
  }

  //data channel related functionality (to show thumbnail when camera not turned on of user)
  private dataChannelOpenedCallback = (streamId) => {
    console.log("[student-publisher-service]: data channel opened for stream", streamId);
    if (streamId === this.localStreamId) {
      console.log("[student-publisher-service]: starting the broadcaster for stream info for stream: ", streamId);
      this.streamStateBroadcaster = setInterval(() => {
        this.sendStreamState();
      }, STREAM_STATE_BROADCAST_TIME)
    }
  }

  private sendStreamState = () => {
    if (this.isPublishing$$.value) {
      this.webRTCAdaptor.sendData(this.localStreamId, JSON.stringify({
        event: "STREAM_INFO",
        data: {
          streamId: this.localStreamId,
          isCameraOn: this.isCameraOn$$.value,
          isMicOn: this.isMicOn$$.value,
          isSharingScreen: false,
        }
      }));
    }
  }

  private dataChannelClosedCallback = (streamId) => {
    console.log("[student-publisher-service]: data channel closed for stream", streamId);
    if (streamId === this.localStreamId) {
      clearInterval(this.streamStateBroadcaster);
      this.streamStateBroadcaster = null;
    }
  }

  private publishStartedCallback(info: any) {
    console.log('[student-publisher-service]: publish started successfully, info: ', info);
    this.isPublishing$$.next(true);
  }

  private publishFinishedCallback() {
    console.log("[student-publisher-service]: publish finished for stream ", this.localStreamId);
    this.isPublishing$$.next(false);
    //if publish finished then lower the hand and reset the webrtc adaptor.
    this.leaveSession();
  }

  public leaveSession() {
    console.log("[student-publisher-service]: leaving session");
    this.isPublishing$$.next(false);
    this.isCameraOn$$.next(true);
    this.isMicOn$$.next(true);
    if (this.streamStateBroadcaster) {
      clearInterval(this.streamStateBroadcaster);
      this.streamStateBroadcaster = null;
    }
    if (this.localStream) {
      this.localStream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
    if (this.webRTCAdaptor) {
      try {
        this.webRTCAdaptor.leaveFromRoom(this.roomName);
      } catch (e) {
        console.log("[student-publisher-service]: error leaving room for adaptor", e);
      }
      if (this.webRTCAdaptor.localStream) {
        this.webRTCAdaptor.localStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      if (this.webRTCAdaptor.webSocketAdaptor) {
        try {
          this.webRTCAdaptor.closeWebSocket();
        } catch (e) {
          console.log("[student-publisher-service]: error closing websocket for adaptor", e);
        }
      }
    }
    this.setInitialState();
  }

  public setInitialState = () => {
    this.resetWebRTCAdaptor();
  }

  private resetWebRTCAdaptor() {
    if (this.webRTCAdaptor) {
      if (this.webRTCAdaptor.roomTimerId) {
        clearInterval(this.webRTCAdaptor.roomTimerId);
      }
      if (this.webRTCAdaptor.webSocketAdaptor) {
        Object.keys(this.webRTCAdaptor.webSocketAdaptor).forEach(k => this.webRTCAdaptor.webSocketAdaptor[k] = null);
      }
      if (this.webRTCAdaptor.localStream) {
        this.webRTCAdaptor.localStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      if (this.webRTCAdaptor.desktopStream) {
        this.webRTCAdaptor.desktopStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      Object.keys(this.webRTCAdaptor).forEach(k => this.webRTCAdaptor[k] = null);
      this.webRTCAdaptor = null;
    }
  }


}
