import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public setItem(key: string, val: any): void {
    return localStorage.setItem(key, val)
  }

  public getItem(key: string): string {
    return localStorage.getItem(key)
  } 

  public removeItem(key: string): void {
    localStorage.removeItem(key)
  }

  public clear(): void {
    localStorage.clear();
  }

  constructor() { }
}
