import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { MaterialModule } from '../material/material.module';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ChangePinComponent } from './change-pin/change-pin.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileDetailsComponent,
    EditProfileComponent,
    ChangePinComponent,
  ],
  exports: [
    ProfileDetailsComponent,
    ProfileComponent,
    SharedComponentsModule
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule
  ]
})
export class ProfileModule { }
