<div class="drawer">
    <div class="side-window-header">
        <img (click)="closeSideWindow()" class="close-icon" src="assets/close.svg" width="16px" height="16px" />
        <div class="participants-heading">Participants</div>
    </div>
    <div class="drawer-content">
        <div *ngIf="selfParticipant" class="participant-card">
            <div class="participant-card-profile">
                <div *ngIf="selfParticipant.profilePicture === ''" class="profile-image participant-profile">
                    {{selfParticipant.name.charAt(0)}}
                </div>
                <div *ngIf="selfParticipant.profilePicture" class="profile-image participant-profile">
                    <img [src]="selfParticipant.profileSrc" />
                </div>
                <div>
                    <div class="participant-card-details">
                        <div class="participant-name">{{ selfParticipant.name}} (Me)</div>
                        <div class="joined-time">{{ selfParticipant.joinedAt | joinedTime | async}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngFor="let participant of activeParticipant">
            <div *ngIf="!selfParticipant || (selfParticipant && selfParticipant.id != participant.id)" class="participant-card">
                <div class="participant-card-profile">
                    <div *ngIf="!participant.profilePicture" class="profile-image participant-profile">
                        {{participant.name.charAt(0)}}
                    </div>
                    <div *ngIf="participant.profilePicture" class="profile-image participant-profile">
                        <img [src]="participant.profileSrc" />
                    </div>
                    <!-- <ng-container *ngIf="type === 'teacher'; else studentParticipant"> -->
                    <div>
                        <div class="participant-card-details">
                            <div class="participant-name">{{ participant.name}}</div>
                            <div class="joined-time">{{ participant.joinedAt | joinedTime | async}}</div>
                        </div>
                    </div>
                    <!-- </ng-container> -->
                    <!-- <ng-template #studentParticipant>
                        <div>
                            <div class="participant-card-details">
                                <div class="participant-name">{{allParticipants.get(participant.id).name}}</div>
                                <div class="joined-time">{{participant.joinedAt | joinedTime | async}}</div>
                            </div>
                        </div>
                    </ng-template> -->
                </div>
                <ng-container *ngIf="type === 'teacher'">
                    <div class="participant-card-icons">
                        <div (click)="onAcceptRaisedHand(participant.id)" class="right-margin" *ngIf="participant.isHandRaised && participant.id != acceptedRaisedHand"><img src="assets/hand_raise_inactive.svg" width="18px" height="18px" /></div>
                        <div (click)="onLowerRaisedHand(participant.id)" class="right-margin" *ngIf="participant.isHandRaised"><img src="assets/lower-hand-blue.svg" width="20px" height="20px" /></div>
                        <div><img src="assets/more.svg" height="20px" width="20px" /></div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>