import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostDetailService {

  getAssignmentsDetailRoute(id) {
    return environment.apiUrl + 'class/assignment/details/' + `${id}`
  }

  getMaterialsRoute(id) {
    return environment.apiUrl + 'class/material/details/' + `${id}`
  }
  
  constructor(public crud: CrudService) { }

  getAssignments(postID) {
    return this.crud.get(this.getAssignmentsDetailRoute(postID));
  }

  getMaterials(postID) {
    return this.crud.get(this.getMaterialsRoute(postID));
  }

  
}
