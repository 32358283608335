import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { emailregex, nameregex } from 'src/app/Constants/Constant';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileComponent } from '../profile.component';
import { UserService } from 'src/app/services/user.service';
import { ProfileService } from '../services/profile.service';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';

export const GENDER_LIST = ['Male', 'Female']
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  profileForm: FormGroup;
  Imageurl: string | ArrayBuffer;
  isSubmitted: boolean = false;
  isImageUploaded: boolean;
  genders = GENDER_LIST;
  userType: string;
  userTypes = USER_TYPES;
  constructor(
    public dialogRef: MatDialogRef<ProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public profileService: ProfileService,
    private fb: FormBuilder,
    public themeService: UserService,
    public _snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: `0px` });
    this.userType = this.fetchTheme();
    this.buildForm();
  }

  buildForm() {
    this.profileForm = this.fb.group({
      'name': [this.data.user.name, [Validators.required, Validators.pattern(nameregex)]],
      'userName': [this.data.user.username ? this.data.user.username : '', [Validators.required]],
      'email': [this.data.user.email ? this.data.user.email : '', [Validators.required, Validators.pattern(emailregex)]],
      'gender': [this.data.user.gender ? this.data.user.gender : '', Validators.required],
      'pin': [this.data.user.pin ? this.data.user.pin : '', [Validators.min(1000), Validators.max(9999)]],
    });
    this.Imageurl = this.data.user.profilePicture;
  }

  getErrorName() {
    return this.profileForm.get('name').hasError('required') ? 'Name is required' :
      this.profileForm.get('name').hasError('pattern') ? 'Not a valid name' : null
  }

  getErrorEmail() {
    return this.profileForm.get('email').hasError('required') ? 'Field is required' :
      this.profileForm.get('email').hasError('pattern') ? 'Not a valid email address' : null;
  }

  onImageUpload(url) {
    this.Imageurl = url ? url : null;
    this.isImageUploaded = url ? true : false;
  }

  close() {
    this.dialogRef.close();
  }

  fetchTheme() {
    return this.themeService.getUserInCache('type');
  }

  getTheme() {
    const theme = this.fetchTheme();
    if (theme === 'admin') {
      return 'admin-theme';
    }
    else if (theme === 'teacher') {
      return 'teacher-theme';
    }
    else {
      return 'student-theme';
    }
  }

  getProfilebody() {
    const theme = this.fetchTheme();
    if (theme !== 'student') {
      const body = {
        name: this.profileForm.controls['name'].value,
        username: this.profileForm.controls['userName'].value,
        email: this.profileForm.controls['email'].value && this.profileForm.controls['email'].value,
        profilePicture: this.Imageurl,
      };
      return body;
    } else if (!this.data.studentID) {
      const body = {
        name: this.profileForm.controls['name'].value,
        email: this.profileForm.controls['email'].value,
        profilePicture: this.Imageurl
      }
      return body;
    } else {
      const body = {
        name: this.profileForm.controls['name'].value,
        gender: this.profileForm.controls['gender'].value,
        username: this.profileForm.controls['userName'].value,
        profilePicture: this.Imageurl,
        pin: `${this.profileForm.controls['pin'].value}`
      }
      if (!body.pin) {
        delete (body.pin);
      }
      return body;
    }

  }

  isProfileValid() {
    if (this.data.studentID && (!this.profileForm.controls['gender'].valid || !this.profileForm.controls['name'].valid)) {
      return false;
    }

    if ((this.fetchTheme() === 'student' && !this.data.studentID) &&
      (!this.profileForm.controls['name'].valid || !this.profileForm.controls['email'].valid) ||
      !this.profileForm.controls['pin'].valid) {
      return false;
    }

    if ((this.fetchTheme() === 'teacher' || this.fetchTheme() === 'admin') &&
      (!this.profileForm.controls['name'].valid || !this.profileForm.controls['userName'].valid
        || !this.profileForm.controls['email'].valid)) {
      return false;
    }

    return true;
  }

  save() {
    this.isSubmitted = true;
    if (this.isProfileValid() && this.Imageurl) {
      const body = this.getProfilebody();
      const editUrl = !this.data.studentID ? this.profileService.editProfile(body) :
        this.profileService.editProfile(body, this.data.studentID);
      editUrl.subscribe(
        (res) => {
          this.isSubmitted = false;
          this._snackbar.openSnackbar('Your profile is sucessfully updated!!');
          this.dialogRef.close('true');
        },
        (err) => {
          this.isSubmitted = false;
          this._snackbar.openSnackbar(err.error.error);
        }
      );
    } else {
      this.isSubmitted = false;
    }
  }
}
