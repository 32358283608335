import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  constructor() { }

  createFormData(body) {
    let file = new FormData();
    Object.keys(body).forEach(ele => {
      if(typeof(body[ele]) === 'object') {
        body[ele].forEach(files => {
          file.append(ele, files)
        })
      } else {
        file.append(ele, body[ele])
      }
    })
    return file;
  }
}
