<ng-container *ngIf="isLoading || loadingAuthStatus; else liveclass">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #liveclass>
    <div class="schedule-live-class-wrapper">
        <div class="header">
            <div class="text">{{labelData['scheduleliveclass']}}</div>
            <div class="img" (click)="close()">
                <img src="assets/close.svg" />
            </div>
        </div>
        <hr>
        <div class="form-container">
            <div class="form">
                <form [formGroup]="liveForm">
                    <div class="title">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label> Title</mat-label>
                            <input matInput placeholder="Enter title" formControlName="title">
                            <mat-error *ngIf="!liveForm.controls['title'].valid && liveForm.controls['title'].touched">Title is required</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="description">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>Description</mat-label>
                            <textarea matInput rows="5" placeholder="Enter description" formControlName="description"></textarea>
                            <mat-error *ngIf="!liveForm.controls['description'].valid && liveForm.controls['description'].touched">Description is required</mat-error>
                        </mat-form-field>
                    </div>

                    <ng-container *ngIf="!(data && data.classID)">
                        <div class="class">
                            <mat-form-field appearance="outline" [floatLabel]="'always'">
                                <mat-label>{{labelData['class']}}</mat-label>
                                <mat-select placeholder="Select" formControlName="class" multiple>
                                    <mat-option *ngFor="let class of classes" [value]="class">
                                        {{class.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="!liveForm.controls['class'].valid && liveForm.controls['class'].touched">
                                    {{labelData['pleaseselectclass']}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!recurClassValue || !recurClassLength; else recurDateTime">
                        <div class="timing-grp">
                            <div class="group">
                                <div class="date">
                                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                                        <mat-label>Start Date</mat-label>
                                        <input matInput placeholder="DD-MM-YY" (click)="picker.open()" [min]="minDate" [matDatepicker]="picker" (dateChange)="addEvent($event, 'start')">
                                        <mat-error *ngIf="!liveForm.controls['startDate'].valid && liveForm.controls['startDate'].touched">Date can't be empty</mat-error>
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                            <mat-icon matDatepickerToggleIcon>
                                                <img src='assets/calender.svg' />
                                            </mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div class="time">
                                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                                        <mat-label>Time</mat-label>
                                        <input matInput placeholder="HH:MM" formControlName="startTime" autocomplete="off">
                                        <mat-error *ngIf="!liveForm.controls['startTime'].valid && liveForm.controls['startTime'].touched ">{{ getTimeError(liveForm, 'startTime') }}</mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="duration">
                                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                                        <mat-label>Duration</mat-label>
                                        <input matInput placeholder="HH:MM" formControlName="duration" autocomplete="off">
                                        <mat-error *ngIf="!liveForm.controls['duration'].valid && liveForm.controls['duration'].touched">{{ getDurationError(liveForm, 'duration') }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #recurDateTime>
                        <div class="recur-start-time">
                            <div class="date">
                                <mat-form-field appearance="outline" [floatLabel]="'always'">
                                    <mat-label>Start Date</mat-label>
                                    <input matInput placeholder="DD-MM-YY" (click)="startpicker.open()" [min]="minDate" [matDatepicker]="startpicker" (dateChange)="addRecurDateTime($event, 'start')">
                                    <!-- <mat-error *ngIf="!liveForm.controls['endDate'].valid && liveForm.controls['endDate'].touched">Start Date can't be empty</mat-error> -->
                                    <mat-datepicker-toggle matSuffix [for]="startpicker">
                                        <mat-icon matDatepickerToggleIcon>
                                            <img src='assets/calender.svg' />
                                        </mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #startpicker></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div class="end-date">
                                <mat-form-field appearance="outline" [floatLabel]="'always'">
                                    <mat-label>End Date</mat-label>
                                    <input matInput placeholder="DD-MM-YY" (click)="picker.open()" [min]="liveForm.value.recurStartDate" [matDatepicker]="picker" (dateChange)="addRecurDateTime($event, 'end')">
                                    <!-- <mat-error *ngIf="!liveForm.controls['startDate'].valid && liveForm.controls['startDate'].touched">Recurring End date can't be empty</mat-error> -->
                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                        <mat-icon matDatepickerToggleIcon>
                                            <img src='assets/calender.svg' />
                                        </mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-template>
                </form>
            </div>
            <ng-container>
                <div class="recur-class">
                    <mat-checkbox color="primary" [value]="recurClassValue" (change)="checkRecurClass($event)">{{labelData['recurringclass']}}</mat-checkbox>
                </div>
            </ng-container>

            <ng-container *ngIf="recurClassValue && recurClassLength">
                <div class="form recur-form">
                    <form [formGroup]="recurClassForm">
                        <div formArrayName="recurDaysArray">
                            <ng-container *ngFor="let recurItems of recurDayArray.controls; let i=index">
                                <div class="group" [formGroupName]="i">
                                    <div class="date recur-time">
                                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                                            <mat-label>Day</mat-label>
                                            <mat-select formControlName="day">
                                                <mat-option *ngFor="let day of days" [value]="day" [disabled]="disableCheckForDay(day)">
                                                    {{day}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="time recur-time">
                                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                                            <mat-label>Time</mat-label>
                                            <input matInput placeholder="HH:MM" formControlName="time" autocomplete="off">
                                            <!-- <mat-error *ngIf="!liveForm.controls['time'].valid && liveForm.controls['time'].touched">{{ getTimeError(liveForm, 'time') }}</mat-error>                             -->
                                        </mat-form-field>
                                    </div>

                                    <div class="duration recur-duration">
                                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                                            <mat-label>Duration</mat-label>
                                            <input matInput placeholder="HH:MM" formControlName="duration" autocomplete="off">
                                            <!-- <mat-error *ngIf="!liveForm.controls['time'].valid && liveForm.controls['time'].touched">{{ getTimeError(liveForm, 'time') }}</mat-error>                                           -->
                                        </mat-form-field>
                                    </div>

                                    <div class="delete" (click)="delete(i)">
                                        <img src="assets/delete-option.svg" />
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </form>
                </div>

                <div class="add-day" (click)="addDay()">
                    <div> <img src="assets/add-option.svg" /></div>
                    <div class="txt">Add Day</div>
                </div>
            </ng-container>

            <div class="warning">
                <div>
                    <img src="assets/warning.svg" />
                </div>
                <div class="txt">All participants will be allowed to join 10 minutes before the scheduled time.</div>
            </div>
            <div class="warning" *ngIf="!isAuthenticated">
                <div>
                    <img src="assets/warning.svg" />
                </div>
                <div class="txt">google authentication is needed to start using {{labelData['liveclass']}}.</div>
            </div>
        </div>

        <div class="save-btn">
            <button [disabled]="disableSubmission || !isAuthenticated" mat-flat-button color="primary" (click)="save()">SAVE & CONTINUE</button>
            <button *ngIf="!isAuthenticated" [disabled]="authenticating" mat-flat-button color="primary" (click)="authenticate()">AUTHENTICATE</button>
        </div>
    </div>
</ng-template>
