import { Component, Inject, OnInit } from '@angular/core';
import { DialogRole, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LibraryModel } from 'src/app/models/library.models';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LibraryService } from '../services/library.service';
import { CloudContentService } from 'src/app/services/cloud-content.service';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';

export const POST_COLUMN = ['select', 'name', 'activityType', 'title', 'createdAt', 'size', 'actions'];
export const TEXTHEADER = 'My Library';
@Component({
  selector: 'app-upload-from-library',
  templateUrl: './upload-from-library.component.html',
  styleUrls: ['./upload-from-library.component.scss']
})
export class UploadFromLibraryComponent implements OnInit {
  public textHeader: string = TEXTHEADER;
  public posts: LibraryModel[] = [];
  step1 = true;
  isLoading = true;
  isFileDisabled = false;
  public selectedFiles = [];
  postsColumn: string[] = POST_COLUMN;

  isAdminTheme = false;
  isCloudContent = false;
  userType: string;
  backButtonSrc = '../../../assets/blue-back.svg';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<any>,
    public libraryService: LibraryService,
    public snackbar: SnackbarService,
    private cloudContentSvc: CloudContentService
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.userType) {
      this.userType = this.data.userType;
      if (this.userType === USER_TYPES.admin) {
        this.isAdminTheme = true;
        this.backButtonSrc = '../../../assets/grey-back.svg';
      }
    }
    if (this.data && this.data.isCloudContent) {
      this.isCloudContent = true;
      this.postsColumn = this.postsColumn.filter((col) => col !== 'activityType');
    }
    this.isFileDisabled = this.data && this.data.fileDisabled ? true : false;
    if (this.isCloudContent) {
      this.getCloudContent();
    } else {
      this.getLibrary();
    }
  }

  getLibrary() {
    this.libraryService.getLibraryFiles().subscribe(
      (res) => {
        this.posts = res;
        this.isLoading = false;
      },

      (err) => {
        this.snackbar.openSnackbar(err.error.error);
        this.isLoading = false;
      }
    );
  }

  getCloudContent() {
    this.cloudContentSvc.getCloudDirectoryContent().subscribe(
      (res) => {
        this.posts = res;
        this.isLoading = false;
      },

      (err) => {
        this.snackbar.openSnackbar(err.error.error);
        this.isLoading = false;
      }
    );
  }

  onFileContent(file: LibraryModel) {
    this.isLoading = true;
    if (!file) {
      if (this.isCloudContent) {
        this.getCloudContent();
        return;
      }
      this.getLibrary();
    } else if (!file.isFolder) {
      this.isLoading = false;
      return;
    } else {
      if (this.isCloudContent) {
        this.cloudContentSvc.getCloudDirectoryContent(file.id).subscribe(
          (res) => {
            this.textHeader = file.name;
            this.posts = res;
            this.step1 = false;
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            this.snackbar.openSnackbar(err.error.error);
          }
        );
        return;
      }
      this.libraryService.getContents(file.id, true).subscribe(
        (res) => {
          this.textHeader = file.name;
          this.posts = res.libraryContents;
          this.step1 = false;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.snackbar.openSnackbar(err.error.error);
        }
      );
    }
  }

  back() {
    this.isLoading = true;
    this.step1 = true;
    this.textHeader = TEXTHEADER;
    if (this.isCloudContent) {
      this.getCloudContent();
      return;
    }
    this.getLibrary();
  }

  onSelectCheckbox(event) {
    if (event.checked) {
      this.selectedFiles.push(event.file);
    } else {
      this.selectedFiles = this.selectedFiles.filter(file => file.id !== event.file.id);
    }
  }

  uploadFile() {
    this.dialogRef.close(this.selectedFiles);
  }

  cancel() {
    this.dialogRef.close();
  }

}
