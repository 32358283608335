<app-shared-dashboard
  *ngIf="width >= 760"
  [selected]="selected"
  [isTabSelected]="isTabSelected"
  (tabSelect)="select($event)"
  [tabs]="tabs"
  [theme]="'student-theme'"
  [classesLabel]="'classes'"
></app-shared-dashboard>

<app-shared-dashboard
  *ngIf="width < 760"
  [selected]="selected"
  [isTabSelected]="isTabSelected"
  (tabSelect)="select($event)"
  [tabs]="tabs2"
  [theme]="'student-theme'"
  [classesLabel]="'classes'"
></app-shared-dashboard>
