import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LibraryModel } from 'src/app/models/library.models';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { CreateFolderComponent } from 'src/app/shared/create-folder/create-folder.component';
import { LibraryService } from '../services/library.service';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';

export const ACTION_ITEM = ['Upload File', 'Create Folder'];
export const POST_COLUMN = ['select', 'name', 'activityType', 'title', 'createdAt', 'size', 'actions'];
const dialogWidth = '386px';
const dialogHeight = '404px';
@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {
  isLoading = true;
  step1 = true;
  posts: LibraryModel[] = [];
  selectedFile;
  action = new FormControl();
  postsColumn: string[] = POST_COLUMN;
  actionList: string[] = ACTION_ITEM;

  userTypes = USER_TYPES;
  constructor(
    public dialog: MatDialog,
    public libraryService: LibraryService,
    public sidenav: ToggleSidenavService,
    public snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.sidenav.maximizeSidenav();
    this.getLibrary();
    this.action.valueChanges.subscribe((type) => {
      if (!type) {
        return;
      }
      const index = this.actionList.findIndex(list => list === type);
      const actionType = this.actionList[index] && this.actionList[index].split(' ')[0].toLowerCase();

      const dialog = this.dialog.open(CreateFolderComponent, {
        width: dialogWidth,
        height: dialogHeight,
        data: { type: actionType },
        backdropClass: 'blur',
        disableClose: true,
        panelClass: ['width-class'],
        autoFocus: false
      })

      dialog.afterClosed().subscribe(res => {
        this.action.setValue(null);
        if (res) {
          this.isLoading = true;
          if (this.selectedFile) {
            res.parent = this.selectedFile.id;
          }
          this.libraryService.createFolder(res).subscribe(
            (response) => {
              this.snackbar.openSnackbar(response.msg);
              this.selectedFile ? this.onFileContent(this.selectedFile) : this.getLibrary();
            },
            (err) => {
              this.snackbar.openSnackbar(err.error.error);
              this.isLoading = false;
            }
          );
        }
      });

    });
  }

  back() {
    this.sidenav.maximizeSidenav();
    this.isLoading = true;
    this.step1 = true;
    this.selectedFile = null;
    this.getLibrary();
  }

  getFileContent(fileID) {
    this.libraryService.getContents(fileID, true).subscribe(
      (res) => {
        this.posts = res.libraryContents;
        this.step1 = false;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.snackbar.openSnackbar(err.error.error);
      }
    );
  }

  onFileContent(file: LibraryModel) {
    if (!file && this.selectedFile) {
      this.getFileContent(this.selectedFile.id);
      return;
    }
    this.isLoading = true;
    if (!file) {
      this.getLibrary();
    } else if (!file.isFolder && file.parent === 'root') {
      this.isLoading = true;
      this.getLibrary();
      return;
    } else if (file.parent !== 'root') {
      this.getFileContent(file.parent);
    } else {
      this.sidenav.minmizeSidenav();
      this.selectedFile = file;
      this.getFileContent(file.id);
    }
  }

  getLibrary() {
    this.libraryService.getLibraryFiles().subscribe(
      (res) => {
        this.posts = res;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.snackbar.openSnackbar(err.error.error);
      }
    );
  }

}
