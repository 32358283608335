import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { ChatMessage, Participant } from 'src/app/models/live-class-models';
import { LiveSessionService } from 'src/app/services/live-session.service';
import { Subscription } from 'rxjs';
import { CHAT_WINDOW } from 'src/app/Constants/Constant';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() allParticipants: Map<string, Participant>;
  @ViewChild('chatInput') chatInputEl: ElementRef;
  @ViewChild('messagesContainer') messagesContainer: ElementRef;
  @Input() selfId: string;
  @Input() teacher: any;
  @Output() closeWindow = new EventEmitter();
  @Output() sendMsg = new EventEmitter();

  public messages: ChatMessage[] = [];
  public isNearBottom: boolean = true;


  private subscriptions: Subscription[] = [];

  public theme: string;

  constructor(private liveSessionSvc: LiveSessionService,
    private ref: ChangeDetectorRef,
    private localStorageSvc: LocalStorageService) { }

  private isUserNearBottom(messagesContainer): boolean {
    const threshold = 50;
    const position = messagesContainer.nativeElement.scrollTop + messagesContainer.nativeElement.offsetHeight;
    const height = messagesContainer.nativeElement.scrollHeight;
    return position > height - threshold;
  }

  ngOnInit() {
    this.theme = this.localStorageSvc.getItem("type")
  }


  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.liveSessionSvc.chatMessages$.subscribe((chatMessages) => {
        this.messages = chatMessages;
          setTimeout(() => {
            if (this.messagesContainer.nativeElement.scrollTop + this.messagesContainer.nativeElement.offsetHeight + 50 >= this.messagesContainer.nativeElement.scrollHeight) {
              this.isNearBottom = true;
            } else {
              this.isNearBottom = false;
            }
            this.ref.detectChanges();
          }, 500);
      })
    );
    this.subscriptions.push(
      this.liveSessionSvc.selectedSideWindow$.subscribe((selectedSideWIndow) => {
        if(selectedSideWIndow === CHAT_WINDOW) {
          setTimeout(() => {
            if (this.messagesContainer.nativeElement.scrollTop + this.messagesContainer.nativeElement.offsetHeight + 50 >= this.messagesContainer.nativeElement.scrollHeight) {
              this.isNearBottom = true;
            } else {
              this.isNearBottom = false;
            }
            this.ref.detectChanges();
          }, 500);
        }
      })
    )
  }

  public closeSideWindow() {
    this.closeWindow.emit();
  }

  public onScroll() {
    this.isNearBottom = this.isUserNearBottom(this.messagesContainer);
  }

  public sendChatMessage() {
    this.sendMsg.emit({ chatInputEl: this.chatInputEl, messagesContainer: this.messagesContainer });
  }



  public scrollToBottom() {
    this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight + 50;
    this.isNearBottom = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

}
