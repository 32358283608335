import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CrudService } from './crud.service';
import { UserService } from './user.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService implements OnInit {
  private isLoggedIn$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  intervalID: any;
  otpTimer$$ = new BehaviorSubject('00:00');

  public get isLoggedIn$(): Observable<boolean> {
    return this.isLoggedIn$$.asObservable();
  }

  public get otpTimer$(): Observable<string> {
    return this.otpTimer$$.asObservable();
  }

  public studentProfileRoute(studentID) {
    return `${environment.apiUrl}/profile?role=student&id=${studentID}`;
  }

  public changePinRoute(type, id) {
    if(type !== 'student') {
      return `${environment.apiUrl}/profile/pin`;
    }
    return `${environment.apiUrl}/profile/pin?role=student&id=${id}`;
  }

  constructor(
    private httpService: HttpClient,
    public userService: UserService,
    public crud: CrudService,
    private localStorage: LocalStorageService
  ) {
   }

  ngOnInit() { }

  startOTPTimer() {
    let i = 60;
    this.intervalID = setInterval(_ => {
      i -= 1;
      if(i < 0) {
        this.otpTimer$$.next(`00:00`);
        clearInterval(this.intervalID);
        return;
      } else if(i < 10) {
        this.otpTimer$$.next(`00:0${i}`);
      } else {
        this.otpTimer$$.next(`00:${i}`);
      }
    }, 1000)
  }

  clearInterval() {
    clearInterval(this.intervalID);
    this.otpTimer$$.next('00:00');
    this.intervalID = null;
  }
  
  login(role, mobileNo) {
    return this.httpService.post(
      `${environment.apiUrl}login`,
        {
            "role": role,
            "mobileNumber": mobileNo
        }
    ).toPromise()
  }

  logout(body) {
    return this.httpService.post(`${environment.apiUrl}logout`, body).toPromise();
  }

 sendOtp(number: number) {
   return this.httpService.post(
    `${environment.apiUrl}send-otp`,
    {
        "mobileNumber": number.toString(),
        "role": this.localStorage.getItem('type')
    }
    ).toPromise()
}

 verify(otp: string, sessionID: string, role: string, mobileNo: string) {
    return this.httpService.post(
      `${environment.apiUrl}login`,
      {
        "otp": otp.toString(),
        "sessionID": sessionID,
        "role": role,
        "mobileNumber": mobileNo
      }
    ).toPromise();
  }

  getProfile(studentID?) {
    if(studentID) {
      return this.crud.get(this.studentProfileRoute(studentID));
    } else {
      return this.crud.get(`${environment.apiUrl}/profile`);
    }
  }

  editProfile(body, studentID?) {
    if(studentID) {
      return this.crud.put(this.studentProfileRoute(studentID), body);
    } else {
      return this.crud.put(`${environment.apiUrl}/profile`, body)
    }
  }

  getFromGcs(url) {
    return this.crud.get(url);
  }

  changePin(type, id, body) {
    return this.crud.patch(this.changePinRoute(type, id), body);
  }

}
