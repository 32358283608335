import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProfileModel } from 'src/app/models/profile.models';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DeleteDialogComponent } from '../shared/delete-dialog/delete-dialog.component';
import { ChangePinComponent } from './change-pin/change-pin.component';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { USER_TYPE_KEY } from '../Constants/local-storage-keys';
import { USER_TYPES } from '../Constants/user-types-constants';
import { ProfileService } from './services/profile.service';

export const TEACHER = 'teacher';
export const ADMIN = 'admin';
export const STUDENT = 'student';
export const PARENT = 'parent';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  width = window.innerWidth;
  theme: string;
  user: ProfileModel;
  studentId: string;
  isLoading: boolean = true;
  constructor(
    public themeService: UserService,
    public profileService: ProfileService,
    public router: Router,
    public snackbar: SnackbarService,
    public dialog: MatDialog,
    private sidenavService: ToggleSidenavService
  ) { }

  ngOnInit(): void {
    this.sidenavService.maximizeSidenav();
    this.theme = this.themeService.getUserInCache(USER_TYPE_KEY);
    if (this.theme === USER_TYPES.student) {
      this.studentId = this.themeService.getUserInCache('studentID');
    }
    this.getProfile();
  }

  getProfile() {
    let profileRequest;
    if (this.studentId) {
      profileRequest = this.profileService.getProfile(this.studentId);
    } else {
      profileRequest = this.profileService.getProfile();
    }
    profileRequest.pipe(
      switchMap((profile: ProfileModel) => {
        this.user = profile;
        return this.profileService.getFromGcs(profile.profilePicture)
      }),
      catchError(err => err)
    )
      .subscribe(
        (image) => {
          this.user.profilePicture = image;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.snackbar.openSnackbar(err.error.error)
        }
      )
  }

  edit() {
    const dialog = this.dialog.open(EditProfileComponent, {
      width: '46%',
      height: '100%',
      data: { user: this.user, studentID: this.studentId },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['width-class'],
      autoFocus: false
    })
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.isLoading = true;
        this.getProfile();
      }
    });
  }

  getLogoutBody(role) {
    const body = {
      role,
      mobileNumber: this.user.mobileNumber
    }
    return body;
  }

  changePin() {
    const dialog = this.dialog.open(ChangePinComponent, {
      width: '46%',
      height: '100%',
      data: { user: this.user },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['width-class'],
      autoFocus: false
    })
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.isLoading = true;
        this.getProfile();
      }
    })
  }

  logout() {
    const body = this.getLogoutBody(this.theme);
    // if (this.user.gender)getLogoutBody {
    //   this.snackbar.openSnackbar('You have successfully logged out!!');
    //   this.themeService.clearStudentId();
    //   this.switchProfile(this.theme);
    // } else {
    this.profileService.logout(body).then((res: any) => {
      this.snackbar.openSnackbar(res.msg);
      this.themeService.clearUserInCache();
      if (this.theme === 'admin') {
        return this.router.navigate(['school', 'login']).then(() => {
          window.location.reload();
        });
      } else {
        this.router.navigate([this.theme, 'login']).then(() => {
          window.location.reload();
        });
      }
    });
    // }
  }

  showAlertMsg() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '363px',
      height: '190px',
      backdropClass: 'blur',
      data: { theme: this.theme, isUpload: true, isLogout: true, alertText: 'Are you sure, you want to logout of the app?' },
      panelClass: ['delete-dialog-css'],
      autoFocus: false
    })
    return dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.logout();
      } else {
        return;
      }
    });
  }

  switchProfile(type) {
    if (type === 'student') {
      this.themeService.clearStudentId();
      this.router.navigate(['parent', 'choose']);
    }
    else {
      if (type == 'teacher') {
        this.router.navigate(['teacher', 'choose']);
      }
      else {
        this.router.navigate(['school', 'choose']);
      }
    }
  }

}
