import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SafePipe} from './safe.pipe';
import { JoinedTimePipe } from './joined-time.pipe';
import { PrettyDatePipe } from './pretty-date.pipe';

@NgModule({
  declarations: [
    SafePipe,
    JoinedTimePipe,
    PrettyDatePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    JoinedTimePipe,
    PrettyDatePipe
  ]
})
export class PipesModule { }
