import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LabelService } from 'src/app/services/label.service';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SELF_ID_KEY, SELECTED_SCHOOL_GROUP_KEY } from 'src/app/Constants/Constant';
import { SchoolGroupService } from 'src/app/services/school-group.service';
import { AlertDialogComponent } from 'src/app/shared-components/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';
import { BASE_ROUTES } from 'src/app/Constants/route-constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  selected = 0;
  isTabSelected = false;
  tabs;
  schoolLabel;
  subscription = new Subscription();
  err: string;
  loading = true;
  constructor(
    private router: Router,
    public sidenavService: ToggleSidenavService,
    public cd: ChangeDetectorRef,
    public labelS: LabelService,
    private localstorage: LocalStorageService,
    private schoolGroupSvc: SchoolGroupService,
    private dialog: MatDialog,
  ) {
    this.subscription = labelS.labels$.subscribe(val => {
      this.schoolLabel = val.schools;
      this.tabs = [
        { name: 'schools', img: '../../../assets/classroom-1.svg' },
        { name: 'analytics', img: '../../../assets/graph.svg' },
        { name: 'profile', img: '../../../assets/graphic.svg' }
      ];
      if (this.localstorage.getItem(SELF_ID_KEY) === this.localstorage.getItem(SELECTED_SCHOOL_GROUP_KEY)) {
        this.tabs.push({ name: 'settings', img: '../../../assets/settings-mark.svg' });
      } else if (!this.localstorage.getItem(SELECTED_SCHOOL_GROUP_KEY)) {
        this.tabs.push({ name: 'settings', img: '../../../assets/settings-mark.svg' });
      }
    });
  }

  ngOnInit(): void {
    this.sidenavService.sidenavToggle$.subscribe(toggle => {
      this.isTabSelected = toggle;
      const routeName = this.router.url.split('/')[3];
      this.selected = this.tabs.findIndex(tab => tab.name.toLowerCase() === routeName);
      this.selected = this.selected > 0 ? this.selected : 0;
      this.cd.detectChanges();
    });

    this.schoolGroupSvc.getAccessRights().subscribe(resp => {
      this.loading = false;
      this.schoolGroupSvc.updateAccessRights(resp);
    }, err => {
      if (err.error.error.includes('This school group has been deactivated')) {
        this.err = 'school group deactivated';
        this.loading = false;
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '363px',
          height: '170px',
          backdropClass: 'blur',
          data: { alertText: 'This school group is deactivated', type: 'accessdenied', theme: USER_TYPES.admin },
          panelClass: ['delete-dialog-css'],
          autoFocus: false
        });
        dialogRef.afterClosed().subscribe((data) => {
          this.router.navigate([BASE_ROUTES.admin, 'choose']);
        });
      }
    });
  }


  select(index) {
    this.selected = index;
    this.router.navigate(['school', 'dashboard', this.tabs[index].name]);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
