import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AppUsersService } from './services/app-users.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ACTIVE_STATUS, INACTIVE_STATUS } from 'src/app/Constants/Constant';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CreatorUser, InstructorUser, LearnerUser } from 'src/app/models/super-admin-users.model';
import { MatDialog } from '@angular/material/dialog';
import { UserSettingsDialogComponent } from './user-settings-dialog/user-settings-dialog.component';
import { CHANGE_MOBILE_NUMBER_ACTION, UPDATE_ACCESS_RIGHTS_ACTION } from '../../Constants/users-view';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  constructor(
    private usersSvc: AppUsersService,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) { }

  tabs = ['Admins', 'Instructors', 'Learners'];
  creators: CreatorUser[] = [];
  instructors: InstructorUser[] = [];
  learners: LearnerUser[] = [];
  isLoading = true;
  updatingState = false;

  adminColumns = ['srNo', 'name', 'mobileNumber', 'schoolsCount', 'maxSchools', 'accessRights', 'status', 'actions'];
  instructorsColumns = ['srNo', 'name', 'mobileNumber', 'schoolsAssociated', 'classesCreated'];
  learnerColumns = ['srNo', 'name', 'mobileNumber', 'classesAssociated', 'standard'];

  private destroy$$: Subject<any> = new Subject<any>();
  ngOnInit(): void {
    this.tabSelected({ index: 0 });
    this.usersSvc.isLoading$.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  tabSelected({ index }) {
    switch (index) {
      case 0:
        if (this.creators.length === 0) {
          this.usersSvc.getAllAdmins();
        }
        this.usersSvc.creator$.pipe(
          takeUntil(this.destroy$$),
        ).subscribe(admins => {
          this.creators = admins;
        });
        break;
      case 1:
        if (this.instructors.length === 0) {
          this.usersSvc.getAllInstructors();
        }
        this.usersSvc.instructors$.pipe(
          takeUntil(this.destroy$$),
        ).subscribe(instructors => {
          this.instructors = instructors;
        });
        break;
      case 2:
        if (this.learners.length === 0) {
          this.usersSvc.getAllLearners();
        }
        this.usersSvc.learners$.pipe(
          takeUntil(this.destroy$$),
        ).subscribe(learners => {
          this.learners = learners;
        });
        break;
    }
  }

  changeCreatorStatus({ transaction, index }) {
    const newStatus = transaction.status === ACTIVE_STATUS ? INACTIVE_STATUS : ACTIVE_STATUS;
    this.updatingState = true;
    this.usersSvc.updateCreatorStatus(transaction.id, newStatus).subscribe((res) => {
      const newCreator = { ...this.creators[index], status: newStatus};
      this.creators.splice(index, 1, newCreator);
      this.updatingState = false;
      this.creators = [...this.creators];
      this.snackbar.openSnackbar(`admin status set to ${newStatus} successfully`);
      this.cd.detectChanges();
    }, () => {
      this.updatingState = false;
      this.snackbar.openSnackbar(`unable to change admin status`);
    });
  }

  changeCreatorMobileNumber({ transaction, index }) {
    const dialogRef = this.dialog.open(UserSettingsDialogComponent, {
      data: {
        title: 'Change Mobile Number',
        user: {
          id: transaction.id,
          name: transaction.name,
          mobileNumber: transaction.mobileNumber,
          accessRights: transaction.accessRights,
        },
        action: CHANGE_MOBILE_NUMBER_ACTION
      },
      width: '46%',
      height: '100%',
      backdropClass: 'blur',
      disableClose: true,
      panelClass: 'super-admin-theme',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.creators[index].mobileNumber = res.mobileNumber;
        this.creators = [...this.creators];
        this.cd.detectChanges();
      }
    });
  }

  changeAccessRights({ transaction, index }) {
    const dialogRef = this.dialog.open(UserSettingsDialogComponent, {
      data: {
        title: 'Update Access Rights',
        user: {
          id: transaction.id,
          name: transaction.name,
          mobileNumber: transaction.mobileNumber,
          accessRights: transaction.accessRights,
        },
        action: UPDATE_ACCESS_RIGHTS_ACTION
      },
      width: '46%',
      height: '100%',
      backdropClass: 'blur',
      disableClose: true,
      panelClass: 'super-admin-theme',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.creators[index].accessRights = res.accessRights;
        this.creators = [...this.creators];
        this.cd.detectChanges();
      }
    });
  }


  ngOnDestroy() {
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }
}
