<ng-container *ngIf="isLoading; else content">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #content>
    <ng-container *ngIf="isCourseActivity else postDetails">
        <div class="post-container">
            <app-post-detail [post]='courseActivity' [text]="text" (onPreview)="onPreviewSelect($event)"></app-post-detail>
            <div class="table" *ngIf="courseActivityDetail">
                <app-post-detail-table [disableActions]="disableTableActions" [courseActivityID]="courseActivityId" [isCourseActivity]="true" [displayedColumns]="displayedColumns" [type]="text.toLowerCase()" [transactions]="courseActivityDetail"></app-post-detail-table>
            </div>
        </div>
    </ng-container>
    <ng-template #postDetails>
        <div class="post-container">
            <div>
                <app-post-detail [post]='post' [text]="text" (onPreview)="onPreviewSelect($event)"></app-post-detail>
            </div>

            <div class="table" *ngIf="postDetail">
                <app-post-detail-table [displayedColumns]="displayedColumns" [type]="text.toLowerCase()" [transactions]="postDetail"></app-post-detail-table>
            </div>
        </div>
    </ng-template>
</ng-template>
