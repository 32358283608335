import { Injectable, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleSidenavService {
  private isSidenavToggled$$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get sidenavToggle$() : Observable<boolean> {
    return this.isSidenavToggled$$.asObservable();
  }

  constructor() {
    console.log('starting the toggle sidenav service');
   }

  minmizeSidenav() {
    this.isSidenavToggled$$.next(true);
  }

  maximizeSidenav() {
    this.isSidenavToggled$$.next(false);
  }
}
