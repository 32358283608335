import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { pageSizeOptions, paginatorPageSize } from 'src/app/Constants/Constant';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit, OnChanges {


  @Input() transactions: any[];
  @Input() displayedColumns: string[];

  @Output() statusChange = new EventEmitter();
  @Output() mobileNumebrChange = new EventEmitter();
  @Output() accessRightChange = new EventEmitter();

  dataSource = null;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  pageSizeOptions: number[] = pageSizeOptions;
  pageSize = paginatorPageSize;


  constructor(
    public cd: ChangeDetectorRef,
  ) { }


  ngOnInit(): void {
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.transactions);
    this.cd.detectChanges();
    this.dataSource.paginator = this.paginator;
  }

  changeStatus(transaction: any, index: number) {
    const arrayIndex = index + this.dataSource.paginator?.pageIndex * this.dataSource.paginator?.pageSize;
    this.statusChange.emit({ transaction, index: arrayIndex});
  }

  changeMobileNumber(transaction: any, index: number) {
    const arrayIndex = index + this.dataSource.paginator?.pageIndex * this.dataSource.paginator?.pageSize;
    this.mobileNumebrChange.emit({ transaction, index: arrayIndex});
  }

  changeAccessRights(transaction: any, index: number) {
    const arrayIndex = index + this.dataSource.paginator?.pageIndex * this.dataSource.paginator?.pageSize;
    this.accessRightChange.emit({ transaction, index: arrayIndex});
  }
}
