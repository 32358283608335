import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {distinctUntilChanged, filter, pairwise, switchMap, takeUntil, tap} from 'rxjs/operators';
import * as PDFJS from 'pdfjs-dist';
import {BehaviorSubject, fromEvent, Subject} from 'rxjs';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { jsPDF } from 'jspdf';
import {allowedImageType, allowedVideoType, FILE_TYPES} from '../../Constants/Constant';
import {AssignmentFilesService} from '../services/assignment-files.service';
import { CourseActivitiesService } from 'src/app/admin/services/course-activities.service';

interface CanvasFile {
  name: string;
  url: string;
}

const CANVAS_WIDTH = 632;

@Component({
  selector: 'app-file-canvas',
  templateUrl: './file-canvas.component.html',
  styleUrls: ['./file-canvas.component.scss']
})
export class FileCanvasComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('canvasWrapper') public canvasWrapper: ElementRef;
  @ViewChild('canvas', {static: false}) public canvas: ElementRef;
  private cx: CanvasRenderingContext2D;
  public FILE_TYPES = FILE_TYPES;
  @Input() file: CanvasFile = null;
  @Input() isCourseActivity = false;
  canEditFile = true;
  @Input() set canEdit(value: boolean) {
    this.canEditFile = value;
  }

  public fileType = this.FILE_TYPES.IMAGE;
  private currPage = 1;
  private numPages = 0;
  private thePDF = null;
  private destroy$$ = new Subject();

  constructor(
    private assignmentFileService: AssignmentFilesService,
    private courseActivitiesService: CourseActivitiesService
  ) { }

  ngOnInit(): void {
  }

  public ngAfterViewInit() {
      const fileType = this.file.name.split('.');
      if (allowedImageType.includes(fileType[fileType.length - 1])) {
        this.renderImage(this.file.url);
        this.fileType = this.FILE_TYPES.IMAGE;
      } else if (fileType[fileType.length - 1] === 'pdf') {
        this.renderPDF(this.file.url);
        this.fileType = this.FILE_TYPES.PDF;
      } else if (allowedVideoType.includes(fileType[fileType.length - 1])) {
        this.fileType = this.FILE_TYPES.VIDEO;
      } else {
        this.fileType = this.FILE_TYPES.OTHER;
      }
  }

  private renderPDF(file) {
    PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    PDFJS.getDocument(file).promise.then((pdf) => {
      this.thePDF = pdf;
      this.numPages = pdf.numPages;
      pdf.getPage( 1 ).then( this.handlePages.bind(this) );
    });
  }

  private handlePages(page){
    const viewport = page.getViewport( {scale: 1} );
    const canvasWrapperEl: HTMLCanvasElement = this.canvasWrapper.nativeElement;
    const canvasEl = document.createElement('canvas');
    const context = canvasEl.getContext('2d');
    canvasEl.height = viewport.height;
    canvasEl.width = viewport.width;
    this.captureEvents(canvasEl, context);
    context.lineWidth = 6;
    context.lineCap = 'round';
    context.strokeStyle = '#ff0000';

    page.render({canvasContext: context, viewport});
    canvasWrapperEl.appendChild(canvasEl);

    this.currPage++;
    if ( this.thePDF !== null && this.currPage <= this.numPages )
    {
      this.thePDF.getPage( this.currPage ).then( this.handlePages.bind(this) );
    }
  }

  private renderImage(file) {
    const img = new Image();
    img.src = file;
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
      const scale = CANVAS_WIDTH / img.width;
      canvasEl.width = CANVAS_WIDTH;
      canvasEl.height = img.height * scale;
      this.cx = canvasEl.getContext('2d');
      this.cx.drawImage(img, 0, 0, CANVAS_WIDTH, canvasEl.height  );
      this.captureEvents(canvasEl, this.cx);
      this.cx.lineWidth = 6;
      this.cx.lineCap = 'round';
      this.cx.strokeStyle = '#ff0000';
    };
  }

  private captureEvents(canvasEl: HTMLCanvasElement, cx) {
      fromEvent(canvasEl, 'mousedown')
        .pipe(
          switchMap((e) => {
            return fromEvent(canvasEl, 'mousemove')
              .pipe(
                takeUntil(fromEvent(canvasEl, 'mouseup')),
                takeUntil(fromEvent(canvasEl, 'mouseleave')),
                pairwise()
              );
          }),
          takeUntil(this.destroy$$),
          filter(_ => this.canEditFile)
        ).subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        this.drawOnCanvas(prevPos, currentPos, cx);
      });


      fromEvent(canvasEl, 'touchstart')
        .pipe(
          switchMap((e) => {
            return fromEvent(canvasEl, 'touchmove')
              .pipe(
                takeUntil(fromEvent(canvasEl, 'touchleave')),
                takeUntil(fromEvent(canvasEl, 'touchcancel')),
                pairwise()
              );
          }),
          takeUntil(this.destroy$$),
          filter(_ => this.canEditFile)
        ).subscribe((res: [TouchEvent, TouchEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        const prevPos = {
          x: res[0].touches[0].pageX - rect.left,
          y: res[0].touches[0].pageY - rect.top
        };

        res[0].preventDefault();
        res[0].stopImmediatePropagation();

        const currentPos = {
          x: res[1].touches[0].pageX  - rect.left,
          y: res[1].touches[0].pageY  - rect.top
        };
        res[1].preventDefault();
        res[1].stopImmediatePropagation();

        console.log(res[0]);
        console.log(res[1]);

        this.drawOnCanvas(prevPos, currentPos, cx);
      });


  }

  private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }, cx) {
    if (!cx) { return; }

    cx.beginPath();

    if (prevPos) {
      cx.moveTo(prevPos.x, prevPos.y); // from
      cx.lineTo(currentPos.x, currentPos.y);
      cx.stroke();
      this.saveFile();
    }
  }

  private saveFile() {
    if(this.isCourseActivity) {
      if (this.fileType === this.FILE_TYPES.IMAGE) {
        this.courseActivitiesService.updateFiles(
          this.canvas.nativeElement,
          this.file.name,
          this.FILE_TYPES.IMAGE
        );
      } else if (this.fileType === this.FILE_TYPES.PDF) {
        this.courseActivitiesService.updateFiles(this.canvasWrapper.nativeElement, this.file.name, this.FILE_TYPES.PDF);
      }
      return;
    }
    if (this.fileType === this.FILE_TYPES.IMAGE) {
      this.assignmentFileService.updateFiles(
        this.canvas.nativeElement,
        this.file.name,
        this.FILE_TYPES.IMAGE
      );
    } else if (this.fileType === this.FILE_TYPES.PDF) {
      this.assignmentFileService.updateFiles(this.canvasWrapper.nativeElement, this.file.name, this.FILE_TYPES.PDF);
    }
  }

  ngOnDestroy(): void {
    this.destroy$$.next(null);
    this.destroy$$.unsubscribe();
  }

}
