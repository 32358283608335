<div class="rename-wrapper">
    <div class="header">Settings</div>

    <div class="rename-input">
        <div class="txt-header">
            <div (click)="back()" class="back"> <img src="assets/grey-back.svg" /></div>
            <div class="name">Rename</div>
        </div>

        <div class="hr">
            <hr>
        </div>

        <div class="form" [formGroup]="labelForm">
            <div class="label-input">
                <div>School</div>
                <!-- <div class="school-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="school" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input">
                <div>Schools</div>
                <!-- <div class="school-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="schools" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input">
                <div>Class</div>
                <!-- <div class="school-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="class" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input">
                <div>Classes</div>
                <!-- <div class="school-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="classes" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input">
                <div>Create Class</div>
                <!-- <div class="school-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="createclass" placeholder="Enter new name">
                </div>
            </div>

            <div class="label-input">
                <div>Standard</div>
                <!-- <div class="standard-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="standard" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input">
                <div>Standards</div>
                <!-- <div class="school-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="standards" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input">
                <div>Section</div>
                <!-- <div class="section-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="section" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input">
                <div>Sections</div>
                <!-- <div class="section-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="sections" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Session</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="session" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Sessions</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="sessions" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Parent</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="parent" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Children</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="children" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Add Student</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="addstudent" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Go To Classes</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="gotoclasses" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>My Class</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="myclass" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Join Live Class</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="joinliveclass" placeholder="Enter new name">
                </div>
            </div><div class="label-input"> 
                <div>Live Class Today</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="liveclasstoday" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Live Class Title</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="liveclasstitle" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>All Schools</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="allschools" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Create School</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="createschool" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Student</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="student" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Students</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="students" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Teacher</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="teacher" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Teachers</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="teachers" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Staff</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="staff" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Subject</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="subject" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Subjects</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="subjects" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>School Creator</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="schoolcreator" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>School Admin</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="schooladmin" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>School Member</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="schoolmember" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Invite Teachers</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="inviteteachers" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Invite School Staff</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="inviteschoolstaff" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Invite Students</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="invitestudents" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Invite Co-Teachers</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="invitecoteachers" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Co-Teachers</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="coteachers" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Live Class</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="liveclass" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div> Create Live Class</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="createliveclass" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div> Live Class Schedule</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="liveclassschedule" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div> Select School</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="selectschool" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div> Class Name</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="classname" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div> Select Standard</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="selectstandard" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div> Select Subject</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="selectsubject" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div> Select Section</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="selectsection" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div> Select Session</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="selectsession" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Please Select School</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="pleaseselectschool" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Please Select Standard</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="pleaseselectstandard" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Please Select Section</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="pleaseselectsection" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Please Select Session</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="pleaseselectsession" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Create a new class</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="createanewclass" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Create a new school</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="createanewschool" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Schedule Live Class</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="scheduleliveclass" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Please Select Class</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="pleaseselectclass" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Recurring Class</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="recurringclass" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>All Classes</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="allclasses" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>All SUbjects</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="allsubjects" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>All Standards</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="allstandards" placeholder="Enter new name">
                </div>
            </div>
            <div class="label-input"> 
                <div>Add Standard</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="addstandard" placeholder="Enter new name">
                </div>
            </div> <div class="label-input"> 
                <div>Add Section</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="addsection" placeholder="Enter new name">
                </div>
            </div> <div class="label-input"> 
                <div>Add Subject</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="addsubject" placeholder="Enter new name">
                </div>
            </div> <div class="label-input"> 
                <div>Add Session</div>
                <!-- <div class="session-colon"> : </div> -->
                <div class="form-input">
                    :<input matInput formControlName="addsession" placeholder="Enter new name">
                </div>
            </div>
        </div>

        <div class="btn">
            <div class="clear">
                <button mat-flat-button (click)="clearAll()">CLEAR ALL</button>
            </div>

            <div class="save">
                <button mat-flat-button color="primary" (click)="save()">SAVE CHANGES</button>
            </div>
        </div>
    </div>
</div>