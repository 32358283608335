<div class="teacher-theme detail-wrapper">
    <ng-container *ngIf="selectedClass">
        <div class="header">
            <div class="left">
                <div (click)="back()" class="close"> <img src="../../../assets/blue-back.svg" /></div>
                <div class="txt">{{selectedClass.name | titlecase}}, {{selectedClass.standard}}, {{selectedClass.section | titlecase}}</div>
            </div>

            <div class="action">
                <button mat-icon-button (click)="select.open()" [disabled]="(selectedClass.status == 'archived') || (selectedClass.schoolStatus == 'archived')">
                    <mat-label>ACTIONS</mat-label>
                    <mat-select #select [formControl]="action" [disabled]="(selectedClass.status == 'archived') || (selectedClass.schoolStatus == 'archived')">
                        <mat-option *ngFor="let action of actionList" [value]="action">{{(action == 'Post Material' || action == 'Post Assignment' || action == 'Take a Test') ? action : getLabel(action)}}</mat-option>
                    </mat-select>
                </button>
            </div>
        </div>

        <div class="tabs">
            <mat-tab-group (selectedTabChange)="tabSelect($event)" [selectedIndex]="0">
                <mat-tab *ngFor="let tab of tabs">
                    <ng-template mat-tab-label *ngIf="tab.label !== 'icon'">{{(tab.label == 'Settings' || tab.label == 'Posts') ? tab.label : getLabel(tab.label)}}</ng-template>

                    <ng-container *ngIf="tab.label.toLowerCase() === 'live class'">
                        <ng-container *ngIf="liveClass; else spinner">
                            <app-live-class-dashboard [list]="liveClass" [columns]="liveClassColumn" (dateFilter)="onDateSelect($event)" [date]="selectedDate" [weekDateRange]="weekRange">
                            </app-live-class-dashboard>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="tab.label.toLowerCase() === 'students'">
                        <ng-container *ngIf="studentList; else spinner">
                            <app-table [transactions]="studentList" [displayedColumns]="studentColumns" [type]="'students'"></app-table>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="tab.label.toLowerCase() === 'co-teachers'">
                        <ng-container *ngIf="coTeachers; else spinner">
                            <app-table [transactions]="coTeachers" [displayedColumns]="coteacherColumns" [type]="'coteachers'"></app-table>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="tab.label.toLowerCase() === 'posts'">
                        <ng-container *ngIf="posts; else spinner">
                            <app-post-table [transactions]="posts" [displayedColumns]="postsColumn" [sortDirection]="sortDirection" (getSortDirection)="onSortChange($event)" (actionSelected)="onActionSelected($event)"></app-post-table>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="tab.label.toLowerCase() !== 'co-teachers' && tab.label.toLowerCase() !== 'live class' && tab.label.toLowerCase() !== 'posts' && tab.label.toLowerCase() !== 'students' && tab.label.toLowerCase() !== 'settings'">
                        {{tab.label}} works!! but not yet implemented :(
                    </ng-container>

                </mat-tab>
            </mat-tab-group>
        </div>
    </ng-container>
</div>

<ng-template #spinner>
    <app-spinner></app-spinner>
</ng-template>