<div class="analytics-wrapper">
    <div class="stats-wrapper">
        <div class="record ">
            <app-analytic-card [record]="stats.students" [studentsLabel]="studentsLabel"
            [staffLabel] = "staffLabel"
            [teachersLabel]="teachersLabel"></app-analytic-card>
        </div>
        <div class="record teacher-record ">
            <app-analytic-card [record]="stats.teachers" [studentsLabel]="studentsLabel"
            [staffLabel] = "staffLabel"
            [teachersLabel]="teachersLabel"></app-analytic-card>
        </div>
        <div class="record staff-record">
            <app-analytic-card [record]="stats.staff" [studentsLabel]="studentsLabel"
            [staffLabel] = "staffLabel"
            [teachersLabel]="teachersLabel"></app-analytic-card>
        </div>
    </div>
    <div class="visual-wrapper">
        <div class="chart">
            <app-chart></app-chart>
        </div>
        <div class="session">
            <app-session-status [session]="sessionStatus"></app-session-status>
        </div>
    </div>
</div>