import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Course } from '../models/course.model';
import { SnackbarService } from './snackbar.service';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';
import { CourseCategory } from '../models/course-category.model';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  constructor(
    public crud: CrudService,
    private snackbar: SnackbarService
  ) { }

  private isLoadingCategories$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public get isLoadingCategories$(): Observable<boolean> {
    return this.isLoadingCategories$$.asObservable();
  }

  private isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public get isLoading$(): Observable<boolean> {
    return this.isLoading$$.asObservable();
  }

  private courseList$$: BehaviorSubject<Course[]> = new BehaviorSubject<Course[]>(null);

  public get courseList$(): Observable<Course[]> {
    return this.courseList$$.asObservable();
  }

  private courseDetail$$: BehaviorSubject<Course> = new BehaviorSubject<Course>(null);

  public get courseDetail$(): Observable<Course> {
    return this.courseDetail$$.asObservable();
  }

  private courseCategoriesList$$: BehaviorSubject<CourseCategory[]> = new BehaviorSubject<CourseCategory[]>([]);

  public get courseCategoriesList$(): Observable<CourseCategory[]> {
    return this.courseCategoriesList$$.asObservable();
  }

  public get courseCategoryRoute() {
    return environment.apiUrl + 'course-category'
  }

  public createCourseCategory(body) {
    return this.crud.post(this.courseCategoryRoute, body)
  }

  public getCourseCategories() {
    if (this.courseCategoriesList$$.value.length > 0) {
      this.isLoadingCategories$$.next(false);
      return;
    }
    this.isLoadingCategories$$.next(true);
    this.crud.get(this.courseCategoryRoute)
      .subscribe(
        (res: CourseCategory[]) => {
          this.isLoadingCategories$$.next(false);
          this.courseCategoriesList$$.next(res);
        },
        (err) => {
          console.log(err);
          this.snackbar.openSnackbar('unable to get course categories');
          this.isLoadingCategories$$.next(false);
        }
      )
  }

  public updateCourseCategory(id, body) {
    return this.crud.patch(this.courseCategoryRoute + `/${id}`, body);
  }

  public deleteCourseCategory(id) {
    return this.crud.delete(this.courseCategoryRoute + `/${id}`);
  }

  public getCourses(schoolID) {
    this.crud.get(environment.apiUrl + `course/school/${schoolID}`).subscribe((resp) => {
      if (resp) {
        this.courseList$$.next(resp);
      }
    }, (err) => {
      this.snackbar.openSnackbar('unable to fetch courses')
    });
  }

  public getCourseDetail(courseId: string) {
    this.isLoading$$.next(true);
    const courseList = this.courseList$$.value;
    if (courseList && courseList.length > 0) {
      const course = courseList.filter(c => c.id === courseId);
      if (course.length > 0) {
        this.courseDetail$$.next(course[0]);
        this.isLoading$$.next(false);
        return;
      }
    }
    this.crud.get(environment.apiUrl + `course/get/${courseId}`).subscribe((resp) => {
      if (resp) {
        this.courseDetail$$.next(resp);
        this.isLoading$$.next(false);
      }
    }, (err) => {
      this.snackbar.openSnackbar('unable to fetch the course details');
      this.isLoading$$.next(false);
    });
  }

  public createCourse(course: Course) {
    return this.crud.post(environment.apiUrl + 'course', course);
  }

  public updateCourse(course) {
    return this.crud.patch(environment.apiUrl + `course/edit/${course.id}`, course);
  }

  public copyCourseToSchools(courseID: string, schoolIDs: string[]) {
    return this.crud.post(environment.apiUrl + 'course/copy', {
      courseID,
      schoolIDs
    });
  }

  public clear() {
    this.courseList$$.next(null);
    this.courseCategoriesList$$.next([]);
  }

  public setInitialState() {
    this.isLoadingCategories$$.next(true);
  }

  public getBase64Image(url) {
      return this.crud.get(url);
  }
}
