import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { dateFormat, pageSizeOptions, paginatorPageSize } from 'src/app/Constants/Constant';
import { LibraryModel } from 'src/app/models/library.models';
import { FileDirectoryService } from 'src/app/services/file-directory.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LibraryService } from 'src/app/teachers/services/library.service';
import { CreateFolderComponent } from '../create-folder/create-folder.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { FolderDirectoryComponent } from '../folder-directory/folder-directory.component';
import { ViewDocsComponent } from '../view-docs/view-docs.component';
import { CloudContentService } from 'src/app/services/cloud-content.service';
import { USER_TYPES, USER_THEMES } from 'src/app/Constants/user-types-constants';

export const MENU_ITEM = ['Edit', 'Move to', 'Delete'];
const CREATE = 'create';
const dialogWidth = '386px';
const dialogWidth1 = '363px';
const dialogHeight = '404px';
const dialogHeight1 = '469px';
const dialogHeight2 = '190px';

@Component({
  selector: 'app-library-table',
  templateUrl: './library-table.component.html',
  styleUrls: ['./library-table.component.scss']
})
export class LibraryTableComponent implements OnInit, OnChanges {
  pageSizeOptions: number[] = pageSizeOptions;
  pageSize = paginatorPageSize;
  menuListItems = MENU_ITEM;
  checkedBoxCount = 0;
  @Input() displayedColumns: string[];
  @Input() transactions;
  @Input() selectedFiles;
  @Input() toSelect = false;
  @Input() disableFolder = false;
  @Input() disabledFile = false;

  @Input() isCloudContent = false;
  @Input() userType;
  @Output() selectedCount = new EventEmitter();
  @Output() getContent = new EventEmitter();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  userTypes = USER_TYPES;

  selection = new SelectionModel(true, []);
  dataSource;
  sortedData: any;
  dateFormat = '';
  dialogConfig = {
    backdropClass: 'blur',
    disableClose: true,
    panelClass: ['width-class'],
    autoFocus: false,
    width: '',
    height: '',
    data: null
  };
  constructor(
    public cd: ChangeDetectorRef,
    public directoryService: FileDirectoryService,
    public librayserice: LibraryService,
    public snackbar: SnackbarService,
    public dialog: MatDialog,
    private cloudContentSvc: CloudContentService
  ) { }

  ngOnInit(): void {
    this.dateFormat = dateFormat;
  }

  getDate(row) {
    return new Date(row.createdAt);
  }

  isFileSelected(row) {
    if (this.selectedFiles) {
      if (this.selectedFiles.filter(file => file.id === row.id).length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  formatBytes(bytes, decimalPoint = 1) {
    if (bytes === 0) {
      return '--';
    }
    const k = 1000;
    const dm = decimalPoint || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  moveBulkFiles() {
    this.moveToFolder(this.selection.selected);
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.transactions);
    this.cd.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.sort.sort(({ id: 'date', start: 'desc' }) as MatSortable);
    this.dataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter(row => !row.isFolder).length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        if (!row.isFolder) {
          this.selection.select(row);
        }
      });
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  onChangeCheckbox(event, row) {
    event.checked === true ? this.checkedBoxCount += 1 : this.checkedBoxCount -= 1;
    if (event) {
      this.selection.toggle(row);
    }
    this.selectedCount.emit({ checked: event.checked, file: row });
  }

  sortData(sort: Sort) {
    const data = this.transactions.slice();
    if (sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a.name, b.name, isAsc);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getImage(row: LibraryModel) {
    return this.directoryService.getImage(row, this.isCloudContent);
  }

  getContents(row) {
    if (!row.isFolder) {
      if (this.isCloudContent || this.userType === USER_TYPES.superAdmin) {
        this.cloudContentSvc.getCloudFileContent(row.id).subscribe(
          (res) => {
            const filesArray = [{ name: row.name, signedUrl: res.signedURL }];
            this.dialog.open(ViewDocsComponent, {
              width: '95%',
              height: '100%',
              data: { files: filesArray, type: row.name },
              backdropClass: 'blur',
              disableClose: true,
              panelClass: ['full-width', 'preview-doc-container'],
              autoFocus: false
            });
          },
          (err) => {
            this.snackbar.openSnackbar(err.error.error);
          }
        );
        return;
      }
      this.librayserice.getContents(row.id, false).subscribe(
        (res) => {
          const filesArray = [{ name: row.name, signedUrl: res.signedURL }];
          const dialog = this.dialog.open(ViewDocsComponent, {
            width: '95%',
            height: '100%',
            data: { files: filesArray, type: row.name },
            backdropClass: 'blur',
            disableClose: true,
            panelClass: ['student-theme', 'full-width'],
            autoFocus: false
          });
        },
        (err) => {
          this.snackbar.openSnackbar(err.error.error);
        }
      );
    } else {
      this.getContent.emit(row);
    }
  }

  selectMenuActions(item, index) {
    if (index === 0) {
      const file = { name: item.name, type: item.activityType, title: item.title };
      const dialogDetail = { ...this.dialogConfig };
      dialogDetail.width = dialogWidth;
      dialogDetail.height = dialogHeight;
      dialogDetail.data = { type: CREATE, fileData: file };
      if (this.userType === USER_TYPES.superAdmin) {
        dialogDetail.data = { ...dialogDetail.data, themeName: USER_THEMES.superAdmin.name, isCloudLibrary: true };
        dialogDetail.height = '345px';
      }
      const dialog = this.dialog.open(CreateFolderComponent, dialogDetail);
      dialog.afterClosed().subscribe(res => {
        if (res) {
          if (this.userType === USER_TYPES.superAdmin) {
            this.cloudContentSvc.edit(item.id, res).subscribe(
              () => {
                this.snackbar.openSnackbar('sucessfully updated the details');
                this.getContent.emit(item);
              },
              (err) => {
                this.snackbar.openSnackbar(err.error.error);
              }
            );
            return;
          }
          this.librayserice.editFolder(item.id, res).subscribe(
            () => {
              this.snackbar.openSnackbar('sucessfully updated the file details');
              this.getContent.emit(item);
            },
            (err) => {
              this.snackbar.openSnackbar(err.error.error);
            }
          );
        }
      });
    } else if (index === 1) {
      if (item.activityType !== 'folders') {
        this.moveToFolder(item);
      } else {
        this.snackbar.openSnackbar('folders can\'t be moved inside other folders');
      }
    } else if (index === 2) {
      const dialogDetail = { ...this.dialogConfig };
      dialogDetail.panelClass = ['delete-dialog-css'];
      dialogDetail.width = dialogWidth1;
      dialogDetail.height = dialogHeight2;
      dialogDetail.data = { theme: 'teacher' };
      if (this.userType === this.userTypes.superAdmin) {
        dialogDetail.data = { theme: 'super-admin' };
      }
      const dialog = this.dialog.open(DeleteDialogComponent, dialogDetail);
      dialog.afterClosed().subscribe(data => {
        if (data) {
          if (this.userType === this.userTypes.superAdmin) {
            this.cloudContentSvc.deleteContent(item.id).subscribe(
              (res) => {
                this.snackbar.openSnackbar(res.msg);
                this.getContent.emit();
              }
            );
            return;
          }
          this.librayserice.deleteFile(item.id).subscribe(
            (res) => {
              this.snackbar.openSnackbar(res.msg);
              this.getContent.emit();
            }
          );
        }
      });
    }
  }

  moveToFolder(item) {
    const directoryName = [];
    this.transactions.map(file => directoryName.push({ name: file.name, activityType: file.activityType }));
    const dialogDetail = { ...this.dialogConfig };
    dialogDetail.width = dialogWidth;
    dialogDetail.height = dialogHeight1;
    dialogDetail.data = { directoryList: this.transactions.filter(row => row.isFolder), file: item };
    if (this.userType === USER_TYPES.superAdmin) {
      dialogDetail.data = { ...dialogDetail.data, userType: this.userType, isCloudLibrary: true, themeName: USER_THEMES.superAdmin.name };
    }
    const dialog = this.dialog.open(FolderDirectoryComponent, dialogDetail);
    dialog.afterClosed().subscribe(_ => {
      this.selection.clear();
      this.getContent.emit();
    });
  }

}
