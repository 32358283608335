<div class="accept-wrapper student-theme">
    <div class="header">
        <div class="text">
            <div>Accept Invitation</div>
            <div class="img" (click)="close()"> <img src="../../../assets/close.svg"/></div>
        </div>
    </div>

    <div class="choose">Choose Student Profile</div>

    <ng-container *ngIf="childrenProfile && childrenProfile.length > 0">
        <div class="flex">
            <div class="profile" *ngFor="let profile of childrenProfile; let i=index" (click)="selectProfile(i)">  
                <div class="img">
                    <div [ngClass]="{'active' : selectedProfileIndex === i}"><img [src]="profile.profilePicture"/></div>
                    <div *ngIf="selectedProfileIndex === i" class="tick"> <img src="../../../assets/green-marked.svg"/></div>
                </div>
                <div class="name">{{profile.name}}</div>
            </div>
        </div>
    </ng-container>

    <div class="btn">
        <button mat-flat-button type="submit" (click)="acceptInvite()" color="primary" [disabled]="!isSelected">ACCEPT INVITATION</button>
    </div>

</div>