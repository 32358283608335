import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Standard } from '../models/standards.models';
import { Sessions } from '../models/sessions.models';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService implements OnDestroy {
  private standardList$$: BehaviorSubject<Standard[]> = new BehaviorSubject<Standard[]>([]);
  private sessionList$$: BehaviorSubject<Sessions[]> = new BehaviorSubject<Sessions[]>([]);
  private subjectList$$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private sectionList$$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private subscription = new Subscription();
  public creatorId = localStorage.getItem('id');

  public get standardList$(): Observable<Standard[]> {
    return this.standardList$$.asObservable();
  }

  public get isLoading$(): Observable<boolean> {
    return this.isLoading$$.asObservable();
  }

  public get sessionList$(): Observable<Sessions[]> {
    return this.sessionList$$.asObservable();
  }

  public get subjectList$(): Observable<Sessions[]> {
    return this.subjectList$$.asObservable();
  }

  public get sectionList$(): Observable<Sessions[]> {
    return this.sectionList$$.asObservable();
  }

  get standardRoute() {
    return environment.apiUrl + 'standard/create';
  }

  get sessionRoute() {
    return environment.apiUrl + 'session/create';
  }

  get subjectRoute() {
    return environment.apiUrl + 'subject/create';
  }

  get sectionRoute() {
    return environment.apiUrl + 'section/create';
  }

  get standards() {
    return environment.apiUrl + 'standard/get/' + `${this.creatorId}`;
  }

  get sessions() {
    return environment.apiUrl + 'session/get/' + `${this.creatorId}`;
  }

  get subjects() {
    return environment.apiUrl + 'subject/get/' + `${this.creatorId}`;
  }

  get sections() {
    return environment.apiUrl + 'section/get/' + `${this.creatorId}`;
  }

  constructor(
    public crud: CrudService,
    private snackbar: SnackbarService) { }

  createStandard(body) {
    return this.crud.post(this.standardRoute, body);
  }

  createSession(body) {
    return this.crud.post(this.sessionRoute, body)
  }

  createSubject(body) {
    return this.crud.post(this.subjectRoute, body)
  }

  createSection(body) {
    return this.crud.post(this.sectionRoute, body)
  }

  getStandards() {
    if (this.standardList$$.value.length > 0) {
      this.isLoading$$.next(false);
      return
    }
    this.subscription = this.crud.get(this.standards)
      .subscribe(
        (res: Standard[]) => {
          this.isLoading$$.next(false);
          this.standardList$$.next(res);
        },
        (err) => console.log(err)
      )
  }

  getSessions() {
    if (this.sessionList$$.value.length > 0) {
      this.isLoading$$.next(false);
      return
    }
    this.subscription = this.crud.get(this.sessions)
      .subscribe(
        (res: any) => {
          res.map(res => {
            res.name = res.starting + ' - ' + res.ending
          })
          this.isLoading$$.next(false);
          this.sessionList$$.next(res);
          return res;
        },
        (err) => console.log(err),
      )
  }

  getSubjects() {
    if (this.subjectList$$.value.length > 0) {
      this.isLoading$$.next(false);
      return
    }
    this.subscription = this.crud.get(this.subjects)
      .subscribe(
        (res) => {
          this.isLoading$$.next(false);
          this.subjectList$$.next(res);
        },
        (err) => console.log(err)
      )
  }

  getSections() {
    if (this.sectionList$$.value.length > 0) {
      this.isLoading$$.next(false);
      return
    }
    this.subscription = this.crud.get(this.sections)
      .subscribe(
        (res) => {
          this.isLoading$$.next(false);
          this.sectionList$$.next(res);
        },
        (err) => console.log(err)
      )
  }

  updateStandard(id, body) {
    return this.crud.patch(environment.apiUrl + 'standard/update/' + `${id}`, body);
  }

  updateSession(id, body) {
    return this.crud.patch(environment.apiUrl + 'session/update/' + `${id}`, body);
  }

  updateSubject(id, body) {
    return this.crud.patch(environment.apiUrl + 'subject/update/' + `${id}`, body);
  }

  updateSection(id, body) {
    return this.crud.patch(environment.apiUrl + 'section/update/' + `${id}`, body);
  }

  deleteStandard(id, body) {
    // this.standardList$$.next(this.standardList$$.value.filter(value => value.id !== id));
    return this.crud.post(environment.apiUrl + 'standard/delete/' + `${id}`, body);
  }

  deleteSession(id, body) {
    // this.sessionList$$.next(this.sessionList$$.value.filter(value => value.id !== id));
    return this.crud.post(environment.apiUrl + 'session/delete/' + `${id}`, body);
  }

  deleteSubject(id, body) {
    // this.subjectList$$.next(this.subjectList$$.value.filter(value => value.id !== id));
    return this.crud.post(environment.apiUrl + 'subject/delete/' + `${id}`, body);
  }

  deleteSection(id, body) {
    // this.sectionList$$.next(this.sectionList$$.value.filter(value => value.id !== id));
    return this.crud.post(environment.apiUrl + 'section/delete/' + `${id}`, body);
  }

  clearList() {
    this.isLoading$$.next(true);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.subscription.closed) {
      console.log("school service subscription closed")
    }
  }



}
