<div class="bulk-invite-wrapper">
    <div class="header">
        <div>{{getLabelData(title)}}</div>
        <div (click)="closeDialog()" class="back"><img src="../../../assets/close.svg"/></div>
    </div>

    <div class="hr"><hr></div>
    <div class="chip">
        <mat-form-field appearance="outline"  [floatLabel]="'always'">
            <mat-label>Mobile Number(s)</mat-label>
                <mat-chip-list #chipList>
                    <mat-chip
                        [ngClass]="{'active' : isUnregistered(number), 'danger':  (isSubmitted && (isInvalidNumber(number) || isAlreadyInvitedNumber(number))) || (!isValidNumber(number))}"
                        *ngFor="let number of numbers"
                        [selectable]="selectable"
                        [removable]="removable"
                        (removed)="remove(number)">
                        {{ (number.split(' ').length > 1) ? number.split(' ')[1] : number}}
                        <mat-icon matChipRemove *ngIf="removable"><img src="../../../assets/close.svg"/></mat-icon>
                    </mat-chip>
                
                     <input
                        matInput
                        #numberInput
                        [placeholder]= placeholder
                        [formControl]="numberCtrl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)"
                    >
                </mat-chip-list> 
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let filter of filterednumbers | async" [value]="filter">
                        {{filter}}
                    </mat-option>
                </mat-autocomplete>
        </mat-form-field>
    
    </div>

    <div *ngIf="unregisterCount > 0" class="active">
        {{unregisterCount}} mobile number {{unregisterCount > 1 ? 'are' : 'is'}} not registered.
    </div>

    <div *ngIf="invalidCount > 0" class="danger">
        {{invalidCount}} mobile number {{invalidCount > 1 ? 'are' : 'is'}} invalid.
    </div>

    <div class="or">
        <hr><div class="text">Or</div><hr>
    </div>

    <div>
        <app-csv-upload [isSubmitted]="isSubmitted" [alreadyInvitedNumber]= "alreadyInvitedNumbers" [invalidNumber]="invalidNumber" [role]="inviteType" [text]=csvText (numberList)="onImageUpload($event)" ></app-csv-upload> 
    </div>

    <div class="invite-staff" *ngIf="title === 'Invite School Staff' ">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>  Assign Role</mat-label>

            <input matInput type="text" hidden  />
            <mat-error>anshu</mat-error>
            <mat-radio-group
              name="matchOn"
              class="group-vertical"
              [ngModel]="schoolRole"
              (ngModelChange)="onChange($event)"
            >
              <mat-radio-button color="primary" value="admin" >
                {{labelData['schooladmin']}}
              </mat-radio-button>
              <div class="role-mem">
                <mat-radio-button color="primary" value="member" >
                    {{labelData['schoolmember']}}
                </mat-radio-button>
                </div>
            </mat-radio-group>
          </mat-form-field>
    </div>

    <div class="btn">
        <button mat-flat-button color="primary" (click)="sendInvite()">SEND INVITATION</button>
    </div>

</div>