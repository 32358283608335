<div class="child-wrapper student-theme" [ngClass]="{'small-container' : smallContainer}">
    <div class="header">Children</div>

    <div class="container">
        <div class="box" *ngFor="let children of childrenList">
            <div class="profile">
                <div class="img">
                    <img [src]= "children.profilePicture !== '' ? children.profilePicture : '../../../assets/group.svg' "/>
                </div>
                <div class="details">
                    <div class="name">{{children.name}}</div>
                </div>
                <div class="select"> 
                    <button mat-icon-button [matMenuTriggerFor]="menu" >
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                        <button mat-menu-item>
                          <span>Edit Profile</span>
                        </button>
                        <button mat-menu-item>
                          <span>Archive</span>
                        </button>
                      </mat-menu>
                </div>
            </div>

            <div class="task">
                <div class="week">This Week:</div>

                <div class="list">
                    <div><img src="../../../assets/book.svg"/></div>
                    <div class="count">{{children.upcomingTasks === '' ?  0 : children.upcomingTasks }}</div>
                    <div class="name">Upcoming tasks</div>
                </div>
                <div class="list">
                  <div><img src="../../../assets/book.svg"/></div>
                  <div class="pending">{{children.pendingTasks === '' ?  0 : children.pendingTasks}}</div>
                  <div class="name">Pending task</div>
                </div>
                <div class="list">
                  <div><img src="../../../assets/book.svg"/></div>
                  <div class="count">{{children.completedTasks === '' ?  0 : children.completedTasks }}</div>
                  <div class="name">Completed tasks</div>
              </div>
              <div class="list">
                <div><img src="../../../assets/book.svg"/></div>
                <div class="count">{{children.attendance === '' ?  0 : children.attendance}}/40</div>
                <div class="name">Periods attended</div>
              </div>

            </div>

            <div class="btn">
                <button  mat-flat-button color="primary" (click)="gotToClass(children)">GO TO CLASSES</button>
            </div>
        </div>
    </div>
</div>