import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { ClassDetailComponent } from './class-detail/class-detail.component';
import { NoticeBoardComponent } from './notice-board/notice-board.component';
import { ClassPostComponent } from './class-post/class-post.component';
import { TakeTestComponent } from './take-test/take-test.component';
import { TestResultComponent } from './test-result/test-result.component';
// import { ProfileComponent } from 'src/app/shared/profile/profile.component';
import { LiveClassScheduleComponent } from 'src/app/shared/live-class/live-class.component';
import { StudentLiveClassComponent } from '../student-live-class/student-live-class.component';
import { CourseListComponent } from './courses-list/course-list.component';
import { ChildCourseDetailComponent } from './child-course-detail/child-course-detail.component';
import { COURSE_ACTIVITY_ROUTE } from 'src/app/Constants/Constant';
import { ChildWorkComponent } from './child-work/child-work.component';
import { ProfileModule } from 'src/app/profile/profile.module';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'dashboard', component: DashboardComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'my-work' },
      { path: 'home', component: HomeComponent },
      { path: 'class-details/:id', component: ClassDetailComponent },
      { path: 'live-class-schedule', component: LiveClassScheduleComponent },
      { path: 'post/tests/:id', component: TakeTestComponent },
      { path: 'post/:name/:id', component: ClassPostComponent },
      { path: 'material', component: DashboardComponent },
      { path: 'post/tests/:id/result', component: TestResultComponent },
      { path: 'notice board', component: NoticeBoardComponent },
      { path: 'my-work', component: ChildWorkComponent },
      { path: 'profile', loadChildren: () => ProfileModule },
      { path: 'courses', component: CourseListComponent },
      { path: 'courses/:id', component: ChildCourseDetailComponent },
      { path: `courses/${COURSE_ACTIVITY_ROUTE}/tests/:id`, component: TakeTestComponent },
      { path: `courses/${COURSE_ACTIVITY_ROUTE}/tests/:id/result`, component: TestResultComponent },
      { path: `courses/${COURSE_ACTIVITY_ROUTE}/:name/:id`, component: ClassPostComponent },

    ]
  },
  { path: 'class/:id', component: StudentLiveClassComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChildDashboardRoutingModule { }
