import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit {

  @Input() userType: string;

  teacherResponsiblity = [
    'Share class materials',
    'Simplify communication',
    'Improve student engagement',
    'Share digital assignments'
  ];

  adminResponsibility = [
    'Create school',
    'Monitor school performance',
    'Improve school engagement',
    'Share views and announcements'
  ];

  studentResponsibility = [
    'View class materials',
    'Interact with classmates & teachers',
    'Upload your assignments',
    'View your grades & performance'
  ];
  user: any;
  userName: any;

  constructor() { }

  ngOnInit(): void {
    if(this.userType === 'admin') {
      this.user = this.adminResponsibility;
      this.userName = 'For School Administrator';
    } else if(this.userType === 'teacher') {
      this.user = this.teacherResponsiblity;
      this.userName = 'For Teachers';

    } else {
      this.user = this.studentResponsibility;
      this.userName = 'For Parents & Students';
    }
  }

}
