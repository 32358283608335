<ng-container *ngIf="loadingCourseDetails; else content">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #content>
    <div class="table-wrapper">
        <div *ngIf="selectedCourse" class="header">
            <div class="back" (click)="back()"><img src="assets/green-back.svg" /></div>
            <div>{{selectedCourse.name | titlecase}}</div>
        </div>
        <ng-container *ngIf="!isLoading else spinner">
            <div class="dashboard">
                <div>
                    <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef>Date</th>
                            <td [ngClass]="transaction.isOpen ? 'enabled-row':''" mat-cell *matCellDef="let transaction"> {{transaction.createdAt | date: formatDate}} </td>
                        </ng-container>

                        <ng-container matColumnDef="activity">
                            <th mat-header-cell *matHeaderCellDef>Activity Type</th>
                            <td [ngClass]="transaction.isOpen ? 'enabled-row':''" mat-cell *matCellDef="let transaction">
                                <div class="activity">
                                    <div>{{transaction.activityType | titlecase}}</div>
                                    <div class="dot"></div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef>Title</th>
                            <td [ngClass]="transaction.isOpen ? 'enabled-row':''" mat-cell *matCellDef="let transaction"> {{transaction.title | titlecase}} </td>
                        </ng-container>

                        <ng-container matColumnDef="totalmarks">
                            <th mat-header-cell *matHeaderCellDef>Total Marks</th>
                            <td [ngClass]="transaction.isOpen ? 'enabled-row':''" mat-cell *matCellDef="let transaction">{{transaction.activityType === 'materials' ? '--' : transaction.totalMarks}}</td>
                        </ng-container>

                        <ng-container matColumnDef="marks">
                            <th mat-header-cell *matHeaderCellDef>Marks Obtained</th>
                            <td [ngClass]="transaction.isOpen ? 'enabled-row':''" mat-cell *matCellDef="let transaction">{{transaction.activityType === 'materials' ? '--' : transaction.marksObtained}}</td>
                        </ng-container>

                        <ng-container matColumnDef="evaluation">
                            <th mat-header-cell *matHeaderCellDef>Evaluation</th>
                            <td mat-cell *matCellDef="let transaction" [ngClass]="getEvalClass(transaction)">{{transaction.activityType === 'materials' ? '--' : (transaction.evalStatus | titlecase)}}</td>
                        </ng-container>


                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>Status</th>
                            <td mat-cell *matCellDef="let transaction" [ngClass]="getClass(transaction)"> {{transaction.activityType === 'materials' ? '--' : transaction.status | titlecase}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>
                        <tr class="row" mat-row *matRowDef="let row; columns: displayedColumns" (click)="navigateToActivity(row)"></tr>
                    </table>
                </div>
            </div>
            <ng-container>
                <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize></mat-paginator>
            </ng-container>
        </ng-container>
        <ng-template #spinner>
            <app-spinner></app-spinner>
        </ng-template>
    </div>

</ng-template>
