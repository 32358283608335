import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleLiveClassService {

  public classRoute() {
    const teacherID = this.localstorage.getItem('id');
    return environment.apiUrl + 'class/get/' + teacherID;
  }

  public createRoute() {
    return environment.apiUrl + 'class/live/create'
  }

  constructor(public crud: CrudService, public localstorage: LocalStorageService) { }

  public getClasses() {
    return this.crud.get(this.classRoute());
  }

  public createClass(body) {
    return this.crud.post(this.createRoute(), body)
  }

  public checkUserOAuthStatus() {
    return this.crud.get( environment.apiUrl + 'class/authenticate');
  }

  public saveOAuthToken(code: string) {
    return this.crud.post( environment.apiUrl + 'class/save-token', { code });
  }
}
