import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { LivePublisherService } from '../services/live-publisher.service';
import { LiveSessionService } from 'src/app/services/live-session.service';
import { Subscription } from 'rxjs';
import { Participant, LiveSessionState, LiveStream } from 'src/app/models/live-class-models';
import {
  PARTICIPANTS_WINDOW, HANDRAISE_WINDOW, CHAT_WINDOW,
  RELOAD_LIVE_CLASS_PAGE, DO_RELOAD, DO_NOT_RELOAD, RELOAD_LIVE_CLASS_PREV_PAGE
} from 'src/app/Constants/Constant';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TeacherSubscriberService } from '../services/teacher-subscriber.service';

@Component({
  selector: 'app-live-class',
  templateUrl: './live-class.component.html',
  styleUrls: ['./live-class.component.scss'],
})
export class LiveClassComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private livePublisherSvc: LivePublisherService,
    private subscriberSvc: TeacherSubscriberService,
    public localStorage: LocalStorageService,
    public route: ActivatedRoute,
    private liveSessionSvc: LiveSessionService,
    private loc: Location,
  ) {
  }

  private startRejoining: any;
  //managing subscriptions
  private subscriptions: Subscription[] = [];

  private leaveSessionRequested: boolean = false;

  //liveSession id and class id, static for now
  //these will be provided by the previous component.
  private liveSessionId = "";

  //live session info
  public liveSession: any;

  // user id of teacher
  public selfId: string;

  // stream id for publishing
  public streamId: string;
  //to keep track if publishing or not
  public publishing: boolean = false;

  //this id should be assigned to the local video element in html
  private localVideoElId: string = "localVideo";


  //showing and hiding buttons on mousemove
  public showButtons: boolean = false;
  public timer: any;

  //for chat
  public teacher: any;

  //is connection dropped
  public isConnectionDropped: boolean = false;

  public sendChatMessage(event) {
    if (event.chatInputEl.nativeElement.value) {
      this.liveSessionSvc.sendChatMessage(event.chatInputEl.nativeElement.value);
      event.chatInputEl.nativeElement.value = "";
      setTimeout(() => {
        if (event.messagesContainer) {
          event.messagesContainer.nativeElement.scrollTop = event.messagesContainer.nativeElement.scrollHeight + 50;
        }
      }, 10)
    }
  }

  //for accepting raised hand
  public acceptedRaisedHand: string;
  public acceptRaisedHand(participantId: string) {
    if (this.acceptedRaisedHand == participantId) {
      return
    } else if (this.acceptedRaisedHand) {
      this.lowerRaisedHand(this.acceptedRaisedHand)
    }
    this.liveSessionSvc.acceptRaisedHand(participantId);
  }

  public preetyDate(date: Date) {
    return date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })
  }

  //all participants (students)
  public allParticipants: Map<string, Participant>;
  // active participants list
  public activeParticipants: Participant[] = [];

  //to manage notification states
  public liveSessionState: LiveSessionState = null;

  //showing side bars for chat, participants etc. windows.
  public isSideWindowOpen: boolean = false;
  public selectedSideWindow: string = '';

  //different sidewindow names
  public participantsWindow = PARTICIPANTS_WINDOW;
  public handRaiseWindow = HANDRAISE_WINDOW;
  public chatWindow = CHAT_WINDOW;


  public openSideWindow = (windowName: string) => {
    this.liveSessionSvc.openSideWindow(windowName);
    this.isSideWindowOpen = true;
  }

  public closeSideWindow = () => {
    this.liveSessionSvc.closeSideWindow();
    this.isSideWindowOpen = false;
  }

  //managing stream related state
  public isMicOn: boolean = true;
  public isCameraOn: boolean = true;
  public isSharingScreen: boolean = false;
  public isSharingScreenWithCamera: boolean = false;
  public togglingVideoSource: boolean = false;

  //view elements
  @ViewChild('localVideo') videoEl: ElementRef;

  //remote streams and showing loader
  public remoteStreams: LiveStream[] = [];
  public showLoader: boolean = false;

  //lower and accept hand raise
  public lowerRaisedHand = (id: string) => {
    this.liveSessionSvc.lowerHand(id);
  }


  //if screen share supported
  public isScreenShareSupported: boolean = true;

  //showing loader for initial get apis
  public showInitLoader: boolean = true;

  ngOnInit(): void {
    let reloadLiveClass = this.localStorage.getItem(RELOAD_LIVE_CLASS_PAGE)
    if (reloadLiveClass == "" || reloadLiveClass == DO_RELOAD) {
      this.localStorage.setItem(RELOAD_LIVE_CLASS_PAGE, DO_NOT_RELOAD);
      window.location.reload();
    } else {
      this.localStorage.setItem(RELOAD_LIVE_CLASS_PAGE, DO_RELOAD);
      this.localStorage.setItem(RELOAD_LIVE_CLASS_PREV_PAGE, DO_RELOAD);
    }
    if (this.ifMobileOperatingSystem()) {
      this.isScreenShareSupported = false;
    }
    this.selfId = this.localStorage.getItem('id');

    // get the liveSession info when the component loads
    // it is needed to get the teacher stream since each session will have a unique teacher stream id
    this.route.params.subscribe(param => {
      this.liveSessionId = param.id;
      this.liveSessionSvc.getLiveSessionById(this.liveSessionId);
    })

    //subscription for live Session
    this.subscriptions.push(this.liveSessionSvc.liveSession$.subscribe((liveSession) => {
      if (liveSession) {
        this.liveSession = liveSession;
        console.log("liveSession:", liveSession)
        // setting the streamId
        this.streamId = liveSession.teacherStream;
        // joining session when streaming has started.
        this.liveSessionSvc.getChatMessages(liveSession.id);
        this.liveSessionSvc.getClassStudents(this.liveSessionId)
        this.liveSessionSvc.getLiveSessionTeacher(this.liveSessionId)
      }
    }));

    //subscriptions to get all the participants
    this.subscriptions.push(this.liveSessionSvc.participants$.subscribe(
      (participants) => {
        if (participants) {
          this.allParticipants = participants;
          if (this.teacher && this.showInitLoader) {
            this.showInitLoader = false;
            this.JoinSession();
          }
        }
      }
    ));

    this.subscriptions.push(this.liveSessionSvc.teacher$.subscribe((teacher) => {
      if (teacher) {
        this.teacher = teacher;
        if (this.allParticipants && this.showInitLoader) {
          this.showInitLoader = false;
          this.JoinSession();
        }
      }
    }));

    // all active participants details subscription
    this.subscriptions.push(this.liveSessionSvc.activeParticipants$.subscribe(
      (activeParticipants) => {
        this.activeParticipants = activeParticipants;
      }
    ));

    this.subscriptions.push(this.liveSessionSvc.selectedSideWindow$.subscribe(
      (selectedSideWindow) => {
        this.selectedSideWindow = selectedSideWindow;
        if (!selectedSideWindow) {
          this.isSideWindowOpen = false;
        }
      }
    ));

    this.subscriptions.push(this.liveSessionSvc.liveSessionState$.subscribe(
      (liveSessionState) => {
        this.liveSessionState = liveSessionState;
      }
    ));

    this.subscriptions.push(
      this.liveSessionSvc.acceptedRaisedHand$.subscribe((acceptedRaisedHand) => {
        this.acceptedRaisedHand = acceptedRaisedHand;
      })
    )
  }

  private JoinSession = () => {
    this.liveSessionSvc.createLiveSessionSocket(this.liveSessionId, this.selfId, this.liveSession.teacherID, this.localStorage.getItem("token"));
    let pc_config = {
      'iceServers': [{
        'urls': 'stun:stun1.l.google.com:19302'
      }]
    };
    let mediaConstraints = {
      audio: true,
      video: {
        width: { max: 640 },
        height: { max: 360 },
        frameRate: 15
      },
    };
    let sdpConstraints = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: false
    };

    //setting the different constraints related to streaming
    this.livePublisherSvc.setStreamingConstraints(pc_config, mediaConstraints, sdpConstraints);
    this.subscriberSvc.setTeacherStreamId(this.streamId);
    this.subscriberSvc.setStreamingConstraints(pc_config, mediaConstraints, sdpConstraints);
    this.videoEl.nativeElement.id = this.localVideoElId;
    this.videoEl.nativeElement.muted = "muted";
    this.livePublisherSvc.joinSession(this.liveSessionId, this.localVideoElId, this.streamId);
    this.subscriberSvc.joinSession(this.liveSessionId, this.localVideoElId, this.streamId)

    this.subscriptions.push(this.livePublisherSvc.isPublishing$.subscribe(
      (isPublishing) => {
        this.publishing = isPublishing;
        if (isPublishing) {
          this.isCameraOn = true;
          this.isMicOn = true;
          this.isSharingScreen = false;
          this.isSharingScreenWithCamera = false;
        } else {
          //uncomment afterwards
          // this.liveSessionSvc.close();
        }
      }
    ));

    this.subscriptions.push(this.livePublisherSvc.togglingVideoSource$.subscribe((togglingVideoSource) => {
      this.togglingVideoSource = togglingVideoSource;
    }))

    this.subscriptions.push(this.livePublisherSvc.isCameraOn$.subscribe(
      (isCameraOn) => {
        this.isCameraOn = isCameraOn;
      }
    ))

    this.subscriptions.push(this.livePublisherSvc.isMicOn$.subscribe(
      (isMicOn) => {
        this.isMicOn = isMicOn;
      }
    ))

    this.subscriptions.push(this.livePublisherSvc.isSharingScreen$.subscribe((isSharingScreen) => {
      this.isSharingScreen = isSharingScreen;
    }));

    this.subscriptions.push(this.livePublisherSvc.isSharingScreenWithCamera$.subscribe((isSharingScreenWithCamera) => {
      this.isSharingScreenWithCamera = isSharingScreenWithCamera;
    }));

    this.subscriptions.push(this.subscriberSvc.remoteStreams$.subscribe((remoteStreams) => {
      if (this.remoteStreams.length > 0) {
        this.liveSessionSvc.setStudentPublishStatus(true);
      } else {
        this.liveSessionSvc.setStudentPublishStatus(false);
      }
      for (let i = 0; i < remoteStreams.length; i++) {
        remoteStreams[i].source.name = this.allParticipants.get(remoteStreams[i].source.streamId).name;
        remoteStreams[i].source.profilePicture = this.allParticipants.get(remoteStreams[i].source.streamId).profilePicture;
        remoteStreams[i].source.profileSrc = this.allParticipants.get(remoteStreams[i].source.streamId).profileSrc;
      }
      this.remoteStreams = remoteStreams;
    }));

    this.subscriptions.push(this.livePublisherSvc.isConnectionClosed$.subscribe((isConnectionClosed) => {
      if (isConnectionClosed) {
        this.isConnectionDropped = true;
        console.log("connection is closed now spanning a new one")
        if (!this.leaveSessionRequested && !this.livePublisherSvc.isJoiningSession()) {
          this.subscriberSvc.leaveSession();
          this.livePublisherSvc.leaveSession();
        }
      }
    }));

    this.subscriptions.push(this.subscriberSvc.isConnectionClosed$.subscribe((isConnectionClosed) => {
      if (isConnectionClosed) {
        console.log("connection is closed now spanning a new one")
        if (!this.leaveSessionRequested && !this.subscriberSvc.isJoiningSession()) {
          this.subscriberSvc.reJoinSession();
        }
      }
    }));

    this.subscriptions.push(this.subscriberSvc.isConnectionDropped$.subscribe((isConnectionDropped) => {
      this.isConnectionDropped = isConnectionDropped;
      if (this.isConnectionDropped) {
        this.subscriberSvc.leaveSession();
        this.livePublisherSvc.leaveSession();
      }
    }));

    this.subscriptions.push(this.livePublisherSvc.showLoader$.subscribe((showLoader) => {
      this.showLoader = showLoader;
    }));
  }

  //stream related functions
  public turnOnCamera = () => {
    if (this.isSharingScreen || this.isSharingScreenWithCamera) {
      return
    }
    this.livePublisherSvc.turnOnCamera();
  }

  public turnOffCamera = () => {
    if (this.isSharingScreen || this.isSharingScreenWithCamera) {
      return
    }
    this.livePublisherSvc.turnOffCamera();
  }

  public turnOnMic() {
    this.livePublisherSvc.turnOnMic();
  }

  public turnOffMic() {
    this.livePublisherSvc.turnOffMic();
  }

  public startScreenShare() {
    this.livePublisherSvc.startScreenShare();
  }

  public stopScreenShare() {
    this.livePublisherSvc.stopScreenShare(this.streamId);
  }

  public startScreenShareWithCamera() {
    this.livePublisherSvc.startScreenShareWithCamera();
  }

  public stopScreenShareWithCamera() {
    this.livePublisherSvc.stopScreenShare(this.streamId);
  }

  private ifMobileOperatingSystem(): boolean {
    let win: any = window;
    var userAgent = navigator.userAgent || navigator.vendor || win.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent) || /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }
    return false;
  }

  public leaveSession() {
    this.leaveSessionRequested = true;
    this.livePublisherSvc.leaveSession();
    this.loc.back();
  }


  ngAfterViewInit() {
    this.videoEl.nativeElement.addEventListener('mousemove', (e) => {
      if (e.target != e.currentTarget) {
        return
      }
      this.showButtons = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showButtons = false;
      }, 3000)
    });

    let videoPlacerHolderRef = document.getElementById("video-placeholder");
    videoPlacerHolderRef.addEventListener('mousemove', (e) => {
      if (e.target != e.currentTarget) {
        return
      }
      this.showButtons = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showButtons = false;
      }, 3000)
    });
  }


  public reJoin() {
    window.location.reload();
  }

  public end() {
    this.loc.back();
  }

  ngOnDestroy() {
    this.liveSessionSvc.setInitialState();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

}
