<div class="material-wrapper">
    <div class="text">
        <div>{{textHeader | titlecase}} Material</div>
        <div (click)="closeDialog()" class="close"><img src="../../../assets/close.svg" /></div>
    </div>

    <div class="header">Enter details</div>

    <div class="form">
        <form [formGroup]="materialForm" (ngSubmit)="postMaterial()">
            <div class="title">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Material Title</mat-label>
                    <input matInput placeholder="Title" formControlName="title" autocomplete="off">
                    <mat-error *ngIf="!materialForm.controls['title'].valid && materialForm.controls['title'].touched">Title is required</mat-error>
                </mat-form-field>
            </div>

            <div class="description">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Description</mat-label>
                    <textarea matInput rows="5" placeholder="Enter material short description" formControlName="note" autocomplete="off"></textarea>
                    <mat-error *ngIf="!materialForm.controls['note'].valid && materialForm.controls['note'].touched">Description is required</mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="!isCourseActivity" class="date">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Due Date</mat-label>
                    <input matInput placeholder="DD-MM-YY" formControlName="dueDate" [disabled]="isUploading" (click)="picker.open()" [min]="minDate" [matDatepicker]="picker" (dateChange)="addEvent($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                        <mat-icon matDatepickerToggleIcon>
                            <img [src]="materialForm.controls['dueDate'].value !== null ? '../../../assets/marked-calender.svg' : '../../../assets/calender.svg'" />
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="!materialForm.controls['dueDate'].valid && materialForm.controls['dueDate'].touched">Due date is required</mat-error>
                </mat-form-field>
            </div>
            <ng-container *ngIf="isCourseActivity else upload">
                <div class="upload">
                    <app-doc-upload [isEdit]="isEditAction" [isCourseActivity]="isCourseActivity" [text]="'UPLOAD MATERIAL'" [fileNames]="docFiles" [disabled]="disable" [uploadFromLibrary]="data.post && data.post.uploadFromLibrary" [isdocUploaded]="isdocUploaded" (docUrl)="onDocUpload($event)"
                        [ngStyle]="{'color': '#607d8b'}"></app-doc-upload>
                </div>
            </ng-container>
            <ng-template #upload>
                <div class="upload">
                    <app-doc-upload [disabled]="isUploading" [isEdit]="isEditAction" [text]="'UPLOAD MATERIAL'" [fileNames]="docFiles" [disabled]="disable" [uploadFromLibrary]="data.post && data.post.uploadFromLibrary" [isdocUploaded]="isdocUploaded" (docUrl)="onDocUpload($event)"></app-doc-upload>
                </div>
            </ng-template>

            <ng-container *ngIf="isUploading">
                <div class="progress">
                    <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
                </div>
            </ng-container>

            <ng-container *ngIf="!isCourseActivity">
                <mat-checkbox color="primary" [value]="checked" (change)="onCheckboxClick($event)" [disabled]="isUploading || (uploadFromLibrary && data.post || disable)">Save to Library</mat-checkbox>
            </ng-container>

            <div class="btn">
                <button mat-flat-button color="primary" type="submit" [ngClass]="{'disabled': isUploading}">SUBMIT</button>
            </div>

        </form>
    </div>
</div>
