import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Participant, ChatMessage, LiveSessionState, LiveSessionMessage, ActiveParticipantDetails } from '../models/live-class-models';
import {
  PARTICIPANTS_WINDOW, HANDRAISE_WINDOW, CHAT_WINDOW, ALL_ACTIVE_PARTICIPANT_PING, PARTICIPANT_REMOVED,
  NEW_PARTICIPANT_ADDED, HAND_RAISE, HAND_LOWER, ACCEPT_HAND_RAISE, CHAT
} from '../Constants/Constant';
import { CrudService } from './crud.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class LiveSessionService {

  constructor(
    private crud: CrudService,
    private snackBar: SnackbarService) {
  }

  // session related info
  private selfId: string;

  private liveSession$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get liveSession$(): Observable<any> {
    return this.liveSession$$.asObservable()
  }

  //teacher details
  private teacher$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get teacher$(): Observable<any> {
    return this.teacher$$.asObservable()
  }

  //all students
  private participants$$: BehaviorSubject<Map<string, Participant>> = new BehaviorSubject(new Map<string, Participant>());

  public get participants$(): Observable<Map<string, Participant>> {
    return this.participants$$.asObservable()
  }

  //all active participants
  private activeParticipants$$: BehaviorSubject<Participant[]> = new BehaviorSubject([]);

  public get activeParticipants$(): Observable<Participant[]> {
    return this.activeParticipants$$.asObservable()
  }

  //all chat messages
  private chatMessages$$: BehaviorSubject<ChatMessage[]> = new BehaviorSubject([]);

  public get chatMessages$(): Observable<ChatMessage[]> {
    return this.chatMessages$$.asObservable()
  }

  //to keep track of selected side window
  private selectedSideWindow$$: BehaviorSubject<string> = new BehaviorSubject('');

  public get selectedSideWindow$(): Observable<string> {
    return this.selectedSideWindow$$.asObservable()
  }

  public participantsWindow = PARTICIPANTS_WINDOW;
  public handRaiseWindow = HANDRAISE_WINDOW;
  public chatWindow = CHAT_WINDOW;


  //teacher details
  private isConnectionDropped$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get isConnectionDropped$(): Observable<boolean> {
    return this.isConnectionDropped$$.asObservable()
  }

  //livesession state for notifications
  private initialState: LiveSessionState = { unseenRaisedHands: 0, unreadChatMessages: 0, totalParticipants: 0 }
  private liveSessionState$$: BehaviorSubject<LiveSessionState> = new BehaviorSubject(this.initialState);

  public get liveSessionState$(): Observable<LiveSessionState> {
    return this.liveSessionState$$.asObservable()
  }

  //new raised hands i.e. when window is not open(used to keep track of count in notification)
  private newRaisedHands: string[] = [];

  //live socket related vars
  private liveSessionSocket: WebSocket;

  private liveSessionSocketStatus: boolean = false;

  //to keep track of the accepted raised hand
  private acceptedRaisedHand$$: BehaviorSubject<string> = new BehaviorSubject('');

  public get acceptedRaisedHand$(): Observable<string> {
    return this.acceptedRaisedHand$$.asObservable()
  }

  //fail count for socket connection
  private failCount: number = 0;

  //to be removed participants(in case of a accepted hand raised participant connection drops)
  private toBeRemovedParticipants: string[] = [];

  //is self hand raised (for student)
  private isSelfHandRaised$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get isSelfHandRaised$(): Observable<boolean> {
    return this.isSelfHandRaised$$.asObservable()
  }

  //to check if the accepted raised hand student is publishing
  private isStudentPublishing: boolean = false;

  public setStudentPublishStatus(ispublishing: boolean) {
    this.isStudentPublishing = ispublishing;
  }

  //
  private shouldRetry: boolean = true;

  public setInitialState = () => {
    this.shouldRetry = false;
    if (this.liveSessionSocket) {
      this.liveSessionSocket.close();
    }
    this.liveSessionSocketStatus = false;
    this.isStudentPublishing = false;
    this.isSelfHandRaised$$.next(false);
    this.toBeRemovedParticipants = [];
    this.failCount = 0;
    this.acceptedRaisedHand$$.next('');
    this.newRaisedHands = [];
    this.liveSessionState$$.next(this.initialState)
    this.selectedSideWindow$$.next('');
    this.chatMessages$$.next([]);
    this.participants$$.next(new Map<string, Participant>());
    this.activeParticipants$$.next([]);
    this.selectedSideWindow$$.next('');
    this.liveSession$$.next(null);
    this.teacher$$.next(null);
  }

  //request to get the live session details (request url needs to be changed)
  public getLiveSessionById(liveSessionId: string) {
    this.crud.get(environment.apiUrl + 'class/live/' + liveSessionId).subscribe(
      (resp: any) => {
        this.liveSession$$.next(resp);
      }
    );
  }

  //request to get the live class teacher (request url needs to be changed)
  public getLiveSessionTeacher(liveSessionId: string) {
    this.crud.get(environment.apiUrl + 'class/live/' + liveSessionId + '/teacher').subscribe(
      (resp: any) => {
        this.crud.get(resp.profilePicture).subscribe(
          (res) => {
            resp.profileSrc = res
            this.teacher$$.next(resp);
          }
          ,
          (error) => console.log(error)
        )
      }
    );
  }

  //request to get all the students corresponding to the live session
  public getClassStudents(liveSessionId: string) {
    this.crud.get(environment.apiUrl + 'class/live/' + liveSessionId + '/students').subscribe(
      (resp: any) => {
        let studentsMap = new Map<string, Participant>();
        for (let i = 0; i < resp.length; i++) {
          let student: Participant = resp[i]
          student.isActive = false;
          student.isHandRaised = false;
          studentsMap.set(student.id, { ...student });
        }
        studentsMap = this.getBase64Image(studentsMap)
        this.participants$$.next(studentsMap);
      }
    );
  }

  getBase64Image(studentsMap: Map<string, Participant>) {
    studentsMap.forEach((participant, id) => {
      this.crud.get(participant.profilePicture).subscribe(
        (res) => participant.profileSrc = res),
        (error) => console.log(error)
    })
    return studentsMap
  }

  //request to get all the students corresponding to the live session
  public getChatMessages(liveSessionId: string) {
    this.crud.get(environment.apiUrl + 'class/live/' + liveSessionId + '/chat-messages').subscribe(
      (resp: any) => {
        let newState = this.liveSessionState$$.value;
        let chatMessages: ChatMessage[] = [];
        for (let i = 0; i < resp.length; i++) {
          let newMessage = resp[i]
          let chatMessage: ChatMessage = {
            id: '',
            sendAt: new Date(newMessage.sendAt),
            participantId: newMessage.senderId,
            msg: newMessage.msg,
          };
          chatMessages.push(chatMessage);
        }
        this.chatMessages$$.next(chatMessages);
        if (this.selectedSideWindow$$.value != this.chatWindow) {
          newState.unreadChatMessages = resp.length;
        }
        this.liveSessionState$$.next(newState);
      }
    );
  }

  //opening and closing side window
  public openSideWindow(windowName: string) {
    this.selectedSideWindow$$.next(windowName);
    let newState = this.liveSessionState$$.value;
    switch (windowName) {
      case HANDRAISE_WINDOW:
        newState.unseenRaisedHands = 0;
        this.newRaisedHands = [];
        break;
      case CHAT_WINDOW:
        newState.unreadChatMessages = 0;
        break;
    }
    this.liveSessionState$$.next(newState);
  }

  public closeSideWindow() {
    this.selectedSideWindow$$.next('');
  }

  //to initiate the socket connection, here the url is temp, it needs to be changed
  public createLiveSessionSocket(liveSessionId: string, participantId: string, teacherId: string, jwtToken: string) {
    if (!this.liveSessionSocketStatus) {
      this.liveSessionSocketStatus = true;
      this.liveSessionSocket = new WebSocket(environment.socketServerUrl + '/ws?liveClassID='
        + liveSessionId + '&clientID=' + participantId + '&teacherID=' + teacherId
        + '&isHandRaised=' + this.isSelfHandRaised$$.value + '&jwt-token=' + jwtToken);
      this.selfId = participantId;
      this.liveSessionSocket.onopen = (event) => {
        this.shouldRetry = true;
        this.failCount = 0;
        this.liveSessionSocketStatus = true;

        if (teacherId == participantId) {
          this.acceptRaisedHand(this.acceptedRaisedHand$$.value);
        }
      };

      this.liveSessionSocket.onclose = (event) => {
        this.liveSessionSocketStatus = false;
        if (this.shouldRetry) {
          this.retrySocket(liveSessionId, participantId, teacherId, jwtToken)
        }
      };


      this.liveSessionSocket.onmessage = (event) => {
        let data = JSON.parse(event.data);
        switch (data.event) {
          case ALL_ACTIVE_PARTICIPANT_PING:
            this.processAllActiveParticipantPing(data);
            break;
          case PARTICIPANT_REMOVED:
            this.removeActiveParticipant(data);
            break;
          case NEW_PARTICIPANT_ADDED:
            this.addNewParticipant(data);
            break;
          case HAND_RAISE:
            this.processRaisedHand(data);
            break;
          case HAND_LOWER:
            this.processHandLower(data);
            break;
          case ACCEPT_HAND_RAISE:
            this.processAcceptHandRaise(data)
            break;
          case CHAT:
            this.processChatMessage(data)
            break;
        }
      };
      this.liveSessionSocket.onerror = (error) => {
        console.log("error received from socket:", error)
      }
    }
  }

  //methods for raising and lowering hand used by student
  public raiseHand(participantId: string) {
    if (this.selfId === participantId) {
      this.isSelfHandRaised$$.next(true);
    }
    let message: LiveSessionMessage = {
      event: HAND_RAISE,
      data: {
        participantId: participantId
      }
    }
    this.sendMessage(message);
  }

  public lowerHand(participantId: string) {
    if (this.selfId === participantId) {
      this.isSelfHandRaised$$.next(false);
    }
    if (this.acceptedRaisedHand$$.value === participantId) {
      this.acceptedRaisedHand$$.next("");
    }
    if (this.toBeRemovedParticipants.includes(participantId)) {
      this.removeActiveParticipant({ participantId: participantId })
    }
    let newState = this.liveSessionState$$.value;
    if (this.newRaisedHands.includes(participantId)) {
      if (newState.unseenRaisedHands > 0) {
        newState.unseenRaisedHands--;
        this.newRaisedHands = this.newRaisedHands.filter((id) => id != participantId);
      }
    }
    this.liveSessionState$$.next(newState);
    let message: LiveSessionMessage = {
      event: HAND_LOWER,
      data: {
        participantId: participantId
      }
    }
    //updating the local state
    let activeParticipants = this.activeParticipants$$.value;
    let index = activeParticipants.findIndex(
      (participant) => participant.id === participantId);
    if (index != -1) {
      let participant = activeParticipants[index]
      participant.isHandRaised = false;
      activeParticipants[index] = participant;
      this.activeParticipants$$.next(activeParticipants);
    }
    this.sendMessage(message);
  }

  public acceptRaisedHand(participantId: string) {
    this.acceptedRaisedHand$$.next(participantId)
    if (this.liveSessionSocket) {
      let message: LiveSessionMessage = {
        event: ACCEPT_HAND_RAISE,
        data: {
          participantId: participantId
        }
      }
      this.sendMessage(message);
    }
  }

  //to send chat message used by both teacher and student
  public sendChatMessage(msg: string) {
    let dt = new Date().getTime();
    let id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    let message: LiveSessionMessage = {
      event: CHAT,
      data: {
        msg: msg,
        id: id
      }
    };
    this.sendMessage(message);
  }

  //to close the connection
  public close() {
    if (this.liveSessionSocket) {
      this.liveSessionSocketStatus = false;
      this.liveSessionSocket.close();
    }
  }

  //processing the all active participant ping (received when socket is connected)
  private processAllActiveParticipantPing(data: any) {
    let newState = this.liveSessionState$$.value;
    newState.totalParticipants = 0;
    newState.unseenRaisedHands = 0;

    let details: ActiveParticipantDetails[] = data.activeParticipants;
    let participants = this.participants$$.value;
    let updatedActiveParticipants: Participant[] = this.activeParticipants$$.value;
    let activeIds: string[] = [];
    for (let i = 0; i < details.length; i++) {
      if (participants.has(details[i].id)) {
        let participant = participants.get(details[i].id);
        participant.joinedAt = new Date(details[i].joinedAt);
        participant.isHandRaised = details[i].isHandRaised;
        if (!participant.isActive) {
          updatedActiveParticipants.push(participant);
          if (participant.isHandRaised) {
            if (this.newRaisedHands.includes(participant.id)) {
              newState.unseenRaisedHands++;
            }
          }
        }
        participant.isActive = true;
        // incrementing active participants
        newState.totalParticipants++;
      }
      activeIds.push(details[i].id);
    }
    //setting isactive to false for the participant which are not active now
    let inactiveParticipants = updatedActiveParticipants.filter((participant) => !activeIds.includes(participant.id))
    for (let i = 0; i < inactiveParticipants.length; i++) {
      participants.get(inactiveParticipants[i].id).isActive = false;
    }

    updatedActiveParticipants = updatedActiveParticipants.filter((participant) => activeIds.includes(participant.id))

    //removing active from to be removed
    this.toBeRemovedParticipants = this.toBeRemovedParticipants.filter((id) => !activeIds.includes(id))

    this.activeParticipants$$.next(updatedActiveParticipants);
    //updating the state
    this.liveSessionState$$.next(newState);
  }

  //removing participant
  removeActiveParticipant(data: any) {
    if (this.acceptedRaisedHand$$.value == data.participantId && this.isStudentPublishing) {
      this.toBeRemovedParticipants.push(data.participantId);
    } else {
      let newState = this.liveSessionState$$.value;
      let activeParticipants = this.activeParticipants$$.value;
      activeParticipants = activeParticipants.filter((participant) =>
        participant.id != data.participantId
      )
      if (this.participants$$.value.has(data.participantId)) {
        let participant = this.participants$$.value.get(data.participantId);
        participant.isActive = false;
        this.activeParticipants$$.next(activeParticipants);
        //decrementing active participant
        if (newState.totalParticipants > 0) {
          newState.totalParticipants--;
        }
        if (participant.isHandRaised) {
          //check if hand raised is in newly raised hands
          let id = this.newRaisedHands.find((id) => id === participant.id);
          if (id) {
            this.newRaisedHands = this.newRaisedHands.filter((id) => id != participant.id);
            newState.unseenRaisedHands--;
          }
        }
        participant.isHandRaised = false;
        //updating the state
        this.liveSessionState$$.next(newState);
      }
    }
  }

  //adding a participant
  addNewParticipant(data: any) {
    let newState = this.liveSessionState$$.value;
    this.toBeRemovedParticipants = this.toBeRemovedParticipants.filter((id) => id != data.participantId);
    let participants = this.participants$$.value;
    if (participants.has(data.participantId)) {
      let detail: ActiveParticipantDetails = { id: data.participantId, joinedAt: new Date(data.joinedAt), isHandRaised: data.isHandRaised }
      let participant: Participant = participants.get(data.participantId)
      participant.joinedAt = new Date(detail.joinedAt);
      participant.isHandRaised = detail.isHandRaised;
      if (participant.isHandRaised) {
        //check if hand raised is in newly raised hands
        let id = this.newRaisedHands.find((id) => id === participant.id);
        if (id) {
          this.newRaisedHands = this.newRaisedHands.filter((id) => id != participant.id);
          newState.unseenRaisedHands++;
        }
      }
      if (!participant.isActive) {
        let activeParticipants = this.activeParticipants$$.value;
        activeParticipants.push(participant);
        this.activeParticipants$$.next(activeParticipants);
        //updating the state
        newState.totalParticipants++;
      }
      participant.isActive = true;
      this.liveSessionState$$.next(newState);
    }
  }

  processRaisedHand(data: any) {
    let newState = this.liveSessionState$$.value;
    let activeParticipants = this.activeParticipants$$.value;
    let index = activeParticipants.findIndex(
      (participant) => participant.id === data.data.participantId);
    if (index != -1) {
      let participant = activeParticipants[index]
      participant.isHandRaised = true;
      activeParticipants[index] = participant;
      this.activeParticipants$$.next(activeParticipants);

      //updating raised hands
      //check: for opended hand raised window
      if (this.selectedSideWindow$$.value != HANDRAISE_WINDOW) {
        newState.unseenRaisedHands++;
        this.newRaisedHands.push(data.data.participantId);
      }
    }
    this.participants$$.value.get(data.data.participantId).isHandRaised = true;
    this.liveSessionState$$.next(newState);
  }

  processHandLower(data: any) {
    if (this.selfId === data.data.participantId) {
      this.isSelfHandRaised$$.next(false);
    }

    let newState = this.liveSessionState$$.value;

    let activeParticipants = this.activeParticipants$$.value;
    let index = activeParticipants.findIndex(
      (participant) => participant.id === data.data.participantId);
    if (index != -1) {
      let participant = activeParticipants[index]
      participant.isHandRaised = false;
      activeParticipants[index] = participant;
      this.activeParticipants$$.next(activeParticipants);

      //updating raised hands
      //check: for opened hand raised window
      if (this.selectedSideWindow$$.value != HANDRAISE_WINDOW) {
        let ind = this.newRaisedHands.findIndex((id) => id === data.data.participantId)
        if (ind > -1) {
          if (newState.unseenRaisedHands > 0) {
            newState.unseenRaisedHands--;
          }
          this.newRaisedHands = this.newRaisedHands.filter((id) => id != data.data.participantId);
        }
      }
    }
    if (this.acceptedRaisedHand$$.value == data.data.participantId) {
      this.acceptedRaisedHand$$.next("");
    }
    this.liveSessionState$$.next(newState);
  }

  // TODO: implement this
  processAcceptHandRaise(data: any) {
    this.acceptedRaisedHand$$.next(data.data.participantId)
  }

  processChatMessage(data: any) {
    let newState = this.liveSessionState$$.value;
    let chatMessages = this.chatMessages$$.value;
    if (data.data.senderId == this.selfId) {
      let index = chatMessages.findIndex((message) => message.id === data.data.id);
      if (index != -1) {
        chatMessages[index].sendAt = new Date(data.data.sendAt);
      }
    } else {
      let chatMessage: ChatMessage = {
        id: data.data.id, sendAt: new Date(data.data.sendAt), msg: data.data.msg,
        participantId: data.data.senderId
      }
      chatMessages.push(chatMessage);

      if (this.selectedSideWindow$$.value !== CHAT_WINDOW) {
        newState.unreadChatMessages++;
      }
    }
    this.chatMessages$$.next(chatMessages);
    this.liveSessionState$$.next(newState);
  }

  private sendMessage(message: LiveSessionMessage) {
    if (this.liveSessionSocket) {
      this.liveSessionSocket.send(JSON.stringify(message));
    }
    if (message.event === CHAT) {
      let chatMessages = this.chatMessages$$.value;
      let chatMessage: ChatMessage = {
        id: message.data.id,
        msg: message.data.msg,
        participantId: this.selfId,
        sendAt: null,
      }
      chatMessages.push(chatMessage);
      this.chatMessages$$.next(chatMessages)
    }
  }

  retrySocket = (liveSessionId, participantId, teacherId, jwtToken) => {
    setTimeout(() => {
      if (!this.liveSessionSocketStatus) {
        this.failCount++;
        if (this.failCount > 2) {
          this.snackBar.openSnackbar("can not establish socket connection to server ")
          //showing an alert for reload
          this.isConnectionDropped$$.next(true)
          this.failCount = 0;
          return;
        }
        this.createLiveSessionSocket(liveSessionId, participantId, teacherId, jwtToken);
      } else {
        this.failCount = 0;
      }
    }, 2000);
  }
}
