import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { nameregex } from 'src/app/Constants/Constant';
import { UserApiService } from 'src/app/services/api/user.api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-create-student-profile',
  templateUrl: './create-student-profile.component.html',
  styleUrls: ['./create-student-profile.component.scss'],
})
export class CreateStudentProfileComponent implements OnInit {

  imgUrl: string | ArrayBuffer;
  isSubmitted = false;
  profileForm: FormGroup
  isImageUploaded: boolean;
  genders = ['Male', 'Female'];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DashboardComponent>,
    private userService: UserApiService,
    public _snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: `0px`});
    this.buildForm()
  }

  buildForm() {
    this.profileForm = this.fb.group({
      'name': [null, [Validators.required, Validators.pattern(nameregex)]],
      'gender': [null, [Validators.required]],
      'username': [null, [Validators.required]],
      'pin': [null, [Validators.required]],
    })
  }

  close() {
    this.dialogRef.close()
  }

  onImageUpload(url) {
    this.imgUrl = url ? url : null;
    this.isImageUploaded = url ? true : false;
  }


  getErrorName() {
    return this.profileForm.get('name').hasError('required') ? 'Field is required' :
      this.profileForm.get('name').hasError('pattern') ? 'Not a valid name' : null
  }


  onSubmit() {
    if(this.profileForm.valid && this.imgUrl) {
      this.isSubmitted = true;
      const requestBody = {
        name: this.profileForm.controls['name'].value.trim(),
        gender: this.profileForm.controls['gender'].value.trim(),
        username: this.profileForm.controls['username'].value.trim(),
        Pin: this.profileForm.controls['pin'].value.toString().trim(),
        profilePicture: this.imgUrl,
      }
      this.userService.login('student', requestBody).subscribe(
        (res) => {
          this._snackbar.openSnackbar("student added succesfully")
          this.dialogRef.close("success")
        }, (err) => {
          this.isSubmitted = false;
          this._snackbar.openSnackbar(err.error.error)
        }
      )
      return
    }
    if(!this.imgUrl) {
      this.isImageUploaded = false
    }
    this._snackbar.openSnackbar("please fill all details")
  }
}

