<div class="wrapper">
    <div class="text">
        <div>{{labelData['createclass']}}</div>
        <div (click)="closeDialog()" class="close"><img src="../../../assets/close.svg"/></div>
    </div>

    <div class="header">Enter details</div>

    <div class="form">
        <form  [formGroup]="classForm" (ngSubmit)="createClass()">
            <div class="school" >
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>{{labelData['selectschool']}}</mat-label>
                    <mat-select [disabled]="classDetail" [compareWith]="compareObjects" placeholder="{{labelData['selectschool']}}" formControlName="school" (selectionChange)="schoolSelected($event)" >
                        <mat-option *ngFor="let school of schools" [value]="school">
                          {{school.name}}
                        </mat-option>
                      </mat-select>
                    <mat-error *ngIf="!classForm.controls['school'].valid && classForm.controls['school'].touched">
                        {{labelData['pleaseselectschool']}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="name">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label> {{labelData['classname']}}</mat-label>
                    <input matInput placeholder="Enter Name" formControlName="className" autocomplete="off">
                    <mat-error *ngIf="classForm.controls['className'].touched && classForm.controls['className'].hasError('required')">{{labelData['classname']}} is required</mat-error>
                    <mat-error *ngIf="classForm.controls['className'].hasError('maxlength')">{{labelData['class']}} length should not exceed more than 100</mat-error>
                </mat-form-field>
            </div>

            <div class="group">
                <div class="standard" >
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>{{labelData['standard']}}</mat-label>
                        <mat-select placeholder="{{labelData['selectstandard']}}" [compareWith]="compareObjects" formControlName="standard" [disabled]="isSchoolSelected">
                            <mat-option *ngFor="let standard of standards" [value]="standard">
                              {{standard.name}}
                            </mat-option>
                          </mat-select>
                        <mat-error *ngIf="!classForm.controls['standard'].valid && classForm.controls['standard'].touched">
                            {{labelData['pleaseselectstandard']}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="section" >
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>{{labelData['section']}}</mat-label>
                        <mat-select placeholder="{{labelData['selectsection']}}" [compareWith]="compareObjects" formControlName="section" [disabled]="isSchoolSelected" >
                            <mat-option *ngFor="let section of sections" [value]="section">
                              {{section.name}}
                            </mat-option>
                          </mat-select>
                        <mat-error *ngIf="!classForm.controls['section'].valid && classForm.controls['section'].touched">
                            {{labelData['pleaseselectsection']}}
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>

            <div class="subject" >
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>{{labelData['subject']}}</mat-label>
                    <mat-select placeholder="{{labelData['selectsubject']}}" [compareWith]="compareObjects" formControlName="subject" [disabled]="isSchoolSelected" >
                        <mat-option *ngFor="let subject of subjects" [value]="subject">
                          {{subject.name}}
                        </mat-option>
                      </mat-select>
                    <mat-error *ngIf="!classForm.controls['subject'].valid && classForm.controls['subject'].touched">
                        {{labelData['pleaseselectsubject']}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="session" >
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>{{labelData['session']}}</mat-label>
                    <mat-select placeholder="{{labelData['selectsession']}}" [compareWith]="compareObjects" formControlName="session" [disabled]="isSchoolSelected" >
                        <mat-option *ngFor="let session of sessions" [value]="session">
                          {{session.name}}
                        </mat-option>
                      </mat-select>
                    <mat-error *ngIf="!classForm.controls['session'].valid && classForm.controls['session'].touched">
                        {{labelData['pleaseselectsession']}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="btn">
                <button mat-flat-button color="primary" type="submit" [disabled]="isSubmitted">{{labelData['createclass']}}</button>
            </div>

        </form>
    </div>
</div>
