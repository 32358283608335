import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AcceptInviteComponent } from '../accept-invite/accept-invite.component';
import { ParentService } from '../services/parent.service';
import { Subject } from 'rxjs';
import { takeUntil, switchMap, mapTo, flatMap } from 'rxjs/operators';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TeacherInvite } from 'src/app/models/teacher-invite.models';
import { Student } from 'src/app/models/student.models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  showChildren: boolean = false;
  tasks = [
    'Create your child’s profile while joining a class',
    'Moniter your child’s activity',
    'Interact with teachers',
  ];
  childrenList = [];
  teacherInvite:  TeacherInvite[] = [];
  isLoading : boolean;
  private destroy$$ : Subject<any> = new Subject<any>();

  constructor(private dialog: MatDialog, public parentService: ParentService, public snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.getInvitations();
    this.getChildren();
  }

  getInvitations() {
    this.parentService.parent$.pipe(
      takeUntil(this.destroy$$),
      switchMap(_  =>   this.parentService.getStudentInvitation())
    ).subscribe(
      (invitation : any) => {
        this.isLoading = false;
        this.teacherInvite = invitation;
      },
      (err) => this.snackbar.openSnackbar(err.error.error)
    );
  }

  getChildren() {
    this.parentService.getChildren();
    this.parentService.children$.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(
      (child: Student[]) => {
        if(child && child.length > 0) {
         this.showChildren = true;
         this.childrenList = child;
        }
      }
    );
  }

  acceptInvite(invite: TeacherInvite) {
    if(this.childrenList.length === 0) {
      this.snackbar.openSnackbar("Create your child profile first");
      return;
    }
    const dialogRef = this.dialog.open(AcceptInviteComponent, {
      width: '46%',
      height: '100%',
      data : { invites : invite},
      backdropClass: 'blur',
      disableClose: true ,
      panelClass: 'panel'
    })

    dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.teacherInvite = this.teacherInvite.filter(invites => invites.invitationID !== result)
        }
    })
  }

  ngOnDestroy() {
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }

}
