import { Injectable } from '@angular/core';
import { LibraryModel } from '../models/library.models';

@Injectable({
  providedIn: 'root'
})
export class FileDirectoryService {

  constructor() { }

  getImage(row: LibraryModel, isCloudContent = false) {
    if (isCloudContent) {
      if (row.isFolder) {
        return '../../../assets/cloud-content-folder.svg';
      } else {
        return '../../../assets/cloud-content-material.svg';
      }
    }
    if (row.activityType === 'tests') {
      return '../../../assets/test.svg';
    } else if (row.activityType === 'assignments') {
      return '../../../assets/library-assignment.svg';
    } else if (row.isFolder) {
      return '../../../assets/floder.svg';
    } else {
      return '../../../assets/library-material.svg';
    }
  }
}
