import { Injectable } from '@angular/core';
import { SchoolSettingService } from 'src/app/services/school-setting.service';
import { SchoolGroupService } from 'src/app/services/school-group.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { ReportInput } from 'src/app/models/report.models';

@Injectable({
  providedIn: 'root'
})
export class ReportDataService {

  private selectedSchoolGroup: string;

  constructor(
    private schoolSettingSvc: SchoolSettingService,
    private schoolGroupSvc: SchoolGroupService,
    private snackbar: SnackbarService,
    private crud: CrudService
  ) {
    this.selectedSchoolGroup = this.schoolGroupSvc.getSelectedSchoolGroup();
  }

  private sessionList$$: BehaviorSubject<any[]> = new BehaviorSubject(null);

  public get sessionList$(): Observable<any[]> {
    return this.sessionList$$.asObservable();
  }

  private sectionList$$: BehaviorSubject<any[]> = new BehaviorSubject(null);

  public get sectionList$(): Observable<any[]> {
    return this.sectionList$$.asObservable();
  }

  private subjectList$$: BehaviorSubject<any[]> = new BehaviorSubject(null);

  public get subjectList$(): Observable<any[]> {
    return this.subjectList$$.asObservable();
  }

  private standardList$$: BehaviorSubject<any[]> = new BehaviorSubject(null);

  public get standardList$(): Observable<any[]> {
    return this.standardList$$.asObservable();
  }

  private teacherList$$: BehaviorSubject<any[]> = new BehaviorSubject(null);

  public get teacherList$(): Observable<any[]> {
    return this.teacherList$$.asObservable();
  }

  public getSessions() {
    if (this.sessionList$$.value && this.sessionList$$.value.length > 0) {
      return
    }
    this.schoolSettingSvc.getMasterSchoolSession(this.selectedSchoolGroup).subscribe((sessions) => {
      if (sessions) {
        this.sessionList$$.next(sessions);
      } else {
        this.sessionList$$.next([]);
      }
    }, err => {
      this.snackbar.openSnackbar("unable to get sessions")
    })
  }
  public getSections() {
    if (this.sectionList$$.value && this.sectionList$$.value.length > 0) {
      return
    }
    this.schoolSettingSvc.getMasterSchoolSection(this.selectedSchoolGroup).subscribe((sections) => {
      if (sections) {
        this.sectionList$$.next(sections);
      } else {
        this.sectionList$$.next([]);
      }
    }, err => {
      this.snackbar.openSnackbar("unable to get sections")
    })

  }
  public getSubjects() {
    if (this.subjectList$$.value && this.subjectList$$.value.length > 0) {
      return
    }
    this.schoolSettingSvc.getMasterSchoolSubject(this.selectedSchoolGroup).subscribe((subjects) => {
      if (subjects) {
        this.subjectList$$.next(subjects);
      } else {
        this.subjectList$$.next([]);
      }
    }, err => {
      this.snackbar.openSnackbar("unable to get subjects")
    })

  }
  public getStandards() {
    if (this.standardList$$.value && this.standardList$$.value.length > 0) {
      return
    }
    this.schoolSettingSvc.getMasterSchoolStandard(this.selectedSchoolGroup).subscribe((standards) => {
      if (standards) {
        this.standardList$$.next(standards);
      } else {
        this.subjectList$$.next([]);
      }
    }, err => {
      this.snackbar.openSnackbar("unable to get standards")
    })
  }


  public getTeachers() {
    if (this.teacherList$$.value && this.teacherList$$.value.length > 0) {
      return
    }
    this.crud.get(environment.apiUrl + "school/teacher/get").subscribe((teachers) => {
      if (teachers) {
        this.teacherList$$.next(teachers);
      } else {
        this.teacherList$$.next([]);
      }
    }, err => {
      this.snackbar.openSnackbar("unable to get teachers")
    })
  }

  public getReport(reportInput: ReportInput) {
    this.crud.post(environment.apiUrl + "analytics/activity", reportInput, { responseType: 'blob'}).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url= window.URL.createObjectURL(blob);
      window.open(url);
    }, err => {
      this.snackbar.openSnackbar("unable to download report")
    })
  }

  public clear() {
    this.sectionList$$.next(null);
    this.sessionList$$.next(null);
    this.subjectList$$.next(null);
    this.standardList$$.next(null);
    this.teacherList$$.next(null);
    this.selectedSchoolGroup = this.schoolGroupSvc.getSelectedSchoolGroup();
  }
}
