import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestQuestionComponent } from '../test-question/test-question.component';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AlertDialogComponent } from 'src/app/shared-components/alert-dialog/alert-dialog.component';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';

export interface optionsValue {
  option: null | string;
  image: null | string;
  isCorrect: boolean;
}

export const SINGLE_CHOICE = 'Single choice';
export const MULTIPLE_CHOICE = 'Multiple choice';
export const BOOLEAN = 'True/False';

@Component({
  selector: 'alert-dialog',
  templateUrl: './alert-dialog.html',
  styleUrls: ['./alert-dialog.scss'],
})

export class AlertDialog {
  constructor(
    public dialogRef: MatDialogRef<AddQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.isAdminTheme = this.data.theme === USER_TYPES.admin;
  }
  isAdminTheme;
  closeDilaog() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent implements OnInit {
  questionLength = 1;
  questionType = [SINGLE_CHOICE, MULTIPLE_CHOICE, BOOLEAN];
  optionList: optionsValue[] = [];
  questionForm: FormGroup;
  imgUrl = '';
  isEdit = false;
  addOptionIconAsset: string;
  isAdminTheme = false;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<TestQuestionComponent>,
    public snackbar: SnackbarService,
    public imageCompress: NgxImageCompressService,
    public fb: FormBuilder) { }

  ngOnInit(): void {
    this.isAdminTheme = this.data.isAdminTheme ? true : false;
    this.addOptionIconAsset = this.isAdminTheme ? 'assets/admin-add-option.svg' : 'assets/add-option.svg';
    this.dialogRef.updatePosition({ right: `0px` });
    this.questionLength = this.data.questionLength;
    if (this.data && this.data.question) {
      this.isEdit = true;
      this.fillForm();
      this.optionList = this.data.question.options;
    } else {
      this.buildform();
    }
    this.questionForm.controls['type'].valueChanges.subscribe(type => {
      this.optionList = [];
      if (type && type === BOOLEAN) {
        this.optionList.push({ option: 'True', image: '', isCorrect: false });
        this.optionList.push({ option: 'False', image: '', isCorrect: false });
        return;
      }
      for (let i = 0; i < 4; i++) {
        this.optionList.push({ option: '', image: '', isCorrect: false });
      }
    })
  }

  fillForm() {
    this.imgUrl = this.data.question.image;
    let type;
    if (this.data.question.type === 'multiple') {
      type = this.questionType[1];
    } else if (this.data.question.type === 'single') {
      type = this.questionType[0];
    } else {
      type = this.questionType[2];
    }
    this.questionForm = this.fb.group({
      question: [this.data.question.question, Validators.required],
      type: [type, Validators.required],
      marks: [this.data.question.marks, Validators.required],
      notes: [this.data.question.notes],
    })
  }

  buildform() {
    this.questionForm = this.fb.group({
      question: [null, Validators.required],
      type: [null, Validators.required],
      marks: [null, Validators.required],
      notes: [null],
    })
  }

  uploadImage() {
    this.compressImage().then(result => {
      this.imgUrl = result;
    });
  }

  uploadOptionimage(index) {
    this.compressImage().then(result => {
      this.optionList.filter((list, i) => {
        if (index === i) {
          list.image = result;
        }
      });
    });
  }

  compressImage() {
    return this.imageCompress.uploadFile().then(({ image, orientation }) => {
      return this.imageCompress.compressFile(image, orientation, 35, 92);
    });
  }

  removeImage() {
    this.imgUrl = '';
  }

  removeOptionImage(index) {
    this.optionList.filter((list, i) => {
      if (index == i)
        list.image = '';
    })
  }

  addOption() {
    if (this.questionForm.controls['type'].value === BOOLEAN) {
      this.snackbar.openSnackbar('You can\'t add more than two options for true/false type');
      return;
    }
    this.optionList.push({ option: '', image: '', isCorrect: false })
  }

  optionSelect(event, option, index) {
    if (event.checked === true) {
      this.optionList[index].isCorrect = true;
    } else {
      this.optionList[index].isCorrect = false;
    }
  }

  radioSelect(option, index) {
    this.optionList.map((list, i) => {
      if (index === i) {
        list.isCorrect = true;
      } else {
        list.isCorrect = false;
      }
    });
  }

  isValidQuestion() {
    let isValid = true;
    for (let i = 0; i < this.optionList.length; i++) {
      if (this.optionList.filter(option =>
        (option.image !== '' && option.image === this.optionList[i].image) || option.option === this.optionList[i].option).length > 1) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }

  save(type: 'save' | 'add') {
    const availableOptions = this.optionList.filter(list => list.isCorrect === true);

    if (!this.questionForm.valid) {
      this.snackbar.openSnackbar('Please fill all the required details');
      return;
    }

    if (this.optionList.filter(option => option.option === '').length > 0) {
      this.snackbar.openSnackbar('Please fill all the options');
      return;
    }

    if (!this.isValidQuestion()) {
      this.snackbar.openSnackbar('Duplicate options not allowed')
      return;
    }

    if (availableOptions.length > 0) {
      const questionData = this.getCreatedQuestion();
      if (this.checkMarksValidation(questionData.marks)) {
        this.dialogRef.close({ question: questionData, type });
        type === 'add' ? this.resetAll() : null;
      }
    } else {
      this.showAlertMsg();
    }
  }

  checkMarksValidation(questionMarks) {
    const totalQuestionMarks = this.data.totalQuestionMarks + questionMarks;
    if (this.data.totalTestMarks < totalQuestionMarks) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '363px',
        height: '190px',
        backdropClass: 'blur',
        data: {
          alertText: `Total test marks (${this.data.totalTestMarks}) is not equal to sum of marks (${totalQuestionMarks}) alloted to questions!!`,
          theme: this.isAdminTheme ? 'admin' : ''
        },
        panelClass: ['delete-dialog-css'],
        autoFocus: false
      });
      return false;
    }
    return true;
  }


  resetAll() {
    this.questionForm.controls['question'].setValue(null);
    this.questionForm.controls['question'].markAsUntouched();
    this.questionForm.controls['type'].setValue(null);
    this.questionForm.controls['type'].markAsUntouched();
    this.questionForm.controls['marks'].setValue(null);
    this.questionForm.controls['marks'].markAsUntouched();
    this.questionForm.controls['notes'].setValue(null);
    this.questionForm.controls['notes'].markAsUntouched();
    this.optionList = [];
  }

  cancel() {
    if (this.isEdit) {
      this.dialogRef.close({ action: 'delete' });
      return;
    }
    this.dialogRef.close();
  }

  getCreatedQuestion() {
    let questiontype;
    if (this.questionForm.controls['type'].value === MULTIPLE_CHOICE) {
      questiontype = 'multiple'
    } else if (this.questionForm.controls['type'].value === BOOLEAN) {
      questiontype = 'boolean'
    } else {
      questiontype = 'single'
    }
    const questionJSON = {
      marks: this.questionForm.controls['marks'].value,
      notes: this.questionForm.controls['notes'].value,
      question: this.questionForm.controls['question'].value,
      type: questiontype,
      image: this.imgUrl,
      options: this.optionList
    }
    return questionJSON;
  }

  showAlertMsg() {
    const dialogRef = this.dialog.open(AlertDialog, {
      width: '363px',
      height: '190px',
      backdropClass: 'blur',
      data: {
        theme: this.isAdminTheme ? USER_TYPES.admin : '',
      },
      panelClass: ['delete-dialog-css'],
      autoFocus: false
    });
  }

  close() {
    this.dialogRef.close();
  }

  deleteOption(option, i) {
    this.optionList = this.optionList.filter((list, index) => index !== i && (list.option === '' || option.option !== list.option))
  }

}
