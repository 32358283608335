import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTimeService } from 'src/app/services/date-time.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewDocsComponent } from '../view-docs/view-docs.component';
import { docsPreviewList } from 'src/app/teachers/post-details/post-details.component';
import { LabelService } from 'src/app/services/label.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const TYPE = 'type'
const DURATION = 60;
@Component({
  selector: 'app-live-class-table',
  templateUrl: './live-class-table.component.html',
  styleUrls: ['./live-class-table.component.scss']
})
export class LiveClassTableComponent implements OnInit, OnDestroy {
  @Input() displayedColumns: string[];
  @Input() transactions;
  @Output() onJoinClass = new EventEmitter();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  public sortedData : any;
  public dataSource;
  public theme: string;
  labelData = {};
  private destroy$$ : Subject<any> = new Subject<any>();
  constructor(
    public cd: ChangeDetectorRef,
    public dateService: DateTimeService,
    public localstorage: LocalStorageService,
    public dialog: MatDialog,
    public labelService: LabelService,
    ) { }
  isParentSelected: boolean

  ngOnInit(): void {
    this.labelService.labels$.pipe(takeUntil(this.destroy$$)).subscribe(data => this.labelData = data)
    this.isParentSelected = this.localstorage.getItem("isParentSelected") === "true";
   }

  selectDateRange(data) {
    console.log(data)
  }

  ngOnChanges() {
    this.theme = this.localstorage.getItem(TYPE);
    this.dataSource = new MatTableDataSource(this.transactions);
    this.cd.detectChanges();
    this.sort && this.sort.sort(({id: 'date', start: 'desc'}) as MatSortable )
    this.dataSource.sort = this.sort;
  }

  sortData(sort: Sort) {
    const data = this.transactions && this.transactions.slice();
    if (sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data && data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a.name, b.name, isAsc);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  formatDuration(duration) {
    return `${(duration / DURATION).toFixed(1)} hr`;
  }

  isAvailableToJoin(row) {
    let leftLimit = new Date(new Date(row.startsAt).getTime() - 10*60000);
    let presentTime = new Date();
    let rightLimit = new Date(new Date(row.endsAt).getTime() + 1*60000);
    return (presentTime > leftLimit && presentTime < rightLimit) && !this.isParentSelected;
  }

  isClassEnded(row) {
    let presentTime = new Date();
    let endTime = new Date(row.endsAt);
    return endTime < presentTime;
  }

  joinClass(row) {
    this.isAvailableToJoin(row) ? this.onJoinClass.emit(row.liveClassID) : null;
  }

  getContents(row) {
    console.log(row);
  }

  openPreviewDialog(row) {
    if(!row.recordings || row.recordings.length <= 0) {
      return
    }
    let theme = "teacher-theme";
    if(this.theme == "student") {
      theme = "student-theme"
    }
    let recordingFiles: docsPreviewList[] = [];
    for(let i = 0; i < row.recordings.length ; i ++) {
      let docPreview: docsPreviewList = {
        signedUrl: row.recordings[i],
        name: "Recording-" + i + ".mp4",
      }
      recordingFiles.push(docPreview);
    }
    const dialog = this.dialog.open(ViewDocsComponent, {
      width: '95%',
      height: '100%',
      data: { files: recordingFiles, type: "Recordings" },
      backdropClass: 'blur',
      disableClose: true ,
      panelClass: [ theme, 'full-width', 'live-class-video', 'preview-doc-container'],
      autoFocus: false
    })
  }
  ngOnDestroy(){
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }
}
