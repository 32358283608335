<ng-container *ngIf="!classes; else class">
    <div class="loader">
        <app-spinner></app-spinner>
    </div>
</ng-container>

<ng-template #class>
    <div class="header">
        <div class="txt">Classes</div>
        <div class="action">
            <div class="filter">
                <ng-select [items]="classList" [clearable]="false" [searchable]="false" (change)="filterByStatus($event)" [(ngModel)]="status">
                </ng-select>
            </div>
            <div *ngIf="!isParentSelected" class="join">
                <button [disabled]="!classes || classes.length === 0" *ngIf="!isParentSelected" mat-flat-button color="primary" (click)="joinClass()">JOIN LIVE CLASS</button>
            </div>
        </div>
    </div>

    <div class="wrapper bordered-box">
        <ng-container *ngIf="classFilter.length === 0; else detail">
            <div class="empty">You are not associated with any class.</div>
        </ng-container>

        <ng-template #detail>
            <div class="container">
                <div class="box" *ngFor="let children of classFilter" (click)="classDetail(children)">
                    <div class="profile">
                        <div class="img">
                            <img src="../../../assets/shape.svg" />
                        </div>
                        <div class="details">
                            <div class="name">{{children.class | titlecase}}</div>
                            <div class="teacher">Teacher: <span class="text">{{children.teacher | titlecase}}</span></div>
                        </div>
                    </div>
                    <div class="task">
                        <div class="week">This Week:</div>
                        <div class="group">
                            <div class="list">
                                <div><img src="../../../assets/book.svg" /></div>
                                <div class="count">{{children.upcomingTasks === '' ? 0 : children.upcomingTasks}}</div>
                                <div class="name">Upcoming tasks</div>
                            </div>

                            <div class="list">
                                <div><img src="../../../assets/book.svg" /></div>
                                <div class="count">{{children.completedTasks === '' ? 0 : children.completedTasks}}</div>
                                <div class="name">Completed task</div>
                            </div>
                        </div>
                        <div class="list">
                            <div><img src="../../../assets/book.svg" /></div>
                            <div class="pending">{{children.pendingTasks === '' ? 0 : children.pendingTasks}}</div>
                            <div class="pending-name">Pending task</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</ng-template>