<div class="question-wrapper" [ngClass]="{ 'question-margin' : isQuestionCreated === 'true'}">
    <ng-container *ngIf="questionList && questionList.length === 0; else questions">
        <div class="txt">Add test question using the ‘Add Questions’ button and save your test.</div>
    </ng-container>

    <ng-template #questions>
        <div *ngFor="let list of questionList; let i= index" class="question">
            <div class="txt">Question #{{i + 1}}</div>
            <div class="content">
                <div class="name">{{list.question}}</div>
                <div (click)="edit(i)" (class)="edit"> <img [src]="editAsset" /></div>
            </div>
        </div>
    </ng-template>
</div>

<ng-container>
    <div class="action">
        <div class="btn">
            <button [ngClass]="isAdminTheme ? 'admin-transparent-button': ''" mat-flat-button type="submit" (click)="addQuestion()">ADD QUESTION</button>
        </div>
        <div class="grp">
            <div class="btn1" [ngClass]="{ 'disabled' : questionList && questionList.length === 0}">
                <button [ngClass]="isAdminTheme ? 'admin-transparent-button': ''" mat-flat-button type="submit" (click)="preview()">SEE PREVIEW</button>
            </div>
            <div class="btn save" [ngClass]="{ 'disabled' : questionList && questionList.length === 0}">
                <button [ngClass]="isAdminTheme ? 'admin-fill-button': ''" mat-flat-button type="submit" [disabled]="questionList && questionList.length === 0" (click)="save()">SAVE TEST</button>
            </div>
        </div>
    </div>
</ng-container>