<ng-container>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <div class="hideCheck">
                    <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [color]="'primary'" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </div>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [color]="'primary'" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </td>
        </ng-container>


        <ng-container matColumnDef="studentName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Student Name</th>
            <td mat-cell *matCellDef="let transaction" [ngClass]="{pendingTest: (transaction.reviewStatus === 'not reviewed' && transaction.result === '')}">
                {{transaction.studentName ? transaction.studentName : '(Unregistered Student)' | titlecase}}</td>
        </ng-container>

        <ng-container *ngIf="!isCourseActivity" matColumnDef="mobileNumber">
            <th mat-header-cell *matHeaderCellDef>Mobile Number</th>
            <td mat-cell *matCellDef="let transaction" [ngClass]="{pendingTest: (transaction.reviewStatus === 'not reviewed' && transaction.result === '')}"> {{transaction.studentMobile}}</td>
        </ng-container>

        <ng-container *ngIf="!isCourseActivity" matColumnDef="marks">
            <th mat-header-cell *matHeaderCellDef>Marks</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.result === '' ? '--' : transaction.marks}} {{transaction.result === '' ? null : '/' }} {{transaction.result === '' ? null : transaction.totalMarks}} </td>
        </ng-container>


        <ng-container *ngIf="isCourseActivity" matColumnDef="marks">
            <th mat-header-cell *matHeaderCellDef>Marks</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.result === '' ? '--' : transaction.marksObtained}} {{transaction.result === '' ? null : '/' }} {{transaction.result === '' ? null : totalMarks}} </td>
        </ng-container>

        <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef>Result</th>
            <td mat-cell *matCellDef="let transaction" [ngClass]="{unreviewed: transaction.result === 'fail'}">
                {{ transaction.result === '' ? '--' : (transaction.result | titlecase) }}</td>
        </ng-container>

        <ng-container matColumnDef="correctAnswers">
            <th mat-header-cell *matHeaderCellDef>Correct Answers</th>
            <td mat-cell *matCellDef="let transaction">{{ transaction.result === '' ? '--' : transaction.correctAnswers }}
            </td>
        </ng-container>

        <ng-container matColumnDef="unanswered">
            <th mat-header-cell *matHeaderCellDef>Unanswered</th>
            <td mat-cell *matCellDef="let transaction">{{ transaction.result === '' ? '--' : transaction.unanswered }}</td>
        </ng-container>

        <ng-container *ngIf="!isCourseActivity" matColumnDef="reviewStatus">
            <th mat-header-cell *matHeaderCellDef>Review Status</th>
            <td mat-cell *matCellDef="let transaction" [ngClass]="{unreviewed: transaction.reviewStatus === 'not reviewed'}">
                {{ transaction.result === '' ? '--' : transaction.reviewStatus | titlecase }}</td>
        </ng-container>
        <ng-container *ngIf="isCourseActivity" matColumnDef="reviewStatus">
            <th mat-header-cell *matHeaderCellDef>Review Status</th>
            <td mat-cell *matCellDef="let transaction" [ngClass]="{unreviewed: transaction.evalStatus === 'pending'}">
                {{ transaction.result === '' ? '--' : transaction.evalStatus | titlecase }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
            <td mat-cell *matCellDef="let transaction" class="action-table-header" (click)="$event.stopPropagation()">
                <div class="select">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
                    <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                        <button mat-menu-item [disabled]="!transaction.result || disableActions" *ngFor="let item of menuListItems" (click)="selectMenuItem(item, transaction)">
              <span> {{ item }}</span>
            </button>
                    </mat-menu>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <ng-container>
        <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize> </mat-paginator>
    </ng-container>
</ng-container>