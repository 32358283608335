import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LabelService } from 'src/app/services/label.service';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { SchoolGroupService } from 'src/app/services/school-group.service';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';
import { AlertDialogComponent } from 'src/app/shared-components/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BASE_ROUTES } from 'src/app/Constants/route-constants';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SELECTED_SCHOOL_GROUP_KEY } from 'src/app/Constants/Constant';

@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  selected = 0;
  isTabSelected: boolean;
  tabs;
  classLabel;
  public subscription: Subscription[] = [];
  err: string;
  loading = true;
  constructor(
    private router: Router,
    public sidenavService: ToggleSidenavService,
    public cd: ChangeDetectorRef,
    public labelService: LabelService,
    private schoolGroupSvc: SchoolGroupService,
    private dialog: MatDialog,
    private localstorage: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.subscription.push(
      this.labelService.labels$.subscribe(data => {
        this.classLabel = data['classes']
        this.tabs = [
          { name: 'Classes', img: '../../../assets/classroom-1.svg' },
          { name: 'Library', img: '../../../assets/library-icon.svg' },
          { name: 'Profile', img: '../../../assets/graphic.svg' },
        ];
      })
    )
    this.sidenavService.sidenavToggle$.subscribe(toggle => {
      this.isTabSelected = toggle;
      const routeList = this.router.url.split('/');
      const routeName = routeList[routeList.length - 1];
      this.selected = this.tabs.findIndex(tab => tab.name.toLowerCase() === routeName);
      this.selected = this.selected > 0 ? this.selected : 0;
      this.cd.detectChanges();
    });
    if (!this.localstorage.getItem(SELECTED_SCHOOL_GROUP_KEY)) {
      this.router.navigate([BASE_ROUTES.teacher, 'choose']);
    }
    this.schoolGroupSvc.getAccessRights().subscribe(resp => {
      this.loading = false;
      this.schoolGroupSvc.updateAccessRights(resp);
    }, err => {
      this.err = 'school group deactivated';
      this.loading = false;
      if (err.error.error.includes('This school group has been deactivated')) {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '363px',
          height: '170px',
          backdropClass: 'blur',
          data: { alertText: 'This school group is deactivated', type: 'accessdenied', theme: USER_TYPES.teacher },
          panelClass: ['delete-dialog-css'],
          autoFocus: false
        });
        dialogRef.afterClosed().subscribe((data) => {
          this.router.navigate([BASE_ROUTES.teacher, 'choose']);
        });
      }
    });
  }

  select(index) {
    this.selected = index;
    this.router.navigate(['teacher', 'dashboard', this.tabs[index].name.toLowerCase()]);
  }

  ngOnDestroy() {
    this.subscription.forEach(s => s.unsubscribe());
  }

}
