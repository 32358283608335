import { Component, OnInit } from '@angular/core';
import { ChildClassService } from '../services/child-class.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ChildClass } from 'src/app/models/child-class-models';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { StudentClass } from 'src/app/models/student-class.models';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public classList = ['All', 'Active', 'Archived'];
  public classFilter;
  public classes: ChildClass[];
  public childID: string;
  status = this.classList[0];
  destroy$$ = new Subject<any>();
  isParentSelected: boolean;
  constructor(
    public childClassService: ChildClassService,
    public snackbar: SnackbarService,
    public localstorage: LocalStorageService,
    public router: Router,
    public route: ActivatedRoute,
    private sidenav: ToggleSidenavService
  ) { }

  ngOnInit(): void {
    this.sidenav.maximizeSidenav();
    this.childID = this.localstorage.getItem('studentID');
    this.isParentSelected = this.localstorage.getItem('isParentSelected') === 'true';
    this.getClasses();
  }
  getClasses() {
    this.childClassService.getClasses(this.childID).pipe(
      takeUntil(this.destroy$$)
    ).subscribe(
      (res) => {
        this.classes = res;
        this.classFilter = this.classes;
      },
      (err) => this.snackbar.openSnackbar(err.error.error)
    );
  }
  filterByStatus(status) {
    if (status === 'All') {
      this.classFilter = this.classes;
    }
    else if (status === 'Active') {
      this.classFilter = this.classes.filter((data) => (data.classStatus === 'active') && (data.schoolStatus === 'active'));
    }
    else if (status === 'Archived') {
      this.classFilter = this.classes.filter((data) => (data.classStatus === 'archived') || (data.schoolStatus === 'archived'));
    }
  }
  joinClass() {
    this.router.navigate(['../', 'live-class-schedule'], { relativeTo: this.route })
  }

  classDetail(classDetail: StudentClass) {
    this.router.navigate(['student', this.childID, 'dashboard', 'class-details', classDetail.classID]);
  }

}
