import { Injectable } from '@angular/core';
import { School } from '../models/school.models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FindSchoolUserRoleService {
  private userRole$$: BehaviorSubject<any> = new BehaviorSubject<string>(null);

  public get userRole$(): Observable<string> {
    return this.userRole$$.asObservable();
  }

  constructor() {
    const userRole = localStorage.getItem('selectedSchoolUserRole');
    this.userRole$$.next(userRole);
  }

  setRoleType(school: School) {
    const userId = localStorage.getItem('id');
    let userRole;
    if (school.creatorID === userId) {
      userRole = 'creator';
    } else if (school.admins && school.admins.includes(userId)) {
      userRole = 'admin';
    } else {
      userRole = 'member';
    }
    this.userRole$$.next(userRole);
    localStorage.setItem('selectedSchoolUserRole', userRole);
  }
}
