import { Component, OnInit, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadComponent } from '../upload/upload.component';
import { userResizeToHeight, userResizeToWidth, logoResizeToHeight, logoResizeToWidth, aspectRatio } from 'src/app/Constants/Constant';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-crop-image-dialog',
  templateUrl: './crop-image-dialog.component.html',
  styleUrls: ['./crop-image-dialog.component.scss'],
})
export class CropImageDialogComponent implements OnInit {
  croppedWidth: string;
  croppedHeight: string;
  aspectRatio : string;
  url : string = '';
  theme: string;
  imageChangedEvent: any = '';
  isImageLoaded: boolean = false;
  toCompress : boolean = false;
  constructor( public snackbar: SnackbarService,
               private dialogRef: MatDialogRef<UploadComponent>,
               public localstorage: LocalStorageService,
               @Inject(MAT_DIALOG_DATA) public data,
             ) { }

  ngOnInit(): void {
    this.theme = this.localstorage.getItem('type');
    this.croppedWidth = this.data.imgType === 'user' ? userResizeToWidth : logoResizeToWidth;
    this.croppedHeight = this.data.imgType === 'user' ? userResizeToHeight : logoResizeToHeight;
    this.aspectRatio = aspectRatio
    this.imageChangedEvent = this.data.imageChangedEvent;
    this.toCompress = this.data.toCompress ? true : false;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.url = event.base64;
      this.isImageLoaded = true;
  }

  loadImageFailed() {
      this.snackbar.openSnackbar("Failed to upload the image")
  }

  getBtnClass() {
    if(this.theme === 'teacher') {
      return 'teacher-btn'
    } else if(this.theme === 'admin') {
      return 'admin-btn'
    } else {
      return 'student-btn'
    }
  }

  doneCropping(action) {
    action === 'cancel' ? this.dialogRef.close() : this.dialogRef.close(this.url);
  }

}
