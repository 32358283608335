import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CreatorUser, InstructorUser, LearnerUser, AccessRights } from 'src/app/models/super-admin-users.model';

@Injectable({
  providedIn: 'root'
})
export class AppUsersService {

  constructor(
    private crud: CrudService,
    private snackbar: SnackbarService
  ) { }
  private isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private creator$$: BehaviorSubject<CreatorUser[]> = new BehaviorSubject<CreatorUser[]>([]);
  private instructors$$: BehaviorSubject<InstructorUser[]> = new BehaviorSubject<InstructorUser[]>([]);
  private learners$$: BehaviorSubject<any> = new BehaviorSubject<LearnerUser[]>([]);


  public get isLoading$(): Observable<boolean> {
    return this.isLoading$$.asObservable();
  }

  public get creator$(): Observable<CreatorUser[]> {
    return this.creator$$.asObservable();
  }

  public get instructors$(): Observable<InstructorUser[]> {
    return this.instructors$$.asObservable();
  }

  public get learners$(): Observable<LearnerUser[]> {
    return this.learners$$.asObservable();
  }

  getAllAdmins() {
    this.isLoading$$.next(true);
    this.crud.get(`${environment.apiUrl}super-admin/dashboard/creator`).subscribe((res) => {
      this.creator$$.next(res);
      this.isLoading$$.next(false);
    }, err => {
      const message = err.error.error ? err.error.error : 'Unable to fetch admins data';
      this.snackbar.openSnackbar(message);
    });
  }

  getAllInstructors() {
    this.isLoading$$.next(true);
    this.crud.get(`${environment.apiUrl}super-admin/dashboard/teacher`).subscribe((res) => {
      this.instructors$$.next(res);
      this.isLoading$$.next(false);
    }, err => {
      const message = err.error.error ? err.error.error : 'Unable to fetch instructors data';
      this.snackbar.openSnackbar(message);
    });
  }

  getAllLearners() {
    this.isLoading$$.next(true);
    this.crud.get(`${environment.apiUrl}super-admin/dashboard/student`).subscribe((res) => {
      this.learners$$.next(res);
      this.isLoading$$.next(false);
    }, err => {
      const message = err.error.error ? err.error.error : 'Unable to fetch learners data';
      this.snackbar.openSnackbar(message);
    });
  }

  updateCreatorStatus(creatorID: string, status: string) {
    return this.crud.post(`${environment.apiUrl}super-admin/creator/status`, {
      creatorID,
      status
    });
  }

  updateCreatorMobileNumber(id: string, mobileNumber: string) {
    return this.crud.patch(`${environment.apiUrl}super-admin/creator/edit`, {
      id,
      mobileNumber
    });
  }

  updateAccessRights(id: string, accessRights: AccessRights) {
    return this.crud.patch(`${environment.apiUrl}super-admin/creator/edit`, {
      id,
      accessRights: { ... accessRights},
    });
  }
}
