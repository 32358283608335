<ng-container *ngIf="userType !== userTypes.student else student">
<div class="container">
    <div class="text">Just a few more details</div>
    <div class="form">
        <form  [formGroup]="profileForm" (ngSubmit)="register()">
            <div class="name">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label> Full Name</mat-label>
                    <input matInput placeholder="Name" formControlName="name">
                    <mat-error *ngIf="profileForm.controls['name'].touched">{{ getErrorName() }}</mat-error>
                </mat-form-field>
            </div>
            <div class="email"
            [ngStyle]="(!profileForm.controls['name'].valid && profileForm.controls['name'].touched) && {'margin-top' : '24px'}">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Enter email" formControlName="email">
                    <mat-error *ngIf=" !profileForm.controls['email'].valid && profileForm.controls['email'].touched">
                        {{ getErrorEmail() }}
                    </mat-error>
                 </mat-form-field>
            </div>
            <div class="user" [ngStyle]="!profileForm.controls['email'].valid && profileForm.controls['email'].touched && {'margin-top' : '24px'}">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>User Name</mat-label>
                    <input matInput placeholder="Enter your new user name" type="text" formControlName="userName">
                    <mat-error *ngIf=" !profileForm.controls['userName'].valid && profileForm.controls['userName'].touched">User name can't be empty</mat-error>
                </mat-form-field>
            </div>

            <div class="upload" [ngStyle]="isImageUploaded === false && {'border': '2px solid #ff8156', 'border-radius': '12px', 'color': '#ff8156'}">
                <app-upload [text]="'UPLOAD PHOTO'" (url)="onImageUpload($event)" [imgType]="'user'"></app-upload>
            </div>

            <div *ngIf="isImageUploaded === false" [ngStyle]="{'color': '#ff8156'}">Please upload image</div>

            <div class="btn" >
                <button mat-flat-button type="submit" color="primary" [disabled]="isSubmitted" >CONTINUE</button>
            </div>
        </form>
    </div>
</div>
</ng-container>
<ng-template #student>
  <div class="container student-theme">
    <div class="text">Create Profile</div>

    <form [formGroup]="profileForm">

        <div class="parent">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Enter name" formControlName="name" autocomplete="off" type="text">
                <mat-error *ngIf="profileForm.controls['name'].touched">{{ getErrorName() }}</mat-error>
            </mat-form-field>
        </div>

        <div class="username">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label> User Name</mat-label>
                <input matInput placeholder="Enter User Name" formControlName="username">
                <mat-error *ngIf="profileForm.controls['username'].touched">Username can't be empty</mat-error>
            </mat-form-field>
        </div>


        <div class="gender" [ngStyle]="!profileForm.controls['username'].valid && profileForm.controls['username'].touched && {'margin-top' : '24px'}">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Gender</mat-label>
                <mat-select placeholder="Select gender" formControlName="gender">
                    <mat-option *ngFor="let gender of genders" [value]="gender">
                        {{gender}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="!profileForm.controls['gender'].valid && profileForm.controls['gender'].touched">
                    Gender can't be empty
                </mat-error>
            </mat-form-field>
        </div>

        <!-- <div class="email" [ngStyle]="!profileForm.controls['parentName'].valid && profileForm.controls['parentName'].touched && {'margin-top' : '24px'}">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Enter email" formControlName="email" autocomplete="off">
                <mat-error *ngIf="!profileForm.controls['email'].valid && profileForm.controls['email'].touched">
                    {{ getErrorEmail() }}
                </mat-error>
            </mat-form-field>
        </div>  -->

        <div class="pin" [ngStyle]="!profileForm.controls['gender'].valid && profileForm.controls['gender'].touched && {'margin-top' : '24px'}">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Pin</mat-label>
                <input matInput placeholder="Set a 4-digit pin" type="number" formControlName="pin" autocomplete="off">
                <mat-error *ngIf="!profileForm.controls['pin'].valid && profileForm.controls['pin'].touched">
                    {{ getErrorPin() }}
                </mat-error>
            </mat-form-field>
        </div>
    </form>

    <div class="upload" [ngStyle]="isImageUploaded === false && {'border': '2px solid #ff8156', 'border-radius': '12px', 'color': '#ff8156'}">
        <app-upload [imgType]="'user'" [text]="'UPLOAD PHOTO'" (url)="onImageUpload($event)"></app-upload>
    </div>

    <div *ngIf="isImageUploaded === false" [ngStyle]="{'color': '#ff8156'}">Please upload image</div>


    <div class="btn" [ngStyle]="!this.profileForm.valid && {'margin-top' : '24px'}">
        <button mat-flat-button type="submit" color="primary" (click)="register()" [disabled]="isSubmitted">CONTINUE</button>
    </div>
  </div>
</ng-template>

