<div class="edit-profile-wrapper student-theme">
    <div class="header">
        <div>Change Pin</div>
        <div class="close" (click)="close()">
            <img src="../../../assets/close.svg"/>
        </div>
    </div>

    <div class="form">

        <div class="photo">
            <img [src]= picture />
        </div>

        <form  [formGroup]="pinForm" (ngSubmit)="save()">
            <ng-container>
                <div class="name">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Old PIN</mat-label>
                        <input matInput placeholder="Enter Old PIN" formControlName="oldPin" type="password" maxlength="4">
                        <mat-error *ngIf=" !pinForm.controls['oldPin'].valid && pinForm.controls['oldPin'].touched">old pin cannot be empty</mat-error>
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container>
                <div class="email" [ngStyle]="{'margin-top' : '24px'}">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>New PIN</mat-label>
                        <input matInput placeholder="Enter New PIN" formControlName="newPin" type="password" maxlength="4"> 
                        <mat-error *ngIf=" !pinForm.controls['newPin'].valid && pinForm.controls['newPin'].touched">new pin cannot be empty</mat-error>           
                     </mat-form-field> 
                </div>
            </ng-container>

            <ng-container>
                <div class="user" [ngStyle]="{'margin-top' : '24px'}">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Confirm PIN</mat-label>
                        <input matInput placeholder="Re-Enter PIN" type="number" formControlName="confirmPin">
                        <mat-error *ngIf="!pinForm.controls['confirmPin'].valid && pinForm.controls['confirmPin'].touched">{{ confirmPinError() }}</mat-error>
                    </mat-form-field>
                </div>
            </ng-container>

            <div class="btn" >
                <button mat-flat-button type="submit" color="primary" [disabled]="isSubmitted" >UPDATE</button>
            </div>   
        </form>
    </div>
</div>