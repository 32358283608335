import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-session-status',
  templateUrl: './session-status.component.html',
  styleUrls: ['./session-status.component.scss']
})
export class SessionStatusComponent implements OnInit {
  toppingList: string[] = ['2020','2019', '2018', '2017', '2016', '2015'];
  toppings = '2019';
  @Input() session;
  constructor() { }

  ngOnInit(): void {

  }

  onYearSelect(event) {
    console.log(event)
  }

}
