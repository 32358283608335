import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SELECTED_SCHOOL_GROUP_KEY, SELF_ID_KEY } from '../Constants/Constant';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptService implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestName = request.url.split('/');
    let schoolGroup = localStorage.getItem(SELECTED_SCHOOL_GROUP_KEY) ?
      localStorage.getItem(SELECTED_SCHOOL_GROUP_KEY) : localStorage.getItem(SELF_ID_KEY);
    if (!schoolGroup) {
      schoolGroup = '';
    }
    if (!requestName.includes('storage.googleapis.com') && !requestName.includes('send-otp') && !requestName.includes('verify-otp')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "School-Group": schoolGroup
        }
      })
      return next.handle(request)
        .pipe(
          tap(event => {
            if (event instanceof HttpResponse) {
            }
          }, err => {
            if (err.error.error && err.error.error.includes('You\'ve been logged out. Please login again')) {
              this.userService.clearUserInCache();
              this.router.navigate([window.location.host]);
            }
            console.error(err.status);
            console.error(err.message);
          })
        );
    } else if (request.url.split('/')[2] === 'storage.googleapis.com') {
      request = request.clone({
        responseType: 'text'
      });

      return next.handle(request);
    } else {
      return next.handle(request);
    }
  }

  constructor(
    private userService: UserService,
    private router: Router
  ) {

  }
}
