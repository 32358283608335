export const multi = [
    {
      "name": "TEST",
      "series": [
        {
          "name": "January",
          "value": 62000000
        },
        {
          "name": "February",
          "value": 73000000
        },
        {
          "name": "March",
          "value": 89400000
        }
      ]
    },
  
    {
      "name": "ASSIGNMENT",
      "series": [
        {
          "name": "January",
          "value": 250000000
        },
        {
          "name": "February",
          "value": 309000000
        },
        {
          "name": "March",
          "value": 311000000
        }
      ]
    },
  
    {
      "name": "MATERIAL",
      "series": [
        {
          "name": "January",
          "value": 58000000
        },
        {
          "name": "February",
          "value": 50000020
        },
        {
          "name": "March",
          "value": 58000000
        }
      ]
    },
    {
      "name": "LIVE CLASS",
      "series": [
        {
            "name": "January",
            "value": 58000000
          },
          {
            "name": "February",
            "value": 50000020
          },
          {
            "name": "March",
            "value": 58000000
        }
      ]
    }
  ];
  