import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CourseCategory } from 'src/app/models/course-category.model';
import { Course } from 'src/app/models/course.model';
import { CoursesService } from 'src/app/services/courses.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { EDIT, INACTIVE_STATUS } from 'src/app/Constants/Constant';

@Component({
  selector: 'app-create-course',
  templateUrl: './course-form.component.html',
  styleUrls: ['./course-form.component.scss']
})
export class CourseFormComponent implements OnInit, OnDestroy {

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private coursesSvc: CoursesService,
    private snackbar: SnackbarService
  ) { }
  public courseForm: FormGroup;
  public categories: CourseCategory[] = [];
  public isImageUploaded: boolean;
  public imageUrl: string = null;
  public submitting = false;
  public expirationDateForm: FormGroup = this.fb.group({
    expirationDate: [null, [Validators.required]]
  });
  public isLoading = false;
  public imgURLIn: string;
  public title: string;
  private courseStatus = INACTIVE_STATUS;
  private coursePublished = false;
  public randomOrderCompletionAllowed = false;

  ngOnInit(): void {
    this.title = this.data.title;
    this.coursesSvc.isLoadingCategories$.subscribe((isLoading) => this.isLoading = isLoading);
    this.coursesSvc.getCourseCategories();
    this.coursesSvc.courseCategoriesList$.subscribe((categories) => {
      if (categories) {
        this.categories = categories;
      }
    })
    this.dialogRef.updatePosition({ right: `0px` });
    this.courseForm = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(100)]],
      description: [null, [Validators.required]],
      categoryID: [null, [Validators.required]],
      courseCode: [null, [Validators.required, Validators.maxLength(50)]],
      certificate: [false, [Validators.required]],
      randomOrder: [false, [Validators.required]],
      expiration: [false, [Validators.required]],
    });
    const course = this.data.course;
    this.imgURLIn = '../../../assets/school.svg';
    if (this.data.type === EDIT) {

      this.coursesSvc.getBase64Image(course.bannerImage).subscribe((content) => {
        this.imgURLIn = content;
        this.imageUrl = this.imgURLIn;
      }, err => this.snackbar.openSnackbar('unable to load course image'));
      this.courseStatus = course.status;
      this.coursePublished = course.isPublished;
      this.randomOrderCompletionAllowed = course.randomOrder;
      this.courseForm.patchValue({
        name: course.name,
        description: course.description,
        categoryID: course.categoryID,
        courseCode: course.courseCode,
        certificate: course.certificate,
        randomOrder: course.randomOrder,
        expiration: course.expiration,
      });

      if (course.expiration) {
        this.expirationDateForm.patchValue({
          expirationDate: course.expirationDate,
        });
      }
    }
  }

  onImageUpload(url) {
    this.imageUrl = url ? url : null;
    this.isImageUploaded = url ? true : false;
  }

  close() {
    this.dialogRef.close({ courseCreated: false });
  }

  submitForm() {
    this.isImageUploaded = this.imageUrl ? true : false;
    if (this.data.type === EDIT && !this.imageUrl) {
      this.isImageUploaded = true;
    }
    const courseExpirationSet = this.courseForm.controls['expiration'].value;
    if (this.courseForm.valid && this.isImageUploaded) {
      if (!courseExpirationSet || (courseExpirationSet && this.expirationDateForm.valid)) {
        const course: Course = {
          name: this.courseForm.get('name').value,
          description: this.courseForm.get('description').value,
          courseCode: this.courseForm.get('courseCode').value,
          bannerImage: this.imageUrl,
          certificate: this.courseForm.get('certificate').value,
          status: this.courseStatus,
          isPublished: this.coursePublished,
          expiration: this.courseForm.get('expiration').value,
          randomOrder: this.courseForm.get('randomOrder').value,
          schoolID: this.data.schoolID,
          expirationDate: this.expirationDateForm.get('expirationDate').value,
          categoryID: this.courseForm.get('categoryID').value
        };
        this.submitting = true;
        if (this.data.type === EDIT) {
          this.editCourse(course);
        } else {
          this.createCourse(course);
        }
      } else {
        this.validateForms();
      }
    } else {
      this.validateForms();
    }
  }

  private editCourse(course) {
    if (this.imageUrl === this.imgURLIn || this.imgURLIn === '../../../assets/school.svg') {
      delete course.bannerImage;
    }
    course.id = this.data.course.id;
    this.coursesSvc.updateCourse(course).subscribe((resp) => {
      this.snackbar.openSnackbar('course updated successfully');
      this.submitting = false;
      this.dialogRef.close({ courseCreated: true });
    }, (err) => {
      if (err.error && err.error.error && err.error.error.includes('duplicate')) {
        this.snackbar.openSnackbar('course code already used please try with a different one');
      } else {
        this.snackbar.openSnackbar('some error occurred while updating the course');
      }
      this.submitting = false;
    });
  }

  private createCourse(course) {
    this.coursesSvc.createCourse(course).subscribe((resp) => {
      this.snackbar.openSnackbar('course created successfully');
      this.submitting = false;
      this.dialogRef.close({ courseCreated: true });
    }, (err) => {
      if (err.error && err.error.error && err.error.error.includes('duplicate')) {
        this.snackbar.openSnackbar('course code already used please try with a different one');
      } else {
        this.snackbar.openSnackbar('some error occurred while creating the course');
      }
      this.submitting = false;
    });
  }

  private validateForms = () => {
    Object.keys(this.courseForm.controls).forEach(field => {
      const control = this.courseForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    })
    if (this.courseForm.controls['expiration'].value) {
      this.expirationDateForm.controls['expirationDate'].markAsTouched({ onlySelf: true });
    }
  }

  ngOnDestroy() {
    this.coursesSvc.setInitialState();
  }
}


