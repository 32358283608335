import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SchoolSpecificSettingsService {

  public schoolRoute() {
    return environment.apiUrl + 'school/get?role=teacher';
  }

  public standardRoute(id) {
    return environment.apiUrl + 'school/standard/view/' + id;
  }

  public sessionRoute(id) {
    return environment.apiUrl + 'school/session/view/' + id;
  }

  public subjectRoute(id) {
    return environment.apiUrl + 'school/subject/view/' + id;
  }

  public sectionRoute(id) {
    return environment.apiUrl + 'school/section/view/' + id;
  }

  constructor(public crud: CrudService) { }

  getSchools(): Observable<any> {
    return this.crud.get(this.schoolRoute());
  }

  getStandards(id) {
    return this.crud.get(this.standardRoute(id))
  }

  getSessions(id) {
    return this.crud.get(this.sessionRoute(id))
  }

  getSubjects(id) {
    return this.crud.get(this.subjectRoute(id))
  }

  getSections(id) {
    return this.crud.get(this.sectionRoute(id))
  }
}
