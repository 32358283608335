import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notice-board',
  templateUrl: './notice-board.component.html',
  styleUrls: ['./notice-board.component.scss']
})
export class NoticeBoardComponent implements OnInit {

  notification = [
    { type: 'warning', title: 'You have one new assignment', date: '2 minutes ago', quarter: 'Electrical Science', amount: '30'},
    { type: 'assignment', title: 'You have one new assignment', date: '2 minutes ago', class: 'Electrical Science', topic: 'oule heating effect of current', totalMarks: '30', submissionDate: ' 24-08-2020', conducting: 'B.K Sharma'},
    { type: 'upcoming', title: 'You have one new assignment', date: '2 minutes ago', class: 'Electrical Science', topic: 'oule heating effect of current', totalMarks: '30', submissionDate: ' 24-08-2020', conducting: 'B.K Sharma'},
    { type: 'announcment', title: 'You have one new assignment', date: '2 minutes ago', class: 'Electrical Science', topic: 'oule heating effect of current', totalMarks: '30', submissionDate: ' 24-08-2020', conducting: 'B.K Sharma'},
    { type: 'message', title: 'You have one new assignment', date: '2 minutes ago', from: 'Electrical Science', message: 'oule heating effect of current'},
    { type: 'assignment', title: 'You have one new assignment', date: '2 minutes ago', class: 'Electrical Science', topic: 'oule heating effect of current', totalMarks: '30', submissionDate: ' 24-08-2020', conducting: 'B.K Sharma'},
    { type: 'warning', title: 'You have one new assignment', date: '2 minutes ago', quarter: 'Electrical Science', amount: '30'},
    { type: 'upcoming', title: 'You have one new assignment', date: '2 minutes ago', class: 'Electrical Science', topic: 'oule heating effect of current', totalMarks: '30', submissionDate: ' 24-08-2020', conducting: 'B.K Sharma'},
    { type: 'announcment', title: 'You have one new assignment', date: '2 minutes ago', class: 'Electrical Science', topic: 'oule heating effect of current', totalMarks: '30', submissionDate: ' 24-08-2020', conducting: 'B.K Sharma'},
    { type: 'message', title: 'You have one new assignment', date: '2 minutes ago', from: 'Electrical Science', message: 'oule heating effect of current'},
    { type: 'announcment', title: 'You have one new assignment', date: '2 minutes ago', class: 'Electrical Science', topic: 'oule heating effect of current', totalMarks: '30', submissionDate: ' 24-08-2020', conducting: 'B.K Sharma'},
    { type: 'assignment', title: 'You have one new assignment', date: '2 minutes ago', class: 'Electrical Science', topic: 'oule heating effect of current', totalMarks: '30', submissionDate: ' 24-08-2020', conducting: 'B.K Sharma'},
    { type: 'upcoming', title: 'You have one new assignment', date: '2 minutes ago', class: 'Electrical Science', topic: 'oule heating effect of current', totalMarks: '30', submissionDate: ' 24-08-2020', conducting: 'B.K Sharma'},
    { type: 'warning', title: 'You have one new assignment', date: '2 minutes ago', quarter: 'Electrical Science', amount: '30'},
    { type: 'assignment', title: 'You have one new assignment', date: '2 minutes ago', class: 'Electrical Science', topic: 'oule heating effect of current', totalMarks: '30', submissionDate: ' 24-08-2020', conducting: 'B.K Sharma'},
    { type: 'announcment', title: 'You have one new assignment', date: '2 minutes ago', class: 'Electrical Science', topic: 'oule heating effect of current', totalMarks: '30', submissionDate: ' 24-08-2020', conducting: 'B.K Sharma'},
    { type: 'warning', title: 'You have one new assignment', date: '2 minutes ago', quarter: 'Electrical Science', amount: '30'},
    { type: 'message', title: 'You have one new assignment', date: '2 minutes ago', from: 'Electrical Science', message: 'oule heating effect of current'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
