import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
// import { LoginComponent } from './login/login.component';
import { AdminRoutingModule } from './admin-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SchoolsComponent } from './schools/schools.component';
import { CreateSchoolComponent } from './create-school/create-school.component';
import { SchoolCardComponent } from './school-card/school-card.component';
import { SchoolDetailComponent } from './school-detail/school-detail.component';
import { MaterialModule } from '../material/material.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SettingsModule } from './settings/settings.module';
import { TableComponent } from './table/table.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AnalyticCardComponent } from './shared/analytic-card/analytic-card.component';
import { SessionStatusComponent } from './shared/session-status/session-status.component';
import { ChartComponent } from './shared/chart/chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AnalyticsDashboardComponent } from './analytics-dashboard/analytics-dashboard.component';
import { AnalyticsFilterComponent } from './analytics-filter/analytics-filter.component';
import { AdminReportComponent } from './admin-report/admin-report.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    // LoginComponent,
    DashboardComponent,
    SchoolsComponent,
    CreateSchoolComponent,
    SchoolCardComponent,
    SchoolDetailComponent,
    TableComponent,
    AnalyticsComponent,
    AnalyticCardComponent,
    SessionStatusComponent,
    ChartComponent,
    AnalyticsDashboardComponent,
    AnalyticsFilterComponent,
    AdminReportComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    NgSelectModule,
    NgxChartsModule,
    SettingsModule,
    SharedComponentsModule,
    InfiniteScrollModule
  ]
})
export class AdminModule { }
