import { Component, Inject, OnInit } from '@angular/core';
import { inject } from '@angular/core/testing';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewAttachmentComponent } from 'src/app/shared/view-attachment/view-attachment.component';
import { docsPreviewList } from 'src/app/teachers/post-details/post-details.component';

@Component({
  selector: 'app-get-evaluation',
  templateUrl: './get-evaluation.component.html',
  styleUrls: ['./get-evaluation.component.scss']
})
export class GetEvaluationComponent implements OnInit {
  files: docsPreviewList[] = [];
  curFileIndex = 0;
  public marks: number;
  public totalMarks: number;
  public remarks: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<ViewAttachmentComponent>) { }

  ngOnInit(): void {
    this.files = this.data.files;
    this.marks = this.data.metadata[0].marks;
    this.totalMarks = this.data.metadata[0].totalMarks;
    this.remarks = this.data.metadata[0].remarks;
  }

  onIndexChange(index) {
    this.curFileIndex = index;
  }

  close() {
    this.dialogRef.close();
  }

}
