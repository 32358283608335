import { Component, OnInit, OnDestroy } from '@angular/core';
import { Course } from 'src/app/models/course.model';
import { FormControl } from '@angular/forms';
import { StudentCoursesService } from '../services/student-courses.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';

const debounceTime = 1000;

@Component({
  selector: 'app-courses-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})

export class CourseListComponent implements OnInit, OnDestroy {

  constructor(
    private studentCoursesSvc: StudentCoursesService,
    private route: ActivatedRoute,
    private snackbar: SnackbarService,
    private router: Router,
    private sidenavSvc: ToggleSidenavService
  ) { }

  public courses: Course[];
  public searchTerm: FormControl = new FormControl('');
  public isLoading = true;
  public timer: any;
  public studentId: string;
  public updatingState = false;
  public schools: any[];
  public selectedSchool = '';

  private destroy$$: Subject<any> = new Subject<any>();


  ngOnInit(): void {
    this.sidenavSvc.maximizeSidenav();
    this.route.parent.parent.params.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(param => {
      console.log(param);
      if (param.id) {
        this.studentId = param.id;
        this.studentCoursesSvc.searchCourses(this.studentId, '', this.selectedSchool);
        this.studentCoursesSvc.getParentSchools();
      }
    });

    this.studentCoursesSvc.schools$.subscribe((schools) => {
      if (schools && schools.length > 0) {
        this.schools = schools.length !== 1 ? [{ id: '', name: 'All Schools' }, ...schools] : schools;
        if(this.schools.length === 1) {
          this.selectedSchool = schools[0].id;
        }
      }
    });

    this.studentCoursesSvc.isLoading$.subscribe((isLoading) => this.isLoading = isLoading);
    this.searchTerm.valueChanges.subscribe((term) => {
      this.isLoading = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.studentCoursesSvc.searchCourses(this.studentId, term, this.selectedSchool);
      }, debounceTime);
    });
    this.studentCoursesSvc.courses$.subscribe((courses) => this.courses = courses);
  }

  public subscribeCourse(course) {
    if (!course.subscribed) {
      this.updatingState = true;
      this.studentCoursesSvc.subscribeCourse(course.id, this.studentId).subscribe((resp) => {
        const ind = this.courses.findIndex((c) => c.id === course.id);
        this.courses[ind].subscribed = true;
        this.updatingState = false;
        this.snackbar.openSnackbar('subscribed to course successfully');
      }, (err) => {
        this.updatingState = false;
        this.snackbar.openSnackbar('unable to subscribe to course at the moment');
      });
    } else {
      this.router.navigate(['student', this.studentId, 'dashboard', 'courses', course.id]);
    }
  }

  public filterBySchool(val) {
    this.studentCoursesSvc.searchCourses(this.studentId, '', val);
  }

  ngOnDestroy() {
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }

}
