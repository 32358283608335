import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ClassDetailComponent } from '../class-detail/class-detail.component';
import { minDate, positiveNmuberRegex, COURSE_ACTIVITY, ACTIVITY_TYPES, UPDATE_ACTION } from 'src/app/Constants/Constant';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { PostDetailService } from '../services/post-detail.service';
import { Posts } from 'src/app/models/post.models';
import { PostAssignmentService } from '../services/post-assignment.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CourseActivitiesService } from 'src/app/admin/services/course-activities.service';
import { HttpEventType } from '@angular/common/http';

export interface DialogDataModel {
  id?: string;
  classID?: string;
  post?: Posts;
  entityType?: string;
  courseID?: string;
  activityIndex?: number;
}

export interface PostUploadResponse {
  activityID?: string,
  signedURLs: string[]
}

const EDIT = 'edit';
const POST = 'post';

@Component({
  selector: 'app-post-assignment',
  templateUrl: './post-assignment.component.html',
  styleUrls: ['./post-assignment.component.scss']
})
export class PostAssignmentComponent implements OnInit {
  assignmentForm: FormGroup;
  docUrl: any;
  docFiles = [];
  uploadFromLibrary = false;
  isdocUploaded: boolean;
  minDate: Date;
  classID: string;
  mapped_url = [];
  isUploading = false;
  textHeader: 'edit' | 'post' = POST;
  checked = false;
  dialoConfig = {
    width: '363px',
    height: '190px',
    backdropClass: 'blur',
    panelClass: ['delete-dialog-css'],
    autoFocus: false,
    data: null
  }
  disable = false;
  private destroy$$: Subject<any> = new Subject<any>();
  public entityType: string;
  public courseActivityEntityType: string = COURSE_ACTIVITY;
  public isCourseActivity = false;

  public isEditAction: boolean;
  progress = 0;

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ClassDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataModel,
    private fb: FormBuilder,
    public assigmentService: PostDetailService,
    public postService: PostAssignmentService,
    public snackbarService: SnackbarService,
    private courseActivitiesService: CourseActivitiesService,
  ) { }

  ngOnInit(): void {
    this.entityType = this.data.entityType;
    this.isCourseActivity = this.entityType === this.courseActivityEntityType;
    this.minDate = minDate;
    this.classID = this.data.id ? this.data.id : this.data.classID;
    this.dialogRef.updatePosition({ right: `0px` });
    this.data.post ? this.populateForm() : this.buildForm();
    this.isEditAction = this.textHeader === 'edit';
  }

  populateForm() {
    this.textHeader = EDIT;
    const posts = this.data.post;
    posts.files.map(file => {
      const fileName = new File([''], file);
      this.docFiles.push(fileName);
    });

    this.docUrl = this.docFiles;
    this.isdocUploaded = true;

    this.fillForm(posts);
    if (new Date() >= new Date(posts.submissionDate)) {
      this.assignmentForm.disable();
      this.disable = true;
      return;
    }
  }

  buildForm() {
    this.fillForm(null);
  }

  fillForm(post) {
    if (this.entityType === this.courseActivityEntityType) {
      this.assignmentForm = this.fb.group({
        title: [post ? post.title : null, [Validators.required]],
        description: [post ? post.description : null, [Validators.required]],
        marks: [post ? post.totalMarks : null, [Validators.required, Validators.pattern(positiveNmuberRegex)]]
      });
    } else {
      this.assignmentForm = this.fb.group({
        title: [post ? post.title : null, [Validators.required]],
        description: [post ? post.description : null, [Validators.required]],
        submission: [post ? post.submissionDate : null, [Validators.required]],
        marks: [post ? post.totalMarks : null, [Validators.required, Validators.pattern(positiveNmuberRegex)]]
      });
    }
    this.checked = this.data.post ? this.data.post.saveToLibrary : false;
  }

  getMarksError() {
    return this.assignmentForm.get('marks').hasError('required') ? 'Total marks is required' :
      this.assignmentForm.get('marks').hasError('pattern') ? 'Only positive number can be entered' : null
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onDocUpload(urlList) {
    this.docUrl = urlList.files;
    this.uploadFromLibrary = urlList.uploadFromLibrary;
    this.isdocUploaded = urlList.files.length > 0 ? true : false;
  }

  checkForValidation() {
    if (!this.assignmentForm.controls['title'].value) {
      this.assignmentForm.controls['title'].setErrors({ 'valid': false })
      this.assignmentForm.controls['title'].markAllAsTouched();
    }
    if (!this.assignmentForm.controls['description'].value) {
      this.assignmentForm.controls['description'].setErrors({ 'valid': false });
      this.assignmentForm.controls['description'].markAllAsTouched();
    }
    if (this.assignmentForm.controls['submission'] && !this.assignmentForm.controls['submission'].value) {
      this.assignmentForm.controls['submission'].setErrors({ 'valid': false })
      this.assignmentForm.controls['submission'].markAllAsTouched();
    }
    if (!this.assignmentForm.controls['marks'].value) {
      this.assignmentForm.controls['marks'].setErrors({ 'valid': false })
      this.assignmentForm.controls['marks'].markAllAsTouched();
    }
  }

  addEvent(event) {
    this.assignmentForm.controls['submission'].setValue(event.value);
  }

  onCheckboxClick(event) {
    this.checked = !this.checked;
  }

  getLibraryFilesBody(requestBody, callback?) {
    const fileNames = [];
    const filePaths = [];

    this.docUrl.map(file => {
      fileNames.push(file.name);
      filePaths.push(file.path);
    });
    requestBody.fileNames = fileNames;
    requestBody.filePaths = filePaths;
    if (callback) {
      requestBody.uploadFromCloud = true;
      return callback(requestBody);
    } else {
      requestBody.saveToLibrary = false;
      requestBody.uploadFromLibrary = true;
      requestBody.classID = this.classID;
    }
    return this.postService.postassignment(requestBody);
  }

  getLocalFilesBody(requestBody, callback?) {
    let postRequest;
    const removedFile = [];
    let addedFile = [];
    const urlFiles = [];

    if (this.textHeader === EDIT) {
      this.data.post.files.map(file => {
        const fileName = new File([''], file);
        urlFiles.push(fileName);
      });

      urlFiles.map(file => {
        if (this.docUrl.filter(url => url.name === file.name).length === 0) {
          removedFile.push(file.name)
        }
      });

      addedFile = this.docUrl.filter(url => urlFiles.filter(file => file.name === url.name).length === 0);
      requestBody.uploadedFiles = addedFile.length === 0 ? [] : addedFile;
      requestBody.deletedFiles = removedFile.length === 0 ? [] : removedFile;
      if (callback) {
        requestBody.uploadFromCloud = false;
        postRequest = callback(this.data.post.id, requestBody);
      } else {
        requestBody.uploadFromLibrary = false;
        postRequest = this.postService.editAssignment(this.data.post.activityID, requestBody);
      }
    } else {
      requestBody.uploadedFiles = this.docUrl;
      if (callback) {
        requestBody.uploadFromCloud = false;
        postRequest = callback(requestBody);
      } else {
        requestBody.uploadFromLibrary = false;
        requestBody.saveToLibrary = this.checked;
        requestBody.classID = this.classID;
        postRequest = this.postService.postassignment(requestBody);
      }
    }
    return postRequest;
  }

  postAssignment() {
    this.checkForValidation();
    if (!(this.docUrl && this.docUrl.length)) {
      this.isdocUploaded = false;
      return;
    }
    if (this.assignmentForm.valid && this.docUrl) {
      const dialogConfig = { ...this.dialoConfig };
      dialogConfig.data = {
        theme: this.isCourseActivity ? 'admin' : 'teacher',
        isUpload: true,
        alertText: this.textHeader === EDIT ? 'Are you sure you want to update this assignment ?' : null,
        actionType: this.textHeader === EDIT ? UPDATE_ACTION : null,
      };
      const dialog = this.dialog.open(DeleteDialogComponent, dialogConfig);
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$$)
      ).subscribe(isUploaded => {
        if (isUploaded === false) {
          return;
        } else if (this.isCourseActivity) {
          this.isUploading = true;
          this.assignmentForm.disable();

          const requestBody = {
            title: this.assignmentForm.controls.title.value,
            courseID: this.data.courseID,
            description: this.assignmentForm.controls.description.value,
            totalMarks: this.assignmentForm.controls.marks.value,
            activityType: ACTIVITY_TYPES.assignments,
            index: this.data.activityIndex,
          };
          let postRequest: any;
          if (this.uploadFromLibrary) {
            postRequest = this.getLibraryFilesBody(requestBody, this.courseActivitiesService.postCourseActivity);
          } else {
            postRequest = this.textHeader === EDIT ?
              this.getLocalFilesBody(requestBody, this.courseActivitiesService.updateCourseActivity) :
              this.getLocalFilesBody(requestBody, this.courseActivitiesService.postCourseActivity);
          }
          postRequest.pipe(
            takeUntil(this.destroy$$)
          ).subscribe(
            (resp) => {
              if (resp.type === HttpEventType.Response) {
                if (this.textHeader === EDIT) {
                  this.snackbarService.openSnackbar('successfully updated the assignment');
                } else {
                  this.snackbarService.openSnackbar('successfully uploaded the assignment');
                }
                this.dialogRef.close({ assignment: resp.body });
              }
              if (resp.type === HttpEventType.UploadProgress) {
                this.progress = 100 * resp.loaded / resp.total;
              }
            },
            (error) => {
              this.assignmentForm.enable();
              this.docFiles = this.docUrl;
              this.snackbarService.openSnackbar(error.error.error ? error.error.error : 'unable to upload, please try again');
              this.isUploading = false;
              this.progress = 0;
            }
          );
        } else {
          this.isUploading = true;
          this.assignmentForm.disable();

          let requestBody = {
            title: this.assignmentForm.controls['title'].value,
            submissionDate: new Date(this.assignmentForm.controls['submission'].value).toISOString(),
            description: this.assignmentForm.controls['description'].value,
            totalMarks: this.assignmentForm.controls['marks'].value,
          }
          let postRequest;
          if (this.uploadFromLibrary) {
            postRequest = this.getLibraryFilesBody(requestBody);
          } else {
            postRequest = this.getLocalFilesBody(requestBody);
          }
          postRequest.pipe(
            takeUntil(this.destroy$$)
          ).subscribe(
            (res) => {
              if (res.type === HttpEventType.Response) {
                this.snackbarService.openSnackbar('successfully uploaded the assignments');
                this.dialogRef.close('true');
              }
              if (res.type === HttpEventType.UploadProgress) {
                this.progress = 100 * res.loaded / res.total;
              }
            },
            (error) => {
              this.assignmentForm.enable();
              this.docFiles = this.docUrl;
              this.snackbarService.openSnackbar(error.error.error ? error.error.error : 'unable to upload, please try again');
              this.isUploading = false;
              this.progress = 0;
            }
          );
        }
      });
      this.isUploading = false;
      return;
    }
  }
}
