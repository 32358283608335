<ng-container *ngIf="isLoading; else content">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #content>
    <div class="directory-wrapper" [ngClass]="themeName">
        <ng-container *ngIf="step1; else nested">
            <ng-container>
                <div class="navbar">
                    <div (click)="close()" class="close"> <img [src]="getBackIcon()" /></div>
                    <div class="text"> {{header}}</div>
                </div>
            </ng-container>
            <hr>

            <ng-container>
                <div class="files">
                    <cdk-virtual-scroll-viewport class="lists" itemSize="50">
                        <div *cdkVirtualFor="let directory of directories; let i = index" (click)="navigateToFolder(directory)" class="example-item" [ngClass]="{'isFile': !directory.isFolder}">
                            <app-library-content-icon [userType]="userType" [isFolder]="true"></app-library-content-icon>
                            <div class="value"> {{directory.name}}</div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #nested>
            <ng-container>
                <div class="navbar">
                    <div (click)="back()" class="close"> <img [src]="getBackIcon()" /></div>
                    <div class="text"> {{selectedFile.name}}</div>
                </div>
            </ng-container>

            <hr>

            <ng-container *ngIf="nestedFiles && nestedFiles.length; else noresult">
                <div class="files">
                    <cdk-virtual-scroll-viewport class="lists" itemSize="50">
                        <div *cdkVirtualFor="let file of nestedFiles; let i = index" (click)="navigateToFolder(file)" class="example-item" [ngClass]="{'isFile': file.activityType !== 'folders'}">
                            <app-library-content-icon [userType]="userType" [activityType]="file.activityType" [isFolder]="file.isFolder"></app-library-content-icon>
                            <div class="value"> {{file.name}}</div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
            </ng-container>

            <ng-template #noresult>
                <div class="nofolder">This folder is empty</div>
            </ng-template>

        </ng-template>

        <hr>
        <ng-container>
            <div class="btn">
                <div class="cancel white-background-button">
                    <button mat-flat-button (click)="close()">CANCEL</button>
                </div>

                <div class="move" (click)="moveFiles()">
                    <button mat-flat-button color="primary" [disabled]="step1 || isFileMoved">MOVE HERE</button>
                </div>
            </div>
        </ng-container>
    </div>

</ng-template>
