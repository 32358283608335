import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssignmentFilesService } from '../services/assignment-files.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared-components/alert-dialog/alert-dialog.component';
import { CourseActivitiesService } from 'src/app/admin/services/course-activities.service';
import { FormControl } from '@angular/forms';

export const REVIEW = 'review';
export const VIEW = 'view';

export interface evaluatedAssignmentResponse {
  activityID: string,
  signedURLs: string[],
}
@Component({
  selector: 'app-review-assignment',
  templateUrl: './review-assignment.component.html',
  styleUrls: ['./review-assignment.component.scss']
})
export class ReviewAssignmentComponent implements OnInit, OnDestroy {

  public obtainedMarks = 0;
  public remarks = 0;
  public studentName: string;
  public title: string;
  public assignmentId: string;
  public studentId: string;
  public classID: string;
  public isEditable: boolean;
  public headerType: 'view' | 'review';
  private destroy$$ = new Subject<any>();

  public isCourseActivity = false;
  public evaluating = false;
  private totalMarks: number;

  public annotationMode = new FormControl('off');
  constructor(
    private route: ActivatedRoute,
    public location: Location,
    public dialog: MatDialog,
    public snackbarService: SnackbarService,
    public assignmentFileService: AssignmentFilesService,
    private router: Router,
    public courseActivitiesSvc: CourseActivitiesService
  ) { }

  ngOnInit(): void {
    this.assignmentId = this.route.snapshot.params.assignmentId;
    this.studentId = this.route.snapshot.params.studentId;
    const type = this.route.snapshot.params.type;
    this.isEditable = this.route.snapshot.params.canEdit === 'true' ? true : false;
    this.isCourseActivity = this.router.url.split('/').includes('course-activity');
    if (this.isCourseActivity) {
      if (type === REVIEW) {
        this.headerType = REVIEW;
        this.courseActivitiesSvc.getSubmission(this.assignmentId, this.studentId);
      } else {
        this.headerType = VIEW;
        this.courseActivitiesSvc.viewEvaluation(this.assignmentId, this.studentId);
      }
      this.courseActivitiesSvc.activitySubmissionDetails$.pipe(
        takeUntil(this.destroy$$)
      ).subscribe(resp => {
        if (resp) {
          this.obtainedMarks = resp.submission ? resp.submission.marksObtained : resp.evaluation.marksObtained;
          this.remarks = resp.submission ? resp.submission.remarks : resp.evaluation.remarks;
          this.studentName = resp.submission ? resp.submission.studentName : resp.evaluation.studentName;
          this.title = resp.activity ? resp.activity.title : resp.activity.title;
          this.totalMarks = resp.activity ? resp.activity.totalMarks : resp.evaluation.totalMarks;
        }
      });
    } else {
      if (type === REVIEW) {
        this.headerType = REVIEW;
        this.assignmentFileService.getSubmission(this.assignmentId, this.studentId);
      } else {
        this.headerType = VIEW;
        this.assignmentFileService.viewEvaluation(this.assignmentId, this.studentId);
      }
      this.assignmentFileService.assignmentDetails$.pipe(filter(res => !!res)).subscribe(resp => {
        this.obtainedMarks = resp.submittedAssignment ? resp.submittedAssignment.marks : resp.evaluatedAssignment.marks;
        this.remarks = resp.submittedAssignment ? resp.submittedAssignment.remarks : resp.evaluatedAssignment.remarks;
        this.studentName = resp.submittedAssignment ? resp.submittedAssignment.studentName : resp.evaluatedAssignment.studentName;
        this.title = resp.assignmentDetails ? resp.assignmentDetails.title : resp.evaluatedAssignment.title;
        this.classID = resp.assignmentDetails ? resp.assignmentDetails.classID : resp.evaluatedAssignment.classID;
        this.totalMarks = resp.assignmentDetails ? resp.assignmentDetails.totalMarks : resp.evaluatedAssignment.totalMarks;
      });
    }
  }

  close() {
    const dialogTheme = this.isCourseActivity ? 'admin' : 'teacher';
    const type = this.route.snapshot.params.type;
    if (type !== REVIEW) {
      this.location.back();
      return;
    } else {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '363px',
        height: '190px',
        backdropClass: 'blur',
        data: { theme: dialogTheme, action: true, alertText: 'Are you sure you want to close ? current progress will be discarded!!' },
        panelClass: ['delete-dialog-css'],
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe((isDeleted: boolean) => {
        if (!!isDeleted) {
          this.location.back();
        }
      });
    }
  }

  onEdit(editable: boolean) {
    // this.isEditable = editable;
  }

  save() {
    this.evaluating = true;
    const marks = parseInt(this.obtainedMarks.toString(), 10);
    if (marks > this.totalMarks) {
      this.snackbarService.openSnackbar('marks obtained can not be greater than total marks');
      return;
    }
    this.assignmentFileService.getUpdatedFiles().then(files => {
      const requestbody = {
        assignmentID: this.assignmentId,
        studentID: this.studentId,
        uploadedFiles: files,
        marks,
        remarks: this.remarks
      };
      this.evaluatePost(requestbody);
    });
  }

  evaluatePost(body) {
    this.evaluating = true;
    this.assignmentFileService.evaluateAssignment(body).subscribe(
      (res) => {
        this.evaluating = false;
        this.snackbarService.openSnackbar('successfully uploaded');
        this.location.back();
      },
      (err) => {
        this.evaluating = false;
        this.snackbarService.openSnackbar(`unable to evaluate the assignments : ${err.error.error}`);
      },
    );
  }

  saveActivity() {
    this.evaluating = true;
    const marks = parseInt(this.obtainedMarks.toString(), 10);
    if (marks > this.totalMarks) {
      this.snackbarService.openSnackbar('marks obtained can not be greater than total marks');
      return;
    }
    this.courseActivitiesSvc.getUpdatedFiles().then(files => {
      const requestbody = {
        activityID: this.assignmentId,
        studentID: this.studentId,
        uploadedFiles: files,
        marks,
        remarks: this.remarks
      };
      this.evaluateActivity(requestbody);
    });
  }

  evaluateActivity(body) {
    this.evaluating = true;
    this.courseActivitiesSvc.evaluateActivity(body).subscribe(
      (res) => {
        this.evaluating = false;
        this.snackbarService.openSnackbar('successfully uploaded');
        this.location.back();
      },
      (err) => {
        this.evaluating = false;
        this.snackbarService.openSnackbar(`unable to evaluate the assignments : ${err.error.error}`);
      },
    );
  }

  ngOnDestroy() {
    this.courseActivitiesSvc.reset();
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }

}
