import { Component, OnInit, ViewChild, Inject, ElementRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { School } from 'src/app/models/school.models';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SchoolService } from 'src/app/services/api/school.service';
import { CoursesService } from 'src/app/services/courses.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LabelService } from 'src/app/services/label.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-course-copy-dialog',
  templateUrl: './course-copy-dialog.component.html',
  styleUrls: ['./course-copy-dialog.component.scss']
})
export class CourseCopyDialogComponent implements OnInit, OnDestroy {

  selectedSchools: School[] = [];
  searchedSchools: School[] = [];
  placeholder = 'You can select multiple ';
  schoolFC = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('schoolInput') schoolInput: ElementRef<HTMLInputElement>;
  loading = true;
  private destroy$$: Subject<any> = new Subject<any>();
  labelData: any;
  copyingSchools = false;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
    private schoolService: SchoolService,
    private courseService: CoursesService,
    private snackbar: SnackbarService,
    public labelS: LabelService,
  ) { }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: `0px` });
    this.labelS.labels$.pipe(takeUntil(this.destroy$$)).subscribe(data => {
      this.labelData = data;
      if(this.labelData) {
        this.placeholder = this.placeholder + this.labelData.schools;
      }
    });
    this.schoolFC.valueChanges.pipe(
      takeUntil(this.destroy$$),
      debounceTime(300)
    ).subscribe(value => {
      if (value) {
        this.loading = true;
        this.schoolService.getSchoolPageReq('active', value, '', '').subscribe(resp => {
          this.searchedSchools = resp.schools;
          this.searchedSchools = this.searchedSchools.filter(school =>
            !this.selectedSchools.map(s => s.id).includes(school.id) && school.id !== this.data.schoolId);
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      }
    });
  }

  remove(school: School) {
    this.selectedSchools = this.selectedSchools.filter(s => s.id !== school.id);
    this.searchedSchools = [];
    this.loading = true;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.searchedSchools.map(school => school.id).includes(event.option.value)) {
      this.selectedSchools.push(this.searchedSchools.find(school => school.id === event.option.value));
      this.schoolFC.setValue(null);
      this.schoolInput.nativeElement.value = '';
      this.loading = true;
      this.searchedSchools = [];
    }
  }

  copy() {
    this.copyingSchools = true;
    this.courseService.copyCourseToSchools(this.data.courseId, this.selectedSchools.map(s => s.id)).subscribe(() => {
      this.snackbar.openSnackbar('Course copied successfully');
      this.dialogRef.close();
    }, err => {
      this.snackbar.openSnackbar(err);
      this.copyingSchools = false;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }
}
