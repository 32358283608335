import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';
import { MaterialModule } from 'src/app/material/material.module';
import { UsersTableComponent } from './users-table/users-table.component';
import { SharedComponentsModule } from 'src/app/shared-components/shared-components.module';
import { UserSettingsDialogComponent } from './user-settings-dialog/user-settings-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [UsersComponent, UsersTableComponent, UserSettingsDialogComponent],
  imports: [
    CommonModule,
    UsersRoutingModule,
    MaterialModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class UsersModule { }
