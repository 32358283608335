import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ClassSpecificService {

  private coTeachers$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private students$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private post$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private liveClass$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public get coTeachers$() {
    return this.coTeachers$$.asObservable();
  }

  public get liveClass$() {
    return this.liveClass$$.asObservable();
  }

  public get students$() {
    return this.students$$.asObservable();
  }

  public get post$() {
    return this.post$$.asObservable();
  }

  public classRoute(id) {
    return environment.apiUrl + 'class/get-by-id/' + id;
  }

  public liveClassRoute(day = 'today', classID?) {
    if (day) {
      day = day.toLowerCase();
    }
    if (classID) {
      return environment.apiUrl + `class/schedule?period=${day}&class-id=${classID}`;
    } else {
      return environment.apiUrl + `class/schedule?period=${day}`;
    }
  }

  public weeklyLiveClassRoute(startDate: Date, endDate: Date, classID?) {
    const start = startDate.toISOString();
    const end = endDate.toISOString();
    if (classID) {
      return environment.apiUrl + `class/schedule?period=weekly&start=${start}&end=${end}&class-id=${classID}`;
    } else {
      return environment.apiUrl + `class/schedule?period=weekly&start=${start}&end=${end}`;
    }
  }

  public coTeacherRoute(id) {
    return environment.apiUrl + 'class/co-teacher/get/' + id;
  }

  public postRoute(id) {
    return environment.apiUrl + 'class/activity/' + id;
  }

  public studentRoute(id) {
    return environment.apiUrl + 'class/student/get/' + id;
  }

  public deleteAssignmentRoute(id) {
    return environment.apiUrl + 'class/assignment/' + `${id}`;
  }

  public deleteMaterialRoute(id) {
    return environment.apiUrl + 'class/material/' + `${id}`;
  }

  public deleteTestRoute(id) {
    return environment.apiUrl + 'class/test/delete/' + `${id}`;
  }
  public changeClassStatusRoute(id) {
    return environment.apiUrl + 'class/status/' + `${id}`;
  }
  constructor(public crud: CrudService, public snackbar: SnackbarService,) { }

  getClassDetail(id) {
    return this.crud.get(this.classRoute(id));
  }

  getCoTeachers(id) {
    this.crud.get(this.coTeacherRoute(id)).subscribe(
      (coTeachers) => this.coTeachers$$.next(coTeachers),
      (err) => this.snackbar.openSnackbar(err.error.error)
    );
  }

  getPosts(id) {
    return this.crud.get(this.postRoute(id)).subscribe(
      (post) => this.post$$.next(post),
      (err) => this.snackbar.openSnackbar(err.error.error)
    );
  }

  getPostsV2(id) {
    return this.crud.get(this.postRoute(id))
  }

  getLiveClasses(classID, day) {
    this.liveClass$$.next(null);
    return this.crud.get(this.liveClassRoute(day, classID)).subscribe(
      (res) => {
        this.liveClass$$.next(res.schedule);
        if (res.error != "") {
          this.snackbar.openSnackbar(res.error)
        }
      },
      (err) => {
        this.snackbar.openSnackbar('Unable to fetch the live class details!!');
        this.liveClass$$.next([]);
      }
    );
  }

  getWeeklyLiveClasses(startDate: Date, endDate: Date, classID?: string) {
    this.liveClass$$.next(null);
    return this.crud.get(this.weeklyLiveClassRoute(startDate, endDate, classID)).subscribe(
      (res) => {
        this.liveClass$$.next(res.schedule);
        if (res.error !== '') {
          this.snackbar.openSnackbar(res.error);
        }
      },
      (err) => {
        this.snackbar.openSnackbar('Unable to fetch the live class details!!');
        this.liveClass$$.next([]);
      }
    );
  }

  deleteAssignmentPost(id) {
    return this.crud.delete(this.deleteAssignmentRoute(id));
  }

  deleteMaterialPost(id) {
    return this.crud.delete(this.deleteMaterialRoute(id));
  }

  deleteTest(id) {
    return this.crud.delete(this.deleteTestRoute(id));
  }

  getStudents(id) {
    this.crud.get(this.studentRoute(id)).subscribe(
      (students) => this.students$$.next(students),
      (err) => this.snackbar.openSnackbar(err.error.error)
    );
  }
  changeClassStatus(id, status) {
    const requestBody = {
      status
    }
    return this.crud.patch(this.changeClassStatusRoute(id), requestBody)
  }

  clearList() {
    this.post$$.next(null);
    this.students$$.next(null);
    this.liveClass$$.next(null);
    this.coTeachers$$.next(null);
  }
}
