<div class="create-wrapper student-theme">
    <div class="header">
        <div class="text">
            <div>Create Student Profile</div>
            <div class="img" (click)="close()"> <img src="../../../assets/close.svg"/></div>
        </div>
    </div>

    <div class="upload" [ngStyle]="isImageUploaded === false && {'border': '2px solid #ff8156', 'border-radius': '12px', 'color': '#ff8156'}">
        <app-upload [text]="'UPLOAD PHOTO'" (url)="onImageUpload($event)" [imgType]="'user'"></app-upload>
    </div>

    <div *ngIf="isImageUploaded === false" [ngStyle]="{'color': '#ff8156'}">Please upload image</div>

    <div class="formGroup">
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">

            <div class="student">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label> Name</mat-label>
                    <input matInput placeholder="Enter Student Name" formControlName="name">
                    <mat-error *ngIf="profileForm.controls['name'].touched">{{ getErrorName() }}</mat-error>
                </mat-form-field> 
            </div>

            <div class="username">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label> User Name</mat-label>
                    <input matInput placeholder="Enter User Name" formControlName="username">
                    <mat-error *ngIf="profileForm.controls['username'].touched">Student username can't be empty</mat-error>
                </mat-form-field> 
            </div>

    
            <div class="gender" [ngStyle]="!profileForm.controls['username'].valid && profileForm.controls['username'].touched && {'margin-top' : '24px'}">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Gender</mat-label>
                    <mat-select placeholder="Select gender" formControlName="gender" >
                        <mat-option *ngFor="let gender of genders" [value]="gender">
                          {{gender}}
                        </mat-option>
                      </mat-select>
                    <mat-error *ngIf="!profileForm.controls['gender'].valid && profileForm.controls['gender'].touched">
                        Gender can't be empty
                    </mat-error>  
                </mat-form-field> 
            </div>
    
            <div class="pin" [ngStyle]="!profileForm.controls['gender'].valid && profileForm.controls['gender'].touched && {'margin-top' : '24px'}">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Pin</mat-label>
                    <input matInput placeholder="Set a 4-digit pin" type="number" formControlName="pin" autocomplete="off">
                    <mat-error *ngIf="profileForm.controls['pin'].touched">pin can't be empty</mat-error>
                </mat-form-field> 
            </div>

            <div class="btn" >
                <button mat-flat-button type="submit" color="primary" [disabled]="isSubmitted">CONTINUE</button>
            </div>  
        </form>      
    </div>

</div>
