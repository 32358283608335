import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ZoomImageComponent } from 'src/app/shared/zoom-image/zoom-image.component';

@Component({
  selector: 'app-test-question',
  templateUrl: './test-question.component.html',
  styleUrls: ['./test-question.component.scss']
})
export class TestQuestionComponent implements OnInit {
  @Input() selectedQuestion;
  questionChoosen;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.questionChoosen = this.selectedQuestion[0];
  }

  radioSelect(option) {
    this.selectedQuestion[0].options.map(list => {
      if(list.option === option.option) {
        list.isSelected = true;
      } else if(list.isSelected) {
        list.isSelected = false;
      }
    })
  }

  multipleSelect(event, option) {
    if(event.checked) {
      this.selectedQuestion[0].options.map(list => {
        if(list.option === option.option) {
          list.isSelected = true
        }
      })
    } else {
      this.selectedQuestion[0].options.map(list => {
        if(list.option === option.option) {
          list.isSelected = false;
        }
      })
    }
  }

  clear() {
    this.selectedQuestion[0].options.map(option => option.isSelected === true ?  option.isSelected = false : null);
  }

  zoomImage(url) {
    this.dialog.open(ZoomImageComponent, {
      width: '80%',
      height: '70%',
      data: {imgUrl : url},
    });
  }

}
