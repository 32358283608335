import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';
import { CrudService } from '../crud.service';
import { School, PagedSchoolData } from 'src/app/models/school.models';
import { takeUntil } from 'rxjs/operators';
import { FindSchoolUserRoleService } from '../find-school-user-role.service';
import { SelectedSchoolService } from '../selected-school.service';
import { LabelService } from '../label.service';
import { ARCHIVED_STATUS, UNARCHIVED_STATUS } from 'src/app/Constants/Constant';

@Injectable({
  providedIn: 'root'
})
export class SchoolService implements OnDestroy {
  private destroy$$: Subject<any> = new Subject<any>();
  private schoolList$$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private schoolCreatorLabel;
  private schoolAdminLabel;
  private schoolMemberLabel;

  private pagedSchoolData$$: BehaviorSubject<PagedSchoolData> = new BehaviorSubject<PagedSchoolData>(null);
  public get pagedSchoolData$(): Observable<PagedSchoolData> {
    return this.pagedSchoolData$$.asObservable();
  }

  private schoolsSearchTerm$$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public get schoolsSearchTerm$(): Observable<string> {
    return this.schoolsSearchTerm$$.asObservable();
  }

  private schoolsStatus$$: BehaviorSubject<string> = new BehaviorSubject<string>('active');
  public get schoolsStatus$(): Observable<string> {
    return this.schoolsStatus$$.asObservable();
  }

  private schoolsRole$$: BehaviorSubject<string> = new BehaviorSubject<string>('all');
  public get schoolsRole$(): Observable<string> {
    return this.schoolsRole$$.asObservable();
  }

  private subscription = new Subscription();
  public get schooList$(): Observable<any> {
    return this.schoolList$$.asObservable();
  }

  public get isLoading$() {
    return this.isLoading$$.asObservable();
  }

  get route(): string {
    return environment.apiUrl + 'school/create';
  }

  get school(): string {
    return environment.apiUrl + 'school/get';
  }


  public getSchool(role): string {
    return environment.apiUrl + 'school/get?role=' + `${role}`;
  }
  public changeSchoolStatusRoute(id) {
    return environment.apiUrl + 'school/status/' + `${id}`;
  }
  constructor(
    private crud: CrudService,
    public schoolUser: FindSchoolUserRoleService,
    private selectedSchoolService: SelectedSchoolService,
    private labelS: LabelService
  ) {
    this.subscription = labelS.labels$.subscribe(val => {
      this.schoolCreatorLabel = val['schoolcreator'];
      this.schoolAdminLabel = val['schooladmin'];
      this.schoolMemberLabel = val['schoolmember']
    });
  }

  create(body) {
    return this.crud.post(this.route, body);
  }

  getSchools() {
    if (this.schoolList$$.value.length > 0) {
      return;
    }
    const req = this.crud.get(this.school);
    req.pipe(
      takeUntil(this.destroy$$),
    )
      .subscribe(
        (resp) => {
          this.isLoading$$.next(false);
          const schoolsList = this.getSchoolRole(resp.schools);
          // schoolsList = this.getBase64Image(resp.schools);
          this.schoolList$$.next(schoolsList);
        },
        (err) => {
          this.schoolList$$.next(err)
        }
      );
  }

  getSchoolPageReq(status: string, searchTerm: string, nextCursor: string, roleQueryParam: string) {
    return this.crud.get(environment.apiUrl + `school/get?dash=true&status=${status}&term=${searchTerm}&cursor=${nextCursor}&role=${roleQueryParam}`);
  }

  getSchoolsPage(searchTerm: string, status: string, role: string, getFirstPage = false) {
    const schoolsData: PagedSchoolData = this.pagedSchoolData$$.value;
    const currentSearchTerm = this.schoolsSearchTerm$$.value;
    const currentStatus = this.schoolsStatus$$.value;
    const currentRole = this.schoolsRole$$.value;
    let appendData = false;
    if (
      currentStatus === status &&
      currentSearchTerm === searchTerm &&
      currentRole === role &&
      !getFirstPage
      && schoolsData) {
      appendData = true;
    }
    let nextCursor = schoolsData && schoolsData.nextCursor ? schoolsData.nextCursor : '';
    if (nextCursor === 'NULL' && appendData) {
      return;
    } else if (nextCursor === 'NULL' || !appendData) {
      nextCursor = '';
    }
    this.isLoading$$.next(true);
    let roleQueryParam = role;
    if (roleQueryParam === 'all') {
      roleQueryParam = '';
    }
    const req = this.crud.get(environment.apiUrl + `school/get?dash=true&status=${status}&term=${searchTerm}&cursor=${nextCursor}&role=${roleQueryParam}`);
    req.pipe(
      takeUntil(this.destroy$$),
    )
      .subscribe(
        (resp) => {
          let updatedSchoolsData: PagedSchoolData;
          if (appendData) {
            updatedSchoolsData = { ...schoolsData };
            updatedSchoolsData.nextCursor = resp.nextCursor;
            let newSchools = this.getBase64Image(resp.schools);
            newSchools = this.getSchoolRole(resp.schools);
            updatedSchoolsData.schools = [...updatedSchoolsData.schools, ...newSchools];
          } else {
            updatedSchoolsData = resp;
            updatedSchoolsData.schools = this.getBase64Image(resp.schools);
            updatedSchoolsData.schools = this.getSchoolRole(resp.schools);
          }
          this.schoolsSearchTerm$$.next(searchTerm);
          this.schoolsStatus$$.next(status);
          this.schoolsRole$$.next(role);
          this.pagedSchoolData$$.next(updatedSchoolsData);
          this.isLoading$$.next(false);
        },
        (err) => {
          this.isLoading$$.next(false);
        }
      );
  }

  getBase64Image(schools: School[]) {
    schools.forEach(school => {
      if (school.logo !== '') {
        this.crud.get(school.logo).subscribe(
          (res) => school.logo = res, (error) => {
            console.log(error);
            school.logo = '';
          }
        );
      }
    });
    return schools;
  }

  getSchoolRole(schools: School[]) {
    schools.map(school => {
      this.assignSchoolRole(school)
    })
    return schools;
  }

  assignSchoolRole(school) {
    const userId = localStorage.getItem('id');
    if (school.creatorID === userId) {
      school.role = `${this.schoolCreatorLabel}`;
      school.isCreator = true;
      school.isAdmin = false;
      school.isMember = false;
    } else if (school.admins && school.admins.includes(userId)) {
      school.role = `${this.schoolAdminLabel}`;
      school.isCreator = false;
      school.isAdmin = true;
      school.isMember = false;
    } else {
      school.role = `${this.schoolMemberLabel}`;
      school.isCreator = false;
      school.isAdmin = false;
      school.isMember = true;
    }
  }

  selectSchool(school) {
    this.selectedSchoolService.selectSchool(school);
  }

  getSelectByRole(role) {
    this.isLoading$$.next(true);
    const schools: Observable<any> = this.crud.get(this.getSchool(role));
    schools.pipe(
      takeUntil(this.destroy$$),
    )
      .subscribe(
        (schools) => {
          this.isLoading$$.next(false);
          schools = this.getSchoolRole(schools);
          // schools = this.getBase64Image(schools);
          this.schoolList$$.next(schools);
        },
        (err) => {
          this.schoolList$$.next(err)
        }
      )
  }
  changeSchoolStatus(id, status) {
    const requestBody = {
      "status": status
    }
    return this.crud.patch(this.changeSchoolStatusRoute(id), requestBody)
  }
  clearSchool() {
    this.schoolList$$.next([]);
    this.isLoading$$.next(true);
    this.pagedSchoolData$$.next(null);
    this.schoolsSearchTerm$$.next('');
    this.schoolsRole$$.next('all');
    this.schoolsStatus$$.next('active');
  }

  archiveSchool(id: string) {
    const pagedData = this.pagedSchoolData$$.value;
    const index = pagedData.schools.findIndex((school) => school.id === id);
    pagedData.schools.splice(index, 1);
    this.pagedSchoolData$$.next(pagedData);
  }
  unArchiveSchool(id: string) {
    const pagedData = this.pagedSchoolData$$.value;
    const index = pagedData.schools.findIndex((school) => school.id === id);
    pagedData.schools.splice(index, 1);
    this.pagedSchoolData$$.next(pagedData);
  }

  ngOnDestroy() {
    this.destroy$$.next(null);
    this.destroy$$.complete();
  }

}
