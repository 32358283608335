<ng-container *ngIf="isLoading; else test">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #test>
    <div class="test-section">
        <div class="solution-wrapper">
            <div class="header">
                <div (click)="back()" class="back">
                    <img *ngIf="!isAdmin" src="assets/blue-back.svg" />
                    <img *ngIf="isAdmin" src="assets/grey-back.svg" />
                </div>
                <div class="txt">{{testDetail.title | titlecase}}</div>
            </div>

            <div class="subheader">
                <div class="section1">
                    <div class="meta-card">
                        <div>
                            <div class="marks">
                                <div class="label">Total Marks</div>
                                <div class="value1"> : {{testDetail.totalMarks}}</div>
                            </div>
                            <ng-container *ngIf="!isCourseActivity">
                                <div class="date">
                                    <div class="label">Date</div>
                                    <div class="value2"> : {{testDetail.timings | date: dateFormat}}</div>
                                </div>
                                <div class="date">
                                    <div class="label">Time</div>
                                    <div class="value3"> : {{testDetail.timings | date: 'shortTime'}}</div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div [ngClass]="isAdmin ? 'admin-color': ''" class="preview" (click)="previewTest()">PREVIEW TEST </div>
                </div>

                <div *ngIf="!isCourseActivity" class="section2">
                    <div class="doc-upload">
                        <app-doc-upload [text]="'UPLOAD SOLUTIONS'" [isdocUploaded]="isdocUploaded" (docUrl)="onDocUpload($event)" type="solution"></app-doc-upload>
                    </div>
                    <div class="btn">
                        <button [ngClass]="isAdmin ? 'admin-background': ''" mat-flat-button [color]="'primary'" (click)="uploadSolution()" [disabled]="isUploading">SHARE</button>
                    </div>
                </div>

                <ng-container *ngIf="testDetail.solutions && testDetail.solutions.length">
                    <div class="hr">
                        <hr>
                    </div>
                    <div class="shared-solution">
                        <div class="wrapper">
                            <div class="text">Shared Solutions</div>
                        </div>
                        <div class="delete" (click)="deleteSolution()">DELETE ALL</div>
                    </div>
                    <div class="view-attachment">
                        <app-view-attachment [files]="testDetail.solutions" [urls]="testDetail.solutionURLs"></app-view-attachment>
                    </div>
                </ng-container>
                <div class="full-width-hr">
                    <hr>
                </div>

                <div class="table">
                    <app-test-solution-table [isCourseActivity]="isCourseActivity" [totalMarks]="testDetail.totalMarks" [transactions]="studentList" [question]="questionList" (isReviewAdded)="onReviewAdded($event)" [displayedColumns]="displayedColumns" [sortDirection]="sortDirection"
                        (getSortDirection)="onSortChange($event)" [disableActions]="disableTableActions"></app-test-solution-table>
                </div>
            </div>
        </div>
    </div>
</ng-template>
