<ng-container *ngIf="isLoading; else settings">
    <app-spinner></app-spinner>
</ng-container>
<ng-template #settings>
    <div [ngClass]="isDeleting ? 'blur': ''" class="course-category-wrapper">
        <div class="inside-loader" *ngIf="isDeleting">
            <app-spinner></app-spinner>
        </div>
        <div class="header">
            <div class="setting">
                <div (click)="back()"> <img src="../../../../assets/grey-back.svg" /> </div>
                <div class="text">Settings - Course Categories</div>
            </div>
            <div class="btn">
                <button mat-flat-button color="primary" (click)="add()">
          ADD COURSE CATEGORY
        </button>
            </div>
        </div>

        <ng-container *ngIf="courseCategories.length == 0">
            <div class="empty">
                <div class="title">You haven’t added any Course Category yet.</div>
                <div class="note">You can add a Course Category by tapping on top right button "ADD COURSE CATEGORY"</div>
            </div>
        </ng-container>

        <ng-container *ngIf="courseCategories.length > 0">
            <div class="tail">
                <div class="settings-list">
                    <div *ngFor="let category of courseCategories" class="details">
                        <div>{{category.name}}</div>
                        <div class="select">
                            <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                            <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                                <button mat-menu-item (click)="editCategory(category)">
                  <span>Edit Category</span>
                </button>
                                <button mat-menu-item (click)="deleteCategory(category)">
                  <span>Delete Category</span>
                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
