import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ANALYTICS_TABS } from 'src/app/Constants/Constant';
import { SuperAdminAnalyticsService } from '../services/super-admin-analytics.service';

@Component({
  selector: 'app-super-admin-analytics',
  templateUrl: './super-admin-analytics.component.html',
  styleUrls: ['./super-admin-analytics.component.scss']
})
export class SuperAdminAnalyticsComponent implements OnInit, AfterViewInit {

  public tabs = [ANALYTICS_TABS.OVERVIEW, ANALYTICS_TABS.SCHOOL, ANALYTICS_TABS.TEACHER, ANALYTICS_TABS.STUDENT]

  constructor(
    private analyticsSvc: SuperAdminAnalyticsService,
  ) {
    this.tabs[2] = 'Instructor';
    this.tabs[3] = 'Learners';
  }

  @ViewChild('overview') overViewTab: ElementRef;
  @ViewChild('subject') subjectTab: ElementRef;
  @ViewChild('school') schoolTab: ElementRef;
  @ViewChild('student') studentTab: ElementRef;
  @ViewChild('teacher') teacherTab: ElementRef;

  private schools = [];
  private sessions = [];
  public selectedTab: string = this.tabs[0];
  public selectedTabIndex: number = 0;

  ngOnInit(): void {
    this.analyticsSvc.getOverviewDashboard();
    this.analyticsSvc.getSchoolDashboard();
    this.analyticsSvc.getStudentDashboard();
    this.analyticsSvc.getTeacherDashboard();
  }

  ngAfterViewInit() {
    this.analyticsSvc.overviewDashboard$.subscribe((data) => {
      if (data) {
        this.overViewTab.nativeElement.src = data.url + '#titled=true&bordered=false';
      }
    })

    this.analyticsSvc.schoolDashboard$.subscribe((data) => {
      if (data) {
        this.schoolTab.nativeElement.src = data.url + '#titled=true&bordered=false';
        if (data.filters) {
          // this.allTabFilters[1] = data.filters;
        }
      }
    })

    this.analyticsSvc.studentDashboard$.subscribe((data) => {
      if (data) {
        this.studentTab.nativeElement.src = data.url + '#titled=true&bordered=false';
        if (data.filters) {
          // this.allTabFilters[3] = data.filters;
        }
      }
    })

    this.analyticsSvc.teacherDashboard$.subscribe((data) => {
      if (data) {
        this.teacherTab.nativeElement.src = data.url + '#titled=true&bordered=false';
        if (data.filters) {
          // this.allTabFilters[2] = data.filters;
        }
      }
    })
  }

  tabSelected(event) {
    this.selectedTab = this.tabs[event.index];
    this.selectedTabIndex = event.index;
  }

}
