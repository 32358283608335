<ng-container>
    <table class="users-table" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="srNo">
            <th mat-header-cell *matHeaderCellDef>Sr No.</th>
            <td mat-cell *matCellDef="let transaction; let i = index"> {{ i + 1 + this.paginator?.pageIndex * this.paginator?.pageSize}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.name ? transaction.name : '(Unregistered)'}} </td>
        </ng-container>

        <ng-container matColumnDef="mobileNumber">
            <th mat-header-cell *matHeaderCellDef>Phone </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.mobileNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="schoolsCount">
            <th mat-header-cell *matHeaderCellDef>Schools Count</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.schoolsCreated}} </td>
        </ng-container>

        <ng-container matColumnDef="maxSchools">
            <th mat-header-cell *matHeaderCellDef>Max Schools</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.accessRights.maxSchools}} </td>
        </ng-container>

        <ng-container matColumnDef="schoolsAssociated">
            <th mat-header-cell *matHeaderCellDef>Schools Associated</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.schoolsAssociated}} </td>
        </ng-container>

        <ng-container matColumnDef="classesCreated">
            <th mat-header-cell *matHeaderCellDef>Classes Created</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.classesCreated}} </td>
        </ng-container>

        <ng-container matColumnDef="classesAssociated">
            <th mat-header-cell *matHeaderCellDef>Classes Associated</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.classesAssociated}} </td>
        </ng-container>

        <ng-container matColumnDef="standard">
            <th mat-header-cell *matHeaderCellDef>Standard</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.standard}} </td>
        </ng-container>

        <ng-container matColumnDef="accessRights">
            <th mat-header-cell *matHeaderCellDef>Access Rights</th>
            <td mat-cell *matCellDef="let transaction">
              <div [ngClass]="!transaction.accessRights.schools ? 'inactive' : 'active'">{{(transaction.accessRights.schools ? 'can create schools' : 'can not create schools') | titlecase}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let transaction">
            <div [ngClass]="transaction.status">
              {{transaction.status | titlecase}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
            <td mat-cell *matCellDef="let transaction; let i = index" class="action-table-header">
                <div class="select">
                    <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                    <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                        <button
                          mat-menu-item
                          (click)="changeStatus(transaction, i)"
                        >Make {{transaction.status == 'active' ? 'Inactive' : 'Active'}}</button>
                        <button mat-menu-item (click)="changeMobileNumber(transaction, i)">Change Mobile Number</button>
                        <button mat-menu-item (click)="changeAccessRights(transaction, i)">Update Access Rights</button>
                    </mat-menu>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <ng-container>
        <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"> </mat-paginator>
    </ng-container>
</ng-container>
