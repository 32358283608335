import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { SettingsService } from 'src/app/services/settings.service';
import { LabelService } from 'src/app/services/label.service';
import { FormControl } from '@angular/forms';
import { SelectedSchoolService } from 'src/app/services/selected-school.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isSchoolSpecificSetting: boolean;
  @Input() selectedSchool: any;
  @Output() specificSelectedSetting = new EventEmitter();
  standardsLabel;
  subjectsLabel;
  sectionsLabel;
  sessionLabel;
  settings = [
    { name: 'Standards', src: "../../../../assets/standards.svg" },
    { name: 'Subjects', src: "../../../../assets/subjects.svg" },
    { name: 'Sections', src: "../../../../assets/sections.svg" },
    { name: 'Session', src: "../../../../assets/sessions.svg" },
  ]

  otherSetting = ['Rename', 'Course Categories']

  selectedSetting: any;

  allowTeacherToInvite: FormControl;

  updatingInviteSetting = false;
  private destroy$$: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public sidenavService: ToggleSidenavService,
    public settingService: SettingsService,
    public labelS: LabelService,
    private shcoolService: SelectedSchoolService,
    private snackbar: SnackbarService) {
  }

  ngOnInit(): void {
    const allowTeacherToInvite = this.selectedSchool && this.selectedSchool.allowInvites ? true : false;
    this.allowTeacherToInvite = new FormControl(allowTeacherToInvite);
    if(this.isSchoolSpecificSetting) {
      this.sidenavService.minmizeSidenav();
    }else {
      this.sidenavService.maximizeSidenav();
    }
    this.labelS.labels$.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(val => {
      this.standardsLabel = val['standards'];
      this.subjectsLabel = val['subjects'];
      this.sectionsLabel = val['sections'];
      this.sessionLabel = val['session'];
    });
    this.allowTeacherToInvite.valueChanges.subscribe(value => {
      if (this.selectedSchool) {
        if (value !== this.selectedSchool.allowInvites) {
          this.updatingInviteSetting = true;
          this.shcoolService.updateSchool(this.selectedSchool.id, { allowInvites: value })
            .pipe(
              takeUntil(this.destroy$$)
            ).subscribe(res => {
              this.selectedSchool.allowInvites = value;
              this.updatingInviteSetting = false;
            }, err => {
              this.updatingInviteSetting = false;
              this.allowTeacherToInvite.patchValue(!value);
              this.snackbar.openSnackbar('unable to update the setting at the moment');
            });
        }
      }
    });
  }

  ngOnChanges() {
    if (this.selectedSchool && this.selectedSchool.allowInvites !== null && this.allowTeacherToInvite) {
      this.allowTeacherToInvite.patchValue(this.selectedSchool && this.selectedSchool.allowInvites);
    }
  }

  selectSettings(chooseSetting) {
    this.selectedSetting = this.settings.filter(setting => { return setting.name === chooseSetting.name });
    this.selectedSetting = this.selectedSetting[0].name.toLowerCase();
    this.isSchoolSpecificSetting ? this.specificSelectedSetting.emit(this.selectedSetting) :
      this.router.navigate(['school', 'dashboard', 'settings', this.selectedSetting, { title: this.selectedSetting }])
  }

  seeSetting(setting) {
    this.router.navigate(['school', 'dashboard', 'settings', setting.replace(/\s+/g, '-').toLowerCase()]);
  }

  ngOnDestroy() {
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }
}
