import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-stream-dialog',
  templateUrl: './stream-dialog.component.html',
  styleUrls: ['./stream-dialog.component.scss']
})
export class StreamDialogComponent implements OnInit {
  stream: string;
  constructor(public dialogref: MatDialogRef<any>) { }

  ngOnInit(): void {
  }

  send() {
    this.dialogref.close(this.stream);
  }

}
