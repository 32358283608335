import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChildWorkActivity, WorkState } from 'src/app/models/child-work-activity-model';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/services/crud.service';
import { TODAY, UPCOMING, OVERDUE, COMPLETED } from 'src/app/Constants/Constant';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ChildWorkService {

  constructor(private crud: CrudService, private snackbar: SnackbarService) { }

  private loadingState$$: BehaviorSubject<WorkState> = new BehaviorSubject<WorkState>({
    overdue: true, upcoming: true, completed: true, today: true
  });

  public get loadingState$(): Observable<WorkState> {
    return this.loadingState$$.asObservable();
  }

  private errorState$$: BehaviorSubject<WorkState> = new BehaviorSubject<WorkState>({
    overdue: false, upcoming: false, completed: false, today: false
  });

  public get errorState$(): Observable<WorkState> {
    return this.errorState$$.asObservable();
  }

  private workToday$$: BehaviorSubject<ChildWorkActivity[]> = new BehaviorSubject<ChildWorkActivity[]>([]);

  public get workToday$(): Observable<ChildWorkActivity[]> {
    return this.workToday$$.asObservable();
  }

  private workUpcoming$$: BehaviorSubject<ChildWorkActivity[]> = new BehaviorSubject<ChildWorkActivity[]>([]);

  public get workUpcoming$(): Observable<ChildWorkActivity[]> {
    return this.workUpcoming$$.asObservable();
  }

  private workOverdue$$: BehaviorSubject<ChildWorkActivity[]> = new BehaviorSubject<ChildWorkActivity[]>([]);

  public get workOverdue$(): Observable<ChildWorkActivity[]> {
    return this.workOverdue$$.asObservable();
  }

  private workCompleted$$: BehaviorSubject<ChildWorkActivity[]> = new BehaviorSubject<ChildWorkActivity[]>([]);

  public get workCompleted$(): Observable<ChildWorkActivity[]> {
    return this.workCompleted$$.asObservable();
  }


  public getWorkActivities(studentId: string, category: string) {

    this.setLoadingAndErrorState(category, true, false);

    this.crud.get(environment.apiUrl + `student/activity/${studentId}?category=${category}`).subscribe((resp) => {
      const loadingState = this.loadingState$$.value;
      switch (category) {
        case TODAY:
          this.workToday$$.next(resp);
          this.loadingState$$.next({ ...loadingState, today: false });
          break;
        case UPCOMING:
          this.workUpcoming$$.next(resp);
          this.loadingState$$.next({ ...loadingState, upcoming: false });
          break;
        case OVERDUE:
          this.workOverdue$$.next(resp);
          this.loadingState$$.next({ ...loadingState, overdue: false });
          break;
        case COMPLETED:
          this.workCompleted$$.next(resp);
          this.loadingState$$.next({ ...loadingState, completed: false });
          break;
      }
    }, (err) => {
      this.setLoadingAndErrorState(category, false, true);
      this.snackbar.openSnackbar('unable to fetch work activities');
    });
  }


  private setLoadingAndErrorState(category: string, loading: boolean, error: boolean) {
    const loadingState = this.loadingState$$.value;
    const errorState = this.errorState$$.value;
    switch (category) {
      case TODAY:
        this.loadingState$$.next({ ...loadingState, today: loading });
        this.errorState$$.next({ ...errorState, today: error });
        break;
      case UPCOMING:
        this.loadingState$$.next({ ...loadingState, upcoming: loading });
        this.errorState$$.next({ ...errorState, upcoming: error });
        break;
      case OVERDUE:
        this.loadingState$$.next({ ...loadingState, overdue: loading });
        this.errorState$$.next({ ...errorState, overdue: error });
        break;
      case COMPLETED:
        this.loadingState$$.next({ ...loadingState, completed: loading });
        this.errorState$$.next({ ...errorState, completed: error });
        break;
    }
  }
}
