import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ParentService } from '../services/parent.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Parent } from 'src/app/models/parent.model';
import { Student } from 'src/app/models/student.models';
import { takeUntil, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ChildClassService } from '../child-dashboard/services/child-class.service';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPinComponent } from '../forgot-pin/forgot-pin.component';
import { SchoolGroupService } from 'src/app/services/school-group.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SELECTED_SCHOOL_GROUP_KEY, SELF_ID_KEY } from 'src/app/Constants/Constant';
import { SchoolService } from 'src/app/services/api/school.service';
import { TeacherService } from 'src/app/services/api/teacher.service';
import { LiveClassService } from 'src/app/services/live-class.service';
import { Location } from '@angular/common';
import { LabelService } from 'src/app/services/label.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ReportDataService } from 'src/app/admin/services/report-data.service';
import { CoursesService } from 'src/app/services/courses.service';

@Component({
  selector: 'app-choose-account',
  templateUrl: './choose-account.component.html',
  styleUrls: ['./choose-account.component.scss']
})
export class ChooseAccountComponent implements OnInit, OnDestroy {

  parentProfile: Parent;
  isLoading = true;
  childrenList: Student[];
  selectedProfile: any;
  isParentSelected: boolean;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    placeholder: '*'
  };
  public destroy$$ = new Subject<any>();
  isSchool = false;
  isTeacher = false;
  schoolGroups = []

  constructor(
    public parentService: ParentService,
    public childService: ChildClassService,
    public dialog: MatDialog,
    public snackbar: SnackbarService,
    public router: Router,
    private route: ActivatedRoute,
    private schoolGroupService: SchoolGroupService,
    private localstorage: LocalStorageService,
    private schoolService: SchoolService,
    private teacherService: TeacherService,
    private liveClassService: LiveClassService,
    private loc: Location,
    private labelService: LabelService,
    private analyticsService: AnalyticsService,
    private reportDataService: ReportDataService,
    private coursesService: CoursesService
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('school')) {
      this.isSchool = true;
    }
    else if (this.router.url.includes('teacher')) {
      this.isTeacher = true;
    }
    console.log(this.router.url);
    if (!(this.isSchool || this.isTeacher)) {
      this.parentService.getParentDetails().pipe(
        takeUntil(this.destroy$$),
        map(parent => this.parentService.getbase64(parent))
      ).subscribe(
        (parent) => {
          this.parentProfile = parent;
          this.parentService.setParentDetails(this.parentProfile);
        }
      )
      this.parentService.getChildren();
      this.parentService.children$.pipe(
        takeUntil(this.destroy$$)
      )
        .subscribe(
          (child) => {
            this.childrenList = child;
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            this.snackbar.openSnackbar(err.error.error);
          }
        )
    }
    else {
      this.schoolGroupService.isLoading$.subscribe((isLoading) => {
        this.isLoading = isLoading;
      })
      this.schoolGroupService.getSchoolGroups();
      this.schoolGroupService.schoolGroups$.subscribe((schoolGroups) => {
        this.schoolGroups = schoolGroups;
      })
    }
  }

  childSelected(index) {
    this.selectedProfile = this.childrenList[index];
    this.isParentSelected = false;
  }

  parentSelected() {
    this.selectedProfile = this.parentProfile;
    this.isParentSelected = true;
  }

  addStudent() {
    console.log("student div called")
  }

  onOtpChange(event) {
    if (event.length === 4) {
      const requestBody = this.isParentSelected ?
        {
          role: 'parent',
          pin: event,
          mobileNumber: this.selectedProfile.mobileNumber
        } :
        {
          id: this.selectedProfile.id,
          pin: event,
        }

      const verify = this.isParentSelected ? this.parentService.verifyParentPin(requestBody) : this.parentService.verifyStudentPin(requestBody);
      verify.pipe(
        takeUntil(this.destroy$$)
      )
        .subscribe(
          (res) => {
            this.snackbar.openSnackbar(res.msg);
            if (this.isParentSelected) {
              localStorage.setItem("isParentSelected", "true");
              this.router.navigate(['parent', 'dashboard'])
            } else {
              localStorage.setItem("isParentSelected", "false");
              this.childService.setChildName(this.selectedProfile.name);
              this.router.navigate(['parent', 'child', this.selectedProfile.id]);
            }
          },
          (err) => this.snackbar.openSnackbar(err.error.error)
        )
    }
  }

  forgotPin() {
    const dialogRef = this.dialog.open(ForgotPinComponent, {
      width: '46%',
      height: '100%',
      backdropClass: 'blur',
      data: { user: this.selectedProfile, type: this.isParentSelected ? 'parent' : 'student' },
      panelClass: ['delete-dialog-css', 'student-theme'],
      autoFocus: false
    })
  }

  back() {
    this.selectedProfile = ''
  }

  goBack() {
    this.loc.back()
  }

  goToDashBoard() {
    if (this.isSchool) {
      this.router.navigate(['school', 'dashboard'])
    }
    else {
      this.router.navigate(['teacher', 'dashboard'])
    }
  }

  selectSchool(schoolGroup) {
    let previousSchoolGroup = this.localstorage.getItem(SELECTED_SCHOOL_GROUP_KEY);
    this.localstorage.setItem(SELECTED_SCHOOL_GROUP_KEY, schoolGroup.id)
    this.labelService.getLabels();
    if (this.isSchool) {
      if (previousSchoolGroup !== schoolGroup.id) {
        this.schoolService.clearSchool();
        this.analyticsService.clear();
        this.reportDataService.clear();
        this.coursesService.clear();
      }
      this.router.navigate(['school', 'dashboard', 'schools'])
    }
    else {
      if (previousSchoolGroup !== schoolGroup.id) {
        this.teacherService.clearClasses();
        this.liveClassService.clearLiveClassData();
      }
      this.router.navigate(['teacher', 'dashboard', 'classes'])
    }
  }

  ngOnDestroy() {
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }

}
