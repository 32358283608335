<div class="test-source-wrapper">
    <div class="text">How do you want to create the test?</div>

    <div class="action">
        <div class="copy">
            <button mat-flat-button color="primary" (click)="action('copy')">COPY FROM LIBRARY</button>
        </div>
        <div class="scratch">
            <button mat-flat-button color="primary" (click)="action('create')">CREATE FROM SCRATCH</button>
        </div>
    </div>
</div>