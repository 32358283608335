import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  public sort: 'asc' | 'desc' = 'desc';
  constructor() { }

  setSortDirection(dir: 'asc' | 'desc') {
    this.sort = dir;
  }

  getSortDirection(): 'asc' | 'desc' {
    return this.sort;
  }

}
