import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { User } from '../models/user.models';
import { SELECTED_SCHOOL_GROUP_KEY } from '../Constants/Constant';

@Injectable({
  providedIn: 'root'
})
export abstract class UserService {

  constructor(private localStorage?: LocalStorageService) {
  }

  storeUserInCache(data: User) {
    const { id, token, type } = data;
    this.localStorage.setItem('id', id);
    this.localStorage.setItem('token', token);
    this.localStorage.setItem('type', type);
    this.localStorage.setItem(SELECTED_SCHOOL_GROUP_KEY, '');
    return;
  }

  clearUserInCache() {
    this.localStorage.clear();
  }

  clearStudentId() {
    this.localStorage.setItem('studentID', '');
  }

  getUserInCache(type: string) {
    return this.localStorage.getItem(type);
  }

  setSessionId(key: string, value: string) {
    this.localStorage.setItem(key, value);
  }

  getSessionId(key: string) {
    return this.localStorage.getItem(key);
  }

  storeUserType(type) {
    this.localStorage.setItem('type', type)
    return;
  }
}
