<div class="wrapper">
        <div class="add">
            <div>{{data.type | titlecase}} {{labelData[title.toLowerCase()] | titlecase }}</div>
            <div (click)="closeDIalog()"> <img src="../../../../assets/close.svg"/></div>
        </div>

        <hr>

        <div class="text">Enter detail </div>
        <ng-container *ngIf="title !== 'session'">
            <div class="form">
                <form [formGroup]="profileForm" (ngSubmit)="crud()">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>{{labelData[title.toLowerCase()] | titlecase}}</mat-label>
                        <input matInput placeholder="Enter detail" formControlName="detail">
                        <mat-error *ngIf="!profileForm.controls['detail'].valid || profileForm.controls['detail'].touched">This field is required</mat-error>
                    </mat-form-field>

                    <div class="btn" >
                        <button mat-flat-button type="submit" color="primary">{{btnLabel | uppercase}}</button>
                    </div>   
                </form>
            </div>
        </ng-container>

        <ng-container *ngIf="title === 'session'">
            <div class="form">
                <form [formGroup]="profileForm" (ngSubmit)="crud()">
                    <div class="from">
                        <mat-form-field appearance="outline" [floatLabel]="'always'" class="field">
                            <mat-label>From</mat-label>
                            <input matInput placeholder="From year" formControlName="from">
                            <mat-error *ngIf="!profileForm.controls['from'].valid || profileForm.controls['from'].touched">This field is required</mat-error>
                        </mat-form-field>
                    
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>To</mat-label>
                            <input matInput placeholder="To year" formControlName="to">
                            <mat-error *ngIf="!profileForm.controls['to'].valid || profileForm.controls['to'].touched">This field is required</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="btn" >
                        <button mat-flat-button type="submit" color="primary">{{btnLabel | uppercase}}</button>
                    </div>   
                </form>
            </div>
        </ng-container>
</div>