import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SchoolsComponent } from './schools/schools.component';
import { SchoolDetailComponent } from './school-detail/school-detail.component';
import { SettingsModule } from './settings/settings.module';
import { AuthGuard } from '../services/auth.guard';
// import { ProfileComponent } from '../shared/profile/profile.component';
import { AnalyticsDashboardComponent } from './analytics-dashboard/analytics-dashboard.component';
import { ChooseAccountComponent } from '../students/choose-account/choose-account.component';
import { AnalyticsFilterComponent } from './analytics-filter/analytics-filter.component';
import { CourseDetailComponent } from '../shared/course-detail/course-detail.component';
import { PostDetailsComponent } from '../teachers/post-details/post-details.component';
import { ReviewAssignmentComponent } from '../teachers/review-assignment/review-assignment.component';
import { TestSolutionComponent } from '../teachers/test-solution/test-solution.component';
import { COURSE_ACTIVITY_ROUTE } from '../Constants/Constant';
import { LoginModule } from '../login/login.module';
import { ProfileModule } from '../profile/profile.module';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', loadChildren: () => LoginModule },
  { path: 'choose', component: ChooseAccountComponent, canActivate: [AuthGuard] },
  {
    path: ':type/course-activity/evaluate/:assignmentId/:studentId/:canEdit',
    component: ReviewAssignmentComponent, canActivate: [AuthGuard]
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
    children: [
      { path: 'schools', component: SchoolsComponent },
      { path: 'analytics', component: AnalyticsDashboardComponent },
      { path: 'analytics/filters', component: AnalyticsFilterComponent },
      { path: 'settings', loadChildren: () => SettingsModule },
      { path: 'school-details/:id', component: SchoolDetailComponent },
      { path: 'course/:id', component: CourseDetailComponent },
      { path: `${COURSE_ACTIVITY_ROUTE}/tests/:id`, component: TestSolutionComponent },
      { path: `${COURSE_ACTIVITY_ROUTE}/:name/:id`, component: PostDetailsComponent },
      { path: 'profile', loadChildren: () => ProfileModule },
      { path: '', redirectTo: 'schools', pathMatch: 'full' }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
