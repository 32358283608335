<ng-container *ngIf="isLoading; else testDetail">
    <app-spinner></app-spinner>
</ng-container>
<ng-template #testDetail>
    <ng-container *ngIf="!isSubmitted; else complete">
        <div class="class-test-wrapper">
            <ng-container *ngIf="test">
                <div class="header">
                    <div class="left">{{test.title | titlecase}}</div>
                    <div class="right">
                        <div class="maximum">
                            <div class="txt">MAXIMUM MARKS</div>
                            <div class="val">{{test.totalMarks}} Marks</div>
                        </div>
                        <ng-container *ngIf="testStarted"> 
                            <div class="sub">
                                    <div class="btn">
                                        <button mat-flat-button color="primary" (click)="submitTest()" [disabled]="isSubmitted">SUBMIT</button>
                                    </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        
            <ng-container *ngIf="!testStarted; else startTest">
                <div class="instruction">
                    <div class="txt">INSTRUCTIONS</div>
                    <div class="val">{{instruction}}</div>
                </div>
        
                <div class="buttons">
                    <div class="back">
                        <button mat-flat-button color="primary" (click)="back()">GO BACK</button>
                    </div>
                    <div class="start">
                        <button mat-flat-button color="primary" (click)="start()">START</button>
                    </div>
                </div>
            </ng-container>
        
            <ng-template #startTest>
                <div class="content">
                    <div class="list">
                        <app-question-list-view [questions]="questionList" (questionSelected)="getQuestion($event)" ></app-question-list-view>
                    </div>
                    <div class="question" *ngIf="questionDetail && questionDetail.length > 0">
                        <app-test-question  [selectedQuestion]="questionDetail"></app-test-question>
                    </div>
                </div>
            </ng-template>
        </div>
    </ng-container>
    
    <ng-template #complete>
        <div class="complete">
            <div class="great">Great!</div>
            <div class="txt">You finished your test</div>
            <div class="btn">
                <button mat-flat-button color="primary" (click)="getResult()">SEE MY RESULTS</button>
            </div>
        </div>
    </ng-template>
</ng-template>
