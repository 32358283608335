import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LibraryModel } from 'src/app/models/library.models';
import { FileDirectoryService } from 'src/app/services/file-directory.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LibraryService } from 'src/app/teachers/services/library.service';
import { LibraryTableComponent } from '../library-table/library-table.component';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';
import { CloudContentService } from 'src/app/services/cloud-content.service';

@Component({
  selector: 'app-folder-directory',
  templateUrl: './folder-directory.component.html',
  styleUrls: ['./folder-directory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderDirectoryComponent implements OnInit {
  directories = [];
  nestedFiles = [];
  step1: boolean = true;
  isLoading: boolean = true;
  isFileMoved: boolean = false;
  header = 'My Library';
  selectedFile;
  userType = USER_TYPES.teacher;
  userTypes = USER_TYPES;
  themeName = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<LibraryTableComponent>,
    public libraryservice: LibraryService,
    private cloudContentSvc: CloudContentService,
    public cdr: ChangeDetectorRef,
    public directoryService: FileDirectoryService,
    public snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    if (this.data.userType) {
      this.userType = this.data.userType;
      this.header = 'Cloud';
    }
    this.themeName = this.data.themeName;
    if (this.userType === this.userTypes.superAdmin) {
      this.cloudContentSvc.getCloudDirectoryContent().subscribe(res => {
        if (res) {
          this.directories = res.filter(content => content.isFolder);
          this.isLoading = false;
          this.cdr.detectChanges();
        }
      }, err => {
        if (err.error.error) {
          this.snackbar.openSnackbar(err.error.error);
        } else {
          this.snackbar.openSnackbar('unable to fetch contents');
        }
      });
      return;
    }
    this.libraryservice.getLibraryFiles().subscribe(
      (res) => {
        this.directories = res;
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      (err) => {
        if (err.error.error) {
          this.snackbar.openSnackbar(err.error.error);
        } else {
          this.snackbar.openSnackbar('unable to fetch contents');
        }
      });
  }

  getBackIcon() {
    if (this.userType === this.userTypes.superAdmin) {
      return 'assets/brown-back.svg';
    }
    return 'assets/blue-back.svg';
  }

  getLibrarryIds(files) {
    return files.map(file => file.id);
  }

  moveFiles() {
    const libraryIDs = this.data.file.length > 0 ? this.getLibrarryIds(this.data.file) : [];
    this.isLoading = true;
    if (this.userType === this.userTypes.superAdmin) {
      const body = {
        parent: this.selectedFile.id,
        contentIDs: libraryIDs,
      }
      this.cloudContentSvc.moveFiles(body).subscribe(
        (res) => {
          this.snackbar.openSnackbar('files are successfully moved.');
          this.nestedFiles = res;
          this.isLoading = false;
          this.isFileMoved = true;
          this.cdr.detectChanges();
        },
        err => {
          if (err.error.error) {
            this.snackbar.openSnackbar(err.error.error);
          } else {
            this.snackbar.openSnackbar('unable to move contents');
          }
        }
      );
      return;
    }
    const requestBody = {
      parent: this.selectedFile.id,
      libraryIDs,
    };

    this.libraryservice.moveFile(requestBody).subscribe(
      (res) => {
        this.snackbar.openSnackbar('files are successfully moved.');
        this.nestedFiles = res;
        this.isLoading = false;
        this.isFileMoved = true;
        this.cdr.detectChanges();
      },
      err => {
        if (err.error.error) {
          this.snackbar.openSnackbar(err.error.error);
        } else {
          this.snackbar.openSnackbar('unable to move contents');
        }
      }
    );
  }

  navigateToFolder(file) {
    if (file.isFolder) {
      this.isLoading = true;
      if (this.userType === this.userTypes.superAdmin) {
        this.cloudContentSvc.getCloudDirectoryContent(file.id).subscribe(res => {
          this.nestedFiles = res;
          this.selectedFile = file;
          this.step1 = false;
          this.isLoading = false;
          this.cdr.detectChanges();
        }, err => {
          this.isLoading = false;
          if (err.error.error) {
            this.snackbar.openSnackbar(err.error.error);
          } else {
            this.snackbar.openSnackbar('unable to fetch contents');
          }
        });
        return;
      }
      this.libraryservice.getContents(file.id, true).subscribe(
        (res) => {
          this.nestedFiles = res.libraryContents;
          this.selectedFile = file;
          this.step1 = false;
          this.isLoading = false;
          this.cdr.detectChanges();
        }, (err) => {
          this.isLoading = false;
          if (err.error.error) {
            this.snackbar.openSnackbar(err.error.error);
          } else {
            this.snackbar.openSnackbar('unable to fetch contents');
          }
        });
    } else {
      return;
    }
  }

  close() {
    this.dialogRef.close();
  }

  back() {
    this.step1 = true;
  }

}
