<div class="assignment-canvas-wrapper">
    <div *ngIf="files">
        <ngb-carousel [interval]="1000000" #carousel *ngIf="files.length" (slide)="updateSlide($event)" [showNavigationIndicators]="false" [showNavigationArrows]="false">
            <ng-container *ngFor="let file of files; let i = index">
                <ng-template ngbSlide [id]="'slideId_' + i">
                    <app-file-canvas [isCourseActivity]="isCourseActivity" [canEdit]="canEditFile" [file]="file"></app-file-canvas>
                </ng-template>
            </ng-container>
        </ngb-carousel>
        <div class="arrow left" (click)="moveToPrev()">
            <span class="material-icons">
          keyboard_arrow_left
        </span>
        </div>
        <div class="arrow right" (click)="moveToNext()">
            <span class="material-icons">
          keyboard_arrow_right
        </span>
        </div>
    </div>
</div>
