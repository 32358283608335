<div class="wrapper">
    <div class="text">
        <div>{{ classDetail ? 'Edit Class' : 'Create Class'}}</div>
        <div (click)="closeDialog()" class="close"><img src="../../../assets/close.svg"/></div>
    </div>

    <div class="header">Enter details</div>

    <div class="form">
        <form  [formGroup]="classForm" (ngSubmit)="createClass()">
            <div class="school" >
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>School</mat-label>
                    <input matInput disabled readonly placeholder="Enter Class Name" formControlName="school" autocomplete="off">
                </mat-form-field>
            </div>

            <div class="name">
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label> Class Name</mat-label>
                    <input matInput placeholder="Enter Class Name" formControlName="className" autocomplete="off">
                    <mat-error *ngIf="classForm.controls['className'].touched && classForm.controls['className'].hasError('required')">Class Name is required</mat-error>
                    <mat-error *ngIf="classForm.controls['className'].hasError('maxlength')">Class length should not exceed more than 100</mat-error>
                </mat-form-field>
            </div>

            <div class="group">
                <div class="standard" >
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Standard</mat-label>
                        <input matInput disabled placeholder="Enter Class Name" formControlName="standard" autocomplete="off">
                    </mat-form-field>
                </div>

                <div class="section" >
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Section</mat-label>
                        <input matInput disabled placeholder="Enter Class Name" formControlName="section" autocomplete="off">
                    </mat-form-field>
                </div>

            </div>

            <div class="subject" >
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Subject</mat-label>
                    <input matInput disabled placeholder="Enter Class Name" formControlName="subject" autocomplete="off">
                </mat-form-field>
            </div>

            <div class="session" >
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Session</mat-label>
                    <input matInput disabled placeholder="Enter Class Name" formControlName="session" autocomplete="off">
                </mat-form-field>
            </div>

            <div class="btn">
                <button mat-flat-button color="primary" type="submit" [disabled]="isSubmitted">{{ classDetail ? 'EDIT CLASS' : 'CREATE CLASS'}}</button>
            </div>

        </form>
    </div>
</div>
