import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public _snackbar: SnackbarService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routeName = state.url.split('/');
    const isLogin = localStorage.getItem('token') ? true : false;
    if (isLogin) {
      return true;
    }
    this._snackbar.openSnackbar('you have to login first in order to visit the page.');
    if (routeName.includes('teacher')) {
      this.router.navigate(['teacher', 'login']);
    } else if (routeName.includes('school')) {
      this.router.navigate(['school', 'login']);
    } else if (routeName.includes('super-admin')) {
      this.router.navigate(['super-admin', 'login']);
    }
    else {
      this.router.navigate(['student', 'login']);
    }
    return false;
  }

}
