<ng-container *ngIf="isLoading; else libraryTable">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #libraryTable>
    <div class="library-wrapper" [ngClass]="{'nestedClass' : !step1}">
        <ng-container>
            <div class="top-header">
                <ng-container *ngIf="step1; else step2">
                    <div class="text">My Library</div>
                </ng-container>

                <ng-template #step2>
                    <div class="header">
                        <div (click)="back()" class="back"> <img src="../../../assets/blue-back.svg"/></div>
                        <div class="text">{{selectedFile.name}}</div>
                    </div>
                </ng-template>

                <div class="action">
                    <button
                    mat-icon-button
                    (click)="select.open()">
                        <mat-label>ACTIONS</mat-label>
                        <mat-select
                        #select
                        [formControl]="action">
                            <mat-option *ngFor="let action of actionList"  [value]="action" [disabled]="selectedFile && action === actionList[1]">{{action}}</mat-option>
                        </mat-select>
                    </button>
                </div>
            </div>
        </ng-container>

        <ng-container>
            <app-library-table [userType]="userTypes.teacher" [transactions]="posts" [displayedColumns]="postsColumn" (getContent)="onFileContent($event)"></app-library-table>
        </ng-container>
    </div>

</ng-template>
