import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LiveClassService } from 'src/app/services/live-class.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { RELOAD_LIVE_CLASS_PREV_PAGE, DO_NOT_RELOAD, DO_RELOAD, RELOAD_LIVE_CLASS_PAGE } from 'src/app/Constants/Constant';

const THIS_WEEK = 'This Week'
const TODAY = 'Today'
const PAST = 'Past'
@Component({
  selector: 'app-live-class-dashboard',
  templateUrl: './live-class-dashboard.component.html',
  styleUrls: ['./live-class-dashboard.component.scss']
})
export class LiveClassDashboardComponent implements OnInit, OnChanges {
  @Input() list;
  @Input() columns;
  @Input() date = TODAY;
  @Input() weekDateRange;
  @Output() dateFilter = new EventEmitter();
  public weekRange;
  dateRange: string[] = [TODAY, THIS_WEEK, PAST];
  selectedDate : Date;
  constructor(
    public snackbar: SnackbarService,
    public router: Router,
    public liveClassService: LiveClassService,
    public route: ActivatedRoute,
    public localstorage: LocalStorageService
  ) { }

  ngOnInit(): void {
    let reloadPage = this.localstorage.getItem(RELOAD_LIVE_CLASS_PREV_PAGE)
    if (reloadPage == DO_RELOAD) {
      this.localstorage.setItem(RELOAD_LIVE_CLASS_PREV_PAGE, DO_NOT_RELOAD);
      window.location.reload();
    } else {
      this.localstorage.setItem(RELOAD_LIVE_CLASS_PAGE, DO_RELOAD);
      this.localstorage.setItem(RELOAD_LIVE_CLASS_PREV_PAGE, '');
    }
    this.addDateColumn();
    this.date === 'Select Range' ? '' : this.date;
    this.date = this.date === 'this-week' ? THIS_WEEK : this.date;
    this.selectedDate = new Date();
  }

  ngOnChanges() {
    this.addDateColumn();
  }

  addDateColumn() {
    if (this.date !== 'Today') {
      if (this.columns[0] !== 'date') {
        this.columns.splice(0, 0 , 'date');
      }
    } else if (this.columns[0] === 'date'){
      this.columns.splice(0, 1);
    }
  }

  onSelect(event) {
    if(event.value === THIS_WEEK) {
      this.dateFilter.emit('this-week')
    } else {
      this.dateFilter.emit(event.value)
    }
  }

  onClassJoin(classID) {
    this.liveClassService.joinClass(classID).subscribe(
      (res) => {
        window.open(res.link, '_blank');
      },
      (err) => {
        this.snackbar.openSnackbar(err.error.error);
      }
    )
  }

}
