import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { LIVE_STREAMING_ERRORS } from 'src/app/Constants/Constant';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class LivePublisherService {

  constructor(
    private crud: CrudService,
    private snackBar: SnackbarService
  ) { }


  //isleftSession
  private isLeftSession: boolean = false;

  // required configuration for ant media webrtc adaptor
  private peerConnectionConfig: any;
  private mediaConstraints: any;
  private sdpConstraints: any;
  private webRTCAdaptor: any;

  // live room related configuration
  private roomName: string;
  private localVideoElId: string;
  private localStreamId: string;
  private desktopStream: any;

  //connection closed variable to maintain state of connection
  private isConnectionClosed$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get isConnectionClosed$(): Observable<boolean> {
    return this.isConnectionClosed$$.asObservable()
  }

  //vars and functions to check for active connection
  private lastPongTime: any;

  private updateLastPongTime() {
    this.lastPongTime = new Date();
  }

  private connectionAliveCheck: any;
  private checkIfConnectionAlive() {
    let presentTime: any = new Date();
    //if last pong happens 8 or more than 8 seconds before then show loader.
    if (presentTime - this.lastPongTime >= 8000) {
      this.showLoader$$.next(true);
    } else {
      this.showLoader$$.next(false);
    }
  }

  //
  private joiningSession: boolean = false;

  public isJoiningSession(): boolean {
    return this.joiningSession;
  }

  // to show or not show loader
  private showLoader$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get showLoader$(): Observable<boolean> {
    return this.showLoader$$.asObservable()
  }

  //to check if publishing or not
  private isPublishing$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get isPublishing$(): Observable<boolean> {
    return this.isPublishing$$.asObservable()
  }

  //to keep track of remote streams.
  // private alreadyPlayingStreamsIds: any = [];

  // private remoteStreams$$: BehaviorSubject<LiveStream[]> = new BehaviorSubject([]);

  // public get remoteStreams$(): Observable<LiveStream[]> {
  //   return this.remoteStreams$$.asObservable()
  // }

  //screen share related vars
  private isSharingScreen$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get isSharingScreen$(): Observable<boolean> {
    return this.isSharingScreen$$.asObservable()
  }
  private isSharingScreenWithCamera$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get isSharingScreenWithCamera$(): Observable<boolean> {
    return this.isSharingScreenWithCamera$$.asObservable()
  }

  //variable to keep track when toggling video source
  private togglingVideoSource$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get togglingVideoSource$(): Observable<boolean> {
    return this.togglingVideoSource$$.asObservable()
  }


  public setStreamingConstraints(
    peerConnectionConfig: any,
    mediaConstraints: any,
    sdpConstraints: any,) {
    this.peerConnectionConfig = peerConnectionConfig;
    this.mediaConstraints = mediaConstraints;
    this.sdpConstraints = sdpConstraints;
    this.setGlobalMediaConstraints();
    this.showLoader$$.next(false);
    this.isLeftSession = false;
  }

  private setGlobalMediaConstraints() {
    mediaConstraints = this.mediaConstraints;
  }

  //stream state related variables
  private isCameraOn$$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public get isCameraOn$(): Observable<boolean> {
    return this.isCameraOn$$.asObservable()
  }

  private isMicOn$$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public get isMicOn$(): Observable<boolean> {
    return this.isMicOn$$.asObservable()
  }

  public streamStateBroadcaster: any;


  public localStream: any;

  public setInitialState = () => {
    if (this.streamStateBroadcaster) {
      clearInterval(this.streamStateBroadcaster)
      this.streamStateBroadcaster = null;
    }
    if (this.connectionAliveCheck) {
      clearInterval(this.connectionAliveCheck)
      this.connectionAliveCheck = null;
    }
    this.isMicOn$$.next(true);
    this.isCameraOn$$.next(true);
    // this.alreadyPlayingStreamsIds = [];
    // this.remoteStreams$$.next([]);
    this.isSharingScreen$$.next(false);
    this.isSharingScreenWithCamera$$.next(false);
    this.isPublishing$$.next(false);
    this.showLoader$$.next(false);
    this.isConnectionClosed$$.next(false);
    if (this.desktopStream) {
      this.desktopStream.getTracks().forEach(function (track) {
        track.stop();
      });
    };
    if (this.localStream) {
      this.localStream.getTracks().forEach(function (track) {
        track.stop();
      });
    };
    this.resetWebRTCAdaptor();
  }

  //stream related functions
  public turnOffMic() {
    this.webRTCAdaptor.muteLocalMic();
    this.isMicOn$$.next(false)
    this.sendStreamState();
  }

  public turnOnMic() {
    this.webRTCAdaptor.unmuteLocalMic();
    this.isMicOn$$.next(true)
    this.sendStreamState();
  }

  public turnOnCamera() {
    this.webRTCAdaptor.turnOnLocalCamera();
    this.isCameraOn$$.next(true)
    this.sendStreamState();
  }

  public turnOffCamera() {
    this.webRTCAdaptor.turnOffLocalCamera();
    this.isCameraOn$$.next(false)
    this.sendStreamState();
  }

  public stopScreenShare(streamId: string) {
    // this.webRTCAdaptor.switchVideoCameraCapture(streamId);
    this.screenShareStoppedCallback();
  }


  public joinSession(roomName: string, localVideoElId: string, localStreamId: string) {
    this.joiningSession = true;
    this.isConnectionClosed$$.next(false);
    this.roomName = roomName;
    this.localVideoElId = localVideoElId;
    this.localStreamId = localStreamId;
    this.webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: environment.antMediaPublishUrl,
      mediaConstraints: this.mediaConstraints,
      peerconnection_config: this.peerConnectionConfig,
      localVideoId: this.localVideoElId,
      sdp_constraints: this.sdpConstraints,
      bandwidth: 800,
      callback: this.successCallback,
      callbackError: this.errorCallback,
    });
  }

  public successCallback = (info, obj = null) => {
    if (this.isLeftSession) {
      this.setInitialState();
      return
    }
    if (info == "initialized") {
      this.initSuccessCallback(info);
    } else if (info == "joinedTheRoom") {
      this.roomJoinSuccessCallback(info, obj);
    } else if (info == "roomInformation") {
      this.receivedRoomInfoCallback(info, obj);
    } else if (info == "publish_started") {
      this.publishStartedCallback(info);
    }
    else if (info == "publish_finished") {
      this.publishFinishedCallback();
    } else if (info == "play_finished") {
      // this.playFinishedCallback(obj);
    } else if (info == "screen_share_stopped") {
      //"Stop Sharing" is clicked in chrome screen share dialog
      this.screenShareStoppedCallback();
    } else if (info == "newStreamAvailable") {
      // this.addNewlyAvailableStream(obj);
    } else if (info == "pong") {
      this.updateLastPongTime();
    } else if (info == "data_channel_opened") {
      this.dataChannelOpenedCallback(obj)
    } else if (info == "data_received") {
      // this.onMessageCallback(obj);
    } else if (info == "data_channel_closed") {
      this.dataChannelClosedCallback(obj);
    }
  }


  private errorCallback = (error, message) => {
    console.log("[errorCallback] error: ", error, 'message: ', message);
    //TODO: check for room not initialized error
    if (this.isLeftSession) {
      this.setInitialState();
      return
    }
    if (error == LIVE_STREAMING_ERRORS.TypeError && this.webRTCAdaptor) {
      this.setInitialState();
    } else if (error === LIVE_STREAMING_ERRORS.WebSocketNotConnectedError || error.target instanceof WebSocket) {
      if (this.connectionAliveCheck) {
        clearInterval(this.connectionAliveCheck);
      }
      if (this.webRTCAdaptor.roomTimerId) {
        clearInterval(this.webRTCAdaptor.roomTimerId);
      }

      if (this.isSharingScreen$$.value || this.isSharingScreenWithCamera$$.value) {
        var tracks = this.desktopStream.getTracks();
        for (var i = 0; i < tracks.length; i++) tracks[i].stop();
      }
      this.joiningSession = false;
      this.isConnectionClosed$$.next(true);
      this.isPublishing$$.next(false);
      if (!this.showLoader$$.value) {
        this.showLoader$$.next(true);
      }
    } else if (error == LIVE_STREAMING_ERRORS.NotAllowedError) {
      this.setInitialState();
      this.snackBar.openSnackbar("Please Give Permission for Camera and Mic to start session");
    } else if (error == LIVE_STREAMING_ERRORS.WebSocketNotSupportedError) {
      this.setInitialState();
      this.snackBar.openSnackbar("Please Use A Browser That Supports WebSockets");
    }
  }

  private initSuccessCallback(info: any) {
    this.localStream = this.webRTCAdaptor.localStream;
    //checking for pong
    console.log(this.webRTCAdaptor)
    this.isConnectionClosed$$.next(false);
    this.showLoader$$.next(false);
    this.connectionAliveCheck = setInterval(() => {
      this.checkIfConnectionAlive()
    }, 10000);
    console.log('[initSuccessCallback]: adaptor initialized successfully, info: ', info)
    this.webRTCAdaptor.joinRoom(this.roomName, this.localStreamId);
  }

  private roomJoinSuccessCallback(info: any, obj: any) {
    this.publishStream(this.localStreamId);
    // sdk itself call it in loop no need for us to do manually just handle in the 
    // room info callback
    // this.webRTCAdaptor.getRoomInfo(this.roomName, this.localStreamId)
  }

  //here according to the room info remote streams are managed.
  private receivedRoomInfoCallback(info: any, obj: any) {
    // for (let index = 0; index < obj.streams.length; index++) {
    //   if (!this.alreadyPlayingStreamsIds.includes(obj.streams[index])) {
    //     //get token to play the corresponding stream from server
    //     if (!this.webRTCAdaptor.isPlayMode) {
    //       this.webRTCAdaptor.isPlayMode = true;
    //     }
    //     // retrive token from server and then add token to the play call.
    //     this.crud.get(environment.apiUrl + '/class/live/' + this.roomName + '/stream/' + obj.streams[index] + '?token-type=play').subscribe(
    //       (resp: any) => {
    //         this.alreadyPlayingStreamsIds.push(obj.streams[index]);
    //         this.webRTCAdaptor.play(obj.streams[index], resp.token, this.roomName);
    //       }
    //     );
    //   }
    // }
    // let existingRemoteStreams = this.remoteStreams$$.value;
    // let toRemoveStreamIds = [];
    // for (let index = 0; index < existingRemoteStreams.length; index++) {
    //   if (!obj.streams.includes(existingRemoteStreams[index].source.streamId)) {
    //     toRemoveStreamIds.push(existingRemoteStreams[index].source.streamId);
    //   }
    // }
    // if (toRemoveStreamIds.length > 0) {
    //   let updatedAlreadyPlayingStreamIds = this.alreadyPlayingStreamsIds.filter(
    //     (streamId) => !toRemoveStreamIds.includes(streamId));
    //   this.alreadyPlayingStreamsIds = updatedAlreadyPlayingStreamIds;
    //   let updatedExistingRemoteStreams = existingRemoteStreams.filter(
    //     (remoteStream) => !toRemoveStreamIds.includes(remoteStream.source.streamId)
    //   );
    //   this.remoteStreams$$.next(updatedExistingRemoteStreams);
    // }
  }

  private publishStream(streamId: string) {
    console.log("publishing inside service")
    this.crud.get(environment.socketServerBaseUrl + '/class/live/' + this.roomName + '/stream/' + streamId + '?token-type=publish').subscribe(
      (resp: any) => {
        if (this.webRTCAdaptor && !this.isLeftSession) {
          this.webRTCAdaptor.publish(streamId, resp.token);
        }
      },(err) => {
        console.log("[teacher-publisher-service]: unable to get play token for stream: ",streamId, " error :",err)
      }
    );
  }

  private publishStartedCallback(info: any) {
    console.log('[publishStartedCallback]: publish started successfully, info: ', info);
    this.isPublishing$$.next(true);
    this.localStream = this.webRTCAdaptor.localStream;
    this.updateLiveClassRecordings();
  }

  private updateLiveClassRecordings() {
    this.crud.patch(environment.apiUrl + '/class/live/' + this.roomName + '/recordings').subscribe(
      (resp: any) => {
        console.log(resp.msg);
      },(err) => {
        console.log("error updating recordings",err);
        setTimeout(() => this.updateLiveClassRecordings(),2000)
      }
    );
  }

  private publishFinishedCallback() {
    console.log("inside callback publish finished");
    //TODO: show the dialog for rejoining when publish unexpectedly finished.
    this.isConnectionClosed$$.next(true);
  }

  public leaveSession() {
    if (this.isPublishing$$.value) {
      this.webRTCAdaptor.stop(this.localStreamId);
      this.isPublishing$$.next(false);
    }
    this.isLeftSession = true;
    this.showLoader$$.next(false)
    // this.alreadyPlayingStreamsIds = [];
    // this.remoteStreams$$.next([]);
    if (this.webRTCAdaptor) {
      if (this.webRTCAdaptor.webSocketAdaptor) {
        this.webRTCAdaptor.closeWebSocket();
      }
    }
  }

  // private playFinishedCallback(obj: any) {
  //   let updatedStartedStreamIds = this.alreadyPlayingStreamsIds;
  //   let leavedStreamIndex = updatedStartedStreamIds.findIndex((streamId) => streamId === obj.streamId);
  //   if (leavedStreamIndex != -1) {
  //     updatedStartedStreamIds.splice(leavedStreamIndex, 1);
  //   }
  //   //check for teacher stream
  //   let updatedRemoteStreams = this.remoteStreams$$.value;
  //   leavedStreamIndex = updatedRemoteStreams.findIndex((stream) => stream.source.streamId === obj.streamId);
  //   if (leavedStreamIndex != -1) {
  //     updatedRemoteStreams.splice(leavedStreamIndex, 1)
  //     this.remoteStreams$$.next(updatedRemoteStreams);
  //   }
  // }

  //callbacks related to screen sharing
  private screenShareStartedCallback() {
    this.isSharingScreen$$.next(true);
    this.isSharingScreenWithCamera$$.next(false);
    this.sendStreamState();
  }

  private screenShareStartedWithCameraCallback() {
    this.isSharingScreenWithCamera$$.next(true);
    this.isSharingScreen$$.next(false);
    this.sendStreamState();
  }

  private screenShareStoppedCallback() {
    this.togglingVideoSource$$.next(true);
    this.isSharingScreen$$.next(false);
    this.isSharingScreenWithCamera$$.next(false);
    //sending the toggle state
    this.sendStreamState();
    //turning the local video off (which will be the screen share video)
    this.webRTCAdaptor.turnOffLocalCamera();
    
    //after 2 seconds turning to local camera capture
    setTimeout(() => {
      this.webRTCAdaptor.switchVideoCameraCapture(this.localStreamId);
      //after 3 seconds sending the stream state
      setTimeout(() => {
        this.togglingVideoSource$$.next(false);
        this.sendStreamState();
        if (!this.isCameraOn$$.value) {
          setTimeout(() => {
            this.webRTCAdaptor.turnOffLocalCamera();
          }, 3000)
        }
      },3000)
    }, 2000)
  }

  //methods to be invoke by component to start or stop screen share
  private async getDesktopStream() {
    const mediaDevices = navigator.mediaDevices as any;
    try {
      const stream = await mediaDevices.getDisplayMedia({ video: true })
      stream.getVideoTracks()[0].applyConstraints({
        frameRate: 5,
      })
      return stream
    } catch (err) {
      throw err
    }
  }

  public startScreenShare() {
    this.getDesktopStream().then((stream) => {
      this.desktopStream = stream;
      stream.getVideoTracks()[0].onended = () => {
        this.screenShareStoppedCallback();
      }
      this.togglingVideoSource$$.next(true);
      this.screenShareStartedCallback();
      //set toggle to true
      //turning off camera before screen share
      this.webRTCAdaptor.turnOffLocalCamera();
      //sending stream state with toggle on
      this.sendStreamState(true);

      //adding the screen share stream after 5 seconds

      setTimeout(() => {
        this.webRTCAdaptor.publishMode = "screen";
        this.webRTCAdaptor.prepareStreamTracks(this.mediaConstraints,
          this.mediaConstraints.audio, this.desktopStream, this.localStreamId);
        //after 2 seconds sending the state of screen sharing
        setTimeout(() => {
          //set toggle to false
          this.togglingVideoSource$$.next(false);
          this.sendStreamState();
        }, 2000);
      }, 5000);
    }).catch((err) => {
      if (err.name === "NotAllowedError") {
        console.debug("Permission denied error");
        this.errorCallback("ScreenSharePermissionDenied",
          "user denied permission for screen sharing");
      } else {
        console.log("getting desktop stream error:", err)
        this.errorCallback(err.name, err.message);
      }
    })
  }

  public startScreenShareWithCamera() {
    this.getDesktopStream().then((stream) => {
      this.desktopStream = stream;
      stream.getVideoTracks()[0].onended = () => {
        this.screenShareStoppedCallback();
      }
      this.screenShareStartedWithCameraCallback();
      //set toggle to true
      this.togglingVideoSource$$.next(true);

      //turning off camera before screen share
      this.webRTCAdaptor.turnOffLocalCamera();
      //sending stream state with toggle on
      this.sendStreamState();
      //adding the screen share stream after 5 seconds
      setTimeout(() => {
        this.webRTCAdaptor.publishMode = "screen+camera";
        this.webRTCAdaptor.prepareStreamTracks(this.mediaConstraints,
          this.mediaConstraints.audio, this.desktopStream, this.localStreamId);
        //after 2 seconds sending the state of screen sharing
        setTimeout(() => {
          //set toggle to false
          this.togglingVideoSource$$.next(false);
          this.sendStreamState();
        }, 2000);
      }, 5000);
    }).catch((err) => {
      if (err.name === "NotAllowedError") {
        console.debug("Permission denied error");
        this.errorCallback("ScreenSharePermissionDenied",
          "user denied permission for screen sharing");
      } else {
        this.errorCallback(err.name, err.message);
      }
    })
  }

  // to add whenever a new remote stream is available
  // private addNewlyAvailableStream(obj: any) {
  //   let alreadyAvailableStreams = this.remoteStreams$$.value;
  //   console.log('[addNewlyAvailableStream]: new stream available, obj: ', obj);
  //   let alreadyPresentStreamIndex = alreadyAvailableStreams.findIndex(
  //     (remoteStream) => remoteStream.source.streamId === obj.streamId
  //   )
  //   if (alreadyPresentStreamIndex === -1) {
  //     let remoteStream: LiveStream = {
  //       isCameraOn: true,
  //       isMicOn: true,
  //       source: obj,
  //       isSharingScreen: false
  //     }
  //     alreadyAvailableStreams.push(remoteStream);
  //     this.remoteStreams$$.next(alreadyAvailableStreams);
  //   }
  // }

  public reJoinSession() {
    this.joiningSession = true;
    setTimeout(() => {
      this.joinSession(this.roomName, this.localVideoElId, this.localStreamId)
    }, 5000);
  }

  //data channel related functionality (to show thumbnail when camera not turned on of user)
  private dataChannelOpenedCallback = (streamId) => {
    console.log("[comp]: data channel opened for stream", streamId);
    if (streamId === this.localStreamId) {
      this.streamStateBroadcaster = setInterval(() => {
        this.sendStreamState();
      }, 10000)
    }
  }

  private dataChannelClosedCallback = (streamId) => {
    console.log("[comp]: dataChannelClosedCallback", streamId);
    if (streamId === this.localStreamId) {
      clearInterval(this.streamStateBroadcaster);
    }
  }

  // private onMessageCallback = (obj) => {
  //   let message = JSON.parse(obj.event.data)
  //   console.log("message received", message)
  //   if (message.event === "STREAM_INFO") {
  //     let index = this.remoteStreams$$.value.findIndex((stream) => stream.source.streamId === message.data.streamId)
  //     if (index != -1) {
  //       let remoteStreams = this.remoteStreams$$.value;
  //       remoteStreams[index].isCameraOn = message.data.isCameraOn;
  //       remoteStreams[index].isMicOn = message.data.isMicOn;
  //       remoteStreams[index].isSharingScreen = message.data.isSharingScreen;
  //       this.remoteStreams$$.next(remoteStreams);
  //     }
  //   }
  // }


  private sendStreamState = (toggle = false) => {
    if (this.isPublishing$$.value) {
      this.webRTCAdaptor.sendData(this.localStreamId, JSON.stringify({
        event: "STREAM_INFO",
        data: {
          streamId: this.localStreamId,
          isCameraOn: this.isCameraOn$$.value,
          isMicOn: this.isMicOn$$.value,
          isSharingScreen: this.isSharingScreen$$.value || this.isSharingScreenWithCamera$$.value,
          toggle: this.togglingVideoSource$$.value,
        }
      }));
    }
  }

  private resetWebRTCAdaptor() {
    if (this.webRTCAdaptor) {
      if (this.webRTCAdaptor.roomTimerId) {
        clearInterval(this.webRTCAdaptor.roomTimerId);
      }
      if (this.webRTCAdaptor.webSocketAdaptor) {
        Object.keys(this.webRTCAdaptor.webSocketAdaptor).forEach(k => this.webRTCAdaptor.webSocketAdaptor[k] = null);
      }
      if (this.webRTCAdaptor.localStream) {
        this.webRTCAdaptor.localStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      if (this.webRTCAdaptor.desktopStream) {
        this.webRTCAdaptor.desktopStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      if (this.webRTCAdaptor.desktopStream) {
        this.webRTCAdaptor.desktopStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      Object.keys(this.webRTCAdaptor).forEach(k => {
        if (k !== "callback" && k !== "callbackError" && k !== "localStream" && k !== "desktopStream") {
          this.webRTCAdaptor[k] = null
        }
      });
    }
  }

}
