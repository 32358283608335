import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailregex, nameregex } from '../../Constants/Constant';
import { UserApiService } from 'src/app/services/api/user.api.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormValidationService } from 'src/app/services/form-validation.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';
import { Router } from '@angular/router';


@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsComponent implements OnInit {

  @Input() mobileNo: string;
  @Input() userType: string;
  @Output() done = new EventEmitter<any>();
  profileForm: FormGroup;
  Imageurl: string | ArrayBuffer;
  isSubmitted = false;
  matcher: ErrorStateMatcher;
  isImageUploaded: boolean;
  userTypes = USER_TYPES;
  genders = ['Male', 'Female'];

  constructor(
    private fb: FormBuilder,
    public localStorageService: UserService,
    private userService: UserApiService,
    private router: Router,
    public _snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.matcher = new FormValidationService();
    if (this.userType === this.userTypes.student) {
      this.profileForm = this.fb.group({
        name: [null, [Validators.required, Validators.pattern(nameregex)]],
        gender: [null, [Validators.required]],
        username: [null, [Validators.required]],
        pin: [null, [Validators.required]],
      });
      return;
    }
    this.profileForm = this.fb.group({
      'name': [null, [Validators.required, Validators.pattern(nameregex)]],
      'userName': [null, [Validators.required]],
      'email': [null, [Validators.required, Validators.pattern(emailregex)]],
    });
  }

  getErrorName() {
    return this.profileForm.get('name').hasError('required') ? 'Name is required' :
      this.profileForm.get('name').hasError('pattern') ? 'Not a valid name' : null
  }

  getErrorEmail() {
    return this.profileForm.get('email').hasError('required') ? 'Field is required' :
      this.profileForm.get('email').hasError('pattern') ? 'Not a valid email address' : null
  }

  getErrorPin() {
    return this.profileForm.get('pin').hasError('required') ? 'Field is required' :
      this.profileForm.get('pin').hasError('pattern') ? 'Pin must be a 4 digit number' : null;
  }


  onImageUpload(url) {
    this.Imageurl = url ? url : null;
    this.isImageUploaded = url ? true : false;
  }

  register() {
    if (this.userType === this.userTypes.student) {
      this.registerStudent();
    }
    this.isSubmitted = true;
    if (this.profileForm.valid && this.Imageurl) {
      const requestBody = {
        name: this.profileForm.controls['name'].value.trim(),
        email: this.profileForm.controls['email'].value.trim(),
        username: this.profileForm.controls['userName'].value.trim(),
        profilePicture: this.Imageurl,
        mobileNumber: this.mobileNo
      };
      this.userService.login(this.userType, requestBody).subscribe(
        (res) => {
          this.isSubmitted = false;
          this._snackbar.openSnackbar('successfully signed up');
          this.localStorageService.storeUserInCache(res);
          this.done.emit(res);
        }, (err) => {
          this.isSubmitted = false;
          this._snackbar.openSnackbar(err.error.error);
        }
      );
      return;
    }
    if (!this.Imageurl) {
      this.isImageUploaded = false;
    }
    this.isSubmitted = false;
    this._snackbar.openSnackbar('please fill all details');
  }

  private registerStudent() {
    this.isSubmitted = true;
    if (this.profileForm.valid && this.Imageurl) {
      const requestBody = {
        name: this.profileForm.controls['name'].value.trim(),
        username: this.profileForm.controls['username'].value.trim(),
        gender: this.profileForm.controls['gender'].value.toString(),
        profilePicture: this.Imageurl,
        mobileNumber: this.mobileNo,
        pin: this.profileForm.controls['pin'].value.toString()
      }
      this.userService.studentSignUp(requestBody).subscribe(
        (res) => {
          res.type = 'student';
          this.localStorageService.storeUserInCache(res);
          this._snackbar.openSnackbar('successfully created your profile!!');
          this.router.navigate(['student', 'dashboard']);
        }, (err) => {
          this.isSubmitted = false;
          this._snackbar.openSnackbar(err.error.error);
        }
      );
      return;
    }
    if (!this.Imageurl) {
      this.isImageUploaded = false;
    }
    this.isSubmitted = false;
    this._snackbar.openSnackbar('please fill all details');
  }
}
