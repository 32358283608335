import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  public route(userType): string {
    return environment.apiUrl + `${userType}` + '/signup';
  }

  private studentSignUpRoute(): string {
    return environment.apiUrl + `v2/student` + '/signup';
  }

  constructor(private crud?: CrudService) {
  }

  login(userType: string, body): Observable<any> {
      return this.crud.post(this.route(userType), body);
  }

  studentSignUp(body): Observable<any> {
    return this.crud.post(this.studentSignUpRoute(), body);
  }
}

// export class TeacherApiService extends UserApiService {
//   constructor() {
//     super('teacher');
//   }
// }

// export class StudentApiService extends UserApiService {
//   constructor() {
//     super('student');
//   }
// }

// export class AdminApiService extends UserApiService {
//   constructor() {
//     super('admin_student');
//   }
// }
