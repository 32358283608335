<ng-container *ngIf="isLoading; else library">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #library>
    <div class="library-upload-wrapper">
        <ng-container *ngIf="step1; else step2">
            <div class="header">
                <div class="back"> <img [src]="backButtonSrc" /></div>
                <div class="text">{{textHeader}}</div>
            </div>
        </ng-container>

        <ng-template #step2>
            <div class="header">
                <div (click)="back()" class="back"> <img [src]="backButtonSrc" /></div>
                <div class="text">{{textHeader}}</div>
            </div>
        </ng-template>

        <div *ngIf="posts && posts.length > 0" class="library-table">
            <app-library-table [userType]="userType" [isCloudContent]="isCloudContent" [transactions]="posts" [disableFolder]="'true'" [disabledFile]="isFileDisabled" [displayedColumns]="postsColumn" [toSelect]="'true'" (selectedCount)="onSelectCheckbox($event)" (getContent)="onFileContent($event)"
                [selectedFiles]="selectedFiles"></app-library-table>
        </div>
        <div *ngIf="!posts || posts.length === 0" class="library-table empty-placeholder">
            <p>Library is Empty</p>
        </div>

        <div class="btn">
            <div class="cancel" (click)="cancel()">
                <button [ngClass]="isAdminTheme ? 'admin-outline-button': ''" mat-flat-button>CANCEL</button>
            </div>
            <div class="upload" (click)="uploadFile()">
                <button [ngClass]="isAdminTheme && selectedFiles.length > 0 ? 'admin-fill-button': ''" mat-flat-button color="primary" [disabled]="selectedFiles.length === 0">UPLOAD</button>
            </div>
        </div>
    </div>
</ng-template>
