import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-shiksha-input',
  templateUrl: './shiksha-input.component.html',
  styleUrls: ['./shiksha-input.component.scss']
})
export class ShikshaInputComponent implements OnInit {

  @Input() labelText = 'Enter';

  constructor() { }

  ngOnInit(): void { }

}
