import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, EventEmitter, ViewChild, Output, Input, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { pageSizeOptions, paginatorPageSize } from 'src/app/Constants/Constant';
import { Posts } from 'src/app/models/post.models';
import { TestEvaluateComponent } from 'src/app/shared/test-evaluate/test-evaluate.component';

const TEST_EVALUATION = 'Test Evaluation';
const VIEW_SUBMISSION = 'View Submission';
const EDIT_CONTENT = 'Edit Content';
const DELETE_CONTENT = 'Delete Content';
const ARCHIVE = 'Archive';
const LIPSUM = 'Lipsum';

@Component({
  selector: 'app-test-solution-table',
  templateUrl: './test-solution-table.component.html',
  styleUrls: ['./test-solution-table.component.scss']
})

export class TestSolutionTableComponent implements OnInit {

  pageSizeOptions: number[] = pageSizeOptions;
  pageSize = paginatorPageSize;
  @Input() displayedColumns: string[];
  @Input() question;
  @Input() transactions: Posts[];
  @Input() sortDirection: 'asc' | 'desc';
  @Input() isCourseActivity = false;
  @Input() totalMarks = 0;
  @Input() disableActions = false;
  @Output() isReviewAdded = new EventEmitter();
  @Output() getSortDirection = new EventEmitter();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;
  selection = new SelectionModel(true, []);

  dataSource: any;
  sortedData: any;
  // FOR DEMO: it will be reverted later
  // menuListItems = [TEST_EVALUATION, EDIT_CONTENT, DELETE_CONTENT, ARCHIVE, LIPSUM]
  menuListItems = [TEST_EVALUATION];
  constructor(
    public router: Router,
    public dialog: MatDialog,
    public cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if (this.isCourseActivity) {
      this.menuListItems = [VIEW_SUBMISSION];
    }
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.transactions);
    this.cd.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.sort.sort(({ id: 'date', start: this.sortDirection }) as MatSortable);
    this.dataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  sortData(sort: Sort) {
    const data = this.transactions.slice();
    if (sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.getSortDirection.emit(sort.direction);
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a.date, b.date, isAsc);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sendMsg() {
    console.log(this.dataSource)
  }

  sendActivity(row: Posts) {
    this.router.navigate(['teacher', 'dashboard', 'post-details', row.activityType, row.activityID]);
  }

  selectMenuItem(action, row: Posts) {
    if (this.disableActions) {
      return;
    }
    if (action === TEST_EVALUATION) {
      if (row.result === '') {
        return;
      }
      const dialog = this.dialog.open(TestEvaluateComponent, {
        width: '100%',
        height: '100%',
        data: { testDetails: row, questionList: this.question },
        backdropClass: 'blur',
        disableClose: true,
        panelClass: ['teacher-theme', 'full-width', 'test-evaluation-dialog'],
        autoFocus: false
      });

      dialog.afterClosed().subscribe((res) => {
        if (res) {
          this.isReviewAdded.emit(true);
        }
      });
    } else if (action === VIEW_SUBMISSION) {
      if (row.result === '') {
        return;
      }
      const dialog = this.dialog.open(TestEvaluateComponent, {
        width: '100%',
        height: '100%',
        data: { testDetails: row, questionList: this.question },
        backdropClass: 'blur',
        disableClose: true,
        panelClass: ['admin-theme', 'full-width','test-evaluation-dialog'],
        autoFocus: false
      });

      dialog.afterClosed().subscribe((res) => {
        if (res) {
          this.isReviewAdded.emit(true);
        }
      });
    }
  }

}
