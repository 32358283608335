<div class="container" (click)="closeDialog('')">
    <ng-container *ngIf="fileType === 'folder'; else test">
        <div class="file-dialog-wrapper" (click)="keepDialog($event)">
            <div class="text">From where you want to upload {{postType | titlecase}}?</div>
            <div class="btn">
                <div class="drive"><button mat-flat-button color="primary" (click)="closeDialog('drive')">LOCAL
                        DRIVE</button></div>
                <div class="library"><button mat-flat-button color="primary" (click)="closeDialog('library')">{{libraryOptionText}}</button></div>
            </div>
        </div>
    </ng-container>

    <ng-template #test>
        <div class="file-dialog-wrapper" (click)="keepDialog($event)">
            <div class="text">How do you want to create the test?</div>

            <div class="btn">
                <div class="drive"><button mat-flat-button color="primary" (click)="closeDialog('drive')">COPY FROM
                        LIBRARY</button></div>
                <div class="library"><button mat-flat-button color="primary" (click)="closeDialog('library')">CREATE
                        FROM scratch</button></div>
            </div>
        </div>
    </ng-template>
</div>