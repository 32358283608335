import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { OtpLoginComponent } from './otp-login/otp-login.component';
import { EmailLoginComponent } from './email-login/email-login.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { MaterialModule } from '../material/material.module';
import { ProfileModule } from '../profile/profile.module';


@NgModule({
  declarations: [LoginComponent, OtpLoginComponent, EmailLoginComponent, UserInfoComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ProfileModule,
    MaterialModule
  ]
})
export class LoginModule { }
