<!-- FOR DEMO: it will be reverted later -->
<!-- <ng-container>
    <div class="flex-1 line">
      <div class="checkbox">
        <mat-checkbox (change)="$event ? masterToggle() : null"
          color="'primary'"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [color]="'primary'"
          [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </div>
    </div>
</ng-container> -->

<ng-container>
    <ng-container *ngIf="type === 'assignment'; else material">
        <table class="detail-table" mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="hideCheck">
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [color]="'primary'" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </div>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [color]="'primary'" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>


            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Student Name</th>
                <td mat-cell *matCellDef="let transaction">
                    {{transaction.studentName ? transaction.studentName : '(Unregistered Student)' | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="mobileNumber">
                <th mat-header-cell *matHeaderCellDef>Mobile Number</th>
                <td mat-cell *matCellDef="let transaction"> {{transaction.studentMobile}} </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Date Submitted</th>
                <td mat-cell *matCellDef="let transaction">
                    {{transaction.submissionDate ? (transaction.submissionDate | date: dateFormat) : '' }} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let transaction" (click)="evaluateAssignment(transaction)" [ngClass]="{'pending': transaction.status.toLowerCase() === 'pending'}"> {{transaction.status | titlecase}}
                    <span class="review" [ngClass]="theme === 'admin'? 'admin-color': ''" *ngIf="transaction.status !== 'pending'">{{transaction.status === 'submitted' && transaction.evalStatus === 'pending' ? 'REVIEW' : 'VIEW'}}
          </span> </td>
            </ng-container>

            <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef>Files</th>
                <td mat-cell *matCellDef="let transaction"> <span class="attach" [ngClass]="theme === 'admin'? 'admin-color': ''">{{transaction.files ? transaction.files.length : 0}}</span>
                    <span>Attachment</span> </td>
            </ng-container>

            <ng-container matColumnDef="evaluation">
                <th mat-header-cell *matHeaderCellDef>Evaluation</th>
                <td mat-cell *matCellDef="let transaction" [ngClass]="{'pending': transaction.evalStatus.toLowerCase() === 'pending','done': transaction.evalStatus.toLowerCase() === 'done'}">
                    {{transaction.evalStatus | titlecase}} </td>
            </ng-container>

            <ng-container *ngIf="isCourseActivity else marksColumn">
                <ng-container matColumnDef="marks">
                    <th mat-header-cell *matHeaderCellDef>Marks</th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.marksObtained}} </td>
                </ng-container>
            </ng-container>
            <ng-template #marksColumn>
                <ng-container matColumnDef="marks">
                    <th mat-header-cell *matHeaderCellDef>Marks</th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.marks}} </td>
                </ng-container>
            </ng-template>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
                <td mat-cell *matCellDef="let transaction" class="action-table-header">
                    <div class="select">
                        <!-- FOR DEMO: it will be reverted later -->
                        <!-- <button mat-icon-button [matMenuTriggerFor]="menu" > -->
                        <button mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
                        <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                            <button mat-menu-item (click)="sendMsg(transaction)">
                <span>Send Message</span>
              </button>
                        </mat-menu>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>

            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <ng-container>
            <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize> </mat-paginator>
        </ng-container>
    </ng-container>

    <ng-template #material>
        <ng-container>
            <table class="detail-table" mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="hideCheck">
                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [color]="'primary'" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [color]="'primary'" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>


                <ng-container matColumnDef="name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Student Name</th>
                    <td mat-cell *matCellDef="let transaction">
                        {{transaction.studentName ? transaction.studentName : '(Unregistered Student)' | titlecase}} </td>
                </ng-container>

                <ng-container matColumnDef="mobileNumber">
                    <th mat-header-cell *matHeaderCellDef>Mobile Number</th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.studentMobile}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status </th>
                    <td mat-cell *matCellDef="let transaction">
                        <div [ngClass]="transaction.status">
                            {{transaction.status | titlecase}}
                        </div>
                    </td>
                </ng-container>

                <ng-container *ngIf="isCourseActivity" matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date Read</th>
                    <td mat-cell *matCellDef="let transaction">
                        {{transaction.submissionDate ? ( transaction.submissionDate | date: dateFormat) : '--'}} </td>
                </ng-container>

                <ng-container *ngIf="!isCourseActivity" matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date Read</th>
                    <td mat-cell *matCellDef="let transaction">
                        {{transaction.dateRead ? ( transaction.dateRead | date: dateFormat) : '--'}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
                    <td mat-cell *matCellDef="let transaction" class="action-table-header">
                        <div class="select">
                            <!-- FOR DEMO: it will be reverted later -->
                            <!-- <button mat-icon-button [matMenuTriggerFor]="menu" > -->
                            <button mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>
                            <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                                <button mat-menu-item>
                  <span>Send Message</span>
                </button>
                                <button mat-menu-item>
                  <span>Make Active</span>
                </button>
                                <button mat-menu-item>
                  <span>Make Inactive</span>
                </button>
                                <button mat-menu-item>
                  <span>Send Note</span>
                </button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-container>

        <ng-container>
            <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize> </mat-paginator>
        </ng-container>
    </ng-template>
</ng-container>