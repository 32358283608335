import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SchoolSettingService } from 'src/app/services/school-setting.service';
import { FindSchoolUserRoleService } from 'src/app/services/find-school-user-role.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LabelService } from 'src/app/services/label.service';

@Component({
  selector: 'app-common-settings',
  templateUrl: './common-settings.component.html',
  styleUrls: ['./common-settings.component.scss'],
  providers: [SchoolSettingService]
})
export class CommonSettingsComponent implements OnInit, OnDestroy {

  selectedSetting: string;
  lists = [];
  addSetting =  [];
  removeSetting =  [];
  isNewAdded: boolean = false;
  isNewRemoved: boolean = false;
  checked = {};
  intialChecked = {};
  private destroy$: Subject<boolean> = new Subject<boolean>();
  userRole: string;
  isLoading : boolean = true;
  labelData;
  @Input() selectedSchool;
  constructor(
    public addSettingService: SchoolSettingService,
    public snackbarService: SnackbarService,
    public userRoleType: FindSchoolUserRoleService,
    private labelService: LabelService
    ) { }

  ngOnInit(): void {
    this.selectedSetting = '';
    this.labelService.labels$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.labelData = data;
    })
    this.userRoleType.userRole$.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(role => {
      this.userRole = role;
    });
  }

  isSelected(setting) {
    return ( this.checked[setting.id] &&  this.checked[setting.id] === true) ? true : false;
  }

  onSettingSelected(event) {
    this.isLoading = true;
    this.selectedSetting = event;
    if(this.selectedSetting === 'standards') {
      forkJoin(this.addSettingService.getMasterSchoolStandard() , this.addSettingService.getSchoolStandard()).pipe(
        takeUntil(this.destroy$),
      ).subscribe(
        (res : any) => {
          this.lists = [];
          if(res.length !== 0){
            res[0] = res[0].filter(standard => {
              if(standard.status === 'inactive') {
                res[1].filter(value => {
                  if(value.standardID === standard.id) {
                    this.lists.push(standard)
                  }
                })
              } else {
                this.lists.push(standard)
              }
            })

            res[1].map(data => {
              this.checked[data.standardID] = true;
              this.intialChecked[data.standardID] = true;
            })
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          console.log(err);
        }
      )
    } else if(this.selectedSetting === 'session') {
      forkJoin(this.addSettingService.getMasterSchoolSession() , this.addSettingService.getSchoolSession()).pipe(
        takeUntil(this.destroy$),
      ).subscribe(
        (res : any) => {
          this.lists = [];
          if(res.length !== 0){
            res[0] = res[0].filter(session => {
              if(session.status === 'inactive') {
                res[1].filter(value => {
                  if(value.session === session.id) {
                    session.name = session.starting + ' - ' + session.ending
                    this.lists.push(session)
                  }
                })
              } else {
                  session.name = session.starting + ' - ' + session.ending
                  this.lists.push(session)
              }
            })

            res[1].map(data => {
              this.checked[data.sessionID] = true;
              this.intialChecked[data.sessionID] = true;
            })
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          console.log(err);
        }
      )

    } else if(this.selectedSetting === 'subjects') {
      forkJoin(this.addSettingService.getMasterSchoolSubject() , this.addSettingService.getSchoolSubject()).pipe(
        takeUntil(this.destroy$),
      ).subscribe(
        (res : any) => {
          this.lists = [];
          if(res.length !== 0){
            res[0] = res[0].filter(subject => {
              if(subject.status === 'inactive') {
                res[1].filter(value => {
                  if(value.subjectID === subject.id) {
                    this.lists.push(subject)
                  }
                })
              } else {
                this.lists.push(subject)
              }
            })

            res[1].map(data => {
              this.checked[data.subjectID] = true;
              this.intialChecked[data.subjectID] = true;
            })
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          console.log(err);
        }
      )
    }  else {
      forkJoin(this.addSettingService.getMasterSchoolSection() , this.addSettingService.getSchoolSection()).pipe(
        takeUntil(this.destroy$),
      ).subscribe(
        (res : any) => {
          this.lists = [];
          if(res.length !== 0){
            res[0] = res[0].filter(section => {
              if(section.status === 'inactive') {
                res[1].filter(value => {
                  if(value.sectionID === section.id) {
                    this.lists.push(section)
                  }
                })
              } else {
                this.lists.push(section)
              }
            })

            res[1].map(data => {
              this.checked[data.sectionID] = true;
              this.intialChecked[data.sectionID] = true;
            })
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          console.log(err);
        }
      )
  }
}

  back() {
    this.selectedSetting = '';
  }

  onSelect(event, setting) {
    event.checked === true ? this.addSetting.push(setting.id) : this.removeSetting.push(setting.id);
    if(event.checked === false) {
      this.isNewRemoved = true;
      this.checked[setting.id]= false;
      this.addSetting = this.addSetting.filter(settings => settings !== setting.id);
    } else {
      this.isNewAdded = true;
      this.removeSetting = this.removeSetting.filter(settings => settings !== setting.id);
    }
  }

  save() {
    if(this.selectedSetting.toLowerCase() === 'standards') {
      this.saveStandards();
    } else if(this.selectedSetting.toLowerCase() === 'subjects') {
      this.saveSubjects();
    } else if(this.selectedSetting.toLowerCase() === 'sections') {
      this.saveSections();
    } else if(this.selectedSetting.toLowerCase() === 'session') {
      this.saveSession();
    }
  }

  saveStandards() {
    this.addSetting = this.addSetting.filter(id => this.intialChecked[id] !== true)
    this.removeSetting = this.removeSetting.filter(id => this.intialChecked[id] === true)

    const requestBody = this.addSetting.length > 0 ?  {
      standardIDs : this.addSetting
    } : null

    const removeRequestBody = this.removeSetting.length > 0 ?  {
      standardIDs : this.removeSetting
    } : null;


    (this.isNewAdded && this.addSetting.length > 0) && this.addSettingService.addSchoolStandard( requestBody).pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      (res) => {
        this.addSetting.forEach(setting => {
          this.checked[setting] = true;
          this.intialChecked[setting] = true;
        })
        this.isNewAdded = false;
        this.addSetting = [];
        this.snackbarService.openSnackbar(`${this.selectedSetting} updated succesfully`);
      },
      (err) => {
        this.snackbarService.openSnackbar(err.error.error);
      }
    );

    (this.isNewRemoved  && this.removeSetting.length > 0) && this.addSettingService.removeSchoolStandard(removeRequestBody).pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      (res) => {
        this.removeSetting.forEach(setting => {
          this.checked[setting] = false;
          this.intialChecked[setting] = false;
        })
        this.isNewRemoved = false;
        this.removeSetting = [];
        this.snackbarService.openSnackbar(`${this.selectedSetting} updated succesfully`);
      },
      (err) => {
        this.snackbarService.openSnackbar(err.error.error);
      }
    );;

  }

  saveSubjects() {
    this.addSetting = this.addSetting.filter(id => this.intialChecked[id] !== true)
    this.removeSetting = this.removeSetting.filter(id => this.intialChecked[id] === true)

    const requestBody = {
      SubjectIDs : this.addSetting
    }

    const removeRequestBody = this.removeSetting.length > 0 ?  {
      SubjectIDs : this.removeSetting
    } : null;

    (this.isNewAdded && this.addSetting.length > 0) && this.addSettingService.addSchoolSubject(requestBody).pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      (res) => {
        this.addSetting.forEach(setting => {
          this.checked[setting] = true;
          this.intialChecked[setting] = true;
        })
        this.isNewAdded = false;
        this.addSetting = [];
        this.snackbarService.openSnackbar(`${this.selectedSetting} updated succesfully`);
      },
      (err) => {
        this.snackbarService.openSnackbar(err.error.error);
      }
    );

    (this.isNewRemoved  && this.removeSetting.length > 0) && this.addSettingService.removeSchoolSubject(removeRequestBody).pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      (res) => {
        this.removeSetting.forEach(setting => {
          this.checked[setting] = false;
          this.intialChecked[setting] = false;
        })
        this.isNewRemoved = false;
        this.removeSetting = [];
        this.snackbarService.openSnackbar(`${this.selectedSetting} updated succesfully`);
      },
      (err) => {
        this.snackbarService.openSnackbar(err.error.error);
      }
    );;

  }

  saveSections() {
    this.addSetting = this.addSetting.filter(id => this.intialChecked[id] !== true)
    this.removeSetting = this.removeSetting.filter(id => this.intialChecked[id] === true)

    const requestBody = {
      SectionIDs : this.addSetting
    }

    const removeRequestBody = this.removeSetting.length > 0 ?  {
      SectionIDs : this.removeSetting
    } : null;

    (this.isNewAdded && this.addSetting.length > 0) && this.addSettingService.addSchoolSection(requestBody).pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      (res) => {
        this.addSetting.forEach(setting => {
          this.checked[setting] = true;
          this.intialChecked[setting] = true;
        })
        this.isNewAdded = false;
        this.addSetting = [];
        this.snackbarService.openSnackbar(`${this.selectedSetting} updated succesfully`);
      },
      (err) => {
        this.snackbarService.openSnackbar(err.error.error);
      }
    );

    (this.isNewRemoved  && this.removeSetting.length > 0) && this.addSettingService.removeSchoolSection(removeRequestBody).pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      (res) => {
        this.removeSetting.forEach(setting => {
          this.checked[setting] = false;
          this.intialChecked[setting] = false;
        })
        this.isNewRemoved = false;
        this.removeSetting = [];
        this.snackbarService.openSnackbar(`${this.selectedSetting} updated succesfully`);
      },
      (err) => {
        this.snackbarService.openSnackbar(err.error.error);
      }
    );;

  }

  saveSession() {
    this.addSetting = this.addSetting.filter(id => this.intialChecked[id] !== true)
    this.removeSetting = this.removeSetting.filter(id => this.intialChecked[id] === true)

    const requestBody = {
      SessionIDs : this.addSetting
    }

    const removeRequestBody = this.removeSetting.length > 0 ?  {
      SessionIDs : this.removeSetting
    } : null;

    (this.isNewAdded && this.addSetting.length > 0) &&this.addSettingService.addSchoolSession(requestBody).pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      (res) => {
        this.addSetting.forEach(setting => {
          this.checked[setting] = true;
          this.intialChecked[setting] = true;
        })
        this.isNewAdded = false;
        this.addSetting = [];
        this.snackbarService.openSnackbar(`${this.selectedSetting} updated succesfully`);
      },
      (err) => {
        this.snackbarService.openSnackbar(err.error.error);
      }
    );

    (this.isNewRemoved  && this.removeSetting.length > 0) && this.addSettingService.removeSchoolSession(removeRequestBody).pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(
      (res) => {
        this.removeSetting.forEach(setting => {
          this.checked[setting] = false;
          this.intialChecked[setting] = false;
        })
        this.isNewRemoved = false;
        this.removeSetting = [];
        this.snackbarService.openSnackbar(`${this.selectedSetting} updated succesfully`);
      },
      (err) => {
        this.snackbarService.openSnackbar(err.error.error);
      }
    );;

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
