import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StudentTestService } from '../../services/student-test.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { QuestionModel } from 'src/app/models/test-questions.models';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { StudentCourseActivitiesService } from '../services/student-course-activities.service';
import { COURSE_ACTIVITY_ROUTE, SUBMITTED_STATUS } from 'src/app/Constants/Constant';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-take-test',
  templateUrl: './take-test.component.html',
  styleUrls: ['./take-test.component.scss']
})
export class TakeTestComponent implements OnInit, OnDestroy {
  test: QuestionModel;
  testID: string;
  testStarted = false;
  questionList = [];
  questionDetail;
  isSubmitted = false;
  isLoading = true;
  instruction: string;
  isCourseActivity = false;
  studentID: string;

  private destroy$$: Subject<any> = new Subject<any>();

  constructor(
    public location: Location,
    public sidenav: ToggleSidenavService,
    public snackbar: SnackbarService,
    public dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    public testService: StudentTestService,
    public localstorage: LocalStorageService,
    private courseActivitiesSvc: StudentCourseActivitiesService,
  ) { }

  ngOnInit(): void {
    this.sidenav.minmizeSidenav();
    this.testID = this.route.snapshot.params.id;
    this.studentID = this.route.parent.snapshot.params.id;

    this.isCourseActivity = this.router.url.includes(COURSE_ACTIVITY_ROUTE);

    if (this.isCourseActivity) {
      this.courseActivitiesSvc.getCourseActivity(this.testID, this.studentID);

      this.courseActivitiesSvc.selectedCourseActivity$.pipe(
        takeUntil(this.destroy$$)
      ).subscribe((activityDetails) => {
        if (activityDetails) {
          this.test = activityDetails.activity;
          if (activityDetails.submission.status === SUBMITTED_STATUS) {
            this.router.navigate(['result'], { relativeTo: this.route, replaceUrl: true });
            return;
          }
          this.testService.getQuestionFromGcs(activityDetails.activityURLs[0]).pipe(
            takeUntil(this.destroy$$)
          ).subscribe((res) => {
            res = JSON.parse(res);
            res.map(question => {
              if (question.instruction) {
                this.instruction = question.instruction;
                return;
              }
              question.options.map(option => {
                option.isSelected = false;
              });
              this.questionList.push(question);
            });
            this.isLoading = false;
          },
            (err) => {
              this.isLoading = false;
              this.snackbar.openSnackbar(`Unable to get the test details!! ${err.error.error}`);
            });
        }
      });
    } else {
      this.testService.getTest(this.testID).pipe(
        switchMap((ques) => {
          this.test = ques.test;
          return this.testService.getQuestionFromGcs(ques.questionURL);
        })
      )
        .subscribe(
          (res: any) => {
            res = JSON.parse(res);
            res.map(question => {
              if (question.instruction) {
                this.instruction = question.instruction;
                return;
              }
              question.options.map(option => {
                option.isSelected = false;
              });
              this.questionList.push(question);
            })
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            this.snackbar.openSnackbar(`Unable to get the test details!! ${err.error.error}`);
          }
        );
    }
  }

  getQuestion(question) {
    this.questionDetail = [];
    this.questionDetail.push(question);
  }

  back() {
    this.sidenav.maximizeSidenav();
    this.location.back();
  }

  mapAnswers() {
    let mapped_ans = [];
    this.questionList.map(question => {
      let selectedOptions = [];
      let correctOptions = [];
      question.options.filter((option, index) => {
        if (option.isSelected) {
          selectedOptions.push(index)
        }
      })
      question.options.filter((option, index) => {
        if (option.isCorrect) {
          correctOptions.push(index)
        }
      })
      mapped_ans.push({ questionNumber: question.number, selectedAnswers: selectedOptions, correctAnswers: correctOptions, marks: parseInt(question.marks.toString()) })
    });
    return mapped_ans;
  }

  submitTest() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '363px',
      height: '190px',
      backdropClass: 'blur',
      data: { theme: 'student', isUpload: true, alertText: 'Are you sure, you want to submit the test' },
      panelClass: ['delete-dialog-css'],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((isDeleted: boolean) => {
      if (!!isDeleted) {
        this.isLoading = true;
        const mappedAnswers = this.mapAnswers();
        if (!this.isCourseActivity) {
          const requestBody = {
            testID: this.testID,
            studentID: this.localstorage.getItem('studentID'),
            answers: mappedAnswers
          }
          this.testService.submitTest(requestBody).subscribe(
            (res) => {
              this.isLoading = false;
              this.isSubmitted = true;
              this.snackbar.openSnackbar('Congratulations!!! You have successfully completed the test!!')
            },
            (err) => {
              this.isLoading = false;
              this.snackbar.openSnackbar(`Unable to submit the test!! ${err.error.error}`)
            }
          );
        } else {
          const requestBody = {
            activityID: this.testID,
            studentID: this.localstorage.getItem('studentID'),
            answers: JSON.stringify(mappedAnswers)
          }
          this.courseActivitiesSvc.submitCourseActivity(requestBody).subscribe(
            (res) => {
              this.isLoading = false;
              this.isSubmitted = true;
              this.snackbar.openSnackbar('Congratulations!!! You have successfully completed the test!!');
            },
            (err) => {
              this.isLoading = false;
              this.snackbar.openSnackbar(`Unable to submit the test!! ${err.error.error}`);
            }
          );
        }
      }
    });
  }

  getResult() {
    this.router.navigate(['result'], { relativeTo: this.route });
  }

  start() {
    this.testStarted = true;
  }

  ngOnDestroy() {
    this.courseActivitiesSvc.reset();
    this.sidenav.maximizeSidenav();
  }

}
