import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-forgot-pin',
  templateUrl: './forgot-pin.component.html',
  styleUrls: ['./forgot-pin.component.scss']
})

export class ForgotPinComponent implements OnInit {
  isOTPVerified: boolean = false;
  newPin: string;
  isOtp: boolean = false
  phoneNumber = '';
  hasError;
  profilePic: string;
  isNumberError: boolean = false
  isOTPError: boolean;
  otpError: string;
  otp = '';
  otpTimer;
  user;
  isSubmitted: boolean = false;
  constructor(
    public authService: AuthService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<any>,
    public _snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: `0px` });
    this.user = this.data.user;
  }

  back() {
    this.dialogRef.close();
  }

  enterPhoneNumber(number) {
    this.hasError = false
    this.phoneNumber = number
  }

  login() {
    if (this.phoneNumber.length != 10 || parseInt(this.phoneNumber[0]) < 7) {
      this.hasError = true
      return
    }
    this.isOtp = true
    this.hasError = false
    this.sendOtp();
  }

  startOTPTimer() {
    this.authService.startOTPTimer();
    this.authService.otpTimer$.subscribe(val => {
      this.otpTimer = val;
    })
  }

  sendOtp() {
    this.isSubmitted = true
    this.authService.sendOtp(parseInt(this.phoneNumber)).then((result: any) => {
      this._snackbar.openSnackbar('OTP succesfully sent');
      this.userService.setSessionId('sessionID', result.sessionID);
      this.isSubmitted = false;
      this.startOTPTimer();
    })
      .catch((error) => {
        this.isSubmitted = false
        this.isNumberError = true;
        this.hasError = true;
      })
  }

  verify(otp) {
    this.otp = otp;
    this.isSubmitted = true
    const sessionID = this.userService.getSessionId('sessionID');
    if (!otp || otp.toString().length === 0) {
      this.isOTPError = true
      this.otpError = 'Please enter valid OTP'
      this.isSubmitted = false
      return
    }
    this.authService.verify(otp, sessionID, 'parent', this.phoneNumber)
      .then(results => {
        this._snackbar.openSnackbar('OTP verified')
        this.isOTPVerified = true;
        this.isSubmitted = false
      })
      .catch(error => {
        this.isSubmitted = false
        this._snackbar.openSnackbar(error.error.error)
        this.isOTPError = true;
        this.otpError = 'Invalid OTP'
      });
  }

  onChange(otp) {
    this.otp = otp;
    this.isOTPError = this.isOTPError == true ? false : null
  }

  savePin() {
    const type = this.data.type;
    if (this.newPin.toString().length !== 4) {
      this._snackbar.openSnackbar('4 digit pin is allowed')
    } else {
      this.isSubmitted = true;
      const requestBody = {
        "forgotPin": true,
        "newPin": this.newPin.toString()
      }
      this.authService.changePin(type, this.user.id, requestBody).subscribe(
        (res) => {
          this._snackbar.openSnackbar(res.msg);
          this.dialogRef.close();
        },
        (err) => {
          this.isSubmitted = false;
          this._snackbar.openSnackbar(err.error.error)
        }
      )
    }
  }

}
