import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoursesService } from 'src/app/services/courses.service';
import { StudentCourseActivitiesService } from '../services/student-course-activities.service';
import { dateFormat, pageSizeOptions, paginatorPageSize, ACTIVITY_TYPES, COURSE_ACTIVITY_ROUTE, SUBMITTED_STATUS } from 'src/app/Constants/Constant';
import { Location } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-child-course-detail',
  templateUrl: './child-course-detail.component.html',
  styleUrls: ['./child-course-detail.component.scss']
})
export class ChildCourseDetailComponent implements OnInit, OnDestroy {

  constructor(
    private sidenav: ToggleSidenavService,
    private route: ActivatedRoute,
    private courseSvc: CoursesService,
    private courseActivitiesSvc: StudentCourseActivitiesService,
    private router: Router,
    private location: Location
  ) { }


  @ViewChild(MatSort) set sorting(sort: MatSort) {
    sort ? this.dataSource.sort = sort : null;
  }
  @ViewChild(MatPaginator) set pagination(paginator: MatPaginator) {
    paginator ? this.dataSource.paginator = paginator : null;
  }

  private destroy$$: Subject<any> = new Subject<any>();
  public isLoading: boolean;
  public loadingCourseDetails = true;
  public selectedCourse: any;
  public courseActivities: any[] = null;
  public displayedColumns = ['date', 'activity', 'title', 'status', 'evaluation', 'totalmarks', 'marks'];
  public pageSizeOptions: number[] = pageSizeOptions;
  public pageSize = paginatorPageSize;
  public dataSource: any;

  public formatDate: string;
  private courseId: string;
  private studentId: string;


  ngOnInit(): void {
    this.formatDate = dateFormat;

    this.sidenav.minmizeSidenav();

    this.courseActivitiesSvc.courseActivities$.subscribe(
      (courseActivities) => {
        if (courseActivities) {
          this.courseActivities = courseActivities;
          this.dataSource = new MatTableDataSource(this.courseActivities);
        }
      });

    this.route.parent.parent.params.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(parentParam => {
      if (parentParam.id) {
        this.studentId = parentParam.id;
        this.route.params.pipe(
          takeUntil(this.destroy$$)
        ).subscribe(param => {
          if (param.id) {
            this.courseId = param.id;
            this.courseSvc.getCourseDetail(param.id);
            this.courseActivitiesSvc.getCourseActivities(this.courseId, this.studentId);
          }
        });
      }
    });

    this.courseSvc.courseDetail$.subscribe((course) => {
      if (course) {
        this.selectedCourse = course;
      }
    });

    this.courseSvc.isLoading$.subscribe((isLoading) => this.loadingCourseDetails = isLoading);
    this.courseActivitiesSvc.isLoading$.subscribe((isLoading) => this.isLoading = isLoading);
  }

  public back() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public navigateToActivity(courseActivity) {
    if (!courseActivity.isOpen) {
      return;
    }
    if (courseActivity.activityType === ACTIVITY_TYPES.tests && courseActivity.status === SUBMITTED_STATUS) {
      this.router.navigate(['student', this.studentId, 'dashboard', 'courses', COURSE_ACTIVITY_ROUTE,
        courseActivity.activityType, courseActivity.activityID, 'result']);
      return;
    }
    this.router.navigate(['student', this.studentId, 'dashboard', 'courses', COURSE_ACTIVITY_ROUTE,
      courseActivity.activityType, courseActivity.activityID]);
  }

  public getClass(row) {
    let cls = '';
    if (row.status === 'pending') {
      cls = 'pending';
    }
    if (row.isOpen) {
      return cls + ' enabled-row';
    }
    return cls;
  }

  getEvalClass(row) {
    let cls = '';
    if (row.evalStatus === 'pending') {
      cls = 'pending';
    } else if (row.evalStatus === 'not reviewed') {
      cls = 'unreviewed';
    } else {
      cls = 'done';
    }
    if (row.isOpen) {
      return cls + ' enabled-row';
    }
    return cls;
  }

  ngOnDestroy() {
    this.destroy$$.next(true);
    this.destroy$$.complete();
    this.courseActivitiesSvc.reset();
  }


}
