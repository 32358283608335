<ng-container *ngIf="false; else school">
    <div class="spinner">
        <app-spinner></app-spinner>
    </div>
</ng-container>

<ng-template #school>
    <div class="admin-theme schools-container">
        <ng-container *ngIf="!isSchoolCreated">
            <div class="head">
                <div>
                  {{schoolLabel}}
                </div>
                <div *ngIf="isSelfSchoolGroup" class="btn">
                    <button [disabled]="accessRights && !accessRights.schools" mat-flat-button color="primary" (click)="addSchool()">{{createSchooolLabel}}</button>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="isSchoolCreated">
            <div class="header">
                <div class="left">
                    {{schoolLabel}} ({{totalSchools}})
                </div>
                <div class="right">
                    <div class="search-box">
                        <input matInput [placeholder]="'Search ' + schoolLabel" [formControl]="searchTerm">
                        <mat-icon class="search-icon">search</mat-icon>
                    </div>
                    <ng-select *ngIf="!isSelfSchoolGroup" [items]="toppingList" [clearable]="false" [searchable]="false" (change)="filterByRole($event)" [(ngModel)]="roleShowValue">
                    </ng-select>
                    <ng-select [items]="statusList" [clearable]="false" [searchable]="false" (change)="filterByStatus($event)" [(ngModel)]="status">
                    </ng-select>
                    <div *ngIf="isSelfSchoolGroup" class="btn">
                        <button [disabled]="accessRights && !accessRights.schools" mat-flat-button color="primary" (click)="addSchool()">{{createSchooolLabel}}</button>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="content bordered-box" [scrollWindow]="false" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="scroll()">
            <ng-container *ngIf="!isLoading && totalSchools === 0">
                <div class="noschool">No Data Found</div>
            </ng-container>
            <ng-container *ngIf="!isLoading && !isSchoolCreated">
                <div class="home">
                    <div class="txt">
                        <div>{{labelData['createanewschool']}}</div>
                    </div>
                    <div class="list">
                        <div *ngFor="let task of tasks" class="task">
                            <div class="tick">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
                                    <path fill="#a1b6c0" d="M13.302.905c.472-.44 1.277-1.183 2-.798 1.86.99-.5 3.301-1.25 4.236-1.194 1.513-2.444 2.971-3.666 4.456-.944 1.128-1.777 2.366-2.805 3.466-.583.633-1.582 1.788-2.554 1.733-.806-.027-1.5-.605-2.027-1.155C1.75 11.55.056 9.515 0 7.617c-.027-1.458 1.306-1.018 2.11-.413 1.112.853 1.973 1.898 2.972 2.86.445-.825 1.167-1.54 1.833-2.2 1.166-1.183 2.222-2.393 3.36-3.603 1.028-1.1 1.944-2.31 3.027-3.356zM2.24 9.873l.271.312c.622.698 1.175 1.205 1.907 1.81.563.084.796.214 1.03.343C4.203 10.733 3.134 9.91 2.24 9.873zm12.634-7.76C13.046 2.997 8.223 7.968 8 8.833L10.094 7c1.001-.543 1.45-1.509 2.3-2.183.2-.533 2.306-2.503 2.48-2.704z"/>
                                </svg>
                            </div>
                            <div class="text">
                                {{task}}
                            </div>
                        </div>
                    </div>
                    <div class="img">
                        <img src="../../../assets/school-pic.svg" />
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="isSchoolCreated">
                <app-school-card [schools]="filteredList"></app-school-card>
            </ng-container>
            <ng-container *ngIf="isLoading">
                <div class="spinner">
                    <app-spinner></app-spinner>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>
