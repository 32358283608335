import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateSchoolComponent } from '../create-school/create-school.component';
import { SchoolService } from 'src/app/services/api/school.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { School, PagedSchoolData } from 'src/app/models/school.models';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LabelService } from 'src/app/services/label.service';
import { UNARCHIVED_STATUS, ARCHIVED_STATUS, SELECTED_SCHOOL_GROUP_KEY, SELF_ID_KEY, ADMIN_ROLE } from 'src/app/Constants/Constant';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { SchoolGroupService } from 'src/app/services/school-group.service';
import { AccessRights } from 'src/app/models/super-admin-users.model';
import { FormControl } from '@angular/forms';
import { ADMIN } from 'src/app/profile/profile.component';

const debounceTime = 1000;

@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss']
})
export class SchoolsComponent implements OnInit, OnDestroy {
  isSchoolCreated = false;
  totalSchools = 0;
  schoolList: School[] = [];
  isLoading = true;
  filteredList = [];
  roleFilteredList = [];
  statusFilteredList = [];
  toppingList: string[] = ['All Role', 'Admin', 'Member'];
  statusList: string[] = ['active', 'archived'];
  roleShowValue = this.toppingList[0];
  role = '';
  status;
  schools;

  private destroy$$ = new Subject<any>();
  subscription = new Subscription();
  tasks = [
    'Access to all school',
    'Invite/Add teacher',
    'View school performance',
  ]
  createSchooolLabel;
  schoolLabel;
  allSchoolLabel;

  isInitialLoading = true;

  public isSelfSchoolGroup = true;
  labelData = {};
  accessRights: AccessRights;

  public searchTerm: FormControl = new FormControl('');
  public timer: any;
  constructor(
    private dialog: MatDialog,
    private _schoolService: SchoolService,
    public _snackbar: SnackbarService,
    public sidenav: ToggleSidenavService,
    public labelS: LabelService,
    private localstorage: LocalStorageService,
    private schoolGroupSvc: SchoolGroupService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.sidenav.maximizeSidenav();
    this.subscription = this.labelS.labels$.subscribe(val => {
      this.labelData = val;
      this.createSchooolLabel = val['createschool'];
      this.schoolLabel = val['schools'];
      this.allSchoolLabel = val['allschools'];
      // this.statusList[0] = val['allschools'];
      this.status = this.statusList[0];
    });
    this.schoolGroupSvc.accessRights$.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(accessRights => {
      this.accessRights = { ...accessRights };
      this.cd.detectChanges();
    });
    if (this.localstorage.getItem(SELECTED_SCHOOL_GROUP_KEY) &&
      (this.localstorage.getItem('id') !== this.localstorage.getItem(SELECTED_SCHOOL_GROUP_KEY))
    ) {
      this.isSelfSchoolGroup = false;
    }

    this._schoolService.schoolsSearchTerm$.pipe(
      takeUntil(this.destroy$$)
    ).subscribe((term) => {
      if (this.isInitialLoading) {
        this.searchTerm.patchValue(term);
      }
      this._schoolService.schoolsStatus$.pipe(
        takeUntil(this.destroy$$)
      ).subscribe((status) => {
        if (this.isInitialLoading) {
          this.status = status;
        }
        this._schoolService.schoolsRole$.pipe(
          takeUntil(this.destroy$$)
        ).subscribe((role) => {
          this.role = role;
          this.roleShowValue = this.role === 'all' ? this.toppingList[0] :
            this.role === ADMIN_ROLE ? this.toppingList[1] : this.toppingList[2];
          if (this.isInitialLoading) {
            this.isInitialLoading = false;
            this._schoolService.getSchoolsPage(this.searchTerm.value, this.status, this.role);
          }
        });
      });
    });


    this.schools = this.allSchoolLabel ? this.allSchoolLabel : 'All Schools';


    this._schoolService.isLoading$.pipe(
      takeUntil(this.destroy$$)
    )
      .subscribe((value: boolean) => this.isLoading = value);

    this._schoolService.pagedSchoolData$.pipe(
      takeUntil(this.destroy$$)
    )
      .subscribe(
        (data: PagedSchoolData) => {
          if (data && data.schools.length > 0) {
            this.isSchoolCreated = true;
            this.totalSchools = data.total;
            this.schoolList = data.schools;
            if (this.isInitialLoading) {
              this.isInitialLoading = false;
              this.roleFilteredList = this.schoolList;
              this.statusFilteredList = this.schoolList;
              this.filteredList = this.schoolList;
            } else {
              this.filteredList = this.schoolList;
            }
          } else {
            this.totalSchools = 0;
            this.schoolList = [];
            this.filteredList = [];
          }
        },
        (err) => {
          this.isLoading = false;
          this._snackbar.openSnackbar(err.error.error);
        }
      );

    this.searchTerm.valueChanges.pipe(
      takeUntil(this.destroy$$)
    ).subscribe((term) => {
      this.isLoading = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this._schoolService.getSchoolsPage(term, this.status, this.role);
      }, debounceTime);
    });
  }

  filterByRole(event) {
    switch (event) {
      case this.toppingList[0]:
        this.role = 'all';
        break;
      case this.toppingList[1]:
        this.role = ADMIN_ROLE;
        break;
      case this.toppingList[2]:
        this.role = 'member';
        break;
    }
    this._schoolService.getSchoolsPage(this.searchTerm.value, this.status, this.role);
  }

  filterByStatus(event) {
    this._schoolService.getSchoolsPage(this.searchTerm.value, event, this.role);
  }

  scroll() {
    if (!this.isLoading) {
      this._schoolService.getSchoolsPage(this.searchTerm.value, this.status, this.role);
    }
  }
  addSchool() {
    const dialogRef = this.dialog.open(CreateSchoolComponent, {
      width: '46%',
      height: '100%',
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['admin-theme', 'width-class'],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(schoolAdded => {
      if (schoolAdded) {
        this.isSchoolCreated = true;
        // populating the school group with self id when a new school is created
        this.localstorage.setItem(SELECTED_SCHOOL_GROUP_KEY, localStorage.getItem(SELF_ID_KEY));
        this._schoolService.clearSchool();
        this._schoolService.assignSchoolRole(schoolAdded);
        this._schoolService.getSchoolsPage(this.searchTerm.value, this.status, this.role, true);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }

}
