import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginModule } from '../login/login.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from '../services/auth.guard';
import { UsersModule } from './users/users.module';
import { SuperAdminAnalyticsComponent } from './super-admin-analytics/super-admin-analytics.component';
import { SuperAdminLibraryComponent } from './super-admin-library/super-admin-library.component';


const routes: Routes = [
  { path: 'login', loadChildren: () => LoginModule },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'users' },
      { path: 'users', loadChildren: () => UsersModule },
      { path: 'analytics', component: SuperAdminAnalyticsComponent },
      { path: 'cloud', component: SuperAdminLibraryComponent },
    ]
  },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuperAdminRoutingModule { }
