<ng-container *ngIf="isLoading else course">
    <app-spinner></app-spinner>
</ng-container>
<ng-template #course>
    <div class="create-course-wrapper admin-theme">
        <div class="header-container">
            <div class="header">
                <div>{{title}}</div>
                <div (click)="close()" class="back"><img src="../../../assets/close.svg" /></div>
            </div>

            <div class="hr">
                <hr>
            </div>
        </div>

        <ng-container *ngIf="categories.length == 0 else formContainer">
            <div class="placeholder">
                <h1>Categories not available to create course</h1>
            </div>
        </ng-container>
        <ng-template #formContainer>
            <div class="form">
                <form [formGroup]="courseForm">
                    <div class="name">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="Enter name" formControlName="name">
                            <mat-error *ngIf="!courseForm.controls['name'].valid && courseForm.controls['name'].touched && !courseForm.controls['name'].hasError('maxlength')">
                                Name is required</mat-error>
                            <mat-error *ngIf="courseForm.controls['name'].hasError('maxlength') && courseForm.controls['name'].touched">
                                Name can not contain more than 100 letters </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="description">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>Description</mat-label>
                            <textarea matInput rows="5" placeholder="Enter description" formControlName="description"></textarea>
                            <mat-error *ngIf="!courseForm.controls['description'].valid && courseForm.controls['description'].touched">
                                Description is required</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="category">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>Category</mat-label>
                            <mat-select placeholder="Select category" formControlName="categoryID">
                                <mat-option *ngFor="let category of categories" [value]="category.id">
                                    {{category.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="!courseForm.controls['categoryID'].valid && courseForm.controls['categoryID'].touched">
                                Category is required
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="course-code">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>Course Code</mat-label>
                            <input matInput placeholder="Enter Code" formControlName="courseCode" />
                            <mat-error *ngIf="!courseForm.controls['courseCode'].valid && courseForm.controls['courseCode'].touched && !courseForm.controls['courseCode'].hasError('maxlength')">
                                Course Code is required</mat-error>
                            <mat-error *ngIf="courseForm.controls['courseCode'].hasError('maxlength') && courseForm.controls['courseCode'].touched">
                                Course Code can not contain more than 50 letters </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="upload">
                        <div [ngStyle]="isImageUploaded === false && {'border': '2px solid #ff8156', 'border-radius': '12px', 'color': '#ff8156'}">
                            <app-upload [imgUrl]="imgURLIn" [imgType]="'logo'" [text]="'UPLOAD BANNER IMAGE'" (url)="onImageUpload($event)"></app-upload>
                        </div>
                        <mat-error class="upload-err" *ngIf="isImageUploaded === false">Please upload image</mat-error>
                    </div>


                    <div class="certificate-field">
                        <p>Certificate Available</p>
                        <div>
                            <mat-radio-group class="radio-btn" formControlName="certificate">
                                <mat-radio-button color="primary" class="example-margin" [value]="true">Yes</mat-radio-button>
                                <mat-radio-button color="primary" class="example-margin" [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="random-order-field">
                        <p>Can Complete Chapters In Random Order</p>
                        <div>
                            <mat-radio-group [disabled]="randomOrderCompletionAllowed" class="radio-btn" formControlName="randomOrder">
                                <mat-radio-button color="primary" class="example-margin" [value]="true">Yes</mat-radio-button>
                                <mat-radio-button color="primary" class="example-margin" [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="expiration-field">
                        <p>Course Expiration</p>
                        <div>
                            <mat-radio-group class="radio-btn" formControlName="expiration">
                                <mat-radio-button color="primary" class="example-margin" [value]="true">Yes</mat-radio-button>
                                <mat-radio-button color="primary" class="example-margin" [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="date" *ngIf="courseForm.controls['expiration'].value">
                        <mat-form-field class="date-input" appearance="outline" [floatLabel]="'always'">
                            <mat-label>Expiration Date</mat-label>
                            <input matInput placeholder="DD-MM-YY" (click)="startpicker.open()" [matDatepicker]="startpicker" [formControl]="expirationDateForm.controls['expirationDate']">
                            <mat-datepicker-toggle matSuffix [for]="startpicker">
                                <mat-icon matDatepickerToggleIcon>
                                    <img src='assets/calender.svg' />
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #startpicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <div class="btn">
                <button (click)="submitForm()" mat-flat-button color="primary" [disabled]="submitting">SUBMIT</button>
            </div>
        </ng-template>
    </div>
</ng-template>