import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Course } from 'src/app/models/course.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class StudentCoursesService {

  constructor(
    private crud: CrudService,
    private snackbar: SnackbarService
  ) { }

  private subscription: any;

  private isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public get isLoading$(): Observable<boolean> {
    return this.isLoading$$.asObservable();
  }

  private courses$$: BehaviorSubject<Course[]> = new BehaviorSubject([]);

  public get courses$(): Observable<Course[]> {
    return this.courses$$.asObservable();
  }

  private schools$$: BehaviorSubject<Course[]> = new BehaviorSubject([]);

  public get schools$(): Observable<Course[]> {
    return this.schools$$.asObservable();
  }

  public searchCourses(studentId: string, term: string, schoolId: string) {
    this.isLoading$$.next(true);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.crud.get(environment.apiUrl + `course/student/${studentId}?term=${term}&school-id=${schoolId}`)
      .subscribe((resp) => {
        if (resp) {
          this.isLoading$$.next(false);
          const subscribedCourses: Map<string, number> = new Map<string, number>();
          if(resp.subscribedCourses) {
            resp.subscribedCourses.forEach(element => {
              subscribedCourses.set(element.courseID, element.percentage);
            });
          }
          const courses = resp.courses.map(c => {
            if (subscribedCourses.get(c.id) !== undefined) {
              c.subscribed = true;
              c.completionPercentage = subscribedCourses.get(c.id);
              return c;
            }
            c.subscribed = false;
            c.completionPercentage = 0;
            return c;
          });
          const subCourses = courses.filter(c => c.subscribed);
          const unSubCourses = courses.filter(c => !c.subscribed);
          const allCourses = [...subCourses, ...unSubCourses];
          this.getBase64Image(allCourses);
          this.courses$$.next(allCourses);
        }
      }, (err) => {
        this.snackbar.openSnackbar('unable to fetch courses');
      });
  }

  private getBase64Image(courses: Course[]) {
    courses.forEach((course) => {
      this.crud.get(course.bannerImage).subscribe(
        (res) => course.bannerImage = res,
        () => {
          this.snackbar.openSnackbar('unable to fetch courses banner image');
        });
    });
  }

  public subscribeCourse(courseID, studentID) {
    const body = { courseID, studentID };
    return this.crud.post(environment.apiUrl + '/course/subscribe', body);
  }

  public getParentSchools() {
    this.crud.get(environment.apiUrl + '/parent/school').subscribe((resp) => {
      this.schools$$.next(resp);
    }, (err) => {
      this.snackbar.openSnackbar('unable to fetch schools for filter');
    })
  }

}
