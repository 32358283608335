<ng-container *ngIf="isCourseActivity else classActivity">
    <div class="review-assignment-wrap" *ngIf="courseActivitiesSvc.activitySubmissionDetails$ | async as activityDetails">
        <div class="header-wrap">
            <ng-container *ngIf="headerType === 'view'; else review">
                <h3>Assignment Evaluated({{(courseActivitiesSvc.currentSlide$ | async)}}/{{activityDetails.submission ? activityDetails.submission.files.length : activityDetails.evaluation.files.length }})</h3>
            </ng-container>
            <ng-template #review>
                <div class="header-title">
                    <h3>Review: Assignment({{(courseActivitiesSvc.currentSlide$ | async)}}/{{activityDetails.submission ? activityDetails.submission.files.length : activityDetails.evalution.files.length }})</h3>
                    <div class="annotation-action">
                        <div class="annotation-title">
                            <p>Annotation Mode</p>
                            <mat-icon matTooltip="scrolling is disabled when annotation mode is on" #tooltip="matTooltip" (click)="tooltip.toggle()"><img src="../../../assets/tooltip-active.svg" /></mat-icon>
                        </div>
                        <mat-button-toggle-group [formControl]="annotationMode" appearance="legacy" name="annotationMode" aria-label="Annotation Mode">
                            <mat-button-toggle [ngClass]="annotationMode.value === 'on' ? 'on-active': ''" value="on">On</mat-button-toggle>
                            <mat-button-toggle [ngClass]="annotationMode.value === 'off' ? 'off-active': ''" value="off">Off</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </ng-template>

            <div class="header-actions">
                <span class="material-icons download-icon-admin" (click)="courseActivitiesSvc.downloadAllUpdatedFiles()">get_app</span>
                <span class="material-icons" (click)="close()">close</span>
            </div>
        </div>
        <div [ngClass]="evaluating? 'blur' : ''" class="review-assignment-content">
            <div class="assignment-images">
                <app-assignment-canvas [canEditFile]="annotationMode.value === 'on'" [isCourseActivity]="isCourseActivity" (onEdit)="onEdit($event)"></app-assignment-canvas>
            </div>

            <div class="assignment-details">
                <div class="group">

                    <div class="submittedBy">
                        <div class="txt">Submitted by:</div>
                        <div class="name">{{studentName | titlecase}}</div>
                    </div>

                    <div class="title">
                        <div>Title: {{title | titlecase}}</div>
                    </div>

                    <div class="assignment-marks">
                        <div>
                            <app-shiksha-input [labelText]="'Marks Obtained'">
                                <input type="text" [(ngModel)]="obtainedMarks" [disabled]="!isEditable">
                            </app-shiksha-input>
                        </div>

                        <div class="totalMarks">
                            <app-shiksha-input [labelText]="'Total Marks'">
                                <input type="text" [value]="activityDetails.activity ? activityDetails.activity.totalMarks : activityDetails.evaluation.totalMarks" disabled>
                            </app-shiksha-input>
                        </div>
                    </div>

                </div>

                <div class="assignment-notes">
                    <app-shiksha-input [labelText]="'Note'">
                        <textarea name="notes" id="notes" [(ngModel)]="remarks" [disabled]="!isEditable"></textarea>
                    </app-shiksha-input>
                </div>

                <ng-container *ngIf="isEditable">
                    <div class="btn">
                        <button [ngClass]="isCourseActivity ? 'admin-color': ''" mat-flat-button color="primary" (click)="saveActivity()">SAVE</button>
                    </div>
                </ng-container>
            </div>
        </div>
        <div *ngIf="evaluating" class="submit-loader">
            <app-spinner></app-spinner>
        </div>
    </div>
</ng-container>
<ng-template #classActivity>
    <div class="review-assignment-wrap" *ngIf="assignmentFileService.assignmentDetails$ | async as assignment">
        <div class="header-wrap">
            <ng-container *ngIf="headerType === 'view'; else review">
                <h3>Assignment Evaluated({{(assignmentFileService.currentSlide$ | async)}}/{{assignment.submittedAssignment ? assignment.submittedAssignment.fileCount : assignment.evaluatedAssignment.fileCount }})</h3>
            </ng-container>
            <ng-template #review>
                <div class="header-title">
                    <h3>Review: Assignment({{(assignmentFileService.currentSlide$ | async)}}/{{assignment.submittedAssignment ? assignment.submittedAssignment.fileCount : assignment.evaluatedAssignment.fileCount }})</h3>
                    <div class="annotation-action">
                        <div class="annotation-title">
                            <p>Annotation Mode</p>
                            <mat-icon matTooltip="scrolling is disabled when annotation mode is on" #tooltip="matTooltip" (click)="tooltip.toggle()"><img src="../../../assets/tooltip-active.svg" /></mat-icon>
                        </div>
                        <mat-button-toggle-group [formControl]="annotationMode" appearance="legacy" name="annotationMode" aria-label="Annotation Mode">
                            <mat-button-toggle [ngClass]="annotationMode.value === 'on' ? 'on-active': ''" value="on">On</mat-button-toggle>
                            <mat-button-toggle [ngClass]="annotationMode.value === 'off' ? 'off-active': ''" value="off">Off</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </ng-template>

            <div class="header-actions">
                <span class="material-icons download-icon" (click)="assignmentFileService.downloadAllUpdatedFiles()">get_app</span>
                <span class="material-icons" (click)="close()">close</span>
            </div>
        </div>
        <div [ngClass]="evaluating? 'blur' : ''" class="review-assignment-content">
            <div class="assignment-images">
                <app-assignment-canvas [canEditFile]="annotationMode.value === 'on'" (onEdit)="onEdit($event)"></app-assignment-canvas>
            </div>

            <div class="assignment-details">
                <div class="group">

                    <div class="submittedBy">
                        <div class="txt">Submitted by:</div>
                        <div class="name">{{studentName | titlecase}}</div>
                    </div>

                    <div class="title">
                        <div>Title: {{title | titlecase}}</div>
                    </div>

                    <div class="assignment-marks">
                        <div>
                            <app-shiksha-input [labelText]="'Marks Obtained'">
                                <input type="text" [(ngModel)]="obtainedMarks" [disabled]="!isEditable">
                            </app-shiksha-input>
                        </div>

                        <div class="totalMarks">
                            <app-shiksha-input [labelText]="'Total Marks'">
                                <input type="text" [value]="assignment.assignmentDetails ? assignment.assignmentDetails.totalMarks : assignment.evaluatedAssignment.totalMarks" disabled>
                            </app-shiksha-input>
                        </div>
                    </div>

                </div>

                <div class="assignment-notes">
                    <app-shiksha-input [labelText]="'Note'">
                        <textarea name="notes" id="notes" [(ngModel)]="remarks" [disabled]="!isEditable"></textarea>
                    </app-shiksha-input>
                </div>

                <ng-container *ngIf="isEditable">
                    <div class="btn">
                        <button mat-flat-button color="primary" (click)="save()">SAVE</button>
                    </div>
                </ng-container>
            </div>
        </div>
        <div *ngIf="evaluating" class="submit-loader">
            <app-spinner></app-spinner>
        </div>
    </div>
</ng-template>
