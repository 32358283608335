<ng-container *ngIf="!liveSession">
    <app-spinner></app-spinner>
</ng-container>
<div *ngIf="!isConnectionDropped" [ngClass]="liveSession? 'visible': 'no-display'" class="container">
    <button class="start-session-button" [ngClass]="isJoinedRoom? 'no-display': 'visible'" (click)="startSession()" mat-raised-button>Start Session</button>
    <div [ngClass]="isJoinedRoom? '': 'no-display'" class="video-container">
        <ng-container *ngIf="teacherStream && teacherStream.toggle">
            <div class="spinner overlay" (click)="false; $event.stopPropagation();">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        </ng-container>
        <button class="leave-session-button" (click)="leaveSession()">LEAVE SESSION</button>
        <video *ngIf="teacherStreamSource && receivedTeacherStreamInfo" [ngClass]="
        {'no-display': ((!receivedTeacherStreamInfo) || teacherStream && !teacherStream.isCameraOn && !teacherStream.isSharingScreen || (teacherStream && teacherStream.toggle)),
        'flip': teacherStream && !teacherStream.isSharingScreen
        }" id="teacher-video" [srcObject]="teacherStreamSource.stream" autoplay playsinline></video>

        <div id="remote-video-placeholder" [ngClass]="!teacherStream || (teacherStream && !teacherStream.isCameraOn  && !teacherStream.isSharingScreen) || (teacherStream && teacherStream.toggle)? 'visible': 'no-display'" class="thumbnail-container">
            <div *ngIf="teacherStream && teacher && !teacher.profilePicture" class="profile-image">{{teacher.name.charAt(0)}}
            </div>
            <div *ngIf="teacherStream && teacher && teacher.profilePicture" class="profile-image"><img [src]="teacher.profileSrc" /></div>
            <div *ngIf="!teacherStream" class="host-placeholder"> Waiting For The Host To Join</div>
        </div>
        <div [ngClass]="showButtons ? 'visible': 'hidden'" class="Bottom-Gradient">
            <div class="actions-container">
                <div class="action" *ngIf="isPublishing && isCameraOn">
                    <div (click)="turnOffCamera()">
                        <img src="assets/turn-off-camera-copy.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Turn Off Camera</div>
                </div>
                <div class="action" *ngIf="isPublishing && !isCameraOn">
                    <div (click)="turnOnCamera()">
                        <img src="assets/turn-on-camera.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Turn On Camera</div>
                </div>
                <div class="action" *ngIf="isPublishing && isMicOn">
                    <div (click)="turnOffMic()">
                        <img src="assets/turn-off-mic.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Turn Off Mic</div>
                </div>
                <div class="action" *ngIf="isPublishing && !isMicOn">
                    <div (click)="turnOnMic()">
                        <img src="assets/turn-on-mic.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Turn On Mic</div>
                </div>
                <div class="action" *ngIf="isHandRaised">
                    <div (click)="lowerHand()">
                        <img src="assets/student_lower_hand.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Lower Hand</div>
                </div>
                <div class="action" *ngIf="!isHandRaised">
                    <div (click)="raiseHand()">
                        <img src="assets/student_raise_hand.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Raise Hand</div>
                </div>
            </div>
            <div class="actions-container">
                <div class="action" *ngIf="!isShowingParticipants">
                    <div (click)="showParticipants()">
                        <div *ngIf="liveSessionState && liveSessionState.totalParticipants" class="tag black-tag">
                            {{liveSessionState.totalParticipants}}</div>
                        <img src="assets/participants.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Participants</div>
                </div>
                <div class="action" *ngIf="!isShowingChat">
                    <div (click)="showChat()">
                        <div *ngIf="liveSessionState && liveSessionState.unreadChatMessages" class="tag red-tag">
                            {{liveSessionState.unreadChatMessages}}</div>
                        <img src="assets/chat.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Chat</div>
                </div>
            </div>
        </div>

        <div [ngClass]="(isPublishing || (remoteStreams && remoteStreams.length > 0)) ? '': 'no-display'" class="other-streams" cdkDragBoundary=".video-container" cdkDrag>
            <div [ngClass]="isPublishing? 'subscriber-video': 'no-display'">
                <div class="remote-stream-dimensions">
                    <video [ngClass]="!isCameraOn? 'no-display': 'visible'" width="130px" height="100px" #localVideo autoplay playsinline></video>
                    <div *ngIf="!isCameraOn" class="thumbnail-container short-thumbnail">
                        <div *ngIf="!selfParticipant.profilePicture" class="profile-image remote-profile">
                            {{selfParticipant.name.charAt(0)}}</div>
                        <div *ngIf="selfParticipant.profilePicture" class="profile-image remote-profile"><img [src]="selfParticipant.profileSrc" /></div>
                    </div>
                </div>
                <div class="remote-stream-info">
                    <div class="remote-stream-name" *ngIf="selfParticipant">{{selfParticipant.name}}</div>
                    <div class="mic-container">
                        <mat-icon *ngIf="isMicOn">mic</mat-icon>
                        <mat-icon *ngIf="!isMicOn">mic_off</mat-icon>
                    </div>
                </div>
            </div>
            <div *ngFor="let stream of remoteStreams;let i=index" class="subscriber-video">
                <div class="remote-stream-dimensions">
                    <video [ngClass]="!stream.isCameraOn? 'no-display': 'visible'" width="130px" height="100px" [srcObject]="stream.source.stream" autoplay playsinline></video>
                    <div *ngIf="!stream.isCameraOn" class="thumbnail-container short-thumbnail">
                        <div *ngIf="!allParticipants.get(stream.source.streamId).profilePicture" class="profile-image remote-profile">{{stream.source.name.charAt(0)}}</div>
                        <div *ngIf="allParticipants.get(streamId).profilePicture" class="profile-image remote-profile"><img [src]="allParticipants.get(stream.source.streamId).profileSrc" /></div>
                    </div>
                </div>
                <div class="remote-stream-info">
                    <div class="remote-stream-name">{{stream.source.name}}</div>
                    <div class="mic-container">
                        <mat-icon *ngIf="stream.isMicOn">mic</mat-icon>
                        <mat-icon *ngIf="!stream.isMicOn">mic_off</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- participants window -->
    <ng-container *ngIf="selectedSideWindow == participantsWindow">
        <app-participant [allParticipants]="allParticipants" [activeParticipant]="activeParticipants" [type]="'student'" [selfParticipant]="selfParticipant" (closeWindow)="closeSideWindow()">
        </app-participant>
    </ng-container>

    <!-- for chat -->
    <div [ngClass]="selectedSideWindow == chatWindow? 'visible': 'no-display'">
        <app-chat [allParticipants]="allParticipants" [selfId]="streamId" (closeWindow)="closeSideWindow()" (sendMsg)="sendChatMessage($event)" [teacher]="teacher">
        </app-chat>
    </div>
</div>

<div *ngIf="showLoader && !isConnectionDropped" class="spinner column-flex overlay" (click)="false; $event.stopPropagation();">
    <mat-progress-spinner mode="indeterminate">
    </mat-progress-spinner>
    <div class="reconnecting-dialog">Reconnecting</div>
</div>
<div *ngIf="isConnectionDropped" class="spinner column-flex overlay">
    <app-rejoin-dialog (rejoin)="reJoin()" (end)="end()"></app-rejoin-dialog>
</div>