import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings/settings.component';
import { SettingsDetailComponent } from './settings-detail/settings-detail.component';
import { MaterialModule } from 'src/app/material/material.module';
import { CrudSettingsComponent } from './crud-settings/crud-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonSettingsComponent } from './common-settings/common-settings.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedComponentsModule } from 'src/app/shared-components/shared-components.module';


@NgModule({
  declarations: [SettingsComponent, SettingsDetailComponent, CrudSettingsComponent, CommonSettingsComponent],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule
  ],
  exports:[CommonSettingsComponent]
})
export class SettingsModule { }
