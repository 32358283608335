import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { BehaviorSubject } from 'rxjs';
import { Parent } from 'src/app/models/parent.model';
import { switchMap } from 'rxjs/operators';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ParentService {
  private parent$$ : BehaviorSubject<Parent> = new BehaviorSubject<Parent>(null);
  private children$$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public get parent$() {
    return this.parent$$.asObservable();
  }

  public get children$() {
    return this.children$$.asObservable();
  }

  public parentRoute() {
    return environment.apiUrl + 'parent/get';
  }

  public studentInvitation() {
    return environment.apiUrl + 'class/student-invitations/get';
  }

  public acceptInviteRoute(id) {
    return environment.apiUrl + 'class/assign-student/' + id;
  }

  public childrenRoute() {
    return environment.apiUrl + 'parent/child/get';
  }

  public parentPinRoute() {
    return environment.apiUrl + 'parent/verify-pin';
  }

  public studentPinRoute() {
    return environment.apiUrl + 'student/login';
  }

  constructor(
    public crud: CrudService,
    public localstorage: LocalStorageService,
    private snackbar: SnackbarService) { }


  getParentDetails() {
    return this.crud.get(this.parentRoute());
  }

  setParentDetails(detail) {
    this.parent$$.next(detail);
  }

  getStudentInvitation() {
    return this.crud.get(this.studentInvitation());
  }

  acceptStudentInvitation(id, body) {
    return this.crud.post(this.acceptInviteRoute(id), body)
  }

  getChildren() {
     this.crud.get(this.childrenRoute()).subscribe(
       (child) => {
         this.children$$.next(child);
         this.getBase64Image(child);
        },
       (err) => {
         this.snackbar.openSnackbar('unable to get profile details at the moment');
       }
     );
  }

   getBase64Image(children) {
    children.forEach(child => {
      this.crud.get(child.profilePicture).subscribe(
        (res) =>  {
          child.profilePicture = res;
        }),
        (error) =>  error;
    })
    this.children$$.next(children);
  }

  getbase64(parent): Parent {
     this.crud.get(parent.profilePicture).subscribe(
      (res) => {
        parent.profilePicture = res;
        this.parent$$.next(parent);
        return parent
      },
      (error) => console.log(error)
    )
    return parent
  }

  verifyParentPin(body) {
    return this.crud.post(this.parentPinRoute(), body);
  }

  verifyStudentPin(body) {
    this.localstorage.setItem('studentID', body.id);
    return this.crud.post(this.studentPinRoute(), body);
  }

}
