import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberValidationService {

  constructor() { }

  isAlreadyInvitedNumber(numberList, number): boolean {
    if(numberList.length > 0) {
      number = number.split(' ')[0]
      const alreadyInvited = numberList.filter(no => no.indexOf(number.trim()) === 0);
      return alreadyInvited.length > 0; 
    } 
    return false
  }

  isInvalidNumber(numberList, number): boolean {
    if(numberList.length > 0) {
      const invalid =  numberList.filter(no => no.indexOf(number.trim()) === 0);
      return invalid.length > 0; 
    }
    return false;
  }
}
