<div *ngIf="childrenDetialsFetched">
    <mat-sidenav-container autosize>
        <mat-sidenav #sidenav mode="side" opened [fixedInViewport]="true">
            <div class="sidenav">
                <div class="img">
                    <div class="circle"></div>
                    <div class="txt">shiksha</div>
                </div>

                <div *ngFor="let tab of tabs; let i=index" class="tabs" (click)="select(i)" [ngClass]="{'active' : selected === i}">
                    <div> <img [src]=tab.img /></div>
                    <div class="name">{{tab.name | uppercase}}</div>
                </div>
            </div>
        </mat-sidenav>

        <mat-sidenav-content class="student-theme" *ngIf="parentDetail">
            <ng-container *ngIf="!selected">
                <div class="header">
                    <div>
                        Welcome, {{ parentDetail.name | titlecase}}
                    </div>
                    <div class="btn">
                        <button mat-flat-button color="primary" (click)="addStudent()">ADD CHILD</button>
                    </div>
                </div>
            </ng-container>

            <div class="content">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>

    </mat-sidenav-container>
</div>