<div class="notice-wrapper">
    <div class="head">Notification</div>

    <div class="box">
        <div *ngFor="let notice of notification" class="notification ">
            <ng-container [ngSwitch]="notice.type">
                <ng-container *ngSwitchCase="'warning'">
                    <div class="warning">
                        <div class="img"> <img src="../../../../assets/alert-marked.svg"/></div>
                        <div class="header">
                            <div class="text">
                                <div class="title">{{notice.title}}</div>
                                <div class="date"> | {{notice.date}}</div>
                            </div>
                            <div class="subheader">
                                <div class="quarter">
                                    <div class="txt">Quarter:</div>
                                    <div class="value">{{notice.quarter}}</div>
                                </div>
                                <div class="amount">
                                    <div class="txt">Amount:</div>
                                    <div class="value">{{notice.amount}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
        
                <ng-container *ngSwitchCase="'assignment'">
                    <div class="assignment">
                        <div> <img src="../../../../assets/assignment-marked.svg"/></div>
                        <div class="header">
                            <div class="text">
                                <div class="title">{{notice.title}}</div>
                                <div class="date"> | {{notice.date}}</div>
                            </div>
                            <div class="subtitle">
                                <div class="class">
                                    <div class="txt">Class:</div>
                                    <div class="value">{{notice.class}}</div>
                                </div>
                                <div class="topic">
                                    <div class="txt">Topic:</div>
                                    <div class="value">{{notice.topic}}</div>
                                </div>
                                <div class="marks">
                                    <div class="txt">Total Marks</div>
                                    <div class="value">{{notice.totalMarks}}</div>
                                </div>
                            </div>
                            <div class="submission">
                                <div class="date">Date of Submission:</div>
                                <div class="value">{{notice.submissionDate}}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
        
                <ng-container *ngSwitchCase="'upcoming'">
                    <div class="upcoming">
                        <div> <img src="../../../../assets/notification.svg"/></div>
                        <div class="header">
                            <div class="text">
                                <div class="title">{{notice.title}}</div>
                                <div class="date"> | {{notice.date}}</div>
                            </div>
                            <div class="subtitle">
                                <div class="class">
                                    <div class="txt">Class:</div>
                                    <div class="value">{{notice.class}}</div>
                                </div>
                                <div class="topic">
                                    <div class="txt">Topic:</div>
                                    <div class="value">{{notice.topic}}</div>
                                </div>
                                <div class="topic">
                                    <div class="txt">Conducted by:</div>
                                    <div class="value">{{notice.conducting}}</div>
                                </div>
                            </div>
                            <div class="submission">
                                <div class="date">Date of Submission:</div>
                                <div class="value">{{notice.submissionDate}}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
        
                <ng-container *ngSwitchCase="'announcment'">
                    <div class="announcment">
                        <div> <img src="../../../../assets/announcement.svg"/></div>
                        <div class="header">
                            <div class="text">
                                <div class="title">{{notice.title}}</div>
                                <div class="date"> | {{notice.date}}</div>
                            </div>
                            <div class="announce-topic">{{notice.topic}}</div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'message'">
                    <div class="message">
                        <div> <img src="../../../../assets/message-marked.svg"/></div>
                        <div class="header">
                            <div class="msg">
                                <div class="txt">Message <span class="length">(1)</span></div>
                                <div class="date"> | {{notice.date}}</div>
                            </div>
                            <div class="detail">
                                <div class="from">
                                    <div class="txt">From:</div>
                                    <div class="value">{{notice.from}}</div>
                                </div>
                                <div class="to">
                                    <div class="txt">Message:</div>
                                    <div class="value">{{notice.message}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    
        
    </div>
</div>