<div class="view-docs-wrapper">
    <div class="text">
        <div>Preview: {{title | titlecase}} ({{curFileIndex + 1}} / {{ files.length}})</div>
        <div class="img" (click)="close()"><img src="../../../assets/close.svg"/></div>
    </div>

    <div class="caraousel">
        <app-doc-caraousel [files]="files" (index)="onIndexChange($event)"></app-doc-caraousel>
    </div>
</div>
