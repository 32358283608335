import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { School } from '../models/school.models';
import { flatMap, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SelectedSchoolService {
  private selectedSchool$$: BehaviorSubject<School> = new BehaviorSubject<School>(null);
  private teachersList$$: BehaviorSubject<any> = new BehaviorSubject<any[]>([]);
  private schoolStaff$$: BehaviorSubject<any> = new BehaviorSubject<any[]>([]);
  private schoolStudents$$: BehaviorSubject<any> = new BehaviorSubject<any[]>([]);
  public schoolId$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private destroy$$ = new Subject<any>();

  public get selectedSchool$(): Observable<School> {
    return this.selectedSchool$$.asObservable();
  }

  public get schoolId$(): Observable<any> {
    return this.schoolId$$.asObservable();
  }

  public get schoolStudents$(): Observable<any> {
    return this.schoolStudents$$.asObservable();
  }

  public schoolRoute(id) {
    return environment.apiUrl + 'school/get/' + id;
  }

  public get teachersList$() {
    return this.teachersList$$.asObservable();
  }

  public get schoolStaff$() {
    return this.schoolStaff$$.asObservable();
  }

  get schoolTeachers() {
    return environment.apiUrl + 'school/teacher/get/';
  }

  get schoolStaff() {
    return environment.apiUrl + 'school/staff/get/';
  }

  get schoolStudents() {
    return environment.apiUrl + 'school/parent/get/';
  }


  constructor(private crud: CrudService) { }

  selectSchool(school) {
    this.selectedSchool$$.next(school);
    this.schoolId$$.next(school.id);
  }

  getSchoolDetail(id) {
    return this.crud.get(this.schoolRoute(id));
  }

  getAllSchoolTeachers() {
    this.crud.get(this.schoolTeachers + this.schoolId$$.value).pipe(
      takeUntil(this.destroy$$)
    )
    .subscribe(teacher => {
      this.teachersList$$.next(teacher);
    })
  }

  getAllSchoolStaff() {
    this.crud.get(this.schoolStaff + this.schoolId$$.value).pipe(
      takeUntil(this.destroy$$)
    )
    .subscribe(staff => {
      this.schoolStaff$$.next(staff);
    })
  }

  getAllSchoolStudents() {
    this.crud.get(this.schoolStudents + this.schoolId$$.value).pipe(
      takeUntil(this.destroy$$)
    )
    .subscribe(staff => {
      this.schoolStudents$$.next(staff);
    })
  }
  changeSchoolStatus(){
    // this.crud.patch
  }

  updateSchool(schoolId, body) {
    return this.crud.patch(environment.apiUrl + `school/edit/${schoolId}`, body);
  }

  clearList() {
    this.teachersList$$.next(null);
    this.schoolStaff$$.next(null);
    this.schoolStudents$$.next(null);
  }

}
