import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, map, filter, catchError } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {

  public route(number, type, theme, schoolID?): string {
    switch(type.toLowerCase()) {
      case 'teacher': return environment.apiUrl + 'school/teacher/search?term=' + `${number}`;
      case 'admin': return environment.apiUrl + 'school/staff/search?term=' + `${number}`;
      case 'co-teacher': return environment.apiUrl + 'school/teacher/search?term=' + `${number}` + '&school-id=' + `${schoolID}`;
      case 'student': return theme === 'admin' ? environment.apiUrl + '/class/parent/search?term=' + `${number}` : environment.apiUrl + '/class/parent/search?term=' + `${number}` + '&school-id=' + `${schoolID}` ;
    }
  }

  constructor(private http: HttpClient, public locatstorage: LocalStorageService) { }

  search(value, type, id?) {
    const theme = this.locatstorage.getItem('type')
    return this.http.get(this.route(value, type, theme, id))
    .pipe(
      catchError(err => {
        return of({ results: null});
      })
    )
  }
}
