import { Component, OnInit, Input } from '@angular/core';
import { ChildWorkActivity } from 'src/app/models/child-work-activity-model';
import { ACTIVITY_TYPES, TODAY, OVERDUE } from 'src/app/Constants/Constant';

const messages = {
  overdue: {
    assignments: 'You have missed one assignment submission date',
    tests: 'You have missed one test',
    live_classes: 'You have missed one video class',
    materials: 'You have missed one material read date'
  },
  today: {
    assignments: 'Submit assignment',
    tests: 'Appear for test',
    live_classes: 'Attent video class',
    materials: 'Read Material'
  },
  upcoming: {
    assignments: 'Assignment',
    tests: 'Test',
    live_classes: 'Video class',
    materials: 'Material'
  }
};

const cardIcon = {
  overdue: {
    assignments: 'assets/mywork-assignment.svg',
    tests: 'assets/mywork-test.svg',
    live_classes: 'assets/mywork-videoclass.svg',
    materials: 'assets/mywork-assignment.svg'
  },
  other: {
    assignments: 'assets/mywork-assignment-black.svg',
    tests: 'assets/mywork-test-black.svg',
    live_classes: 'assets/mywork-videoclass-black.svg',
    materials: 'assets/mywork-assignment-black.svg'
  },
};

@Component({
  selector: 'app-my-work-card',
  templateUrl: './my-work-card.component.html',
  styleUrls: ['./my-work-card.component.scss']
})
export class MyWorkCardComponent implements OnInit {

  constructor() { }

  message: string;
  @Input() activity: ChildWorkActivity;
  @Input() category: string;
  presentDate = new Date();
  cardIcon: string;
  activityTypes = { ...ACTIVITY_TYPES };
  today = TODAY;
  overdue = OVERDUE;

  ngOnInit(): void {
    this.message = messages[this.category][this.activity.activityType];
    if(this.category === this.overdue) {
      this.cardIcon = cardIcon[this.category][this.activity.activityType];
    }else {
      this.cardIcon = cardIcon.other[this.activity.activityType];
    }
  }

}
