import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailregex, nameregex } from '../../Constants/Constant';
import { Router } from '@angular/router';
import { UserApiService } from 'src/app/services/api/user.api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  Imageurl: string | ArrayBuffer;
  profileForm: FormGroup
  @Input() mobileNo: string;
  isImageUploaded: boolean;
  isSubmitted = false;
  genders = ['Male', 'Female'];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public storeUser: UserService,
    private userService: UserApiService,
    private _snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    // this.profileForm = this.fb.group({
    //   'parentName': [null, [Validators.required, Validators.pattern(nameregex)]],
    //   'email': [null, [Validators.required, Validators.pattern(emailregex)]],
    //   'pin': [null, [Validators.required, Validators.pattern('^[0-9]{4}$')]]
    // });

    this.profileForm = this.fb.group({
      name: [null, [Validators.required, Validators.pattern(nameregex)]],
      gender: [null, [Validators.required]],
      username: [null, [Validators.required]],
    });
  }

  getErrorName() {
    return this.profileForm.get('name').hasError('required') ? 'Name is required' :
      this.profileForm.get('name').hasError('pattern') ? 'Not a valid name' : null;
  }

  // getErrorEmail() {
  //   return this.profileForm.get('email').hasError('required') ? 'Field is required' :
  //     this.profileForm.get('email').hasError('pattern') ? 'Not a valid email address' : null;
  // }

  // getErrorPin() {
  //   return this.profileForm.get('pin').hasError('required') ? 'Field is required' :
  //     this.profileForm.get('pin').hasError('pattern') ? 'Pin must be a 4 digit number' : null;
  // }

  onImageUpload(url) {
    this.Imageurl = url ? url : null;
    this.isImageUploaded = url ? true : false;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.profileForm.valid && this.Imageurl) {
      const requestBody = {
        name: this.profileForm.controls['name'].value.trim(),
        username: this.profileForm.controls['username'].value.trim(),
        gender: this.profileForm.controls['gender'].value.toString(),
        profilePicture: this.Imageurl,
        mobileNumber: this.mobileNo
      }
      this.userService.studentSignUp(requestBody).subscribe(
        (res) => {
          res.type = 'student';
          this.storeUser.storeUserInCache(res);
          this._snackbar.openSnackbar('successfully created your profile!!');
          this.router.navigate(['student', 'dashboard']);
        }, (err) => {
          this.isSubmitted = false;
          this._snackbar.openSnackbar(err.error.error);
        }
      );
      // this.userService.login('parent', requestBody).subscribe(
      //   (res) => {
      //     res.type = 'student';
      //     this.storeUser.storeUserInCache(res);
      //     this._snackbar.openSnackbar('successfully created your profile!!');
      //     this.router.navigate(['./', 'parent', 'dashboard']);
      //   }, (err) => {
      //     this.isSubmitted = false;
      //     this._snackbar.openSnackbar(err.error.error);
      //   }
      // );
      return;
    }
    if (!this.Imageurl) {
      this.isImageUploaded = false;
    }
    this.isSubmitted = false;
    this._snackbar.openSnackbar('please fill all details');
  }
}
