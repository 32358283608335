import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddQuestionComponent } from '../add-question/add-question.component';
import { Router } from '@angular/router';
import { PreviewComponent } from '../preview/preview.component';
import { QuestionModel } from 'src/app/models/test-questions.models';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-test-question',
  templateUrl: './test-question.component.html',
  styleUrls: ['./test-question.component.scss']
})
export class TestQuestionComponent implements OnInit {
  @Input() questionList: QuestionModel[] = [];
  @Input() isQuestionCreated;
  @Input() testDetail;
  @Input() isAdminTheme = false;
  @Input() totalTestMarks;
  @Output() onSaveTest = new EventEmitter();
  @Output() onQuestionCreated = new EventEmitter();


  editAsset: string;
  constructor(
    public dialog: MatDialog,
    public snackbar: SnackbarService,
    public router: Router) { }

  ngOnInit(): void {
    this.editAsset = this.isAdminTheme ? '../../../assets/admin-edit.svg' : '../../../assets/edit.svg';
    console.log(this.isQuestionCreated);
    if (this.questionList.length > 0) {
      this.onQuestionCreated.emit('true');
    }
  }

  addQuestion() {
    let totalQuestionMarks;
    if (this.questionList.length > 0) {
      totalQuestionMarks = this.questionList.map(obj => obj.marks).reduce((total, marks) => total + marks);
    } else {
      totalQuestionMarks = 0;
    }
    const dialogRef = this.dialog.open(AddQuestionComponent, {
      width: '75%',
      height: '100%',
      data: {
        questionLength: this.questionList.length + 1,
        isAdminTheme: this.isAdminTheme,
        totalQuestionMarks,
        totalTestMarks: this.totalTestMarks,
      },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: [this.isAdminTheme ? 'admin-theme' : 'teacher-theme', 'full-width'],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.questionList.push(res.question);
      }
      if (res && res.type === 'add') {
        this.addQuestion();
        return;
      }
    });
  }

  preview() {
    if (!this.questionList.length) {
      this.snackbar.openSnackbar('Please add questions first!!!');
      return;
    }
    const dialogRef = this.dialog.open(PreviewComponent, {
      width: '100%',
      height: '100%',
      data: { question: this.questionList, testDetail: this.testDetail },
      panelClass: ['teacher-theme', 'width-class'],
      autoFocus: false
    })
  }

  save() {
    if (!this.questionList.length) {
      this.snackbar.openSnackbar('Please add questions first!!!');
      return;
    }
    this.onSaveTest.emit(this.questionList);
  }

  edit(i) {
    let totalQuestionMarks;
    if (this.questionList.length > 0) {
      totalQuestionMarks = this.questionList.map((obj, index) => {
        return index === i ? 0 : obj.marks;
      }).reduce((total, marks) => total + marks);
    } else {
      totalQuestionMarks = 0;
    }
    const dialogRef = this.dialog.open(AddQuestionComponent, {
      width: '75%',
      height: '100%',
      data: {
        question: this.questionList[i], questionLength: i + 1,
        isAdminTheme: this.isAdminTheme,
        totalQuestionMarks,
        totalTestMarks: this.totalTestMarks,
      },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: [this.isAdminTheme ? 'admin-theme' : 'teacher-theme', 'full-width'],
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.action && res.action === 'delete') {
        this.questionList = this.questionList.filter((ques, index) => index !== i);
        return;
      }
      if (res) {
        this.questionList[i] = res.question;
      }
      if (res && res.type === 'add') {
        this.addQuestion();
      }
    });
  }

}
