import { Component, OnInit } from '@angular/core';
import { USER_TYPES, USER_THEMES } from '../Constants/user-types-constants';
import { Router } from '@angular/router';
import { BASE_ROUTES } from '../Constants/route-constants';
import { LocalStorageService } from '../services/local-storage.service';
import { LoginService } from './services/login.service';
import { UserService } from '../services/user.service';
import { SnackbarService } from '../services/snackbar.service';
import { USER_TYPE_KEY, SESSION_ID } from '../Constants/local-storage-keys';
import { FormGroup, Validators, FormControl } from '@angular/forms';

const teacherResponsiblities = [
  'Share class materials',
  'Simplify communication',
  'Improve student engagement',
  'Share digital assignments'
];

const adminResponsibilities = [
  'Create school',
  'Monitor school performance',
  'Improve school engagement',
  'Share views and announcements'
];

const studentResponsibilities = [
  'View class materials',
  'Interact with classmates & teachers',
  'Upload your assignments',
  'View your grades & performance'
];

const superAdminResponsibilities = [
  'View app analytics',
  'Manage cloud library',
  'Manage app data',
  'Control app access'
];

export const PIN_MODE = 'PIN';
export const OTP_MODE = 'OTP';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  responsibilities: any;
  userName: string;

  userTypes = USER_TYPES;
  themes = USER_THEMES;

  theme = USER_THEMES.student.name;
  themeColor = this.themes.student.background;
  storeUserType = USER_TYPES.student;
  baseRoute = BASE_ROUTES.student;
  width = window.innerWidth;

  isOtp = false;
  phoneNumber = '';
  invalidNumber = false;
  hasError = false;
  isNumberError = false;
  isOTPError: boolean;
  otpError: string;
  otpTimer;
  otp = '';
  signUp = false;
  isSubmitted = false;

  loginForm: FormGroup;

  isPin = false;
  pin: number;
  isPinError = false;
  pinError = '';
  loginMode = '';
  constructor(
    private router: Router,
    private localStorageSvc: LocalStorageService,
    private authService: LoginService,
    private userService: UserService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
    const isLoggedIn = this.localStorageSvc.getItem('token') ? true : false;
    const routeList = this.router.url.split('/');
    this.baseRoute = routeList[1];
    switch (this.baseRoute) {
      case BASE_ROUTES.admin:
        if (isLoggedIn) {
          this.router.navigate([BASE_ROUTES.admin, 'dashboard']);
        }
        this.theme = this.themes.admin.name;
        this.themeColor = this.themes.admin.background;
        this.storeUserType = this.userTypes.admin;
        break;
      case BASE_ROUTES.teacher:
        if (isLoggedIn) {
          this.router.navigate([BASE_ROUTES.teacher, 'dashboard']);
        }
        this.theme = this.themes.teacher.name;
        this.themeColor = this.themes.teacher.background;
        this.storeUserType = this.userTypes.teacher;
        break;
      case BASE_ROUTES.superAdmin:
        if (isLoggedIn) {
          this.router.navigate([BASE_ROUTES.superAdmin, 'dashboard']);
        }
        this.theme = this.themes.superAdmin.name;
        this.themeColor = this.themes.superAdmin.background;
        this.storeUserType = this.userTypes.superAdmin;
        this.loginForm = new FormGroup({
          email: new FormControl(null, [Validators.required, Validators.email]),
          password: new FormControl(null, [Validators.required]),
        });
        break;
      default:
        if (isLoggedIn) {
          this.router.navigate([BASE_ROUTES.student, 'dashboard']);
        }
        this.theme = this.themes.student.name;
        this.themeColor = this.themes.student.background;
        this.storeUserType = this.userTypes.student;
        this.baseRoute = BASE_ROUTES.student;
        break;
    }
    if (this.storeUserType === this.userTypes.admin) {
      this.responsibilities = adminResponsibilities;
      this.userName = 'For School Administrator';
    } else if (this.storeUserType === this.userTypes.teacher) {
      this.responsibilities = teacherResponsiblities;
      this.userName = 'For Teachers';
    } else if (this.storeUserType === this.userTypes.superAdmin) {
      this.responsibilities = superAdminResponsibilities;
      this.userName = 'For Super Admins';
    }
    else {
      this.responsibilities = studentResponsibilities;
      this.userName = 'For Parents & Students';
    }
  }

  enterPhoneNumber(num: string) {
    this.hasError = false;
    this.phoneNumber = num;
  }

  startOTPTimer() {
    this.authService.startOTPTimer();
    this.authService.otpTimer$.subscribe(val => {
      this.otpTimer = val;
    });
  }

  login() {
    if (this.phoneNumber.length !== 10 || this.invalidNumber) {
      this.hasError = true;
      return;
    }
    this.hasError = false;
    this.sendOtp();
  }

  sendOtp() {
    this.localStorageSvc.setItem(USER_TYPE_KEY, this.storeUserType);
    this.isSubmitted = true;
    this.authService.sendOtp(this.phoneNumber).subscribe((result: any) => {
      this.isOtp = true;
      this.snackbar.openSnackbar('OTP succesfully sent');
      this.userService.setSessionId(SESSION_ID, result.sessionID);
      this.isSubmitted = false;
      this.startOTPTimer();
    }, (err) => {
      this.isSubmitted = false;
      this.isNumberError = true;
      this.hasError = true;
    });
  }

  verify(otp) {
    this.isSubmitted = true;
    const sessionID = this.localStorageSvc.getItem(SESSION_ID);
    if (!this.otp || this.otp.toString().length === 0) {
      this.isOTPError = true;
      this.otpError = 'Please enter valid OTP';
      this.isSubmitted = false;
      return;
    }
    this.authService.verify(this.otp, sessionID, this.storeUserType, this.phoneNumber)
      .subscribe((res: any) => {
        this.snackbar.openSnackbar('OTP verified');
        if (res.isNew === false) {
          res.type = this.storeUserType;
          this.userService.storeUserInCache(res);
          if (res.schoolGroups && res.schoolGroups.length > 0) {
            this.router.navigate(['/', this.baseRoute, 'choose']);
          } else {
            this.router.navigate(['/', this.baseRoute, 'dashboard']);
          }
        } else {
          this.signUp = true;
        }
      }, (err) => {
        this.isSubmitted = false;
        this.snackbar.openSnackbar(err.error.error);
        this.isOTPError = true;
        this.otpError = 'Invalid OTP';
      });
  }

  back() {
    if (this.isPin || this.isOtp) {
      this.authService.clearInterval();
      this.otp = '';
      this.isOtp = false;
      this.isOTPError = false;

      this.pin = null;
      this.isPin = false;
      this.isPinError = false;
      this.pinError = '';
    } else {
      this.loginMode = '';
    }
  }

  onChange(otp) {
    this.otp = otp;
    this.isOTPError = this.isOTPError ? false : null;
  }

  changePin(pin) {
    this.pin = pin;
    this.isPinError = this.isPinError ? false : null;
  }

  onProfileCreate(response) {
    this.userService.storeUserType(this.storeUserType);
    this.router.navigate(['/', this.baseRoute, 'dashboard']);
  }

  superAdminLogin() {
    if (this.loginForm.valid) {
      this.isSubmitted = true;
      this.authService.superAdminLogin(this.loginForm.controls.email.value, this.loginForm.controls.password.value)
        .subscribe((res: any) => {
          res.type = this.storeUserType;
          this.userService.storeUserInCache(res);
          this.router.navigate(['/', this.baseRoute, 'dashboard']);
        }, (err) => {
          this.isSubmitted = false;
          if (err.error.error) {
            this.snackbar.openSnackbar(err.error.error);
            return;
          }
          this.snackbar.openSnackbar('Unable to login at the moment.');
        });
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  choosePinLogin() {
    if (this.phoneNumber.length !== 10 || this.invalidNumber) {
      this.hasError = true;
      return;
    }
    this.hasError = false;
    this.isPin = true;
  }

  verifyPin() {
    if (!this.pin || this.pin.toString().length === 0) {
      this.isPinError = true;
      this.pinError = 'Please enter valid PIN';
      this.isSubmitted = false;
      return;
    }
    this.authService.loginWithPin(this.pin.toString(), this.storeUserType, this.phoneNumber)
      .subscribe((res: any) => {
        this.snackbar.openSnackbar('Login Successful');
        res.type = this.storeUserType;
        this.userService.storeUserInCache(res);
        if (res.schoolGroups && res.schoolGroups.length > 0) {
          this.router.navigate(['/', this.baseRoute, 'choose']);
        } else {
          this.router.navigate(['/', this.baseRoute, 'dashboard']);
        }
      }, (err) => {
        if (err.error.error) {
          const message = err.error.error as string;
          const msg = message.split(' ').slice(1).join(' ');
          console.log(msg);
          this.isPinError = true;
          this.pinError = msg;
        } else {
          this.snackbar.openSnackbar('unable to login at the moment.');
        }
        this.isSubmitted = false;
      });
  }

  selectPinMode() {
    this.loginMode = PIN_MODE;
  }

  selectOtpMode() {
    this.loginMode = OTP_MODE;
  }

  loginUser() {
    if (this.loginMode === PIN_MODE) {
      this.choosePinLogin();
    } else {
      this.login();
    }
  }

  verifyUser(data) {
    if (this.loginMode === PIN_MODE) {
      this.verifyPin();
    } else {
      this.verify(data);
    }
  }
}
