<ng-container *ngIf="!courses; else coursesWrapper">
    <div class="loader">
        <app-spinner></app-spinner>
    </div>
</ng-container>
<ng-template #coursesWrapper>
    <div class="course-list-wrapper">
        <div class="header">
            <div class="txt">Courses</div>
            <div class="action">
                <div *ngIf="schools && schools.length > 0" class="filter">
                    <ng-select (change)="filterBySchool($event)" [searchable]="false" [clearable]="false" [(ngModel)]="selectedSchool">
                        <ng-option *ngFor="let school of schools" [value]="school.id">{{school.name}}</ng-option>
                    </ng-select>
                </div>
                <div class="search-box">
                    <input [disabled]="updatingState" matInput placeholder="Search Courses" [formControl]="searchTerm">
                    <mat-icon class="search-icon">search</mat-icon>
                </div>
            </div>
        </div>
        <div class="courses-content bordered-box">
            <app-spinner class="update-loader" *ngIf="updatingState"></app-spinner>
            <ng-container *ngIf="isLoading else coursesView">
                <app-spinner></app-spinner>
            </ng-container>
            <ng-template #coursesView>
                <ng-container *ngIf="courses && courses.length > 0 else coursesPlaceholder">
                    <app-cards [updatingState]="updatingState" [type]="'course'" [lists]="courses" (selectedlist)="subscribeCourse($event)"></app-cards>
                </ng-container>
                <ng-template #coursesPlaceholder>
                    <div class="course-placeholder">
                        <ng-container *ngIf="!searchTerm.value else emptySearch">
                            <h3 class="title">You have not subscribed to any courses</h3>
                            <p class="sub-content">You can search courses using the top right search box</p>
                        </ng-container>
                        <ng-template #emptySearch>
                            <h3 class="title">Courses not found</h3>
                        </ng-template>
                    </div>
                </ng-template>
            </ng-template>
        </div>
    </div>
</ng-template>