<ng-container *ngIf="!toCompress; else compress">
    <div class="container bordered-box">
      <div class="cropper-container">
        <div class="img" *ngIf="isImageLoaded">
            <img [src]="url ? url : '../../../assets/group.svg'" />
            <div class="text">Preview</div>
        </div>

        <div class="image">
            <image-cropper
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true"
              [aspectRatio]="aspectRatio"
              [resizeToWidth]="croppedWidth"
              [resizeToHeight]="croppedHeight"
              [onlyScaleDown]="'true'"
              (imageCropped)="imageCropped($event)"
              (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>
      </div>
      <div class="btn" *ngIf="isImageLoaded">
          <div class="cancel"><button mat-flat-button (click)="doneCropping('cancel')" [ngClass]="theme">CANCEL</button></div>
          <div class="done"><button mat-flat-button (click)="doneCropping('done')" [ngClass]=getBtnClass()>SAVE CHANGES</button></div>
      </div>
    </div>
</ng-container>

<ng-template #compress>
    <div class="image">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="aspectRatio"
          [resizeToWidth]="croppedWidth"
          [resizeToHeight]="croppedHeight"
          [onlyScaleDown]="'true'">
        </image-cropper>
    </div>
</ng-template>
