import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {CrudService} from 'src/app/services/crud.service';
import {docsPreviewList} from 'src/app/teachers/post-details/post-details.component';
import {NgbCarousel, NgbSlideEvent} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-doc-caraousel',
  templateUrl: './doc-caraousel.component.html',
  styleUrls: ['./doc-caraousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocCaraouselComponent implements OnInit {

  @Input() files: docsPreviewList[];
  @Output() index = new EventEmitter();
  @ViewChild('carousel') carousel: NgbCarousel;

  constructor(
    public crud: CrudService,
  ) { }

  ngOnInit(): void { }

  changedIndex(event: NgbSlideEvent) {
    this.index.emit(parseInt(event.current.replace('slideId_', ''), 10));
  }

  public moveToNext() {
    this.carousel.next();
  }

  public moveToPrev() {
    this.carousel.prev();
  }

}
