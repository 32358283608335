import { Component, OnInit, ViewChild, Input, OnChanges, AfterViewInit } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SendMessageComponent } from '../send-message/send-message.component';
import { dateFormat, pageSizeOptions, paginatorPageSize, ROLE_TYPE } from 'src/app/Constants/Constant';
import { ActivatedRoute, Router } from '@angular/router';
import { ReviewAssignmentComponent } from '../review-assignment/review-assignment.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-post-detail-table',
  templateUrl: './post-detail-table.component.html',
  styleUrls: ['./post-detail-table.component.scss']
})
export class PostDetailTableComponent implements OnInit, AfterViewInit {

  sortedData: any;
  @Input() displayedColumns: string[];
  @Input() transactions;
  @Input() type;
  @Input() disableActions = false;
  dataSource;
  pageSizeOptions: number[] = pageSizeOptions;
  pageSize = paginatorPageSize;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  selection = new SelectionModel(true, []);
  dateFormat;

  @Input() isCourseActivity = false;
  @Input() courseActivityID: string;
  public theme;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private localstorage: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.dateFormat = dateFormat;
    this.dataSource = new MatTableDataSource(this.transactions);
    this.theme = this.localstorage.getItem(ROLE_TYPE);
  }


  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.transactions);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  sortData(sort: Sort) {
    const data = this.transactions.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  evaluateAssignment(row) {
    if (row.status === 'pending' || this.disableActions) {
      return;
    }
    const type = row.evalStatus && row.evalStatus === 'pending' ? 'review' : 'view';
    if (this.isCourseActivity) {
      if (type === 'review') {
        this.router.navigate(['school', type, 'course-activity', 'evaluate', this.courseActivityID, row.studentID, 'true']);
      } else {
        this.router.navigate(['school', type, 'course-activity', 'evaluate', this.courseActivityID, row.studentID, 'false']);
      }
      return;
    }
    if (type === 'review') {
      this.router.navigate(['teacher', type, 'evaluate', row.assignmentID, row.studentID, 'true']);
    } else {
      this.router.navigate(['teacher', type, 'evaluate', row.assignmentID, row.studentID, 'false']);
    }
  }

  sendMsg(list?) {
    if (this.disableActions) {
      return;
    }
    let lists = [];
    if (!list) {
      lists = this.selection.selected;
    } else {
      lists.push(list);
    }
    const dialog = this.dialog.open(SendMessageComponent, {
      width: '46%',
      height: '100%',
      data: { studentList: lists },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['teacher-theme', 'width-class'],
      autoFocus: false
    });
  }

}
