<div class="file-canvas-wrapper">
    <div class="canvas-wrapper" [ngClass]="canEditFile ? 'no-scroll' : ''" *ngIf="fileType === this.FILE_TYPES.IMAGE">
        <canvas (scroll)="$event.stopPropagation();$event.preventDefault()" #canvas width="600px" height=1000></canvas>
    </div>
    <div class="pdf-canvas-wrapper" [ngClass]="canEditFile ? 'no-scroll' : ''" #canvasWrapper *ngIf="fileType === this.FILE_TYPES.PDF"></div>
    <div class="pdf-canvas-wrapper" [ngClass]="canEditFile ? 'no-scroll' : ''" #canvasWrapper *ngIf="fileType === this.FILE_TYPES.VIDEO">
        <video controls controlsList="nodownload">
      <source [src]="file.url">
    </video>
    </div>
    <div class="placeholder" *ngIf="fileType === this.FILE_TYPES.OTHER">
        <div>Preview Not Available</div>
        <div class="title">{{file.name | titlecase}}</div>
        <a [href]="file.url" target="_blank">
            <img src="../../../assets/download-btn.svg" />
            <button>DOWNLOAD</button>
        </a>
    </div>
</div>
