import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Student } from 'src/app/models/student.models';
import { Router } from '@angular/router';
import { ChildClassService } from '../child-dashboard/services/child-class.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-child-list',
  templateUrl: './child-list.component.html',
  styleUrls: ['./child-list.component.scss'],
  encapsulation : ViewEncapsulation.None

})
export class ChildListComponent implements OnInit {

  childrenList : Student[] = [];
  @Input() smallContainer : boolean;
  @Input() set childrens(child) {
    this.childrenList = child;
  }

  constructor(public router: Router, public childService: ChildClassService, public localstorage: LocalStorageService) { }

  ngOnInit(): void {
    console.log(this.smallContainer)
  }

  gotToClass(child: Student) {
    this.localstorage.setItem('studentID', child.id);
    this.childService.setChildName(child.name);
    this.router.navigate(['parent', 'child', child.id]);
  }

}
