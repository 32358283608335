import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Sort, MatSort } from '@angular/material/sort';
import { schoolTeachers } from 'src/app/models/school-table.model';
import { MatPaginator } from '@angular/material/paginator';
import { pageSizeOptions, paginatorPageSize } from 'src/app/Constants/Constant';
import { FindSchoolUserRoleService } from 'src/app/services/find-school-user-role.service';

@Component({
  selector: 'app-admin-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  sortedData: any;
  @Input() displayedColumns: string[];
  @Input() transactions; 
  @Input() type;
  dataSource
  pageSizeOptions: number[] = pageSizeOptions;
  pageSize = paginatorPageSize;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  selection = new SelectionModel(true, []);

    constructor(public cd: ChangeDetectorRef,private userRole: FindSchoolUserRoleService) { }

    ngOnInit(): void { }
    ngOnChanges() {
      this.dataSource = new MatTableDataSource(this.transactions);
      this.cd.detectChanges();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      console.log(this.transactions,'school login student data')
      this.userRole.userRole$.subscribe(data => {
        console.log(data, 'user role in student school login')
      })
    }

    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
    }

    checkboxLabel(row?: schoolTeachers): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
    }
    sortData(sort: Sort) {
      const data = this.transactions.slice();
      if (!sort.active || sort.direction === '') {
        this.sortedData = data;
        return;
      }

      this.sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name': return this.compare(a.name, b.name, isAsc);
          default: return 0;
        }
      });
    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
