<ng-container *ngIf="loadingCourseDetails else courseDetails">
    <app-spinner></app-spinner>
</ng-container>
<ng-template #courseDetails>
    <div class="admin-theme detail-wrapper bordered-box">
        <div class="header">
            <div class="left">
                <div (click)="back()" class="close"> <img src="../../../assets/grey-back.svg" /></div>
                <div class="txt" *ngIf="selectedCourse">{{selectedCourse.name}}</div>
            </div>
            <div class="operations-container">
                <button [disabled]="(selectedCourse && selectedCourse.isPublished) || updatingState || (courseActivities && courseActivities.length < 1) || role === 'member'" mat-raised-button class="publish-button" [ngClass]="(selectedCourse && selectedCourse.isPublished) || updatingState ||(courseActivities && courseActivities.length < 1)? 'disabled' : ''"
                    (click)="publishCourse()">{{ publishButtonText | uppercase}}</button>
                <div class="action" [ngClass]="(selectedCourse && selectedCourse.isPublished) || updatingState || role === 'member'? 'disabled': ''" *ngIf="selectedCourse">
                    <button mat-icon-button (click)="select.open()" [disabled]="(selectedCourse && selectedCourse.isPublished) || updatingState">
                    <mat-label>ACTIONS</mat-label>
                    <mat-select [disabled]="(selectedCourse && selectedCourse.isPublished) || updatingState || role === 'member'" #select [formControl]="action">
                    <mat-option *ngFor="let action of actionsList" [value]="action">{{action}}</mat-option>
                    </mat-select>
                </button>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!loadingCourseActivities else spinner">
            <ng-container *ngIf="courseActivities.length > 0 else placeholder">
                <div class="activities">
                    <app-course-activities-table [draggable]="selectedCourse && !selectedCourse.isPublished" [reordering]="updatingState" [dataSource]="dataSource" [displayedColumns]="displayedColumns" (OnPositionChange)="updateOrder($event)" (OnEditCourseActivity)="editCourseActivity($event)"
                        (navigateToActivity)="navigateToActivity($event)" [courseID]="selectedCourse && selectedCourse.id" [disableActions]="(selectedCourse && selectedCourse.isPublished ) || disableTableActions"></app-course-activities-table>
                </div>
            </ng-container>
            <ng-template #placeholder>
                <div class="placeholder">
                    <p class="title">You have not added any course content yet</p>
                    <p class="sub-text">You can add contents using the action button above</p>
                </div>
            </ng-template>

        </ng-container>
        <ng-template #spinner>
            <app-spinner></app-spinner>
        </ng-template>
    </div>
</ng-template>
