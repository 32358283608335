<div class="info-wrapper">
    <div class="header">
        <svg xmlns="http://www.w3.org/2000/svg" width="103" height="26" viewBox="0 0 103 26">
          <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
              <text fill="#FFF" font-family="HugMeTight, Hug Me Tight" font-size="18">
                  <tspan x="32" y="18">shiksha</tspan>
              </text>
              <circle cx="12" cy="12" r="12" stroke="#FFF" stroke-width="2"/>
          </g>
        </svg>
    </div>

    <div class="container">
        <div class="text">{{userName}} </div>
        <div *ngFor="let responsibility of user" class="tasks">
            <div class="tick"><img src="../../../assets/combined-shape.svg" /></div>
            <div class="value">{{responsibility}}</div>
        </div>
    </div>

    <div class="footer teacher" *ngIf="userType === 'teacher'">
        <img src="../../../assets/teacher-icon.svg" />
    </div>

    <div class="footer admin" *ngIf="userType === 'admin'">
        <img src="../../../assets/admin-icon.svg" />
    </div>

    <div class="footer parent" *ngIf="userType === 'student'">
        <img src="../../../assets/student-icon.svg" />
    </div>
</div>
