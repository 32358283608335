import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';
import { FormDataService } from './form-data.service';

const ROOT_DIRECTORY = 'root';

@Injectable({
  providedIn: 'root'
})
export class CloudContentService {

  constructor(
    private crud: CrudService,
    private formData: FormDataService
  ) { }

  public getCloudDirectoryContent(folderId?) {
    if(!folderId) {
      folderId =  'root';
    }
    return this.crud.get(environment.apiUrl + `course/cloud/directory/${folderId}`);
  }

  public getCloudFileContent(fileId) {
    return this.crud.get(environment.apiUrl + `course/cloud/file/${fileId}`);
  }

  public upload(body) {
    const formData = this.formData.createFormData(body);
    return this.crud.post(environment.apiUrl + `course/cloud`, formData);
  }

  public edit(id, body) {
    return this.crud.patch(environment.apiUrl + `course/cloud/edit/${id}`, body);
  }

  public moveFiles(body) {
    return this.crud.post(environment.apiUrl + 'course/cloud/directory/move', body);
  }

  public deleteContent(id: string) {
    return this.crud.delete(environment.apiUrl + `course/cloud/${id}`);
  }


}
