<ng-container *ngIf="isLoading; else settings">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #settings>
    <div class="settings-detail-wrapper bordered-box">
        <div class="header">
            <div class="setting">
                <div (click)="back()"> <img src="../../../../assets/grey-back.svg" /> </div>
                <div *ngIf="title" class="text">Settings - {{(labelData[title.toLowerCase()] | titlecase) }}</div>
            </div>
            <div class="btn">
                <button *ngIf="title" mat-flat-button color="primary" (click)="add()">ADD {{ labelData[title.toLowerCase()] | uppercase}}</button>
            </div>
        </div>

        <ng-container *ngIf="!haveDetail">
            <div class="empty">
                <div *ngIf="title" class="title">You haven’t added any {{labelData[title.toLowerCase()] | titlecase}} yet.</div>
                <div *ngIf="title" class="note">You can add {{labelData[title.toLowerCase()]}} by tapping on top right button ADD {{labelData[title.toLowerCase()] | uppercase }}</div>
            </div>
        </ng-container>

        <ng-container *ngIf="haveDetail">
            <div class="tail">
                <div class="settings-list">
                    <div *ngFor="let detail of details" class="details" [ngClass]="{'inactive' : detail.status === 'inactive'}">
                        <div>{{detail.name}}</div>
                        <ng-container *ngIf="detail.status !== 'inactive'; else active">
                            <div class="select">
                                <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="detail.status === 'inactive'">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                                <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">

                                    <button mat-menu-item (click)="editDetail(detail)">
                                <span>Edit {{labelData[title.toLowerCase()] | titlecase}}</span>
                                </button>

                                    <button mat-menu-item (click)="delete(detail)">
                                <span>Delete {{labelData[title.toLowerCase()] | titlecase}}</span>
                                </button>

                                </mat-menu>
                            </div>
                        </ng-container>

                        <ng-template #active>
                            <div class="select">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                                <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                                    <button mat-menu-item (click)="editDetail(detail)">
                                    <span>Make Active</span>
                                </button>
                                </mat-menu>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
