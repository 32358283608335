import { Component, OnInit, Input } from '@angular/core';
import { SchoolSpecificSettingsService } from '../services/school-specific-settings.service';
import { forkJoin, from } from 'rxjs';
import { Router } from '@angular/router';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { Class } from 'src/app/models/class.models';

@Component({
  selector: 'app-class-card',
  templateUrl: './class-card.component.html',
  styleUrls: ['./class-card.component.scss'],

})
export class ClassCardComponent implements OnInit {
  classList = [];
  standardList = [];
  constructor(
    public classService: SchoolSpecificSettingsService,
    public sidenavService: ToggleSidenavService,
    public router: Router,
  ) { }

  @Input() set classes(classData: Class[]) {
    this.classList = [];
    classData.map(data => {
      if(data.performance === '') {
        data.performance = 0;
      } else {
        Number(data.performance)
      }
    })
    this.classList = classData;
  }

  ngOnInit(): void { }

  onCardClick(event) {
    this.sidenavService.minmizeSidenav();
    this.router.navigate(['teacher', 'dashboard', 'class-detail',event.id])
  }

}
