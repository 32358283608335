<ng-container>
    <!-- <div class="line filterHeader">
    <div class="checkbox">
      <mat-checkbox (change)="$event ? masterToggle() : null"
        color="'primary'"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [color]="'primary'"
        [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </div>
    <div class="actionWrapper">
      <div class="action" (click)="makeActiveGroup()">
        <img src="../../../assets/transfer.svg" alt="">
        <div class="title">
          Transfer
        </div>
      </div>
      <div class="action">
        <img src="../../../assets/files-and-folders.svg" alt="">
        <div class="title">
          Assign Class
        </div>
      </div>
      <div class="action">
        <img src="../../../assets/message.svg" alt="">
        <div class="title">
          Send Message
        </div>
      </div>
      <div class="action">
        <img src="../../../assets/select.svg" alt="">
        <div class="title">
          Mark Active
        </div>
      </div>
      <div class="action">
        <img src="../../../assets/active.svg" alt="">
        <div class="title">
          Mark Inactive
        </div>
      </div>
    </div>
  </div> -->
</ng-container>
<ng-container *ngIf="type === 'teacher' || type === 'staff'">
    <ng-container *ngIf="type === 'teacher'; else staff">
        <ng-container>
            <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="hideCheck">
                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [color]="'primary'" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [color]="'primary'" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>


                <ng-container matColumnDef="name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.name}} </td>
                </ng-container>

                <ng-container matColumnDef="mobileNumber">
                    <th mat-header-cell *matHeaderCellDef>Phone </th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.mobileNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="subjects">
                    <th mat-header-cell *matHeaderCellDef>{{labelData['subjects']}} </th>
                    <td mat-cell *matCellDef="let transaction;let i = index">
                        <div class="subjects-column" [ngStyle]="{'display': 'flex', 'align-items': 'center'}">
                            <p class="subjects-text">{{ getSubjectsString(transaction.subjects)}}</p>
                            <div [ngStyle]="{'margin-bottom': '10px'}" *ngIf="transaction.subjects && transaction.subjects.length > 0">
                                <button (click)="toggleSubjects(i)" mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon *ngIf="!transaction.subjectsOpen"><img src="../../../assets/tooltip.svg" /></mat-icon>
                                    <mat-icon *ngIf="transaction.subjectsOpen"><img src="../../../assets/tooltip-active.svg" /></mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" (closed)="closeSubjects(i)" class="subjects-menu" overlapTrigger="true">
                                    <button *ngFor="let subject of transaction.subjects" (click)="$event.stopPropagation()" class="subjects-option" mat-menu-item>{{subject}}</button>
                                </mat-menu>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status </th>
                    <td mat-cell *matCellDef="let transaction">
                        <div [ngClass]="transaction.status">
                            {{transaction.status | titlecase}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
                    <td mat-cell *matCellDef="let transaction" class="action-table-header">
                        <div class="select">
                            <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                            <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                                <button mat-menu-item (click)="changeTeacherStatus(transaction)" [disabled]="(role == 'member') || (selectedSchool && selectedSchool.status === 'archived') || transaction.status === 'pending'">
                                    <span>Make {{transaction.status == 'active' ? 'Inactive' : 'Active'}}</span>
                                </button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-container>

        <ng-container>
            <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize> </mat-paginator>
        </ng-container>
    </ng-container>

    <ng-template #staff>
        <ng-container>
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="hideCheck">
                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [color]="'primary'" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [color]="'primary'" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>


                <ng-container matColumnDef="name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Name</th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.name}} </td>
                </ng-container>

                <ng-container matColumnDef="mobileNumber">
                    <th mat-header-cell *matHeaderCellDef>Phone </th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.mobileNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef>Role </th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.role}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status </th>
                    <td mat-cell *matCellDef="let transaction">
                        <div [ngClass]="transaction.status">
                            {{transaction.status | titlecase}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
                    <td mat-cell *matCellDef="let transaction" class="action-table-header">
                        <div class="select">
                            <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                            <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                                <button mat-menu-item (click)="changeStaffSatus(transaction)" [disabled]="(role != 'creator') || (selectedSchool && selectedSchool.status === 'archived') || transaction.status === 'pending'">
                                    <span>Make {{transaction.status == 'active' ? 'Inactive' : 'Active'}}</span>
                                </button>
                                <!-- <button mat-menu-item>
                      <span>Make Inactive s</span>
                    </button> -->
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-container>

        <ng-container>
            <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize> </mat-paginator>
        </ng-container>
    </ng-template>
</ng-container>

<ng-container *ngIf="type === 'students' || type === 'coteachers'">
    <ng-container *ngIf="type === 'students'; else coteachers">
        <ng-container>
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
                <ng-container matColumnDef="name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.name}} </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.mobileNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="testMarks">
                    <th mat-header-cell *matHeaderCellDef>Tests Marks</th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.testMarks}} </td>
                </ng-container>

                <ng-container matColumnDef="assignments">
                    <th mat-header-cell *matHeaderCellDef>Assignments</th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.assignments}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let transaction">
                      <div [ngClass]="transaction.status">
                        {{transaction.status | titlecase}}
                      </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="performance">
                    <th mat-header-cell *matHeaderCellDef>Performance</th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.performance}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
                    <td mat-cell *matCellDef="let transaction" class="action-table-header">
                        <div class="select">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
                            <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                                <button mat-menu-item (click)="changeStudentSatus(transaction)" [disabled]="!transaction.studentID">
                  <span>Make {{transaction.status == 'active' ? 'Inactive' : 'Active'}}</span>
                </button>
                                <!-- <button mat-menu-item>
                    <span>Make Inactive</span>
                  </button> -->
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-container>

        <ng-container>
            <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize> </mat-paginator>
        </ng-container>
    </ng-container>

    <ng-template #coteachers>
        <ng-container>
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="hideCheck">
                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [color]="'primary'" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [color]="'primary'" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>


                <ng-container matColumnDef="name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef disableClear>Name</th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.name}} </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone </th>
                    <td mat-cell *matCellDef="let transaction"> {{transaction.mobileNumber}} </td>
                </ng-container>


                <ng-container matColumnDef="subjects">
                    <th mat-header-cell *matHeaderCellDef>{{labelData['subjects']}}</th>
                    <td mat-cell *matCellDef="let transaction">{{transaction.subjects}}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
                    <td mat-cell *matCellDef="let transaction" class="action-table-header">
                        <div class="select">
                            <button mat-icon-button [matMenuTriggerFor]="menu" disabled>
                <mat-icon>more_vert</mat-icon>
              </button>
                            <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                                <button mat-menu-item>
                  <span>Make {{transaction.status == 'active' ? 'Inactive' : 'Active'}}</span>
                </button>
                                <!-- <button mat-menu-item>
                    <span>Make Inactive</span>
                  </button> -->
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-container>

        <ng-container>
            <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize> </mat-paginator>
        </ng-container>
    </ng-template>
</ng-container>
<ng-container *ngIf="type === 'courses'">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
        <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.name}} </td>
        </ng-container>
        <ng-container matColumnDef="code">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Code</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.courseCode}} </td>
        </ng-container>
        <ng-container matColumnDef="category">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Category</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.category}} </td>
        </ng-container>
        <ng-container matColumnDef="certificate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Certificate</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.certificate ? 'Yes': 'No'}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status </th>
            <td mat-cell *matCellDef="let transaction">
                <div [ngClass]="transaction.status">
                    {{transaction.status | titlecase}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
            <td (click)="$event.stopPropagation();$event.preventDefault()" mat-cell *matCellDef="let transaction" class="action-table-header">
                <div class="select">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
                    <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                        <button mat-menu-item (click)="goToCourse(transaction)">
                          <span>View Activities</span>
                        </button>
                        <button mat-menu-item (click)="changeCourseStatus(transaction)" [disabled]="(role == 'member') || (selectedSchool && selectedSchool.status === 'archived') ||(role === 'admin' && transaction.creatorID !== userID)">
                          <span>Make {{transaction.status == 'active' ? 'Inactive' : 'Active'}}</span>
                        </button>
                        <button mat-menu-item (click)="editCourse(transaction)" [disabled]="(role == 'member') || (selectedSchool && selectedSchool.status === 'archived') ||(role === 'admin' && transaction.creatorID !== userID  )">
                          <span>Edit Course</span>
                        </button>
                        <button mat-menu-item (click)="copyCourse(transaction)" [disabled]="transaction.status === 'inactive'">
                          <span>{{ 'Copy to Other ' + labelData['schools'] }}</span>
                        </button>
                    </mat-menu>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>

        <tr (click)="courseClicked(row)" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <ng-container>
        <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize> </mat-paginator>
    </ng-container>
</ng-container>
