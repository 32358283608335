<div class="msg-wrapper">
    <div class="text">
        <div>Send Message</div>
        <div (click)="closeDialog()" class="close"><img src="../../../assets/close.svg"/></div>
    </div>

    <div class="header">Enter details</div>

    <div class="form">
        <form  [formGroup]="msgForm" (ngSubmit)="sendMessage()">
            <div class="title" >
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>To</mat-label>
                    <input matInput [placeholder]=placeholder autocomplete="off" [disabled]="'true'">
                    <!-- <mat-error *ngIf="!msgForm.controls['title'].valid && msgForm.controls['title'].touched">Title is required</mat-error> -->
                </mat-form-field> 
            </div>

            <div class="description" >
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Note</mat-label>
                    <textarea matInput rows="3" placeholder="Enter short note" formControlName="note" autocomplete="off"></textarea>
                    <mat-error *ngIf="!msgForm.controls['note'].valid && msgForm.controls['note'].touched">Note is required</mat-error>
                </mat-form-field> 
            </div>

            <div class="btn">
                <button mat-flat-button color="primary" type="submit" [disabled]="isSubmitted">SEND MESSAGE</button>
            </div>

        </form>
    </div>   
</div>