import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { SettingsDetailComponent } from './settings-detail/settings-detail.component';
import { RenameLabelComponent } from 'src/app/shared/rename-label/rename-label.component';
import { CourseCategoriesSettingComponent } from 'src/app/shared/course-categories-setting/course-categories-setting.component';



const routes: Routes = [
  { path: '', component: SettingsComponent },
  { path: 'standards', component: SettingsDetailComponent },
  { path: 'subjects', component: SettingsDetailComponent },
  { path: 'session', component: SettingsDetailComponent },
  { path: 'sections', component: SettingsDetailComponent },
  { path: 'rename', component: RenameLabelComponent },
  { path: 'course-categories', component: CourseCategoriesSettingComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
