import { Component, OnInit, Input } from '@angular/core';
import { USER_TYPES } from '../../Constants/user-types-constants';

const teacherResponsiblities = [
  'Share class materials',
  'Simplify communication',
  'Improve student engagement',
  'Share digital assignments'
];

const adminResponsibilities = [
  'Create school',
  'Monitor school performance',
  'Improve school engagement',
  'Share views and announcements'
];

const studentResponsibilities = [
  'View class materials',
  'Interact with classmates & teachers',
  'Upload your assignments',
  'View your grades & performance'
];

const superAdminResponsibilities = [
  'View app analytics',
  'Manage cloud library',
  'Manage app data',
  'Control app access'
];

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  @Input() userType: string;
  responsibilities: any;
  userName: string;
  userTypes = { ...USER_TYPES };

  constructor() { }

  ngOnInit(): void {
    if (this.userType === this.userTypes.admin) {
      this.responsibilities = adminResponsibilities;
      this.userName = 'For School Administrator';
    } else if (this.userType === this.userTypes.teacher) {
      this.responsibilities = teacherResponsiblities;
      this.userName = 'For Teachers';
    } else if (this.userType === this.userTypes.superAdmin) {
      this.responsibilities = superAdminResponsibilities;
      this.userName = 'For Super Admins';
    }
    else {
      this.responsibilities = studentResponsibilities;
      this.userName = 'For Parents & Students';
    }
  }
}
