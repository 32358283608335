import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-test-source-alert',
  templateUrl: './test-source-alert.component.html',
  styleUrls: ['./test-source-alert.component.scss']
})
export class TestSourceAlertComponent implements OnInit {

  constructor(public dialogref: MatDialogRef<any>) { }

  ngOnInit(): void { }

  action(type) {
    this.dialogref.close(type);
  }

}
