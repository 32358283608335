<ng-container>
    <div class="wrapper" [ngClass]="{'accessdenied': data.type}">
        <div class="text">{{alertText}}</div>

        <div class="btn">
            <div class="ok"><button [ngClass]="isAdminTheme ? 'admin-outline-button' : isStudentTheme ? 'student-outline-button':''" mat-flat-button (click)="closeDialog()">{{data.type == 'accessdenied' ? 'GO BACK' : data.type == 'accessDeniedHome' ? 'OK' : 'CANCEL'}}</button></div>
            <div *ngIf="action" class="yes"><button [ngClass]="isAdminTheme ? 'admin-button' :''" mat-flat-button (click)="closeDialog('true')">OK</button></div>
        </div>
    </div>
</ng-container>
