<form class="login-form-container" [formGroup]="loginForm">
    <div class="text">Enter Email and Password</div>
    <div class="input">
        <input [ngClass]="loginForm.controls.email.touched && !loginForm.controls.email.valid ? 'danger' : null" matInput placeholder="Email" formControlName="email" />
    </div>
    <div *ngIf="loginForm.controls.email.touched && !loginForm.controls.email.valid" [ngStyle]="{'color': '#ff8156', 'margin': '5px 0 0 10px'}" class="error">
        {{loginForm.controls.email.errors?.required ? 'email is required' : 'invalid value' }}
    </div>
    <div class="input">
        <input type="password" [ngClass]="loginForm.controls.password.touched && !loginForm.controls.password.valid ? 'danger' : null" matInput placeholder="Password" formControlName="password" />
    </div>
    <div *ngIf="loginForm.controls.password.touched && !loginForm.controls.password.valid" [ngStyle]="{'color': '#ff8156', 'margin': '5px 0 0 10px'}" class="error">
        {{'password is required'}}
    </div>
</form>
