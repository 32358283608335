<ng-container *ngIf="mobileNumber.length === 0; else uploadedFile">
    <label class="avatar" >
        <div class="img">
            <img src="../../../assets/persons.svg" />
        </div>
        <div class="input">
            <label for="txtFileUpload" [ngClass]= "getTheme()" >{{text}}</label>
            <input #fileInput type="file" id="txtFileUpload" accept=".csv, .xlx, .xlxx" (click)="fileInput.value = null"  (change)="fileChange($event)" />
        </div>
    </label>

    <div class="sample">
        <ng-container *ngIf="getTheme() === 'admin'; else teacher">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="26" viewBox="0 0 20 26">
                <g fill="#607D8B">
                    <path d="M13.583.194C13.467.073 13.309 0 13.145 0H3.331C1.52 0 .025 1.489.025 3.3v18.56c0 1.81 1.495 3.3 3.306 3.3h13.32c1.812 0 3.307-1.49 3.307-3.3V7.122c0-.158-.073-.31-.176-.425l-6.2-6.503zm.176 1.951l4.15 4.358h-2.697c-.803 0-1.453-.645-1.453-1.447v-2.91zm2.893 21.8H3.33c-1.137 0-2.091-.943-2.091-2.085V3.3c0-1.136.948-2.085 2.09-2.085h9.214v3.841c0 1.477 1.19 2.662 2.668 2.662h3.53V21.86c0 1.142-.948 2.084-2.09 2.084z"/>
                    <path d="M14.993 19.751H4.99c-.335 0-.608.273-.608.608 0 .334.273.607.608.607h10.009c.334 0 .608-.273.608-.607 0-.335-.274-.608-.614-.608zM9.548 17.57c.115.12.273.194.443.194.17 0 .328-.073.444-.195l3.561-3.822c.231-.243.213-.632-.03-.857-.243-.231-.632-.213-.857.03l-2.51 2.692V8.976c0-.334-.274-.608-.608-.608-.334 0-.608.274-.608.608v6.636L6.88 12.92c-.231-.243-.614-.261-.857-.03-.243.23-.262.614-.03.857l3.555 3.822z"/>
                </g>
            </svg>
        </ng-container >
        <ng-template #teacher>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="26" viewBox="0 0 20 26">
                <g fill="#2e95d9">
                    <path d="M13.583.194C13.467.073 13.309 0 13.145 0H3.331C1.52 0 .025 1.489.025 3.3v18.56c0 1.81 1.495 3.3 3.306 3.3h13.32c1.812 0 3.307-1.49 3.307-3.3V7.122c0-.158-.073-.31-.176-.425l-6.2-6.503zm.176 1.951l4.15 4.358h-2.697c-.803 0-1.453-.645-1.453-1.447v-2.91zm2.893 21.8H3.33c-1.137 0-2.091-.943-2.091-2.085V3.3c0-1.136.948-2.085 2.09-2.085h9.214v3.841c0 1.477 1.19 2.662 2.668 2.662h3.53V21.86c0 1.142-.948 2.084-2.09 2.084z"/>
                    <path d="M14.993 19.751H4.99c-.335 0-.608.273-.608.608 0 .334.273.607.608.607h10.009c.334 0 .608-.273.608-.607 0-.335-.274-.608-.614-.608zM9.548 17.57c.115.12.273.194.443.194.17 0 .328-.073.444-.195l3.561-3.822c.231-.243.213-.632-.03-.857-.243-.231-.632-.213-.857.03l-2.51 2.692V8.976c0-.334-.274-.608-.608-.608-.334 0-.608.274-.608.608v6.636L6.88 12.92c-.231-.243-.614-.261-.857-.03-.243.23-.262.614-.03.857l3.555 3.822z"/>
                </g>
            </svg>
        </ng-template>
        <a class="text" [ngClass]="getTheme()" href="../../../assets/invite-list.csv">DOWNLOAD SAMPLE LIST</a>
    </div>
</ng-container>

<ng-template #uploadedFile>
    <div class="list">
        <mat-chip-list aria-label="Fish selection">
            <mat-chip  
                [ngClass]="{'danger': isSubmitted && (isInvalidNumber(number) || isAlreadyInvitedNumber(number))}"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(number)"
                *ngFor="let number of mobileNumber">
                {{number}}
                <mat-icon matChipRemove  *ngIf="removable"><img src="../../../assets/close.svg"/></mat-icon>
            </mat-chip>
          </mat-chip-list>
    </div>
</ng-template>

