import { Component, Input, OnInit } from '@angular/core';
const TEACHERS = 'teachers'
const STAFF = 'staff'
const STUDENTS = 'students'
@Component({
  selector: 'app-analytic-card',
  templateUrl: './analytic-card.component.html',
  styleUrls: ['./analytic-card.component.scss']
})
export class AnalyticCardComponent implements OnInit {
  @Input() record;
  @Input() studentsLabel;
  @Input() staffLabel;
  @Input() teachersLabel;
  constructor() { }

  ngOnInit(): void {
  }

  getClass() {
    if(this.record.type === TEACHERS)
      return 'teacher-text';
    else if(this.record.type === STAFF)
      return 'staff-text';
    else
      return 'student-text';
  }

  getColor() {
    if(this.record.type === TEACHERS)
      return '#2e95d9';
    else if(this.record.type === STAFF)
      return '#607d8b';
    else
      return '#39bda7';
  }

}
