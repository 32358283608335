<div class="completed-work-container">
    <div *ngFor="let work of workCompleted">
        <div class="btn">
            <button mat-flat-button color="primary">{{work.completionDate | date }}</button>
        </div>
        <div class="timeline">
            <div class="card" *ngFor="let activity of work.activities; let i = index ">
                <div class="point"></div>
                <div class="card-content">
                    <p class="message zero-margin">{{ getMessage(activity.activityType) }}</p>
                    <div class="content-details">
                        <p class="zero-margin class-name text"><span class="grey">Class: </span>{{activity.className}}</p>
                        <ng-container>
                            <p class="zero-margin topic text"><span class="grey">Topic: </span>{{activity.topic}}</p>
                            <ng-container *ngIf="activity.activityType === activityTypes.assignments else liveClasses">
                                <p class="zero-margin text"><span class="grey">Total Marks: </span>{{ activity.totalMarks}}</p>
                            </ng-container>
                            <ng-template #liveClasses>
                                <p *ngIf="activity.activityType === activityTypes.liveClasses" class="zero-margin text"><span class="grey">Conducted By: </span>{{ activity.teacher}}</p>
                                <p *ngIf="activity.activityType !== activityTypes.liveClasses" class="zero-margin text"><span class="grey">Shared By: </span>{{ activity.teacher}}</p>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
                <hr *ngIf="i !== (work.activities.length - 1)" class="line" />
            </div>
        </div>
    </div>
</div>
