export const USER_TYPES = {
  admin: 'admin',
  teacher: 'teacher',
  student: 'student',
  superAdmin: 'super-admin',
};

export const USER_THEMES = {
  admin: {
    name: 'admin-theme',
    background: '#607d8b'
  },
  teacher: {
    name: 'teacher-theme',
    background: '#2e95d9'
  },
  student: {
    name: 'student-theme',
    background: '#39bda7'
  },
  superAdmin: {
    name: 'super-admin-theme',
    background: '#8d6e63'
  },
};
