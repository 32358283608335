import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeachersModule } from './teachers/teachers.module';
import { AdminModule } from './admin/admin.module';
import { StudentsModule } from './students/students.module';
import { SubDomainRouteGuard } from './services/sub-domain-route.guard';
import { SuperAdminModule } from './super-admin/super-admin.module';


const routes: Routes = [
  { path: 'teacher', loadChildren: () => TeachersModule, canLoad: [SubDomainRouteGuard] },
  { path: 'school', loadChildren: () => AdminModule, canLoad: [SubDomainRouteGuard] },
  { path: 'student', loadChildren: () => StudentsModule, canLoad: [SubDomainRouteGuard] },
  { path: 'super-admin', loadChildren: () => SuperAdminModule, canLoad: [SubDomainRouteGuard] },
  { path: '', pathMatch: 'full', redirectTo: 'teacher' },
  { path: '**', redirectTo: 'teacher' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
