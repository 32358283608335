<div class="student-live-class">
    <div class="text-header">Live class today : {{ currentDate | date : 'dd'}} {{ currentDate | date : 'LLLL'}}, {{ currentDate | date : 'EEEE'}}</div>

    <ng-container *ngIf="data && data.length > 0 else placeholder">
        <div>
            <app-live-class-table [transactions]="data" [displayedColumns]="columns" (onJoinClass)="onClassJoin($event)"></app-live-class-table>
        </div>
    </ng-container>
    <ng-template #placeholder>
        <div class="placeholder">
          <p>No Live Class Today</p>
        </div>
    </ng-template>
</div>
