<div class="drawer">
    <div class="side-window-header">
        <img (click)="closeSideWindow()" class="close-icon" src="assets/close.svg" width="16px" height="16px" />
        <div class="participants-heading">Chat</div>
    </div>
    <ng-container *ngIf="!isNearBottom">
        <div (click)="scrollToBottom()" [ngClass]="theme ==='teacher' ? '' : 'green-icon'" class="move-to-bottom-icon">
            <mat-icon>arrow_drop_down_circle</mat-icon>
        </div>
    </ng-container>
    
    <div class="drawer-content" (scroll)="onScroll()" #messagesContainer>
        <div class="participant" *ngFor="let message of messages">
            <div *ngIf="message.participantId != selfId">
                <div *ngIf="allParticipants.get(message.participantId)" class="chat-message">
                    <div *ngIf="!allParticipants.get(message.participantId).profilePicture" class="profile-image">
                        {{allParticipants.get(message.participantId).name.charAt(0)}}
                    </div>
                    <div *ngIf="allParticipants.get(message.participantId).profilePicture" class="profile-image participant-profile">
                        <img [src]="allParticipants.get(message.participantId).profileSrc" />
                    </div>
                    <div>
                        <div class="chat-participant-details">
                            <div class="name">{{allParticipants.get(message.participantId).name}}</div>
                            <div class="send-time">{{ message.sendAt | prettyDate}}</div>
                        </div>
                        <div class="chat-message-text">
                            {{message.msg}}
                        </div>
                    </div>
                </div>
                <div *ngIf="teacher && message.participantId==teacher.id" class="chat-message">
                    <div  *ngIf="!teacher.profilePicture" class="profile-image">
                        {{teacher.name.charAt(0)}}
                    </div>
                    <div *ngIf="teacher.profilePicture" class="profile-image participant-profile">
                        <img [src]="teacher.profileSrc" />
                    </div>
                    <div>
                        <div class="chat-participant-details">
                            <div class="name">{{teacher.name}}</div>
                            <div class="send-time">{{ message.sendAt | prettyDate }}</div>
                        </div>
                        <div class="chat-message-text">
                            {{message.msg}}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="message.participantId == selfId">
                <div class="chat-message self-message">
                    <div>
                        <div class="chat-participant-details self-details">
                            <div *ngIf="message.sendAt" class="send-time">{{message.sendAt | prettyDate}}</div>
                        </div>
                        <div [ngClass]="theme=== 'teacher' ? '' : 'student-self'" class="chat-message-text self-message-text">
                            {{message.msg}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="chat-input-box">
        <div class="chat-input">
            <img [src]="theme === 'teacher' ? 'assets/send-24-px.svg': 'assets/send-24-px-green.svg'" width="24px" height="24px" (click)="sendChatMessage()" />
            <input (keyup.enter)="sendChatMessage()" placeholder="Type a message.." #chatInput/>
        </div>
    </div>
</div>