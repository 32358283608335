import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LIVE_CLASS_TABS, DO_RELOAD, RELOAD_LIVE_CLASS_PAGE, RELOAD_LIVE_CLASS_PREV_PAGE, DO_NOT_RELOAD } from 'src/app/Constants/Constant';
import { LabelService } from 'src/app/services/label.service';
import { LiveClassService } from 'src/app/services/live-class.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { ScheduleLiveClassComponent } from 'src/app/teachers/schedule-live-class/schedule-live-class.component';
import { DateTimeService } from '../../services/date-time.service';
import { TYPE } from '../live-class-table/live-class-table.component';

export const TEACHER = 'teacher';
export const DIALOG_WIDTH = 'teacher';
export const DIALOG_HEIGHT = 'teacher';
export const TODAY = 'today';
export const WEEK = 'weekly';
export const PAST = 'past';
const WEEKLY_API_DEBOUNCE = 1000;
@Component({
  selector: 'app-live-class',
  templateUrl: './live-class.component.html',
  styleUrls: ['./live-class.component.scss']
})
export class LiveClassScheduleComponent implements OnInit, OnDestroy {
  public TABS = LIVE_CLASS_TABS;
  tabs = [{ label: this.TABS.TODAY }, { label: this.TABS.UPCOMING }, { label: this.TABS.PAST }];
  selectedDate = new Date();
  weekDay = this.selectedDate.getDay();
  weekStart = new Date();
  list;
  weekClass = [];
  todayClass = [];
  pastClass = [];
  theme = TEACHER;
  isLoading: boolean;
  private destroy$$: Subject<any> = new Subject<any>();
  columns: string[] = ['startsAt', 'endsAt', 'duration', 'title', 'standard', 'action'];
  public weekDateRange = {
    monday: new Date(),
    saturday: new Date()
  };

  labelData = {};
  changingWeekRange = false;
  weeklyClassApiTimer: any;
  @Input() selectedIndex: number | null = 0;

  constructor(
    public localstorage: LocalStorageService,
    public location: Location,
    public liveClassService: LiveClassService,
    public dialog: MatDialog,
    public router: Router,
    public route: ActivatedRoute,
    public sidenav: ToggleSidenavService,
    public snackbar: SnackbarService,
    private dateService: DateTimeService,
    private labelService: LabelService
  ) { }

  ngOnInit(): void {
    this.weekDateRange = this.dateService.getWeekRange();

    this.route.queryParams.pipe(takeUntil(this.destroy$$)).subscribe((params) => {
      if (params.tab) {
        switch (params.tab) {
          case LIVE_CLASS_TABS.PAST:
            this.selectedIndex = 2;
            break;
          case LIVE_CLASS_TABS.UPCOMING:
            this.selectedIndex = 1;
            this.weekDateRange = this.dateService.getWeekRange(new Date(params.classDate));
            break;
          default:
            this.selectedIndex = 0;
        }
      }
      this.tabSelect({ index: this.selectedIndex });
    });

    this.labelService.labels$.pipe(takeUntil(this.destroy$$)).subscribe(data => this.labelData = data)
    const reloadPage = this.localstorage.getItem(RELOAD_LIVE_CLASS_PREV_PAGE);
    if (reloadPage === DO_RELOAD) {
      this.localstorage.setItem(RELOAD_LIVE_CLASS_PREV_PAGE, DO_NOT_RELOAD);
      window.location.reload();
    } else {
      this.localstorage.setItem(RELOAD_LIVE_CLASS_PAGE, DO_RELOAD);
      this.localstorage.setItem(RELOAD_LIVE_CLASS_PREV_PAGE, '');
    }
    this.sidenav.minmizeSidenav();
    this.theme = this.localstorage.getItem(TYPE);

    this.liveClassService.isLoading$.pipe(
      takeUntil(this.destroy$$),
    ).subscribe(
      (loading: boolean) => this.isLoading = loading
    );


    this.liveClassService.todayClass$.pipe(
      takeUntil(this.destroy$$),
    )
      .subscribe(classes => {
        if (classes) {
          this.todayClass = classes;
        } else {
          this.todayClass = [];
        }
        if (this.selectedIndex === 0) {
          this.list = this.todayClass;
        }
      });

    this.liveClassService.weeklyClass$.pipe(
      takeUntil(this.destroy$$),
    )
      .subscribe(classes => {
        if (classes) {
          if (classes) {
            this.weekClass = classes;
          } else {
            this.weekClass = [];
          }
          if (this.selectedIndex === 1) {
            this.list = this.weekClass;
          }
        }
      });

    this.liveClassService.pastClass$.pipe(
      takeUntil(this.destroy$$),
    )
      .subscribe(classes => {
        if (classes) {
          this.pastClass = classes;
        } else {
          this.pastClass = [];
        }
        if (this.selectedIndex === 2) {
          this.list = this.pastClass;
        }
      });
  }

  getNextWeekLiveClasses() {
    this.changingWeekRange = true;
    this.weekClass = [];
    this.isLoading = true;
    this.weekDateRange = this.dateService.getNextWeekRange(new Date(this.weekDateRange.monday));
    if (this.weeklyClassApiTimer) {
      clearTimeout(this.weeklyClassApiTimer);
    }
    this.weeklyClassApiTimer = setTimeout(() => {
      this.liveClassService.weeklyLiveClassDetails(this.weekDateRange.monday, this.weekDateRange.saturday);
    }, WEEKLY_API_DEBOUNCE);
    this.changingWeekRange = false;
  }

  getPrevWeekLiveClasses() {
    this.changingWeekRange = true;
    this.weekClass = [];
    this.isLoading = true;
    this.weekDateRange = this.dateService.getPrevWeekRange(new Date(this.weekDateRange.monday));
    if (this.weeklyClassApiTimer) {
      clearTimeout(this.weeklyClassApiTimer);
    }
    this.weeklyClassApiTimer = setTimeout(() => {
      this.liveClassService.weeklyLiveClassDetails(this.weekDateRange.monday, this.weekDateRange.saturday);
    }, WEEKLY_API_DEBOUNCE);
    this.changingWeekRange = false;
  }

  tabSelect(event) {
    if (event.index === 0) {
      this.selectedIndex = 0;
      this.todayClass.length === 0 ? this.liveClassService.liveClassDetails(TODAY) : (this.list = this.todayClass);
    } else if (event.index === 1) {
      this.selectedIndex = 1;
      this.weekClass.length === 0 ? this.liveClassService.weeklyLiveClassDetails(this.weekDateRange.monday, this.weekDateRange.saturday) :
        (this.list = this.weekClass);
    } else {
      this.selectedIndex = 2;
      this.pastClass.length === 0 ? this.liveClassService.liveClassDetails(PAST) : (this.list = this.pastClass);
    }
  }

  back() {
    this.sidenav.maximizeSidenav();
    this.location.back();
  }

  onClassJoin(classID) {
    this.liveClassService.joinClass(classID).subscribe(
      (res) => {
          window.open(res.link, '_blank');
      },
      (err) => {
        this.snackbar.openSnackbar(err.error.error);
      }
    );
  }

  createClass() {
    const dialogRef = this.dialog.open(ScheduleLiveClassComponent, {
      width: '70%',
      height: '100%',
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['teacher-theme', 'width-class'],
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.liveClassService.liveClassDetails(TODAY);
        this.liveClassService.liveClassDetails(WEEK);
      }
    })
  }

  getTheme() {
    return this.theme === 'teacher' ? 'teacher' : 'student';
  }

  public getColumns(tab) {
    if (tab !== this.TABS.TODAY) {
      if (this.columns[0] !== 'date') {
        this.columns.splice(0, 0, 'date');
      }
    } else if (this.columns[0] === 'date') {
      this.columns.splice(0, 1);
    }
    return this.columns;
  }
  ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}
