import { Component, OnInit, Input } from '@angular/core';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';
import { ACTIVITY_TYPES } from 'src/app/Constants/Constant';

@Component({
  selector: 'app-library-content-icon',
  templateUrl: './library-content-icon.component.html',
  styleUrls: ['./library-content-icon.component.scss']
})
export class LibraryContentIconComponent implements OnInit {

  @Input() isFolder: boolean;
  @Input() userType: string;
  @Input() activityType: string;
  userTypes = USER_TYPES;
  activityTypes = ACTIVITY_TYPES;

  constructor() { }

  ngOnInit(): void {
  }

}
