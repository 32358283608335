import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  public fileRoute() {
    return `${environment.apiUrl}teacher/library`;
  }

  public folderRoute() {
    return `${environment.apiUrl}teacher/library/upload`;
  }

  public uploadRoute() {
    return `${environment.apiUrl}teacher/library`;
  }

  public moveFileRoute() {
    return `${environment.apiUrl}teacher/library/move`;
  }

  public editRoute(id) {
    return `${environment.apiUrl}teacher/library/edit/${id}`;
  }

  public contentRoute(id, isFolder) {
    return `${environment.apiUrl}teacher/library/contents/${id}?is-folder=${isFolder}`;
  }

  public deleteRoute(id) {
    return `${environment.apiUrl}teacher/library/${id}`;
  }

  constructor(public crud: CrudService, public formdata: FormDataService) { }

  getLibraryFiles() {
    return this.crud.get(this.fileRoute());
  }

  createFolder(body) {
    const formData = this.formdata.createFormData(body);
    return this.crud.post(this.folderRoute(), formData);
  }

  editFolder(id, body) {
    return this.crud.patch(this.editRoute(id), body);
  }

  getContents(id, isFolder) {
    return this.crud.get(this.contentRoute(id, isFolder));
  }

  moveFile(body) {
    return this.crud.post(this.moveFileRoute(), body);
  }

  deleteFile(id) {
    return this.crud.delete(this.deleteRoute(id));
  }
}
