import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Subscription } from 'rxjs';
import { NumberValidationService } from 'src/app/services/number-validation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.scss']
})
export class CsvUploadComponent implements OnInit, OnDestroy {
  @Output() numberList = new EventEmitter();
  @Input() role: string;
  @Input() invalidNumber: string[];
  @Input() text: string;
  @Input() isSubmitted: boolean; 
  @Input() alreadyInvitedNumber: string[];
  mobileNumber = [];
  csvFile: File = null;
  removable = true;
  selectable= true;
  mappedNumberWithName = [];
  // theme: string;
  private subscription = new Subscription();

  constructor(
    private fileUploadService: FileUploadService,
    private _snackbar: SnackbarService,
    public userService: UserService,
    public numberValidation: NumberValidationService
    ) { }

  ngOnInit(): void { }

  getTheme() {
    return this.userService.getUserInCache('type');
  }

  fileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      // this.teacherList.emit(file)
      this.csvFile = file;
      this.uploadFile();
    }
  }

  fetchMobileNumber(numbers, prop?) {
    let number = []
    Object.keys(numbers).forEach(num => {
      if(numbers[num] === prop || prop === num) {
        console.log(prop)
      } else {
        number.push(num)
      }
    })
    this.numberList.emit(number);
  }

  uploadFile() {
    this.subscription = this.fileUploadService.uploadFile(this.csvFile, this.role).subscribe(
      (numbers) => {
        this.mappedNumberWithName = numbers;
        Object.keys(numbers).forEach(number => {
          if(numbers[number] === '') {
            this.mobileNumber.push(number)
          } else {
            this.mobileNumber.push(numbers[number]);
          }
        })
        this.fetchMobileNumber(numbers);
      },
      (err) => this._snackbar.openSnackbar(err.error.error)
    )
  }
  userType(schoolID: string, csvFile: File, userType: any) {
    throw new Error("Method not implemented.");
  }

  remove(number: string): void {
    const index = this.mobileNumber.indexOf(number);
    Object.entries(this.mappedNumberWithName).forEach(k => {
      if(k[1].toString() !== '' && k[1].toString() === number) {
        delete this.mappedNumberWithName[k[0].toString()]
      } else if(k[1].toString()  === '' && k[0].toString() === number) {
        delete this.mappedNumberWithName[k[0].toString()]
      }
    })
    if (index >= 0) {
      this.mobileNumber.splice(index, 1);
    }
    this.fetchMobileNumber(this.mappedNumberWithName, number);
  }

  isInvalidNumber(number) {
    return this.numberValidation.isInvalidNumber(this.invalidNumber, number);
  }

  isAlreadyInvitedNumber(number) {
    const a =  this.numberValidation.isAlreadyInvitedNumber(this.alreadyInvitedNumber, number);
    console.log(a);
    return a
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
