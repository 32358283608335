import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor() { }

  miliSecondsInADay = 24 * 60 * 60 * 1000;

  public calculateInMins(duration) {
    const durArr = duration.split(':');
    return (+durArr[0] * 60 + +durArr[1]);
  }

  public concatDateTime(date: Date, time: string) {
    const timeVal = time.split(':');
    const curDate = new Date(date).setHours(+timeVal[0]);
    const curDateTime = new Date(curDate).setMinutes(+timeVal[1]);
    return new Date(curDateTime);
  }

  public createTimeStamp(testTime: string, date: Date) {
    const time = testTime.split(':');
    const timeStamp = new Date(date).setHours(parseInt(time[0]), parseInt(time[1]));
    return new Date(timeStamp);
  }

  public calculateTimeDiff(startDate, endDate) {
    var diff = (new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  public getWeekRange(refDate?: Date) {
    const ref = refDate ? new Date(refDate) : new Date();
    const refDayWithZeroTime = new Date(ref.getFullYear(), ref.getMonth(), ref.getDate(), 0, 0, 0, 0);
    const refDayDate = refDayWithZeroTime.getDate();
    const redDay = refDayWithZeroTime.getDay();
    if (redDay === 0) {
      return {
        monday: new Date(refDayWithZeroTime.setDate(refDayDate + 1)),
        saturday: new Date(refDayWithZeroTime.setDate(refDayDate + 6))
      };
    } else {
      return {
        monday: new Date(refDayWithZeroTime.setDate(refDayDate - (redDay + 6) % 7)),
        saturday: new Date(refDayWithZeroTime.setDate((refDayDate - (redDay + 6) % 7) + 5))
      };
    }
  }

  public getNextWeekRange(currentMonday: Date) {
    const nextMonday = new Date(currentMonday.getTime() + 7 * this.miliSecondsInADay);
    return {
      monday: nextMonday,
      saturday: new Date(nextMonday.getTime() + 5 * this.miliSecondsInADay),
    };
  }

  public getPrevWeekRange(currentMonday: Date) {
    const prevMonday = new Date(currentMonday.getTime() - 7 * this.miliSecondsInADay);

    return {
      monday: prevMonday,
      saturday: new Date(currentMonday.getTime() - 2 * this.miliSecondsInADay),
    };
  }
}
