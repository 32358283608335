<div class="preview-wrapper">
    <div class="header">
        <div class="txt">Preview - {{test.title | titlecase}}</div>
        <div class="side">
            <div class="nav">
                <div class="title">MAXIMUM MARKS</div>
                <div class="subtitle">{{test.totalMarks}} Marks</div>
            </div>
            
            <div class="nav close" (click)="close()">
                <img src="../../../assets/close.svg"/>
            </div>
        </div>
    </div>
    <div class="container">
        <div *ngFor="let question of questions; let i=index" class="question">
            <div class="question-header">
                <div class="value">Question #{{i + 1}}</div>
                <div>{{question.marks}} marks</div>
            </div>
            <div class="name">{{question.question}}</div>
            <div *ngIf="question.image != ''" class="image"> 
                <img [src]="question.image" />
            </div>
            <div *ngFor="let option of question.options; let i = index" class="loop">
                <ng-container *ngIf="question.type.toLowerCase() !== 'multiple'; else checkbox">
                    <div class="optionList">
                        <mat-radio-group [disabled]="true">
                            <div *ngIf="option.image !== ''" class="image"> <img [src]=option.image /></div>
                            <mat-radio-button [color]="'accent'" [checked]="option.isCorrect" [value]="option.option"> {{option.option}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </ng-container>

                <ng-template #checkbox>
                    <div class="optionList">
                        <div *ngIf="option.image !== ''"  class="image"> <img [src]=option.image /></div>
                        <div>
                            <mat-checkbox [color]="'accent'" [disabled]="true" [checked]="option.isCorrect" [value]="option.option">{{option.option}}</mat-checkbox>
                        </div>
                    </div>
                </ng-template>
           </div>
        </div>
    </div>
</div>