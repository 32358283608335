import { Injectable } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { FormDataService } from 'src/app/services/form-data.service';

@Injectable({
  providedIn: 'root'
})
export class PostAssignmentService {

  constructor(
    public crud: CrudService,
    public formData: FormDataService,
  ) { }

  assignmentRoute() {
    return environment.apiUrl + 'class/assignment/upload';
  }

  editAssignmentRoute(id) {
    return environment.apiUrl + 'class/assignment/edit/' + `${id}`
  }

  materialRoute() {
    return environment.apiUrl + 'class/material/upload';
  }

  editMaterialRoute(id) {
    return environment.apiUrl + 'class/material/v2/edit/' + `${id}`
  }

  statusRoute(id) {
    return environment.apiUrl + 'class/activity/upload-status/' + `${id}`;
  }

  postassignment(body) {
    const formData = this.formData.createFormData(body);
    return this.crud.post(this.assignmentRoute(), formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  editAssignment(id, body) {
    const formData = this.formData.createFormData(body);
    return this.crud.patch(this.editAssignmentRoute(id), formData,  {
      reportProgress: true,
      observe: 'events'
    });
  }

  postMaterial(body) {
    const formData = this.formData.createFormData(body);
    return this.crud.post(this.materialRoute() + '/v2', formData,  {
      reportProgress: true,
      observe: 'events'
    });
  }

  putMaterial(url, body) {
    return this.crud.put(url, body);
  }

  ackMaterial(id) {
    return this.crud.put(this.materialRoute() + '/v2/ack/' + id);
  }

  editMaterial(id, body) {
    const formData = this.formData.createFormData(body);
    return this.crud.patch(this.editMaterialRoute(id), formData,  {
      reportProgress: true,
      observe: 'events'
    });
  }

}
