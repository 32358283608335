import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from './crud.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  public route(id, type, role, theme) {
    switch(type.toLowerCase()) {
      case 'teacher' : return environment.apiUrl + 'school/teacher/invite/' + `${id}`;
      case 'admin' : return environment.apiUrl + 'school/staff/invite/' + `${id}` + `?role=${role}`;
      case 'student' : return theme === 'admin' ? environment.apiUrl + 'school/parent/invite/' + `${id}` : environment.apiUrl + 'class/student/invite/' + `${id}` ;
      case 'co-teacher' : return environment.apiUrl + 'class/co-teacher/invite/' + `${id}`;
    }

  }

  constructor(private crud: CrudService, public localstorgae: LocalStorageService) { }

  sendInvite(id, body, type, role) {
    const theme = this.localstorgae.getItem('type');
    return this.crud.post(this.route(id, type, role, theme), body);
  }

}
