<div class="account-wrapper student-theme" [ngClass]="{'isSchool': isSchool, 'isTeacher': isTeacher}">
  <div class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="103" height="26" viewBox="0 0 103 26">
      <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
        <text fill="#FFF" font-family="HugMeTight, Hug Me Tight" font-size="18">
          <tspan x="32" y="18">shiksha</tspan>
        </text>
        <circle cx="12" cy="12" r="12" stroke="#FFF" stroke-width="2" />
      </g>
    </svg>
  </div>
  <ng-container *ngIf="!(isSchool || isTeacher)">
    <ng-container *ngIf="!selectedProfile">
      <div class="header">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="#ffffff"
              d="M15.013 6.758c-.065-.01-.132-.015-.198-.014H3.547l.245-.115c.24-.113.459-.268.646-.457l3.16-3.16c.416-.397.486-1.036.166-1.514-.373-.51-1.088-.62-1.598-.247-.04.03-.08.063-.117.098L.335 7.064c-.446.446-.447 1.17 0 1.616l5.714 5.715c.447.446 1.171.445 1.617-.002.035-.035.068-.073.098-.112.32-.478.25-1.117-.166-1.515L4.444 9.601c-.168-.168-.36-.309-.572-.417l-.343-.155h11.223c.584.022 1.096-.386 1.206-.96.101-.623-.322-1.21-.945-1.31z" />
          </svg>
        </div>
        <div class="txt">Choose your account</div>
      </div>
      <div class="profile">
        <div class="parent-container" (click)="parentSelected()" *ngIf="parentProfile">
          <div class="pic"><img [src]="parentProfile.profilePicture" /></div>
          <div class="name">{{parentProfile.name | titlecase}}</div>
          <div class="parent">(Parent)</div>
        </div>
        <div *ngFor="let profile of childrenList; let i=index" class="list" (click)="childSelected(i)">
          <div class="pic"><img [src]="profile.profilePicture" /></div>
          <div class="name">{{profile.name | titlecase}}</div>
        </div>
        <!-- <div class="add" (click)="addStudent()">
                    <div class="div"><img src="../../../assets/add.svg"/></div>
                    <div class="txt">Add Student</div>
                </div>  -->
      </div>
    </ng-container>

    <ng-container *ngIf="selectedProfile">
      <div class="header">
        <div class="back" (click)="back()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="#ffffff"
              d="M15.013 6.758c-.065-.01-.132-.015-.198-.014H3.547l.245-.115c.24-.113.459-.268.646-.457l3.16-3.16c.416-.397.486-1.036.166-1.514-.373-.51-1.088-.62-1.598-.247-.04.03-.08.063-.117.098L.335 7.064c-.446.446-.447 1.17 0 1.616l5.714 5.715c.447.446 1.171.445 1.617-.002.035-.035.068-.073.098-.112.32-.478.25-1.117-.166-1.515L4.444 9.601c-.168-.168-.36-.309-.572-.417l-.343-.155h11.223c.584.022 1.096-.386 1.206-.96.101-.623-.322-1.21-.945-1.31z" />
          </svg>
        </div>
        <div class="txt">Enter you 4-digit pin</div>
      </div>

      <div class="select">
        <div class="user">
          <div class="picture"><img [src]="selectedProfile.profilePicture" /></div>
        </div>
        <div class="name">{{selectedProfile.name | titlecase}}</div>
      </div>

      <div class="pin">
        <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
      </div>

      <div class="forgot">
        <div>Forgot Pin?</div>
        <div class="click" (click)="forgotPin()">Click here</div>
      </div>

    </ng-container>
  </ng-container>
  <ng-container *ngIf="isSchool || isTeacher">
    <ng-container *ngIf="!isLoading && (schoolGroups && schoolGroups.length == 0)">
        <div class="placeholder">
          <div>You are not added to any school group</div>
          <button mat-button (click)="goToDashBoard()">GO TO DASHBOARD</button>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoading && (schoolGroups && schoolGroups.length > 0)">
      <div class="header">
        <div class="back" (click)="goBack()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="#ffffff"
              d="M15.013 6.758c-.065-.01-.132-.015-.198-.014H3.547l.245-.115c.24-.113.459-.268.646-.457l3.16-3.16c.416-.397.486-1.036.166-1.514-.373-.51-1.088-.62-1.598-.247-.04.03-.08.063-.117.098L.335 7.064c-.446.446-.447 1.17 0 1.616l5.714 5.715c.447.446 1.171.445 1.617-.002.035-.035.068-.073.098-.112.32-.478.25-1.117-.166-1.515L4.444 9.601c-.168-.168-.36-.309-.572-.417l-.343-.155h11.223c.584.022 1.096-.386 1.206-.96.101-.623-.322-1.21-.945-1.31z" />
          </svg>
        </div>
        <div class="txt">Choose your {{isSchool ? 'school' : 'teacher'}} group</div>
      </div>
      <div class="profile">
        <div *ngFor="let schoolGroup of schoolGroups; let i=index" class="list center-elements"
          (click)="selectSchool(schoolGroup)">
          <div class="pic school-pic"><img src="../../../assets/group.svg" /></div>
          <div class="name">{{schoolGroup.name | titlecase}}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
