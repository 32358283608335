import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OTP_MODE, PIN_MODE } from '../login.component';
@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.scss']
})
export class OtpLoginComponent implements OnInit {

  @Input() theme: 'student' | 'teacher' | 'admin';
  @Input() isOtp = false;
  @Input() isNumberError = false;
  @Input() isOTPError: boolean;
  @Input() otpError: string;
  @Input() isPinError: boolean;
  @Input() pinError: string;
  @Input() otp = '';
  @Input() pin = '';
  @Input() otpTimer = '';
  @Input() hasError;
  @Input() isPin = false;
  @Input() loginMode = OTP_MODE;
  @Output() phoneNumberInput = new EventEmitter();
  @Output() loginApp = new EventEmitter();
  @Output() pinLoginApp = new EventEmitter();
  @Output() verifyApp = new EventEmitter();
  @Output() verifyPin = new EventEmitter();
  @Output() inputChange = new EventEmitter();
  @Output() pinChange = new EventEmitter();
  @Output() sendOTP = new EventEmitter();
  inputNumber;


  constructor() { }

  ngOnInit(): void { }

  enterPhoneNumber(num) {
    this.phoneNumberInput.emit(num);
  }

  login() {
    if (this.loginMode !== PIN_MODE) {
      this.loginApp.emit();
    } else {
      this.pinLoginApp.emit();
    }
  }


  verify() {
    this.verifyApp.emit(this.otp);
  }

  pinVerify() {
    this.verifyPin.emit(this.pin);
  }

  onChange() {
    this.inputChange.emit(this.otp);
  }

  onPinChange() {
    this.pinChange.emit(this.pin);
  }

  sendOtp() {
    this.sendOTP.emit();
  }

}
