<ng-container *ngIf="isLoading; else questions">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #questions>
    <div class="test-container">
        <div class="test-wrapper" [class.fixed]="haveQuestion">
            <div class="header">
                <div>Create a test</div>
                <div class="close" (click)="close()"> <img src="../../../assets/close.svg" /> </div>
            </div>

            <div class="stepper">
                <div class="step1" [ngClass]="isAdminTheme ? 'admin-step-1': ''" *ngIf="currentStep === 1">
                    <div class="label">1</div>
                    <div class="txt">Info</div>
                </div>
                <div class="step1-active" [ngClass]="{ 'active' : currentStep === 2, 'admin-step-1-active': isAdminTheme }" *ngIf="currentStep === 2">
                    <div class="label">
                        <div class="label"><img src="../../../assets/info-mark.svg" /></div>
                        <div class="txt" [ngClass]="isAdminTheme ? 'admin-color': ''">Info</div>
                    </div>
                </div>
                <div class="angle" *ngIf="currentStep === 1">
                    <div class="block" [ngClass]="isAdminTheme ? 'admin-border': ''"></div>
                    <div class="block2" [ngClass]="isAdminTheme ? 'admin-border': ''"></div>
                </div>

                <div class="step2" [ngClass]="{ 'disabled' : currentStep === 1, 'step2-active' : currentStep === 2, 'admin-border': isAdminTheme, 'admin-background':  isAdminTheme && currentStep=== 2 }">
                    <div [ngClass]="{'admin-background': isAdminTheme, 'admin-step2-label': isAdminTheme &&  currentStep === 2}" class="label">2</div>
                    <div class="txt" [ngClass]="{ 'admin-color': isAdminTheme,  'active-admin-color': isAdminTheme && currentStep === 2}">Questions</div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="currentStep === 1; else step2">
            <form class="form" [formGroup]="testForm" (ngSubmit)="save()">
                <div class="form-inputs">
                    <div class="title">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>Title</mat-label>
                            <input matInput placeholder="Title" formControlName="title" autocomplete="off">
                            <mat-error *ngIf="testForm.controls['title'].errors?.required">Title is required</mat-error>
                            <mat-error *ngIf="testForm.controls['title'].errors?.maxlength">Title should be less than 100 characters long.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="description">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>Instructions</mat-label>
                            <textarea matInput rows="5" placeholder="Enter instruction for test" formControlName="instruction" autocomplete="off"></textarea>
                            <mat-error *ngIf="!testForm.controls['instruction'].valid && testForm.controls['instruction'].touched">Instruction can't be empty</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="group" *ngIf="!isCourseActivity">
                        <div class="date">
                            <mat-form-field appearance="outline" [floatLabel]="'always'">
                                <mat-label>Date</mat-label>
                                <input matInput placeholder="DD-MM-YY" (click)="picker.open()" [min]="minDate" [matDatepicker]="picker" (dateChange)="addEvent($event)">
                                <mat-error *ngIf="!testForm.controls['date'].valid && testForm.controls['date'].touched">Date can't be empty</mat-error>
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <mat-icon matDatepickerToggleIcon>
                                        <img src='../../../assets/calender.svg' />
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="time">
                            <mat-form-field appearance="outline" [floatLabel]="'always'">
                                <mat-label>Time</mat-label>
                                <input matInput placeholder="HH:MM" formControlName="time" autocomplete="off">
                                <mat-error *ngIf="!testForm.controls['time'].valid && testForm.controls['time'].touched">{{ getTimeError() }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="marks-group">
                        <div class="marks">
                            <mat-form-field appearance="outline" [floatLabel]="'always'">
                                <mat-label>Maximum Marks</mat-label>
                                <input matInput placeholder="Ex: 100, 70, etc" formControlName="marks" autocomplete="off">
                                <mat-error *ngIf="!testForm.controls['marks'].valid && testForm.controls['marks'].touched">{{ getMarksError() }}</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="pass">
                            <mat-form-field appearance="outline" [floatLabel]="'always'">
                                <mat-label>Pass Mark</mat-label>
                                <input matInput placeholder="Ex: 35" formControlName="pass" autocomplete="off">
                                <mat-error *ngIf="!testForm.controls['pass'].valid && testForm.controls['pass'].touched">{{ getPassMarksError() }}</mat-error>
                            </mat-form-field>
                        </div>

                    </div>

                    <ng-container>
                        <div class="library-checkbox" *ngIf="!isCourseActivity">
                            <mat-checkbox color="primary" [value]="checked" (change)="saveToLibrary($event)">Save to Library</mat-checkbox>
                        </div>
                    </ng-container>
                </div>


                <div class="btn">
                    <button [ngClass]="isAdminTheme ? 'admin-background': ''" mat-flat-button type="submit" [disabled]="isSubmitted">SAVE & CONTINUE</button>
                </div>
            </form>
        </ng-container>

        <ng-template #step2>
            <app-test-question class="test-question-container" [totalTestMarks]="testDetail.totalMarks" [isAdminTheme]="isAdminTheme" [testDetail]="testDetail" [isQuestionCreated]="data.test ? 'true' : 'false'" [questionList]="questionList" (onQuestionCreated)="onQuestionCreated($event)"
                (onSaveTest)="saveTest($event)"></app-test-question>
        </ng-template>
    </div>
</ng-template>
