import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { minDate, timeregex, positiveNmuberRegex, COURSE_ACTIVITY, ACTIVITY_TYPES, ADMIN_THEME } from 'src/app/Constants/Constant';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClassDetailComponent } from '../class-detail/class-detail.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Router } from '@angular/router';
import { TestService } from '../services/test.service';
import { AlertDialogComponent } from 'src/app/shared-components/alert-dialog/alert-dialog.component';
import { LibraryService } from '../services/library.service';
import { zip } from 'rxjs';
import { DateTimeService } from 'src/app/services/date-time.service';
import { CourseActivitiesService } from 'src/app/admin/services/course-activities.service';

export interface TestMetaDetailModel {
  title: string,
  instruction?: string,
  time?: string,
  date?: Date,
  totalMarks: number,
  passMarks?: number
}

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  testForm: FormGroup;
  haveQuestion = false;
  minDate: Date;
  currentStep = 1;
  isSubmitted = false;
  testDetail: TestMetaDetailModel;
  selected = 'AM';
  checked = false;
  questionList = [];
  isLoading = false;

  isAdminTheme = false;
  isCourseActivity = false;

  constructor(
    public fb: FormBuilder,
    public snackbar: SnackbarService,
    public router: Router,
    public testService: TestService,
    public dateservice: DateTimeService,
    public LibraryService: LibraryService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ClassDetailComponent>,
    public cd: ChangeDetectorRef,
    private courseActivitiesSvc: CourseActivitiesService,
  ) { }

  ngOnInit(): void {
    this.isCourseActivity = this.data.type === COURSE_ACTIVITY ? true : false;
    this.isAdminTheme = this.data.theme === ADMIN_THEME ? true : false;
    this.dialogRef.updatePosition({ right: `0px` });
    this.minDate = minDate;
    this.data.test ? this.populateForm(this.data.test.id) : null;
    this.buildForm();
  }

  buildForm() {
    if (!this.isCourseActivity) {
      this.testForm = this.fb.group({
        title: [null, [Validators.required, Validators.maxLength(100)]],
        instruction: [null, Validators.required],
        time: [null, [Validators.required, Validators.pattern(timeregex)]],
        date: [null, Validators.required],
        marks: [null, [Validators.required, Validators.maxLength(4), Validators.pattern(positiveNmuberRegex)]],
        pass: [null, [Validators.required, Validators.maxLength(4), Validators.pattern(positiveNmuberRegex)]]
      });
    } else {
      this.testForm = this.fb.group({
        title: [null, [Validators.required, Validators.maxLength(100)]],
        instruction: [null, Validators.required],
        marks: [null, [Validators.required, Validators.maxLength(4), Validators.pattern(positiveNmuberRegex)]],
        pass: [null, [Validators.required, Validators.maxLength(4), Validators.pattern(positiveNmuberRegex)]]
      });
    }
  }

  populateForm(testID) {
    this.LibraryService.getContents(testID, false).subscribe(
      (res) => {
        this.getQuestionDetails(res);
      },
      (err) => {
        this.snackbar.openSnackbar('Unable to get the test details.')
        this.isLoading = false;
      }
    )
  }

  getQuestionDetails(res) {
    const questionURL = this.testService.fetchFromGcs(res.signedURL);
    const answerURL = this.testService.fetchFromGcs(res.testAnsURL);
    zip(questionURL, answerURL).subscribe(
      (questions) => {
        this.questionList = this.testService.mapQuestionWithAnswer(questions);
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.snackbar.openSnackbar('Unable to fetch the test details, please refresh your page')
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  onQuestionCreated(event) {
    this.haveQuestion = event;
    this.cd.detectChanges();
  }

  addEvent(event) {
    this.testForm.controls['date'].setValue(event.value)
  }

  saveToLibrary(event) {
    this.checked = !this.checked;
  }

  getTimeError() {
    return this.testForm.get('time').hasError('required') ? 'Time is required' :
      this.testForm.get('time').hasError('pattern') ? 'Not a valid time, please add time in the format (HH:MM)' : null
  }

  getMarksError() {
    if (this.testForm.get('marks').hasError('maxlength')) {
      return 'maximum marks cannot exceeds 10000';
    }
    return this.testForm.get('marks').hasError('required') ? 'Marks is required' :
      this.testForm.get('marks').hasError('pattern') ? 'Only positive number can be entered' : null
  }

  getPassMarksError() {
    return this.testForm.get('pass').hasError('required') ? 'Pass marks is required' :
      this.testForm.get('pass').hasError('pattern') ? 'Only positive number can be entered' : null
  }

  save() {
    if (parseInt(this.testForm.get('pass').value) > parseInt(this.testForm.get('marks').value)) {
      this.snackbar.openSnackbar('passing marks cannot be greater than maximum marks');
      return;
    }
    if (this.testForm.valid) {
      if (!this.isCourseActivity) {
        this.testForm.controls['time'].setValue(this.testForm.controls['time'].value.concat(this.selected));
      }
      this.testDetail = {
        title: this.testForm.controls['title'].value,
        totalMarks: this.testForm.controls['marks'].value,
        passMarks: this.testForm.controls['pass'].value
      }
      this.currentStep = this.currentStep + 1;
    }
    else {
      this.snackbar.openSnackbar('Please fill all the details');
    }
  }

  checkForMarksValidation(questions) {
    let marksTotal = 0
    questions.map(question => marksTotal += question.marks);
    if (parseInt(this.testDetail.totalMarks.toString()) !== marksTotal) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '363px',
        height: '190px',
        backdropClass: 'blur',
        data: {
          alertText: `Totalmarks (${this.testDetail.totalMarks}) is not equal to sum of marks (${marksTotal}) alloted to questions!!`,
          theme: this.isAdminTheme ? 'admin' : ''
        },
        panelClass: ['delete-dialog-css'],
        autoFocus: false
      });
      return false;
    }
    return true;
  }

  saveTest(questions) {
    this.isLoading = true;
    if (!this.checkForMarksValidation(questions)) {
      this.isLoading = false;
      return;
    }
    const url = this.router.url.split('/');
    const classID = url[url.length - 1];
    const timeStamp = this.isCourseActivity ? null :
      this.dateservice.createTimeStamp(this.testForm.controls['time'].value, this.testForm.controls['date'].value);
    const requestBody = this.data.type === COURSE_ACTIVITY ? {
      title: this.testDetail.title,
      courseID: this.data.courseID,
      totalMarks: this.testDetail.totalMarks,
      passMarks: this.testDetail.passMarks,
      activityType: ACTIVITY_TYPES.tests,
      uploadFromCloud: false,
      index: this.data.activityIndex,
    } : {
        title: this.testDetail.title,
        classID: classID,
        timings: timeStamp.toISOString(),
        totalMarks: this.testDetail.totalMarks,
        passMarks: this.testDetail.passMarks,
        saveToLibrary: this.checked,
        uploadFromLibrary: this.data.test ? true : false
      };


    if (this.data.test && !this.isCourseActivity) {
      requestBody['quesFilePath'] = this.data.test.name;
      requestBody['ansFilePath'] = this.data.test.testAnsFile;
    }
    let answers = [];
    questions.map((ques, index) => ques.number = index);
    questions.map(question => {
      let correctAns = []
      question.options.filter((option, index) => {
        if (option.isCorrect) {
          correctAns.push(index)
        }
      })
      answers.push({ 'questionNumber': question.number, 'correctAnswers': correctAns })
    })
    questions.map(ques => {
      ques.options.filter((option, index) => {
        option = { option: option.option, image: option.image };
        ques.options[index] = option;
      })
    })

    questions.push({ instruction: this.testForm.controls['instruction'].value });
    if (!this.isCourseActivity) {
      this.testService.createTest(requestBody, questions, answers).subscribe(
        (res) => {
          this.isLoading = false;
          this.snackbar.openSnackbar("Test created successfully");
          this.dialogRef.close('true');
        },
        (err) => {
          this.isLoading = false;
          this.snackbar.openSnackbar(`Unable to create test!! Please try again ${err.error.error}`)
        }
      );
    } else {
      this.courseActivitiesSvc.postCourseActivity(requestBody, questions, answers).subscribe(
        (res) => {
          this.isLoading = false;
          this.snackbar.openSnackbar('Test created successfully');
          this.dialogRef.close({ test: res });
        },
        (err) => {
          this.isLoading = false;
          this.snackbar.openSnackbar(`Unable to create test!! Please try again ${err.error.error}`)
        }
      );
    }
  }

}
