import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { PostDetailService } from '../services/post-detail.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PostDetail } from 'src/app/models/post-detail.models';
import { MaterialsDetail } from 'src/app/models/material.models';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewDocsComponent } from 'src/app/shared/view-docs/view-docs.component';
import { CourseActivitiesService } from 'src/app/admin/services/course-activities.service';
import { MATERIAL } from 'src/app/students/child-dashboard/class-post/class-post.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FindSchoolUserRoleService } from 'src/app/services/find-school-user-role.service';

const MATERIALS = 'materials';

export interface docsPreviewList {
  signedUrl: string,
  name: string
}

@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.scss']
})
export class PostDetailsComponent implements OnInit, OnDestroy {

  text: 'Assignment' | 'Material';
  displayedColumns: any;
  postDetail = [];
  post = {};
  previewDocsList: docsPreviewList[] = [];
  isLoading: boolean = true;

  public isCourseActivity: boolean;
  public courseActivity: any;
  public courseActivityDetail: any;
  public courseActivityId: string;
  public role = '';
  private destroy$$: Subject<any> = new Subject<any>();
  public disableTableActions = false;
  constructor(
    public _location: Location,
    public router: Router,
    public postService: PostDetailService,
    public sidenav: ToggleSidenavService,
    public dialog: MatDialog,
    public snackbar: SnackbarService,
    private courseActivitiesSvc: CourseActivitiesService,
    private route: ActivatedRoute,
    private userRole: FindSchoolUserRoleService
  ) { }

  ngOnInit(): void {
    this.userRole.userRole$.pipe(
      takeUntil(this.destroy$$)
    )
      .subscribe(role => {
        this.role = role;
        if (this.role === 'member') {
          this.disableTableActions = true;
        }
      });
    this.sidenav.minmizeSidenav();
    const urlArray = this.router.url.split('/');
    this.isCourseActivity = urlArray.includes('course-activity');
    if (this.isCourseActivity) {
      this.route.params.pipe(
        takeUntil(this.destroy$$)
      ).subscribe((params) => {
        if (params.name) {
          this.text = params.name === MATERIALS ? 'Material' : 'Assignment';
          this.courseActivityId = urlArray[urlArray.length - 1];
          this.getCourseActivityDetails();
        }
      });
    } else {
      const postID = urlArray[urlArray.length - 1];
      urlArray.includes(MATERIALS) ? this.getMaterialList(postID) : this.getAssignmentsList(postID);
    }
  }

  getMaterialList(postID) {
    this.postService.getMaterials(postID).subscribe(
      (res: MaterialsDetail) => {
        this.isLoading = false;
        this.post = { title: res.title, dueDate: res.dueDate, attachment: res.fileCount, description: res.description };
        this.populateFileList(res);
        this.postDetail = res.materialStats;
      },
      (err) => {
        this.isLoading = false;
        this.snackbar.openSnackbar(err.error.error)
      }
    )
    // FOR DEMO: it will be reverted later
    // this.displayedColumns = ['select', 'name','status', 'date','actions'];
    this.displayedColumns = ['name', 'mobileNumber', 'date', 'status'];
    this.text = 'Material';
  }

  getAssignmentsList(postID) {
    this.postService.getAssignments(postID).subscribe(
      (res: PostDetail) => {
        this.isLoading = false;
        this.post = {
          title: res.title, submissionDate: res.submissionDate, attachment: res.fileCount,
          marks: res.totalMarks, description: res.description
        };
        this.populateFileList(res);
        this.postDetail = res.submissions;
      },
      (err) => {
        this.isLoading = false;
        this.snackbar.openSnackbar(err.error.error)
      }
    )
    // this.displayedColumns = ['select', 'name', 'date', 'status', 'file', 'evaluation', 'marks', 'actions'];
    this.displayedColumns = ['name', 'mobileNumber', 'date', 'status', 'file', 'evaluation', 'marks'];
    this.text = 'Assignment';
  }

  populateFileList(response) {
    response.files.map((file: any, index) => {
      this.previewDocsList.push({ name: file, signedUrl: response.signedURLs[index] })
    });
  }


  back() {
    this._location.back();
  }

  onPreviewSelect(event) {
    const dialog = this.dialog.open(ViewDocsComponent, {
      width: '95%',
      height: '100%',
      data: { files: this.previewDocsList, type: this.text.toLowerCase() },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['teacher-theme', 'full-width', 'preview-doc-container'],
      autoFocus: false
    });
  }

  private getCourseActivityDetails() {
    this.setDisplayColumns();
    this.courseActivitiesSvc.getCourseActivity(this.courseActivityId);
    this.courseActivitiesSvc.selectedCourseActivity$.pipe(
      takeUntil(this.destroy$$)
    ).subscribe((courseActivityDetails) => {
      if (courseActivityDetails) {
        this.courseActivity = courseActivityDetails;
        this.courseActivityDetail = [...courseActivityDetails.submissions];
        this.populateFileList(this.courseActivity);
      }
    });
    this.courseActivitiesSvc.isLoading$.pipe(
      takeUntil(this.destroy$$)
    ).subscribe((isLoading) => this.isLoading = isLoading);
  }

  private setDisplayColumns() {
    // this.displayedColumns = this.text === 'Material' ? ['name', 'status', 'date', 'actions'] :
    //   ['name', 'date', 'status', 'file', 'evaluation', 'marks', 'actions'];

    this.displayedColumns = this.text === 'Material' ? ['name', 'date', 'status'] :
      ['name', 'date', 'status', 'file', 'evaluation', 'marks'];
  }

  ngOnDestroy() {
    this.courseActivitiesSvc.reset();
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }

}
