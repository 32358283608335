import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClassesComponent } from './classes/classes.component';
import { ClassDetailComponent } from './class-detail/class-detail.component';
import { PostDetailsComponent } from './post-details/post-details.component';
import { PreviewComponent } from './preview/preview.component';
import { ReviewAssignmentComponent } from './review-assignment/review-assignment.component';
import { TestSolutionComponent } from './test-solution/test-solution.component';
import { AuthGuard } from '../services/auth.guard';
import { LibraryComponent } from './library/library.component';
import { LiveClassComponent } from './live-class/live-class.component';
import { LiveClassScheduleComponent } from '../shared/live-class/live-class.component';
import { ChooseAccountComponent } from '../students/choose-account/choose-account.component';
import { LoginModule } from '../login/login.module';
import { ProfileModule } from '../profile/profile.module';

const routes: Routes = [
  { path: 'login', loadChildren: () => LoginModule },
  { path: 'choose', component: ChooseAccountComponent, canActivate: [AuthGuard] },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: ':type/evaluate/:assignmentId/:studentId/:canEdit', component: ReviewAssignmentComponent, canActivate: [AuthGuard] },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
    children: [
      { path: 'classes', component: ClassesComponent },
      { path: 'library', component: LibraryComponent },
      { path: 'class-detail/:id', component: ClassDetailComponent },
      { path: 'live-class-schedule', component: LiveClassScheduleComponent },
      { path: 'preview', component: PreviewComponent },
      { path: 'post-details/:name/:id', component: PostDetailsComponent },
      { path: 'class/test/:id', component: TestSolutionComponent },
      { path: 'profile', loadChildren: () => ProfileModule },
      { path: '', redirectTo: 'classes', pathMatch: 'full' }
    ]
  },
  { path: 'live-class/:id', component: LiveClassComponent,canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeachersRoutingModule { }
