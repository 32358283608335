import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Sort, MatSort, MatSortable } from '@angular/material/sort';
import { schoolTeachers } from 'src/app/models/school-table.model';
import { SchoolService } from 'src/app/services/api/school.service';
import { MatPaginator } from '@angular/material/paginator';
import { pageSizeOptions, paginatorPageSize, ACTIVE_STATUS, INACTIVE_STATUS } from 'src/app/Constants/Constant';
import { SelectedSchoolService } from 'src/app/services/selected-school.service';
import { FindSchoolUserRoleService } from 'src/app/services/find-school-user-role.service';
import { TeacherService } from 'src/app/services/api/teacher.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ClassSpecificService } from 'src/app/teachers/services/class-specific.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LabelService } from 'src/app/services/label.service';
import { Course } from 'src/app/models/course.model';
import { MatDialog } from '@angular/material/dialog';
import { CourseCopyDialogComponent } from '../course-copy-dialog/course-copy-dialog.component';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy, OnChanges {
  sortedData: any;
  @Input() displayedColumns: string[];
  @Input() transactions;
  @Input() type;
  @Input() sortDirection: 'asc' | 'desc';
  @Output() getSortDirection = new EventEmitter();
  @Output() navigateToCourse = new EventEmitter();

  @Output() OnEditCourse = new EventEmitter();
  @Output() OnCourseStatusChange = new EventEmitter();
  userID: string;

  dataSource;
  pageSizeOptions: number[] = pageSizeOptions;
  pageSize = paginatorPageSize;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  selection = new SelectionModel(true, []);
  role;
  selectedSchool;
  labelData = {};
  private destroy$$: Subject<any> = new Subject<any>();
  constructor(
    public schoolService: SchoolService,
    public cd: ChangeDetectorRef,
    private selectedSchoolService: SelectedSchoolService,
    private userRole: FindSchoolUserRoleService,
    private teacherService: TeacherService,
    private snackbar: SnackbarService,
    private classSpecificService: ClassSpecificService,
    private route: ActivatedRoute,
    private labelService: LabelService,
    public dialog: MatDialog,
    public labelS: LabelService,
  ) { }

  ngOnInit(): void {
    this.userID = localStorage.getItem('id');
    this.labelService.labels$.pipe(takeUntil(this.destroy$$)).subscribe(data => this.labelData = data);
    this.userRole.userRole$.subscribe(data => {
      this.role = data;
      console.log(this.role, 'role in app-table class detail')
    })
    this.selectedSchoolService.selectedSchool$.subscribe(data => {
      console.log(data, 'selected school');
      this.selectedSchool = data;
      if (data) {
        this.userRole.setRoleType(data);
      }
    });
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.transactions);
    this.cd.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.sort.sort(({ id: 'date', start: this.sortDirection }) as MatSortable);
    this.dataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: schoolTeachers): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  sortData(sort: Sort) {
    const data = this.transactions.slice();
    if (sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.getSortDirection.emit(sort.direction);
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a.date, b.date, isAsc);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  makeActiveGroup(id?) {
    let ids = this.selection.selected.map(a => a.schoolID);
    console.log(this.selection.selected);
  }

  changeTeacherStatus(data) {
    console.log(data)
    let statusMessage: string;
    let status = data.status;
    if (status === ACTIVE_STATUS) {
      status = INACTIVE_STATUS;
      statusMessage = 'inactivated';
    } else {
      status = ACTIVE_STATUS;
      statusMessage = 'activated';
    }
    this.teacherService.changeTeacherStatus(data.schoolID, data.teacherID, status).subscribe(data => {
      this.snackbar.openSnackbar(`Teacher ${statusMessage} successfully`);
      this.teacherService.getClasses();
      this.selectedSchoolService.getAllSchoolTeachers()
    }, err => {
      if (err.error.error.includes('This action cannot be performed on archived school')) {
        this.snackbar.openSnackbar('This action cannot be performed on archived school')
      } else {
        this.snackbar.openSnackbar(err.error.error)
      }
    });
  }

  changeStaffSatus(data) {
    let statusMessage: string;
    let status = data.status;
    if (status === ACTIVE_STATUS) {
      status = INACTIVE_STATUS;
      statusMessage = 'inactivated';
    } else {
      status = ACTIVE_STATUS;
      statusMessage = 'activated';
    }
    this.teacherService.changeStaffSatus(data.schoolID, data.staffID, status).subscribe(data => {
      this.snackbar.openSnackbar(`Staff ${statusMessage} successfully`);
      this.teacherService.getClasses();
      this.selectedSchoolService.getAllSchoolStaff()
    }, err => {
      if (err.error.error.includes('This action cannot be performed on archived school')) {
        this.snackbar.openSnackbar('This action cannot be performed on archived school')
      } else {
        this.snackbar.openSnackbar(err.error.error)
      }
    });
  }

  changeStudentSatus(data) {
    let statusMessage: string;
    let status = data.status;
    if (status === ACTIVE_STATUS) {
      status = INACTIVE_STATUS;
      statusMessage = 'marked inactive';
    } else {
      status = ACTIVE_STATUS;
      statusMessage = 'marked active';
    }
    this.teacherService.changeStudentSatus(data.classID, data.studentID, status).subscribe(data => {
      this.snackbar.openSnackbar(`Student ${statusMessage} successfully`);
      this.teacherService.getClasses();
      this.route.params.pipe(
        takeUntil(this.destroy$$)
      )
        .subscribe((param: any) => {
          if (param.id) {
            this.classSpecificService.getClassDetail(param.id)
              .subscribe(
                (res) => {
                  this.classSpecificService.getStudents(res.id);
                }
              ),
              (err => this.snackbar.openSnackbar(err.error.error))
          }
        })
    }, err => {
      this.snackbar.openSnackbar(`${err.error.error}`);
    });
  }

  public changeCourseStatus(course: Course) {
    this.OnCourseStatusChange.emit(course);
  }

  public editCourse(course: Course) {
    this.OnEditCourse.emit(course);
  }

  public goToCourse(course: Course) {
    this.navigateToCourse.emit(course);
  }

  public copyCourse(course: Course) {
    this.dialog.open(CourseCopyDialogComponent, {
      width: '46%',
      height: '100%',
      data: { courseId: course.id, schoolId: course.schoolID },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['admin-theme', 'width-class'],
      autoFocus: false
    });

  }

  public toggleSubjects(index) {
    this.transactions[index].subjectsOpen = this.transactions[index].subjectsOpen ? false : true;
  }

  public closeSubjects(index) {
    this.transactions[index].subjectsOpen = false;
  }

  getSubjectsString(subjects: string[]) {
    let subjectsString = '';
    subjects.forEach((subject, index) => {
      if (index !== (subjects.length - 1)) {
        subjectsString = subjectsString + `${subject}, `;
      } else {
        subjectsString = subjectsString + `${subject}`;
      }
    });
    return subjectsString;
  }

  courseClicked(course) {
    this.navigateToCourse.emit(course);
  }
  ngOnDestroy() {
    this.destroy$$.unsubscribe();
  }
}
