import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LabelService } from './services/label.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'shiksha';
  constructor(private router: Router, private labelS: LabelService, updates: SwUpdate) {
    this.labelS.getLabels();

    updates.available.subscribe(event => {
      updates.activateUpdate().then(() => document.location.reload());
    });
  }
}
