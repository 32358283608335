import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeachersRoutingModule } from './teachers-routing.module';
// import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClassesComponent } from './classes/classes.component';
import { CreateClassComponent } from './create-class/create-class.component';
import { ClassDetailComponent } from './class-detail/class-detail.component';
import { ClassCardComponent } from './class-card/class-card.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PostAssignmentComponent } from './post-assignment/post-assignment.component';
import { PostMaterialComponent } from './post-material/post-material.component';
import { PostDetailsComponent } from './post-details/post-details.component';
import { PostDetailTableComponent } from './post-detail-table/post-detail-table.component';
import { SendMessageComponent } from './send-message/send-message.component';
import { PostTableComponent } from './post-table/post-table.component';
import { TestComponent } from './test/test.component';
import { TestQuestionComponent } from './test-question/test-question.component';
import { AddQuestionComponent, AlertDialog } from './add-question/add-question.component';
import { PreviewComponent } from './preview/preview.component';
import {ReviewAssignmentComponent} from './review-assignment/review-assignment.component';
import { AssignmentCanvasComponent } from './assignment-canvas/assignment-canvas.component';
import { TestSolutionComponent } from './test-solution/test-solution.component';
import { TestSolutionTableComponent } from './test-solution-table/test-solution-table.component';
import { FileCanvasComponent } from './file-canvas/file-canvas.component';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import { LibraryComponent } from './library/library.component';
import { UploadFromLibraryComponent } from './upload-from-library/upload-from-library.component';
import { ScheduleLiveClassComponent } from './schedule-live-class/schedule-live-class.component';
import { LiveClassDashboardComponent } from './live-class-dashboard/live-class-dashboard.component';
import { LiveClassComponent } from './live-class/live-class.component';
import { PipesModule } from '../pipes/pipes.module';
import { EditClassComponent } from './edit-class/edit-class.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';


@NgModule({
  declarations: [
    DashboardComponent,
    ClassesComponent,
    CreateClassComponent,
    ClassDetailComponent,
    ClassCardComponent,
    PostAssignmentComponent,
    PostMaterialComponent,
    PostDetailsComponent,
    PostDetailTableComponent,
    SendMessageComponent,
    PostTableComponent,
    TestComponent,
    TestQuestionComponent,
    AddQuestionComponent,
    PreviewComponent,
    ReviewAssignmentComponent,
    AssignmentCanvasComponent,
    TestSolutionComponent,
    TestSolutionTableComponent,
    FileCanvasComponent,
    LibraryComponent,
    UploadFromLibraryComponent,
    AlertDialog,
    ScheduleLiveClassComponent,
    LiveClassDashboardComponent,
    LiveClassComponent,
    EditClassComponent,
  ],
  imports: [
    CommonModule,
    TeachersRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MaterialModule,
    NgbCarouselModule,
    PipesModule,
    SharedComponentsModule
  ]
})
export class TeachersModule { }
