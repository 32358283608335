<div class="users-wrapper bordered-box" [ngClass]="updatingState ? 'users-filter': ''">
    <div class="users-header">
        <h3>Users</h3>
    </div>
    <div class="tabs">
        <mat-tab-group (selectedTabChange)="tabSelected($event)">
            <mat-tab *ngFor="let tab of tabs">
                <ng-template mat-tab-label>{{ tab }}</ng-template>

                <ng-container *ngIf="tab === tabs[0]">
                  <app-spinner *ngIf="isLoading"></app-spinner>
                  <div *ngIf="!isLoading &&  creators && creators.length == 0" class="placeholder">
                      <p>No Data Found</p>
                  </div>
                  <app-users-table
                    *ngIf="!isLoading &&  creators && creators.length > 0"
                    [displayedColumns]="adminColumns"
                    [transactions]="creators"
                    (statusChange)="changeCreatorStatus($event)"
                    (mobileNumebrChange)="changeCreatorMobileNumber($event)"
                    (accessRightChange)="changeAccessRights($event)"
                  ></app-users-table>
                </ng-container>

                <ng-container *ngIf="tab === tabs[1]">
                  <app-spinner *ngIf="isLoading"></app-spinner>
                  <div *ngIf="!isLoading &&  instructors && instructors.length == 0" class="placeholder">
                    <p>No Data Found</p>
                  </div>
                  <app-users-table
                    *ngIf="!isLoading &&  instructors && instructors.length > 0"
                    [displayedColumns]="instructorsColumns"
                    [transactions]="instructors"
                  ></app-users-table>
                </ng-container>

                <ng-container *ngIf="tab === tabs[2]">
                  <app-spinner *ngIf="isLoading"></app-spinner>
                  <div *ngIf="!isLoading &&  learners && learners.length == 0" class="placeholder">
                      <p>No Data Found</p>
                  </div>
                  <app-users-table
                    *ngIf="!isLoading && learners && learners.length > 0"
                    [displayedColumns]="learnerColumns"
                    [transactions]="learners"
                  ></app-users-table>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<div *ngIf="updatingState" class="status-update-backdrop">
  <app-spinner></app-spinner>
</div>
