<div [ngClass]="{'disable': disabled}">
    <div *ngIf="showDialog">
        <app-insert-file-dialog [isCloudContent]="isCourseActivity" [postType]="postType" [fileType]="fileType" (action)="close($event)"></app-insert-file-dialog>
    </div>
    <div [ngStyle]="isdocUploaded === false && {'border': '2px solid #ff8156', 'border-radius': '12px', 'color': '#ff8156'}">
        <ng-container *ngIf="type != 'solution'; else solutionInput">
            <div class="avatar" (click)="getFiles()">
                <div class="img">
                    <img [src]="fileNames && fileNames.length > 0 ? getFile() : '../../../assets/docFile.svg'" />
                </div>
                <ng-container *ngIf="text.split(' ')[0].toLowerCase() !== 'browse'; else browseInput">
                    <div class="input">
                        <div [ngClass]="userType === userTypes.admin ? 'admin-color': userType === userTypes.student ? 'student-color' : ''" class="txt">{{text}}</div>
                        <input #fileInput type="file" [disabled]="disabled" id="file" name="file" accept="*" multiple="multiple" (click)="fileInput.value = null; $event.stopPropagation()" value="" (change)="fileChangeEvent($event)" />
                    </div>
                </ng-container>

                <ng-template #browseInput>
                    <div class="input">
                        <div [ngClass]="userType === userTypes.admin ? 'admin-color': userType === userTypes.student ? 'student-color' : ''" class="txt">{{text}}</div>
                        <input #fileInput type="file" [disabled]="disabled" id="file" name="file" accept="*" (click)="fileInput.value = null" value="" (change)="fileChangeEvent($event)" />
                    </div>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #solutionInput>
            <label class="avatar" for="file">
                <div class="img">
                    <img [src]="fileNames && fileNames.length > 0 ? getFile() : '../../../assets/docFile.svg'"/>
                </div>
                <ng-container *ngIf="text.split(' ')[0].toLowerCase() !== 'browse'; else browseInput">
                    <div class="input">
                        <div [ngClass]="userType === userTypes.admin ? 'admin-color': userType === userTypes.student ? 'student-color' : ''"class="txt">{{text}}</div>
                        <input #fileInput type="file" [disabled]= "disabled" id="file" name="file" accept="*" multiple="multiple" (click)="fileInput.value = null; $event.stopPropagation()" value="" (change)="fileChangeEvent($event)"/>
                    </div>
                </ng-container>

                <ng-template #browseInput>
                    <div class="input">
                        <div [ngClass]="userType === userTypes.admin ? 'admin-color': userType === userTypes.student ? 'student-color' : ''"class="txt">{{text}}</div>
                        <input #fileInput type="file" [disabled]= "disabled" id="file" name="file" accept="*" (click)="fileInput.value = null" value=""  (change)="fileChangeEvent($event)" />
                    </div>
                </ng-template>
            </label>
        </ng-template>
    </div>

    <ng-container *ngIf="fileNames && fileNames.length === 0; else files">
        <ng-container *ngIf="text.split(' ')[0].toLowerCase() !== 'browse'">
            <div class="note">Example: Word Documents, Text File, PDF, Video, Audio etc</div>
        </ng-container>
    </ng-container>

    <ng-template #files>
        <div class="lists" *ngFor="let file of fileNames">
            <div>{{fileNameFormat(file.name)}}</div>
            <div (click)="deleteFile(file)" class="delete"><img [ngClass]="{'disable': disabled}" [src]=getDeleteImage() /></div>
        </div>
    </ng-template>
    <ng-container *ngIf="text.split(' ')[0].toLowerCase() !== 'browse'">
        <div *ngIf="duplicateError" class="error">you have selected the duplicate file</div>
        <div class="text" [ngClass]="{'isUploaded' : fileNames && fileNames.length > 0}">*You can upload multiple files</div>
    </ng-container>
</div>
