import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersComponent } from '../users.component';
import { AppUsersService } from '../services/app-users.service';
import { CHANGE_MOBILE_NUMBER_ACTION, UPDATE_ACCESS_RIGHTS_ACTION } from 'src/app/Constants/users-view';
import { SnackbarService } from 'src/app/services/snackbar.service';

const canCreateSchools = 'can create schools';
const canNotCreateSchools = 'can not create schools';

@Component({
  selector: 'app-user-settings-dialog',
  templateUrl: './user-settings-dialog.component.html',
  styleUrls: ['./user-settings-dialog.component.scss']
})
export class UserSettingsDialogComponent implements OnInit {

  title: string;
  userDetailsForm: FormGroup;
  accessRights: any[] = [{ text: canCreateSchools, value: true }, { text: canNotCreateSchools, value: false }];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<UsersComponent>,
    private usersService: AppUsersService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    if (this.data.action === UPDATE_ACCESS_RIGHTS_ACTION) {
      this.userDetailsForm = this.fb.group({
        name: new FormControl({ value: this.data.user.name, disabled: true }),
        mobileNumber: new FormControl({ value: this.data.user.mobileNumber, disabled: true }),
        maxSchools: new FormControl(this.data.user.accessRights.maxSchools || 0, [Validators.required]),
        schools: new FormControl(this.data.user.accessRights.schools, [Validators.required]),
        assignments: new FormControl(this.data.user.accessRights.assignments, [Validators.required]),
        materials: new FormControl(this.data.user.accessRights.materials, [Validators.required]),
        tests: new FormControl(this.data.user.accessRights.tests, [Validators.required]),
        liveClasses: new FormControl(this.data.user.accessRights.liveClasses, [Validators.required])
      });
    } else {
      this.userDetailsForm = this.fb.group({
        name: new FormControl({ value: this.data.user.name, disabled: true }),
        mobileNumber: new FormControl(
          this.data.user.mobileNumber,
          [Validators.required, Validators.min(1000000000), Validators.max(9999999999)]),
        maxSchools: new FormControl({ value: this.data.user.accessRights.maxSchools || 0, disabled: true }),
        schools: new FormControl({ value: this.data.user.accessRights.schools, disabled: true }),
        assignments: new FormControl({ value: this.data.user.accessRights.assignments, disabled: true }),
        materials: new FormControl({ value: this.data.user.accessRights.materials, disabled: true }),
        tests: new FormControl({ value: this.data.user.accessRights.tests, disabled: true }),
        liveClasses: new FormControl({ value: this.data.user.accessRights.liveClasses, disabled: true })
      });
    }
    this.dialogRef.updatePosition({ right: `0px` });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  submit() {
    if (this.userDetailsForm.valid) {
      if (this.data.action === UPDATE_ACCESS_RIGHTS_ACTION) {
        const accessRightsVal = {
          maxSchools: this.userDetailsForm.controls.maxSchools.value,
          schools: this.userDetailsForm.controls.schools.value,
          assignments: this.userDetailsForm.controls.assignments.value,
          materials: this.userDetailsForm.controls.materials.value,
          tests: this.userDetailsForm.controls.tests.value,
          liveClasses: this.userDetailsForm.controls.liveClasses.value,
        };
        this.usersService.updateAccessRights(this.data.user.id, accessRightsVal)
          .subscribe(() => {
            this.snackbar.openSnackbar('access rights updated successfully');
            this.dialogRef.close({
              accessRights: accessRightsVal
            });
          }, (err) => {
            if (err.error.error) {
              this.snackbar.openSnackbar(err.error.error);
              return;
            }
            this.snackbar.openSnackbar('unable to change access rights of the creator');
          });
      } else {
        const mobileNumVal = `${this.userDetailsForm.controls.mobileNumber.value}`;
        this.usersService.updateCreatorMobileNumber(this.data.user.id, mobileNumVal)
          .subscribe(() => {
            this.snackbar.openSnackbar('mobile number updated successfully');
            this.dialogRef.close({ mobileNumber: mobileNumVal });
          }, (err) => {
            if (err.error.error) {
              this.snackbar.openSnackbar(err.error.error);
              return;
            }
            this.snackbar.openSnackbar('unable to change mobile number of the creator');
          });
      }
    }
  }

}
