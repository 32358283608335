<div class="wrapper">
    <div class="table-container" [ngClass]="reordering ? 'blur' : ''">
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8" cdkDropList #table1 #list1="cdkDropList" cdkDropListConnectedTo="table1" [cdkDropListData]="dataSource" (cdkDropListDropped)="drop($event)" id="table1" [cdkDropListEnterPredicate]="canDrop">

            <ng-container matColumnDef="seq">
                <mat-header-cell *matHeaderCellDef class="seq">Seq. No.</mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" class="seq">{{ element.index }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef class="title"> Title </mat-header-cell>
                <mat-cell *matCellDef="let element" class="title">{{element.title}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef class="date"> Date </mat-header-cell>
                <mat-cell *matCellDef="let element" class="date">{{element.createdAt | date: dateFormat}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef class="type">Type</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="activity">
                        <div>{{element.activityType | titlecase}}</div>
                        <div class="dot"></div>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="completedBy">
                <mat-header-cell *matHeaderCellDef class="completedBy">Completed By</mat-header-cell>
                <mat-cell *matCellDef="let element" class="completedBy">{{element.totalSubmissions ? element.totalSubmissions : '0/0'}}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="actions">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="action-table-header">Actions</mat-header-cell>
                <mat-cell (click)="$event.stopPropagation();$event.preventDefault()" *matCellDef="let element" class="action-table-header">
                    <div class="select">
                        <button mat-icon-button [disabled]="disableActions" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                        <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true" (click)="$event.stopPropagation();$event.preventDefault()">
                            <button mat-menu-item (click)="editCourseActivity(element)" [disabled]="element.activityType === 'tests'">
                            <span>Edit Content</span>
                          </button>
                        </mat-menu>
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row (click)="gotoActivity(row)" *matRowDef="let row; columns: displayedColumns;" class="{{row.id}}" cdkDrag (cdkDragStarted)="dragStarted($event)" cdkDragPreviewClass="hello-placeholder" [cdkDragDisabled]="reordering || !draggable" [cdkDragData]="row"
                class="pointer">
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
            </mat-row>
        </mat-table>
    </div>
</div>
