<ng-container *ngIf="isLoading; else canvasUpload">
  <app-spinner></app-spinner>
</ng-container>
<ng-template #canvasUpload>
  <div class="file-carousel-item-wrapper">
    <div class="canvas-wrapper" *ngIf="fileType === this.FILE_TYPES.IMAGE">
      <img class="loading" [src]="file.signedUrl"/>
    </div>
    <div class="loading" *ngIf="fileType === this.FILE_TYPES.PDF">
      <iframe [src]="file.signedUrl | safe"></iframe>
    </div>
    <div (focusout)="pauseVideo($event)" *ngIf="fileType === this.FILE_TYPES.VIDEO">
      <video controls controlsList="nodownload">
        <source [src]="file.signedUrl">
      </video>
    </div>
    <div *ngIf="fileType === this.FILE_TYPES.AUDIO">
      <audio controls>
        <source [src]="file.signedUrl" type="audio/mp3">
      </audio>
    </div>
    <div class="placeholder" *ngIf="fileType === this.FILE_TYPES.OTHER">
        <div>Preview Not Available</div>
        <div class="title">{{file.name | titlecase}}</div>
      <a [href]="file.signedUrl" target="_blank">
        <img src="../../../assets/download-btn.svg"/>
        <button>DOWNLOAD</button>
      </a>
    </div>
  </div>
</ng-template>
