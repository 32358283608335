import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-zoom-image',
  templateUrl: './zoom-image.component.html',
  styleUrls: ['./zoom-image.component.scss']
})
export class ZoomImageComponent implements OnInit {
  imgUrl: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
    this.imgUrl = this.data.imgUrl;
  }

  zoomOut() {
    this.dialogRef.close();
  }

}
