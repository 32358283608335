<div class="attachment-wrapper">
    <div *ngIf="videoFile && videoFile.length > 0" class="video">
        <div class="container" (click)="viewDocs('video')">
            <div class="preview">
                <img src="../../../assets/video-file.svg"/>
            </div>
            <div class="file-container">
                <div class="count">Videos({{videoFile.length}})</div>
                <div class="content" *ngFor="let video of videoFile">
                    <div class="value">{{video.name}}, </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="imageFile && imageFile.length > 0" class="video">
        <div class="container" (click)="viewDocs('image')">
            <div class="preview">
                <img src="../../../assets/image-file.svg"/>
            </div>
            <div class="file-container">
                <div class="count">Images({{imageFile.length}})</div>
                <div class="name">
                    <div class="content" *ngFor="let image of imageFile">
                        <div class="value">{{image.name}}, </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="docFile && docFile.length > 0" class="video">
        <div class="container" (click)="viewDocs('doc')">
            <div class="preview">
                <img src="../../../assets/document-file.svg"/>
            </div>
            <div class="file-container">
                <div class="count">Documents({{docFile.length}})</div>
                <div class="name">
                    <div class="content" *ngFor="let doc of docFile">
                        <div class="value">{{doc.name}}, </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
