<ng-container *ngIf="dataSource else loader">
    <mat-table class="table" [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matSortDirection="desc" matSortDisableClear>

        <ng-container matColumnDef="date">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Date</mat-header-cell>
            <mat-cell *matCellDef="let transaction"> {{transaction.startsAt | date : 'dd LLL yyyy' }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startsAt">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Starts At</mat-header-cell>
            <mat-cell *matCellDef="let transaction"> {{transaction.startsAt | date : 'shortTime' }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="endsAt">
            <mat-header-cell mat-sort-header *matHeaderCellDef>Ends At</mat-header-cell>
            <mat-cell *matCellDef="let transaction"> {{transaction.endsAt | date : 'shortTime' }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="duration">
            <mat-header-cell *matHeaderCellDef>Duration</mat-header-cell>
            <mat-cell *matCellDef="let transaction">{{ formatDuration(transaction.duration) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>{{labelData['liveclasstitle']}}</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let transaction">{{ transaction.title | titlecase }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="standard">
            <mat-header-cell *matHeaderCellDef>{{labelData['standard']}}</mat-header-cell>
            <mat-cell mat-cell *matCellDef="let transaction">{{ transaction.standards[0] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef class="action-table-header">Actions</mat-header-cell>
            <mat-cell *matCellDef="let transaction" class="action-table-header" (click)="$event.stopPropagation()">
                <ng-container>
                    <div class="action-btn" [ngClass]="theme">
                        <!-- removing edit button for demo will add when functionality is added -->
                        <!-- <ng-container *ngIf="theme !== 'student'">
                        <div class="edit">EDIT</div>
                    </ng-container> -->

                        <ng-container *ngIf="isClassEnded(transaction); else joinButton">
                            <div class="join" [ngClass]="{'opaque': !transaction.recordings || transaction.recordings.length == 0}" (click)="openPreviewDialog(transaction)">PLAY</div>
                        </ng-container>
                        <ng-template #joinButton>
                            <div class="join" [ngClass]="{'opaque': !isAvailableToJoin(transaction)}" (click)="joinClass(transaction)">JOIN</div>
                        </ng-template>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" (click)="getContents(row)"></mat-row>
    </mat-table>
</ng-container>

<ng-template #loader>
    <app-spinner></app-spinner>
</ng-template>