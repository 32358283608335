<div class="question-wrapper">
    <div>
        <div class="header">
            <div>Question #{{questionChoosen.number + 1}}</div>
            <div>{{questionChoosen.marks}} Marks</div>
        </div>
        <div class="question">
            <div class="name">{{questionChoosen.question}}</div>
            <div class="name uploaded-photo" *ngIf="questionChoosen.image">
                <div class="option-image"><img [src]= questionChoosen.image/></div>
                <div class="zoom" (click)="zoomImage(questionChoosen.image)"><img src="../../../../assets/zoom.svg" /></div>
            </div> 
        </div>
    </div>

    <ng-container *ngIf="questionChoosen.type && questionChoosen.type.toLowerCase() === 'multiple'; else single">
        <div class="options-wrapper">
            <div *ngFor="let option of questionChoosen.options; let i = index" class="container">
                <div class="optionList">
                    <div class="uploaded-photo" *ngIf="option.image">
                        <div class="option-image"><img [src]= option.image/></div>
                        <div class="zoom" (click)="zoomImage(option.image)"><img src="../../../../assets/zoom.svg"/></div>
                    </div> 
                    <div class="options">
                        <mat-checkbox [color]="'primary'" [checked]="option.isSelected" (change)="multipleSelect($event, option)">{{option.option}}</mat-checkbox>
                    </div>
                </div>
            </div> 
        </div>
    </ng-container>

    <ng-template #single>
        <div class="options-wrapper">
            <div *ngFor="let option of questionChoosen.options; let i = index" class="container">
                <div class="optionList">
                    <div class="uploaded-photo" *ngIf="option.image">
                        <div class="option-image"><img [src]= option.image /></div>
                        <div class="zoom" (click)="zoomImage(option.image)"><img src="../../../../assets/zoom.svg"/></div>
                    </div> 
                    <div class="options">
                        <mat-radio-button [color]="'primary'" [checked]="option.isSelected"  (change)="radioSelect(option)">{{option.option}}</mat-radio-button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <div class="clear" (click)="clear()">Clear Selection</div>
</div>