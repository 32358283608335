import { Component, OnInit, Input } from '@angular/core';
import { CompletedWorkGroupByDate } from 'src/app/models/child-work-activity-model';
import { ACTIVITY_TYPES } from 'src/app/Constants/Constant';

const completionMessages = {
  assignments: 'Assignment submitted',
  tests: 'Appeared for test',
  live_classes: 'Attended a video class',
  materials: 'Material Read',
};

@Component({
  selector: 'app-completed-work',
  templateUrl: './completed-work.component.html',
  styleUrls: ['./completed-work.component.scss']
})
export class CompletedWorkComponent implements OnInit {

  constructor() { }
  @Input() workCompleted: CompletedWorkGroupByDate[];

  activityTypes = { ...ACTIVITY_TYPES };
  message: string;

  getMessage(activityType) {
    return completionMessages[activityType];
  }

  ngOnInit(): void {
  }

}
