import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef  } from '@angular/material/dialog';
import { LabelService } from 'src/app/services/label.service';
import { ReportDataService } from '../services/report-data.service';
import { ReportInput } from 'src/app/models/report.models';
import { SchoolService } from 'src/app/services/api/school.service';

@Component({
  selector: 'app-admin-report',
  templateUrl: './admin-report.component.html',
  styleUrls: ['./admin-report.component.scss']
})
export class AdminReportComponent implements OnInit {

  public isLoading: boolean = true;
  public isDataAvailable: boolean = false;

  labelData = {};
  reportForm: FormGroup
  constructor(
    private dialogRef: MatDialogRef<AdminReportComponent>,
    private labelService: LabelService,
    private reportDataSvc: ReportDataService,
    private schoolSvc: SchoolService
  ) { }
  public schools: any[] = [];
  private schoolsReceived: boolean = false;
  private sectionsReceived: boolean = false;
  public sections: any[] = [];
  private sessionsReceived: boolean = false;
  public sessions: any[] = [];
  private standardsReceived: boolean = false;
  public standards: any[] = [];
  private subjectsReceived: boolean = false;
  public subjects: any[] = [];
  private teachersReceived: boolean = false;
  public teachers: any[] = [];

  public presentDate = new Date(new Date().setHours(0, 0, 0));

  ngOnInit(): void {
    let lastMonthStartDate = new Date()
    lastMonthStartDate.setDate(lastMonthStartDate.getDate() - 30)

    this.reportDataSvc.getSessions();
    this.reportDataSvc.getSections();
    this.reportDataSvc.getSubjects();
    this.reportDataSvc.getStandards();
    this.reportDataSvc.getTeachers();
    this.schoolSvc.getSchools();

    this.dialogRef.updatePosition({ right: `0px` });
    this.labelService.labels$.subscribe(data => this.labelData = data);
    this.reportForm = new FormGroup({
      startDate: new FormControl(lastMonthStartDate),
      endDate: new FormControl(this.presentDate),
      type: new FormControl("materials", [Validators.required]),
      school: new FormControl(null, [Validators.required]),
      teacher: new FormControl(null, [Validators.required]),
      standard: new FormControl(null, [Validators.required]),
      section: new FormControl(null, [Validators.required]),
      subject: new FormControl(null, [Validators.required]),
      session: new FormControl(null, [Validators.required]),
    })

    this.schoolSvc.schooList$.subscribe((schools) => {
      if(schools) {
        this.schools = schools;
        this.schoolsReceived = true;
        this.updateLoadingStatus();
      }
    })

    this.reportDataSvc.sessionList$.subscribe((sessions) => {
      if (sessions) {
        this.sessions = sessions;
        this.sessionsReceived = true;
        this.updateLoadingStatus();
      }
    })
    this.reportDataSvc.sectionList$.subscribe((sections) => {
      if (sections) {
        this.sections = sections;
        this.sectionsReceived = true;
        this.updateLoadingStatus();
      }
    })
    this.reportDataSvc.subjectList$.subscribe((subjects) => {
      if (subjects) {
        this.subjects = subjects;
        this.subjectsReceived = true;
        this.updateLoadingStatus();
      }
    })
    this.reportDataSvc.standardList$.subscribe((standards) => {
      if (standards) {
        this.standards = standards;
        this.standardsReceived = true;
        this.updateLoadingStatus();
      }
    })
    this.reportDataSvc.teacherList$.subscribe((teachers) => {
      if (teachers) {
        this.teachers = teachers;
        this.teachersReceived = true;
        this.updateLoadingStatus();
      }
    })
  }

  updateLoadingStatus = () => {
    this.isLoading = !(this.sessionsReceived && this.sectionsReceived && this.subjectsReceived &&
                     this.standardsReceived && this.teachersReceived && this.schoolsReceived);
    this.isDataAvailable = this.sessions.length > 0 && this.sections.length > 0 && this.subjects.length > 0 &&
      this.standards.length > 0 && this.teachers.length > 0 && this.schools.length > 0;
  }

  downloadReport() {
    if (this.reportForm.valid) {
      let reportInput: ReportInput = {
        activityType: this.reportForm.controls.type.value,
        teacherID: this.reportForm.controls.teacher.value,
        schoolID: this.reportForm.controls.school.value,
        standardID: this.reportForm.controls.standard.value,
        sectionID: this.reportForm.controls.section.value,
        subjectID: this.reportForm.controls.subject.value,
        fromDate: this.reportForm.controls.startDate.value,
        toDate: this.reportForm.controls.endDate.value,
        sessionID: this.reportForm.controls.session.value
      }
      this.reportDataSvc.getReport(reportInput);
      this.dialogRef.close();
    } else {
      this.validateReportForm();
    }
  }

  private validateReportForm = () => {
    Object.keys(this.reportForm.controls).forEach(field => {
      const control = this.reportForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
    }
    })
  }

  close() {
    this.dialogRef.close();
  }


}
