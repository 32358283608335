<div class="card-wrapper">
    <div class="card">
        <div class="card-icon">
            <img [src]="cardIcon" />
        </div>
        <div class="card-content">
            <p class="message zero-margin">{{ message }}</p>
            <div class="content-details">
                <p class="zero-margin class-name text"><span class="grey">Class: </span>{{activity.className}}</p>
                <ng-container>
                    <p class="zero-margin topic text"><span class="grey">Topic: </span>{{activity.topic}}</p>

                    <ng-container *ngIf="activity.activityType === activityTypes.assignments else liveClasses">
                        <p class="zero-margin text"><span class="grey">Total Marks: </span>{{ activity.totalMarks}}</p>
                    </ng-container>
                    <ng-template #liveClasses>
                        <p *ngIf="activity.activityType === activityTypes.liveClasses" class="zero-margin text"><span class="grey">Conducted By: </span>{{ activity.teacher}}</p>
                        <p *ngIf="activity.activityType !== activityTypes.liveClasses" class="zero-margin text"><span class="grey">Shared By: </span>{{ activity.teacher}}</p>
                    </ng-template>
                </ng-container>
            </div>
            <ng-container *ngIf="category !== today else todayWork">
                <p [ngClass]="category===overdue ? 'overdue-date':''" *ngIf="activity.activityType === activityTypes.assignments" class="zero-margin"><span class="grey">Date of
            Submission: </span>{{ activity.date | date: 'dd-MM-yyyy' }}</p>
                <p [ngClass]="category===overdue ? 'overdue-date':''" *ngIf="activity.activityType !== activityTypes.assignments" class="zero-margin content-date"><span class="grey">Date: </span>{{ activity.date | date: 'dd-MM-yyyy' }} <span class="grey">at</span> {{activity.date | date: 'hh:mm a'}}</p>
            </ng-container>
            <ng-template #todayWork>
                <p *ngIf="activity.activityType === activityTypes.assignments" class="zero-margin"><span class="grey">Date of
            Submission: </span>Today</p>
                <p *ngIf="activity.activityType !== activityTypes.assignments" class="zero-margin"><span class="grey">Date:
          </span>Today <span class="grey">at</span> {{activity.date | date: 'hh:mm a'}}</p>
            </ng-template>
        </div>
        <div class="arrow">
            <img src="assets/right-arrow.svg" />
        </div>
    </div>
    <hr class="line" />
</div>