import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CrudService } from 'src/app/services/crud.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClassPostDashboardService implements OnInit {
  private studentID$$: BehaviorSubject<any> = new BehaviorSubject<string>(null);
  private materialDetail$$: BehaviorSubject<any> = new BehaviorSubject<string>(null);

  constructor(
    public crud: CrudService,
    public formData: FormDataService,
    public localstorage: LocalStorageService) { }

  ngOnInit() {
    this.studentID$$.next(this.localstorage.getItem('studentID'));
  }

  public get studentID$(): Observable<string> {
    return this.studentID$$.asObservable();
  }

  public get materialDetail$(): Observable<string> {
    return this.materialDetail$$.asObservable();
  }

  private postRoute(studentID, classID) {
    return `${environment.apiUrl}student/activity/${studentID}/class/${classID}`;
  }

  private assignmentRoute(id, studentID) {
    return `${environment.apiUrl}student/assignment/get/${id}/student/${studentID}`;
  }

  private assignmentStatusRoute(studentID, postID) {
    return `${environment.apiUrl}student/assignment/evaluation/${postID}/student/${studentID}`;
  }

  private materialRoute(id, studentID) {
    return `${environment.apiUrl}student/material/get/${id}/student/${studentID}`;
  }

  private readMaterialRoute() {
    return `${environment.apiUrl}student/material/read`;
  }

  private submitAssignmentRoute() {
    return `${environment.apiUrl}student/assignment/submission`
  }

  private statusRoute(id) {
    return environment.apiUrl + 'class/activity/upload-status/' + `${id}`;
  }

  public submissionRoute(assignmentId, studentId) {
    return `${environment.apiUrl}/class/assignment/submission/${assignmentId}/student/${studentId}`;
  }

  setStudentID(id) {
    this.studentID$$.next(id);
  }

  getPosts(studentID, classID) {
    return this.crud.get(this.postRoute(studentID, classID));
  }

  getAssignmentById(assignmentID, studentID) {
    return this.crud.get(this.assignmentRoute(assignmentID, studentID));
  }

  getSubmission(assignmentID, studentID) {
    return this.crud.get(this.submissionRoute(assignmentID, studentID))
  }

  getMaterialById(materialID, studentID) {
    return this.crud.get(this.materialRoute(materialID, studentID));
  }

  setMaterialDetail(post) {
    this.materialDetail$$.next(post);
  }

  notifyForMaterialRead(body) {
    return this.crud.post(this.readMaterialRoute(), body);
  }

  assignmentStatus(studentID, postID) {
    return this.crud.get(this.assignmentStatusRoute(studentID, postID))
  }

  submitAssignment(body) {
    const formData = this.formData.createFormData(body);
    return this.crud.post(this.submitAssignmentRoute(), formData);
  }
}
