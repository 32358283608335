import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StudentsRoutingModule } from './students-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ProfileComponent } from './profile/profile.component';
import { ChooseAccountComponent } from './choose-account/choose-account.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { MessagesComponent } from './messages/messages.component';
import { ActivityComponent } from './activity/activity.component';
import { CreateStudentProfileComponent } from './create-student-profile/create-student-profile.component';
import { AcceptInviteComponent } from './accept-invite/accept-invite.component';
import { ChildListComponent } from './child-list/child-list.component';
import { MaterialModule } from '../material/material.module';
import { ForgotPinComponent } from './forgot-pin/forgot-pin.component';
import { StudentLiveClassComponent } from './student-live-class/student-live-class.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';


@NgModule({
  declarations: [
    ProfileComponent, ChooseAccountComponent, DashboardComponent, HomeComponent, MessagesComponent, ActivityComponent, CreateStudentProfileComponent, AcceptInviteComponent, ChildListComponent, ForgotPinComponent, StudentLiveClassComponent],
  imports: [
    CommonModule,
    StudentsRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    MaterialModule,
    SharedComponentsModule
  ]
})
export class StudentsModule { }
