import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SchoolService } from 'src/app/services/api/school.service';
import { School } from 'src/app/models/school.models';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';

@Component({
  selector: 'app-school-card',
  templateUrl: './school-card.component.html',
  styleUrls: ['./school-card.component.scss'],
})
export class SchoolCardComponent implements OnInit {
  @Input() schools: any;
  constructor(private router: Router, private schoolService: SchoolService, public sidenavService: ToggleSidenavService) { }

  ngOnInit(): void {
    this.sidenavService.maximizeSidenav();
  }

  onCardClick(school: School) {
    this.sidenavService.minmizeSidenav();
    this.schoolService.selectSchool(school);
    this.router.navigate(['school', 'dashboard', 'school-details', school.id])
  }

  select(event) {
    event.stopPropagation();
  }

  trackByID(index, schoolList) {
    if (schoolList) {
      return schoolList.id;
    }
  }

}
