import { Pipe, PipeTransform } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


function getJoinedString(joinedDate: any): string {
  let presentDate: any = new Date();
  let diff = presentDate - joinedDate;
  let leftSeconds = diff / 1000;
  let hours = Math.floor(leftSeconds / 3600);
  let minutes = Math.floor(leftSeconds / 60) - hours * 60;
  if (minutes == 0) {
    minutes = 1;
  }
  let hourString = "hour"
  if (hours > 1) {
    hourString = "hours"
  }
  let minuteString = "minute"
  if (minutes > 1) {
    minuteString = "minutes"
  }
  console.log("hours and mins are", hours, minutes)
  if (hours > 0) {
    if (minutes > 0) {
      return `Joined ${hours} ${hourString} and ${minutes} ${minuteString} ago`
    } else {
      return `Joined ${hours} ${hourString}  ago`
    }
  } else {
    return `Joined ${minutes} ${minuteString} ago`
  }
}

@Pipe({
  name: 'joiningTime'
})
export class JoiningTimePipe implements PipeTransform {

  transform(joinedDate: any): Observable<string> {
    return interval(30000).pipe(startWith(0),map(() => getJoinedString(joinedDate)));
  }

}