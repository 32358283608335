import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-rejoin-dialog',
  templateUrl: './rejoin-dialog.component.html',
  styleUrls: ['./rejoin-dialog.component.scss']
})
export class RejoinDialogComponent implements OnInit {

  @Output() rejoin = new EventEmitter();
  @Output() end = new EventEmitter();
  public theme: string;
  constructor(private localStorage: LocalStorageService) { }

  ngOnInit(): void {
    this.theme = this.localStorage.getItem("type")
  }

  endSession() {
    this.end.emit();
  }

  rejoinSession() {
    this.rejoin.emit();
  }

}
