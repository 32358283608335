import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProfileComponent } from '../profile.component';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-change-pin',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.scss']
})
export class ChangePinComponent implements OnInit {
  pinForm: FormGroup;
  picture: string;
  isSubmitted: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    public authservice: ProfileService,
    public _snackbar: SnackbarService) { }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: `0px`});
    this.buildForm()
  }

  buildForm() {
    this.pinForm = this.fb.group({
      'oldPin': [null, [Validators.required]],
      'newPin': [null, [Validators.required]],
      'confirmPin': [null, Validators.required],
    });
    this.picture = this.data.user.profilePicture;
  }

  isNewPinMatched() {
    if(parseInt(this.pinForm.get('newPin').value) !== parseInt(this.pinForm.get('confirmPin').value)) {
      this.pinForm.controls['confirmPin'].setErrors({'valid': false})
      this.pinForm.controls['confirmPin'].markAllAsTouched();
      return;
    } else {
      return true
    }
  }

  confirmPinError() {
    return this.pinForm.get('confirmPin').hasError('requried') ?  'confirm pin cannot be empty' : 'New PIN and Confirm PIN does not match';
  }

  save() {
    if(!this.isNewPinMatched()) {
      return;
    }
    if(this.pinForm.valid) {
      const type = localStorage.getItem('studentID') ? 'student' : 'parent';
      const requestBody = {
         forgotPin: false,
         oldPin: this.pinForm.get('oldPin').value,
         newPin: this.pinForm.get('newPin').value
      }
      this.authservice.changePin(type, this.data.user.id, requestBody).subscribe(
        (res) => {
          this._snackbar.openSnackbar(res.msg);
          this.dialogRef.close('true');
        },
        (err) => {
          this._snackbar.openSnackbar(err.error.error)
        }
      )
    } else {
      this._snackbar.openSnackbar('Please fill all the details')
    }
  }

  close() {
    this.dialogRef.close();
  }

}
