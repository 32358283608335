import { Component, Inject, OnInit } from '@angular/core';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestQuestionComponent } from '../test-question/test-question.component';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  test;
  questions = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<TestQuestionComponent>) { }

  ngOnInit(): void {
    this.test = this.data.testDetail;
    this.questions = this.data.question;
  }

  close() {
    this.dialogRef.close();
  }

}
