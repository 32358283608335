import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { AssignmentFilesService } from '../services/assignment-files.service';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { CourseActivitiesService } from 'src/app/admin/services/course-activities.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ROLE_TYPE, ADMIN_ROLE } from 'src/app/Constants/Constant';


@Component({
  selector: 'app-assignment-canvas',
  templateUrl: './assignment-canvas.component.html',
  styleUrls: ['./assignment-canvas.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssignmentCanvasComponent implements OnInit {

  @ViewChild('carousel', { static: false }) carousel: NgbCarousel;
  @Output() onEdit = new EventEmitter();
  @Input() isCourseActivity = false;
  @Input() canEditFile = false;
  public files: any[] = null;

  public isAdminTheme = false;

  constructor(
    public assignmentFileService: AssignmentFilesService,
    private route: ActivatedRoute,
    public courseActivitiesSvc: CourseActivitiesService,
    private localstorage: LocalStorageService
  ) { }

  public ngOnInit(): void {
    this.isAdminTheme = this.localstorage.getItem(ROLE_TYPE) === ADMIN_ROLE ? true : false;
    // this.route.snapshot.params.canEdit === 'true' ? this.canEditFile = true : this.canEditFile = false;
    this.onEdit.emit(this.canEditFile);
    if (this.isCourseActivity) {
      this.courseActivitiesSvc.files$.subscribe((files) => {
        if (files) {
          this.files = files;
        }
      });
    } else {
      this.assignmentFileService.files$.subscribe((files) => {
        if (files) {
          this.files = files;
        }
      });
    }
  }

  public updateSlide(event: NgbSlideEvent) {
    if (!this.isCourseActivity) {
      this.assignmentFileService.updateSlide(parseInt(event.current.replace('slideId_', ''), 10));
      return;
    }
    this.courseActivitiesSvc.updateSlide(parseInt(event.current.replace('slideId_', ''), 10));
  }

  public moveToNext() {
    this.carousel.next();
  }

  public editDoc() {
    this.canEditFile = !this.canEditFile;
  }

  public moveToPrev() {
    this.carousel.prev();
  }

}
