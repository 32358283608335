import { Injectable, Inject, OnDestroy, OnInit, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subject, zip, Subscription, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CrudService } from './crud.service';
import { takeUntil, filter, switchMap, concatMap, switchMapTo } from 'rxjs/operators';
import { SchoolService } from './api/school.service';
import { SnackbarService } from './snackbar.service';
import { SelectedSchoolService } from './selected-school.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolSettingService implements OnInit, OnDestroy {
  private schoolStandards$$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  private schoolSections$$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  private schoolSubjects$$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  private schoolSessions$$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  public schoolMasterStandards$$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  public schoolMasterSections$$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  public schoolMasterSubjects$$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  public schoolMasterSessions$$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  public schoolId: string;
  private subscription = new Subscription();
  schoolCreator: string;

  public get schoolStandards$(): Observable<any> {
    return this.schoolStandards$$.asObservable();
  }

  public get schoolSections$(): Observable<any> {
    return this.schoolSections$$.asObservable();
  }

  public get schoolSubjects$(): Observable<any> {
    return this.schoolSubjects$$.asObservable();
  }

  public get schoolSessions$(): Observable<any> {
    return this.schoolSessions$$.asObservable();
  }

  public schoolRoute(setting) {
    return environment.apiUrl + 'school/' + `${setting}` + '/view/';
  }

  public masterSchoolRoute(setting) {
    return environment.apiUrl + `${setting}` + '/get/';
  }

  public addSetting(setting) {
    return environment.apiUrl + 'school/' + `${setting}` + '/add/';
  }

  public removeSetting(setting) {
    return environment.apiUrl + 'school/' + `${setting}` + '/remove/';
  }

  public getSchoolDetail(id) {
    return environment.apiUrl + 'school/get/' + `${id}`;
  }

  constructor(private crud: CrudService, public schoolService: SelectedSchoolService, private snackbar: SnackbarService) {
    this.subscription = this.schoolService.schoolId$
      .subscribe((id: any) => {
        this.schoolId = id;
        this.getSchoolCreator(id).subscribe(school => {
          this.schoolCreator = school.creatorID
        })
      });
  }

  ngOnInit() { }

  getSchoolCreator(id) {
    return this.crud.get(this.getSchoolDetail(id))
  }

  getSchoolStandard() {
    return this.crud.get(this.schoolRoute('standard') + `${this.schoolId}`);
  }

  getMasterSchoolStandard(creatorId?) {
    if (creatorId) {
      return this.crud.get(this.masterSchoolRoute('standard') + `${creatorId}`);
    }
    return this.crud.get(this.masterSchoolRoute('standard') + `${this.schoolCreator}`);
  }

  getSchoolSession() {
    return this.crud.get(this.schoolRoute('session') + `${this.schoolId}`);
  }

  getMasterSchoolSession(creatorId?) {
    if (creatorId) {
      return this.crud.get(this.masterSchoolRoute('session') + `${creatorId}`);
    }
    return this.crud.get(this.masterSchoolRoute('session') + `${this.schoolCreator}`);
  }

  getSchoolSubject() {
    return this.crud.get(this.schoolRoute('subject') + `${this.schoolId}`);
  }

  getMasterSchoolSubject(creatorId?) {
    if (creatorId) {
      return this.crud.get(this.masterSchoolRoute('subject') + `${creatorId}`);
    }
    return this.crud.get(this.masterSchoolRoute('subject') + `${this.schoolCreator}`);
  }

  getSchoolSection() {
    return this.crud.get(this.schoolRoute('section') + `${this.schoolId}`);
  }

  getMasterSchoolSection(creatorId?) {
    if (creatorId) {
      return this.crud.get(this.masterSchoolRoute('section') + `${creatorId}`);
    }
    return this.crud.get(this.masterSchoolRoute('section') + `${this.schoolCreator}`);
  }

  addSchoolStandard(body) {
    return this.crud.post(this.addSetting('standard') + `${this.schoolId}`, body)
  }

  removeSchoolStandard(body) {
    return this.crud.post(this.removeSetting('standard') + `${this.schoolId}`, body);
    //  this.subscription = removedSetting.subscribe(
    //    (res) => {
    //       res = zip(this.schoolStandards$$, body.standardIDs).pipe(
    //         filter((a : any,b) => a.id !== b)
    //       )
    //       .subscribe(value => {
    //         this.snackbar.openSnackbar('standard updated succesfully');
    //         this.schoolStandards$$.next(value)
    //       })
    //     },
    //  )
  }

  addSchoolSubject(body) {
    return this.crud.post(this.addSetting('subject') + `${this.schoolId}`, body)
  }

  removeSchoolSubject(body) {
    return this.crud.post(this.removeSetting('subject') + `${this.schoolId}`, body);
    // this.subscription = removedSetting
    // .subscribe(
    //   (res) => {
    //      res = zip(this.schoolSubjects$$, body.SubjectIDs).pipe(
    //        filter((a : any,b) => a.id !== b)
    //      )
    //      .subscribe(value => {
    //        this.snackbar.openSnackbar('subjects updated succesfully');
    //        this.schoolSubjects$$.next(value)
    //      })
    //    },
    // )
  }

  addSchoolSession(body) {
    return this.crud.post(this.addSetting('session') + `${this.schoolId}`, body)
  }

  removeSchoolSession(body) {
    return this.crud.post(this.removeSetting('session') + `${this.schoolId}`, body);
    // this.subscription = removedSetting
    // .subscribe(
    //   (res) => {
    //      res = zip(this.schoolSessions$$, body.SessionIDs).pipe(
    //        filter((a : any,b) => a.id !== b)
    //      )
    //      .subscribe(value => {
    //        this.snackbar.openSnackbar('session updated succesfully');
    //        this.schoolSessions$$.next(value)
    //      })
    //    },
    //    (err) => this.snackbar.openSnackbar(err.error.error)
    // )
  }

  addSchoolSection(body) {
    return this.crud.post(this.addSetting('section') + `${this.schoolId}`, body)
  }

  removeSchoolSection(body) {
    return this.crud.post(this.removeSetting('section') + `${this.schoolId}`, body);
    // this.subscription = removedSetting
    // .subscribe(
    //   (res) => {
    //     this.snackbar.openSnackbar('section updated succesfully');
    //      res = zip(this.schoolSections$$, body.SectionIDs).pipe(
    //        filter((a : any,b) => a.id !== b)
    //      )
    //      .subscribe(value => {
    //        this.schoolSections$$.next(value)
    //      })
    //    },
    // )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.subscription.closed) {
      console.log("school setting subscription ended")
    }
  }


}
