import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CrudService } from '../crud.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage.service';
import { Class } from 'src/app/models/class.models';
import { takeUntil } from 'rxjs/operators';
import { ARCHIVED_STATUS, UNARCHIVED_STATUS, SELF_ID_KEY } from 'src/app/Constants/Constant';

@Injectable({
  providedIn: 'root'
})
export class TeacherService implements OnDestroy {
  private class$$: BehaviorSubject<Class[]> = new BehaviorSubject<Class[]>([]);
  private isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private destroy$$: Subject<any> = new Subject<any>();

  public teacherId;

  public get class$() : Observable<Class[]> {
    return this.class$$.asObservable();
  }

  public get isLoading$() : Observable<boolean> {
    return this.isLoading$$.asObservable();
  }

  public classRoute() {
    return environment.apiUrl + 'class/get/' + this.teacherId;
  }

  public filterRoute(subject, standard) {
    if(subject && standard === '') {
      return environment.apiUrl + 'class/get/' + this.teacherId + '?subject=' + subject;
    } else if(standard && subject === '') {
      return environment.apiUrl + 'class/get/' + this.teacherId + '?standard=' + standard;
    } else {
      return environment.apiUrl + 'class/get/' + this.teacherId + '?subject=' + subject +  '&standard=' + standard;
    }
  }

  public createClassRoute() {
    return environment.apiUrl + 'class/create'
  }
  public editClassRoute(id){
    return environment.apiUrl + 'class/edit/' + id
  }
  public changeTeacherStatusRoute(){
    return environment.apiUrl + 'school/teacher/status'
  }
  public changeStaffStatusRoute(){
    return environment.apiUrl + 'school/staff/status'
  }
  public changeStudentStatusRoute(){
    return environment.apiUrl + 'class/student/status'
  }
  constructor(public crud: CrudService, public localStorage: LocalStorageService) {
    this.teacherId = this.localStorage.getItem(SELF_ID_KEY);
  }

  getClasses() {
    this.crud.get(this.classRoute()).pipe(
      takeUntil(this.destroy$$)
    )
    .subscribe(
      classes => { 
        this.isLoading$$.next(false);
        this.class$$.next(classes)
      },
      (err) => console.log(err.error.error)
    )
  }

  archiveClass(id: string) {
    let classes = this.class$$.value;
    let index = classes.findIndex((cls) => cls.id === id);
    classes[index].status = ARCHIVED_STATUS;
    this.class$$.next(classes);
  }

  unArchiveClass(id: string) {
    let classes = this.class$$.value;
    let index = classes.findIndex((cls) => cls.id === id);
    classes[index].status = UNARCHIVED_STATUS;
    this.class$$.next(classes);
  }

  createClass(body) {
    return this.crud.post(this.createClassRoute(), body);
  }

  filterByRole(standard, subject) {
    return this.crud.get(this.filterRoute(subject, standard)).pipe(
      takeUntil(this.destroy$$)
    )
    .subscribe(
      classes => { 
        this.isLoading$$.next(false);
        this.class$$.next(classes)
      },
      (err) => console.log(err)
    )
  }
  changeTeacherStatus(schoolID, teacherID, status) {
    const body = {
      "schoolID": schoolID,
    "teacherID": teacherID,
    "status": status
    }
    return this.crud.post(this.changeTeacherStatusRoute(),body);
  }
  changeStaffSatus(schoolID, staffID, status) {
    const body = {
      "schoolID": schoolID,
    "staffID": staffID,
    "status": status
    }
    return this.crud.post(this.changeStaffStatusRoute(),body);
  }
  changeStudentSatus(classID, studentID, status) {
    const body = {
      "classID": classID,
    "studentID": studentID,
    "status": status
    }
    return this.crud.post(this.changeStudentStatusRoute(),body);
  }
  editClass(body,id){
    return this.crud.patch(this.editClassRoute(id),body)
  }

  clearClasses() {
    this.class$$.next([]);
  }

  ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}
