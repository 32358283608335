import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { LiveSessionService } from 'src/app/services/live-session.service';
import { Subscription } from 'rxjs';
import { ChatMessage } from 'src/app/models/live-class-models';

@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss']
})
export class LiveChatComponent implements OnInit, OnDestroy {

  constructor(private liveSessionSvc: LiveSessionService) { }

  private subscriptions: Subscription[];

  public chatMessages: ChatMessage[] = [];
  private isNearBottom: boolean = true;
  @ViewChild('messagesContainer') messagesContainer: ElementRef;

  //scrolling functionality of chat
  public onScroll = (e) => {
    this.isNearBottom = this.isUserNearBottom();
  }

  private isUserNearBottom(): boolean {
    const threshold = 150;
    const position = this.messagesContainer.nativeElement.scrollTop + this.messagesContainer.nativeElement.offsetHeight;
    const height = this.messagesContainer.nativeElement.scrollHeight;
    return position > height - threshold;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.liveSessionSvc.chatMessages$.subscribe((chatMessages) => {
        this.chatMessages = chatMessages;
        if(this.messagesContainer && this.isNearBottom) {
          this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

}
