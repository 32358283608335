import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CrudService } from '../../services/crud.service';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { FILE_TYPES } from '../../Constants/Constant';
import { jsPDF } from 'jspdf';
import { FormDataService } from 'src/app/services/form-data.service';


@Injectable({
  providedIn: 'root'
})
export class AssignmentFilesService {

  public get assignmentDetails$() {
    return this.assignmentDetails$$.asObservable();
  }

  public get files$() {
    return this.files$$.asObservable();
  }

  public get updatedFiles$() {
    return this.updatedFiles$$.asObservable();
  }

  public get currentSlide$() {
    return this.currentSlide$$.asObservable();
  }


  private assignmentDetails$$ = new BehaviorSubject(null);
  private files$$ = new BehaviorSubject([]);
  private updatedFiles$$ = new BehaviorSubject([]);
  private currentSlide$$ = new BehaviorSubject('1');

  public submissionRoute(assignmentId, studentId) {
    return `${environment.apiUrl}/class/assignment/submission/${assignmentId}/student/${studentId}`;
  }

  public evaluateRoute() {
    return `${environment.apiUrl}class/assignment/evaluation`;
  }

  public uploadRoute(id) {
    return `${environment.apiUrl}/class/activity/upload-status/${id}`;
  }

  public evaluationRoute(assignmentId, studentId) {
    return `${environment.apiUrl}student/assignment/evaluation/${assignmentId}/student/${studentId}`;
  }

  constructor(
    private crud: CrudService,
    public formData: FormDataService,
  ) {
  }

  getSubmission(assignmentId, studentId) {
    this.crud.get(this.submissionRoute(assignmentId, studentId)).subscribe((response: any) => {
      this.assignmentDetails$$.next(response);
      this.files$$.next(
        response.submittedAssignment.files.map((file, index) => {
          return {
            name: file,
            url: response.submissionURLs[index]
          };
        })
      );
    });
  }

  viewEvaluation(assignmentId, studentId) {
    this.crud.get(this.evaluationRoute(assignmentId, studentId)).subscribe((response: any) => {
      this.assignmentDetails$$.next(response);
      this.files$$.next(
        response.evaluatedAssignment.evalFiles.map((file, index) => {
          return {
            name: file,
            url: response.evalURLs[index]
          };
        })
      );
    });
  }

  updateSlide(slideNumber: number) {
    this.currentSlide$$.next(`${slideNumber + 1}`);
  }

  updateFiles(file, fileName, type) {
    const files = this.updatedFiles$$.value;
    const selectedFileIndex = files.findIndex(updatedFile => updatedFile.name === fileName);
    if (selectedFileIndex > -1) {
      files[selectedFileIndex].file = file;
    } else {
      files.push({
        name: fileName,
        type,
        file
      });
    }
    this.updatedFiles$$.next(files);
  }

  async getUpdatedFiles() {
    const files = this.updatedFiles$$.value;
    const updatedFiles = [];
    for (const file of files) {
      const extension = file.name.split('.');
      if (extension[extension.length - 1] !== 'pdf') {
        let s = '';
        for (let i = 0; i < extension.length - 1; i++) {
          s = s.concat(extension[i]);
        }
        s = s.concat('.png');
        file.name = s;
      }
      if (file.type === FILE_TYPES.IMAGE) {
        const blob: any = await new Promise(resolve => file.file.toBlob(resolve));
        const fileObject = new File([blob], file.name);
        updatedFiles.push(fileObject);
      } else if (file.type === FILE_TYPES.PDF) {
        const pdf = new jsPDF({
          unit: 'px',
          format: 'a4',
        });
        Array.from(file.file.children).forEach((el: any, index) => {
          const imgData = el.toDataURL('image/jpeg');
          if (index > 0) {
            pdf.addPage(imgData.width, imgData.height);
          }
          pdf.addImage(imgData, 'JPEG', 0, 0, imgData.width, imgData.height);
        });
        const blob = pdf.output('blob');
        const fileName = new File([blob], file.name);
        updatedFiles.push(fileName);
      }
    };
    return updatedFiles;
  }

  downloadAllUpdatedFiles() {
    const files = this.files$$.value;
    files.forEach(file => {
      saveAs(file.url, file.name);
    });
  }

  evaluateAssignment(body) {
    const formData = this.formData.createFormData(body);
    return this.crud.post(this.evaluateRoute(), formData);
  }

  getFromGcs(url) {
    return this.crud.get(url);
  }

}
