import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  public route(role) {
    return environment.apiUrl + 'school/invite-list' + `?role=${role}`
  }

  constructor(private crud: CrudService) { }

  uploadFile(fileToUpload, role){
    const formData = new FormData();
    formData.append('invite-list', fileToUpload);
    return this.crud.post(this.route( role), formData)
  }
}
