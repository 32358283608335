import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from './crud.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SnackbarService } from './snackbar.service';
import { LocalStorageService } from './local-storage.service';
import { SELF_ID_KEY, SELECTED_SCHOOL_GROUP_KEY } from '../Constants/Constant';
import { AccessRights } from '../models/super-admin-users.model';

@Injectable({
  providedIn: 'root'
})
export class SchoolGroupService {

  constructor(
    private crud: CrudService,
    private snackbar: SnackbarService,
    private localstorage: LocalStorageService
  ) { }


  private isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public get isLoading$(): Observable<boolean> {
    return this.isLoading$$.asObservable();
  }

  private schoolGroups$$: BehaviorSubject<any[]> = new BehaviorSubject(null);

  public get schoolGroups$(): Observable<any[]> {
    return this.schoolGroups$$.asObservable();
  }

  private accessRights$$: BehaviorSubject<AccessRights> = new BehaviorSubject(null);

  public get accessRights$(): Observable<AccessRights> {
    return this.accessRights$$.asObservable();
  }

  getSchoolGroups() {
    this.isLoading$$.next(true);
    this.crud.get(environment.apiUrl + 'school-groups').subscribe(
      (resp) => {
        const selfId = this.localstorage.getItem(SELF_ID_KEY)
        const selfIndex = resp.findIndex((group) => group.id === selfId)
        if (selfIndex != -1) {
          if (selfIndex != 0) {
            [resp[0], resp[selfIndex]] = [resp[selfIndex], resp[0]];
          }
        }
        this.schoolGroups$$.next(resp);
        this.isLoading$$.next(false);
      },
      (err) => {
        this.isLoading$$.next(false);
        this.schoolGroups$$.next(null);
        this.snackbar.openSnackbar('unable to fetch school groups from server');
      });
  }

  getSelectedSchoolGroup() {
    return this.localstorage.getItem(SELECTED_SCHOOL_GROUP_KEY) ? this.localstorage.getItem(SELECTED_SCHOOL_GROUP_KEY) :
           this.localstorage.getItem(SELF_ID_KEY);
  }

  getAccessRights() {
    return this.crud.get(environment.apiUrl + 'school-groups/access-rights');
  }

  updateAccessRights(accessRights: AccessRights) {
    this.accessRights$$.next(accessRights);
  }
}
