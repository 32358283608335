import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TableService } from 'src/app/services/table.service';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { TestService } from '../services/test.service';
import { dateFormat, COURSE_ACTIVITY_ROUTE, ROLE_TYPE } from 'src/app/Constants/Constant';
import { Subject, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { PreviewComponent } from '../preview/preview.component';
import { CourseActivitiesService } from 'src/app/admin/services/course-activities.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { FindSchoolUserRoleService } from 'src/app/services/find-school-user-role.service';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';

@Component({
  selector: 'app-test-solution',
  templateUrl: './test-solution.component.html',
  styleUrls: ['./test-solution.component.scss']
})
export class TestSolutionComponent implements OnInit {
  sortDirection: 'asc' | 'desc' = 'desc';
  storedSortDirection: 'asc' | 'desc';
  docUrl: any;
  isdocUploaded: boolean;
  studentList;
  questionList = [];
  displayedColumns;
  isLoading: boolean = true;
  testDetail: any = [];
  dateFormat: string;
  isUploading: boolean = false;

  isCourseActivity = false;
  isAdmin = false;
  role = '';
  disableTableActions = false;

  public destroy$$ = new Subject();
  constructor(
    public location: Location,
    public tableService: TableService,
    public testService: TestService,
    public route: ActivatedRoute,
    private router: Router,
    public snackbar: SnackbarService,
    public dialog: MatDialog,
    public sidenav: ToggleSidenavService,
    private courseActivitiesSvc: CourseActivitiesService,
    private localstorage: LocalStorageService,
    private userRole: FindSchoolUserRoleService
  ) { }

  ngOnInit(): void {
    this.userRole.userRole$.pipe(
      takeUntil(this.destroy$$)
    )
      .subscribe(role => {
        this.role = role;
        if (this.role === 'member') {
          this.disableTableActions = true;
        }
      });
    this.dateFormat = dateFormat;
    this.sidenav.minmizeSidenav();
    this.isAdmin = this.localstorage.getItem(ROLE_TYPE) === USER_TYPES.admin;
    this.isCourseActivity = this.router.url.includes(COURSE_ACTIVITY_ROUTE);
    // FOR DEMO: it will be reverted later
    // this.displayedColumns = ['select', 'studentName', 'marks', 'result','correctAnswers','unanswered','reviewStatus','actions'];
    if (this.isCourseActivity) {
      this.displayedColumns = ['studentName', 'marks', 'result', 'correctAnswers', 'unanswered', 'reviewStatus', 'actions'];

      this.getCourseActivityDetails();
    } else {
      this.displayedColumns = ['studentName', 'mobileNumber', 'marks', 'result', 'correctAnswers', 'unanswered', 'reviewStatus', 'actions'];
      this.getTestDetails();
    }
  }

  getQuestionDetails(testID) {
    this.isLoading = true;
    this.testService.getTestQuestions(testID).subscribe(
      (res) => {
        const questionURL = this.testService.fetchFromGcs(res.questionURL);
        const answerURL = this.testService.fetchFromGcs(this.testDetail.answersURL);
        zip(questionURL, answerURL).subscribe(
          (questions) => {
            this.questionList = this.testService.mapQuestionWithAnswer(questions);
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            this.snackbar.openSnackbar('Unable to fetch the test details, please refresh your page')
          }
        )
      },
      (err) => {
        this.isLoading = false;
        this.snackbar.openSnackbar('Unable to fetch the test details, please refresh your page')
      }
    )
  }

  getTestDetails() {
    const testID = this.route.snapshot.params.id;
    this.testService.getTestDetails(testID).subscribe(
      (res) => {
        this.testDetail = res;
        this.studentList = res.submissions;
        this.getQuestionDetails(testID);
      },
      (err) => {
        this.snackbar.openSnackbar('Unable to get the test details.')
        this.isLoading = false;
      }
    );
  }

  private getCourseActivityDetails() {
    const testID = this.route.snapshot.params.id;
    this.courseActivitiesSvc.getCourseActivity(testID);
    this.courseActivitiesSvc.selectedCourseActivity$.pipe(
      takeUntil(this.destroy$$)
    ).subscribe((courseActivityDetails) => {
      if (courseActivityDetails) {
        this.testDetail = courseActivityDetails;
        this.studentList = courseActivityDetails.submissions;

        const questionURL = this.testService.fetchFromGcs(courseActivityDetails.signedURLs[0]);
        const answerURL = this.testService.fetchFromGcs(courseActivityDetails.signedURLs[1]);
        zip(questionURL, answerURL).pipe(
          takeUntil(this.destroy$$)
        ).subscribe(
          (questions) => {
            this.questionList = this.testService.mapQuestionWithAnswer(questions);
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            this.snackbar.openSnackbar('Unable to fetch the test details, please refresh your page');
          }
        );
      }
    });
  }

  deleteSolution() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '363px',
      height: '190px',
      backdropClass: 'blur',
      data: { theme: 'teacher' },
      panelClass: ['delete-dialog-css'],
      autoFocus: false
    })

    dialogRef.afterClosed().subscribe((isDeleted: boolean) => {
      if (isDeleted) {
        this.isLoading = true;
        const testID = this.route.snapshot.params.id;
        const requestBody = {
          ID: testID,
          classID: this.testDetail.classID,
          solutions: this.testDetail.solutions
        }
        this.testService.deleteSolutions(testID, requestBody).subscribe(
          (res) => {
            this.getTestDetails();
            this.snackbar.openSnackbar(res.msg);
          },
          (err) => {
            this.isLoading = false;
            this.snackbar.openSnackbar('All solutions are deleted successfully!!')
          }
        )
      }
    })
  }

  onDocUpload(url) {
    this.docUrl = url ? url : null;
    this.isdocUploaded = url ? true : false;
  }

  onSortChange(event: 'asc' | 'desc') {
    this.tableService.setSortDirection(event);
  }

  previewTest() {
    if (this.questionList && this.questionList.length === 0) {
      this.snackbar.openSnackbar('Unable to fetch test, please refresh your page!!');
      return;
    } else {
      const dialogRef = this.dialog.open(PreviewComponent, {
        width: '100%',
        height: '100%',
        data: { question: this.questionList, testDetail: this.testDetail },
        panelClass: ['teacher-theme', 'width-class'],
        autoFocus: false
      })
    }
  }

  mapGCSUrl(signedUrl: string[] | any) {
    let urls = [];
    signedUrl.map((url, index) => urls.push({ signedUrl: url, file: this.docUrl[index] }));
    return urls;
  }

  uploadSolution() {
    const { files } = this.docUrl;
    if (!(this.docUrl)) {
      this.snackbar.openSnackbar('Please upload solutions first!!')
      return
    }
    this.isLoading = true;
    const requestbody = {
      testID: this.testDetail.testID,
      classID: this.testDetail.classID,
      uploadedFiles: files,
    }
    this.isUploading = true;
    this.testService.uploadSolution(requestbody).pipe(
      takeUntil(this.destroy$$),
    )
      .subscribe(
        (res) => {
          this.isUploading = false;
          this.isLoading = false;
          this.snackbar.openSnackbar('successfully uploaded');
          this.getTestDetails();
        },
        (err) => {
          this.isUploading = false;
          this.isLoading = false;
          this.snackbar.openSnackbar('Unable to upload the solutions');
        }
      )
  }

  back() {
    this.location.back();
  }

  onReviewAdded(event) {
    this.getTestDetails();
  }


}
