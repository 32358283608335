import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ParentService } from '../services/parent.service';
import { takeUntil, flatMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TeacherInvite } from 'src/app/models/teacher-invite.models';
import { Student } from 'src/app/models/student.models';

@Component({
  selector: 'app-accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.scss']
})
export class AcceptInviteComponent implements OnInit {

  selectedProfileIndex : number;
  selectedProfile : any = {};
  isSelected: boolean = false
  invitations : TeacherInvite;
  private destroy$$ = new Subject<any>();
  childrenProfile = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data , 
    public dialogRef: MatDialogRef<DashboardComponent>, 
    public snackbar: SnackbarService,
    public parentService: ParentService) { }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: `0px`});
    this.invitations = this.data.invites;
    this.parentService.children$.pipe(
      takeUntil(this.destroy$$),
      flatMap(child => child)
    ).subscribe(
      (res) => {
        this.childrenProfile.push(res);
        console.log(this.childrenProfile)
      },
      (err) => this.snackbar.openSnackbar(err.error.error)
    )
  }

  selectProfile(index) {
    this.selectedProfileIndex = index
    this.isSelected = true
    this.selectedProfile = this.childrenProfile[index];
  }

  close() {
    this.dialogRef.close()
  }

  acceptInvite() {
    const id = this.invitations.invitationID;
    const body = {
      classID : this.invitations.classID,
      studentID : this.selectedProfile.id
    }

    this.parentService.acceptStudentInvitation(id, body).pipe(
      takeUntil(this.destroy$$)
    ).subscribe(
      (res) => { 
        this.snackbar.openSnackbar(res.msg);
        this.dialogRef.close(id);
      },
      (err) => this.snackbar.openSnackbar(err.error.error)
    )
  }


}
