<div class="analytics-wrapper bordered-box">
    <div class="analytics-header">
        <h3>Analytics</h3>
        <button mat-flat-button color="primary" (click)="openReport()" class="report">Report</button>
        <button *ngIf="selectedTab.toLowerCase() !== 'overview' && selectedTab !== tabs[5]" (click)="openFilters()" mat-flat-button color="primary">Filter</button>
    </div>
    <div class="tabs">
        <mat-tab-group (selectedTabChange)="tabSelected($event)">
            <mat-tab *ngFor="let tab of tabs">
                <ng-template mat-tab-label>{{ tab }}</ng-template>
                <!-- commenting for demo will be needed later -->
                <!-- <ng-container *ngIf="tab.toLowerCase() === 'overview'">
          <app-analytics></app-analytics>
        </ng-container> -->

                <ng-container *ngIf="tab === tabs[0]">
                    <div class="image-wrap">
                        <!-- <img src="assets/overview-analytics.png" alt="School Analytics"> -->
                        <iframe #overview class="overview-iframe"></iframe>
                    </div>
                </ng-container>

                <ng-container *ngIf="tab === tabs[1]">
                    <div class="image-wrap">
                        <!-- <img src="../../../assets/school-analytics.png" alt="School Analytics"> -->
                        <iframe #school></iframe>
                    </div>
                </ng-container>

                <ng-container *ngIf="tab === tabs[2]">
                    <div class="image-wrap">
                        <!-- <img src="../../../assets/teacher-analytics.png" alt="School Analytics"> -->
                        <iframe #teacher></iframe>
                    </div>
                </ng-container>

                <ng-container *ngIf="tab === tabs[3]">
                    <div class="image-wrap">
                        <!-- <img src="../../../assets/student-analytics.png" alt="School Analytics"> -->
                        <iframe #student></iframe>
                    </div>
                </ng-container>

                <ng-container *ngIf="tab === tabs[4]">
                    <div class="image-wrap">
                        <!-- <img src="../../../assets/subject-analytics.png" alt="School Analytics"> -->
                        <iframe #subject></iframe>
                    </div>
                </ng-container>

                <ng-container *ngIf="tab === tabs[5]">
                  <div class="image-wrap">
                    <iframe #liveClass></iframe>
                  </div>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
