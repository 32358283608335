import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';
import { USER_TYPE_KEY } from 'src/app/Constants/local-storage-keys';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private httpService: HttpClient,
    private localStorageSvc: LocalStorageService
  ) { }

  private otpTimer$$ = new BehaviorSubject('00:60');
  private intervalId: any;

  public get otpTimer$(): Observable<string> {
    return this.otpTimer$$.asObservable();
  }

  sendOtp(num: string) {
    return this.httpService.post(`${environment.apiUrl}send-otp`, {
      mobileNumber: num,
      role: this.localStorageSvc.getItem(USER_TYPE_KEY) === USER_TYPES.student ? 'parent' : this.localStorageSvc.getItem(USER_TYPE_KEY),
    });
  }

  startOTPTimer() {
    let i = 60;
    this.intervalId = setInterval(_ => {
      i -= 1;
      if (i < 0) {
        this.otpTimer$$.next(`00:00`);
        clearInterval(this.intervalId);
        return;
      } else if (i < 10) {
        this.otpTimer$$.next(`00:0${i}`);
      } else {
        this.otpTimer$$.next(`00:${i}`);
      }
    }, 1000);
  }

  clearInterval() {
    clearInterval(this.intervalId);
    this.otpTimer$$.next('00:00');
    this.intervalId = null;
  }

  verify(otp: string, sessionID: string, role: string, mobileNumber: string) {
    return this.httpService.post(`${environment.apiUrl}login`,
      {
        otp: otp.toString(),
        sessionID,
        role: role === USER_TYPES.student ? 'parent' : role,
        mobileNumber,
      }
    );
  }

  loginWithPin(pin: string, role: string, mobileNumber: string) {
    return this.httpService.post(`${environment.apiUrl}v2/login`,
      {
        pin,
        role: role === USER_TYPES.student ? 'parent' : role,
        mobileNumber,
      }
    );
  }

  superAdminLogin(email: string, password: string) {
    return this.httpService.post(`${environment.apiUrl}super-admin/login`, {
      email,
      password
    });
  }
}
