<div class="list-wrapper">
    <div class="header">
        <div>
            <span class="txt">Ques.</span>
            <span class="value">({{totalAttempt}}/{{totalQues}} Answered)</span>
        </div>
        <div class="resize">
            <div class="bullet" (click)="changeView('bullet')" *ngIf="!detailView"><img src="../../../../assets/icon-grey.svg"/></div>
            <div class="bullet" (click)="changeView('bullet')" *ngIf="detailView"><img src="../../../../assets/icon-2.svg"/></div>
            <div class="small" (click)="changeView('grid')" *ngIf="detailView"><img src="../../../../assets/small-window-2.svg"/></div>
            <div class="small" (click)="changeView('grid')" *ngIf="!detailView"><img src="../../../../assets/small-window.svg"/></div>
        </div>
    </div>

    <div class="view">
        <ng-container *ngIf="detailView; else grid">
            <div class="number">
                <div *ngFor="let question of questions; trackBy: trackByQuestionByID; let i=index" class="group" [ngClass]=getStatus(question)>
                    <div class="round" (click)="selectQuestion(question, i)" [ngClass]="{'large': i > 8}">{{i + 1}}</div>
                </div>
            </div>
        </ng-container>

        <ng-template #grid>
            <div *ngFor="let question of questions;trackBy: trackByQuestionByID; let i=index" [ngClass]=getStatus(question) class="grid-group">
                <div [ngClass]="{'large': i > 8}" class="round" (click)="selectQuestion(question, i)">{{i + 1}}</div>
                <div [ngClass]=getTextColor(getStatus(question)) class="txt" (click)="selectQuestion(question, i)">{{question?.question}}</div>
            </div>
        </ng-template>
    </div>

    <div class="action">
        <div class="previous">
            <button mat-flat-button color="primary" (click)="goToQuestion('previous')">PREVIOUS</button>
        </div>

        <div class="next">
            <button mat-flat-button color="primary" (click)="goToQuestion('next')">NEXT</button>
        </div>
    </div>
</div>