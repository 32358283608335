import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { Router } from '@angular/router';
import { BASE_ROUTES } from 'src/app/Constants/route-constants';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';
import { SuperAdminProfileService } from '../services/super-admin-profile.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  selected = 0;
  isTabSelected = false;
  tabs = [
    { name: 'users', img: '../../../../assets/mywork.svg' },
    { name: 'analytics', img: '../../../assets/graph.svg' },
    { name: 'cloud', img: '../../../assets/library-icon.svg' },
    { name: 'logout', img: '../../../assets/settings-mark.svg' }
  ];
  schoolLabel;
  subscription = new Subscription();
  constructor(
    private router: Router,
    private sidenavService: ToggleSidenavService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private profileService: SuperAdminProfileService,
    private snackbar: SnackbarService,
    public themeService: UserService,
  ) { }

  ngOnInit(): void {
    this.sidenavService.sidenavToggle$.subscribe(toggle => {
      this.isTabSelected = toggle;
      const routeName = this.router.url.split('/')[3];
      this.selected = this.tabs.findIndex(tab => tab.name.toLowerCase() === routeName);
      this.selected = this.selected > 0 ? this.selected : 0;
      this.cd.detectChanges();
    });
  }


  select(index: number) {
    if (index === 3) {
      this.showAlertMsg();
      return;
    }
    this.selected = index;
    this.router.navigate([BASE_ROUTES.superAdmin, 'dashboard', this.tabs[index].name]);
  }

  showAlertMsg() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '363px',
      height: '195px',
      backdropClass: 'blur',
      data: { theme: USER_TYPES.superAdmin, isUpload: true, isLogout: true, alertText: 'Are you sure, you want to logout of the app?' },
      panelClass: ['delete-dialog-css'],
      autoFocus: false
    });
    return dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.logout();
      } else {
        return;
      }
    });
  }

  logout() {
    this.profileService.logout().then((res: any) => {
      this.snackbar.openSnackbar(res.msg);
      this.themeService.clearUserInCache();
      this.router.navigate([BASE_ROUTES.superAdmin, 'login']).then(() => {
        window.location.reload();
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
