import { Component, OnInit } from '@angular/core';
import { LibraryModel } from 'src/app/models/library.models';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CreateFolderComponent } from 'src/app/shared/create-folder/create-folder.component';
import { USER_TYPES, USER_THEMES } from 'src/app/Constants/user-types-constants';
import { CloudContentService } from 'src/app/services/cloud-content.service';

const ACTION_ITEM = ['Upload File', 'Create Folder'];
const DISPLAY_COLUMNS = ['select', 'name', 'title', 'createdAt', 'size', 'actions'];
const dialogWidth = '386px';
const dialogHeight = '350px';
@Component({
  selector: 'app-super-admin-library',
  templateUrl: './super-admin-library.component.html',
  styleUrls: ['./super-admin-library.component.scss']
})
export class SuperAdminLibraryComponent implements OnInit {
  isLoading = true;
  step1 = true;
  posts: LibraryModel[] = [];
  selectedEntity;
  action = new FormControl();
  postsColumn: string[] = DISPLAY_COLUMNS;
  actionList: string[] = ACTION_ITEM;

  userTypes = USER_TYPES;
  constructor(
    public dialog: MatDialog,
    public libraryService: CloudContentService,
    public sidenav: ToggleSidenavService,
    public snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.sidenav.maximizeSidenav();
    this.getLibrary();
    this.action.valueChanges.subscribe((type) => {
      if (!type) {
        return;
      }
      const index = this.actionList.findIndex(list => list === type);
      const actionType = this.actionList[index] && this.actionList[index].split(' ')[0].toLowerCase();

      const dialog = this.dialog.open(CreateFolderComponent, {
        width: dialogWidth,
        minHeight: dialogHeight,
        data: { type: actionType, themeName: USER_THEMES.superAdmin.name, isCloudLibrary: true },
        backdropClass: 'blur',
        disableClose: true,
        panelClass: ['width-class'],
        autoFocus: false
      });

      dialog.afterClosed().subscribe(res => {
        this.action.setValue(null);
        if (res) {
          this.isLoading = true;
          if (this.selectedEntity) {
            res.parent = this.selectedEntity.id;
          }
          this.libraryService.upload(res).subscribe(
            (response) => {
              this.snackbar.openSnackbar(response.msg);
              this.selectedEntity ? this.onFileContent(this.selectedEntity) : this.getLibrary();
            },
            (err) => {
              this.snackbar.openSnackbar(err.error.error);
              this.isLoading = false;
            }
          );
        }
      });

    });
  }

  back() {
    this.sidenav.maximizeSidenav();
    this.isLoading = true;
    this.step1 = true;
    this.selectedEntity = null;
    this.getLibrary();
  }

  getFileContent(fileID) {
    this.libraryService.getCloudFileContent(fileID).subscribe(
      (res) => {
        this.posts = res.libraryContents;
        this.step1 = false;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.snackbar.openSnackbar(err.error.error);
      });
  }

  onFileContent(entity: LibraryModel) {
    if (!entity && this.selectedEntity) {
      this.getLibrary(this.selectedEntity.id);
      return;
    }
    this.isLoading = true;
    if (!entity) {
      this.getLibrary();
    } else if (!entity.isFolder && entity.parent === 'root') {
      this.isLoading = true;
      this.getLibrary();
      return;
    } else if (entity.parent !== 'root') {
      this.getLibrary(entity.parent);
    } else {
      this.sidenav.minmizeSidenav();
      this.selectedEntity = entity;
      this.getLibrary(entity.id);
    }
  }

  getLibrary(folderId?: string) {

    this.libraryService.getCloudDirectoryContent(folderId).subscribe(
      (res) => {
        this.posts = res;
        this.isLoading = false;
        if (folderId) {
          this.step1 = false;
        }
      },
      (err) => {
        this.isLoading = false;
        this.snackbar.openSnackbar(err.error.error);
      }
    );
  }

}
