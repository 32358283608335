import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  alertText: string;
  action = false;
  public isAdminTheme = false;
  public isStudentTheme = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
    this.alertText = this.data.alertText;
    this.action = this.data.action ? true : false;
    this.isAdminTheme = this.data.theme === USER_TYPES.admin ? true : false;
    this.isStudentTheme = this.data.theme === USER_TYPES.student ? true : false;
  }

  closeDialog(value?) {
    if (value) {
      this.dialogRef.close(value);
    } else {
      this.dialogRef.close();
    }
  }


}
