import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsDashboardComponent } from '../analytics-dashboard/analytics-dashboard.component';
import { ANALYTICS_TABS } from 'src/app/Constants/Constant';
import { LabelService } from 'src/app/services/label.service';

@Component({
  selector: 'app-analytics-filter',
  templateUrl: './analytics-filter.component.html',
  styleUrls: ['./analytics-filter.component.scss']
})
export class AnalyticsFilterComponent implements OnInit, AfterViewInit {


  schoolsLabel = "Schools"

  constructor(
    private dialogRef: MatDialogRef<AnalyticsDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private labelSvc: LabelService,
  ) {
    this.labelSvc.labels$.subscribe(val => {
      if (val['school']) {
        this.schoolFilterTitle = val['school']
      }
      if (val['session']) {
        this.sessionFilterTitle = val['session']
      }
      if (val['schools']) {
        this.schoolsLabel = val['schools']
      }
    })
  }
  public isLoading: boolean = false;
  public schoolFilterTitle: string = 'School';
  public sessionFilterTitle: string = 'Session';
  @ViewChild('select') select: MatSelect;
  allSelected = false;
  schools: any[] = [{ name: "All", id: "all" }];
  sessions: any[] = [{ name: "All", id: "all" }];
  selectedSession = null;
  filterForm: FormGroup;


  //for dates
  allDateRanges = []
  rollingDates = []

  isRollingDateSelected: boolean = false;
  isCustomDateSelected: boolean = false;
  showSchoolFilter: boolean = true;

  presentDate = new Date();

  ngOnInit(): void {
    if (this.data.filterTab == ANALYTICS_TABS.SCHOOL) {
      this.showSchoolFilter = false;
    }
    let presentDate = new Date();
    let yesterDay = new Date();
    yesterDay.setDate(yesterDay.getDate() - 1)
    let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7)
    let last14Days = new Date();
    last14Days.setDate(last14Days.getDate() - 14)
    let last30Days = new Date();
    last30Days.setDate(last30Days.getDate() - 30)
    let last60Days = new Date();
    last60Days.setDate(last60Days.getDate() - 60)
    this.allDateRanges = [
      { label: "All data", dateRange: { startDate: null, endDate: null } },
      { label: "Today", dateRange: { startDate: presentDate, endDate: presentDate } },
      { label: "Yesterday", dateRange: { startDate: yesterDay, endDate: presentDate } },
      { label: "Last Week", dateRange: { startDate: lastWeek, endDate: presentDate } },
      { label: "Rolling date range", dateRange: { startDate: null, endDate: null } },
      { label: "Custom date range", dateRange: { startDate: null, endDate: null } }
    ]

    this.rollingDates = [
      { label: "All data before today", dateRange: { startDate: null, endDate: null } },
      { label: "Last 7 days", dateRange: { startDate: lastWeek, endDate: presentDate } },
      { label: "Last 14 days", dateRange: { startDate: last14Days, endDate: presentDate } },
      { label: "Last 30 days", dateRange: { startDate: last30Days, endDate: presentDate } },
      { label: "Last 60 days", dateRange: { startDate: last60Days, endDate: presentDate } },
    ]



    this.filterForm = new FormGroup({
      school: new FormControl(null, [Validators.required, Validators.minLength(1)]),
      session: new FormControl(),
      allDateRange: new FormControl(this.allDateRanges[0].label),
      rollingDateRange: new FormControl(),
      startDate: new FormControl(this.presentDate),
      endDate: new FormControl(this.presentDate),
    })

    // this.filterForm.controls.allDateRange.patchValue(this.allDateRanges[0].label)
    if (this.data.appliedFilters) {
      let allDateRangeValue = this.data.appliedFilters.allDateRangeValue;
      let index = this.allDateRanges.findIndex((d) => d.label == allDateRangeValue)
      this.filterForm.controls.allDateRange.patchValue(this.allDateRanges[index].label)
      if (index == 4) {
        this.isRollingDateSelected = true;
        let rollingDateRangeValue = this.data.appliedFilters.rollingDateRangeValue;
        let index = this.rollingDates.findIndex((d) => d.label == rollingDateRangeValue)
        this.filterForm.controls.rollingDateRange.patchValue(this.rollingDates[index].label)
      } else if (index == 5) {
        this.isCustomDateSelected = true;
        let startDate = this.data.appliedFilters.startDate;
        let endDate = this.data.appliedFilters.endDate;
        this.filterForm.controls.startDate.patchValue(new Date(startDate))
        this.filterForm.controls.endDate.patchValue(new Date(endDate))
      }
    }


    this.schools = this.schools.concat(this.data.schools);
    this.sessions = this.sessions.concat(this.data.sessions);
    this.dialogRef.updatePosition({ right: `0px` });
    this.filterForm.valueChanges.subscribe(data => {
      if (data.allDateRange == this.allDateRanges[4].label) {
        this.isRollingDateSelected = true;
      } else if (data.rollingDateRange !== this.rollingDates[0].label) {
        this.filterForm.controls.rollingDateRange.patchValue(this.rollingDates[0].label)
        this.isRollingDateSelected = false;
      } else {
        this.isRollingDateSelected = false;
      }

      if (data.allDateRange == this.allDateRanges[5].label) {
        this.isCustomDateSelected = true;
      } else if (this.filterForm.controls.startDate.value !== this.presentDate || this.filterForm.controls.endDate.value !== this.presentDate) {
        this.filterForm.controls.startDate.patchValue(this.presentDate)
        this.filterForm.controls.endDate.patchValue(this.presentDate)
        this.isCustomDateSelected = false;
      } else {
        this.isCustomDateSelected = false;
      }
    })
    let session = this.data.appliedFilters && this.data.appliedFilters.session;
    if (!session || session.length == (this.sessions.length - 1)) {
      this.filterForm.controls.session.patchValue("all")
    } else {
      let index = this.sessions.findIndex((s) => s.id == session[0]);
      let formValue = this.sessions[index].id;
      this.filterForm.controls.session.patchValue(formValue)
    }

    let school = this.data.appliedFilters && this.data.appliedFilters.school;
    if (!school || school.length == (this.schools.length - 1)) {
      this.filterForm.controls.school.patchValue("all")
    } else {
      let index = this.schools.findIndex((s) => s.id == school[0]);
      let formValue = this.schools[index].id;
      this.filterForm.controls.school.patchValue(formValue)
    }

  }

  ngAfterViewInit() {
    // let schools = this.data.appliedFilters && this.data.appliedFilters.schools;
    // if (!schools || schools.length == this.schools.length) {
    //   this.select.options.forEach((item: MatOption) => item.select());
    //   this.allSelected = true;
    // } else {
    //   this.select.options.forEach((item: MatOption) => {
    //     if (schools.includes(item.value)) {
    //       item.select()
    //     }
    //   })
    // }
  }

  close() {
    this.dialogRef.close();
  }

  clearFilters(event: Event) {
    event.stopPropagation();
    this.filterForm.controls.allDateRange.patchValue(this.allDateRanges[0].label)
    this.filterForm.controls.session.patchValue(this.sessions[0].id)
    this.filterForm.controls.school.patchValue(this.schools[0].id)
    this.allSelected = true;
  }

  toggleAllSelection(event: Event) {
    event.stopPropagation();
    if (!this.allSelected) {
      this.select.selected
      this.select.options.forEach((item: MatOption) => item.select());
      this.allSelected = true;
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
      this.allSelected = false;
    }
  }
  optionClick(event: Event) {
    event.stopPropagation();
    let newStatus = true;
    let isAllChecked: boolean = true;
    this.select.options.forEach((item: MatOption, counter) => {
      if (counter != 0 && !item.selected) {
        isAllChecked = false;
        newStatus = false;
        this.select.options.forEach((data, i) => { if (i === 0) { data.deselect() } })
        this.allSelected = newStatus;
      }
    });
    if (isAllChecked) {
      this.select.options.forEach((data, i) => { if (i === 0) { data.select() } })
      this.allSelected = true;
    }
  }
  get selectDisplayValue() {
    let l = this.select && (<MatOption[]>this.select.selected).length
    if (this.allSelected) {
      l = l - 1;
    }
    if (l == this.schools.length) {
      return 'All'
    } else if (l > 1) {
      return l + " " + this.schoolsLabel + " Selected"
    } else {
      return 1 + " " + this.schoolFilterTitle + " Selected"
    }
  }

  public applyFilters() {
    if (this.filterForm.valid) {
      let schoolFilterValue = this.filterForm.value.school;
      let startDate = null;
      let endDate = null;
      let rollingDateValue = this.filterForm.controls.rollingDateRange.value;
      let allDateRangeValue = this.filterForm.controls.allDateRange.value;
      if (this.isRollingDateSelected) {
        let index = this.rollingDates.findIndex((d) => d.label == rollingDateValue)
        startDate = this.rollingDates[index].dateRange.startDate;
        endDate = this.rollingDates[index].dateRange.endDate;
      } else if (this.isCustomDateSelected) {
        startDate = this.filterForm.controls.startDate.value;
        endDate = this.filterForm.controls.endDate.value;
      }
      else {
        let index = this.allDateRanges.findIndex((d) => d.label == allDateRangeValue)
        startDate = this.allDateRanges[index].dateRange.startDate;
        endDate = this.allDateRanges[index].dateRange.endDate;
      }
      let filters: any = {
        schools: schoolFilterValue,
        session: this.filterForm.value.session,
        startDate: startDate,
        endDate: endDate,
        allDateRangeValue: allDateRangeValue,
        rollingDateRangeValue: rollingDateValue,
      }
      this.dialogRef.close(filters);
    }
  }
}
