
<table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <div class="hideCheck">
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [color]="'primary'"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </div>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [color]="'primary'"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="mobileNumber">
    <th mat-header-cell *matHeaderCellDef>Phone </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.mobileNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="actions" >
    <th mat-header-cell *matHeaderCellDef class="action-table-header" >Actions</th>
    <td mat-cell *matCellDef="let transaction" class="action-table-header">
      <div class="select"> 
        <button mat-icon-button [matMenuTriggerFor]="menu" >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
            <button mat-menu-item disabled>
              <span>Make Active</span>
            </button>
            <!-- <button mat-menu-item>
              <span>Make Inactive</span>
            </button> -->
          </mat-menu>
    </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>

  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<ng-container>
  <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize> </mat-paginator>
</ng-container>

