import { Component, OnInit } from '@angular/core';
import { ChildWorkActivity, WorkState, CompletedWorkGroupByDate } from 'src/app/models/child-work-activity-model';
import { ChildWorkService } from '../services/child-work.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OVERDUE, TODAY, UPCOMING, COMPLETED, ACTIVITY_TYPES, LIVE_CLASS_TABS } from 'src/app/Constants/Constant';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';


@Component({
  selector: 'app-child-work',
  templateUrl: './child-work.component.html',
  styleUrls: ['./child-work.component.scss']
})
export class ChildWorkComponent implements OnInit {

  constructor(
    private childWorkService: ChildWorkService,
    private route: ActivatedRoute,
    private router: Router,
    private sidenav: ToggleSidenavService
  ) { }

  width = window.innerWidth;
  tabs = [
    {
      label: 'Today',
      count: 0
    }, {
      label: 'Overdue',
      count: 0
    }, {
      label: 'Upcoming',
      count: 0
    }, {
      label: 'Completed',
      count: 0
    }
  ];

  workOverdue: ChildWorkActivity[];
  workToday: ChildWorkActivity[];
  workUpcoming: ChildWorkActivity[];
  workCompleted: CompletedWorkGroupByDate[];


  loadingState: WorkState = {
    overdue: true,
    completed: true,
    today: true,
    upcoming: true
  };

  errorState: WorkState = {
    overdue: false,
    completed: false,
    today: false,
    upcoming: false
  };

  studentId: string;
  timeCategories = {
    overdue: OVERDUE,
    today: TODAY,
    upcoming: UPCOMING,
    completed: COMPLETED
  };


  ngOnInit(): void {
    this.sidenav.maximizeSidenav();
    this.route.parent.parent.params.subscribe((params) => {
      this.studentId = params.id;
      this.childWorkService.getWorkActivities(this.studentId, OVERDUE);
      this.childWorkService.getWorkActivities(this.studentId, TODAY);
      this.childWorkService.getWorkActivities(this.studentId, UPCOMING);
      this.childWorkService.getWorkActivities(this.studentId, COMPLETED);
    });

    this.childWorkService.loadingState$.subscribe((loadingState) => {
      this.loadingState = loadingState;
    });

    this.childWorkService.errorState$.subscribe((errorState) => {
      this.errorState = errorState;
    });

    this.childWorkService.workOverdue$.subscribe((workOverdue) => {
      this.workOverdue = workOverdue;
      this.tabs[1].count = workOverdue.length;
    });

    this.childWorkService.workToday$.subscribe((workToday) => {
      this.workToday = workToday;
      this.tabs[0].count = workToday.length;
    });

    this.childWorkService.workUpcoming$.subscribe((workUpcoming) => {
      this.workUpcoming = workUpcoming;
    });

    this.childWorkService.workCompleted$.subscribe((workCompleted) => {
      if (workCompleted) {
        this.workCompleted = [];
        workCompleted.forEach(workActivity => {
          const workActivityDate = new Date(workActivity.date);
          const completionDate: Date = new Date(workActivityDate.getFullYear(), workActivityDate.getMonth(),
            workActivityDate.getDate(), 0, 0, 0);
          const index = this.workCompleted.findIndex((dateWork) => dateWork.completionDate.getTime() === completionDate.getTime());
          if (index < 0) {
            this.workCompleted.push({
              completionDate,
              activities: [workActivity]
            });
          } else {
            this.workCompleted[index].activities.push(workActivity);
          }
        });
      }
    });
  }

  tabSelect(event) {
    console.log('tab selected');
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    document.getElementsByClassName('mat-tab-header-pagination-before')[0].remove();
    document.getElementsByClassName('mat-tab-header-pagination-after')[0].remove();
  }

  goToActivity(activity: ChildWorkActivity, category: string) {
    switch (activity.activityType) {
      case ACTIVITY_TYPES.assignments:
        this.router.navigate(['../', 'post', ACTIVITY_TYPES.assignments, activity.activityID],
          { relativeTo: this.route });
        break;
      case ACTIVITY_TYPES.materials:
        this.router.navigate(['../', 'post', ACTIVITY_TYPES.materials, activity.activityID],
          { relativeTo: this.route });
        break;
      case ACTIVITY_TYPES.tests:
        this.router.navigate(['../', 'post', ACTIVITY_TYPES.tests, activity.activityID],
          { relativeTo: this.route });
        break;
      case ACTIVITY_TYPES.liveClasses:
        switch (category) {
          case OVERDUE:
            this.router.navigate(['../', 'live-class-schedule'], { relativeTo: this.route, queryParams: { tab: LIVE_CLASS_TABS.PAST } });
            break;
          case TODAY:
            this.router.navigate(['../', 'live-class-schedule'], { relativeTo: this.route, queryParams: { tab: LIVE_CLASS_TABS.TODAY } });
            break;
          case UPCOMING:
            this.router.navigate(['../', 'live-class-schedule'],
              { relativeTo: this.route, queryParams: { tab: LIVE_CLASS_TABS.UPCOMING, classDate: activity.date } });
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }
}
