<div class="dashboard-wrapper" [ngClass]="isTabSelected ? 'minNav ' + theme : theme" *ngIf="width >= 760">
  <mat-sidenav-container autosize *ngIf="width >= 760">
      <mat-sidenav color="primary" #sidenav [mode]="'side'" opened>
          <div class="sidenav" [ngClass]="theme">
              <div class="img">
                  <div class="circle"></div>
                  <div class="txt">shiksha</div>
              </div>

              <div *ngFor="let tab of tabs; let i=index" class="tabs" (click)="select(i)" [ngClass]="{'active' : selected === i}">
                  <div> <img [src]="tab.img" /></div>
                  <div class="name">{{
                    (tab.name == 'schools' ? schoolLabel : tab.name == 'classes' ? classesLabel :  tab.name) | uppercase
                  }}</div>
              </div>
          </div>
      </mat-sidenav>

      <mat-sidenav-content [ngClass]="{theme: true}">
          <div class="content">
              <router-outlet *ngIf="!err"></router-outlet>
          </div>
      </mat-sidenav-content>

  </mat-sidenav-container>
</div>
<div class="bot-dashboard-wrapper" [ngClass]="isTabSelected ? 'minNav ' + theme : theme" *ngIf="width < 760">
  <span class="toolbar-flex"></span>
  <nav mat-tab-nav-bar class="botNav" mat-stretch-tabs *ngIf="width < 760">
    <div mat-tab-link *ngFor="let tab of tabs; let i=index" class="botNavTabs" (click)="select(i)" [ngClass]="{'active' : selected === i}">
      <div class="img-container"> <img class= "img" [src]="tab.img" /></div>
      <!--      <div class="name">{{-->
      <!--        (tab.name == 'schools' ? schoolLabel : tab.name == 'classes' ? classesLabel :  tab.name) | uppercase-->
      <!--        }}</div>-->
    </div>
  </nav>
  <div class="basic-container" *ngIf="width < 760">
    <router-outlet *ngIf="!err"></router-outlet>
  </div>
</div>
