import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';

export interface testResultResponse {
  answers: any,
  classID: string,
  correctAnswers: number,
  id: string,
  marks: number,
  result: string,
  reviewStatus: string,
  studentID: string,
  studentName: string,
  testID: string,
  totalMarks: number
  unanswered: number
}
@Injectable({
  providedIn: 'root'
})
export class StudentTestService {

  public getTestRoute(testID) {
    return `${environment.apiUrl}student/test/get/${testID}`
  }

  public submitTestRoute() {
    return `${environment.apiUrl}student/test/submission`;
  }

  public resultRoute(testID, studentID) {
    return `${environment.apiUrl}student/test/results/${testID}/student/${studentID}`;
  }

  constructor(public crud: CrudService) { }

  getTest(testID) {
    return this.crud.get(this.getTestRoute(testID))
  }

  getQuestionFromGcs(url) {
    return this.crud.get(url)
  }

  submitTest(body) {
    return this.crud.post(this.submitTestRoute(), body);
  }

  getResult(testID, studentID) {
    return this.crud.get(this.resultRoute(testID, studentID))
  }
}
