import { Component, OnInit } from '@angular/core';
import { multi } from './data';
import * as shape from 'd3-shape';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  multi: any[];
  view: any[] = ['700', '300'];
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Month';
  yAxisLabel: string = 'Student';
  timeline: boolean = true;
  linearCurve;
  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  constructor() { }

  ngOnInit(): void {
    this.linearCurve = shape.curveCardinal;
    Object.assign(this, { multi });
  }

  onSelect(event) {
    console.log(event);
  }

}
