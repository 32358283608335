import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CrudService } from 'src/app/services/crud.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminAnalyticsService {

  constructor(
    private crud: CrudService,
    private snackbar: SnackbarService,
  ) {

  }

  private overviewDashboard$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get overviewDashboard$(): Observable<any> {
    return this.overviewDashboard$$.asObservable();
  }

  private schoolDashboard$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get schoolDashboard$(): Observable<any> {
    return this.schoolDashboard$$.asObservable();
  }

  private studentDashboard$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get studentDashboard$(): Observable<any> {
    return this.studentDashboard$$.asObservable();
  }

  private teacherDashboard$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get teacherDashboard$(): Observable<any> {
    return this.teacherDashboard$$.asObservable();
  }

  public getOverviewDashboard() {
    this.crud.post(environment.apiUrl + 'analytics/overview', {}).subscribe((resp) => {
        this.overviewDashboard$$.next({ url: resp.dashboardURL });
    }, err => this.snackbar.openSnackbar('Unable to get analytics dashboard'));
  }

  public getSchoolDashboard() {
    this.crud.post(environment.apiUrl + 'analytics/school', {}).subscribe((resp) => {
        this.schoolDashboard$$.next({ url: resp.dashboardURL });
    }, err => this.snackbar.openSnackbar('Unable to get analytics dashboard'));
  }

  public getStudentDashboard() {
    this.crud.post(environment.apiUrl + 'analytics/student', {}).subscribe((resp) => {
        this.studentDashboard$$.next({ url: resp.dashboardURL });
    }, err => this.snackbar.openSnackbar('Unable to get analytics dashboard'));
  }

  public getTeacherDashboard() {
    this.crud.post(environment.apiUrl + 'analytics/teacher', {}).subscribe((resp) => {
        this.teacherDashboard$$.next({ url: resp.dashboardURL });
    }, err => this.snackbar.openSnackbar('Unable to get analytics dashboard'));
  }
}
