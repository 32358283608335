import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GalleryItem, IframeItem, ImageItem } from 'ng-gallery';
import { docsPreviewList } from 'src/app/teachers/post-details/post-details.component';

@Component({
  selector: 'app-view-docs',
  templateUrl: './view-docs.component.html',
  styleUrls: ['./view-docs.component.scss']
})
export class ViewDocsComponent implements OnInit {
  title: string;
  files: docsPreviewList[] = [];
  images: GalleryItem[] = [];
  file
  curFileIndex: number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: '0px' });
    this.files = this.data.files;
    this.title = this.data.type;
  }

  onIndexChange(index) {
    this.curFileIndex = index;
  }

  close() {
    this.dialogRef.close();
  }

}
