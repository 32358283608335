import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, ViewChild, OnInit, Inject, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {debounceTime, switchMap, filter, distinctUntilChanged, catchError, map} from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InviteService } from 'src/app/services/invite.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { Subscription, Observable } from 'rxjs';
import { NumberValidationService } from 'src/app/services/number-validation.service';
import { LabelService } from 'src/app/services/label.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit, OnDestroy {
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  numberCtrl = new FormControl();
  filterednumbers
  numbers: string[] = [];
  fileUploaded = [];
  id: string;
  title: string;
  csvText: string;
  unregisterMoblie = [];
  invalidMobile = [];
  unregisterCount: number = 0;
  invalidCount = 0;
  placeholder= "You can enter multiple numbers"
  @ViewChild('numberInput') numberInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  schoolRole: 'School Admin' | 'School Member';
  inviteType: string;
  private subscription = new Subscription();
  alreadyInvitedNumbers: string[] = [];
  invalidNumber: string[] = [];
  isSubmitted: boolean = false;
  labelData;
  constructor(
     private dialogRef: MatDialogRef<any>,
     @Inject(MAT_DIALOG_DATA) public data,
     public snackbarService: SnackbarService,
     public uploadService: FileUploadService,
     public searchService: AutocompleteService,
     public numberValidation: NumberValidationService,
     private inviteService: InviteService,
     private labelService: LabelService) { }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: `0px`});
    this.id = this.data.id
    this.title = this.data.title;
    const schoolID = this.data.schoolID ? this.data.schoolID : null;
    const lastWord = this.title.split(' ');
    this.csvText = 'INSERT LIST'
    this.getInviteType(lastWord);
    this.filterednumbers = this.numberCtrl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(items => !!items),
      switchMap(value => this.searchService.search(value, this.inviteType, schoolID).pipe(
        map(numbers => {
          return Object.keys(numbers).map(_ => _.concat(" ", numbers[_]))
        }),
      )),
    )
    this.subscription = this.filterednumbers;
    this.subscription = this.labelService.labels$.subscribe(data => {
      this.labelData = data;
    })
  }
  getLabelData(title:string){
    return this.labelData[title.replace(/[- \s]/g, '').toLowerCase()]
  }
  getInviteType(lastWord) {
    if(lastWord[lastWord.length - 1].toLowerCase() === 'teachers') {
      this.inviteType = 'teacher';
    } else if (lastWord[lastWord.length - 1].toLowerCase() === 'staff') {
      this.inviteType = 'admin';
    } else if (lastWord[lastWord.length - 1].toLowerCase() === 'students') {
      this.inviteType = 'student';
    } else {
      this.inviteType = 'co-teacher';
    }
  }

  add(event: MatChipInputEvent): void {
    this.placeholder = '';
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if(value.trim().length === 10) {
        this.unregisterCount += 1;
        this.unregisterMoblie.push(value.trim());
      } else {
        this.invalidCount += 1;
        this.invalidMobile.push(value.trim());
      }
      this.numbers.push(value.trim());
    }
    if (input) {
      input.value = '';
    }

    this.numberCtrl.setValue(null);
    this.isSubmitted = this.isSubmitted === true ? false : true;
  }

  remove(number: string): void {
    const index = this.numbers.indexOf(number);
    const unregisteredIndex = this.unregisterMoblie.indexOf(number);
    const invalidIndex = this.invalidMobile.indexOf(number);

    if (unregisteredIndex >= 0) {
      this.unregisterMoblie.splice(index, 1);
      this.unregisterCount -=1;
    }

    if(invalidIndex >= 0) {
      this.invalidMobile.splice(index,1);
      this.invalidCount -= 1;
    }

    if (index >= 0) {
      this.numbers.splice(index, 1);
    }
    if(this.numbers.length === 0) {
      this.placeholder = 'You can enter multiple numbers'
    }
    this.isSubmitted = this.isSubmitted === true ? false : true;
  }

  isUnregistered(number) {
    const isRegistered =  this.unregisterMoblie.filter(no => {
      if(no.trim().length === 10) {
        return no.indexOf(number.trim()) === 0
      }
    });
    return isRegistered.length > 0;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.numbers.push(event.option.viewValue);
    this.numberInput.nativeElement.value = '';
    this.numberCtrl.setValue(null);
  }

  onChange(event) {
    this.schoolRole = event
  }

  closeDialog(res?) {
    this.dialogRef.close(res)
  }

  onImageUpload(event) {
    this.fileUploaded = event;
  }

  isCharacterALetter(char) {
    return (/[a-zA-Z]/).test(char)
  }

  isValidNumber(number) {
    if(number.split(' ').length > 1) {
      return true;
    }
    return number && number.length !== 10 ? false : true;
  }

  sendInvite() {
    const numbers = this.numbers && this.numbers.map(number =>  number.split(' ')[0]);
    if(!this.schoolRole && this.inviteType === 'admin') {
      this.snackbarService.openSnackbar('Please assign role');
      return
    }

    if(this.fileUploaded.length === 0 && this.numbers.length === 0 ) {
      this.snackbarService.openSnackbar('Please enter number or upload file');
      return;
    }

    if(this.fileUploaded.length > 0 && this.numbers.length > 0 ) {
      this.snackbarService.openSnackbar('Please either enter number or upload file ');
      return;
    }

    if(this.invalidCount) {
      this.snackbarService.openSnackbar('Please add valid numbers!!');
      return;
    }

    const requestBody = this.fileUploaded.length > 0 ? { mobileNumbers : [...this.fileUploaded] } : { mobileNumbers : [...numbers] }
    const invitationService: Observable<any> = this.inviteService.sendInvite(this.id, requestBody,this.inviteType ,this.schoolRole)
    this.subscription = invitationService.subscribe(
            (res) => {
              this.snackbarService.openSnackbar("Invitation Sent Successfully");
              this.closeDialog('true');
            },
            (err) => {
              if(err.error.alreadyInvitedNumbers) {
                this.isSubmitted = true;
                this.alreadyInvitedNumbers = err.error.alreadyInvitedNumbers;
                this.snackbarService.openSnackbar("Invitation already sent to these numbers");
              } else if(err.error.schoolCreatorInvited) {
                this.isSubmitted = true;
                this.alreadyInvitedNumbers = err.error.schoolCreatorInvited;
                this.snackbarService.openSnackbar("you are not allowed to send invite to creator of school");
              }
               else if(err.error.invalidNumbers) {
                this.isSubmitted = true;
                this.invalidNumber = err.error.invalidNumbers;
                this.snackbarService.openSnackbar("Numbers provided are invalid numbers");
              } else if(err.error.unregisteredTeachers) {
                this.isSubmitted = true;
                this.invalidNumber = err.error.unregisteredTeachers;
                this.snackbarService.openSnackbar('Teachers have not registered yet');
              } else if(err.error.unregisteredStudents) {
                this.isSubmitted = true;
                this.invalidNumber = err.error.unregisteredStudents;
                this.snackbarService.openSnackbar('Students have not registered yet');
              } else if(err.error.selfInvitedNumber) {
                this.isSubmitted = true;
                this.invalidNumber = err.error.selfInvitedNumber;
                this.snackbarService.openSnackbar('self invite not permissible');
              } else {
                this.snackbarService.openSnackbar(err.error.error)}
              }
        )
  }

  isAlreadyInvitedNumber(number) {
    return this.numberValidation.isAlreadyInvitedNumber(this.alreadyInvitedNumbers, number);
  }

  isInvalidNumber(number) {
    return this.numberValidation.isInvalidNumber(this.invalidNumber, number);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
