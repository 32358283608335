<div class="course-copy-dialog-wrapper">
    <div class="header">
        <div>Copy Course</div>
        <div (click)="closeDialog()" class="back"><img src="../../../assets/close.svg" /></div>
    </div>

    <div class="hr">
        <hr>
    </div>
    <div class="chip">
        <mat-form-field appearance="outline" [floatLabel]="'always'">
            <mat-label>Schools</mat-label>
            <mat-chip-list #chipList>
                <mat-chip
                  *ngFor="let school of selectedSchools"
                  [selectable]="true"
                  [removable]="true"
                    (removed)="remove(school)">
                    {{ school.name }}
                    <mat-icon matChipRemove><img src="../../../assets/close.svg" /></mat-icon>
                </mat-chip>
                <input
                  matInput
                  #schoolInput
                  [placeholder]="placeholder"
                  [formControl]="schoolFC"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                >
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let filter of searchedSchools" [value]="filter.id">
                    {{filter.name}}
                </mat-option>
                <mat-option *ngIf="!loading && schoolFC.value && searchedSchools.length === 0" [value]="''" disabled>
                  No data found
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

    </div>

    <div class="btn">
        <button [disabled]="copyingSchools" mat-flat-button color="primary" (click)="copy()">COPY</button>
    </div>

</div>
