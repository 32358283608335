<ng-container *ngIf="isLoading; else filters">
    <app-spinner></app-spinner>
</ng-container>
<ng-template #filters>
    <div class="filters-wrapper">
        <div class="user-inputs">
            <div class="header">
                <div class="text">Filters</div>
                <div class="img" (click)="close()">
                    <img src="assets/close.svg" />
                </div>
            </div>
            <div class="filter-container" [formGroup]="filterForm">
                <div class="school-filter-container" [ngClass]="showSchoolFilter ? '': 'hidden'">
                    <div class="title">
                        <p>{{ schoolFilterTitle }}</p>
                    </div>
                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="filter-input">
                        <mat-label>Select</mat-label>
                        <mat-select placeholder="Select" #select formControlName="school" panelClass="filterSelect">
                            <!-- <mat-select-trigger>
                                {{selectDisplayValue}}
                            </mat-select-trigger> -->
                            <!-- <div class="select-all"> -->
                            <!-- <mat-option class="select-all-checkbox" (click)="toggleAllSelection($event)" value='all'>Select All
                            </mat-option> -->
                            <!-- </div> -->
                            <mat-option *ngFor="let school of schools" [value]="school.id">
                                {{ school.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!filterForm.controls['school'].valid && filterForm.controls['school'].touched">
                            {{'please select at least one value'}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="session-filter-container">
                    <div class="title">
                        <p>{{ sessionFilterTitle }}</p>
                    </div>

                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="filter-input">
                        <mat-label>Select</mat-label>
                        <mat-select #sessionSelect placeholder="Select" formControlName="session">
                            <mat-option *ngFor="let s of sessions" [value]="s.id">
                                {{s.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="session-filter-container">
                    <div class="title">
                        <p>Date Range</p>
                    </div>
                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="filter-input">
                        <mat-label>Select Date Range</mat-label>
                        <mat-select #sessionSelect placeholder="Select" formControlName="allDateRange">
                            <mat-option *ngFor="let d of allDateRanges" [value]="d.label">
                                {{d.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="isRollingDateSelected" appearance="outline" [floatLabel]="'always'" class="filter-input">
                        <mat-label>Select Rolling Dates</mat-label>
                        <mat-select #sessionSelect placeholder="Select" formControlName="rollingDateRange">
                            <mat-option *ngFor="let d of rollingDates" [value]="d.label">
                                {{d.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div *ngIf="isCustomDateSelected" class="custom-date">
                        <mat-form-field class="custom-date-input" appearance="outline" [floatLabel]="'always'">
                            <mat-label>From</mat-label>
                            <input matInput placeholder="DD-MM-YY" (click)="startpicker.open()" [max]="presentDate" [matDatepicker]="startpicker" formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="startpicker">
                                <mat-icon matDatepickerToggleIcon>
                                    <img src='assets/calender.svg' />
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #startpicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="custom-date-input" appearance="outline" [floatLabel]="'always'">
                            <mat-label>To</mat-label>
                            <input matInput placeholder="DD-MM-YY" (click)="picker.open()" [min]="filterForm.value.startDate" [max]="presentDate" [matDatepicker]="picker" formControlName="endDate">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>
                                    <img src='assets/calender.svg' />
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

            </div>
        </div>

        <div class="btn">
            <button class="clear-button" mat-flat-button color="primary" (click)="clearFilters($event)">CLEAR ALL</button>
            <button mat-flat-button color="primary" (click)="applyFilters()">APPLY</button>
        </div>
    </div>
</ng-template>
