<div class="settings-wrapper" [ngClass]="{'container': isSchoolSpecificSetting}">
    <ng-container *ngIf="!isSchoolSpecificSetting">
        <div class="upper">Settings</div>
    </ng-container>

    <div class="lower bordered-box" [ngClass]="{'school-specific': isSchoolSpecificSetting}">
        <div class="text">Common Settings</div>
        <div class="setting-box">
            <div *ngFor="let setting of settings" class="container" (click)="selectSettings(setting)">
                <div class="img">
                    <img [src]=setting.src />
                </div>
                <div class="name">
                    {{setting.name == 'Standards' ? standardsLabel : (setting.name == 'Subjects' ? subjectsLabel : (setting.name == 'Sections' ? sectionsLabel : (setting.name == 'Session' ? sessionLabel : setting.name)) ) }}
                </div>
            </div>
        </div>

        <ng-container *ngIf="isSchoolSpecificSetting else otherCommonSettings">
            <div class="wrapper">
                <div class="other">Other Settings</div>
                <div class="others">
                    <div class="setting">
                        <div class="txt">Allow Teachers To Invite Students</div>
                        <div class="radio-btn-container">
                            <mat-radio-group class="radio-btn" [formControl]="allowTeacherToInvite">
                                <mat-radio-button [disabled]="updatingInviteSetting" color="primary" class="radio-btn-margin" [value]="true">Yes</mat-radio-button>
                                <mat-radio-button [disabled]="updatingInviteSetting" color="primary" [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #otherCommonSettings>
            <div class="wrapper" *ngIf="!isSchoolSpecificSetting">
                <div class="other">Other Settings</div>
                <div class="others">
                    <div *ngFor="let other of otherSetting" class="setting" (click)="seeSetting(other)">
                        <div class="txt">{{other}}</div>
                        <div class="img"><img src="../../../../assets/chevron-right.svg" /></div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <ng-container *ngIf="updatingInviteSetting">
        <div class="backdrop"></div>
    </ng-container>
</div>
