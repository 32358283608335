import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { SchoolSpecificSettingsService } from '../services/school-specific-settings.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TeacherService } from 'src/app/services/api/teacher.service';
import { LabelService } from 'src/app/services/label.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-class',
  templateUrl: './create-class.component.html',
  styleUrls: ['./create-class.component.scss']
})
export class CreateClassComponent implements OnInit, OnDestroy {
  classForm: FormGroup;
  isSchoolSelected = true;
  isSubmitted = false;
  schools =  [];
  sessions = [];
  subjects = [];
  sections = [];
  standards = [];
  classDetail;
  labelData = {};
  private destroy$$: Subject<any> = new Subject<any>();
  constructor(
              private fb: FormBuilder,
              public classTeacherService : TeacherService,
              @Inject(MAT_DIALOG_DATA) public data,
              public classService: SchoolSpecificSettingsService,
              public snackbar: SnackbarService,
              private dialogRef: MatDialogRef<DashboardComponent>,
              private labelService: LabelService
  ) { }

  ngOnInit(): void {
    this.labelService.labels$.pipe(takeUntil(this.destroy$$)).subscribe(data => this.labelData = data)
    this.classDetail = this.data.classDetail
    this.dialogRef.updatePosition({ right: `0px`});
    this.schools = this.data.schools && this.data.schools.filter(data => data.status == 'active');
    this.buildForm();
  }

  buildForm() {
      this.classForm = this.fb.group({
        'school' : [null, [Validators.required]],
        'className': [null, [Validators.required, Validators.maxLength(100)]],
        'standard': [null, [Validators.required]],
        'section': [null, [Validators.required]],
        'subject': [null, [Validators.required]],
        'session': [null, [Validators.required]],
      })
  }
    compareObjects(o1: any, o2: any): boolean {
      return o1 && o2 && o1.name === o2.name && o1.id === o2.id;
    }
  resetForm() {
    this.classForm.controls['standard'].setValue('');
    this.classForm.controls['section'].setValue('');
    this.classForm.controls['subject'].setValue('');
    this.classForm.controls['session'].setValue('');
  }

  schoolSelected(event) {
    const id = event.value.id;
    this.resetForm();

    this.isSchoolSelected = false;
    this.classService.getStandards(id).subscribe(
      (standard) => {
        if(standard.length === 0) {
          this.snackbar.openSnackbar('no standards are available for this selected school')
        } else {
          this.standards = standard;
        }
      },
      (err) => { this.snackbar.openSnackbar(err.error.error)}
    )
    this.classService.getSections(id).subscribe(
      (section) => {
        if(section.length === 0) {
          this.snackbar.openSnackbar('no sections are available for this selected school')
        } else {
          this.sections = section;
        }
      },
      (err) => { this.snackbar.openSnackbar(err.error.error)}
    )
    this.classService.getSessions(id).subscribe(
      (session) => {
        if(session.length === 0) {
          this.snackbar.openSnackbar('no session are available for this selected school')
        } else {
          session.map(session => session.name = session.starting.concat(' - ', ' ', session.ending))
          this.sessions = session;
        }
      },
      (err) => { this.snackbar.openSnackbar(err.error.error)}
    )
    this.classService.getSubjects(id).subscribe(
      (subject) => {
        if(subject.length === 0) {
          this.snackbar.openSnackbar('no standards are available for this selected school')
        } else {
          this.subjects = subject;
        }
      },
      (err) => { this.snackbar.openSnackbar(err.error.error)}
    )
  }

  closeDialog() {
    this.dialogRef.close();
  }

  createClass() {
    if(this.classForm.valid) {
      this.isSubmitted = true;
        const requestbody = {
          name: this.classForm.controls['className'].value.trim(),
          schoolID: this.classForm.controls['school'].value.id.trim(),
          standardID: this.classForm.controls['standard'].value.standardID.trim(),
          sectionID: this.classForm.controls['section'].value.sectionID.trim(),
          subjectID: this.classForm.controls['subject'].value.subjectID.trim(),
          sessionID: this.classForm.controls['session'].value.sessionID.trim()
        }
        this.classTeacherService.createClass(requestbody).subscribe(
          (res) => {
            this.snackbar.openSnackbar('class successfully created');
            this.dialogRef.close(res);
          },
          (err) => {
            this.isSubmitted = false;
            this.snackbar.openSnackbar(err.error.error);
          }
        )
    }
  }
  ngOnDestroy(){
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}