<div class="view-docs-wrapper">
    <div class="text">
        <div>My Assignment: Evaluated ({{curFileIndex + 1}} / {{ files.length}})</div>
        <div class="img" (click)="close()"><img src="../../../assets/close.svg"/></div>
    </div>
    
    <div class="container">
        <div class="caraousel">
            <app-doc-caraousel [files]="files" (index)="onIndexChange($event)"></app-doc-caraousel>
        </div>
        
        <div class="metadata">
            <div class="assignment-marks">
                <div>
                  <app-shiksha-input [labelText]="'Marks Obtained'">
                    <input type="text" [value]="marks" disabled>
                  </app-shiksha-input>
                </div>
                
                <div class="totalMarks">
                  <app-shiksha-input [labelText]="'Total Marks'">
                    <input type="text" [value]="totalMarks" disabled>
                  </app-shiksha-input>
                </div>
            </div>
            <div class="assignment-notes">
                <app-shiksha-input [labelText]="'Note'">
                  <textarea name="notes" id="notes"  [value]="remarks" disabled></textarea>
                </app-shiksha-input>
            </div>
        </div>
    </div>
</div>
