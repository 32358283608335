<ng-container *ngIf="isLoading; else result">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #result>
    <div class="result-wrapper bordered-box">
        <div class="header">
            <div (click)="goBack()" class="back"> <img src="../../../../assets/green-back.svg" /></div>
            <div class="text">{{test.title | titlecase}}</div>
        </div>

        <div class="subheader  bordered-box">
            <div class="card">
                <div class="detail">
                    <div class="text">
                        <div class="head">Total Marks</div>
                        <div class="value1">: {{test.totalMarks}}</div>
                    </div>
                    <ng-container *ngIf="!isCourseActivity">
                        <div class="text">
                            <div class="head">Date</div>
                            <div class="value3">: {{test.timings | date: dateFormat }}</div>
                        </div>
                        <div class="text">
                            <div class="head">Time</div>
                            <div class="value4">: {{test.timings | date: 'shortTime'}}</div>
                        </div>
                    </ng-container>
                </div>
                <div class="test" (click)="previewTest()">VIEW TEST</div>
            </div>

            <div class="result">
                <div class="text">
                    <div class="nam">My Result</div>
                    <div class="evaluation" (click)="viewEvaluation()">VIEW EVALUATION</div>
                </div>
                <div class="detail">
                    <div class="marks">
                        <div class="txt">Marks Obtained</div>
                        <ng-container *ngIf="isCourseActivity else marks">
                            <div class="value1">: {{testResult.marksObtained}}</div>
                        </ng-container>
                        <ng-template #marks>
                            <div class="value1">: {{testResult.marks}}</div>
                        </ng-template>
                    </div>
                    <div class="marks">
                        <div class="txt">Correct Answers</div>
                        <div class="value2">: {{testResult.correctAnswers}}</div>
                    </div>
                    <div class="marks">
                        <div class="txt">Incorrect Answers</div>
                        <div class="value3">: {{testResult.incorrectAnswers}}</div>
                    </div>
                    <div class="marks">
                        <div class="txt">Unanswered</div>
                        <div class="value4">: {{testResult.unanswered}}</div>
                    </div>
                    <div class="marks">
                        <div class="txt">Result</div>
                        <div class="status" [ngClass]="{'pass': testResult.result === 'PASS'}">: {{testResult.result | uppercase}}
                        </div>
                    </div>
                </div>
            </div>

            <hr>

            <div class="solution" *ngIf="test.solutions">
                <div class="text">
                    <div class="sol">Solutions</div>
                    <div class="download">DOWNLOAD ALL</div>
                </div>
                <div>
                    <app-view-attachment [files]="test.solutions" [urls]="solutionUrl"></app-view-attachment>
                </div>
            </div>
        </div>
    </div>
</ng-template>
