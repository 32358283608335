import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-shared-login',
  templateUrl: './shared-login.component.html',
  styleUrls: ['./shared-login.component.scss']
})
export class SharedLoginComponent implements OnInit {
  @Input() theme : 'student' | 'teacher' | 'admin'
  @Input() isOtp: boolean = false;
  @Input() isNumberError: boolean = false;
  @Input() isOTPError: boolean;
  @Input() otpError: string;
  @Input() otp = '';
  @Input() otpTimer = '';
  @Input() hasError;
  @Output() phoneNumberInput = new EventEmitter();
  @Output() loginApp = new EventEmitter();
  @Output() verifyApp = new EventEmitter();
  @Output() onInputChange = new EventEmitter();
  @Output() onSendOTP = new EventEmitter();
  inputNumber;
  constructor() { }

  ngOnInit(): void { }

  enterPhoneNumber(number) {
    this.phoneNumberInput.emit(number);
  }

  login() {
    this.loginApp.emit();
  }

  verify() {
    this.verifyApp.emit(this.otp);
  }

  onChange() {
    this.onInputChange.emit(this.otp);
  }

  sendOtp() {
    this.onSendOTP.emit();
  }

}
