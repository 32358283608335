<div class="doc-carousel-wrapper">
  <div *ngIf="files">
    <ngb-carousel #carousel [interval]="1000000" *ngIf="files.length" (slide)="changedIndex($event)" [showNavigationIndicators]="false" [showNavigationArrows]="false">
      <ng-container *ngFor="let file of files; let i = index">
        <ng-template ngbSlide [id]="'slideId_' + i">
          <app-file-carousel-item [file]="file"></app-file-carousel-item>
        </ng-template>
      </ng-container>
    </ngb-carousel>
    <div class="arrow left" (click)="moveToPrev()">
        <span class="material-icons">
          keyboard_arrow_left
        </span>
    </div>
    <div class="arrow right" (click)="moveToNext()">
        <span class="material-icons">
          keyboard_arrow_right
        </span>
    </div>
  </div>
</div>

