import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPanelComponent } from './info-panel/info-panel.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserApiService } from '../services/api/user.api.service';
import { TableComponent } from './table/table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { InviteComponent } from './invite/invite.component';
import { CsvUploadComponent } from './csv-upload/csv-upload.component';
import { MaterialModule } from '../material/material.module';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { CardsComponent } from './cards/cards.component';
import { DocUploadComponent } from './doc-upload/doc-upload.component';
import { PostDetailComponent } from './post-detail/post-detail.component';
import { ViewAttachmentComponent } from './view-attachment/view-attachment.component';
import { ZoomImageComponent } from './zoom-image/zoom-image.component';
import { ShikshaInputComponent } from './shiksha-input/shiksha-input.component';
import { TestEvaluateComponent } from './test-evaluate/test-evaluate.component';
import { ViewDocsComponent } from './view-docs/view-docs.component';
import { DocCaraouselComponent } from './doc-caraousel/doc-caraousel.component';
import { GalleryModule } from 'ng-gallery';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { FileCarouselItemComponent } from './file-carousel-item/file-carousel-item.component';
import { PipesModule } from '../pipes/pipes.module';
import { LibraryTableComponent } from './library-table/library-table.component';
import { UserAlertComponent } from './user-alert/user-alert.component';
import { CreateFolderComponent } from './create-folder/create-folder.component';
import { FolderDirectoryComponent } from './folder-directory/folder-directory.component';
import { InsertFileDialogComponent } from './insert-file-dialog/insert-file-dialog.component';
import { SharedLoginComponent } from './shared-login/shared-login.component';
import { TestSourceAlertComponent } from './test-source-alert/test-source-alert.component';
import { LiveClassTableComponent } from './live-class-table/live-class-table.component';
import { LiveClassScheduleComponent } from './live-class/live-class.component';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { ChatComponent } from './chat/chat.component';
import { ParticipantComponent } from './participant/participant.component';
import { JoiningTimePipe } from '../teachers/live-class/joining-time.pipe';
import { StreamDialogComponent } from './stream-dialog/stream-dialog.component';
import { RenameLabelComponent } from './rename-label/rename-label.component';
import { RejoinDialogComponent } from './rejoin-dialog/rejoin-dialog.component';
import { CourseCategoriesSettingComponent } from './course-categories-setting/course-categories-setting.component';
import { CourseFormComponent } from './course-form/course-form.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { CourseActivitiesTableComponent } from './course-activities-table/course-activities-table.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { LibraryContentIconComponent } from './library-content-icon/library-content-icon.component';
import { CourseCopyDialogComponent } from './course-copy-dialog/course-copy-dialog.component';


@NgModule({
  declarations: [
    InfoPanelComponent,
    TableComponent,
    InviteComponent,
    CsvUploadComponent,
    CustomSnackbarComponent,
    DeleteDialogComponent,
    CardsComponent,
    DocUploadComponent,
    PostDetailComponent,
    ViewAttachmentComponent,
    ZoomImageComponent,
    ShikshaInputComponent,
    TestEvaluateComponent,
    ViewDocsComponent,
    DocCaraouselComponent,
    DocCaraouselComponent,
    FileCarouselItemComponent,
    LibraryTableComponent,
    UserAlertComponent,
    CreateFolderComponent,
    FolderDirectoryComponent,
    InsertFileDialogComponent,
    SharedLoginComponent,
    TestSourceAlertComponent,
    LiveClassTableComponent,
    LiveClassScheduleComponent,
    LiveChatComponent,
    ChatComponent,
    ParticipantComponent,
    ChatComponent,
    JoiningTimePipe,
    StreamDialogComponent,
    RenameLabelComponent,
    RejoinDialogComponent,
    CourseCategoriesSettingComponent,
    CourseFormComponent,
    CourseDetailComponent,
    CourseActivitiesTableComponent,
    LibraryContentIconComponent,
    CourseCopyDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    MaterialModule,
    GalleryModule,
    NgbCarouselModule,
    PipesModule,
    SharedComponentsModule
  ],
  exports: [
    InfoPanelComponent,
    TableComponent,
    ShikshaInputComponent,
    CardsComponent,
    DocUploadComponent,
    PostDetailComponent,
    ViewAttachmentComponent,
    DocCaraouselComponent,
    LibraryTableComponent,
    SharedLoginComponent,
    LiveClassTableComponent,
    LiveClassScheduleComponent,
    ParticipantComponent,
    ChatComponent,
    JoiningTimePipe,
    RejoinDialogComponent
  ],
  providers: [UserApiService]
})
export class SharedModule { }
