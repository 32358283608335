<div class="admin-theme detail-wrapper">
    <div class="header" *ngIf="selectedSchool">
        <div class="left">
            <div (click)="back()" class="close"> <img src="../../../assets/grey-back.svg" /></div>
            <div class="txt">{{selectedSchool.name}}</div>
        </div>

        <div class="action" [ngClass]="{'disabled': role === 'member'  || (selectedSchool && selectedSchool.status === 'archived')}">
            <button mat-icon-button [disabled]="role === 'member' || (selectedSchool && selectedSchool.status === 'archived')" (click)="select.open()">
        <mat-label>ACTIONS</mat-label>
        <mat-select [disabled]="role === 'member' || (selectedSchool && selectedSchool.status === 'archived')" #select
          [formControl]="toppings">
          <mat-option *ngFor="let topping of toppingList"
            [disabled]="(role === 'member' || role === 'admin') && topping === 'Invite School Staff'" [value]="topping">
            {{actionLabel[topping]}}</mat-option>
        </mat-select>
      </button>
        </div>
    </div>

    <div class="tabs" *ngIf="selectedSchool">
        <mat-tab-group (selectedTabChange)="tabSelect($event)">
            <mat-tab *ngFor="let tab of tabs">
                <ng-template mat-tab-label *ngIf="tab.label !== 'icon'">
                    {{tab.label == 'Teachers' ? teachersLabel : (tab.label == 'Staff' ? staffLabel : (tab.label == 'Students' ? studentsLabel : tab.label) )}}
                </ng-template>

                <ng-container *ngIf="tab.label.toLowerCase() === 'overview'">
                    <ng-container *ngIf="!overviewDashboardLoaded">
                        <app-spinner></app-spinner>
                        <!-- <app-analytics [teachersLabel]="teachersLabel" [staffLabel]="staffLabel" [studentsLabel]="studentsLabel"></app-analytics> -->
                    </ng-container>
                    <iframe #overview [ngClass]="overviewDashboardLoaded ? '' : 'hidden'" class="overview-iframe"></iframe>
                </ng-container>

                <ng-container *ngIf="tab.label.toLowerCase() === 'teachers'">
                    <ng-container *ngIf="lists1; else spinner">
                        <app-table [transactions]="lists1" [displayedColumns]="displayedColumns1" [sortDirection]="sortDirection" (getSortDirection)="onSortChange($event)" [type]="'teacher'"></app-table>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="tab.label.toLowerCase() === 'staff'">
                    <ng-container *ngIf="lists2; else spinner">
                        <app-table [transactions]="lists2" [type]="'staff'" [sortDirection]="sortDirection" (getSortDirection)="onSortChange($event)" [displayedColumns]="displayedColumns2"></app-table>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="tab.label.toLowerCase() === 'students'">
                    <ng-container *ngIf="lists3; else spinner">
                        <app-admin-table [transactions]="lists3" [displayedColumns]="displayedColumns3"></app-admin-table>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="tab.label === coursesLabel">
                    <ng-container *ngIf="courses; else spinner">
                        <app-table [transactions]="courses" [type]="'courses'" [sortDirection]="sortDirection" (getSortDirection)="onSortChange($event)" [displayedColumns]="courseColumns" (navigateToCourse)="navigateToCourse($event)" (OnCourseStatusChange)="changeCourseStatus($event)"
                            (OnEditCourse)="editCourse($event)"></app-table>
                    </ng-container>
                </ng-container>

                <!-- <ng-container *ngIf="tab.label.toLowerCase() !== 'teachers' && tab.label.toLowerCase() !== 'overview' && tab.label.toLowerCase() !== 'staff' && tab.label.toLowerCase() !== 'settings' && tab.label.toLowerCase() !== 'students'">
                    {{tab.label}} works!! but not yet implemented :(
                </ng-container> -->

                <ng-container *ngIf="tab.label === 'Settings'">
                    <app-common-settings [selectedSchool]="selectedSchool"></app-common-settings>
                </ng-container>

            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<ng-template #spinner>
    <app-spinner></app-spinner>
</ng-template>
