<ng-container *ngIf="actionType === publishAction else others">
    <div class="wrapper">
        <div class="text">Are you sure you want to publish this {{contentType}} ?
            <br/>
            <span>**WARNING: course contents and content order can not be modified after publishing</span>
        </div>

        <div class="btn">
            <div><button mat-flat-button (click)="closeDialog('cancel')" [ngClass]=theme>CANCEL</button></div>
            <div><button mat-flat-button (click)="closeDialog('delete')" [ngClass]="getClass()">{{actionType | uppercase}}</button></div>
        </div>
    </div>
</ng-container>
<ng-template #others>
    <ng-container *ngIf="!toUpload; else upload">
        <div class="wrapper">
            <div class="text">Are you sure you want to delete this content?</div>

            <div class="btn">
                <div><button mat-flat-button (click)="closeDialog('cancel')" [ngClass]=theme>CANCEL</button></div>
                <div><button mat-flat-button (click)="closeDialog('delete')" [ngClass]="getClass()">DELETE</button></div>
            </div>
        </div>
    </ng-container>

    <ng-template #upload>
        <div class="wrapper">
            <div class="text">{{alertText}}</div>

            <div class="btn">
                <div><button mat-flat-button (click)="closeDialog('cancel')" [ngClass]=theme>CANCEL</button></div>
                <div><button mat-flat-button (click)="closeDialog('delete')" [ngClass]="getClass()">{{actionType}}</button></div>
            </div>
        </div>
    </ng-template>

</ng-template>