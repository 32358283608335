import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { dateFormat, ACTIVITY_TYPES } from 'src/app/Constants/Constant';
import { ChildPostDetailModel } from 'src/app/models/child-post-detail.models';
import { EvaluatedAssignmentModel, SubmittedAssingmentModel } from 'src/app/models/evaluated-assignment-models';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { ViewDocsComponent } from 'src/app/shared/view-docs/view-docs.component';
import { ClassPostDashboardService } from '../services/class-post-dashboard.service';
import { StudentCourseActivitiesService } from '../services/student-course-activities.service';

export const ASSIGNMENT = 'assignment';
export const PENDING = 'pending';
export const DONE = 'done';
export const MATERIAL = 'material';

export interface EvaluatedMetaModel {
  marks: number,
  totalMarks: number,
  remarks: string
}
@Component({
  selector: 'app-class-post',
  templateUrl: './class-post.component.html',
  styleUrls: ['./class-post.component.scss']
})

export class ClassPostComponent implements OnInit, OnDestroy {
  docUrl: any;
  isdocUploaded: boolean;
  text: 'assignment' | 'material';
  post: ChildPostDetailModel;
  isLoading: boolean = true;
  files: string[];
  studentID: string;
  postID: string;
  isPostSubmitted: boolean = false;
  isPostEvaluated: boolean = false;
  evaluatedAssignment: EvaluatedAssignmentModel;
  submittedAssingment: SubmittedAssingmentModel;
  formatDate: string;
  urls: string[]
  urls1: string[] = [];
  evaluatedMetaData: EvaluatedMetaModel[] = [];

  public isCourseActivity = false;
  public courseActivityId: string;
  public courseActivity: any;
  public isActivitySubmitted = false;
  public isActivityEvaluated = false;
  public submittedCourseActivity: any;
  public evaluatedActivityMetaData: any[] = [];
  public evaluatedCourseActivity: any;

  constructor(
    public sidenav: ToggleSidenavService,
    public router: Router,
    public dialog: MatDialog,
    public snackbar: SnackbarService,
    public localstorage: LocalStorageService,
    public postService: ClassPostDashboardService,
    private courseActivitiesSvc: StudentCourseActivitiesService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.formatDate = dateFormat;
    this.sidenav.minmizeSidenav();
    const url = this.router.url.split('/');
    this.isCourseActivity = url.includes('course-activity');
    this.studentID = this.localstorage.getItem('studentID');

    if (this.isCourseActivity) {
      this.route.params.subscribe((param) => {
        if (param.name) {
          this.text = param.name === ACTIVITY_TYPES.assignments ? 'assignment' : 'material';
          this.courseActivityId = url[url.length - 1];
          this.getCourseActivity();
        }
      });
    } else {
      this.postID = url[url.length - 1];
      this.router.url.split('/').includes('assignments') ? this.getAssignments() : this.getMaterials();
    }
  }

  getAssignments() {
    this.text = ASSIGNMENT;
    this.postService.getAssignmentById(this.postID, this.studentID).subscribe(
      (res) => {
        this.post = res.assignment;
        this.post.assignmentStatus = res.assignmentStatus;
        this.urls1 = res.assignmentURLs;
        this.isPostEvaluated = res.assignmentStatus === DONE ? true : false;
        this.isPostSubmitted = res.assignmentStatus === PENDING ? false : true;
        if (this.isPostEvaluated && res.assignmentStatus !== PENDING) {
          this.getEvaluatedAssignment();
          return;
        }
        if (res.assignmentStatus !== PENDING && this.isPostSubmitted) {
          this.getSubmittedAssignment();
          return;
        }
        this.isLoading = false;
      },
      (err) => this.isLoading = false
    );
  }

  getMaterials() {
    this.text = MATERIAL;
    this.postService.getMaterialById(this.postID, this.studentID).subscribe(
      (res: any) => {
        this.post = res.material;
        this.urls1 = res.signedURLs;
        this.post.materialStatus = res.materialStatus;
        this.notifyMaterialStatus(res);
      },
      (err) => {
        this.isLoading = false;
        this.snackbar.openSnackbar(err.error.error);
      },
      () => this.isLoading = false
    )
  }

  notifyMaterialStatus(postDetail) {
    if (this.post.materialStatus === PENDING) {
      const requestBody = {
        materialID: this.post.id,
        studentID: this.studentID
      }
      this.postService.notifyForMaterialRead(requestBody).subscribe(
        (res) => {
          console.log(res)
        },
        (err) => {
          this.snackbar.openSnackbar(err.error.error);
        })
    } else {
      return;
    }

  }

  getSubmittedAssignment() {
    this.postService.getSubmission(this.postID, this.studentID).subscribe(
      (res) => {
        this.urls = res.submissionURLs;
        this.isLoading = false;
        this.submittedAssingment = res.submittedAssignment;
      },
      (err) => {
        this.isLoading = false;
        this.snackbar.openSnackbar('Unable to fetch the submitted assingment details!')
      }
    )
  }

  getEvaluatedAssignment() {
    this.postService.assignmentStatus(this.studentID, this.postID).subscribe(
      (res) => {
        this.urls = res.evalURLs;
        this.isLoading = false;
        this.evaluatedAssignment = res.evaluatedAssignment;
        this.evaluatedMetaData.push({
          marks: res.evaluatedAssignment.marks,
          totalMarks: res.evaluatedAssignment.totalMarks, remarks: res.evaluatedAssignment.remarks
        });
      },
      (err) => {
        this.isLoading = false;
        this.snackbar.openSnackbar('Unable to fetch the evaluated assingment details!')
      }
    )
  }

  onDocUpload(urlList) {
    this.docUrl = urlList.files;
    this.isdocUploaded = (urlList.files && urlList.files.length > 0) ? true : false;
  }

  previewPost(event) {
    let fileArray = [];
    this.post.files.map((file, index) => fileArray.push({ name: file, signedUrl: this.urls1[index] }));

    const dialog = this.dialog.open(ViewDocsComponent, {
      width: '95%',
      height: '100%',
      data: { files: fileArray, type: this.text.toLowerCase() },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['student-theme', 'full-width',  'preview-doc-container'],
      autoFocus: false
    });
  }

  previewActivityEvaluation() {
    let fileArray = [];
    this.evaluatedCourseActivity.files.map((file, index) => fileArray.push({ name: file, signedUrl: this.urls[index] }));
    const dialog = this.dialog.open(ViewDocsComponent, {
      width: '95%',
      height: '100%',
      data: { files: fileArray, type: this.text.toLowerCase() },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['student-theme', 'full-width', 'preview-doc-container'],
      autoFocus: false
    });
  }

  previewClassPostEvaluation() {
    let fileArray = [];
    this.evaluatedAssignment.evalFiles.map((file, index) => fileArray.push({ name: file, signedUrl: this.urls[index] }));
    const dialog = this.dialog.open(ViewDocsComponent, {
      width: '95%',
      height: '100%',
      data: { files: fileArray, type: this.text.toLowerCase() },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['student-theme', 'full-width', 'preview-doc-container'],
      autoFocus: false
    });
  }

  submit() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '363px',
      height: '190px',
      backdropClass: 'blur',
      data: { theme: 'student', isUpload: true },
      panelClass: ['delete-dialog-css'],
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.isLoading = true;
        const requestBody = {
          assignmentID: this.postID,
          studentID: this.studentID,
          uploadedFiles: this.docUrl,
        }
        this.postService.submitAssignment(requestBody).subscribe(
          (res) => {
            this.snackbar.openSnackbar('assignments are successfully uploaded');
            this.getAssignments();
          },
          (err) => {
            this.snackbar.openSnackbar(err.error.error);
            this.isLoading = false;
          }
        );
      }
    });
  }

  private getCourseActivity() {
    this.courseActivitiesSvc.isLoading$.subscribe((isLoading) => this.isLoading = isLoading);
    this.courseActivitiesSvc.getCourseActivity(this.courseActivityId, this.studentID);
    this.courseActivitiesSvc.selectedCourseActivity$.subscribe((courseActivityDetails) => {
      if (courseActivityDetails) {
        if (this.text === MATERIAL) {
          this.markCourseActivityMaterialAsRead();
        }
        this.courseActivity = courseActivityDetails.activity;
        this.courseActivity.assignmentStatus = courseActivityDetails.status;
        this.courseActivity.materialStatus = courseActivityDetails.status;
        this.urls1 = courseActivityDetails.activityURLs;
        this.isActivitySubmitted = this.courseActivity.assignmentStatus === PENDING ? false : true;
        this.isActivityEvaluated = false;
        if (this.isActivitySubmitted) {
          this.submittedCourseActivity = courseActivityDetails.submission;
          if (this.submittedCourseActivity.evalStatus === DONE) {
            this.getEvaluatedActivity();
          } else {
            this.isActivityEvaluated = false;
            this.urls = courseActivityDetails.submissionURLs;
          }
        }
      }
    });
  }

  private getEvaluatedActivity() {
    this.courseActivitiesSvc.getEvaluatedCourseActivity(this.courseActivityId, this.studentID);
    this.courseActivitiesSvc.evaluatedCourseActivity$.subscribe((evaluationDetails) => {
      if (evaluationDetails) {
        this.isActivityEvaluated = true;
        this.evaluatedCourseActivity = evaluationDetails.evaluation;
        this.evaluatedActivityMetaData.push({
          marks: this.evaluatedCourseActivity.marksObtained,
          totalMarks: this.evaluatedCourseActivity.totalMarks,
          remarks: this.evaluatedCourseActivity.remarks
        });
        this.urls = evaluationDetails.evalURLs;
      }
    });
  }
  public previewCourseActivity(event) {
    const fileArray = [];
    this.courseActivity.files.map((file, index) => fileArray.push({ name: file, signedUrl: this.urls1[index] }));
    this.dialog.open(ViewDocsComponent, {
      width: '95%',
      height: '100%',
      data: { files: fileArray, type: this.text.toLowerCase() },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['student-theme', 'full-width', 'preview-doc-container'],
      autoFocus: false
    });
  }

  public markCourseActivityMaterialAsRead() {
    this.isLoading = true;
    const requestBody = {
      activityID: this.courseActivityId,
      studentID: this.studentID,
    }
    this.courseActivitiesSvc.submitCourseActivity(requestBody).subscribe(
      (res) => {
        this.isLoading = false;
      },
      (err) => {
        this.snackbar.openSnackbar(err.error.error);
        this.isLoading = false;
      }
    );
  }

  public submitCourseActivity() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '363px',
      height: '190px',
      backdropClass: 'blur',
      data: { theme: 'student', isUpload: true },
      panelClass: ['delete-dialog-css'],
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.isLoading = true;
        const requestBody = {
          activityID: this.courseActivityId,
          studentID: this.studentID,
          uploadedFiles: this.docUrl,
        }
        this.courseActivitiesSvc.submitCourseActivity(requestBody).subscribe(
          (res) => {
            this.snackbar.openSnackbar('successfully submitted the assignment');
            this.getCourseActivity();
          },
          (err) => {
            this.snackbar.openSnackbar(err.error.error);
            this.isLoading = false;
          }
        );
      }
    });
  }

  ngOnDestroy() {
    this.courseActivitiesSvc.reset();
  }
}
