import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CrudSettingsComponent } from '../crud-settings/crud-settings.component';
import { Location } from '@angular/common';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Subject } from 'rxjs';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { LabelService } from 'src/app/services/label.service';

@Component({
  selector: 'app-settings-detail',
  templateUrl: './settings-detail.component.html',
  styleUrls: ['./settings-detail.component.scss']
})
export class SettingsDetailComponent implements OnInit, OnDestroy {

  details: any = [];
  haveDetail: boolean = false;
  private destroy$$: Subject<any> = new Subject<any>();
  labelData;
  title: string;
  isLoading: boolean;
  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _location: Location,
    public settingService: SettingsService,
    public sidenavService: ToggleSidenavService,
    public _snackbar: SnackbarService,
    private labelService: LabelService
  ) {
  }

  ngOnInit(): void {
    this.sidenavService.minmizeSidenav();
    this.labelService.labels$.pipe(takeUntil(this.destroy$$)).subscribe(data => this.labelData = data)
    this.title = this.route.snapshot.paramMap.get('title');
    this.title = this.title !== 'session' ? this.title.substring(0, this.title.length - 1) : this.title;
    this.storeSettingDetails(this.title);
    if (this.title !== 'session') {
      this.getSettings();
    } else if (this.title === 'session') {
      this.getSessions();
    }
    this.settingService.isLoading$.subscribe(value => this.isLoading = value)
  }

  storeSettingDetails(setting) {
    if (setting === 'standard') {
      this.settingService.getStandards();
    } else if (setting === 'subject') {
      this.settingService.getSubjects();
    } else if (setting === 'section') {
      this.settingService.getSections();
    } else {
      this.settingService.getSessions();
    }
  }

  getSettings() {
    let list$;
    if (this.title === 'standard') {
      list$ = this.settingService.standardList$.pipe(
        takeUntil(this.destroy$$)
      );
    } else if (this.title === 'subject') {
      list$ = this.settingService.subjectList$.pipe(
        takeUntil(this.destroy$$)
      );
    } else if (this.title === 'section') {
      list$ = this.settingService.sectionList$.pipe(
        takeUntil(this.destroy$$)
      );
    }
    list$.subscribe(list => {
      this.haveDetail = list.length === 0 ? false : true;
      this.details = list
    })
  }

  getSessions() {
    this.settingService.sessionList$.pipe(
      takeUntil(this.destroy$$)
    )
      .subscribe(list => {
        this.haveDetail = list.length === 0 ? false : true;
        this.details = list
      })
  }

  add() {
    const dialogRef = this.dialog.open(CrudSettingsComponent, {
      data: {
        title: this.title,
        type: 'add'
      },
      width: '46%',
      height: '100%',
      backdropClass: 'blur',
      disableClose: true,
      panelClass: 'admin-theme',
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe(data => {
      if (data.starting) {
        data['name'] = data.starting.concat(" - ", data.ending)
      }
      this.details.push(data);
      this.haveDetail = true;
    })
  }

  editDetail(detail) {
    const dialogRef = this.dialog.open(CrudSettingsComponent, {
      data: {
        title: this.title,
        name: detail.name,
        type: 'edit',
        settingId: detail.id
      },
      width: '46%',
      height: '100%',
      backdropClass: 'blur',
      disableClose: true,
      panelClass: 'admin-theme',
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe(data => {
      if (data.starting) {
        data['name'] = data.starting.concat(" - ", data.ending)
      }
      this.details.filter(setting => {
        if (setting.id === detail.id) {
          setting.name = data.name;
        }
      })
      this.haveDetail = true;
    })
  }

  back() {
    this.settingService.clearList();
    this._location.back();
  }

  delete(detail) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '363px',
      height: '190px',
      backdropClass: 'blur',
      data: { theme: 'admin' },
      panelClass: ['delete-dialog-css'],
      autoFocus: false
    })

    dialogRef.afterClosed().subscribe((isDeleted: boolean) => {
      if (!isDeleted)
        return
      else {
        let delSetting;
        const requestBody = {
          status: "inactive"
        }
        if (this.title === 'standard') {
          delSetting = this.settingService.deleteStandard(detail.id, requestBody)
        } else if (this.title === 'subject') {
          delSetting = this.settingService.deleteSubject(detail.id, requestBody)
        } else if (this.title === 'section') {
          delSetting = this.settingService.deleteSection(detail.id, requestBody)
        }

        if (this.title !== 'session') {
          delSetting.pipe(
            takeUntil(this.destroy$$)
          )
            .subscribe(
              (res) => {
                this.details = this.details.map(list => {
                  list.status = (list.id === detail.id) ? 'inactive' : list.status;
                  return list
                });
                this._snackbar.openSnackbar(`${this.title} deleted succesfully`);
              },
              (err) => {
                this._snackbar.openSnackbar(err.error.error);
              }
            )
        } else if (this.title === 'session') {
          this.settingService.deleteSession(detail.id, requestBody).pipe(
            takeUntil(this.destroy$$)
          )
            .subscribe(
              (res) => {
                this.details = this.details.map(list => {
                  list.status = (list.id === detail.id) ? 'inactive' : list.status;
                  return list
                });
                this._snackbar.openSnackbar('session deleted succesfully');
              },
              (err) => {
                this._snackbar.openSnackbar(err.error.error);
              }
            )
        }
      }
    })
  }

  ngOnDestroy() {
    this.settingService.clearList();
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }

}
