import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { dateFormat } from 'src/app/Constants/Constant';
@Component({
  selector: 'app-course-activities-table',
  templateUrl: './course-activities-table.component.html',
  styleUrls: ['./course-activities-table.component.scss']
})
export class CourseActivitiesTableComponent implements OnInit {


  @Input() dataSource;
  @Input() displayedColumns: string[];
  @Input() courseID: string;
  @Input() reordering: string;
  @Input() draggable: boolean;
  @Input() disableActions: boolean;
  @Output() OnEditCourseActivity = new EventEmitter();
  @Output() OnPositionChange = new EventEmitter();
  @Output() navigateToActivity = new EventEmitter();
  public canDrop = true;
  public dateFormat;

  constructor(
  ) { }



  ngOnInit(): void {
    this.dateFormat = dateFormat;
  }

  drop(event) {
    if (event.currentIndex !== event.previousIndex) {
      this.OnPositionChange.emit({ prevIndex: event.previousIndex, newIndex: event.currentIndex});
    }
  }

  dragStarted(event) {
    event.stopPropagation();
  }

  navigateToView(row) {

  }

  editCourseActivity(courseActivity) {
    this.OnEditCourseActivity.emit(courseActivity);
  }

  gotoActivity(courseActivity) {
    this.navigateToActivity.emit(courseActivity);
  }
}
