import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChooseAccountComponent } from './choose-account/choose-account.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { MessagesComponent } from './messages/messages.component';
import { ActivityComponent } from './activity/activity.component';
import { ChildDashboardModule } from './child-dashboard/child-dashboard.module';
import { AuthGuard } from '../services/auth.guard';
// import { ProfileComponent } from '../shared/profile/profile.component';
import { StudentLiveClassComponent } from './student-live-class/student-live-class.component';
import { LoginModule } from '../login/login.module';


const routes: Routes = [
  { path: 'login', loadChildren: () =>  LoginModule },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  // { path: 'choose', component: ChooseAccountComponent, canActivate: [AuthGuard] },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
    children: [
      // { path: 'home', component: HomeComponent, pathMatch: 'full' },
      // { path: 'messages', component: MessagesComponent, pathMatch: 'full' },
      // { path: 'activity', component: ActivityComponent, pathMatch: 'full' },
      // { path: 'profile', component: ProfileComponent, pathMatch: 'full' },
      { path: '', component: HomeComponent, pathMatch: 'full' },
      // { path: 'live-class/:id', component: StudentLiveClassComponent },
    ]
  },
  // { path: 'child/:id', loadChildren: () => ChildDashboardModule, canActivate: [AuthGuard] }
  { path: ':id', loadChildren: () => ChildDashboardModule, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudentsRoutingModule { }
