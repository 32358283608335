import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
import { dateFormat, pageSizeOptions, paginatorPageSize } from 'src/app/Constants/Constant';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { ClassPostDashboardService } from '../services/class-post-dashboard.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ChildPostModel } from 'src/app/models/child-class-post.models';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LiveClassService } from '../../services/live-class.service';
import { zip } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared-components/alert-dialog/alert-dialog.component';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';

@Component({
  selector: 'app-class-detail',
  templateUrl: './class-detail.component.html',
  styleUrls: ['./class-detail.component.scss']
})
export class ClassDetailComponent implements OnInit, AfterViewInit {
  isLoading = true;
  name: string;
  pageSizeOptions: number[] = pageSizeOptions;
  pageSize = paginatorPageSize;
  transactions: ChildPostModel[];

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  selection = new SelectionModel(true, []);
  dataSource: any;
  sortedData: any;
  displayedColumns = ['date', 'activity', 'title', 'status', 'evaluation', 'totalmarks', 'marks'];
  liveClassColumn = ['startsAt', 'endsAt', 'duration', 'title', 'standard', 'action'];
  formatDate: string;
  liveClassData = [];
  studentID: string;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public sidenav: ToggleSidenavService,
    public postService: ClassPostDashboardService,
    public cd: ChangeDetectorRef,
    public snackbar: SnackbarService,
    public localstorage: LocalStorageService,
    public liveClassService: LiveClassService,
    private dialogRef: MatDialog
  ) { }

  ngOnInit(): void {
    this.sidenav.minmizeSidenav();
    this.formatDate = dateFormat;
  }

  ngAfterViewInit() {
    this.studentID = this.localstorage.getItem('studentID');
    const classID = this.route.snapshot.paramMap.get('id');

    this.postService.setStudentID(this.studentID);
    const liveClassDetails = this.liveClassService.liveClass(this.studentID, classID);
    const postDetails = this.postService.getPosts(this.studentID, classID);

    this.postService.getPosts(this.studentID, classID).subscribe(
      (res: ChildPostModel[]) => {
        this.displayedColumns = ['date', 'activity', 'title', 'status', 'evaluation', 'totalmarks', 'marks'];
        this.transactions = res;
        this.name = res.length > 0 ? res[0].class : null;
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.transactions);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.cd.detectChanges();
      },
      (err) => {
        this.isLoading = false;
        if (err.error.error.includes('Access restricted')) {
          const dialogRef = this.dialogRef.open(AlertDialogComponent, {
            width: '363px',
            disableClose: true,
            height: '190px',
            backdropClass: 'blur',
            data: { alertText: 'Access Denied', type: 'accessdenied', studentID: this.studentID },
            panelClass: ['delete-dialog-css'],
            autoFocus: false
          });
        }
        else {
          this.snackbar.openSnackbar(err.error.error);
        }
      },
      () => this.isLoading = false
    );

    zip(liveClassDetails, postDetails).subscribe(
      (response) => {
        this.transactions = response[1];
        this.liveClassData = response[0].schedule;
        this.name = response[1].length > 0 ? response[1][0].class : null;
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.transactions);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.cd.detectChanges();
      },
      (err) => {
        this.isLoading = false;
        if (err.error.error.includes('Access restricted')) {
          const dialogRef = this.dialogRef.open(AlertDialogComponent, {
            width: '363px',
            disableClose: true,
            height: '170px',
            backdropClass: 'blur',
            data: { alertText: 'Access Denied', type: 'accessdenied', theme: USER_TYPES.student, studentID: this.studentID },
            panelClass: ['delete-dialog-css'],
            autoFocus: false
          });
          dialogRef.afterClosed().subscribe(() => {
            this.router.navigate(['../../', 'home'], { relativeTo: this.route });
          });
        } else {
          this.snackbar.openSnackbar(err.error.error);
        }
      }, () => this.isLoading = false
    );
  }

  getClass(row: ChildPostModel) {
    return row.submissionStatus === 'pending' ? 'pending' : '';
  }

  getEvalClass(row: ChildPostModel) {
    if (row.evalStatus === 'pending') {
      return 'pending';
    } else if (row.evalStatus === 'not reviewed') {
      return 'unreviewed';
    } else {
      return 'done';
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource && this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }
  sortData(sort: Sort) {
    const data = this.transactions.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'desc';
      return this.compare(a.date, b.date, isAsc);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sendMsg() {
    console.log(this.dataSource);
  }

  sendActivity(row) {
    if (row.activityType.toLowerCase() === 'tests') {
      if (row.submissionStatus === 'submitted') {
        this.router.navigate(['../../', 'post', 'tests', row.activityID, 'result'], { relativeTo: this.route });
      } else {
        this.router.navigate(['../../', 'post', 'tests', row.activityID], { relativeTo: this.route });
      }
    } else {
      this.router.navigate(['../../', 'post', row.activityType, row.activityID], { relativeTo: this.route });
    }
  }

  back() {
    this.sidenav.maximizeSidenav();
    this.router.navigate(['../../', 'home'], { relativeTo: this.route });
  }

}
