import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolService } from 'src/app/services/api/school.service';
import { LabelService } from 'src/app/services/label.service';

@Component({
  selector: 'app-rename-label',
  templateUrl: './rename-label.component.html',
  styleUrls: ['./rename-label.component.scss']
})
export class RenameLabelComponent implements OnInit {
  labelValues = {
      'school' : 'School',
      'schools' : 'Schools',
      'standard' : 'Standard',
      'standards' : 'Standards',
      'section' : 'Section',
      'sections' : 'Sections',
      'session' : 'Session',
      'sessions' : 'Sessions',
      'parent' : 'Parent',
      'addstudent' : 'Add Student',
      'gotoclasses' : 'Go to Classes',
      'children' : 'Children',
      'myclass' : 'My Class',
      'joinliveclass' : 'Join Live Class',
      'liveclasstoday' : 'Live Class Today',
      'liveclasstitle' : 'Live Class Title',
      'allschools' : 'All Schools',
      'createschool' : 'Create School',
      'student' : 'Student',
      'students' : 'Students',
      'teacher' : 'Teacher',
      'teachers' : 'Teachers',
      'staff' : 'Staff',
      'subject' : 'Subject',
      'subjects' : 'Subjects',
  }
  labelForm: FormGroup;
  constructor(public location: Location, public labelService: LabelService, public router: Router, private _schoolService: SchoolService) { }

  ngOnInit(): void { 
    this.labelForm = new FormGroup({
      'school': new FormControl(''),
      'schools': new FormControl(''),
      'standard': new FormControl(''),
      'standards': new FormControl(''),
      'section': new FormControl(''),
      'sections': new FormControl(''),
      'session': new FormControl(''),
      'sessions': new FormControl(''),
      'parent': new FormControl(''),
      'addstudent': new FormControl(''),
      'gotoclasses': new FormControl(''),
      'children': new FormControl(''),
      'myclass': new FormControl(''),
      'joinliveclass': new FormControl(''),
      'liveclasstoday': new FormControl(''),
      'liveclasstitle': new FormControl(''),
      'allschools': new FormControl(''),
      'createschool': new FormControl(''),
      'student': new FormControl(''),
      'students': new FormControl(''),
      'teacher': new FormControl(''),
      'teachers': new FormControl(''),
      'staff': new FormControl(''),
      'subject': new FormControl(''),
      'subjects': new FormControl(''),
      'schoolcreator': new FormControl(''),
      'schooladmin': new FormControl(''),
      'schoolmember': new FormControl(''),
      'inviteteachers': new FormControl(''),
      'inviteschoolstaff': new FormControl(''),
      'invitestudents': new FormControl(''),
      'invitecoteachers': new FormControl(''),
      'coteachers': new FormControl(''),
      'liveclass': new FormControl(''),
      'createliveclass': new FormControl(''),
      'class': new FormControl(''),
      'classes': new FormControl(''),
      'createclass': new FormControl(''),
      'liveclassschedule': new FormControl(''),
      'selectschool': new FormControl(''),
      'classname': new FormControl(''),
      'selectstandard': new FormControl(''),
      'selectsection': new FormControl(''),
      'selectsession': new FormControl(''),
      'selectsubject': new FormControl(''),
      'pleaseselectschool': new FormControl(''),
      'pleaseselectstandard': new FormControl(''),
      'pleaseselectsection': new FormControl(''),
      'pleaseselectsession': new FormControl(''),
      'pleaseselectsubject': new FormControl(''),
      'createanewclass': new FormControl(''),
      'createanewschool': new FormControl(''),
      'scheduleliveclass': new FormControl(''),
      'pleaseselectclass': new FormControl(''),
      'recurringclass': new FormControl(''),
      'allclasses': new FormControl(''),
      'allsubjects': new FormControl(''),
      'allstandards': new FormControl(''),
      'addstandard': new FormControl(''),
      'addsection': new FormControl(''),
      'addsubject': new FormControl(''),
      'addsession': new FormControl(''),
    })
    this.setLabels();
  }
  setLabels() {
    this.labelService.labels$.subscribe(data => {
      Object.keys(this.labelForm.value).forEach(key => {
        this.labelForm.controls[key].patchValue(data[key])
      })
    })
  }
  back() {
    this.location.back();
  }
  clearAll() {
    this.labelForm.reset();
  }
  save() {
    let c = Object.keys(this.labelForm.value).filter(k => {
      return this.labelForm.value[k] &&  this.labelForm.value[k]
    }).reduce((obj,k) => {
      return {
        ...obj,
        [k]: this.labelForm.value[k]
      }
    },{})
    if(Object.keys(c).length > 0) {
      let stringifyData = JSON.stringify(c);
      console.log(stringifyData)
      if(stringifyData){
        this.labelService.setLabels(stringifyData).subscribe(data => {
          this.labelService.getLabels();
          this._schoolService.clearSchool();
          this._schoolService.getSchools();
          this.router.navigate(['school','dashboard','settings'])
        })
      }
    }
  }
}
