<div *ngIf="userType === userTypes.admin">
  <svg *ngIf="isFolder" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fill-rule="evenodd">
          <g stroke="#607d8b">
              <g>
                  <g>
                      <path d="M9.477 4.546c.287 0 .561.082.796.228.234.147.427.358.553.616h0l.911 1.875 8.262.28c.383 0 .732.144.997.38.276.244.46.59.497.978h0V19c0 .414-.168.788-.44 1.06-.27.27-.645.439-1.06.439h0H5c-.414 0-.789-.167-1.06-.439-.272-.271-.44-.646-.44-1.06h0l.006-12.956c0-.414.169-.788.44-1.06.272-.27.647-.439 1.06-.44h0z" transform="translate(-186 -304) translate(148 304) translate(38)"/>
                      <path fill="#607d8b" d="M19 10.5c.69 0 1.315.278 1.767.73.453.453.733 1.078.733 1.769h0l-.006 6.002c-.001.414-.17.788-.44 1.06-.272.27-.646.438-1.06.439h0H5c-.414 0-.79-.168-1.06-.44-.272-.27-.44-.646-.44-1.06h0v-6c0-.69.28-1.315.732-1.768C4.685 10.78 5.31 10.5 6 10.5h0z" transform="translate(-186 -304) translate(148 304) translate(38)"/>
                  </g>
              </g>
          </g>
      </g>
  </svg>
  <svg *ngIf="!isFolder" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <g fill-rule="nonzero">
            <g>
                <g>
                    <g>
                        <path fill="#607d8b" d="M1.257 5.709c0-2.34-.074-4.258 2.267-4.258h11.302c-.294-1.029-.483-.889-1.604-.889L2.965.596c-1.288 0-2.347.994-2.45 2.256-.004.044-.006 4.89-.008 14.537 0 1.356-.18 2.025.526 2.025h.224V5.709z" transform="translate(-186 -250) translate(148 250) translate(38) translate(2 1)"/>
                        <path stroke="#607d8b" stroke-width=".844" d="M15.518 3.435c.562 0 1.07.228 1.44.596.368.37.596.878.596 1.44h0v12.96c0 .561-.228 1.07-.597 1.439-.368.368-.877.597-1.439.597h0H5.239c-.561 0-1.07-.229-1.439-.597-.368-.369-.597-.878-.597-1.44h0V5.47c0-.561.229-1.07.597-1.439.369-.368.878-.596 1.44-.596h0z" transform="translate(-186 -250) translate(148 250) translate(38) translate(2 1)"/>
                        <path fill="#607d8b" d="M13.73 16.263H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.67.67-.67h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67zM13.73 13.881H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.67.67-.67h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67zM13.73 11.504H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.671.67-.671h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67zM13.73 9.12H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.671.67-.671h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67z" transform="translate(-186 -250) translate(148 250) translate(38) translate(2 1)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
</div>
<div *ngIf="userType === userTypes.teacher">
  <svg *ngIf="isFolder" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <g stroke="#2E95D9">
            <g>
                <g>
                    <path d="M9.477 4.546c.287 0 .561.082.796.228.234.147.427.358.553.616h0l.911 1.875 8.262.28c.383 0 .732.144.997.38.276.244.46.59.497.978h0V19c0 .414-.168.788-.44 1.06-.27.27-.645.439-1.06.439h0H5c-.414 0-.789-.167-1.06-.439-.272-.271-.44-.646-.44-1.06h0l.006-12.956c0-.414.169-.788.44-1.06.272-.27.647-.439 1.06-.44h0z" transform="translate(-186 -304) translate(148 304) translate(38)"/>
                    <path fill="#2E95D9" d="M19 10.5c.69 0 1.315.278 1.767.73.453.453.733 1.078.733 1.769h0l-.006 6.002c-.001.414-.17.788-.44 1.06-.272.27-.646.438-1.06.439h0H5c-.414 0-.79-.168-1.06-.44-.272-.27-.44-.646-.44-1.06h0v-6c0-.69.28-1.315.732-1.768C4.685 10.78 5.31 10.5 6 10.5h0z" transform="translate(-186 -304) translate(148 304) translate(38)"/>
                </g>
            </g>
        </g>
    </g>
  </svg>
  <svg *ngIf="!isFolder && activityType === activityTypes.tests" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <g fill-rule="nonzero">
            <g>
                <g>
                    <path stroke="#2E95D9" stroke-width=".844" d="M17.226 3.31c.642 0 1.224.261 1.646.683.421.421.682 1.003.682 1.646h0v14.5c0 .642-.261 1.224-.682 1.645-.422.422-1.004.683-1.646.683h0-11.5c-.642 0-1.224-.261-1.646-.683-.421-.421-.682-1.003-.682-1.645h0v-14.5c0-.643.26-1.225.682-1.646.422-.422 1.004-.683 1.646-.683h0z" transform="translate(-186 -412) translate(148 412) translate(38)"/>
                    <path fill="#FFF" stroke="#2E95D9" stroke-width=".643" d="M11.5.679c.43 0 .83.23 1.052.605h0l1.894 3.213-5.447.91 1.432-4.094C10.65.92 11.058.68 11.5.679z" transform="translate(-186 -412) translate(148 412) translate(38)"/>
                    <g fill="#2E95D9">
                        <path d="M1.235 2.625l.53.53L3 1.921v1.72H.75V1.39h1.125V.64h-1.5C.168.64 0 .807 0 1.014v3c0 .207.168.375.375.375h3c.207 0 .375-.168.375-.375V1.171l.64-.64L3.86 0 1.235 2.625zM5.875 1.39L11 1.39 11 2.14 5.875 2.14zM5.875 2.89L9.505 2.89 9.505 3.64 5.875 3.64z" transform="translate(-186 -412) translate(148 412) translate(38) translate(6.375 14.61)"/>
                    </g>
                    <g fill="#2E95D9">
                        <path d="M1.235 2.625l.53.53L3 1.921v1.72H.75V1.39h1.125V.64h-1.5C.168.64 0 .807 0 1.014v3c0 .207.168.375.375.375h3c.207 0 .375-.168.375-.375V1.171l.64-.64L3.86 0 1.235 2.625zM5.875 1.39L11 1.39 11 2.14 5.875 2.14zM5.875 2.89L9.505 2.89 9.505 3.64 5.875 3.64z" transform="translate(-186 -412) translate(148 412) translate(38) translate(6.375 7.61)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
  <svg *ngIf="!isFolder && activityType === activityTypes.assignments"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
    <defs>
        <filter id="gnxnklnfia" width="141.6%" height="138.3%" x="-22.7%" y="-18.8%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter1"/>
            <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
            <feColorMatrix in="shadowOffsetOuter1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
        </filter>
        <path id="xbw8kpivtb" d="M13.728 7.279L16.09 9.64c-3.549 3.504-4.015 3.977-4.123 4.056l-.012.008c-.01.007-.018.009-.026.012l-.01.003-.006.003-2.983 1.392c-.201.094-.44.052-.596-.105-.157-.157-.199-.395-.105-.596l1.392-2.983c.008-.017.008-.025.016-.038l.006-.011c.02-.03.074-.089.24-.257l.078-.08.33-.331.407-.407.311-.311.367-.367.428-.427.493-.492.367-.368.4-.4.664-.663zm2.464-2.4c.414-.337 1.027-.313 1.412.072l.88.88c.411.412.411 1.08 0 1.492l-.85.84-2.367-2.367.65-.65.096-.096.09-.09c.058-.057.065-.062.09-.081z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <g transform="translate(-186 -196) translate(148 196) translate(38) translate(3 3)">
                        <rect width="15" height="19" x="-.5" y="-.5" stroke="#2E95D9" rx="2"/>
                        <g fill-rule="nonzero">
                            <use fill="#000" filter="url(#gnxnklnfia)" xlink:href="#xbw8kpivtb"/>
                            <use fill="#FFF" stroke="#2E95D9" xlink:href="#xbw8kpivtb"/>
                        </g>
                        <path fill="#2E95D9" fill-rule="nonzero" d="M2.813 3.41h7.875c.291 0 .527.236.527.527 0 .292-.236.528-.527.528H2.813c-.291 0-.528-.236-.528-.527 0-.292.237-.528.528-.528zM2.813 6.223H9c.291 0 .528.236.528.527 0 .291-.237.527-.528.527H2.813c-.291 0-.528-.236-.528-.527 0-.291.237-.527.528-.527zM2.285 9.563c0-.292.237-.528.528-.528h4.5c.291 0 .527.236.527.527 0 .292-.236.528-.527.528h-4.5c-.291 0-.528-.236-.528-.527z"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
  <svg *ngIf="!isFolder && activityType === activityTypes.materials" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <g fill-rule="nonzero">
            <g>
                <g>
                    <g>
                        <path fill="#2E95D9" d="M1.257 5.709c0-2.34-.074-4.258 2.267-4.258h11.302c-.294-1.029-.483-.889-1.604-.889L2.965.596c-1.288 0-2.347.994-2.45 2.256-.004.044-.006 4.89-.008 14.537 0 1.356-.18 2.025.526 2.025h.224V5.709z" transform="translate(-186 -250) translate(148 250) translate(38) translate(2 1)"/>
                        <path stroke="#2E95D9" stroke-width=".844" d="M15.518 3.435c.562 0 1.07.228 1.44.596.368.37.596.878.596 1.44h0v12.96c0 .561-.228 1.07-.597 1.439-.368.368-.877.597-1.439.597h0H5.239c-.561 0-1.07-.229-1.439-.597-.368-.369-.597-.878-.597-1.44h0V5.47c0-.561.229-1.07.597-1.439.369-.368.878-.596 1.44-.596h0z" transform="translate(-186 -250) translate(148 250) translate(38) translate(2 1)"/>
                        <path fill="#2E95D9" d="M13.73 16.263H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.67.67-.67h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67zM13.73 13.881H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.67.67-.67h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67zM13.73 11.504H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.671.67-.671h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67zM13.73 9.12H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.671.67-.671h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67z" transform="translate(-186 -250) translate(148 250) translate(38) translate(2 1)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
</div>
<div *ngIf="userType === userTypes.superAdmin">
  <svg *ngIf="isFolder" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <g stroke="#8d6e63">
            <g>
                <g>
                    <path d="M9.477 4.546c.287 0 .561.082.796.228.234.147.427.358.553.616h0l.911 1.875 8.262.28c.383 0 .732.144.997.38.276.244.46.59.497.978h0V19c0 .414-.168.788-.44 1.06-.27.27-.645.439-1.06.439h0H5c-.414 0-.789-.167-1.06-.439-.272-.271-.44-.646-.44-1.06h0l.006-12.956c0-.414.169-.788.44-1.06.272-.27.647-.439 1.06-.44h0z" transform="translate(-186 -304) translate(148 304) translate(38)"/>
                    <path fill="#8d6e63" d="M19 10.5c.69 0 1.315.278 1.767.73.453.453.733 1.078.733 1.769h0l-.006 6.002c-.001.414-.17.788-.44 1.06-.272.27-.646.438-1.06.439h0H5c-.414 0-.79-.168-1.06-.44-.272-.27-.44-.646-.44-1.06h0v-6c0-.69.28-1.315.732-1.768C4.685 10.78 5.31 10.5 6 10.5h0z" transform="translate(-186 -304) translate(148 304) translate(38)"/>
                </g>
            </g>
        </g>
    </g>
  </svg>
  <svg *ngIf="!isFolder" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <g fill-rule="nonzero">
            <g>
                <g>
                    <g>
                        <path fill="#8d6e63" d="M1.257 5.709c0-2.34-.074-4.258 2.267-4.258h11.302c-.294-1.029-.483-.889-1.604-.889L2.965.596c-1.288 0-2.347.994-2.45 2.256-.004.044-.006 4.89-.008 14.537 0 1.356-.18 2.025.526 2.025h.224V5.709z" transform="translate(-186 -250) translate(148 250) translate(38) translate(2 1)"/>
                        <path stroke="#8d6e63" stroke-width=".844" d="M15.518 3.435c.562 0 1.07.228 1.44.596.368.37.596.878.596 1.44h0v12.96c0 .561-.228 1.07-.597 1.439-.368.368-.877.597-1.439.597h0H5.239c-.561 0-1.07-.229-1.439-.597-.368-.369-.597-.878-.597-1.44h0V5.47c0-.561.229-1.07.597-1.439.369-.368.878-.596 1.44-.596h0z" transform="translate(-186 -250) translate(148 250) translate(38) translate(2 1)"/>
                        <path fill="#8d6e63" d="M13.73 16.263H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.67.67-.67h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67zM13.73 13.881H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.67.67-.67h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67zM13.73 11.504H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.671.67-.671h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67zM13.73 9.12H7.027c-.37 0-.67-.3-.67-.67 0-.37.3-.671.67-.671h6.703c.37 0 .67.3.67.67 0 .37-.3.67-.67.67z" transform="translate(-186 -250) translate(148 250) translate(38) translate(2 1)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
</div>
