import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-shared-dashboard',
  templateUrl: './shared-dashboard.component.html',
  styleUrls: ['./shared-dashboard.component.scss']
})
export class SharedDashboardComponent implements OnInit {
  width = window.innerWidth;

  @Input() selected = 0;
  @Input() isTabSelected = false;
  @Input() tabs;
  @Input() theme = '';
  @Input() schoolLabel: string;
  @Input() classesLabel: string;
  @Input() err: string;
  @Output() tabSelect = new EventEmitter();
  constructor(
  ) {}

  ngOnInit() {
  }

  select(index) {
    this.tabSelect.emit(index);
  }
}
