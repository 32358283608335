<div class="forgot-wrapper">
    <div class="header">
        <div>Forgot PIN</div>
        <div class="close" (click)="back()"> <img src="../../../assets/close.svg"/> </div>
    </div>

    <div class="pic">
        <img [src]= user.profilePicture/>
        <div class="text">{{user.name | titlecase}}</div>
    </div>

    <ng-container *ngIf="!isOTPVerified; else pin">
        <div>
            <app-shared-login
                [theme]="'student'"
                [isOtp]="isOtp"
                [isNumberError]="isNumberError"
                [isOTPError]="isOTPError"
                [otpError]="otpError"
                [otp]="otp"
                [otpTimer]="otpTimer"
                [hasError]="hasError"
                (phoneNumberInput)="enterPhoneNumber($event)"
                (loginApp)="login()"
                (verifyApp)="verify($event)"
                (onSendOTP)="sendOtp()"
                (onInputChange)="onChange($event)"
            ></app-shared-login>
        </div>
    
        <div class="btn">
            <button mat-flat-button color="primary" (click)="isOtp ? verify(otp) : login()" [ngClass]="{'disabled' : isSubmitted}" [disabled]="isSubmitted">CONTINUE</button>
        </div>
    </ng-container>

    <ng-template #pin>
        <div class="set-pin">Set a 4 digit new PIN</div>

        <div class="input">
            <input matInput type="number" maxlength="4" placeholder="Enter new pin" [(ngModel)]="newPin" (keydown.enter)="savePin()" />
        </div>

        <div class="btn">
            <button mat-flat-button color="primary" (click)="savePin()" [disabled]="isSubmitted" [ngClass]="{'disabled' : isSubmitted}">SAVE & CONTINUE</button>
        </div>
    </ng-template>

</div>