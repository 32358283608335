<div class="wrapper">
    <div class="add">
        <div>{{title}}</div>
        <div (click)="closeDialog()"> <img src="assets/close.svg" /></div>
    </div>

    <hr>

    <div class="text">Enter details</div>
    <div class="form">
        <form [formGroup]="userDetailsForm" (ngSubmit)="submit()">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Enter detail" formControlName="name">
            </mat-form-field>
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Mobile Number</mat-label>
                <input matInput type="number" placeholder="Enter detail" formControlName="mobileNumber">
                <mat-error *ngIf="userDetailsForm.controls['mobileNumber'].errors?.required">
                    mobile number is required
                </mat-error>
                <mat-error *ngIf="userDetailsForm.controls['mobileNumber'].errors?.min ||
                    userDetailsForm.controls['mobileNumber'].errors?.max">
                    invalid mobile number
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Max Schools</mat-label>
                <input matInput type="number" placeholder="Enter detail" formControlName="maxSchools">
                <mat-error *ngIf="userDetailsForm.controls['maxSchools'].errors?.required">
                    max schools is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" [floatLabel]="'always'" class="filter-input">
                <mat-label>Access Rights</mat-label>
                <mat-select placeholder="Select" #select formControlName="schools" panelClass="filterSelect">
                    <mat-option *ngFor="let ar of accessRights" [value]="ar.value">
                        {{ ar.text | titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="activity-rights-field">
                <p>Assignments</p>
                <div>
                    <mat-radio-group class="radio-btn" formControlName="assignments">
                        <mat-radio-button color="primary" class="example-margin" [value]="true">Yes</mat-radio-button>
                        <mat-radio-button color="primary" class="example-margin" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="activity-rights-field">
                <p>Materials</p>
                <div>
                    <mat-radio-group class="radio-btn" formControlName="materials">
                        <mat-radio-button color="primary" class="example-margin" [value]="true">Yes</mat-radio-button>
                        <mat-radio-button color="primary" class="example-margin" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="activity-rights-field">
                <p>Tests</p>
                <div>
                    <mat-radio-group class="radio-btn" formControlName="tests">
                        <mat-radio-button color="primary" class="example-margin" [value]="true">Yes</mat-radio-button>
                        <mat-radio-button color="primary" class="example-margin" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="activity-rights-field">
              <p>Live Classes</p>
              <div>
                  <mat-radio-group class="radio-btn" formControlName="liveClasses">
                      <mat-radio-button color="primary" class="example-margin" [value]="true">Yes</mat-radio-button>
                      <mat-radio-button color="primary" class="example-margin" [value]="false">No</mat-radio-button>
                  </mat-radio-group>
              </div>
            </div>

            <div class="btn">
                <button mat-flat-button type="submit" color="primary">SUBMIT</button>
            </div>
        </form>
    </div>
</div>
