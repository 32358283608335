<div class="session-wrapper">
    <div class="header">
        <div class="txt">Session Status</div>
        <div class="session">
            <ng-select 
            [items]= toppingList
            (change)="onYearSelect($event)"
            [clearable]="false"
            [searchable]="false"
            [(ngModel)]="toppings">
            </ng-select>   
        </div>
    </div>
    <hr>
    <div class="year">{{toppings}}</div>
    <ng-container>
        <div class="assignment">
            <div class="header">
                <div class="txt">Assignment Completion</div>
                <div class="value">{{session.assignment}}</div>
            </div>
            <div class="progress">
                <mat-progress-bar mode="determinate" [value]='65'></mat-progress-bar>
            </div>
        </div>
    </ng-container>
    <ng-container>
        <div class="test">
            <div class="header">
                <div class="txt">Test Completion</div>
                <div class="value">{{session.test}}</div>
            </div>
            <div class="progress">
                <mat-progress-bar mode="determinate" [value]='80'></mat-progress-bar>
            </div>
        </div>
    </ng-container>
    <ng-container>
        <div class="material">
            <div class="header">
                <div class="txt">Shared Material Read</div>
                <div class="value">{{session.material}}</div>
            </div>
            <div class="progress">
                <mat-progress-bar mode="determinate" [value]='75'></mat-progress-bar>
            </div>
        </div>
    </ng-container>
    <ng-container>
        <div class="live">
            <div class="header">
                <div class="txt">Live class Attendance</div>
                <div class="value">{{session.live}}</div>
            </div>
            <div class="progress">
                <mat-progress-bar mode="determinate" [value]='83'></mat-progress-bar>
            </div>
        </div>
    </ng-container>
</div>