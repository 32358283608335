<div [ngClass]="themeName? 'library-action-wrapper ' + themeName : 'library-action-wrapper'">
    <div class="text"> {{text | titlecase}}</div>
    <div>
        <ng-container *ngIf="btnText !== 'upload'">
            <div class="form-input">
                <app-shiksha-input [labelText]="'Name'">
                    <input type="text" [(ngModel)]="name" placeholder="Name">
                </app-shiksha-input>
            </div>
        </ng-container>

        <ng-container *ngIf="!isCloudLibrary">
            <div class="file-type" >
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Type</mat-label>
                    <mat-select [formControl]="type" [disabled]="btnText === 'create'">
                        <mat-option *ngFor="let action of actionList" [value]="action" [disabled]="action === 'folders' || action === 'tests'">
                          {{action}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
        </ng-container>

        <ng-container>
            <div class="form-input">
                <app-shiksha-input [labelText]="'Title'">
                    <input type="text" [(ngModel)]="title" placeholder="Title">
                </app-shiksha-input>
            </div>
        </ng-container>

        <ng-container *ngIf="btnText === 'upload'">
            <div class="browse">
                <app-doc-upload [text]="'BROWSE FILE'" [fileNames]="docFiles" (docUrl)="onDocUpload($event)"></app-doc-upload>
            </div>
        </ng-container>
    </div>

    <div class="btn">
        <div class="cancel white-background-button" (click)="btnAction('cancel')">
            <button mat-flat-button>CANCEL</button>
        </div>

        <div class="btntext" (click)="btnAction(btnText)" [ngClass]="{'save': btnText === 'save'}">
            <button mat-flat-button color="primary">{{btnText | uppercase}}</button>
        </div>
    </div>
</div>
