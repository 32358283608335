import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private httpService: HttpClient,
    private crud: CrudService
  ) { }

  public studentProfileRoute(studentID) {
    return `${environment.apiUrl}profile?role=student&id=${studentID}`;
  }

  public changePinRoute(type, id) {
    if(type !== 'student') {
      return `${environment.apiUrl}profile/pin`;
    }
    return `${environment.apiUrl}profile/pin?role=student&id=${id}`;
  }

  getProfile(studentID?) {
    if(studentID) {
      return this.crud.get(this.studentProfileRoute(studentID));
    } else {
      return this.crud.get(`${environment.apiUrl}profile`);
    }
  }

  editProfile(body, studentID?) {
    if(studentID) {
      return this.crud.put(this.studentProfileRoute(studentID), body);
    } else {
      return this.crud.put(`${environment.apiUrl}/profile`, body)
    }
  }

  getFromGcs(url) {
    return this.crud.get(url);
  }

  changePin(type, id, body) {
    return this.crud.patch(this.changePinRoute(type, id), body);
  }

  logout(body) {
    return this.httpService.post(`${environment.apiUrl}logout`, body).toPromise();
  }
}
