import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from './crud.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { COURSES_LABEL } from '../Constants/Constant';

@Injectable({
    providedIn: 'root'
})
export class LabelService implements OnDestroy {
    incomingData = {
        'school': 'स्कूलों',
        'schools': 'स्कूलों',
        'standard': 'स्कूलों',
        'standards': 'स्कूलों',
        'section': 'स्कूलों',
        'sections': 'स्कूलों',
        'session': 'स्कूलों',
        'createschool': 'स्कूलों',
    }
    labelData = {
        'school': 'School',
        'schools': 'Schools',
        'class': 'Class',
        'classes': 'Classes',
        'standard': 'Standard',
        'standards': 'Standards',
        'section': 'Section',
        'sections': 'Sections',
        'session': 'Session',
        'sessions': 'Sessions',
        'parent': 'Parent',
        'addstudent': 'Add Student',
        'gotoclasses': 'Go To Classes',
        'children': 'Children',
        'myclass': 'My Class',
        'joinliveclass': 'Join Live Class',
        'liveclasstoday': 'Live Class Today',
        'liveclasstitle': 'Live Class Title',
        'allschools': 'All Schools',
        'createschool': 'Create School',
        'student': 'Student',
        'students': 'Students',
        'teacher': 'Teacher',
        'teachers': 'Teachers',
        'staff': 'Staff',
        'subject': 'Subject',
        'subjects': 'Subjects',
        'schoolcreator': 'School Creator',
        'schooladmin': 'School Admin',
        'schoolmember': 'School Member',
        'inviteteachers': 'Invite Teachers',
        'inviteschoolstaff': 'Invite School Staff',
        'invitestudents': 'Invite Students',
        'invitecoteachers': 'Invite Co-Teachers',
        'coteachers': 'Co-Teachers',
        'liveclass': 'Live Class',
        'createliveclass': 'Create Live Class',
        'liveclassschedule': 'Live Class Schedule',
        'createclass': 'Create Class',
        'selectschool': 'Select School',
        'classname': 'Class Name',
        'selectstandard': 'Select Standard',
        'selectsection': 'Select Section',
        'selectsession': 'Select Session',
        'selectsubject': 'Select Subject',
        'pleaseselectschool': 'Please Select School',
        'pleaseselectstandard': 'Please Select Standard',
        'pleaseselectsection': 'Please Select Section',
        'pleaseselectsession': 'Please Select Session',
        'pleaseselectsubject': 'Please Select Subject',
        'createanewclass': 'Create a new class',
        'createanewschool': 'Create a new school',
        'scheduleliveclass': 'Schedule Live Class',
        'pleaseselectclass': 'Please Select Class',
        'recurringclass': 'Recurring Class',
        'allclasses': 'All Classes',
        'allsubjects': 'All Subjects',
        'allstandards': 'All Standards',
        'addstandard': 'Add Standard',
        'addsection': 'Add Section',
        'addsubject': 'Add Subject',
        'addsession': 'Add Session',
        'course-category': 'Course Category',
        COURSES_LABEL: COURSES_LABEL
    }
    private labels$$: BehaviorSubject<any> = new BehaviorSubject<any>(this.labelData);
    constructor(private crud: CrudService, public localstorgae: LocalStorageService) {
    }
    private subscription = new Subscription();
    public get labels$() {
        return this.labels$$.asObservable();
    }
    private get labels() {
        return environment.apiUrl + 'school/labels';
    }
    getLabels() {
        console.log(this.labels)
        this.subscription = this.crud.get(this.labels).subscribe(data => {
            if (data.newLabels) {
                let jsonData = JSON.parse(data.newLabels)
                let a = Object.keys(jsonData).filter(k => {
                    return Object.keys(this.labelData).includes(k)
                }).reduce((obj, k) => {
                    return {
                        ...obj,
                        [k]: jsonData[k]
                    }
                }, {})
                this.labels$$.next({ ...this.labelData, ...a })
            }
            else {
                this.labels$$.next({ ...this.labelData })
            }
        }, (error) => {
            this.labels$$.next({ ...this.labelData })
        });
    }
    setLabels(body) {
        let labelBody = {
            'newLabels': body
        }
        return this.crud.post(this.labels, labelBody);
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
