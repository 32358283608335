import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SpinnerComponent } from './spinner/spinner.component';
import { UploadComponent } from './upload/upload.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { CropImageDialogComponent } from './crop-image-dialog/crop-image-dialog.component';
import { SharedDashboardComponent } from './shared-dashboard/shared-dashboard.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    SpinnerComponent,
    UploadComponent,
    AlertDialogComponent,
    CropImageDialogComponent,
    SharedDashboardComponent
  ],
  exports: [
    SpinnerComponent,
    UploadComponent,
    SharedDashboardComponent
  ],
  imports: [
    ImageCropperModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    RouterModule
  ]
})
export class SharedComponentsModule { }
