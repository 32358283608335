import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminProfileService {

  constructor(
    private crud: CrudService
  ) { }

  logout() {
    return this.crud.post(`${environment.apiUrl}logout`, {}).toPromise();
  }

}
