<div class="filters-wrapper">
    <div class="user-inputs">
        <div class="header">
            <div class="text">Report</div>
            <div class="img" (click)="close()">
                <img src="assets/close.svg" />
            </div>
        </div>
        <ng-container *ngIf="isLoading; else filters">
            <app-spinner class="loader"></app-spinner>
        </ng-container>
        <ng-template #filters>
            <div class="empty-data-placeholder" *ngIf="!isDataAvailable">
                <p>Data Not Available</p>
            </div>
            <div *ngIf="isDataAvailable" class="filter-container" [formGroup]="reportForm">
                <div class="school-filter-container">
                    <div class="title">
                        <p>Activity Date</p>
                    </div>
                    <div class="date">
                        <mat-form-field class="date-input" appearance="outline" [floatLabel]="'always'">
                            <mat-label>From</mat-label>
                            <input matInput placeholder="DD-MM-YY" (click)="startpicker.open()" [max]="presentDate" [matDatepicker]="startpicker" formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="startpicker">
                                <mat-icon matDatepickerToggleIcon>
                                    <img src='assets/calender.svg' />
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #startpicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="date-input" appearance="outline" [floatLabel]="'always'">
                            <mat-label>To</mat-label>
                            <input matInput placeholder="DD-MM-YY" (click)="picker.open()" [min]="reportForm.value.startDate" [max]="presentDate" [matDatepicker]="picker" formControlName="endDate">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>
                                    <img src='assets/calender.svg' />
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="report-title">
                        <p>Report Type</p>
                    </div>
                    <div>
                        <mat-radio-group class="radio-btn" formControlName="type">
                            <div class="radio-btn-subgroup">
                                <mat-radio-button color="primary" class="example-margin" value="materials">Material</mat-radio-button>
                                <mat-radio-button color="primary" class="example-margin" value="tests">Test</mat-radio-button>
                            </div>
                            <div class="radio-btn-subgroup">
                                <mat-radio-button color="primary" class="example-margin" value="assignments">Assignment
                                </mat-radio-button>
                                <mat-radio-button color="primary" class="example-margin" value="live_classes">Live Class
                                </mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>

                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="filter-input">
                        <mat-label>{{labelData['school'] | titlecase}}</mat-label>
                        <mat-select placeholder="Select" #select formControlName="school" panelClass="filterSelect">
                            <mat-option *ngFor="let s of schools" [value]="s.id">
                                {{ s.name | titlecase}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!reportForm.controls['school'].valid && reportForm.controls['school'].touched">
                            {{'please select a value for ' + labelData['school'].toLowerCase()}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="filter-input">
                        <mat-label>{{labelData['teacher'] | titlecase}}</mat-label>
                        <mat-select placeholder="Select" #select formControlName="teacher" panelClass="filterSelect">
                            <mat-option *ngFor="let t of teachers" [value]="t.id">
                                {{ t.name | titlecase}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!reportForm.controls['teacher'].valid && reportForm.controls['teacher'].touched">
                            {{'please select a value for ' + labelData['teacher'].toLowerCase()}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="filter-input">
                        <mat-label>{{labelData['standard']}}</mat-label>
                        <mat-select placeholder="Select" #select formControlName="standard" panelClass="filterSelect">
                            <mat-option *ngFor="let std of standards" [value]="std.id">
                                {{ std.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!reportForm.controls['standard'].valid && reportForm.controls['standard'].touched">
                            {{'please select a value for '+ labelData['standard'].toLowerCase()}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="filter-input">
                        <mat-label>{{labelData['section']}}</mat-label>
                        <mat-select placeholder="Select" #select formControlName="section" panelClass="filterSelect">
                            <mat-option *ngFor="let sec of sections" [value]="sec.id">
                                {{ sec.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!reportForm.controls['section'].valid && reportForm.controls['section'].touched">
                            {{'please select a value for '+ labelData['section'].toLowerCase()}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="filter-input">
                        <mat-label>{{labelData['subject'] | titlecase}}</mat-label>
                        <mat-select placeholder="Select" #select formControlName="subject" panelClass="filterSelect">
                            <mat-option *ngFor="let sub of subjects" [value]="sub.id">
                                {{ sub.name | titlecase }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!reportForm.controls['subject'].valid && reportForm.controls['subject'].touched">
                            {{'please select a value for '+ labelData['subject'].toLowerCase()}}
                        </mat-error>
                    </mat-form-field>

                </div>
                <div class="session-filter-container">
                    <!-- <div class="title">
                      <p>{{ sessionFilterTitle }}</p>
                  </div> -->

                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="filter-input">
                        <mat-label>{{labelData['session']}}</mat-label>
                        <mat-select placeholder="Select" formControlName="session">
                            <mat-option *ngFor="let session of sessions" [value]="session.id">
                                {{session.name | titlecase}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!reportForm.controls['session'].valid && reportForm.controls['session'].touched">
                            {{'please select a value for '+ labelData['session'].toLowerCase()}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </ng-template>
    </div>
    <div *ngIf="isDataAvailable" class="btn">
        <button mat-flat-button color="primary" (click)="downloadReport()" [disabled]="!reportForm.valid">DOWNLOAD
      REPORT</button>
    </div>
</div>