import { Component, OnInit, ElementRef, ViewChild, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { LiveSessionService } from 'src/app/services/live-session.service';
import { Subscription } from 'rxjs';
import {
  PARTICIPANTS_WINDOW, HANDRAISE_WINDOW, CHAT_WINDOW, RELOAD_LIVE_CLASS_PAGE, DO_RELOAD,
  DO_NOT_RELOAD, RELOAD_LIVE_CLASS_PREV_PAGE
} from 'src/app/Constants/Constant';
import { Participant, LiveSessionState, LiveStream } from 'src/app/models/live-class-models';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { StudentPublisherService } from '../services/student-publisher.service';
import { StudentSubscriberService } from '../services/student-subscriber.service';

@Component({
  selector: 'app-student-live-class',
  templateUrl: './student-live-class.component.html',
  styleUrls: ['./student-live-class.component.scss']
})
export class StudentLiveClassComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private liveSessionSvc: LiveSessionService,
    private publisherSvc: StudentPublisherService,
    private subscriberSvc: StudentSubscriberService,
    public localStorage: LocalStorageService,
    public route: ActivatedRoute,
    public loc: Location
  ) { }

  public teacherStreamSource: any;

  public remoteStreams: any;
  public leaveSessionRequested: boolean = false;
  //teacherStream details
  public teacherStream: LiveStream;
  //live-session and class related info , to be changed later
  private liveSessionId = "";
  public liveSession: any = null;
  public teacher: any = null;

  public isConnectionDropped: boolean = false;

  //self stream id which should be used in publishing
  public streamId: string;

  //subscriptions to manage
  private subscriptions: Subscription[] = [];

  //joined the room or not
  public isJoinedRoom: boolean = false;

  //remote video and self video element
  @ViewChild('localVideo') videoEl: ElementRef;
  private localVideoElId: string = "localVideo";

  //when publishing
  public isMicOn: boolean = true;
  public isCameraOn: boolean = true;
  public isPublishing: boolean = false;
  public isHandRaised: boolean = false;

  //showing and hiding buttons on mousemove
  public showButtons: boolean = false;
  public timer: any;

  //showing loader if connection closed
  public showLoader: boolean;

  //acceptedhand raised
  public acceptedRaisedHand: string;

  //self stream related functions
  public turnOnCamera() {
    this.publisherSvc.turnOnCamera();
  }

  public turnOffCamera() {
    this.publisherSvc.turnOffCamera();
  }

  public turnOnMic() {
    this.publisherSvc.turnOnMic();
  }

  public turnOffMic() {
    this.publisherSvc.turnOffMic();
  }

  //raise hand related
  public raiseHand() {
    this.liveSessionSvc.raiseHand(this.streamId)
  }

  public lowerHand() {
    if (this.isHandRaised) {
      this.liveSessionSvc.lowerHand(this.streamId)
    }
  }

  //show and hide participant and chat
  public isShowingParticipants: boolean = false;
  public isShowingChat: boolean = false;

  public showParticipants() {
    this.isShowingParticipants = true;
    this.isShowingChat = false;
    this.openSideWindow(this.participantsWindow);
  }

  public showChat() {
    this.isShowingParticipants = false;
    this.isShowingChat = true;
    this.openSideWindow(this.chatWindow);
  }

  //starting and stopping publishing according to accept raised hand
  private startPublishing() {
    this.publisherSvc.joinSession(this.liveSessionId, this.localVideoElId, this.streamId);
  }

  private stopPublishing() {
    this.publisherSvc.leaveSession();
  }


  //side window
  //different sidewindow names
  public participantsWindow = PARTICIPANTS_WINDOW;
  public handRaiseWindow = HANDRAISE_WINDOW;
  public chatWindow = CHAT_WINDOW;
  public selectedSideWindow: string;


  public openSideWindow = (windowName: string) => {
    this.liveSessionSvc.openSideWindow(windowName);
  }

  public closeSideWindow = () => {
    this.liveSessionSvc.closeSideWindow();
    this.isShowingChat = false;
    this.isShowingParticipants = false;
  }

  public isNearBottom: boolean = true;

  public receivedTeacherStreamInfo: boolean = false;


  public sendChatMessage(event) {
    if (event.chatInputEl.nativeElement.value) {
      this.liveSessionSvc.sendChatMessage(event.chatInputEl.nativeElement.value);
      console.log("sending a message", event.chatInputEl.nativeElement.value)
      event.chatInputEl.nativeElement.value = "";
      setTimeout(() => {
        if (event.messagesContainer) {
          event.messagesContainer.nativeElement.scrollTop = event.messagesContainer.nativeElement.scrollHeight + 50;
        }
      }, 10);
    }
  }

  //all participants (students)
  public allParticipants: Map<string, Participant>;
  public selfParticipant: Participant;
  // active participants list
  public activeParticipants: Participant[] = [];

  //live session state
  public liveSessionState: LiveSessionState;

  ngOnInit(): void {
    let reloadLiveClass = this.localStorage.getItem(RELOAD_LIVE_CLASS_PAGE)
    if (reloadLiveClass == "" || reloadLiveClass == DO_RELOAD) {
      this.localStorage.setItem(RELOAD_LIVE_CLASS_PAGE, DO_NOT_RELOAD);
      window.location.reload();
    } else {
      this.localStorage.setItem(RELOAD_LIVE_CLASS_PAGE, DO_RELOAD);
      this.localStorage.setItem(RELOAD_LIVE_CLASS_PREV_PAGE, DO_RELOAD);
    }
    this.streamId = this.localStorage.getItem('studentID');
    this.route.params.subscribe(param => {
      this.liveSessionId = param.id;
      this.liveSessionSvc.getLiveSessionById(this.liveSessionId);
    })

    this.subscriptions.push(this.liveSessionSvc.liveSession$.subscribe((liveSession) => {
      if (liveSession) {
        this.liveSession = liveSession;
        this.liveSessionSvc.getLiveSessionTeacher(this.liveSessionId)
        this.liveSessionSvc.getClassStudents(this.liveSessionId);
        this.liveSessionSvc.getChatMessages(liveSession.id);
      }
    }));

    this.subscriptions.push(this.liveSessionSvc.teacher$.subscribe((teacher) => {
      this.teacher = teacher;
    }));

    this.subscriptions.push(this.liveSessionSvc.selectedSideWindow$.subscribe(
      (selectedSideWindow) => {
        this.selectedSideWindow = selectedSideWindow;
      }
    ));

    //subscriptions to get all the participants
    this.subscriptions.push(this.liveSessionSvc.participants$.subscribe(
      (participants) => {
        this.allParticipants = participants;
        if (this.streamId) {
          this.selfParticipant = participants.get(this.streamId);
        }
      }
    ));

    // all active participants details subscription
    this.subscriptions.push(this.liveSessionSvc.activeParticipants$.subscribe(
      (activeParticipants) => {
        this.activeParticipants = activeParticipants;
      }
    ));

    //raised hands
    this.subscriptions.push(this.liveSessionSvc.acceptedRaisedHand$.subscribe(
      (acceptedRaisedHand) => {
        this.acceptedRaisedHand = acceptedRaisedHand
        if (acceptedRaisedHand == this.streamId && this.isHandRaised) {
          if (!this.isPublishing && this.teacherStreamSource != null) {
            console.log("starting publishing")
            this.startPublishing();
          }
        } else {
          if (this.isPublishing) {
            this.stopPublishing();
          }
        }
      }
    ));

    this.subscriptions.push(this.liveSessionSvc.isSelfHandRaised$.subscribe(
      (isSelfHandRaised) => {
        this.isHandRaised = isSelfHandRaised;
      }
    ));

    this.subscriptions.push(this.liveSessionSvc.liveSessionState$.subscribe(
      (liveSessionState) => {
        this.liveSessionState = liveSessionState;
      }
    ))
  }

  public startSession() {
    if (!this.isJoinedRoom) {
      let pc_config = {
        'iceServers': [{
          'urls': 'stun:stun1.l.google.com:19302'
        }]
      };

      let mediaConstraints = {
        audio: true,
        video: {
          width: { ideal: 320, max: 320 },
          height: { ideal: 240, max: 240 },
          frameRate: 15,
        },
      };;

      let Window: any = window;
      let isChromium = Window.chrome;
      let winNav = Window.navigator;
      let vendorName = winNav.vendor;
      let isOpera = typeof Window.opr !== "undefined";
      let isIEedge = winNav.userAgent.indexOf("Edge") > -1;
      let isIOSChrome = winNav.userAgent.match("CriOS");

      if (isIOSChrome) {
        // is Google Chrome on IOS
      } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
      ) {
        mediaConstraints = {
          audio: true,
          video: {
            width: { ideal: 160, max: 160 },
            height: { ideal: 120, max: 120 },
            frameRate: 15,
          },
        };
      } else {
        // not Google Chrome
      }

      let sdpConstraints = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true
      };

      this.subscriberSvc.setTeacherStreamId(this.liveSession.teacherStream);
      this.subscriberSvc.setStreamingConstraints(pc_config, mediaConstraints, sdpConstraints);
      this.publisherSvc.setStreamingConstraints(pc_config, mediaConstraints, sdpConstraints);


      //for live streaming
      this.videoEl.nativeElement.id = this.localVideoElId;
      this.videoEl.nativeElement.muted = "muted";
      this.subscriberSvc.joinSession(this.liveSessionId, this.localVideoElId, this.streamId);
      this.subscriptions.push(this.publisherSvc.isPublishing$.subscribe((isPublishing) => {
        if (this.isPublishing && !isPublishing) {
          this.lowerHand();
        }
        this.isPublishing = isPublishing;
        if (!isPublishing) {
          this.videoEl.nativeElement.srcObject = null;
        } else {
          console.log("publishing");
          this.videoEl.nativeElement.srcObject = this.publisherSvc.localStream;
          this.isCameraOn = true;
          this.isMicOn = true;
          if (this.acceptedRaisedHand != this.streamId || this.teacherStreamSource == null) {
            this.stopPublishing();
          }
        }
      }));

      this.subscriptions.push(this.publisherSvc.isCameraOn$.subscribe(
        (isCameraOn) => {
          this.isCameraOn = isCameraOn;
        }
      ))

      this.subscriptions.push(this.publisherSvc.isMicOn$.subscribe(
        (isMicOn) => {
          this.isMicOn = isMicOn;
        }
      ))

      this.subscriptions.push(this.subscriberSvc.showLoader$.subscribe((showLoader) => {
        this.showLoader = showLoader;
      }));

      this.subscriptions.push(this.subscriberSvc.remoteStreams$.subscribe((remoteStreams) => {
        for (let i = 0; i < remoteStreams.length; i++) {
          remoteStreams[i].source.name = this.allParticipants.get(remoteStreams[i].source.streamId).name;
        }
        this.remoteStreams = remoteStreams;
      }));

      this.subscriptions.push(this.subscriberSvc.isConnectionClosed$.subscribe((isConnectionClosed) => {
        if (isConnectionClosed) {
          if (!this.leaveSessionRequested && !this.subscriberSvc.isJoiningSession()) {
            console.log("connection is closed now spanning a new one")
            this.publisherSvc.leaveSession();
            this.subscriberSvc.reJoinSession();
          }
        }
      }));

      this.subscriptions.push(this.subscriberSvc.teacherStreamSource$.subscribe((teacherStreamSource) => {
        this.teacherStreamSource = teacherStreamSource;
        if (!teacherStreamSource && this.isPublishing) {
          this.stopPublishing();
        }
      }));

      this.subscriptions.push(this.subscriberSvc.teacherStream$.subscribe((teacherStream) => {
        this.teacherStream = teacherStream;
        if (!this.receivedTeacherStreamInfo && this.teacherStream) {
          this.receivedTeacherStreamInfo = true;
          setTimeout(() => {
            this.addTeacherVideoEventListner();
          }, 5000)
        }
        if (!teacherStream) {
          this.receivedTeacherStreamInfo = false;
        }
      }));
      this.isJoinedRoom = true;
      this.liveSessionSvc.createLiveSessionSocket(this.liveSessionId, this.streamId, this.liveSession.teacherID, this.localStorage.getItem("token"));

      this.subscriptions.push(this.liveSessionSvc.isConnectionDropped$.subscribe((isConnectionDropped) => {
        this.isConnectionDropped = isConnectionDropped;
        if (this.isConnectionDropped) {
          this.publisherSvc.leaveSession();
          this.subscriberSvc.leaveSession();
        }
      }))
    }
  }

  public leaveSession() {
    this.leaveSessionRequested = true;
    this.subscriberSvc.leaveSession();
    this.loc.back();
  }

  private addTeacherVideoEventListner() {
    let remoteVideoPlacerHolderRef = document.getElementById("teacher-video");
    remoteVideoPlacerHolderRef.addEventListener('mousemove', (e) => {
      if (e.target != e.currentTarget) {
        return
      }
      this.showButtons = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showButtons = false;
      }, 3000)
    });
  }

  ngAfterViewInit() {
    let remoteVideoPlacerHolderRef = document.getElementById("remote-video-placeholder");
    remoteVideoPlacerHolderRef.addEventListener('mousemove', (e) => {
      this.showButtons = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showButtons = false;
      }, 3000)
    });
  }

  public reJoin() {
    window.location.reload();
  }

  public end() {
    this.loc.back();
  }

  ngOnDestroy() {
    this.liveSessionSvc.setInitialState();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

}
