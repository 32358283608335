import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { USER_THEMES } from 'src/app/Constants/user-types-constants';

const CANCEL = 'cancel';
const CREATE = 'create';
const UPLOAD = 'upload';
const EDIT_TEXT = 'Edit selected file or folder';
const SAVE = 'save';
const ACTION_ITEM = ['assignments', 'materials', 'tests', 'folders'];

export interface LibraryDialogData {
  type: 'create' | 'upload';
  fileData: any;
  themeName?: string;
  isCloudLibrary?: boolean;
}
@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss']
})
export class CreateFolderComponent implements OnInit {
  name: string;
  type;
  title: string;
  text: string;
  actionList = ACTION_ITEM;
  btnText: 'upload' | 'create' | 'save';
  docFiles: string[] = [];

  themeName = '';
  isCloudLibrary = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LibraryDialogData,
    public snackbar: SnackbarService,
    public dialogref: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
    this.text = this.data.type === CREATE ? 'create folder' : 'upload file';
    this.type = this.data.type === CREATE ? new FormControl('folders') : new FormControl('assignments');
    this.btnText = this.data.type === CREATE ? CREATE : UPLOAD;
    this.themeName = this.data.themeName;
    this.isCloudLibrary = this.data.isCloudLibrary;
    this.data.fileData ? this.populateData() : null;
  }

  populateData() {
    this.name = this.data.fileData.name;
    this.type = new FormControl(this.data.fileData.type);
    this.title = this.data.fileData.title;
    this.text = EDIT_TEXT;
    this.btnText = SAVE;
  }

  onDocUpload(event) {
    this.docFiles = event.files;
  }

  getRequestBody() {
    if (this.btnText === CREATE) {
      return {
        name: this.name,
        isFolder: true,
        title: this.title,
      };
    } else {
      if (this.text === EDIT_TEXT) {
        return {
          activityType: this.type.value.toLowerCase(),
          name: this.name,
          title: this.title,
        }
      } else {
        return {
          activityType: this.type.value.toLowerCase(),
          title: this.title,
          isFolder: false,
          parent: 'root',
          uploadedFiles: this.docFiles
        };
      }
    }
  }

  getRequestBodyForCloud() {
    if (this.btnText === CREATE) {
      return {
        name: this.name,
        title: this.title,
        contentType: 'folders'
      };
    } else {
      if (this.text === EDIT_TEXT) {
        return {
          name: this.name,
          title: this.title,
        };
      } else {
        return {
          title: this.title,
          parent: 'root',
          uploadedFiles: this.docFiles,
          contentType: 'files'
        };
      }
    }
  }

  checkForValidation(requestBody) {
    let isValid = true;
    Object.keys(requestBody).forEach(body => {
      if (!requestBody[body] && body !== 'isFolder') {
        isValid = false;
      }
    });
    return isValid;
  }

  btnAction(action) {
    if (action === CANCEL) {
      this.dialogref.close();
    } else {
      const requestBody = this.themeName === USER_THEMES.superAdmin.name ? this.getRequestBodyForCloud() :  this.getRequestBody();
      if (this.checkForValidation(requestBody)) {
        this.dialogref.close(requestBody);
      } else {
        this.snackbar.openSnackbar('Please fill all the details!!');
      }
    }
  }
}
