import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {allowedAudioType, allowedImageType, allowedVideoType, FILE_TYPES} from '../../Constants/Constant';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-file-carousel-item',
  templateUrl: './file-carousel-item.component.html',
  styleUrls: ['./file-carousel-item.component.scss']
})
export class FileCarouselItemComponent implements OnInit, AfterViewInit {

  public FILE_TYPES = FILE_TYPES;
  public fileType = this.FILE_TYPES.IMAGE;
  isLoading: boolean = true;
  @Input() file = null;

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.isLoading = true;
    const fileType = this.file.name.split('.');
    if (allowedImageType.includes(fileType[fileType.length - 1])) {
      this.fileType = this.FILE_TYPES.IMAGE;
      this.isLoading = false;
    } else if (fileType[fileType.length - 1] === 'pdf') {
      this.fileType = this.FILE_TYPES.PDF;
      this.isLoading = false;
    } else if (allowedVideoType.includes(fileType[fileType.length - 1])) {
      this.fileType = this.FILE_TYPES.VIDEO;
      this.isLoading = false;
    } else if (allowedAudioType.includes(fileType[fileType.length - 1])) {
      this.fileType = this.FILE_TYPES.AUDIO;
      this.isLoading = false;
    } else {
      this.fileType = this.FILE_TYPES.OTHER;
      this.isLoading = false;
    }
  }

  pauseVideo(event) {
    event.target.pause()
  }

}
