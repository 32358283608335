import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { CropImageDialogComponent } from '../crop-image-dialog/crop-image-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { maxLogoSize } from 'src/app/Constants/Constant';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  @Output() url= new EventEmitter<String | ArrayBuffer>();
  @Input() imgUrl: string | ArrayBuffer
  @Input() text: string
  imageChangedEvent: any = '';
  @Input() imgType : 'logo' | 'user';
  constructor(public snackbar: SnackbarService, public dialogRef: MatDialog) { }

  ngOnInit(): void { }

  fileChangeEvent(event: any): void {
    if(event.target.files[0].size > maxLogoSize) {
      const dialogRef = this.dialogRef.open(AlertDialogComponent, {
        width: '363px',
        height: '190px',
        backdropClass: 'blur',
        data: { alertText: 'size of logo cannot exceed 5Mb'},
        panelClass: ['delete-dialog-css'],
        autoFocus: false
      });
      return;
    } else {
      const dialogRef = this.dialogRef.open(CropImageDialogComponent, {
        data: {imageChangedEvent : event, imgType: this.imgType},
        backdropClass: 'blur',
        autoFocus: false,
        panelClass: 'image-dialog',
        maxHeight: '100%',
        maxWidth: '100%',
        width: '100%',
        height: '100%'
      });

      dialogRef.afterClosed().subscribe(image => {
          if(image) {
            this.imgUrl = image;
            this.url.emit(image);
          }

      })
    }
  }
}
