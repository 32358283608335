import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Participant } from 'src/app/models/live-class-models';

@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss']
})
export class ParticipantComponent implements OnInit {
  @Input() allParticipants: Map<string, Participant>;
  @Input() activeParticipant: Participant[] = [];
  @Input() type: 'teacher' | 'student';
  @Input() selfParticipant: Participant;
  @Input() acceptedRaisedHand: string;
  @Output() closeWindow = new EventEmitter();
  @Output() acceptRaisedHand = new EventEmitter();
  @Output() lowerRaisedHand = new EventEmitter();
  constructor() { }

  ngOnInit(): void { }

  closeSideWindow() {
    this.closeWindow.emit();
  }

  onAcceptRaisedHand(participantId: string) {
    this.acceptRaisedHand.emit(participantId);
  }

  onLowerRaisedHand(participantId: string) {
    this.lowerRaisedHand.emit(participantId);
  }

  public preetyDate(date: Date) {
    return date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })
  }
}
