<ng-container>
    <div class="header">
        <ng-container *ngIf="date !== 'This Week'; else week">
            <div class="date">{{ selectedDate | date : 'dd'}} {{ selectedDate | date : 'LLLL'}}, {{ selectedDate | date : 'EEEE'}}</div>
        </ng-container>

        <ng-template #week>
            <div class="date">{{ weekDateRange.monday | date : 'dd LLL, yyyy'}} - {{ weekDateRange.saturday | date : 'dd LLL, yyyy'}}</div>
        </ng-template>

        <div class="session">
            <mat-select [value]="date" (selectionChange)="onSelect($event)">
                <mat-option *ngFor="let date of dateRange" [value]="date">
                    {{date}}
                </mat-option>
            </mat-select>
        </div>
    </div>
</ng-container>
<hr>
<ng-container>
    <div class="live-table">
        <app-live-class-table [transactions]="list" [displayedColumns]="columns" (onJoinClass)="onClassJoin($event)"></app-live-class-table>
    </div>
</ng-container>
