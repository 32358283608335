<ng-container>
    <div class="assignment-wrapper">
        <div class="text">
            <div>{{textHeader | titlecase}} Assignment</div>
            <div (click)="closeDialog()" class="close"><img src="../../../assets/close.svg" /></div>
        </div>

        <div class="header">Enter details</div>

        <ng-container *ngIf="assignmentForm">
            <div class="form">
                <form [formGroup]="assignmentForm" (ngSubmit)="postAssignment()">
                    <div class="name">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label> Assignment Title</mat-label>
                            <input matInput placeholder="Title" formControlName="title" autocomplete="off">
                            <mat-error *ngIf="!assignmentForm.controls['title'].valid && assignmentForm.controls['title'].touched">Assignment title is required</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="description">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>Description</mat-label>
                            <textarea matInput rows="5" placeholder="Enter assignment short description" formControlName="description" autocomplete="off"></textarea>
                            <mat-error *ngIf="!assignmentForm.controls['description'].valid && assignmentForm.controls['description'].touched">Description is required</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="group">
                        <div *ngIf="!isCourseActivity" class="submission">
                            <mat-form-field appearance="outline" [floatLabel]="'always'">
                                <mat-label>Date of Submission</mat-label>
                                <input matInput placeholder="DD-MM-YY" formControlName="submission" [disabled]="isUploading" [min]="minDate" (click)="picker.open()" [matDatepicker]="picker" (dateChange)="addEvent($event)">
                                <mat-datepicker-toggle matSuffix [for]="picker">
                                    <mat-icon matDatepickerToggleIcon>
                                        <img [src]="assignmentForm.controls['submission'].value !== null ? '../../../assets/marked-calender.svg' : '../../../assets/calender.svg'" />
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="!assignmentForm.controls['submission'].valid && assignmentForm.controls['submission'].touched">Date of submission is required</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="marks" [ngClass]="isCourseActivity ? 'marks-full-width': ''">
                            <mat-form-field appearance="outline" [floatLabel]="'always'">
                                <mat-label>Full Marks</mat-label>
                                <input matInput placeholder="Enter marks" type="number" formControlName="marks" autocomplete="off">
                                <mat-error *ngIf="!assignmentForm.controls['marks'].valid && assignmentForm.controls['marks'].touched">{{ getMarksError() }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container *ngIf="isCourseActivity else upload">
                        <div class="upload" [ngClass]="{'hasError': (!assignmentForm.controls['marks'].valid && assignmentForm.controls['marks'].touched)}" [ngStyle]="{'color': '#607d8b'}">
                            <app-doc-upload [isEdit]="isEditAction" [isCourseActivity]="isCourseActivity" [text]="'UPLOAD ASSIGNMENT'" [fileNames]="docFiles" [uploadFromLibrary]="data.post && data.post.uploadFromLibrary" [disabled]="disable" [isdocUploaded]="isdocUploaded" (docUrl)="onDocUpload($event)"></app-doc-upload>
                        </div>
                    </ng-container>
                    <ng-template #upload>
                        <div class="upload" [ngClass]="{'hasError': (!assignmentForm.controls['marks'].valid && assignmentForm.controls['marks'].touched)}">
                            <app-doc-upload [disabled]="isUploading" [isEdit]="isEditAction" [text]="'UPLOAD ASSIGNMENT'" [fileNames]="docFiles" [uploadFromLibrary]="data.post && data.post.uploadFromLibrary" [disabled]="disable" [isdocUploaded]="isdocUploaded" (docUrl)="onDocUpload($event)"></app-doc-upload>
                        </div>
                    </ng-template>

                    <ng-container *ngIf="isUploading">
                        <div class="progress">
                            <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!isCourseActivity">
                        <mat-checkbox color="primary" [value]="checked" (change)="onCheckboxClick($event)" [disabled]="isUploading || (uploadFromLibrary && data.post || disable)">Save to Library</mat-checkbox>
                    </ng-container>

                    <div class="btn">
                        <button mat-flat-button color="primary" type="submit" [ngClass]="{'disabled': isUploading}">SUBMIT</button>
                    </div>

                </form>
            </div>
        </ng-container>
    </div>
</ng-container>
