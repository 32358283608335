<ng-container *ngIf="showInitLoader">
    <app-spinner></app-spinner>
</ng-container>
<div *ngIf="!isConnectionDropped" [ngClass]="liveSession ? 'visible': 'hidden'" class="container">
    <div class="video-container">
        <ng-container *ngIf="togglingVideoSource">
            <div class="spinner overlay no-pointer-events" (click)="false; $event.stopPropagation();">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        </ng-container>
        <button class="leave-session-button" (click)="leaveSession()">END SESSION</button>
        <video [ngClass]="isSharingScreen || isSharingScreenWithCamera || !isCameraOn? 'no-display': 'visible'" #localVideo autoplay playsinline></video>
        <div id="video-placeholder" class="thumbnail-container self-thubmnail-container" [ngClass]="isSharingScreen || isSharingScreenWithCamera || !isCameraOn? 'visible': 'no-display'">
            <div *ngIf="teacher && !teacher.profilePicture" class="profile-image-thumbnail">{{teacher.name.charAt(0)}}
            </div>
            <div *ngIf="teacher &&teacher.profilePicture" class="profile-image-thumbnail"><img [src]="teacher.profileSrc" /></div>
        </div>
        <div *ngIf="publishing" class="Bottom-Gradient" [ngClass]="showButtons ? 'visible': 'hidden'">
            <div class="actions-container">
                <div class="action" *ngIf="isCameraOn">
                    <div (click)="turnOffCamera()">
                        <img src="assets/turn-off-camera-copy.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Turn Off Camera</div>
                </div>
                <div class="action" *ngIf="!isCameraOn">
                    <div (click)="turnOnCamera()">
                        <img src="assets/turn-on-camera.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Turn On Camera</div>
                </div>
                <div class="action" *ngIf="isMicOn">
                    <div (click)="turnOffMic()">
                        <img src="assets/turn-off-mic.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Turn Off Mic</div>
                </div>
                <div class="action" *ngIf="!isMicOn">
                    <div (click)="turnOnMic()">
                        <img src="assets/turn-on-mic.svg" width="48px" height="48px" />
                    </div>
                    <div class="action-label">Turn On Mic</div>
                </div>
                <div *ngIf="isScreenShareSupported" class="share-screen-buttons">
                    <div class="action" *ngIf="!isSharingScreen">
                        <div (click)="startScreenShare()">
                            <img src="assets/share-screen.svg" width="48px" height="48px" />
                        </div>
                        <div class="action-label">Share screen</div>
                    </div>
                    <div class="action" *ngIf="isSharingScreen">
                        <div (click)="stopScreenShare()">
                            <img src="assets/stop-sharing.svg" width="48px" height="48px" />
                        </div>
                        <div class="action-label">Stop sharing</div>
                    </div>

                    <div class="action" *ngIf="!isSharingScreenWithCamera">
                        <div (click)="startScreenShareWithCamera()">
                            <img src="assets/share-screen.svg" width="48px" height="48px" />
                        </div>
                        <div class="action-label">Screen+Camera</div>
                    </div>
                    <div class="action" *ngIf="isSharingScreenWithCamera">
                        <div (click)="stopScreenShareWithCamera()">
                            <img src="assets/stop-sharing.svg" width="48px" height="48px" />
                        </div>
                        <div class="action-label">Stop sharing</div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="remoteStreams && remoteStreams.length > 0" class="other-streams" cdkDragBoundary=".video-container" cdkDrag>
            <div *ngFor="let stream of remoteStreams;let i=index" class="subscriber-video">
                <div class="remote-stream-dimensions">
                    <video [ngClass]="!stream.isCameraOn ? 'no-display' : ''" width="130px" height="100px" [srcObject]="stream.source.stream" autoplay playsinline></video>
                    <div *ngIf="!stream.isCameraOn" class="thumbnail-container">
                        <div *ngIf="!stream.source.profilePicture" class="profile-image remote-profile">
                            {{stream.source.name.charAt(0)}}</div>
                        <div *ngIf="stream.source.profilePicture" class="profile-image remote-profile"><img [src]="stream.source.profileSrc" /></div>
                    </div>
                </div>
                <div class="remote-stream-info">
                    <div class="remote-stream-name">{{stream.source.name}}</div>
                    <div class="mic-container">
                        <mat-icon *ngIf="stream.isMicOn">mic</mat-icon>
                        <mat-icon *ngIf="!stream.isMicOn">mic_off</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- participants window -->
    <ng-container *ngIf="selectedSideWindow == participantsWindow">
        <app-participant [allParticipants]="allParticipants" [activeParticipant]="activeParticipants" [type]="'teacher'" [acceptedRaisedHand]="acceptedRaisedHand" (closeWindow)="closeSideWindow()" (acceptRaisedHand)="acceptRaisedHand($event)" (lowerRaisedHand)="lowerRaisedHand($event)">
        </app-participant>
    </ng-container>

    <!-- hand raise window -->
    <div class="drawer" *ngIf="selectedSideWindow == handRaiseWindow">
        <div class="side-window-header">
            <img (click)="closeSideWindow()" class="close-icon" src="assets/close.svg" width="16px" height="16px" />
            <div class="participants-heading">Raised Hands</div>
        </div>
        <div class="drawer-content">
            <div *ngFor="let participant of activeParticipants">
                <div class="participant-card" *ngIf="participant.isHandRaised">
                    <div class="participant-card-profile">
                        <div *ngIf="!participant.profilePicture" class="profile-image participant-profile">
                            {{participant.name.charAt(0)}}
                        </div>
                        <div *ngIf="participant.profilePicture" class="profile-image participant-profile">
                            <img [src]="participant.profileSrc" />
                        </div>
                        <div>
                            <div class="participant-card-details">
                                <div class="participant-name">{{participant.name}}</div>
                                <div class="joined-time">{{participant.joinedAt | joinedTime | async}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="participant-card-icons">
                        <div (click)="acceptRaisedHand(participant.id)" class="right-margin" *ngIf="participant.isHandRaised && participant.id != acceptedRaisedHand"><img src="assets/hand_raise_inactive.svg" width="18px" height="18px" /></div>
                        <div (click)="lowerRaisedHand(participant.id)" class="right-margin" *ngIf="participant.isHandRaised"><img src="assets/lower-hand-blue.svg" width="20px" height="20px" /></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- for chat -->
    <div [ngClass]="selectedSideWindow == chatWindow? 'visible': 'no-display'">
        <app-chat [allParticipants]="allParticipants" [selfId]="selfId" (closeWindow)="closeSideWindow()" (sendMsg)="sendChatMessage($event)" [teacher]="teacher">
        </app-chat>
    </div>

    <div class="side-menu">
        <div>
            <div [ngClass]="selectedSideWindow == participantsWindow ? 'active-icon': ''" class="side-menu-icon-container" (click)="openSideWindow(participantsWindow)">
                <img *ngIf="selectedSideWindow != participantsWindow" src="assets/participants_inactive.svg" width="24px" height="24px" />
                <img *ngIf="selectedSideWindow == participantsWindow" src="assets/participants_active.svg" width="24px" height="24px" />
                <div *ngIf="liveSessionState && liveSessionState.totalParticipants" class="tag black-tag">
                    {{liveSessionState.totalParticipants}}</div>
            </div>
            <div [ngClass]="selectedSideWindow == chatWindow ? 'active-icon': ''" class="side-menu-icon-container" (click)="openSideWindow(chatWindow)">
                <img *ngIf="selectedSideWindow != chatWindow" src="assets/chat_inactive.svg" width="24px" height="24px" />
                <img *ngIf="selectedSideWindow == chatWindow" src="assets/chat_active.svg" width="24px" height="24px" />
                <div *ngIf="liveSessionState && liveSessionState.unreadChatMessages && selectedSideWindow != chatWindow" class="tag red-tag">{{liveSessionState.unreadChatMessages}}</div>
            </div>
            <div [ngClass]="selectedSideWindow == handRaiseWindow? 'active-icon': ''" class="side-menu-icon-container" (click)="openSideWindow(handRaiseWindow)">
                <img *ngIf="selectedSideWindow != handRaiseWindow" src="assets/hand_raise_inactive.svg" width="24px" height="24px" />
                <img *ngIf="selectedSideWindow == handRaiseWindow" src="assets/hand_raise_active.svg" width="24px" height="24px" />
                <div *ngIf="liveSessionState && liveSessionState.unseenRaisedHands && selectedSideWindow != handRaiseWindow" class="tag red-tag">
                    {{liveSessionState.unseenRaisedHands}}
                </div>
            </div>
        </div>

        <div>
            <div class="side-menu-icon-container">
                <img src="assets/settings_inactive.svg" width="24px" height="24px" />
                <!-- <img src="assets/settings_active.svg" width="24px" height="24px" /> -->
            </div>

            <div class="side-menu-icon-container">
                <img src="assets/help_inactive.svg" width="24px" height="24px" />
                <!-- <img src="assets/help_active.svg" width="24px" height="24px" /> -->
            </div>
        </div>
    </div>
</div>
<div *ngIf="showLoader &&  !isConnectionDropped" class="spinner column-flex overlay no-pointer-events" (click)="false; $event.stopPropagation();">
    <mat-progress-spinner mode="indeterminate">
    </mat-progress-spinner>
    <div class="reconnecting-dialog">Reconnecting</div>
</div>
<div *ngIf="isConnectionDropped" class="spinner column-flex overlay">
    <app-rejoin-dialog (rejoin)="reJoin()" (end)="end()"></app-rejoin-dialog>
</div>