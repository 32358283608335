import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormDataService } from 'src/app/services/form-data.service';

@Injectable({
  providedIn: 'root'
})
export class StudentCourseActivitiesService {

  constructor(
    private crud: CrudService,
    private snackbar: SnackbarService,
    public formData: FormDataService
  ) { }

  private isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public get isLoading$(): Observable<boolean> {
    return this.isLoading$$.asObservable();
  }

  private courseActivities$$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

  public get courseActivities$(): Observable<any[]> {
    return this.courseActivities$$.asObservable();
  }

  private selectedCourseActivity$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public get selectedCourseActivity$(): Observable<any> {
    return this.selectedCourseActivity$$.asObservable();
  }

  private evaluatedCourseActivity$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public get evaluatedCourseActivity$(): Observable<any> {
    return this.evaluatedCourseActivity$$.asObservable();
  }

  public getCourseActivities(courseId: string, studentId: string) {
    this.isLoading$$.next(true);
    this.crud.get(environment.apiUrl + `course-activity/course/${courseId}/student/${studentId}`).subscribe((resp) => {
      this.courseActivities$$.next(resp.activities);
      this.isLoading$$.next(false);
    }, (err) => {
      this.snackbar.openSnackbar('unable to fetch student course activities');
    });
  }

  public getCourseActivity(courseActivityId: string, studentId: string) {
    this.isLoading$$.next(true);
    this.crud.get(environment.apiUrl + `course-activity/get/${courseActivityId}/student/${studentId}`).subscribe((resp) => {
      this.selectedCourseActivity$$.next(resp);
      this.isLoading$$.next(false);
    }, (err) => {
      this.snackbar.openSnackbar('unable to fetch student course activity');
    });
  }

  public submitCourseActivity(reqBody) {
    const formData = this.formData.createFormData(reqBody);
    return this.crud.post(environment.apiUrl + 'course-activity/submission', formData);
  }

  public getEvaluatedCourseActivity(courseActivityId: string, studentId: string) {
    this.isLoading$$.next(true);
    this.crud.get(environment.apiUrl + `course-activity/evaluation/${courseActivityId}/student/${studentId}`).subscribe((resp) => {
      this.evaluatedCourseActivity$$.next(resp);
      this.isLoading$$.next(false);
    }, (err) => {
      this.snackbar.openSnackbar('unable to fetch evaluation details');
    });
  }

  public reset() {
    this.isLoading$$.next(true);
    this.courseActivities$$.next(null);
    this.selectedCourseActivity$$.next(null);
    this.evaluatedCourseActivity$$.next(null);
  }
}
