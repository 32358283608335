import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TeacherService } from '../../services/api/teacher.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateClassComponent } from '../create-class/create-class.component';
import { Class } from 'src/app/models/class.models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SchoolSpecificSettingsService } from '../services/school-specific-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { ARCHIVED_STATUS, SELECTED_SCHOOL_GROUP_KEY } from 'src/app/Constants/Constant';
import { LabelService } from 'src/app/services/label.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AccessRights } from 'src/app/models/super-admin-users.model';
import { SchoolGroupService } from 'src/app/services/school-group.service';
import { BASE_ROUTES } from 'src/app/Constants/route-constants';

@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss']
})
export class ClassesComponent implements OnInit, OnDestroy {
  classList: Class[] = [];
  isClassCreated = false;
  subjects = '';
  allSubjectsList = ['All Subjects'];
  standards = '';
  allStandardsList = ['All Standards'];
  statusList = ['All Classes', 'active', 'archived']
  filteredList = [];
  totalClass: number;
  isLoading = false;
  status = '';
  schools;
  tasks = [
    'Video session',
    'Class assignment',
    'Students performance',
  ];
  private destroy$$: Subject<any> = new Subject<any>();
  labelData;

  isSchoolGroupSelected = false;
  accessRights: AccessRights;

  constructor(
    public teacherService: TeacherService,
    public classService: SchoolSpecificSettingsService,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public snackbar: SnackbarService,
    private labelService: LabelService,
    private localstorage: LocalStorageService,
    private sidenavService: ToggleSidenavService,
    private schoolGroupSvc: SchoolGroupService
  ) { }

  ngOnInit(): void {
    this.sidenavService.maximizeSidenav();
    if (this.localstorage.getItem(SELECTED_SCHOOL_GROUP_KEY)) {
      this.isSchoolGroupSelected = true;
    }else {
      this.router.navigate([BASE_ROUTES.teacher, 'choose']);
    }
    this.schoolGroupSvc.accessRights$.subscribe(accessRights => {
      this.accessRights = accessRights;
    });
    this.labelService.labels$.pipe(takeUntil(this.destroy$$)).subscribe(data => this.labelData = data)
    this.classService.getSchools().subscribe(
      (resp) => {
        this.schools = resp.schools;
      },
      (err) => { this.snackbar.openSnackbar(err.error.error); }
    );

    this.subjects = 'All Subjects';
    this.standards = 'All Standards';
    this.status = 'All Classes';
    if (this.isSchoolGroupSelected) {
      this.teacherService.isLoading$.pipe(
        takeUntil(this.destroy$$)
      )
        .subscribe((value: boolean) => this.isLoading = value)
      this.teacherService.getClasses();
    }


    this.getClasses();
  }
  getClasses() {
    this.teacherService.class$.pipe(
      takeUntil(this.destroy$$)
    )
      .subscribe(
        (res: any) => {
          if (res && res.length > 0) {
            this.isClassCreated = true;
            this.totalClass = res.length;
            this.classList = this.filteredList = [];
            this.filteredList = this.classList = res;
            this.getAllFilterList(this.classList);
            this.filterByStandard(this.standards);
            this.filterBySubject(this.subjects);
            this.filterByStatus(this.status);
          }
        },
        (err) => {
          this.snackbar.openSnackbar(err.error.error)
        }
      );
  }
  createClass() {
    const dialogRef = this.dialog.open(CreateClassComponent, {
      width: '50%',
      height: '100%',
      data: { schools: this.schools },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['teacher-theme', 'width-class'],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.teacherService.getClasses();
      }
    })
  }

  joinClass() {
    this.router.navigate(['../', 'live-class-schedule'], { relativeTo: this.route })
  }

  getAllFilterList(lists) {
    lists.map((list: Class) => {
      this.allStandardsList.indexOf(list.standard) === -1 ? this.allStandardsList.push(list.standard) : null;
      this.allSubjectsList.indexOf(list.subject) === -1 ? this.allSubjectsList.push(list.subject) : null;
    });
  }

  filterByStandard(event) {
    this.standards = event;
    const subjects = this.subjects === 'All Subjects' || this.subjects === 'All' ? '' : this.subjects;
    const standards = this.standards === 'All Standards' || this.standards === 'All' ? '' : this.standards;
    const status = this.status === 'All Classes' || this.status === 'All' ? '' : this.status;
    this.filterClass(subjects, standards, status);
  }

  filterBySubject(event) {
    this.subjects = event;
    const subjects = this.subjects === 'All Subjects' || this.subjects === 'All' ? '' : this.subjects;
    const standards = this.standards === 'All Standards' || this.standards === 'All' ? '' : this.standards;
    const status = this.status === 'All Classes' || this.status === 'All' ? '' : this.status;
    this.filterClass(subjects, standards, status);
  }
  filterByStatus(event) {
    this.status = event;
    const subjects = this.subjects === 'All Subjects' || this.subjects === 'All' ? '' : this.subjects;
    const standards = this.standards === 'All Standards' || this.standards === 'All' ? '' : this.standards;
    const status = this.status === 'All Classes' || this.status === 'All' ? '' : this.status;
    this.filterClass(subjects, standards, status)
  }

  filterClass(subjects, standards, status) {
    let filteredList = this.classList.filter(() => true);
    if (subjects !== '') {
      filteredList = filteredList.filter(cls => cls.subject === subjects)
    }
    if (standards !== '') {
      filteredList = filteredList.filter(cls => cls.standard === standards)
    }
    if (status !== '') {
      filteredList = filteredList.filter(cls => {
        if (status == ARCHIVED_STATUS) {
          return cls.schoolStatus == ARCHIVED_STATUS || cls.status == ARCHIVED_STATUS;
        } else {
          return cls.schoolStatus == ARCHIVED_STATUS ? false : cls.status == ARCHIVED_STATUS ? false : true;
        }
      })
    }
    this.filteredList = filteredList;
  }

  switchSchoolGroup() {
    this.router.navigate(['/', 'teacher', 'choose']);
  }

  ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}
