import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { STUDENT_ID } from 'src/app/Constants/Constant';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isTabSelected = false;
  width = window.innerWidth;
  tabs = [
    { name: 'my work', url: 'my-work', img: '../../../../assets/mywork.svg' },
    { name: 'classes', url: 'home', img: '../../../../assets/myclass.svg' },
    { name: 'courses', url: 'courses', img: '../../../../assets/myclass.svg' },
    { name: 'profile', url: 'profile', img: '../../../../assets/graphic.svg' },
  ];

  tabs2 = [
    { name: 'my work', url: 'my-work', img: '../../../../assets/mobile/myWork.svg' },
    { name: 'classes', url: 'home', img: '../../../../assets/mobile/classes.svg' },
    { name: 'courses', url: 'courses', img: '../../../../assets/mobile/courses.svg' },
    { name: 'profile', url: 'profile', img: '../../../../assets/mobile/user.svg' },
  ]
  selected = 0;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public sidenavService: ToggleSidenavService,
    public cd: ChangeDetectorRef,
    private localStorage: LocalStorageService
  ) { }

  ngOnInit(): void {
    const routeName = this.router.url.split('/')[4];
    this.route.parent.params.subscribe((params) => {
      if(params.id) {
        this.localStorage.setItem(STUDENT_ID, params.id);
      }
    })
    this.tabs.filter((tab, index) => {
      if (tab.url === routeName) {
        this.selected = index;
      }
      return this.selected;
    });
    this.sidenavService.sidenavToggle$.subscribe(toggle => {
      this.isTabSelected = toggle;
      this.cd.detectChanges();
    });

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        const route = val.url.split('/')[4];
        this.tabs.filter((tab, index) => {
          if (tab.url === route) {
            this.selected = index;
          }
          return this.selected;
        });
      }
    });
  }

  select(index) {
    this.selected = index;
    if (index === 1) {
      this.router.navigate(['home'], { relativeTo: this.route });
    } else {
      this.router.navigate([this.tabs[index].url], { relativeTo: this.route });
    }
  }

}
