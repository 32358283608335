<ng-container>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="date">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.date | date: dateFormat}} </td>
        </ng-container>

        <ng-container matColumnDef="submissionDate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Submission Date</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.submissionDate ? (transaction.submissionDate | date: dateFormat) : (transaction.dueDate | date: dateFormat)}} </td>
        </ng-container>

        <ng-container matColumnDef="activityType">
            <th mat-header-cell *matHeaderCellDef>Activity Type</th>
            <td mat-cell *matCellDef="let transaction">
                <div class="activity">
                    <div>{{transaction.activityType | titlecase}}</div>
                    <div class="dot"></div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.title | titlecase}} </td>
        </ng-container>

        <ng-container matColumnDef="totalMarks">
            <th mat-header-cell *matHeaderCellDef>Total Marks</th>
            <td mat-cell *matCellDef="let transaction">{{ (transaction.totalMarks === 0 && transaction.activityType === 'materials') ? '--' : transaction.totalMarks}}</td>
        </ng-container>

        <ng-container matColumnDef="submittedBy">
            <th mat-header-cell *matHeaderCellDef>Submitted by</th>
            <td mat-cell *matCellDef="let transaction">{{ (transaction.activityType === 'materials') ? '--' : transaction.submittedBy }} {{ (transaction.activityType === 'materials') ? null : '/' }} {{ (transaction.activityType === 'materials') ? null : transaction.totalStudents}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
            <td mat-cell *matCellDef="let transaction" class="action-table-header" (click)="$event.stopPropagation()">
                <div class="select">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
                    <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                        <button mat-menu-item *ngFor="let item of menuListItems" (click)="selectMenuItem(item, transaction)">
                <span> {{ item }}</span> 
                </button>
                    </mat-menu>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="sendActivity(row)"></tr>
    </table>

    <ng-container>
        <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize> </mat-paginator>
    </ng-container>
</ng-container>