import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PostDetailTableComponent } from '../post-detail-table/post-detail-table.component';

export interface  dialogData {
  studentList : Array<[]>
}
@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {
  msgForm: FormGroup;
  students = [];
  placeholder : string;
  isSubmitted: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<PostDetailTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data : dialogData,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: `0px`});
    this.msgForm = this.fb.group({
      'note': [null, Validators.required]
    })
    this.students = this.data.studentList;
    this.setPlaceholder();
  }

  setPlaceholder() {
    if(this.students.length === 1) {
      this.placeholder = this.students[0].name;
    } else {
      let nameList = '';
      this.students.slice(0,3).map(student => nameList = nameList.concat(student.name, ','));
      nameList = nameList.concat('...');
      this.placeholder = nameList;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  sendMessage() {
    this.dialogRef.close();
  }

}
