import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { InviteComponent } from 'src/app/shared/invite/invite.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FindSchoolUserRoleService } from 'src/app/services/find-school-user-role.service';
import { takeUntil } from 'rxjs/operators';
import { SelectedSchoolService } from 'src/app/services/selected-school.service';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { TableService } from 'src/app/services/table.service';
import { MatSelect } from '@angular/material/select';
import { LabelService } from 'src/app/services/label.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CourseFormComponent } from 'src/app/shared/course-form/course-form.component';
import { COURSES_LABEL, ACTIVE_STATUS, INACTIVE_STATUS, EDIT } from 'src/app/Constants/Constant';
import { CoursesService } from 'src/app/services/courses.service';
import { Course } from 'src/app/models/course.model';
import { AlertDialogComponent } from 'src/app/shared-components/alert-dialog/alert-dialog.component';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';
import { BASE_ROUTES } from 'src/app/Constants/route-constants';

const INVITE_TEACHERS = 'Invite Teachers';
const INVITE_STAFF = 'Invite School Staff';
const INVITE_STUDENTS = 'Invite Students';
const ARCHIVE = 'Archive';
const CREATE_COURSE = 'Create Course';

@Component({
  selector: 'app-school-detail',
  templateUrl: './school-detail.component.html',
  styleUrls: ['./school-detail.component.scss'],
})
export class SchoolDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  sortDirection: 'asc' | 'desc' = 'desc';
  storedSortDirection: 'asc' | 'desc';
  title: string = '';
  school$
  tabs = [];
  selectedSchool
  toppings = new FormControl();
  toppingList: string[] = [INVITE_TEACHERS, INVITE_STAFF, INVITE_STUDENTS, CREATE_COURSE];
  asyncTabs: Observable<any>;
  schoolId: number;
  @ViewChild('select') select: MatSelect;
  role: string;
  lists1 = [];
  displayedColumns1: string[];
  lists2 = [];
  displayedColumns2: string[];
  lists3 = [];
  displayedColumns3: string[];
  courses = [];
  courseColumns: string[];
  private destroy$$: Subject<any> = new Subject<any>();
  teachersLabel;
  studentsLabel;
  staffLabel;
  actionLabel = {
    'Invite Teachers': '',
    'Invite School Staff': '',
    'Invite Students': '',
    Archive: 'Archive',
    CREATE_COURSE
  }
  public overviewDashboardLoaded: boolean = false;
  @ViewChild('overview') overViewTab: ElementRef;
  public coursesLabel: string = COURSES_LABEL;

  constructor(
    public snackbar: SnackbarService,
    public schoolService: SelectedSchoolService,
    private route: ActivatedRoute,
    private router: Router,
    public userRole: FindSchoolUserRoleService,
    public sidenavService: ToggleSidenavService,
    public tableService: TableService,
    private dialog: MatDialog,
    private labelS: LabelService,
    private analyticsService: AnalyticsService,
    private coursesService: CoursesService,
    private dialogRef: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.sidenavService.minmizeSidenav();
    this.labelS.labels$.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(val => {
      this.teachersLabel = val['teachers'];
      this.staffLabel = val['staff'];
      this.studentsLabel = val['students'];
      this.actionLabel['Invite Teachers'] = val['inviteteachers'];
      this.actionLabel['Invite School Staff'] = val['inviteschoolstaff'];
      this.actionLabel['Invite Students'] = val['invitestudents'];
      this.actionLabel[CREATE_COURSE] = CREATE_COURSE;
    });
    this.tabs = [{ label: 'Overview' }, { label: this.coursesLabel }, { label: 'Teachers' },
    { label: 'Staff' }, { label: 'Students' }, { label: 'Settings' }];
    this.route.params.pipe(
      takeUntil(this.destroy$$)
    )
      .subscribe(param => {
        if (param.id) {
          this.schoolService.getSchoolDetail(param.id)
            .subscribe((res) => {
              this.analyticsService.getSchoolOverviewDashboard(param.id, 'true');
              this.selectedSchool = res;
              this.userRole.setRoleType(res);
              this.schoolService.selectSchool(res);
            }, err => {
              if (err.error.error.includes('Access restricted')) {
                const dialogRef = this.dialogRef.open(AlertDialogComponent, {
                  width: '363px',
                  height: '170px',
                  backdropClass: 'blur',
                  data: { alertText: 'Access Denied', type: 'accessdenied', theme: USER_TYPES.admin },
                  panelClass: ['delete-dialog-css'],
                  autoFocus: false
                });
                dialogRef.afterClosed().subscribe((data) => {
                  this.router.navigate([BASE_ROUTES.admin, 'dashboard', 'schools']);
                });
              }else {
                this.snackbar.openSnackbar(err.error.error);
              }
            });
        }
      });

    this.schoolService.clearList();

    this.userRole.userRole$.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(role => {
      this.role = role;
    });
    this.courseColumns = ['name', 'code', 'category', 'certificate', 'status', 'actions'];
    this.displayedColumns3 = ['mobileNumber', 'actions'];
    this.displayedColumns2 = ['name', 'mobileNumber', 'role', 'status', 'actions'];
    this.displayedColumns1 = ['name', 'mobileNumber', 'subjects', 'status', 'actions'];


    this.toppings.valueChanges.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(event => {
      if (event && event.toLowerCase() === 'invite teachers' || event.toLowerCase() === 'invite students' || event.toLowerCase() === 'invite school staff') {
        const dialogRef = this.dialog.open(InviteComponent, {
          width: '46%',
          height: '100%',
          data: { title: event, id: this.selectedSchool.id.toString() },
          backdropClass: 'blur',
          disableClose: true,
          panelClass: ['admin-theme', 'width-class'],
          autoFocus: false
        });
        dialogRef.afterClosed().subscribe(result => {
          this.toppings.setValue('');
          if (result) {
            if (event && event.toLowerCase() === 'invite teachers') {
              this.schoolService.getAllSchoolTeachers();
              this.sortDirection = this.tableService.getSortDirection();
            } else if (event && event.toLowerCase() === 'invite school staff') {
              this.sortDirection = this.tableService.getSortDirection();
              this.schoolService.getAllSchoolStaff();
            } else if (event && event.toLowerCase() === 'invite students') {
              this.schoolService.getAllSchoolStudents();
            }
          }
        })
      } else if (event && event === CREATE_COURSE) {
        const dialogRef = this.dialog.open(CourseFormComponent, {
          width: '46%',
          height: '100%',
          data: { title: event, schoolID: this.selectedSchool.id.toString() },
          backdropClass: 'blur',
          disableClose: true,
          panelClass: ['admin-theme', 'width-class', 'zero-top-padding'],
          autoFocus: false
        });
        dialogRef.afterClosed().subscribe(result => {
          this.toppings.setValue('');
          if (result.courseCreated) {
            this.courses = [];
            this.coursesService.getCourses(this.selectedSchool.id);
          }
        });
      }
    });
  }


  ngAfterViewInit() {
    this.analyticsService.schoolOverviewDashboard$.subscribe((data) => {
      if (data && data.url) {
        this.overViewTab.nativeElement.src = data.url + '#titled=true&bordered=false';
        this.overviewDashboardLoaded = true;
      }
    })
  }
  tabSelect(event) {
    // if(this.role === 'member') {
    //   return;
    // }
    if (event.index === 1) {
      this.courses.length === 0 ? this.coursesService.getCourses(this.selectedSchool.id) : [];

      this.coursesService.courseList$.pipe(
        takeUntil(this.destroy$$),
      )
        .subscribe(courses => {
          this.courses = courses;
        });
    } else if (event.index === 2) {
      this.lists1.length === 0 ? this.schoolService.getAllSchoolTeachers() : [];

      this.schoolService.teachersList$.pipe(
        takeUntil(this.destroy$$),
      )
        .subscribe(teacher => {
          this.lists1 = teacher;
        });

    } else if (event.index === 3) {
      this.lists2.length === 0 ? this.schoolService.getAllSchoolStaff() : [];

      this.schoolService.schoolStaff$.pipe(
        takeUntil(this.destroy$$),
      )
        .subscribe(staff => {
          this.lists2 = staff;
        })
    } else if (event.index === 4) {
      this.lists3.length === 0 ? this.schoolService.getAllSchoolStudents() : [];

      this.schoolService.schoolStudents$.pipe(
        takeUntil(this.destroy$$),
      )
        .subscribe(students => {
          this.lists3 = students;
        });
    }
  }

  back() {
    this.router.navigate([BASE_ROUTES.admin, 'dashboard', 'schools']);
  }

  onSortChange(event: 'asc' | 'desc') {
    this.tableService.setSortDirection(event);
  }

  navigateToCourse(course: Course) {
    this.router.navigate(['school', 'dashboard', 'course', course.id]);
  }

  editCourse(course: Course) {
    const dialogRef = this.dialog.open(CourseFormComponent, {
      width: '46%',
      height: '100%',
      data: { title: 'Edit Course', type: EDIT, course: { ...course }, schoolID: this.selectedSchool.id.toString() },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['admin-theme', 'width-class', 'zero-top-padding'],
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.courseCreated) {
        this.courses = null;
        this.coursesService.getCourses(this.selectedSchool.id);
      }
    });

  }

  changeCourseStatus(course: Course) {
    const requestBody = {
      id: course.id,
      status: course.status === ACTIVE_STATUS ? INACTIVE_STATUS : ACTIVE_STATUS
    };
    if (!course.isPublished && requestBody.status === ACTIVE_STATUS) {
      this.snackbar.openSnackbar(`course needed to be published before activation`);
      return;
    }

    this.coursesService.updateCourse(requestBody).subscribe(() => {
      const ind = this.courses.findIndex((c) => c.id === course.id);
      if (ind >= 0) {
        this.courses[ind].status = requestBody.status;
      }
      const message = requestBody.status === ACTIVE_STATUS ? 'activated' : 'inactivated';
      this.snackbar.openSnackbar(`course ${message} successfully`);
    });
  }

  ngOnDestroy() {
    this.destroy$$.next(true);
    this.destroy$$.complete();
    this.analyticsService.clearSchoolOverviewDashboard();
    this.coursesService.clear();
  }
}
