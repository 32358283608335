import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PUBLISH_ACTION, UPDATE_ACTION } from 'src/app/Constants/Constant';

export const UPLOAD = 'UPLOAD';
export const SUBMIT = 'SUBMIT';
export const LOGOUT = 'LOGOUT';
@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  theme: 'admin' | 'teacher' | 'student' | 'super-admin';
  toUpload: boolean;
  alertText = 'Are you sure you want to upload these files?';
  actionType: 'UPLOAD' | 'SUBMIT' | 'LOGOUT' | 'PUBLISH' | 'UPDATE' = UPLOAD;
  isLogout: boolean = false;
  publishAction = PUBLISH_ACTION;
  contentType: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
    this.theme = this.data.theme;
    this.toUpload = this.data.isUpload ? this.data.isUpload : false;
    if (this.data.alertText) {
      this.alertText = this.data.alertText;
      this.actionType = SUBMIT;
    }
    if (this.data.isLogout) {
      this.actionType = LOGOUT;
    }
    if (this.data.actionType === this.publishAction || this.data.actionType === UPDATE_ACTION) {
      this.actionType = this.data.actionType;
    }
    this.contentType = this.data.contentType ? this.data.contentType : 'content';
  }

  getClass() {
    if (this.theme === 'teacher') {
      return 'delete-teacher';
    } else if (this.theme === 'admin') {
      return 'delete-admin';
    }else if (this.theme === 'super-admin') {
      return 'delete-super-admin';
    } else {
      return 'delete-student';
    }
  }

  closeDialog(choice) {
    choice = choice === 'cancel' ? false : true;
    this.dialogRef.close(choice);
  }

}
