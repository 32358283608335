<ng-container *ngIf="!toSelect">
    <div class="flex-1 line">
        <div class="checkbox">
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [color]="'primary'" [aria-label]="checkboxLabel()">
            </mat-checkbox>

            <div class="flex-1 headers" *ngIf="selection.hasValue()">
                <div class="block">
                    <div class="image">
                        <img src="../../../assets/message.svg" />
                    </div>
                    <div class="txt" (click)="moveBulkFiles()">Move To</div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matSortDirection="desc" matSortDisableClear>
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <div class="hideCheck">
                    <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [color]="'primary'" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </div>
            </th>
            <td mat-cell *matCellDef="let row">
                <div [ngClass]="isCloudContent ? 'admin-options': ''">
                    <mat-checkbox [disabled]="row.isFolder || (disableFolder && row.isFolder) ||(disabledFile && row.activityType !== 'tests')" (click)="$event.stopPropagation()" (change)="onChangeCheckbox($event, row)" [color]="'primary'" [checked]="selection.isSelected(row) || isFileSelected(row)"
                        [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let transaction">
                <div class="name-column">
                    <app-library-content-icon
                        [isFolder]="transaction.isFolder"
                        [userType]="userType"
                        [activityType]="transaction.activityType"
                    ></app-library-content-icon>
                    <div class="name">
                        {{transaction.activityType !== 'folder' ? transaction.name : ( transaction.name | titlecase ) }} </div>
                </div>
            </td>""
        </ng-container>

        <ng-container *ngIf="!isCloudContent || userType !== userTypes.superAdmin" matColumnDef="activityType">
            <th mat-header-cell *matHeaderCellDef>Activity Type</th>
            <td mat-cell *matCellDef="let transaction">
                <div class="activity">
                    <div>{{transaction.activityType | titlecase}}</div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let transaction">{{ transaction.title | titlecase }}</td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Added</th>
            <td mat-cell *matCellDef="let transaction"> {{getDate(transaction) | date: dateFormat}} </td>
        </ng-container>

        <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef>Size</th>
            <td mat-cell *matCellDef="let transaction">{{ formatBytes(transaction.size) }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="action-table-header">Actions</th>
            <td mat-cell *matCellDef="let transaction" class="action-table-header" (click)="$event.stopPropagation()">
                <div class="select" [ngStyle]="!selection.isEmpty() &&{'pointer-events': 'none'}">
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" xPosition="before" overlapTrigger="true">
                      <button [disabled]="transaction.isFolder && i==1" mat-menu-item *ngFor="let item of menuListItems; let i= index" (click)="selectMenuActions(transaction, i)">
                        <span> {{ item }}</span>
                      </button>
                  </mat-menu>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="getContents(row)"></tr>
    </table>

    <ng-container>
        <mat-paginator [pageSizeOptions]=pageSizeOptions [pageSize]=pageSize> </mat-paginator>
    </ng-container>
</ng-container>
