import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeacherService } from 'src/app/services/api/teacher.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { SchoolSpecificSettingsService } from '../services/school-specific-settings.service';

@Component({
  selector: 'app-edit-class',
  templateUrl: './edit-class.component.html',
  styleUrls: ['./edit-class.component.scss']
})
export class EditClassComponent implements OnInit {
  classForm: FormGroup;
  classDetail;
  isSubmitted = false
  constructor(
    private fb: FormBuilder,
              public classTeacherService : TeacherService,
              @Inject(MAT_DIALOG_DATA) public data,
              public classService: SchoolSpecificSettingsService,
              public snackbar: SnackbarService,
              private dialogRef: MatDialogRef<DashboardComponent>,
  ) { }
  buildForm() {
    this.classForm = this.fb.group({
      'school' : [{value: this.classDetail.school, disabled: true}, [Validators.required]],
      'className': [this.classDetail.name, [Validators.required, Validators.maxLength(100)]],
      'standard': [{value: this.classDetail.standard, disabled: true}, [Validators.required]],
      'section': [{value: this.classDetail.section, disabled: true}, [Validators.required]],
      'subject': [{value: this.classDetail.subject, disabled: true}, [Validators.required]],
      'session': [{value: this.classDetail.session, disabled: true}, [Validators.required]],
    })
}
  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: `0px`});
    this.classDetail = this.data.classDetail;
    this.buildForm();
  }
  createClass(){
    const requestBody = {
      name: this.classForm.value.className
    }
    this.classTeacherService.editClass(requestBody, this.classDetail.id).subscribe(
      (res) => {
        this.snackbar.openSnackbar('class successfully edited');
        this.dialogRef.close(res);
      },
      (err) => {
        this.isSubmitted = false;
        this.snackbar.openSnackbar(err.error.error);
      }
    )
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
