import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CreateStudentProfileComponent } from '../create-student-profile/create-student-profile.component';
import { ParentService } from '../services/parent.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Parent } from 'src/app/models/parent.model';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { Student } from 'src/app/models/student.models';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ChildClassService } from '../child-dashboard/services/child-class.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  parentDetail: Parent;
  tabs = [
    { name: 'home', img: '../../../assets/home.svg' },
    { name: 'profile', img: '../../../assets/graphic.svg' },
  ]
  selected = 0;
  private destroy$$: Subject<any> = new Subject<any>();

  childrenDetialsFetched = false;

  constructor(
    private router: Router,
    public parentService: ParentService,
    public dialog: MatDialog,
    public sidenavService: ToggleSidenavService,
    public cd: ChangeDetectorRef,
    public snackbar: SnackbarService,
    private localstorage: LocalStorageService,
    private childService: ChildClassService) { }

  ngOnInit(): void {
    const routeList = this.router.url.split('/');
    const routeName = routeList[routeList.length - 1];
    this.selected = routeName === 'dashboard' ? 0 : this.tabs.findIndex(tab => tab.name.toLowerCase() === routeName);

    this.getChildren();

    // this.parentService.getParentDetails().pipe(
    //   takeUntil(this.destroy$$)
    // ).subscribe(
    //   (parent : Parent) => {
    //     this.parentDetail = parent;
    //     this.parentService.setParentDetails(this.parentDetail);
    //   }),
    //   (err) => this.snackbar.openSnackbar(err.error.error)
  }

  private getChildren() {
    this.parentService.getChildren();
    this.parentService.children$.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(
      (childs: Student[]) => {
        if (childs && childs.length > 0) {
          this.localstorage.setItem('studentID', childs[0].id);
          this.childService.setChildName(childs[0].name);
          this.router.navigate(['student', childs[0].id], { replaceUrl: true });
        }
      }
    );
  }

  select(index) {
    this.selected = index;
    this.router.navigate(['parent', 'dashboard', this.tabs[index].name]);
  }

  addStudent() {
    const dialogRef = this.dialog.open(CreateStudentProfileComponent, {
      width: '46%',
      height: '100%',
      backdropClass: 'blur',
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.parentService.getChildren();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$$.next(true);
    this.destroy$$.complete();
  }
}
