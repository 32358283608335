<div class="live-class-wrapper">
    <div class="header">
        <ng-container *ngIf="theme === 'teacher'; else studnet">
            <div class="left-txt">
                <div class="img" (click)="back()"> <img src="assets/blue-back.svg" /></div>
                <div class="text">{{labelData['liveclassschedule']}}</div>
            </div>

            <div class="right-txt">
                <button mat-flat-button color="primary" (click)="createClass()">{{labelData['createliveclass']}}</button>
            </div>
        </ng-container>

        <ng-template #studnet>
            <div class="left-txt">
                <div class="img" (click)="back()"><img src="assets/green-back.svg" /></div>
                <div class="text">{{labelData['liveclassschedule']}}</div>
            </div>
        </ng-template>
    </div>

    <div class="tabs" [ngClass]="theme">
        <mat-tab-group (selectedTabChange)="tabSelect($event)" [selectedIndex]="selectedIndex">
            <mat-tab *ngFor="let tab of tabs">
                <ng-template mat-tab-label>{{tab.label | titlecase}}</ng-template>

                <ng-container *ngIf="tab.label === this.TABS.TODAY">
                    <div class="date-text">{{ selectedDate | date : 'dd LLLL EEEE'}}</div>
                </ng-container>

                <ng-container *ngIf="tab.label === this.TABS.UPCOMING">
                    <div class="week-paginator">
                        <button [disabled]="changingWeekRange" (click)="getPrevWeekLiveClasses()" class="paginator-arrow"><img src="assets/icons-chevron-left.svg" /></button>
                        <div class="date-text">{{ weekDateRange.monday | date : 'dd LLL, yyyy'}} - {{ weekDateRange.saturday | date : 'dd LLL, yyyy'}}</div>
                        <button [disabled]="changingWeekRange" (click)="getNextWeekLiveClasses()" class="paginator-arrow"><img src="assets/icons-chevron-right.svg" /></button>
                    </div>
                </ng-container>

                <ng-container *ngIf="tab.label === this.TABS.PAST">
                    <div class="date">
                        <div class="filter-date-text">Before, {{ selectedDate | date : 'dd LLLL EEEE'}}</div>
                        <!-- <div class="filter">
                            <div class="txt">Filters</div>
                            <div class="dash">|</div>
                            <div class="img">
                                <img src="assets/filter.svg" />
                            </div>
                        </div> -->
                    </div>
                </ng-container>
                <ng-container *ngIf="!isLoading; else loader">
                    <ng-container *ngIf="list && list.length > 0 else emptyPlaceHolder">
                        <app-live-class-table [transactions]="list" [displayedColumns]="getColumns(tab.label)" (onJoinClass)="onClassJoin($event)"></app-live-class-table>
                    </ng-container>
                    <ng-template #emptyPlaceHolder>
                        <div class="emptyPlaceHolder">
                            <p>No Data Found</p>
                        </div>
                    </ng-template>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>


<ng-template #loader>
    <app-spinner></app-spinner>
</ng-template>