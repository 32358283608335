<div class="work-wrapper" *ngIf="width >= 760">
    <div class="header">
        <div class="txt">My Work</div>
    </div>
    <div class="tabs">
        <mat-tab-group (selectedTabChange)="tabSelect($event)">
            <mat-tab *ngFor="let tab of tabs">
                <ng-template mat-tab-label>
                    <div class="label-container">
                        <p>{{tab.label}}</p>
                        <p *ngIf="tab.count !=0" class="tag red-tag">
                            {{tab.count}}
                        </p>
                    </div>
                </ng-template>

                <ng-container *ngIf="tab.label === 'Overdue'">
                    <ng-container *ngIf="loadingState.overdue else overdue">
                        <div class="work-container bordered-box">
                            <app-spinner></app-spinner>
                        </div>
                    </ng-container>
                    <ng-template #overdue>
                        <ng-container *ngIf="errorState.overdue else overdueData">
                            <div class="work-container bordered-box centered">
                                <p>Something Went Wrong</p>
                            </div>
                        </ng-container>
                        <ng-template #overdueData>
                            <div *ngIf="workOverdue && workOverdue.length > 0" class="work-container bordered-box">
                                <div *ngFor="let activity of workOverdue">
                                    <app-my-work-card (click)="goToActivity(activity,timeCategories.overdue)" [activity]="activity" [category]="'overdue'"></app-my-work-card>
                                </div>
                            </div>
                            <div *ngIf="workOverdue && workOverdue.length === 0" class="work-container bordered-box centered">
                                <p>No overdues</p>
                            </div>
                        </ng-template>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="tab.label === 'Today'">
                    <ng-container *ngIf="loadingState.today else today">
                        <div class="work-container bordered-box">
                            <app-spinner></app-spinner>
                        </div>
                    </ng-container>
                    <ng-template #today>
                        <ng-container *ngIf="errorState.today else todayData">
                            <div class="work-container bordered-box centered">
                                <p>Something Went Wrong</p>
                            </div>
                        </ng-container>
                        <ng-template #todayData>
                            <div *ngIf="workToday && workToday.length > 0" class="work-container bordered-box">
                                <div *ngFor="let activity of workToday">
                                    <app-my-work-card (click)="goToActivity(activity,timeCategories.today)" [activity]="activity" [category]="'today'"></app-my-work-card>
                                </div>
                            </div>
                            <div *ngIf="workToday && workToday.length === 0" class="work-container bordered-box centered">
                                <p>No work for today</p>
                            </div>
                        </ng-template>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="tab.label === 'Upcoming'">
                    <ng-container *ngIf="loadingState.upcoming else upcoming">
                        <div class="work-container bordered-box">
                            <app-spinner></app-spinner>
                        </div>
                    </ng-container>
                    <ng-template #upcoming>
                        <ng-container *ngIf="errorState.upcoming else upcomingData">
                            <div class="work-container bordered-box centered">
                                <p>Something Went Wrong</p>
                            </div>
                        </ng-container>
                        <ng-template #upcomingData>
                            <div *ngIf="workUpcoming && workUpcoming.length > 0" class="work-container bordered-box">
                                <div *ngFor="let activity of workUpcoming">
                                    <app-my-work-card (click)="goToActivity(activity,timeCategories.upcoming)" [activity]="activity" [category]="'upcoming'"></app-my-work-card>
                                </div>
                            </div>
                            <div *ngIf="workUpcoming && workUpcoming.length === 0" class="work-container bordered-box centered">
                                <p>No upcoming work</p>
                            </div>
                        </ng-template>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="tab.label === 'Completed'">
                    <ng-container *ngIf="loadingState.completed else completed">
                        <div class="work-container bordered-box">
                            <app-spinner></app-spinner>
                        </div>
                    </ng-container>
                    <ng-template #completed>
                        <ng-container *ngIf="errorState.completed else completedData">
                            <div class="work-container bordered-box centered">
                                <p>Something Went Wrong</p>
                            </div>
                        </ng-container>
                        <ng-template #completedData>
                            <div *ngIf="workCompleted && workCompleted.length > 0" class="work-container bordered-box">
                                <app-completed-work [workCompleted]="workCompleted"></app-completed-work>
                            </div>
                            <div *ngIf="workCompleted && workCompleted.length === 0" class="work-container bordered-box centered">
                                <p>No completed work</p>
                            </div>
                        </ng-template>
                    </ng-template>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<div class="work-wrapper" *ngIf="width < 760">
  <div class="header" [class.mat-elevation-z8]="true">
    <div class="txt">My Work</div>
  </div>
  <div class="tabs">
    <mat-tab-group (selectedTabChange)="tabSelect($event)" disablePagination = true>
      <mat-tab *ngFor="let tab of tabs">
        <ng-template mat-tab-label>
          <div class="label-container">
            <p>{{tab.label}}</p>
            <p *ngIf="tab.count !=0" class="tag red-tag">
              {{tab.count}}
            </p>
          </div>
        </ng-template>

        <ng-container *ngIf="tab.label === 'Overdue'">
          <ng-container *ngIf="loadingState.overdue else overdue">
            <div class="work-container bordered-box">
              <app-spinner></app-spinner>
            </div>
          </ng-container>
          <ng-template #overdue>
            <ng-container *ngIf="errorState.overdue else overdueData">
              <div class="work-container bordered-box centered">
                <p>Something Went Wrong</p>
              </div>
            </ng-container>
            <ng-template #overdueData>
              <div *ngIf="workOverdue && workOverdue.length > 0" class="work-container bordered-box">
                <div *ngFor="let activity of workOverdue">
                  <app-my-work-card (click)="goToActivity(activity,timeCategories.overdue)" [activity]="activity" [category]="'overdue'"></app-my-work-card>
                </div>
              </div>
              <div *ngIf="workOverdue && workOverdue.length === 0" class="work-container bordered-box centered">
                <p>No overdues</p>
              </div>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="tab.label === 'Today'">
          <ng-container *ngIf="loadingState.today else today">
            <div class="work-container bordered-box">
              <app-spinner></app-spinner>
            </div>
          </ng-container>
          <ng-template #today>
            <ng-container *ngIf="errorState.today else todayData">
              <div class="work-container bordered-box centered">
                <p>Something Went Wrong</p>
              </div>
            </ng-container>
            <ng-template #todayData>
              <div *ngIf="workToday && workToday.length > 0" class="work-container bordered-box">
                <div *ngFor="let activity of workToday">
                  <app-my-work-card (click)="goToActivity(activity,timeCategories.today)" [activity]="activity" [category]="'today'"></app-my-work-card>
                </div>
              </div>
              <div *ngIf="workToday && workToday.length === 0" class="work-container bordered-box centered">
                <p>No work for today</p>
              </div>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="tab.label === 'Upcoming'">
          <ng-container *ngIf="loadingState.upcoming else upcoming">
            <div class="work-container bordered-box">
              <app-spinner></app-spinner>
            </div>
          </ng-container>
          <ng-template #upcoming>
            <ng-container *ngIf="errorState.upcoming else upcomingData">
              <div class="work-container bordered-box centered">
                <p>Something Went Wrong</p>
              </div>
            </ng-container>
            <ng-template #upcomingData>
              <div *ngIf="workUpcoming && workUpcoming.length > 0" class="work-container bordered-box">
                <div *ngFor="let activity of workUpcoming">
                  <app-my-work-card (click)="goToActivity(activity,timeCategories.upcoming)" [activity]="activity" [category]="'upcoming'"></app-my-work-card>
                </div>
              </div>
              <div *ngIf="workUpcoming && workUpcoming.length === 0" class="work-container bordered-box centered">
                <p>No upcoming work</p>
              </div>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="tab.label === 'Completed'">
          <ng-container *ngIf="loadingState.completed else completed">
            <div class="work-container bordered-box">
              <app-spinner></app-spinner>
            </div>
          </ng-container>
          <ng-template #completed>
            <ng-container *ngIf="errorState.completed else completedData">
              <div class="work-container bordered-box centered">
                <p>Something Went Wrong</p>
              </div>
            </ng-container>
            <ng-template #completedData>
              <div *ngIf="workCompleted && workCompleted.length > 0" class="work-container bordered-box">
                <app-completed-work [workCompleted]="workCompleted"></app-completed-work>
              </div>
              <div *ngIf="workCompleted && workCompleted.length === 0" class="work-container bordered-box centered">
                <p>No completed work</p>
              </div>
            </ng-template>
          </ng-template>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
