<ng-container *ngIf="isLoading; else content">
    <app-spinner></app-spinner>
</ng-container>

<ng-template #content>
    <ng-container  *ngIf="teacherInvite && teacherInvite.length === 0 && !showChildren">
        <div class="home">
            <div class="txt">
                <div>You have not recieved any class invitations yet.</div>
                <div class="bottom">Please request your child’s teacher to send an invite..</div>
            </div>
            <div class="list">
                <div *ngFor="let task of tasks" class="task">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
                            <path fill="#a1b6c0" d="M13.302.905c.472-.44 1.277-1.183 2-.798 1.86.99-.5 3.301-1.25 4.236-1.194 1.513-2.444 2.971-3.666 4.456-.944 1.128-1.777 2.366-2.805 3.466-.583.633-1.582 1.788-2.554 1.733-.806-.027-1.5-.605-2.027-1.155C1.75 11.55.056 9.515 0 7.617c-.027-1.458 1.306-1.018 2.11-.413 1.112.853 1.973 1.898 2.972 2.86.445-.825 1.167-1.54 1.833-2.2 1.166-1.183 2.222-2.393 3.36-3.603 1.028-1.1 1.944-2.31 3.027-3.356zM2.24 9.873l.271.312c.622.698 1.175 1.205 1.907 1.81.563.084.796.214 1.03.343C4.203 10.733 3.134 9.91 2.24 9.873zm12.634-7.76C13.046 2.997 8.223 7.968 8 8.833L10.094 7c1.001-.543 1.45-1.509 2.3-2.183.2-.533 2.306-2.503 2.48-2.704z"/>
                        </svg>
                    </div>
                    <div class="text">
                        {{task}}
                    </div>
                </div>
            </div>
            <div class="img">
                <img src="../../../assets/teaching-bw.svg"/>
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="teacherInvite && teacherInvite.length > 0">
        <div class="invite">
            <div class="text">Invitations</div>
    
            <div *ngFor="let invite of teacherInvite; let i = index" class="list">
                <div class="box"> 
                    <div class="image"><img src="../../../assets/notification.svg"/></div>
                    <div class="txt">Teacher <span class="teacher">{{invite.teacher}} </span> has invited <span> to join {{invite.class}} {{invite.section}}.</span> </div>
                    <div class="btn">
                        <button (click)="acceptInvite(invite)">ACCEPT</button>
                    </div>
                </div> 
                <ng-container *ngIf="i !== teacherInvite.length-1">
                    <hr>
                </ng-container>
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="showChildren">
        <div>
            <app-child-list [childrens]="childrenList" [smallContainer]="(teacherInvite && teacherInvite.length > 0) ? true : false"></app-child-list>
        </div>
    </ng-container>
</ng-template>
