import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/services/crud.service';

@Injectable({
  providedIn: 'root'
})
export class ChildClassService {
  public className : string;
  constructor(public crud: CrudService) { }

  public classRoute(id) {
    return environment.apiUrl + 'student/get-classes/' + id;
  }

  getClasses(studentID) {
    return this.crud.get(this.classRoute(studentID));
  }

  setChildName(name) {
    this.className = name;
  }

  getChildName() {
    return this.className;
  }
}
