import { Component, OnInit, OnDestroy } from '@angular/core';
import { CourseCategory } from 'src/app/models/course-category.model';
import { CrudSettingsComponent } from 'src/app/admin/settings/crud-settings/crud-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { COURSE_CATEGORY_TITLE } from 'src/app/Constants/Constant';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { Location } from '@angular/common';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CoursesService } from 'src/app/services/courses.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-course-categories-setting',
  templateUrl: './course-categories-setting.component.html',
  styleUrls: ['./course-categories-setting.component.scss']
})
export class CourseCategoriesSettingComponent implements OnInit, OnDestroy {

  constructor(
    private dialog: MatDialog,
    private sidenavSvc: ToggleSidenavService,
    private _location: Location,
    private snackbar: SnackbarService,
    private coursesSvc: CoursesService
  ) {
    this.sidenavSvc.minmizeSidenav();
  }

  public isLoading: boolean = true;
  public isDeleting: boolean = false;
  public courseCategories: CourseCategory[] = [];

  ngOnInit(): void {
    this.coursesSvc.isLoadingCategories$.subscribe(value => this.isLoading = value)
    this.coursesSvc.getCourseCategories()
    this.coursesSvc.courseCategoriesList$.subscribe((courseCategories) => {
      if (courseCategories) {
        this.courseCategories = courseCategories;
      }
    })
  }

  public back() {
    this.coursesSvc.setInitialState()
    this.sidenavSvc.maximizeSidenav();
    this._location.back();
  }

  public add() {
    const dialogRef = this.dialog.open(CrudSettingsComponent, {
      data: {
        title: COURSE_CATEGORY_TITLE,
        type: 'add'
      },
      width: '46%',
      height: '100%',
      backdropClass: 'blur',
      disableClose: true,
      panelClass: 'admin-theme',
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.courseCategories.push(data);
      }
    })
  }

  public editCategory(category) {
    const dialogRef = this.dialog.open(CrudSettingsComponent, {
      data: {
        title: COURSE_CATEGORY_TITLE,
        name: category.name,
        type: 'edit',
        settingId: category.id
      },
      width: '46%',
      height: '100%',
      backdropClass: 'blur',
      disableClose: true,
      panelClass: 'admin-theme',
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.courseCategories.filter(setting => {
          if (setting.id === category.id) {
            setting.name = data.name;
          }
        })
      }
    })
  }

  public deleteCategory(category) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '363px',
      height: '190px',
      backdropClass: 'blur',
      data: { theme: 'admin' },
      panelClass: ['delete-dialog-css'],
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isDeleting = true;
        this.coursesSvc.deleteCourseCategory(category.id).subscribe((res) => {
          const deletedCategoryIndex = this.courseCategories.findIndex(c => c.id === category.id);
          this.courseCategories.splice(deletedCategoryIndex, 1)
          this.isDeleting = false;
          this.snackbar.openSnackbar(`category "${category.name}" deleted successfully`)
        }, (err) => {
          this.isDeleting = false;
          if (err.error && err.error.error) {
            this.snackbar.openSnackbar(err.error.error)
          } else {
            this.snackbar.openSnackbar(`unable to delete category: ${category.name}`)
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.coursesSvc.setInitialState()
    this.sidenavSvc.maximizeSidenav();
  }
}
