<div class="edit-profile-wrapper" [ngClass]="getTheme()">
    <div class="header">
        <div>Edit Profile</div>
        <div class="close" (click)="close()">
            <img src="../../../assets/close.svg" />
        </div>
    </div>

    <div class="form">
        <form [formGroup]="profileForm" (ngSubmit)="save()">
            <ng-container>
                <div class="name">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Name" formControlName="name">
                        <mat-error *ngIf="profileForm.controls['name'].touched">{{ getErrorName() }}</mat-error>
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngIf="data.user.email">
                <div class="email" [ngStyle]="{'margin-top' : '24px'}">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Enter email" formControlName="email">
                        <mat-error *ngIf="profileForm.controls['email'].errors">
                            {{ getErrorEmail() }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngIf="data.user.gender">
                <div class="gender" [ngStyle]="{'margin-top' : '24px'}">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Gender</mat-label>
                        <mat-select placeholder="Select gender" formControlName="gender">
                            <mat-option *ngFor="let gender of genders" [value]="gender">
                                {{gender}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!profileForm.controls['gender'].valid && profileForm.controls['gender'].touched">
                            Gender can't be empty
                        </mat-error>
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngIf="data.user.username">
                <div class="user" [ngStyle]="{'margin-top' : '24px'}">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>User Name</mat-label>
                        <input matInput placeholder="Enter your new user name" type="text" formControlName="userName">
                        <mat-error *ngIf=" !profileForm.controls['userName'].valid && profileForm.controls['userName'].touched">User name can't be empty</mat-error>
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container *ngIf="userType === userTypes.student">
                <div class="pin" [ngStyle]="{'margin-top' : '24px'}">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Pin</mat-label>
                        <input matInput placeholder="Set a 4-digit pin" type="number" formControlName="pin" autocomplete="off">
                        <mat-error *ngIf="profileForm.controls['pin'].touched && !profileForm.controls['pin'].valid">pin must be of exactly 4 digits</mat-error>
                    </mat-form-field>
                </div>
            </ng-container>

            <div class="upload" [ngStyle]="isImageUploaded === false && {'border': '2px solid #ff8156', 'border-radius': '12px', 'color': '#ff8156'}">
                <app-upload [text]="'UPLOAD PHOTO'" [imgUrl]="Imageurl" (url)="onImageUpload($event)" [imgType]="'user'"></app-upload>
            </div>

            <div *ngIf="isImageUploaded === false" [ngStyle]="{'color': '#ff8156'}">Please upload image</div>

            <div class="btn">
                <button mat-flat-button type="submit" color="primary" [disabled]="isSubmitted">SAVE</button>
            </div>
        </form>
    </div>
</div>
