import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyDate'
})
export class PrettyDatePipe implements PipeTransform {

  transform(date: any): unknown {
    return date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })
  }

}
